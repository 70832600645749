import SearchBar from "components/atoms/search";
import MemoizedAvatar from "components/molecules/ProjectSelect/Avatar";
import { useState } from "react";
import { cloudFrontUrl } from "utils/common";
import "./IconPicker.scss";

const raw_icons = [
  { name: "flood-risk-feedback.svg" },
  { name: "waves.svg" },
  { name: "add.svg" },
  { name: "attach-money.svg" },
  { name: "beach-access.svg" },
  { name: "business.svg" },
  { name: "eco.svg" },
  { name: "house.svg" },
  { name: "policy.svg" },
  { name: "security.svg" },
  { name: "supervised-user-circle.svg" },
  { name: "supervisor-account.svg" },
  { name: "traffic.svg" },
  { name: "solar.svg" },
  { name: "bike.svg" },
];

const IconSelector = ({ onSelect, setIcon }) => {
  const [searchRef, setSearchRef] = useState(0);
  const [icons, setIcons] = useState(raw_icons);
  const [iconsAux, setIconsAux] = useState(raw_icons);
  const onSelectIcon = (icon) => {
    console.log(icon);
    setIcon(icon);
    onSelect(false);
  };

  const handleSearch = (query, isEmpty) => {
    let result = iconsAux.filter((n) =>
      n.name.toLowerCase().includes(query.toLowerCase())
    );
    if (searchRef === result.length) {
    } else {
      if (result.length <= 0) {
        setIcons([{}]);
      } else {
        if (isEmpty) {
          setIcons(iconsAux);
        } else {
          setIcons(result);
        }
      }
    }
    setSearchRef(result.length);
  };

  return (
    <div className="icon-picker-container">
      <div className="icon-container-boder">
        <SearchBar
          placeholder={"search"}
          handleSearch={(e) => handleSearch(e)}
          className="icon-picker-searchbar "
        />
        <div className="icon-container-inner">
          {icons.map((icon) => {
            return (
              <div
                className="icon-item"
                onClick={() => onSelectIcon(icon.name)}
              >
                <MemoizedAvatar
                  size={30}
                  className={null}
                  src={cloudFrontUrl(icon.name)}
                  // logo={icon.name}
                  customPlaceHolder="https://image.flaticon.com/icons/png/128/727/727399.png"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default IconSelector;
