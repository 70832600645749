import { Row, Layout, Col, Divider } from "antd";
import { PrimaryButton } from "components/atoms/controls";
import { EditIcon } from "components/atoms/icons";
import { TrashIcon } from "components/atoms/icons";
import { Title } from "components/atoms/texts";
import React, { useContext } from "react";
import { I18nContext } from "utils/i18n/locale";
import "./NewBusinessHeader.scss"
const NewBusinessHeader = ({directoryTitle}) => {
  const {translate} = useContext(I18nContext)
  return (
    <Layout className="new-business-header-wrapper">
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={10} className="title-column">
          <Title text={directoryTitle} className="survey-title" />
          <EditIcon className="title-icon" />
        </Col>
        <Col xs={24} lg={14} className="survey-editor-actions">
          <div className="actions-container">
            <TrashIcon
              className="survey-header-icon"
            />
            <PrimaryButton
              title={translate("pages").directory.placeholder.publish}
              cssClassName="survey-header-editor-button"
            />
          </div>
        </Col>
      </Row>
      <Divider className="business-header-divider"/>
    </Layout>
  );
};
export default NewBusinessHeader;
