import Verify from 'pages/utils/Verify';

const prefix = '/verify';

const routes = [
  {
    path: `${prefix}`,
    component: Verify,
    exact: true,
  },
];

export default routes;
