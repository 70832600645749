import LoginRoute from "router/modules/loginRT";
import RecoverRoute from "router/modules/recoverRT";
import ChangeRoute from "router/modules/changeRT";
import VerifyCodeRoute from "router/modules/verifyCodeRT";
import verifyRoute from "router/modules/verifyRT";
import signUpRoute from "router/modules/signupRT";
import LoginLayout from "layouts/LoginLayout";

export const authRoutes = [
  {
    layout: LoginLayout,
    routes: [
      ...LoginRoute,
      ...verifyRoute,
      ...RecoverRoute,
      ...ChangeRoute,
      ...VerifyCodeRoute,
      ...signUpRoute,
    ],
  }
]
