import NewBusinessHeader from "components/molecules/NewBusinessHeader";
import NewBusinessForm from "components/organisms/NewBusinessForm";
import React from "react";

const NewBusiness = () => {
  return <> 
  
  <NewBusinessHeader directoryTitle="Business Name"/>
  <NewBusinessForm/>
  </>;
};

export default NewBusiness;
