import { Row, Col } from "antd";
import Card from "components/molecules/Card";

const CardContainer = ({ props }) => {
  const { items, loading } = props;
  return (
    <Row gutter={[24, 24]}>
      {items.map((item) => (
        <Col
          xxl={24 / 6}
          xl={24 / 4}
          lg={24 / 3}
          md={24 / 2}
          sm={24 / 2}
          xs={24}
          key={Math.floor(Math.random() * 10000)}
        >
          <Card loading={loading} item={item} props={props} />
        </Col>
      ))}
    </Row>
  );
};

export default CardContainer;
