import { Col, Divider, Layout, Row } from "antd";
import DropUpload from "components/atoms/DropUpload";
import { CreateElementInput } from "components/atoms/inputs";
import { SourceImage } from "components/atoms/media";
import { Text } from "components/atoms/texts";
import React, { useContext, useState } from "react";
import "./NewBusinessForm.scss";
import { MapSearchType } from "components/organisms/LocationAutoSuggest";
import { PrimaryButton } from "components/atoms/controls";
import { I18nContext } from "utils/i18n/locale";
const NewBusinessForm = () => {
  const [imageSrc, setImageSrc] = useState([]);
  const [active, setActive] = useState("city");
  const {translate} = useContext(I18nContext)

  const setFileSrc = (files) => {
    for (let file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        setImageSrc((prev) => [...prev, reader.result]);
      };
    }
  };

  const removeImage = (index) => {
    setImageSrc((prev) => prev.filter((value, i) => i !== index));
  };
  return (
    <div className="new-business-form-wrapper">
      <Layout className="new-business-form">
        <CreateElementInput
          label={translate("pages").directory.placeholder.name}
          placeholder={translate("pages").directory.placeholder.titleHere}
        />
        <div className="new-business-images">
          <Text
            text={translate("pages").directory.placeholder.selectImage}
            className="select-images-label"
          />
          <DropUpload setFileSrc={setFileSrc} className="dropzone-container" />

          <Row gutter={[16, 16]} className="uploaded-images">
            {imageSrc.map((image, index) => (
              <Col xs={24} md={12} lg={6}>
                <SourceImage
                  src={image}
                  onRemove={() => removeImage(index)}
                  preview={false}
                />
              </Col>
            ))}
          </Row>
        </div>
        <CreateElementInput
          label={translate("pages").directory.placeholder.location}
          placeholder={translate("pages").directory.placeholder.addLocation}
          suffix={<MapSearchType active={active} setActive={setActive} />}
        />
        <CreateElementInput
          label={translate("pages").directory.placeholder.phone}
          placeholder={translate("pages").directory.placeholder.phoneText}
        />
        <CreateElementInput
          label={translate("pages").directory.placeholder.webLink}
          placeholder={translate("pages").directory.placeholder.webLinkText}
        />
      </Layout>
      <Divider />
      <div className="business-form-save-button">
        <PrimaryButton title={translate("pages").directory.placeholder.save} />
      </div>
    </div>
  );
};

export default NewBusinessForm;
