import React, { Suspense, useState } from "react";
import { SpiningPage } from "components/atoms/icons";
import AnalyticsGallery from "./Gallery";
import { PlusOutlined } from "@ant-design/icons";
import FAB from "components/atoms/fab";
import CreateFlagForm from "components/organisms/CreateFlagForm";
import CreateProjectTabs from "components/organisms/CreateProjectTabs";

const Analytics = (props) => {
  const [isModalOpen, openModal] = useState(false);
  return (
    <Suspense fallback={<SpiningPage opacity={1} />}>
      <AnalyticsGallery {...props} />
      {/* <FAB onClick={() => openModal(!isModalOpen)} icon={<PlusOutlined />} /> */}
      {/* <CreateProjectTabs onClose={() => openModal(false)} show={isModalOpen} /> */}
      {/* <CreateFlagForm onClose={() => openModal(false)} show={isModalOpen} /> */}
    </Suspense>
  );
};

export default Analytics;
