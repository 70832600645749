import React, {useContext} from 'react';
import {I18nContext} from 'utils/i18n/locale';
import {Col, Divider} from 'antd';
import {Text} from 'components/atoms/texts';
import {withRouter} from 'react-router-dom';
import './Analytics.scss';
import {EditIcon} from "components/atoms/icons";

const AnalyticsTemplates = ({
  children,
  className,
  headerActivity = <div />,
  isDetails = false,
  onEdit = () => {},
  title
}) => {
  const {translate} = useContext(I18nContext);

  return (
    <Col className={`analytics-content-container ${className}`} xs={24}>
      <div className="analytics-header-row">
        <div className='title-container'>
          <Text
            className="analytics-main-title"
            text={title || translate('pages').analytic.gallery.title}
          />
          {
            isDetails && <div onClick={onEdit}><EditIcon className='edit-icon'/></div>
          }
        </div>
        <div className="analytics-action-container">{headerActivity}</div>
      </div>
      <Divider type="horizontal" />
      {children}
    </Col>
  );
};

export default withRouter(AnalyticsTemplates);
