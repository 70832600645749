import React, { useContext } from "react";
import { Text } from "components/atoms/texts";
import Moment from "moment";
import { I18nContext } from "utils/i18n/locale";
import "./EventsRow.scss";
import { cloudFrontUrl } from "utils/common";

const Image = React.memo(function Image({ src, classname }) {
  const { translate } = useContext(I18nContext);
  return (
    <img
      src={src}
      className={classname}
      alt={translate("client").pages.home.LOADING}
    />
  );
});

const EventsRow = (props) => {
  const { onClick, item, index } = props;
  return (
    <div onClick={() => onClick(index)} style={{ height: 120 }}>
      <div className="events-sa-container">
        <div className="events-sa-image">
          {item.image != null ? (
            <Image
              classname="events-sa-image-placeholder"
              src={
                item.image
                  ? cloudFrontUrl(item.image)
                  : "https://jpassport.asia/static/image-not-available.png"
              }
            />
          ) : null}
        </div>
        <div className="events-sa-info">
          <Text className="events-sa-title" text={item.title} />

          <div className="news-row">
            <div className="events-source-sa-info">
              {/*<Text className='events-sa-desc' text={item.description} />*/}
              <Text
                className="events-date-sa-text"
                text={Moment(item.created_at).format("DD MMM YYYY")}
              />
              <div className="news-actions">Find out more</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsRow;
