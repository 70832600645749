import React from 'react';
import {Text} from 'components/atoms/texts';
import "./ProjectInfo.scss";
import {ReactComponent as DownArrowSvg} from "components/client/components/atoms/DownArrow.svg";

const ProjectInfo = (props) => (
    <div className="project-container">
        <div className="project-info">
            <img className="project-logo" alt="project-logo" src={props.image} />
            <div className="project-title">
                <span className="project-organization">{props.projectOrganization}</span>
                <Text className="project-name" text={props.projectName} />
            </div>
        </div>
        <span className="project-dropdown">
            <DownArrowSvg className="down-svg" onClick={props.onClick} />
        </span>
    </div>
);

export default ProjectInfo;
