import React, {useContext} from 'react';
import "./Eventsempty.scss";
import { I18nContext } from "utils/i18n/locale";
import EmptyEventsSvg from "assets/img/illustrations/emptyevents.svg";
import { Image } from "antd";

const EventsEmpty = () => {
  const { translate } = useContext(I18nContext)

  return (
    <div className="empty-event-container">
      <Image src={EmptyEventsSvg} preview={false} />
      <p className="message">
        {translate("client").pages.events.empty.message}
      </p>
    </div>
  )
}

export default EventsEmpty;