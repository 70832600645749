import React, { useContext, useEffect } from "react";
import SurveyEditorHeader from "components/organisms/SurveyEditorHeader";
import { I18nContext } from "utils/i18n/locale";
import "./survey-editor.scss";
import SurveyBuilder from "components/templates/SurveyBuilder";
import SurveyResult from "components/templates/SurveyResult";
import TabsMenu from "components/atoms/tabs";
import SurveyResponses from "components/templates/SurveyResponses";
import SingleSurveyResponse from "components/templates/SurveyResponses/SingleSurveyResponse";
import SurveySettings from "components/templates/SurveySettings";
import { useHistory, useRouteMatch, Route } from "react-router-dom";
import { QuestionContext } from "context/SurveyEditorContext";
import { useDispatch, useSelector } from "react-redux";
import { getSurvey, getSurveyQuestions } from "redux/slices/surveys";
import { getQuestionTypes } from "redux/slices/questions";
import { useParams } from "react-router-dom";

const SurveyEditor = (props) => {
  const dispatch = useDispatch();
  const {
    surveys: {
      loading,
      error,
      surveyQuestionsFetched,
      surveyQuestions,
      survey,
    },
    questions: { questionTypes, questionTypesFetched },
  } = useSelector((state) => state);
  const history = useHistory();
  const { state } = history.location || {};
  const { translate } = useContext(I18nContext);
  const {
    responses,
    questions,
    newSurveyTitle,
    setSurveyTitle,
    surveyId,
    setSurveyId,
    setQuestions,
  } = useContext(QuestionContext);

  const urlSplit = window.location.href.split("/");
  // const urlId = urlSplit[urlSplit.length - 1];

  const params = useParams();

  const { tab, id } = params;

  let storedTitle;
  if (newSurveyTitle) {
    storedTitle = newSurveyTitle;
  } else {
    storedTitle = state?.surveyTitle;
    setSurveyTitle(state?.surveyTitle);
  }
  if (!surveyId && state?.surveyId) {
    setSurveyId(state?.surveyId);
  }

  useEffect(() => {
    setSurveyTitle(survey.title);
  }, [survey]);

  useEffect(() => {
    if (!state && !newSurveyTitle) {
      dispatch(getSurvey(surveyId || id));
    }

    dispatch(getQuestionTypes());
    if (surveyId || id) {
      dispatch(getSurveyQuestions(surveyId || id));
    }
  }, []);

  useEffect(() => {
    if (questionTypes.length > 0 && surveyQuestionsFetched) {
      const formattedQuestions = surveyQuestions.map((question) => ({
        ...question,
        question: question.title,
        questionId: question.id,
        surveyId: question.survey_id,
        editMode: false,
        settingMode: false,
        questionType: questionTypes.filter(
          (type) => type.id === question.question_type_id
        )[0].name,
        created: true,
      }));
      setQuestions(formattedQuestions);
      // console.log({ formattedQuestions });
    }
  }, [surveyQuestionsFetched, questionTypesFetched]);

  // const urlSplit = window.location.href.split("/");

  const singleResponsePage =
    urlSplit[urlSplit.length - 2] === "responses" ? true : false;

  let { path } = useRouteMatch();
  // console.log("The path in surveys:", path);
  // TODO: Check pending tabs of surveys
  const tabChildren = (
    <>
      <Route path={`${path}/builder/:id`} component={SurveyBuilder} exact />
      {/* <Route path={`${path}/result`} component={SurveyResult} exact /> */}
      <Route path={`${path}/responses/:id`} component={SurveyResponses} exact />
      {/* <Route path={`${path}/settings`} component={SurveySettings} exact /> */}
      {/* <Route
        path={`${path}/response/:id`}
        component={SingleSurveyResponse}
        exact
      /> */}
    </>
  );
  const tabOptions = [
    {
      title: translate("pages").surveyEditor.tabs.builder,
      key: "builder",
      children: tabChildren,
      id: surveyId,
    },
    // {
    //   title: translate("pages").surveyEditor.tabs.result,
    //   key: "result",
    //   children: tabChildren,
    // },
    {
      title: `${translate("pages").surveyEditor.tabs.responses}`,
      // title: `${translate("pages").surveyEditor.tabs.responses} (${
      //   responses.length
      // })`,
      key: "responses",
      children: tabChildren,
      id: surveyId,
    },
    // {
    //   title: translate("pages").surveyEditor.tabs.settings,
    //   key: "settings",
    //   children: tabChildren,
    // },
  ];

  let activePageObject = tabOptions.filter(
    (option) => option.key === urlSplit[urlSplit.length - 1]
  );

  const activePage =
    activePageObject.length < 1
      ? urlSplit[urlSplit.length - 2]
      : activePageObject[0].key;

  return (
    <div>
      <SurveyEditorHeader
        page={activePage}
        singleResponsePage={singleResponsePage}
        surveyTitle={storedTitle}
        surveyId={surveyId || state?.surveyId}
      />
      <TabsMenu
        options={tabOptions}
        onChange={(key) => history.push(`/home/surveys/editor/${key}/`)}
        defaultActiveKey={activePage}
        isSurvey
      />
    </div>
  );
};

export default SurveyEditor;
