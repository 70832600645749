import React, {useContext} from 'react'
import {I18nContext} from "utils/i18n/locale";
import {LAYOUT, LEGEND_DIRECTION, LEGEND_POSITION} from "components/molecules/Graphs";
import ScatterPlot from "components/molecules/Graphs/Scatter";

const FlagApprovalScatterPlot = () => {
	const {translate} = useContext(I18nContext);

	// This should be replaced with an API Call
	const DATA = [
		{
			"x": 0.15,
			"y": 0.15,
			"category": "Flag On Time",
			"size": 1
		},
		{
			"x": 0.3,
			"y": 0.53,
			"category": "Expired Flags",
			"size": 1
		}
	]

	return (
		<ScatterPlot
			height='100%'
			legendPosition={LEGEND_POSITION.TOP}
			legendDirection={LEGEND_DIRECTION.ROW}
			layout={LAYOUT.HORIZONTAL}
			title={translate("pages").analytics.flagApprovalAndCompletion}
			xAxisLabel={translate("pages").analytic.editor.charts.flagApprovalScatterPlot.daysToApprove}
			yAxisLabel={translate("pages").analytic.editor.charts.flagApprovalScatterPlot.daysToComplete}
			data={DATA}
			indexBy="category"
			maxXScale={1.0}
			maxYScale={1.0}
			varyingSize={false}
			keyPoints={{
				[translate("pages").analytic.editor.charts.flagApprovalScatterPlot.flagOnTime]: "#75FACF",
				[translate("pages").analytic.editor.charts.flagApprovalScatterPlot.flagExpired]: "#C43A5A",
			}}/>
	)
}

export default FlagApprovalScatterPlot;
