import React, { useContext } from 'react';
import "./EventsWelcomeHeader.scss";
import { Text } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";


const EventsWelcomeHeader = ({ userName }) => {
  const { translate } = useContext(I18nContext)

  return(
    <div className="events-welcome-container">
      <Text className="welcome-user" text={ `${translate("client").pages.events.topbar.welcomestarter} ${userName}`} />
      <Text className="welcome-message" text={`${translate("client").pages.events.topbar.welcomeMessage}`} />
      <Text className="welcome-current-date" text={ `${translate("client").pages.events.common.today} sep 28, 2020 ${translate("client").pages.events.common.to} October 28, 2020`} />
    </div>
  )
}

export default EventsWelcomeHeader;