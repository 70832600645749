import React, { useContext, useEffect, useState } from "react";
import { Col, Layout, Row } from "antd";
import { PrimaryButton } from "components/atoms/controls";
import { EditIcon } from "components/atoms/icons";
import { Title } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";
import "./SurveyEditorHeader.scss";
import { TrashIcon } from "components/atoms/icons";
import DeleteSurveyPopup from "components/molecules/DeleteSurveyPopup";
import { Text } from "components/atoms/texts";
import { useHistory } from "react-router-dom";
import { CreateElementInput } from "components/atoms/inputs";
import CreateSurveyPopup from "components/molecules/CreateSurveyPopup";
import { useDispatch, useSelector } from "react-redux";
import { publishSurvey, updateSurvey } from "redux/slices/surveys";
import { unwrapResult } from "@reduxjs/toolkit";
import { SwalAlerts } from "components/atoms/outputs";
import { SpinLoader } from "components/atoms/icons";
import { Alert } from "components/atoms/outputs";
import { QuestionContext } from "context/SurveyEditorContext";

const SurveyEditorHeader = ({
  page,
  surveyTitle,
  singleResponsePage,
  surveyId,
}) => {
  const history = useHistory();
  const { translate } = useContext(I18nContext);
  const [visible, setVisible] = useState(false);
  const [titleEdit, setTitleEdit] = useState(false);
  const [title, setTitle] = useState(surveyTitle);
  const [publishing, setPublishing] = useState(false);
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();
  const {
    surveys: { loading, error },
  } = useSelector((state) => state);
  const { setSurveyTitle } = useContext(QuestionContext);

  const handleSurveyEdit = () => {
    setEditing(true);
    dispatch(
      updateSurvey({
        title: title,
        description: "description",
        image: "survey.png",
        id: surveyId,
      })
    )
      .then(unwrapResult)
      .then(() => {
        setEditing(false);
        setSurveyTitle(title);
        Alert("success", translate("pages").survey.alerts.surveyUpdate);
        setTitleEdit(false);
      })
      .catch(() => {
        setEditing(false);
        Alert("error", translate("pages").survey.alerts.surveyUpdateError);
      });
  };

  const handleSurveyPublish = () => {
    setPublishing(true);
    const available_until = new Date();
    dispatch(publishSurvey({ surveyId, available_until }))
      .then(unwrapResult)
      .then(() => {
        setPublishing(false);
        SwalAlerts({
          title: translate("pages").survey.alerts.publishSuccess,
          text: translate("pages").survey.alerts.publishSuccessText,
          type: "success",
        });
        setTimeout(function () {
          history.push("/home/surveys");
        }, 2000);
      })
      .catch(() => {
        SwalAlerts({
          title: translate("pages").survey.alerts.publishError,
          text: translate("pages").survey.alerts.publishErrorText,
          type: "error",
        });
      });
  };
  return (
    <>
      <Layout className="survey-editor-header-container">
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={10} className="title-column">
            <Title text={surveyTitle} className="survey-title" />
            {page === "builder" && (
              <EditIcon
                className="title-icon"
                onClick={() => setTitleEdit(true)}
              />
            )}
          </Col>
          <Col xs={24} lg={14} className="survey-editor-actions">
            <div className="actions-container">
              {/* TODO:functions to handle delete survey */}
              {/* {singleResponsePage && (
                <TrashIcon
                  className="survey-header-icon"
                  onClick={() => setVisible(true)}
                />
              )} */}
              {/* TODO: export to csv */}
              {/* {(page === "responses" || page === "settings") &&
                !singleResponsePage && (
                  <PrimaryButton
                    title={translate("pages").surveyEditor.title.exportBtn}
                    cssClassName="survey-header-editor-button"
                    color="secondary"
                  />
                )} */}
              <PrimaryButton
                title={
                  loading && publishing ? (
                    <SpinLoader color="#fff" opacity={1} fontSize={20} />
                  ) : (
                    !singleResponsePage &&
                    translate("pages").surveyEditor.title.publishBtn
                  )
                }
                cssClassName="survey-header-editor-button"
                onClick={handleSurveyPublish}
              />
            </div>
          </Col>
        </Row>

        <DeleteSurveyPopup
          isVisible={visible}
          handleCancel={() => setVisible(false)}
          title={translate("pages").surveyEditor.title.deleteSurvey}
          className="modal"
        >
          <Text
            text={translate("pages").surveyEditor.title.deleteSurveyMessage}
            className="survey-delete-popup-text"
          />
          <div className="survey-delete-btn">
            <PrimaryButton
              title={translate("pages").surveyEditor.title.deleteSurveyBtn}
            />
          </div>
        </DeleteSurveyPopup>
      </Layout>
      <CreateSurveyPopup
        isVisible={titleEdit}
        title={translate("pages").survey.title.editSurvey}
        className="modal"
        handleCancel={() => setTitleEdit(false)}
      >
        <CreateElementInput
          className="modal-input"
          label={translate("pages").survey.placeholder.surveyLabel}
          placeholder={translate("pages").survey.placeholder.createSurvey}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
        <div className="modal-button create-survey-button">
          <PrimaryButton
            title={
              editing ? (
                <SpinLoader color="#fff" opacity={1} fontSize={20} />
              ) : (
                translate("pages").survey.title.continue
              )
            }
            onClick={handleSurveyEdit}
          />
        </div>
      </CreateSurveyPopup>
    </>
  );
};

export default SurveyEditorHeader;
