import React, {useContext} from 'react'
import {I18nContext} from "utils/i18n/locale";
import {LEGEND_DIRECTION, LEGEND_POSITION} from "components/molecules/Graphs";
import PieGraph, {TYPE} from "components/molecules/Graphs/Pie";

const OpenOrExpired = () => {
	const {translate} = useContext(I18nContext);

	// This should be replaced with an API Call
	const DATA = [
		{
			"id": "open",
			"label": 'Open',
			"value": 0,
			"color": "#00B999"
		},
		{
			"id": "expired",
			"label": 'Expired',
			"value": 100,
			"color": "#C43A5A"
		}
	]

	return (
		<PieGraph
			height="100%"
			type={TYPE.FULL}
			legendDirection={LEGEND_DIRECTION.ROW}
			legendPosition={LEGEND_POSITION.BOTTOM}
			reverse={true}
			title={translate("pages").analytic.editor.sidePane.menu.openOrExpired}
			data={DATA} />
	)
}

export default OpenOrExpired;
