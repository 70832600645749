import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col } from "antd";
import "./InviteUserForm.scss";
import BottomDrawer from "components/atoms/bottom-drawer";
import { I18nContext } from "utils/i18n/locale";
import { DataInput } from "components/atoms/inputs";
import { PrimaryButton } from "components/atoms/controls";
import { emailRegex } from "utils/common";
import { useDispatch, useSelector } from "react-redux";
import { inviteUser } from "redux/slices/projects";
import { SwalAlerts } from "components/atoms/outputs";
import { unwrapResult } from "@reduxjs/toolkit";
import { CheckCircleTwoTone } from "@ant-design/icons";

const ActionFooter = ({ onSubmit, translate, loading, success, restart }) => {
  return (
    <Row justify="end" align="middle" gutter={2}>
      <PrimaryButton
        loading={loading}
        onClick={!success ? onSubmit : restart}
        htmlType="submit"
        cssClassName="save-btn"
        title={
          !success
            ? translate("pages").users.sendInvite
            : translate("pages").users.gotIt
        }
      />
    </Row>
  );
};

const InviteUserForm = ({ show, onClose, isEdit, preData }) => {
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const loading = useSelector((state) => state.projects.inviteLoading);

  const [email, setEmail] = useState("");

  const [showSuccess, setShowSucces] = useState(false);

  const restart = () => {
    onClose();
    setShowSucces(false);
  };

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (data) => {
    const worker = {
      email,
    };

    console.log(worker);

    dispatch(inviteUser(worker))
      .then(unwrapResult)
      .then((data) => {
        console.log(data, "success");

        form.resetFields();
        setShowSucces(true);
      })
      .catch((e) => {
        SwalAlerts({
          title: translate("pages").users.alerts.invite.error.title,
          text: translate("pages").users.alerts.invite.error.subtitle,
          type: "error",
        });
      });
  };

  const formItems = [
    {
      name: "email",
      rules: [{ required: true, type: "email", pattern: emailRegex }],
      placeholder: translate("pages").settings.drawer.placeholder.email,
      title: translate("pages").settings.drawer.title.email,
    },
  ];

  return (
    <BottomDrawer
      isSmall
      className="bottom-smaller"
      footer={
        <ActionFooter
          success={showSuccess}
          loading={loading}
          translate={translate}
          restart={restart}
          onSubmit={(e) => {
            formRef.current.submit();
            formRef.current
              .validateFields()
              .then(() => {
                onFinish();
              })
              .catch((err) => {
                console.error(err, "Missing fields");
              });
          }}
        />
      }
      onClose={() => {
        restart();
      }}
      open={show}
      title={
        showSuccess ? (
          <span>
            {" "}
            <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
            {translate("pages").users.inviteSent}
          </span>
        ) : (
          translate("pages").users.inviteNewUser.toUpperCase()
        )
      }
    >
      {!showSuccess ? (
        <Form
          name="worker"
          form={form}
          className="flag-form"
          ref={formRef}
          onValuesChange={({ email }) => {
            email && setEmail(email);
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {formItems.map((formItem, i) => (
            <Form.Item key={i} name={formItem.name} rules={formItem.rules}>
              <DataInput
                placeholder={formItem.placeholder}
                title={formItem.title}
                allowClear
              />
            </Form.Item>
          ))}
        </Form>
      ) : (
        <span className="invite-sent">
          <b>{email}</b> {translate("pages").users.inviteTitle}
        </span>
      )}
    </BottomDrawer>
  );
};

export default InviteUserForm;
