import React, { useContext, useEffect } from "react";
import { I18nContext } from "utils/i18n/locale";
import Modal, { TYPE_DANGER } from "components/atoms/modal";
import "./RejectFlagModal.scss";
import { DataInput } from "components/atoms/inputs";
import { Form } from "antd";
import { Select } from "components/atoms/select";
import { useSelector, useDispatch } from "react-redux";
import { rejectFlag } from "redux/slices/flags";
import { SwalAlerts } from "components/atoms/outputs";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

const RejectFlagModal = ({ data, ...props }) => {
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const params = useParams();

  const loadingAction = useSelector((state) => state.flags.loadingAction);
  const flagRejected = useSelector((state) => state.flags.flagRejected);
  const flagRejectFailed = useSelector((state) => state.flags.flagRejectFailed);

  const onFinishFailed = (error) => {};

  const onFinish = (value) => {
    dispatch(rejectFlag({ id: params.id ?? data, ...value }))
      .then(unwrapResult)
      .then(() => {
        SwalAlerts({
          title: translate("pages").flags.flagDetails.modal.rejectSuccess.title,
          text: translate("pages").flags.flagDetails.modal.rejectSuccess.text,
          type: "success",
        });
        props.onCancel();
      })
      .catch(() => {
        SwalAlerts({
          title: translate("pages").flags.flagDetails.modal.rejectFailed.title,
          text: translate("pages").flags.flagDetails.modal.rejectFailed.text,
          type: "error",
        });
      });
  };

  return (
    <Modal
      className="flag-reject-modal"
      data={data}
      showCloseIcon={false}
      type={TYPE_DANGER}
      okText={translate("pages").flags.flagDetails.reject}
      title={translate("pages").flags.flagDetails.rejectFlag}
      okButtonProps={{
        form: "reject-flag-form",
        key: "submit",
        htmlType: "submit",
        loading: loadingAction,
      }}
      {...props}
    >
      {data?.category}
      <Form
        id="reject-flag-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="reject_reason"
          rules={[
            {
              required: true,
              message: translate("pages").flags.flagDetails.form.reject.reason,
            },
          ]}
        >
          <DataInput
            className="reject-reason-input"
            placeholder={translate("pages").flags.flagDetails.reason}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default RejectFlagModal;
