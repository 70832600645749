import {
  Button as AntButton,
  Col,
  Dropdown as AntDropdown,
  Menu,
  Row,
} from "antd";
import {
  EllipsisOutlined,
  MenuOutlined,
  PlusOutlined,
  TableOutlined,
} from "@ant-design/icons";
import "./controls.scss";
import { useState, useContext, useEffect } from "react";
import { I18nContext } from "utils/i18n/locale";
import { Text } from "../texts";
import { GridIcon } from "../icons";
import { ListViewIcon } from "../icons";
import { getLocalSettings } from "utils/common";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

export const PRIMARY_COLOR = "primary";
export const SECONDARY_COLOR = "secondary";
export const ACTION_COLOR = "action";
export const TERTIARY_COLOR = "tertiary";

const COLOR_CLASSES = {
  [PRIMARY_COLOR]: "primary-button",
  [SECONDARY_COLOR]: "secondary-button",
  [ACTION_COLOR]: "action-button",
  [TERTIARY_COLOR]: "tertiary-button",
};
// const settings = getLocalSettings();

export const PrimaryButton = ({
  style,
  title,
  loading,
  cssClassName = "",
  color = "primary",
  ...props
}) => {
  const location = useLocation();
  const userSettings = useSelector((state) => state.projects.settings);
  const [settings, setSettings] = useState(userSettings);
  useEffect(() => {
    setSettings(userSettings);
  }, [userSettings]);
  return (
    <AntButton
      loading={loading}
      {...props}
      style={
        location.search
          ? { backgroundColor: "#003F4F", borderColor: "#003F4F" }
          : {
              backgroundColor: settings?.sibebar_color ?? "black",
              borderColor: settings?.sibebar_color ?? "black",
              ...style,
            }
      }
      type="primary"
      className={`${
        COLOR_CLASSES[color] ?? COLOR_CLASSES[[PRIMARY_COLOR]]
      } ${cssClassName}`}
    >
      {title}
    </AntButton>
  );
};

export const SwitchButton = ({ handleView, leftIcon, rightIcon }) => {
  const [selectLeft, setSelectLeft] = useState("selected");
  const [selectRight, setSelectRight] = useState("unselected");
  const userSettings = useSelector((state) => state.projects.settings);
  const [settings, setSettings] = useState(userSettings);
  useEffect(() => {
    setSettings(userSettings);
  }, [userSettings]);

  return (
    <Row align="middle" justify="start" className="swich-button-container">
      <Col
        className={`switch-button left ${selectLeft}`}
        style={{
          backgroundColor:
            selectLeft === "selected" ? settings?.sibebar_color ?? "black" : "",
        }}
        onClick={() => {
          setSelectLeft("selected");
          setSelectRight("unselected");
          handleView("cards");
        }}
      >
        <GridIcon
          color={selectLeft === "selected" ? "white" : settings?.sibebar_color}
        />
      </Col>
      <Col
        className={`switch-button right ${selectRight}`}
        style={{
          backgroundColor:
            selectRight === "selected"
              ? settings?.sibebar_color ?? "black"
              : "",
        }}
        onClick={() => {
          setSelectLeft("unselected");
          setSelectRight("selected");
          handleView("list");
        }}
      >
        <ListViewIcon
          color={selectRight === "selected" ? "white" : settings?.sibebar_color}
        />
      </Col>
    </Row>
  );
};

const defaultDropdownChildren = (
  <EllipsisOutlined
    key="ellipsis"
    rotate={90}
    style={{
      fontSize: 20,
      verticalAlign: "top",
    }}
    onClick={(e) => e.stopPropagation()}
  />
);

export const Dropdown = ({
  menu,
  children = defaultDropdownChildren,
  trigger,
  getPopupContainer,
  placement = "bottomRight",
  disabled,
}) => {
  return (
    <AntDropdown
      getPopupContainer={getPopupContainer}
      key="more"
      trigger={trigger}
      overlay={menu}
      placement={placement}
      disabled={disabled}
    >
      {children}
    </AntDropdown>
  );
};

export const DropdownInvisible = ({ menu }) => {
  return <AntDropdown key="more" overlay={menu} />;
};

export const CrudOptionsMenu = (props) => {
  const { item } = props;
  const { handleEdit, handleDelete, handleCopy, handlePublish } = props.props;
  const { translate } = useContext(I18nContext);
  const menu = (
    <Menu style={{ width: "200px", marginLeft: "-175px" }}>
      <Menu.Item key="edit">
        <div
          className="menu-item-list"
          onClick={() => {
            handleEdit(item);
          }}
        >
          {translate("pages").feed.news.menu.edit}
        </div>
      </Menu.Item>
      <Menu.Item key="publish">
        <div
          className="menu-item-list"
          onClick={() => {
            handlePublish(item.id);
          }}
        >
          {translate("pages").feed.news.menu.publish}
        </div>
      </Menu.Item>
      <Menu.Item key="copy">
        <div
          className="menu-item-list"
          onClick={() => {
            handleCopy(item);
          }}
        >
          {translate("pages").feed.news.menu.duplicate}
        </div>
      </Menu.Item>
      <Menu.Item key="delete">
        <div
          className="menu-item-list delete-text"
          onClick={() => {
            handleDelete(item.id);
          }}
        >
          {translate("pages").feed.news.menu.delete}
        </div>
      </Menu.Item>
    </Menu>
  );
  return menu;
};

export const RoundedButton = ({ loading, title, text, ...props }) => {
  const userSettings = useSelector((state) => state.projects.settings);
  const [settings, setSettings] = useState(userSettings);
  useEffect(() => {
    setSettings(userSettings);
  }, [userSettings]);
  return (
    <Row justify="start" align="middle" gutter={[40, 0]}>
      <Col span={1}>
        <AntButton
          className="rounded-button"
          style={{
            backgroundColor: settings?.sibebar_color ?? "black",
            borderColor: settings?.sibebar_color ?? "black",
          }}
          loading={loading}
          {...props}
          type="primary"
          icon={<PlusOutlined />}
        >
          {title}
        </AntButton>
      </Col>
      <Col span={15}>
        <Text text={text} />
      </Col>
    </Row>
  );
};
