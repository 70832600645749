import React, {useEffect, useState, useContext} from 'react';
import "./Events.scss";
import EventsHeader from "client/components/organisms/EventsHeader";
import EventsEmpty from 'client/components/molecules/EventsEmpty';
import EventsTab from 'client/components/molecules/EventsTab';
import EventsDatePicker from 'client/components/molecules/EventsDatePicker';
import { I18nContext } from "utils/i18n/locale";
import ProvideInput from 'components/client/components/organisms/ProvideInput'

const Events = () => {
  
  const { translate } = useContext(I18nContext)

  const [eventsList, setEventsList] = useState([]);
  const [chooseDate, setChooseDate] = useState(false);
  const [eventFilter, setEventFilter] = useState('all');
  const [date, setDate] = useState([]);
  const [calendarHeader, setCalendarHeader] = useState(translate("client").pages.events.calendar.month)

  useEffect(() => {
    getEvents()
  }, [eventFilter])

  const getEvents = () => {
    if(eventFilter === 'all'){
      setEventsList([
        {
          id:1,
          type: "Virtual Meeting",
          topic: "Accessing the waterfront",
          timing: "Tommorow 5 pm - 10 pm",
          snooze: false,
          notify: false
        },
        {
          id:2,
          type: "Virtual Meeting",
          topic: "Accessing the waterfront",
          timing: "Tommorow 5 pm - 10 pm",
          snooze: true,
          notify: false
        },
        {
          id:3,
          type: "Virtual Meeting",
          topic: "Accessing the waterfront",
          timing: "Tommorow 5 pm - 10 pm",
          snooze: false,
          notify: true
        }
      ]
      )
    }
    else{
      setEventsList([
        {
          id:1,
          type: "My Meeting",
          topic: "dfdfd the waterfront",
          timing: "Tommorow 5 pm - 10 pm",
          snooze: false,
          notify: false
        },
        {
          id:2,
          type: "My Meeting",
          topic: "sdfdf the waterfront",
          timing: "Tommorow 5 pm - 10 pm",
          snooze: true,
          notify: false
        },
        {
          id:3,
          type: "My Meeting",
          topic: "Accessing the waterfront",
          timing: "Tommorow 5 pm - 10 pm",
          snooze: false,
          notify: true
        }
      ])
    }
  }

  return(
    <div className="events-container">
      {chooseDate ? <EventsDatePicker state={date} visible={chooseDate} setVisible={setChooseDate} setState={setDate} setHeader={setCalendarHeader} />:  null}
      <EventsHeader calendarHeader={calendarHeader} eventsList={eventsList} setChooseDate={setChooseDate} />
      {eventsList.length ? 
        <>
          <EventsTab eventsList={eventsList} eventFilter={eventFilter} setEventFilter={setEventFilter} />
          <div className="provide-input">
              <ProvideInput />
          </div>
        </>
        : 
        <EventsEmpty />
      }
    </div>
  )
}

export default Events;
