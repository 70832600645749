import Surveys from "pages/Surveys";
import SurveyGallery from "pages/Surveys/SurveyGallery";
import SurveyEditor from "pages/SurveyEditor";

const prefix = "/surveys";

const routes = [
  {
    path: `${prefix}`,
    component: Surveys,
    exact: true,
  },
  {
    path: `${prefix}/survey-gallery`,
    component: SurveyGallery,
    exact: true,
  },
  {
    path: `${prefix}`,
    component: SurveyEditor,
  },
];

export default routes;
