import { SearchOutlined } from "@ant-design/icons";
import { StandardInput } from "components/atoms/inputs";
import { Text, SearchText } from "components/atoms/texts";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { I18nContext } from "utils/i18n/locale";
import Highlighter from "react-highlight-words";
import "./NavbarSearch.scss";

const NavbarSearch = () => {
    const { translate } = useContext(I18nContext);
    const categories = [
        { title: "See Events", description: "Events", path: "events" },
        { title: "Create Event", description: "Events", path: "events/manage" },
        { title: "See News", description: "Feed", path: "feed" },
        { title: "Settings", description: "Settings", path: "settings" },
        { title: "My profile", description: "Profile", path: "profile" },
        {
            title: "Explore Knowledgebase",
            description: "Knowledgebase",
            path: "knowledge",
        },
        {
            title: "Add Knowledge Post",
            description: "Knowledgebase",
            path: "knowledge/post-editor",
        },
        {
            title: "My Dashboard",
            description: "Dashboard",
            path: "analytics/demo",
        },
        { title: "Explore Surveys", description: "Surveys", path: "surveys" },
        {
            title: "Add New Survey",
            description: "Surveys",
            path: "surveys/survey-gallery",
        },
        {
            title: "My Inbox",
            description: "Communications",
            path: "communications",
        },
        { title: "Users Engaged", description: "Users", path: "user-list" },
        { title: "My Team", description: "Settings", path: "settings/users" },
        {
            title: "Explore Project Categories",
            description: "Settings",
            path: "settings/categories",
        },
        {
            title: "See Notifications",
            description: "Notifications",
            path: "notifications",
        },
        {
            title: "Create Notification",
            description: "Notifications",
            path: "notifications/manage",
        },
        {
            title: "Check Flags",
            description: "Flags",
            path: "flags",
        },
    ];

    const cleanWord = () => {
        setSearchWord("");
        setFilteredPosts([]);
    };
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [searchWord, setSearchWord] = useState("");
    let counter = 0;
    const onChange = (e) => {
        setSearchWord(e.target.value);
        if (e.target.value !== "") {
            setSearchWord(e.target.value);
            const filteredData = categories.filter((category) =>
                category.title
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            );
            console.log({ filteredData });
            setFilteredPosts(filteredData);
        } else {
            setFilteredPosts([]);
        }
    };
    return (
        <div className="navbar-search-box">
            <StandardInput
                prefixIcon={<SearchOutlined />}
                placeholder={"Search in the CRM"}
                value={searchWord}
                onChange={onChange}
            />

            <div className="search-results">
                {filteredPosts?.map(({ title, description, path }) => (
                    <SearchResultCard
                        title={title}
                        description={description}
                        searchWord={searchWord}
                        path={path}
                        clean={cleanWord}
                    />
                ))}
                {/* {filteredPosts?.map(({ searchedPosts, category }) => {
                    if (searchedPosts?.length <= 0) {
                        counter++;
                    }
                })}
                {counter !== 0 && counter === filteredPosts.length && (
                    <div className="search-no-result-card">
                        <span>
                            {
                                translate("pages").knowledge.placeholder
                                    .noResultsFound
                            }
                        </span>
                    </div>
                )} */}
            </div>
        </div>
    );
};

const SearchResultCard = ({ title, description, searchWord, path, clean }) => {
    const history = useHistory();
    return (
        <div
            className="search-result-card"
            onClick={() => {
                history.push(`/home/${path}`);
                clean();
            }}
        >
            <Highlighter
                highlightClassName="search-title-highlighted"
                highlightTag="span"
                // highlightStyle={{fontWeight:"bold", letterSpacing:.1 }}
                className="search-title"
                searchWords={[searchWord]}
                autoEscape={true}
                textToHighlight={title}
            />
            {/* <SearchText
                // innerHTML={{ __html: formattedTitle }}
                isHighlight
                Highlight={
                    <Highlighter
                        // highlightClassName="YourHighlightClass"
                        searchWords={[searchWord]}
                        autoEscape={true}
                        textToHighlight={title}
                    />
                }
                className="search-title"
            /> */}
            <Text text={description} className="search-category" />
        </div>
    );
};
export default NavbarSearch;
