import ResourcesPage from "client/pages/Resources";
import ResourcesDetail from "client/pages/ResourcesDetail";

const prefix = "/launchsa";

const routes = [
  {
    path: `${prefix}/resources`,
    component: ResourcesPage,
    exact: true,
  },{
    path: `${prefix}/resourceDetail`,
    component: ResourcesDetail,
    exact: true,
  },
];

export default routes;
