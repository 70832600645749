import React from "react";
import "./map.scss";
import {GoogleApiWrapper, Map as GMap, Marker, HeatMap, Polygon} from "google-maps-react";
import mapPin from "assets/img/map-pin.svg";
import styles from './styles.json';

const CustomMap = ({ location, showPin=true, className = "", google, zoom=17, mapTypeControl=true, heatPoints, height='400px',center, customPin=mapPin }) => {

  const _mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles,
    })
  }
 const triangleCoords = [
   { lat: 6.467955799999999, lng: 3.5637301 },
   { lat: 5.4679557999999, lng: 3.5637 },
   { lat: 6.4679557, lng: 3.563 },
 ];
  return (
    <GMap
      className={`irys-map ${className}`}
      google={window.google}
      zoom={zoom}
      initialCenter={location}
      containerStyle={{ height, position: "relative" }}
      streetViewControl={false}
      mapTypeControl={mapTypeControl}
      fullscreenControl={false}
      heatmapLibrary={true}
      heatmap={heatPoints}
      zoomControlOptions={{
        position: window.google.maps.ControlPosition.RIGHT_TOP,
      }}
      onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
      center={center}
    >
      {heatPoints && (
        <HeatMap
          gradient={["rgba(255, 255, 255, 0)", "rgba(117,250,207,0.4)"]}
          positions={heatPoints.positions}
          {...heatPoints.options}
        />
      )}
      {showPin && (
        <Marker
          position={location}
          icon={{
            url: customPin,
            anchor: new window.google.maps.Point(32, 32),
            scaledSize: new window.google.maps.Size(64, 64),
          }}
        />
      )}
      <Polygon
        paths={triangleCoords}
        strokeColor="#0000FF"
        strokeOpacity={0.8}
        strokeWeight={2}
        fillColor="#0000FF"
        fillOpacity={0.35}
      />
    </GMap>
  );
}

const Map = GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_API_KEY, libraries: ["visualization"] })(CustomMap)

export default CustomMap;
