import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
} from "react";
import { SpiningPage } from "components/atoms/icons";
import { Alert } from "components/atoms/outputs";
import { I18nContext } from "utils/i18n/locale";
import { verifyRecoveryCode } from "api/amplifyAuthAPI";
import { useHistory, useLocation } from "react-router";
const AuthTemplate = lazy(() => import("components/templates/AuthTemplate"));

const renderLoader = () => <SpiningPage opacity={1} />;

const Verify = (props) => {
  const { email } = props.location;
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);
  const isMounted = useRef(null);
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    if (!email) {
      history.push({ pathname: "/recover", search: location?.search });
    }
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  const changeStatusLoading = (statusBoolean) => {
    setLoading(statusBoolean);
  };

  const handleVerify = (values) => {
    changeStatusLoading(true);
    verifyRecoveryCode(email, values)
      .then(() => {
        Alert("success", `${translate("pages").verify.messages.verifysuccess}`);
        history.push({ pathname: "/login", search: location?.search });
        changeStatusLoading(false);
      })
      .catch((err) => {
        console.log(err, "err");
        Alert("error", translate("pages").verify.messages.verifyerror);
        changeStatusLoading(false);
      })
      .finally(() => {
        if (isMounted.current) {
          changeStatusLoading(false);
        }
      });
  };
  return (
    <Suspense fallback={renderLoader()}>
      <AuthTemplate handleVerify={handleVerify} loading={loading} verify />
    </Suspense>
  );
};
export default Verify;
