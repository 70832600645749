import React, { Suspense, useContext, useEffect, useState } from "react";
import "./details.scss";
import { SpiningPage } from "components/atoms/icons";
import FlagDetailsOrganism from "components/organisms/FlagDetailsOrganism";
import FlagCommentsOrganism from "components/organisms/FlagCommentsOrganism";
import CreateFlagComment from "components/organisms/CreateFlagComment";
import FlagsTemplate from "components/templates/Flags";
import { I18nContext } from "utils/i18n/locale";
import { PrimaryButton, SECONDARY_COLOR } from "components/atoms/controls";
import Modal, { TYPE_SUCCESS } from "components/atoms/modal";
import RejectFlagModal from "components/organisms/RejectFlagModal";
import ApproveFlagModal from "components/organisms/ApproveFlagModal";
import { useDispatch, useSelector } from "react-redux";
import { getFlag, getAssignedUsers, getDepartments } from "redux/slices/flags";
import LoadErrorLayout from "components/molecules/LoadErrorLayout";
import { getCurrentProjectId } from "utils/common";
import { useParams } from "react-router-dom";

const renderLoader = () => <SpiningPage opacity={1} />;

const Actions = ({ openReject, openApprove }) => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="details-actions-container">
      <PrimaryButton
        onClick={openReject}
        cssClassName="action-buttons"
        title={translate("pages").flags.flagDetails.reject}
        color={SECONDARY_COLOR}
      />
      <PrimaryButton
        onClick={openApprove}
        cssClassName="action-buttons"
        title={translate("pages").flags.flagDetails.approve}
      />
    </div>
  );
};

const Details = (props) => {
  const flagDetail = useSelector((state) => state.flags.flag);
  const assignedUsers = useSelector((state) => state.flags.assignedUsers);
  const departments = useSelector((state) => state.flags.departments);
  const loading = useSelector((state) => state.flags.loading);
  const loadingError = useSelector((state) => state.flags.loadingError);

  const { id } = useParams();

  const dispatch = useDispatch();

  const { translate } = useContext(I18nContext);
  const [mentionUsersOptions] = useState([
    {
      name: "Hashir Baig",
      value: "imhashir",
      image: `https://i.pravatar.cc/60?u=${Math.random() * 100}`,
    },
    {
      name: "Julio Piubelo",
      value: "julio",
      image: `https://i.pravatar.cc/60?u=${Math.random() * 100}`,
    },
    {
      name: "Osama Riaz",
      value: "oriaz",
      image: `https://i.pravatar.cc/60?u=${Math.random() * 100}`,
    },
  ]);
  const [, setComment] = useState();
  const [openReject, setOnOpenReject] = useState(false);
  const [openApprove, setOnOpenApprove] = useState(false);

  const flag = id;

  const fetchFlag = () => {
    // const id = props.match.params.id;
    dispatch(getFlag(id));
  };

  let flagDetailContent;

  if (loading) {
    flagDetailContent = <SpiningPage opacity={1} />;
  } else if (flagDetail) {
    flagDetailContent = (
      <>
        <FlagsTemplate
          className="flag-details-root"
          action={
            <Actions
              openApprove={() => setOnOpenApprove(true)}
              openReject={() => setOnOpenReject(true)}
            />
          }
        >
          <FlagDetailsOrganism {...flagDetail} />
          {/* TODO: handle functions to add comments  */}
          {/* <FlagCommentsOrganism />
          <CreateFlagComment
            options={mentionUsersOptions}
            onSubmit={setComment}
          /> */}
        </FlagsTemplate>
        <RejectFlagModal
          data={flag}
          onCancel={() => setOnOpenReject(false)}
          visible={openReject}
        />
        <ApproveFlagModal
          data={flag}
          onCancel={() => setOnOpenApprove(false)}
          departments={departments}
          assignedUsers={assignedUsers}
          visible={openApprove}
        />
      </>
    );
  } else if (loadingError) {
    flagDetailContent = <LoadErrorLayout onReload={fetchFlag} />;
  }

  useEffect(() => {
    fetchFlag();
  }, []);

  useEffect(() => {
    console.log(flagDetail, flagDetail?.typename, "flag detail");
    if (flagDetail && flagDetail?.typename) {
      dispatch(getDepartments(getCurrentProjectId()));
      dispatch(getAssignedUsers(getCurrentProjectId()));
    }
  }, [flagDetail?.typename?.project_id]);

  return <Suspense fallback={renderLoader()}>{flagDetailContent}</Suspense>;
};

export default Details;
