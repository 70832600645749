import React, { useContext, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Text } from "components/atoms/texts";
import SurveyCardDropdown from "components/molecules/SurveyCardDropdown";
import "./SurveyListItem.scss";
import { dateTimeFormat } from "utils/common";
import { useHistory } from "react-router-dom";
import { QuestionContext } from "context/SurveyEditorContext";

const SurveyListItem = ({
  data,
  currentIndex,
  dropdownOptions,
  setCurrentIndex,
  index,
}) => {
  const [show, setShow] = useState(false);
  const { setSurveyTitle, setSurveyId } = useContext(QuestionContext);
  const history = useHistory();
  const handleClick = (index) => {
    setShow(show && index !== currentIndex ? show : !show);
    setCurrentIndex(index);
  };
  const handleSurveyClick = (title, id) => {
    setSurveyTitle(title);
    setSurveyId(id);
    history.push({
      pathname: `/home/surveys/editor/builder/${id}`,
      state: { surveyTitle: title, surveyId: id },
    });
    // history.push(`/surveys/builder/${id}`);
  };
  return (
    <Row className="survey-list-item">
      <Col
        xs={10}
        lg={8}
        onClick={() => handleSurveyClick(data.title, data.id)}
      >
        <Text text={data.title} className="list-item-title" />
      </Col>
      <Col xs={0} lg={7} className="list-item-wrapper">
        <Text
          text={dateTimeFormat(data.created_at)}
          className="list-item-text"
        />
      </Col>
      <Col xs={10} lg={7} className="list-item-wrapper">
        <Text
          text={dateTimeFormat(data.created_at)}
          className="list-item-text"
        />
      </Col>
      <Col xs={2} lg={1} offset={1}>
        <div className="list-icon">
          <MoreOutlined onClick={() => handleClick(index)} />
          {show && currentIndex === index && (
            <div className="survey-card-dropdown">
              <SurveyCardDropdown
                options={dropdownOptions}
                id={data.id}
                setShow={setShow}
              />
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default SurveyListItem;
