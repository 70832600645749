import { PrimaryButton } from "components/atoms/controls";
import { Title } from "components/atoms/texts";
import { Text } from "components/atoms/texts";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { I18nContext } from "utils/i18n/locale";
import emptyUserSvg from "assets/img/empty-user.svg";
import "./EmptyUserCard.scss";
const EmptyUserCard = ({ className, openInvite }) => {
  const { translate } = useContext(I18nContext);
  return (
    <div className="empty-card-container">
      <img
        className="placeholder-icon"
        src={emptyUserSvg}
        alt={translate("pages").users.placeholder.noUser}
      />

      <Title level={3} text={translate("pages").users.placeholder.noUser} />

      <Text
        className="no-user-text"
        text={translate("pages").users.placeholder.noUserMessage}
      />

      <PrimaryButton
        title={translate("pages").users.placeholder.noUserBtn}
        cssClassName="no-user-button"
        onClick={() => openInvite()}
      />
    </div>
  );
};

export default EmptyUserCard;
