import React from "react";
import { Tabs } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { SpiningPageSmall } from "../icons";

const { TabPane } = Tabs;

const ParentTabsMenu = ({
  onChange,
  options,
  variant = "default",
  activeKey,
  defaultActiveKey,
  baseRoute = null,
  isSurvey,
  loading,
}) => {
  const urlSplit = window.location.href.split("/");
  const urlId = urlSplit[urlSplit.length - 1];
  return baseRoute ? (
    <Router>
      <Route
        path={`/${baseRoute}/:tab?`}
        render={({ match, history }) => {
          return (
            <Switch>
              <Tabs
                hideAdd
                className={[
                  variant === "expanded" && "expanded-tabs",
                  "tab-ops-hidden",
                ]}
                defaultActiveKey={match.params.tab || options[0]?.key}
                onChange={(key) => {
                  onChange(key);
                  history.push(`/${baseRoute}/${key}`);
                }}
                // onChange={(key) => }
              >
                {options.map(({ title, key, children }, index) => (
                  <TabPane tab={title} key={key || index.toString()}>
                    {children}
                  </TabPane>
                ))}
              </Tabs>
            </Switch>
          );
        }}
      />
    </Router>
  ) : (
    <Tabs
      hideAdd
      className={[variant === "expanded" && "expanded-tabs", "tab-ops-hidden"]}
      activeKey={activeKey}
      defaultActiveKey={
        isSurvey ? `${defaultActiveKey}/${urlId}` : defaultActiveKey
      }
      onChange={onChange}
    >
      {options.map(({ title, key, children, id }, index) => (
        <TabPane
          tab={title}
          key={isSurvey ? `${key}/${id ?? urlId}` : key || index.toString()}
        >
          <div className="email-list-container">
            {loading ? (
              <div
                style={{
                  maxHeight: "200px",
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
              >
                {" "}
                <SpiningPageSmall opacity={1} />
              </div>
            ) : (
              children
            )}
          </div>
        </TabPane>
      ))}
    </Tabs>
  );
};

export default ParentTabsMenu;
