import React, {useContext} from 'react';
import "./fab.scss";
import {Text} from "components/atoms/texts";
import {I18nContext} from "utils/i18n/locale";

const Fab = ({icon, onClick, isOpen}) => {
  const {translate} = useContext(I18nContext);

  return (
    <div className="fab-flex">
      <div className={`base-container ${!isOpen ? "container-large background-close" : "container-normal background-open"}`}
        onClick={onClick}
        >
        <div className="child-container">
          {icon?.()}
          {!isOpen && <Text text={translate("client").pages.home.PROVIDE_INPUT} className="provide-input-text" />}
        </div>
      </div>
    </div>
)};

export default Fab;
