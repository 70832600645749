import React from "react";
import Layout from "antd/lib/layout/layout";
import UserDetailNav from "components/molecules/UserDetailNav";
import { Divider } from "antd";
import ProfileInfoForm from "components/organisms/ProfileInfoForm";
import { withRouter } from "react-router-dom";

const UserDetailOrganism = ({history: {push}}) => {
  return (
    <>
      <UserDetailNav onBack={() => push('/settings/users')} />
      <Divider />
      <Layout flex="auto" className="user-list-layout">
        <ProfileInfoForm />
      </Layout>
    </>
  );
};

export default withRouter(UserDetailOrganism);
