import React, { useContext } from "react";
import { I18nContext } from "utils/i18n/locale";
import Table from "components/molecules/Table";
import GraphContainer from "components/molecules/Graphs/Container";
import "./RankByZipcode.scss";

const RankByZipcode = () => {
  const { translate } = useContext(I18nContext);

  // This should be replaced with an API Call
  const DATA = Array(6).fill({
    rank: (Math.random() * (10 - 1) + 1).toFixed(0),
    // neighbourhood: "Bristol",
    zipCode: "03100",
    inputs: "3000",
  });

  return (
    <GraphContainer
      className="zipcode-table-container"
      title={translate("pages").analytics.byZipcode}
    >
      <Table
        showPagination={false}
        columns={[
          {
            title: "Rank",
            dataIndex: "rank",
          },
          //   {
          //     title: "Neighbourhood",
          //     dataIndex: "neighbourhood",
          //   },
          {
            title: "Zip Code",
            dataIndex: "zipCode",
          },
          {
            title: "Inputs",
            dataIndex: "inputs",
          },
        ]}
        items={DATA.map((item, index) => ({ ...item, key: index.toString() }))}
      />
    </GraphContainer>
  );
};

export default RankByZipcode;
