import React, {  useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./resourcesDetails.scss";
import { I18nContext } from "utils/i18n/locale";
import { useHistory, useLocation } from "react-router-dom";
import { BackArrowMobile } from "assets/svg/mobile";
import { getClientResourceById } from "redux/slices/resources";
import { Text } from "components/atoms/texts";
import LaunchSaLogo from "assets/img/launchSaLogo.png";
import Linkify from "react-linkify";
import { cloudFrontUrl } from "utils/common";

const ResourcesDetail = () => {
  const history = useHistory();
  const historyLocation = useLocation();
  const dispatch = useDispatch();

  const resourceFetched = useSelector((state) => state.resources.resource);
  const [resource, setResource] = useState(resourceFetched);

  const [resourceId, setResourceId] = useState(
    historyLocation?.state?.resourceId ?? 0
  );
  useEffect(() => {
    setResource(resourceFetched);
  }, [resourceFetched]);
  useEffect(() => {
    console.log("resourceId", resourceId);
    if (resourceId != 0) {
      console.log("resourceId", resourceId);
      dispatch(getClientResourceById(resourceId));
    }
  }, [resourceId]);

  return (
    <div id="client-home" className="client-home-container">
      {resource && (
        <div
          className="header-resources"
          style={{
            backgroundImage: `url(${
              resource?.image ? cloudFrontUrl(resource.image) : LaunchSaLogo
            })`,
          }}
        >
          <BackArrowMobile onClick={() => history.goBack()} />
        </div>
      )}

      <div className="left-all-child ">
        <div>
          <Text
            text={resource?.title}
            style={{ fontWeight: 500, fontSize: 20 }}
          />
        </div>
        <Text
          text={
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {resource?.description}
            </Linkify>
          }
          className="resource-content"
        />

        <div
          className="about-container"
          onClick={() => {
            window.open(resource ? resource.website_link : "", "_blank");
          }}
        >
          {resource && resource.website_link ? (
            <div>
              <Text text={"Website"} className="title" />
              <Text
                text={resource ? resource.website_link : ""}
                className="resource-url"
              />
            </div>
          ) : null}

          {resource && resource.more_websites ? (
            <div>
              {resource.more_websites.map((website) => {
                return (
                  <div>
                    <Text text={website.title} className="title" />
                    <Text text={website.url} className="resource-url" />
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ResourcesDetail);
