import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Col } from "antd";
import "./UserListForm.scss";
import { Select } from "components/atoms/select";
import BottomDrawer from "components/atoms/bottom-drawer";
import { I18nContext } from "utils/i18n/locale";
import { DataInput } from "components/atoms/inputs";
import Switch from "components/atoms/switch";
import { Text } from "components/atoms/texts";
import { PrimaryButton } from "components/atoms/controls";
import { emailRegex, getCurrentOrganizationId, phoneRegex } from "utils/common";
import { useDispatch, useSelector } from "react-redux";
import {
  createProjectWorker,
  getProjectDepartments,
  getProjectRoles,
  getProjectUsers,
} from "redux/slices/projects";
import { SwalAlerts } from "components/atoms/outputs";
import { unwrapResult } from "@reduxjs/toolkit";

const ActionFooter = ({ onSubmit, translate }) => {
  return (
    <Row justify="end" align="middle" gutter={2}>
      <PrimaryButton
        onClick={onSubmit}
        htmlType="submit"
        cssClassName="save-btn"
        title={translate("pages").settings.drawer.save}
      />
    </Row>
  );
};

const UserListForm = ({ show, onClose, isEdit, preData }) => {
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const departmentsFetched = useSelector((state) => state.projects.departments);
  const rolesFetched = useSelector((state) => state.projects.roles);

  useEffect(() => {
    dispatch(getProjectDepartments());
    dispatch(getProjectRoles());
  }, []);

  const [departments, setDeparments] = useState(departmentsFetched);
  const [roles, setRoles] = useState(rolesFetched);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [department, setDepartment] = useState("");
  const [sendSms, setSendSms] = useState(false);

  const [initialValues, setInitialValues] = useState(preData);

  useEffect(() => {
    setDeparments(departmentsFetched);
    // console.log("Departments fetched: ", departmentsFetched);
  }, [departmentsFetched]);

  useEffect(() => {
    setRoles(rolesFetched);
    // console.log("Roles fetched: ", rolesFetched);
  }, [rolesFetched]);

  useEffect(() => {
    setInitialValues(preData);
  }, [preData]);

  useEffect(() => {
    // console.log("Cambio en initialValues: ", initialValues);
    if (!initialValues && formRef.current) {
      // console.log("Form: ", formRef.current);
      formRef?.current?.resetFields();
    }
  }, [initialValues]);

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (data) => {
    const worker = {
      name: firstName,
      last_name: lastName,
      email,
      phone_number: phoneNumber,
      department_id: department,
      role_id: role,
      sms: sendSms,
      organization_id: parseInt(getCurrentOrganizationId()),
    };

    dispatch(createProjectWorker(worker))
      .then(unwrapResult)
      .then(() => {
        SwalAlerts({
          title: translate("pages").projects.alerts.addProject.success.title,
          text: translate("pages").projects.alerts.addProject.success.subtitle,
          type: "success",
        });
        form.resetFields();
        onClose();
        dispatch(getProjectUsers());
      })
      .catch((e) => {
        SwalAlerts({
          title: translate("pages").projects.alerts.addProject.error.title,
          text: translate("pages").projects.alerts.addProject.error.subtitle,
          type: "error",
        });
      });
  };

  const formItems = [
    {
      name: "firstName",
      rules: [{ required: true }],
      placeholder: translate("pages").settings.drawer.placeholder.firstName,
      title: translate("pages").settings.drawer.title.firstName,
    },
    {
      name: "lastName",
      rules: [{ required: true }],
      placeholder: translate("pages").settings.drawer.placeholder.lastName,
      title: translate("pages").settings.drawer.title.lastName,
    },
    {
      name: "email",
      rules: [{ required: true, type: "email", pattern: emailRegex }],
      placeholder: translate("pages").settings.drawer.placeholder.email,
      title: translate("pages").settings.drawer.title.email,
    },
    {
      name: "phoneNumber",
      rules: [{ required: true, pattern: phoneRegex }],
      placeholder: translate("pages").settings.drawer.placeholder.phoneNumber,
      title: translate("pages").settings.drawer.title.phoneNumber,
    },
  ];

  return (
    <BottomDrawer
      // isSmall
      // className="bottom-altern"
      footer={
        <ActionFooter
          translate={translate}
          onSubmit={(e) => {
            formRef.current.submit();
            formRef.current
              .validateFields()
              .then(() => {
                onFinish();
              })
              .catch((err) => {
                console.error(err, "Missing fields");
              });
          }}
        />
      }
      onClose={onClose}
      open={show}
      title={
        isEdit
          ? translate("pages").settings.drawer.editTitle
          : translate("pages").settings.drawer.addTitle
      }
    >
      <Form
        name="worker"
        form={form}
        className="user-form"
        ref={formRef}
        onValuesChange={({
          firstName,
          lastName,
          email,
          phoneNumber,
          role,
          department,
        }) => {
          firstName && setFirstName(firstName);
          lastName && setLastName(lastName);
          email && setEmail(email);
          phoneNumber && setPhoneNumber(phoneNumber);
          role && setRole(role);
          department && setDepartment(department);
        }}
        initialValues={preData}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {formItems.map((formItem, i) => (
          <Form.Item key={i} name={formItem.name} rules={formItem.rules}>
            <DataInput
              placeholder={formItem.placeholder}
              title={formItem.title}
              allowClear
            />
          </Form.Item>
        ))}
        <Form.Item
          className="select-space"
          name="role"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            gutter={20}
            placeholder={translate("pages").settings.drawer.placeholder.role}
            title={translate("pages").settings.drawer.title.role}
            options={roles.map((rol) => {
              return { name: rol?.name, value: rol?.id };
            })}
          />
        </Form.Item>
        <Form.Item
          name="department"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            gutter={20}
            placeholder={
              translate("pages").settings.drawer.placeholder.department
            }
            title={translate("pages").settings.drawer.title.department}
            options={departments.map((department) => {
              return { name: department?.name, value: department?.id };
            })}
          />
        </Form.Item>
        <Form.Item>
          <Row gutter={0} justify="space-between">
            <Col xs={5}></Col>
            {/* {!isEdit ? (
              <Col xs={18}>
                <Row gutter={10} align="middle">
                  <Col xs={9}>
                    <Text
                      text={translate("pages").settings.drawer.title.sendSms}
                    />
                  </Col>
                  <Col xs={12}>
                    <Switch
                      active={sendSms}
                      onToggle={() => setSendSms(!sendSms)}
                    />
                  </Col>
                </Row>
              </Col>
            ) : null} */}
          </Row>
        </Form.Item>
      </Form>
    </BottomDrawer>
  );
};

export default UserListForm;
