import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { I18nContext } from "utils/i18n/locale";
import { BackArrowMobile } from "assets/svg/mobile";
import { SendMail } from "assets/svg/mobile";
import { Text } from "components/atoms/texts";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import "./createCommunication.scss";
import { DataInput, SearchInput } from "client/components/atoms/inputs";
import { StandardInput } from "components/atoms/inputs";
import {
  createEmailClient,
  handleAddressDeselection,
  handleAddressSelection,
  searchByEmailClient,
} from "redux/slices/communication/emails";
import { unwrapResult } from "@reduxjs/toolkit";
import LaunchSaLogo from "assets/img/launchSaLogo.png";
import { FileUploader } from "components/atoms/media";
import ClipLoader from "react-spinners/ClipLoader";
import { makeId } from "utils/common";
import { Storage } from "aws-amplify";

const CreateMessage = (props) => {
  const FILE_TYPES = {
    application: "document",
    image: "image",
    video: "video",
    audio: "audio",
  };
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const historyLocation = useLocation();
  const [fileList, setFileList] = useState([]);

  const [emails, setEmails] = useState([]);
  const searchedEmails = useSelector((state) => state.emails.searchedEmails);
  const selectedAddress = useSelector((state) => state.emails.selectedAddress);

  const history = useHistory();
  const [currentStyle, setCurrentStyle] = useState(null);
  const [image, setImage] = useState(null);
  const [loadingAction, setLoadingAction] = useState(false);
  const getFileType = (type) => {
    let newType = type.split("/");
    return FILE_TYPES[newType[0]];
  };
  const [state, setState] = useState({
    to: "",
    subject: "",
    message: "",
  });
  const Image = React.memo(function Image({ src, classname }) {
    const { translate } = useContext(I18nContext);
    return (
      <img
        src={src}
        className={classname}
        alt={translate("client").pages.home.LOADING}
      />
    );
  });

  useEffect(() => {
    const cs_json = JSON.parse(localStorage.getItem("custom_client"));
    if (cs_json) {
      setCurrentStyle(cs_json);
    }
  }, []);

  useEffect(() => {
    setEmails(searchedEmails);
  }, [searchedEmails]);

  const subjectChanged = (e) => {
    setState((prevState) => ({
      ...prevState,
      ["subject"]: e.target.value,
    }));
  };

  const messageChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      ["message"]: e.target.value,
    }));
  };

  const sendMessage = async () => {
    setLoadingAction(true);
    const createNewEmail = {
      subject: state.subject,
      reply_body: state.message,
      organization_id: currentStyle.organizationId,
      project_id: currentStyle.projectId,
      webapp: true,
    };
    if (fileList.length > 0) {
      const fileInfo = Promise.all(fileList.map(handleUpload)).then(
        (results) => {
          createNewEmail.attachments = results;
          dispatch(createEmailClient(createNewEmail))
            .then(unwrapResult)
            .then((data) => {
              console.log("success");
              setLoadingAction(false);
              setFileList([]);
              history.goBack();
            })
            .catch((error) => {
              setLoadingAction(false);

              console.log(error);
            });
        }
      );
    } else {
      setLoadingAction(true);
      dispatch(createEmailClient(createNewEmail))
        .then(unwrapResult)
        .then((data) => {
          console.log("success");
          setLoadingAction(false);
          setFileList([]);
          history.goBack();
        })
        .catch((error) => {
          setLoadingAction(false);

          console.log(error);
        });
    }
  };

  const handleSearch = (value) => {
    if (value) {
      const dataToSend = {
        email: value,
        project_id: currentStyle.projectId,
      };
      return dispatch(searchByEmailClient(dataToSend)).then(unwrapResult);
    } else {
      setEmails([]);
    }
  };

  const onSelect = (item) => {
    dispatch(handleAddressSelection(parseInt(item.value)));
  };
  const onDeSelect = (item) => {
    dispatch(handleAddressDeselection(parseInt(item.value)));
  };

  const handleUpload = async (file) => {
    console.log("file", file);
    const uploadName = makeId(10) + "." + file.name.split(".").pop();
    return new Promise((resolve, reject) => {
      Storage.put("attachments/" + uploadName, file, {
        contentType: file.type,
        contentDisposition: "attachment",
      }).then((result) => {
        resolve({
          type: getFileType(file.type),
          url: result.key,
          name: file.name,
          size: (file.size / 1024).toFixed(1) + " kb",
        });
      });
    });
  };

  return (
    <div id="client-home" className="client-home-container">
      <div className="mobile-header">
        <div className={"back-arrow"}>
          <BackArrowMobile onClick={() => history.goBack()} />
        </div>
        <Text
          className={"resources-title"}
          text={translate("pages").mobile.communication.compose}
          style={{ lineHeight: "70px" }}
        />
        <div className={"delete-button"}>
          {!loadingAction ? <SendMail onClick={() => sendMessage()} /> : null}
          <ClipLoader color={"#003F4F"} loading={loadingAction} size={30} />
        </div>
        <div className={"archive-button"}>
          <FileUploader
            showUploadList={true}
            fileList={fileList}
            setFileList={setFileList}
            showIcon={true}
            isInput
          />
        </div>
      </div>
      <div className="message-creation-container">
        <div className="comunication-child">
          <div>
            <div className="message-creation-horizontal">
              <Text className="communication-title" text={"To"} />

              <div className="message-creation-launchSa">
                <img src={LaunchSaLogo} className="message-creation-logo"></img>
                <Text className="message-creation-title" text={"Launch SA"} />
              </div>
              {/*
            
             <SearchInput
                isSearch
                
                onSearch={handleSearch}
                onChange={handleSearch}
                searchedItems={emails}
                onSelect={(e) => onSelect(e)}
                onDeselect={(e) => onDeSelect(e)}
              
                style={{with:'100%'}}
                allowClear
              />
            */}
            </div>
            <div className="communication-actions"></div>
            <div className="message-creation-horizontal">
              <Text className="communication-title" text={"Subject"} />
              <StandardInput
                placeholder={"Type here..."}
                className="communication-input"
                onChange={subjectChanged}
              />
            </div>
            <div className="communication-actions"></div>

            <DataInput
              type="textarea"
              rows={4}
              className="communication-text-area"
              placeholder={"Compose email..."}
              onChange={messageChange}
            />
            <div className="communication-actions"></div>
            <div style={{ marginTop: 30 }}>
              <FileUploader
                showUploadList={true}
                fileList={fileList}
                setFileList={setFileList}
                showIcon={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(CreateMessage);
