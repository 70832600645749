import api from "config/axios/axios.api.config";

const prefixEmail = "emails";

const getUserEmail = (id) => api.get(`${prefixEmail}/${id}`);

const getReceivedEmails = (id) =>
    api.get(`${prefixEmail}/projects/${id}/received`);

const getArchivedEmails = (id) =>
    api.get(`${prefixEmail}/projects/${id}/archived`);

const createEmail = (id, data) =>
    api.postJson(`${prefixEmail}/projects/${id}/`, data);

const createEmailClient = (id, data) =>
    api.postJson(`${prefixEmail}/projects/${id}?webapp=true`, data);

const createReply = (id, data) =>
    api.postJson(`${prefixEmail}/${id}/reply`, data);

const deleteEmail = (id) => api.put(`${prefixEmail}/${id}/delete`, { id });

const starrEmail = (id) => api.put(`${prefixEmail}/${id}/starred`, { id });

const archiveEmail = (id) => api.put(`${prefixEmail}/${id}/archive`, { id });

const archiveEmails = (data) => api.put(`${prefixEmail}/archive`, data);

const searchWorkerByEmail = (id, email) =>
    api.get(`workers/search/projects/${id}?email=${email}`);

export default {
    getUserEmail,
    getReceivedEmails,
    getArchivedEmails,
    searchWorkerByEmail,
    createEmail,
    deleteEmail,
    starrEmail,
    archiveEmail,
    archiveEmails,
    createReply,
    createEmailClient,
};
