import React, { useContext, useEffect, useState } from "react";
import "./KnowledgePostOrganism.scss";
import KnowledgePostCard from "components/molecules/KnowledgePostCard";
import { useDispatch, useSelector } from "react-redux";
import { getKnowledgeCategories } from "redux/slices/knowledge";
import { KnowledgeContextProvider } from "context/KnowledgeContext";

const KnowledgePostOrganism = ({ posts }) => {
  const { categories, posts: contextPosts, setCategories } = useContext(
    KnowledgeContextProvider
  );
  const [renderPost, setRenderPost] = useState(posts);
  const knowledgeCategoriesFetched = useSelector(
    (state) => state.knowledge.knowledgeCategories
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!renderPost) {
      dispatch(getKnowledgeCategories());
    }
  }, [renderPost]);

  useEffect(() => {
    setRenderPost(posts);
  }, [posts]);

  useEffect(() => {
    setRenderPost(contextPosts);
  }, [contextPosts]);

  useEffect(() => {
    setCategories(knowledgeCategoriesFetched);
  }, [knowledgeCategoriesFetched]);

  return (
    <div className="knowledge-posts-container">
      {
        // renderPost?.length > 0 &&
        renderPost?.map(
          (
            { id, content, title, description, last_edit, faq_category_id },
            index
          ) => (
            <KnowledgePostCard
              id={id}
              content={content}
              title={title}
              description={description}
              lastUpdate={last_edit}
              categoryId={faq_category_id}
            />
          )
        )
      }
    </div>
  );
};
export default KnowledgePostOrganism;
