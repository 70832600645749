import ClientHome from "client/pages/Home";

const prefix = "/client/home";

const routes = [
  {
    path: `${prefix}`,
    component: ClientHome,
    exact: true,
  },
];

export default routes;
