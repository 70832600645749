import React, { useContext } from "react";
import { Text } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";
import Moment from "moment";
import PlaceHolder1 from "assets/img/placeholder1.png";
import PlaceHolder2 from "assets/img/placeholder2.png";
import PlaceHolder3 from "assets/img/placeholder3.png";
import "./ReportRow.scss";
import { cloudFrontUrl } from "utils/common";

const Image = React.memo(function Image({ src, classname }) {
  const { translate } = useContext(I18nContext);
  return (
    <img
      src={src}
      className={classname}
      alt={translate("client").pages.home.LOADING}
    />
  );
});

const ReportRow = (props) => {
  const { translate } = useContext(I18nContext);
  const { onClick, item, index } = props;

  const getPlaceHolder = (index) => {
    console.log({ index });
    if (index % 3 === 0) {
      return PlaceHolder1;
    }
    if (index % 3 === 1) {
      return PlaceHolder2;
    }
    if (index % 3 === 2) {
      return PlaceHolder3;
    }
  };

  return (
    <div onClick={() => onClick(index)}>
      <div className="report-container">
        <div className="report-image">
          {item.images && item.images.length > 0 ? (
            <Image
              classname="report-image-placeholder"
              src={
                item?.images[0]
                  ? cloudFrontUrl(item?.images[0])
                  : "https://jpassport.asia/static/image-not-available.png"
              }
            />
          ) : (
            <Image
              classname="report-image-placeholder"
              src={getPlaceHolder(index)}
            />
          )}
        </div>
        <div className="report-info">
          <div className="report-row">
            <Text
              className="report-row-title-text"
              text={
                item.typename
                  ? item.typename.name
                  : item.something === "love"
                  ? translate("client").pages.home.SOMETHING_I_LOVE
                  : item.something === "change"
                  ? translate("client").pages.home.SOMETHING_TO_CHANGE
                  : translate("client").pages.home.SOMETHING_TO_ADD
              }
            />
            <Text
              className="report-date-text"
              text={Moment(item.created_at).format("DD MMM YYYY")}
            />
          </div>
          <Text className="address" text={item.formatted_address} />
        </div>
      </div>
    </div>
  );
};

export default ReportRow;
