import api from "config/axios/axios.api.config";

const prefix = "projects";

const getProjects = () => api.get(`workers/${prefix}`);

const getProject = (id) => api.get(`${prefix}/${id}?validate=true`);

const updateProject = (data, id) => api.put(`${prefix}/${id}`, data);

const getProjectCategories = () => api.get(`${prefix}/categories/all`);

const createProject = (data) => api.postJson(`${prefix}`, data);

const inviteUser = (data, id) => api.postJson(`projects/${id}/invite`, data);

const getProjectUsers = (id) => api.get(`workers/${prefix}/${id}`);

const createProjectWorker = (data, id) =>
    api.postJson(`workers/${prefix}/${id}/`, data);

// const createProjectWorker = (data, id) =>
//     api.postJson(`${prefix}/${id}/workers`, data);

const getUserProjects = () => api.get(`workers/${prefix}`);

const enableUser = (id) => api.put(`workers/${id}/enable`);

const disableUser = (id) => api.put(`workers/${id}/disable`);

const getProjectRoles = (id) => api.get(`roles/${prefix}/${id}`);

const getProjectDepartments = (id) => api.get(`${prefix}/${id}/departments`);

const getProjectResume = (id) => api.get(`${prefix}/${id}/resume`);

const getLaunchSaAnalytics = () => api.get(`analytics/launchsa`);

//Migrated to citizenAPI.js
//TODO: migrate to citizen
// const getProjectCitizen = (id) => api.get(`citizens/${prefix}/${id}`);
// const enableCitizen = (id) => api.put(`citizen/${id}/enable`);
// const disableCitizen = (id) => api.put(`citizen/${id}/disable`);

// eslint-disable-next-line
export default {
    getProjects,
    getProject,
    getProjectCategories,
    createProject,
    getProjectUsers,
    getUserProjects,
    createProjectWorker,
    enableUser,
    disableUser,
    getProjectRoles,
    getProjectDepartments,
    // getProjectCitizen,
    // enableCitizen,
    // disableCitizen,
    getProjectResume,
    inviteUser,
    getLaunchSaAnalytics,
    updateProject,
};
