import React, { useState, useContext } from "react";
import "./ProfileInfoForm.scss";
import { Avatar, Row, Col, Form } from "antd";
import { I18nContext } from "utils/i18n/locale";
import { Title } from "components/atoms/texts";
import { Text } from "components/atoms/texts";
import { DataInput } from "components/atoms/inputs";
import { emailRegex, phoneRegex } from "utils/common";

const ProfileInfoForm = () => {
  const { translate } = useContext(I18nContext);
  const [imageSrc, setImageSrc] = useState(
    `https://i.pravatar.cc/60?u=${Math.random() * 100}`
  );
  const [role, setRole] = useState("Director");
  const [department, setDepartment] = useState("Community Development");
  const [firstName, setFirstName] = useState("Adison");
  const [lastName, setLastName] = useState("Lipshutz");
  const [email, setEmail] = useState("adisonlipshutz@gmail.com");
  const [username, setUsername] = useState("adison910");
  const [phoneNumber, setPhoneNumber] = useState("+12027953213");
  const [zipCode, setZipCode] = useState("90210");

  const formItems = [
    {
      name: translate("pages").profile.placeholders.firstname,
      initialValue: firstName,
      rules: [
        {
          required: true,
        },
      ],
      placeholder: translate("pages").profile.placeholders.firstname,
    },
    {
      name: translate("pages").profile.placeholders.lastname,
      initialValue: lastName,
      rules: [
        {
          required: true,
        },
      ],
      placeholder: translate("pages").profile.placeholders.lastname,
    },
    {
      name: translate("pages").profile.placeholders.role,
      initialValue: role,
      rules: [
        {
          required: true,
        },
      ],
      placeholder: translate("pages").profile.placeholders.role,
    },
    {
      name: translate("pages").profile.placeholders.email,
      initialValue: email,
      rules: [
        {
          required: true,
          pattern: emailRegex,
        },
      ],
      placeholder: translate("pages").profile.placeholders.email,
    },
    {
      name: translate("pages").profile.placeholders.username,
      initialValue: role,
      rules: [{ required: true }],
      placeholder: translate("pages").profile.placeholders.username,
    },
    {
      name: translate("pages").profile.placeholders.phonenumber,
      initialValue: phoneNumber,
      rules: [{ required: true, pattern: phoneRegex }],
      placeholder: translate("pages").profile.placeholders.phonenumber,
    },
    {
      name: translate("pages").profile.placeholders.zip,
      initialValue: zipCode,
      rules: [{ required: true }],
      placeholder: translate("pages").profile.placeholders.zip,
    },
  ];

  return (
    <>
      <Title
        level={3}
        className="user-profile-header-text"
        align="left"
        text={translate("pages").settings.userDetail.title}
      />
      <Row>
        <Col xs={24}>
          <Row justify="center" className="user-profile-avatar">
            <Avatar
              size={{ xs: 50, sm: 75, md: 90, lg: 100, xl: 120, xxl: 150 }}
              src={imageSrc}
            />
          </Row>
        </Col>
        <Col xs={24}>
          <Title
            level={3}
            className="profile-form-name"
            text={firstName + " " + lastName}
          />
        </Col>
        <Col xs={24}>
          <Row justify="center">
            <Text text={role} className="center-text" />
          </Row>
        </Col>
        <Col xs={24}>
          <Row justify="center">
            <Text text={department} className="center-text" />
          </Row>
        </Col>
      </Row>
      <Form name="pofileInfoForm">
        {formItems.map((formItem, i) => (
          <Form.Item
            key={i}
            initialValue={formItem.initialValue}
            name={formItem.name}
            rules={formItem.rules}
          >
            <DataInput placeholder={formItem.placeholder} />
          </Form.Item>
        ))}
      </Form>
    </>
  );
};

export default ProfileInfoForm;
