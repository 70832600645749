import HomeRoute from "client/router/modules/homeRT";
import AuthRoute from "client/router/modules/authRT";

import NotificationRoute from "client/router/modules/notificationRT";
import ProfileRoute from "client/router/modules/profileRT";
import EventRoute from 'client/router/modules/eventsRT'

import ClientLoginLayout from "client/layout/LoginLayout";
import ClientMainLayout from "client/layout/MainLayout";

import LaunchSa from "client/router/modules/launchSA";
import HomeSa from "client/router/modules/homeSaRT";

import ResourcesRoute from "client/router/modules/resourcesRT";



const clientListRoutes = [
  {
    layout: ClientLoginLayout,
    routes: [...AuthRoute],
  },
  {
    layout: ClientLoginLayout,
    routes: [...LaunchSa],
  },


  {
    layout: ClientLoginLayout,
    routes: [...ProfileRoute, ...HomeSa],
  },

  {
    layout: ClientLoginLayout,
    routes: [...ProfileRoute, ...HomeRoute],
  },
  {
    layout: ClientMainLayout,
    routes: [...NotificationRoute],
  },
  {
    layout: ClientMainLayout,
    routes: [...EventRoute],
  },
  {
    layout: ClientMainLayout,
    routes: [...ResourcesRoute],
  },
];

export default clientListRoutes
