import React, { useContext, useState } from "react";
import moment from "moment";
import { Calendar as AntCalendar, Col, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "components/molecules/Calendar/Calendar.scss";
import { Text } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";
import { PrimaryButton } from "components/atoms/controls";

const Calendar = ({ handleApplyClick, handleCancelClick }) => {
  const [currentMonth, setCurrentMonth] = useState(
    moment().format("MMMM YYYY")
  );

  function onPanelChange(value) {
    setCurrentMonth(value.format("MMMM YYYY"));
  }
  const { translate } = useContext(I18nContext);

  return (
    <div className="site-calendar-customize-header-wrapper">
      <Row>
        <Col xs={24} lg={7} className="calendar-date-example">
          <Text text={translate("molecule").calendar.today} />
          <Text text={translate("molecule").calendar.lastWeek} />
          <Text text={translate("molecule").calendar.lastMonth} />
          <Text text={translate("molecule").calendar.lastYear} />
        </Col>
        <Col xs={24} lg={17} className="calendar-container">
          <AntCalendar
            fullscreen={false}
            headerRender={({ value, onChange }) => {
              return (
                <div>
                  <Row gutter={8} className="calendar-header">
                    <LeftOutlined
                      onClick={() => onChange(value.clone().subtract(1, "M"))}
                    />
                    <Text text={currentMonth} />
                    <RightOutlined
                      onClick={() => onChange(value.clone().add(1, "M"))}
                    />
                  </Row>
                </div>
              );
            }}
            onPanelChange={onPanelChange}
            dateFullCellRender={(value) => moment(value).format("D")}
          />
          <Row className="calendar-buttons">
            <PrimaryButton
              color="tertiary"
              title={translate("molecule").calendar.cancel}
              onClick={handleCancelClick}
            />
            <PrimaryButton
              title={translate("molecule").calendar.apply}
              onClick={handleApplyClick}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Calendar;
