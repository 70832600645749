import React from "react";
import { AttachBoxFileIcon, DownloadFileIcon } from "components/atoms/icons";
import "./AttachmentCard.scss";
import { cloudFrontUrl, textTruncate } from "utils/common";
import {
  AudioFilled,
  FilePdfFilled,
  VideoCameraFilled,
} from "@ant-design/icons";

const AttachmentCard = ({ ...props }) => {
  const FILE_TYPES = {
    application: "document",
    image: "image",
    video: "video",
    audio: "audio",
  };

  return (
    <a href={cloudFrontUrl(props.url)} target="_self" download={props.text}>
      <div className="card">
        <div className="card-img">
          {props.type === FILE_TYPES.image && <AttachBoxFileIcon />}
          {props.type === FILE_TYPES.application && (
            <FilePdfFilled style={{ fontSize: 21, color: "red" }} />
          )}
          {props.type === FILE_TYPES.audio && (
            <AudioFilled style={{ fontSize: 18, color: "gray" }} />
          )}
          {props.type === FILE_TYPES.video && (
            <VideoCameraFilled style={{ fontSize: 18, color: "gray" }} />
          )}
        </div>
        <div className="card-main">
          {props.text.length >= 13
            ? textTruncate(props.text, 12, "..") + props.text.split(".").pop()
            : props.text}
          <br />
          {props.fileSize}
        </div>
        <div className="card-download">
          <DownloadFileIcon />
        </div>
      </div>
    </a>
  );
};
export default AttachmentCard;
