import {
  Button as AntButton,
  Col,
  Dropdown as AntDropdown,
  Menu,
  Row,
} from "antd";
import {
  EllipsisOutlined,
  MenuOutlined,
  PlusOutlined,
  TableOutlined,
} from "@ant-design/icons";
import "./controls.scss";
import { useState, useContext, useEffect } from "react";
import { I18nContext } from "utils/i18n/locale";

import { getLocalSettings } from "utils/common";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

export const HOLLOW_BUTTON = "hollow-button";
export const SECONDARY_COLOR = "secondary";
export const ACTION_COLOR = "action";
export const TERTIARY_COLOR = "tertiary";

const COLOR_CLASSES = {
  [HOLLOW_BUTTON]: "client-hollow-button",
  
};
// const settings = getLocalSettings();

export const PrimaryButton = ({
  style,
  title,
  loading,
  cssClassName = "",
  color = "primary",
  ...props
}) => {
  const location = useLocation();
  const userSettings = useSelector((state) => state.projects.settings);
  const [settings, setSettings] = useState(userSettings);
  useEffect(() => {
    setSettings(userSettings);
  }, [userSettings]);
  return (
    <AntButton
      loading={loading}
      {...props}

      type="primary"
      className={`${
        COLOR_CLASSES[color] 
      } ${cssClassName}`}
    >
      {title}
    </AntButton>
  );
};
