import EditProfile from "client/pages/EditProfile";

const prefix = "/client/profile";

const routes = [
  {
    path: `${prefix}`,
    component: EditProfile,
    exact: true,
  },
];

export default routes;
