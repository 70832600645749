import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import resourceAPI from "api/resourceAPI";
import { getCurrentProjectId } from "utils/common";

const awsErrorSerialized = {
  error: "ASW Error",
};

const awsError = (resp) => {
  console.log(resp);
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};

export const asyncGetData = (previewData) => async (dispatch) => {
  try {
    dispatch(startGetForm());
    dispatch(successGetForm(previewData));
  } catch (error) {
    dispatch(errorGetForm(error.toString()));
  }
};
export const asyncClearData = (previewData) => async (dispatch) => {
  try {
    dispatch(startCleanForm());
    dispatch(successCleanForm(previewData));
  } catch (error) {
    dispatch(errorCleanForm(error.toString()));
  }
};

export const getResources = createAsyncThunk(
  "resources/getResources",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const resources = await resourceAPI.getResources(project_id);
      awsError(resources);
      return resources.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getClientMyResources = createAsyncThunk(
  "resources/getClientMyResources",
  async (id, { rejectWithValue }) => {
    try {
      const resources = await resourceAPI.getClientMyResources(id);
      awsError(resources);
      return resources.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getClientResourceById = createAsyncThunk(
  "resources/getClientResourceById",
  async (id, { rejectWithValue }) => {
    try {
      const resources = await resourceAPI.getResourceById(id);
      awsError(resources);
      return resources.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const getClientResources = createAsyncThunk(
  "resources/getClientResources",
  async (id, { rejectWithValue }) => {
    try {
      const resources = await resourceAPI.getResources(id);

      awsError(resources);
      return resources.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getResourcesCategories = createAsyncThunk(
  "resources/getResourcesCategories",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const resourcesCategories = await resourceAPI.getResourcesCategories(
        project_id
      );
      awsError(resourcesCategories);
      return resourcesCategories.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createResourcesCategories = createAsyncThunk(
  "resources/createResourcesCategories",
  async (item, { rejectWithValue }) => {
    try {
      const project_id = getCurrentProjectId();
      const resources = await resourceAPI.createResourcesCategories(
        item,
        project_id
      );
      console.log("API CALL: ", resources);
      awsError(resources);
      return resources.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createResources = createAsyncThunk(
  "resources/createResources",
  async (item, { rejectWithValue }) => {
    try {
      const project_id = getCurrentProjectId();
      const resources = await resourceAPI.createResource(item, project_id);
      awsError(resources);
      return resources.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updateResources = createAsyncThunk(
  "resources/updateResources",
  async (item, { rejectWithValue }) => {
    try {
      let editResources = { ...item };
      delete editResources.id;
      const resources = await resourceAPI.updateResource(
        editResources,
        item.id
      );
      console.log(resources, "editado");
      awsError(resources);
      return resources.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const publishResources = createAsyncThunk(
  "resources/publishResources",
  async (item, { rejectWithValue }) => {
    try {
      const resources = await resourceAPI.publishResource(item);
      awsError(resources);
      return resources.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const unpublishResources = createAsyncThunk(
  "resources/unpublishResources",
  async (item, { rejectWithValue }) => {
    try {
      const resources = await resourceAPI.unpublishResource(item);
      awsError(resources);
      return resources.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const duplicateResources = createAsyncThunk(
  "resources/duplicateResources",
  async (item, { rejectWithValue }) => {
    try {
      const resources = await resourceAPI.duplicateResource(item);
      awsError(resources);
      return resources.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const deleteResources = createAsyncThunk(
  "resources/deleteResources",
  async (id, { rejectWithValue }) => {
    try {
      const resources = await resourceAPI.deleteResource(id);
      awsError(resources);
      return resources.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

const initialState = {
  previewData: {
    title: "",
    description: "",
    category: "General",
    website_link: "",
  },
  resource: null,
  resources: [],
  clientResources:[],
  resourcesCategories: [],
  error: null,
  loading: true,
  resourceUpdated: false,
  resourceCreated: false,
  loadingAction: false,
};

const resources = createSlice({
  name: "resources",
  initialState,
  reducers: {
    startGetForm(state, action) {},
    successGetForm(state, action) {
      state.previewData = { ...state.previewData, ...action.payload };
    },
    errorGetForm(state, action) {
      console.log("error en el preview data", action);
    },
    startCleanForm(state, action) {},
    successCleanForm(state, action) {
      state.previewData = { ...action.payload };
    },
    errorCleanForm(state, action) {
      console.log("error en el clean data", action);
    },
  },
  extraReducers: {
    [getClientResourceById.pending]: (state, action) => {
      console.info("getClientResourceById pending");
      state.loading = true;
      state.error = null;
      state.resource = null;
    },
    [getClientResourceById.fulfilled]: (state, action) => {
      console.info("getClientResourceById fullfilled =>", action.payload);
      state.loading = false;
      state.error = null;
      state.resource = action.payload.body;
    },
    [getClientResourceById.rejected]: (state, action) => {
      console.error("getClientResourceById rejected =>", action);
      state.loading = false;
      state.error = action.payload;
      state.resource = null;
    },

    [getClientMyResources.pending]: (state, action) => {
      console.info("getClientMyResources pending");
      state.loading = true;
      state.error = null;
      state.resourcesUpdated = false;
      state.resourcesCreated = false;
      state.clientResources = [];
    },
    [getClientMyResources.fulfilled]: (state, action) => {
      console.info("getClientMyResources fullfilled =>", action.payload);
      state.loading = false;
      state.error = null;
      state.resourcesUpdated = false;
      state.clientResources = action.payload.body;
    },
    [getClientMyResources.rejected]: (state, action) => {
      console.error("getClientMyResources rejected =>", action);
      state.loading = false;
      state.error = action.payload;
      state.clientResources = [];
    },
    [getClientResources.pending]: (state, action) => {
      console.info("getClientResources pending");
      state.loading = true;
      state.error = null;
      state.resourcesUpdated = false;
      state.resourcesCreated = false;
      state.resources = [];
    },
    [getClientResources.fulfilled]: (state, action) => {
      console.info("getClientResources fullfilled =>", action.payload);
      state.loading = false;
      state.error = null;
      state.resourcesUpdated = false;
      state.resources = action.payload.body;
    },
    [getClientResources.rejected]: (state, action) => {
      console.error("getClientResources rejected =>", action);
      state.loading = false;
      state.error = action.payload;
      state.resources = [];
    },
    //Get Resources
    [getResources.pending]: (state, action) => {
      console.info("getResources pending");
      state.loading = true;
      state.error = null;
      state.resourcesUpdated = false;
      state.resourcesCreated = false;
      // state.resources = [];
    },
    [getResources.fulfilled]: (state, action) => {
      console.info("getResources fullfilled");
      state.loading = false;
      state.error = null;
      state.resourcesUpdated = false;
      state.resources = action.payload.body;
    },
    [getResources.rejected]: (state, action) => {
      console.error("getResources rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.resources = [];
    },

    //Get ResourcesCategories
    [getResourcesCategories.pending]: (state, action) => {
      console.info("getResourcesCategories pending");
      // state.loadingAction = true;
      state.error = null;
      state.resourcesUpdated = false;
      state.resourcesCreated = false;
    },
    [getResourcesCategories.fulfilled]: (state, action) => {
      console.info("getResourcesCategories fullfilled");
      // state.loadingAction = false;
      state.error = null;
      state.resourcesUpdated = false;
      state.resourcesCategories = action.payload.body;
    },
    [getResourcesCategories.rejected]: (state, action) => {
      console.error("getResourcesCategories rejected =>", action.payload);
      // state.loadingAction = false;
      state.error = action.payload;
      state.resourcesCategories = [];
    },

    //Create ResourcesCategories
    [createResourcesCategories.pending]: (state, action) => {
      console.info("createResourcesCategories pending");
      state.loadingAction = true;
      state.error = null;
      state.resourcesUpdated = false;
      state.resourcesCreated = false;
    },
    [createResourcesCategories.fulfilled]: (state, action) => {
      console.info("createResourcesCategories fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.resourcesUpdated = false;
    },
    [createResourcesCategories.rejected]: (state, action) => {
      console.error("createResourcesCategories rejected =>", action.payload);
      state.loadingAction = false;
      state.error = action.payload;
    },

    //Create Resources
    [createResources.pending]: (state, action) => {
      console.info("createResources pending");
      state.loadingAction = true;
      state.error = null;
      state.resourcesUpdated = false;
      state.resourcesCreated = false;
    },
    [createResources.fulfilled]: (state, action) => {
      console.info("createResources fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.resourcesCreated = true;
    },
    [createResources.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("createResources rejected =>", action.payload);
    },

    //Update Resources
    [updateResources.pending]: (state, action) => {
      console.info("updateResources pending");
      state.loadingAction = true;
      state.error = null;
      state.resourcesUpdated = false;
      state.resourcesCreated = false;
    },
    [updateResources.fulfilled]: (state, action) => {
      console.info("updateresources fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.resourcesCreated = true;
    },
    [updateResources.rejected]: (state, action) => {
      console.error("updateresources rejected =>", action.payload);
      state.resourcesCreated = false;
      state.resourcesUpdated = false;
      state.loadingAction = false;
      state.error = action.payload.error;
    },

    //Delete Resources
    [deleteResources.pending]: (state, action) => {
      console.info("DeleteResources started");
      state.loadingAction = true;
      state.error = null;
      state.resourcesUpdated = false;
    },
    [deleteResources.fulfilled]: (state, action) => {
      console.info("DeleteResources success", state.resourcesUpdated);
      state.loadingAction = false;
      state.error = null;
      state.resourcesUpdated = true;
    },
    [deleteResources.rejected]: (state, action) => {
      console.error("DeleteResources error=> ", action.payload.error);
      state.loadingAction = false;
      state.error = null;
      state.resourcesUpdated = false;
      state.error = action.payload.error;
    },

    //Publish Resources
    [publishResources.pending]: (state, action) => {
      console.info("PublishResources pending");
      state.loadingAction = true;
      state.error = null;
      state.resourcesUpdated = false;
    },
    [publishResources.fulfilled]: (state, action) => {
      console.info("PublishResources fullfiled", state.resourcesUpdated);
      state.loadingAction = false;
      state.error = null;
      state.resourcesUpdated = true;
    },
    [publishResources.rejected]: (state, action) => {
      console.error("PublishResources rejected=> ", action.payload);
      state.loadingAction = false;
      state.error = action.payload;
      state.resourcesUpdated = false;
    },

    //Publish Resources
    [unpublishResources.pending]: (state, action) => {
      console.info("unPublishResources pending");
      state.loadingAction = true;
      state.error = null;
      state.resourcesUpdated = false;
    },
    [unpublishResources.fulfilled]: (state, action) => {
      console.info("unPublishResources fullfiled", state.resourcesUpdated);
      state.loadingAction = false;
      state.error = null;
      state.resourcesUpdated = true;
    },
    [unpublishResources.rejected]: (state, action) => {
      console.error("unPublishResources rejected=> ", action.payload);
      state.loadingAction = false;
      state.error = action.payload;
      state.resourcesUpdated = false;
    },

    //Duplicate Resources
    [duplicateResources.pending]: (state, action) => {
      console.info("DuplicateResources pending");
      state.loadingAction = true;
      state.error = null;
      state.resourcesUpdated = false;
    },
    [duplicateResources.fulfilled]: (state, action) => {
      console.info("DuplicateResources fullfilled", state.resourcesUpdated);
      state.loadingAction = false;
      state.error = null;
      state.resourcesUpdated = true;
    },
    [duplicateResources.rejected]: (state, action) => {
      console.error("DuplicateResources rejected=> ", action.payload);
      state.loadingAction = false;
      state.error = null;
      state.resourcesUpdated = false;
      state.error = action.payload;
    },
  },
});

export const {
  startGetForm,
  successGetForm,
  errorGetForm,
  startCleanForm,
  successCleanForm,
  errorCleanForm,
} = resources.actions;
export default resources.reducer;
