import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
} from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { SpiningPage } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import { Row, Col } from "antd";
import NewsOrganism from "components/organisms/NewsOrganism";
import { useDispatch, useSelector } from "react-redux";
import {
  getNews,
  deleteNews,
  duplicateNews,
  createNews,
  updateNews,
  publishNews,
} from "redux/slices/news";
import { AlertWithfunctions, SwalAlerts } from "components/atoms/outputs";
import noNews from "assets/img/illustrations/no-news.svg";
import { validateLaunchSA } from "utils/common";
import { DeleteMedia } from "api/amplifyAuthAPI";
// import { Alert } from "components/atoms/outputs";
// import { useHistory } from "react-router-dom";
const NewsTemplate = lazy(() => import("components/templates/NewsTemplate"));

const EmptyComponentTemplate = lazy(() =>
  import("components/templates/EmptyComponentTemplate")
);

const renderLoader = () => <SpiningPage opacity={1} />;

const News = () => {
  const newsFetched = useSelector((state) => state.news.news);
  const newsLoading = useSelector((state) => state.news.loading);
  const newsLoadingAction = useSelector((state) => state.news.loadingAction);
  const newsUpdated = useSelector((state) => state.news.newsUpdated);
  // const newsError = useSelector((state) => state.news.error);
  // const newsCreated = useSelector((state) => state.news.newsCreated);
  const [news, setNews] = useState(newsFetched);
  const [newsAux, setNewsAux] = useState(newsFetched);
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentNews, setCurrentNews] = useState({});
  const [view, setView] = useState("cards");
  const [searchRef, setSearchRef] = useState(0);
  const { translate } = useContext(I18nContext);
  const isMounted = useRef(null);
  const dispatch = useDispatch();
  // const history = useHistory();
  // const newsRef = useRef(news);
  useEffect(() => {
    dispatch(getNews());
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setNews(newsFetched);
    setNewsAux(newsFetched);
  }, [newsFetched]);

  useEffect(() => {
    if (newsUpdated) {
      dispatch(getNews());
    }
  }, [newsUpdated]);

  // const handleSearch = (query, isEmpty) => {
  //   let result = newsAux.filter((n) =>
  //     n.title.toLowerCase().includes(query.toLowerCase())
  //   );
  //   if (result.length <= 0) {
  //     setNews([{}]);
  //   } else {
  //     if (isEmpty) {
  //       setNews(newsAux);
  //     } else {
  //       setNews(result);
  //     }
  //   }
  // };
  const handleSearch = (query, isEmpty) => {
    let result = newsAux.filter((n) =>
      n.title.toLowerCase().includes(query.toLowerCase())
    );
    if (searchRef === result.length) {
    } else {
      if (result.length <= 0) {
        setNews([{}]);
      } else {
        if (isEmpty) {
          setNews(newsAux);
        } else {
          setNews(result);
        }
      }
    }
    setSearchRef(result.length);
  };

  const redirectToNewsForm = () => {
    setCreate(true);
  };

  const handleView = (view) => {
    setView(view);
  };

  const handleEdit = (item) => {
    setCurrentNews(item);
    setEdit(true);
  };
  const handleCopy = (item) => {
    const info = {
      title: translate("pages").feed.alerts.duplicate.question.title,
      text: translate("pages").feed.alerts.duplicate.question.subtitle,
      icon: "info",
      confirm:
        translate("pages").feed.alerts.duplicate.question.buttons.confirm,
      successTitle: translate("pages").feed.alerts.duplicate.success.title,
      successSubtitle:
        translate("pages").feed.alerts.duplicate.success.subtitle,
      errorTitle: translate("pages").feed.alerts.duplicate.error.title,
      errorSubtitle: translate("pages").feed.alerts.duplicate.error.subtitle,
      dispatchFunction: duplicateNews,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };
  const handleDelete = (item) => {
    const info = {
      title: translate("pages").feed.alerts.delete.question.title,
      text: translate("pages").feed.alerts.delete.question.subtitle,
      icon: "info",
      confirm: translate("pages").feed.alerts.delete.question.buttons.confirm,
      successTitle: translate("pages").feed.alerts.delete.success.title,
      successSubtitle: translate("pages").feed.alerts.delete.success.subtitle,
      errorTitle: translate("pages").feed.alerts.delete.error.title,
      errorSubtitle: translate("pages").feed.alerts.delete.error.subtitle,
      dispatchFunction: deleteNews,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };

  const handlePublish = (item) => {
    const info = {
      title: translate("pages").feed.alerts.publish.question.title,
      text: translate("pages").feed.alerts.publish.question.subtitle,
      icon: "info",
      confirm: translate("pages").feed.alerts.publish.question.buttons.confirm,
      successTitle: translate("pages").feed.alerts.publish.success.title,
      successSubtitle: translate("pages").feed.alerts.publish.success.subtitle,
      errorTitle: translate("pages").feed.alerts.publish.error.title,
      errorSubtitle: translate("pages").feed.alerts.publish.error.subtitle,
      dispatchFunction: publishNews,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };

  const handleSaveEdited = async (values) => {
    dispatch(updateNews(values))
      .then(unwrapResult)
      .then(() => {
        SwalAlerts({
          title: translate("pages").feed.alerts.save.success.title,
          text: translate("pages").feed.alerts.save.success.subtitle,
          type: "success",
        });
        DeleteMedia(currentNews.image);
        dispatch(getNews());
        setTimeout(function () {
          setEdit(false);
        }, 2000);
      })
      .catch(() => {
        SwalAlerts({
          title: translate("pages").feed.alerts.save.error.title,
          text: translate("pages").feed.alerts.save.error.subtitle,
          type: "error",
        });
      });
  };

  const handleSave = async (values) => {
    dispatch(createNews(values))
      .then(unwrapResult)
      .then(() => {
        SwalAlerts({
          title: translate("pages").feed.alerts.save.success.title,
          text: translate("pages").feed.alerts.save.success.subtitle,
          type: "success",
        });
        dispatch(getNews());
        setTimeout(function () {
          setCreate(false);
        }, 2000);
      })
      .catch(() => {
        SwalAlerts({
          title: translate("pages").feed.alerts.save.error.title,
          text: translate("pages").feed.alerts.save.error.subtitle,
          type: "error",
        });
      });
  };
  return (
    <Suspense fallback={renderLoader()}>
      {news && news?.length > 0 && !edit && !newsLoading && !create ? (
        <NewsOrganism
          items={news}
          loading={newsLoading}
          type={view}
          handleView={handleView}
          redirectToNewsForm={redirectToNewsForm}
          handleEdit={handleEdit}
          handleCopy={handleCopy}
          handleDelete={handleDelete}
          handlePublish={handlePublish}
          handleSearch={handleSearch}
        />
      ) : create ? (
        <Row justify="center">
          <Col flex="auto">
            <NewsTemplate
              handleSave={handleSave}
              handlePublish={handlePublish}
              redirectToNewsForm={redirectToNewsForm}
              loading={newsLoadingAction}
            />
          </Col>
        </Row>
      ) : edit ? (
        <Row justify="center">
          <Col flex="auto">
            <NewsTemplate
              news={currentNews}
              handleSaveEdited={handleSaveEdited}
              handlePublish={handlePublish}
              loading={newsLoadingAction}
            />
          </Col>
        </Row>
      ) : (
        !newsLoading &&
        news.length <= 0 && (
          <EmptyComponentTemplate
            onButtonClick={redirectToNewsForm}
            image={noNews}
            title={
              !validateLaunchSA()
                ? translate("pages").feed.notFound.title
                : translate("pages").feed.notFoundPrograms.title
            }
            subtitle={
              !validateLaunchSA()
                ? translate("pages").feed.notFound.description
                : translate("pages").feed.notFoundPrograms.description
            }
            buttonText={
              !validateLaunchSA()
                ? translate("pages").feed.notFound.buttons.createPost
                : translate("pages").feed.notFoundPrograms.buttons.createPost
            }
          />
        )
      )}
      {newsLoading && <SpiningPage opacity={1} />}
    </Suspense>
  );
};
export default News;
