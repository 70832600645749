import React, { useContext, useEffect, useRef, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { SwalAlerts } from "components/atoms/outputs";
import { Form, Row, Col, Divider, DatePicker } from "antd";
import { I18nContext } from "utils/i18n/locale";
import { CreateElementInput } from "components/atoms/inputs";
import { PrimaryButton } from "components/atoms/controls";
import { BodyFormLayout } from "components/atoms/layouts";
import { FileDragger } from "components/atoms/media";
import { MapComponent, LocationSearchInput } from "components/molecules/Map";
import { dateFormat, emailRegex, getCurrentOrganizationId } from "utils/common";
import { useDispatch, useSelector } from "react-redux";
import { RightOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { SelectBox } from "components/atoms/inputs";
import { uploadDrag } from "components/atoms/media";
import Switch from "components/atoms/switch";
import { format } from "date-fns";
import {
    createProjects,
    getProject,
    getProjectCategories,
    getProjectsSilence,
    updateProject,
} from "redux/slices/projects";
import { uploadCarrouselFiles } from "components/atoms/media";
import "./ProjectForm.scss";
import { SpiningPage } from "components/atoms/icons";
import moment from "moment";
import { Text } from "components/atoms/texts";

const ProjectForm = (props) => {
    const { id } = useParams();

    const initialForm = {
        organization_id: getCurrentOrganizationId(),
        completion_status: 0,
        name: "",
        logo: null,
        carrousel_images: null,
        description: "",
        address: "",
        latitude: "",
        longitude: "",
        owner_developer: "",
        project_category: "",
        cost: "",
        contact_email: "",
        project_start: new Date().toLocaleDateString(),
        estimated_completion: new Date().toLocaleDateString(),
        share: true,
        is_private: false,
        ready: false,
    };

    const [formItems, setFormItems] = useState(initialForm);
    const projectCategories = useSelector((state) => state.projects.categories);
    const projectFetched = useSelector(
        (state) => state.projects.projectFetched
    );
    const loadingProject = useSelector((state) => state.projects.singleLoading);
    const [loading, setLoading] = useState(false);
    const [uploadedCarrousel, setUploadedCarrousel] = useState([]);
    const [loadingEdit, setLoadingEdit] = useState(true);
    const [form] = Form.useForm();
    const history = useHistory();
    const editingProjectRef = useRef(null);
    const dispatch = useDispatch();
    const { translate } = useContext(I18nContext);

    useEffect(() => {
        if (formItems.ready) {
            dispatchCreateProject();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formItems]);

    useEffect(() => {
        if (projectFetched && projectFetched.length !== 0) {
            editingProjectRef.current = projectFetched.id;
            setFormItems(projectFetched);
            setLocation({
                address: projectFetched.address,
                latitude: projectFetched.latitude,
                longitude: projectFetched.longitude,
            });
            setUploadedCarrousel(projectFetched.carrousel_images);
        } else if (
            projectFetched &&
            projectFetched.length === 0 &&
            Number.isInteger(+id)
        ) {
            SwalAlerts({
                title: "Proyecto no válido".title,
                text: "El proyecto seleccionado no es válido para este usuario",
                type: "error",
            });
            history.push("/home/project/manage/");
        }
    }, [projectFetched]);

    useEffect(() => {
        if (id && Number.isInteger(+id)) {
            form.resetFields();
            if (!editingProjectRef.current) {
                dispatch(getProject(id));
            }
            if (
                !loadingProject &&
                (!projectFetched ||
                    editingProjectRef.current?.toString() !== id)
            ) {
                setLoadingEdit(true);
                if (id) {
                    dispatch(getProject(id));
                } else {
                    setInitialState();
                }
            }
        } else {
            if (id && !Number.isInteger(+id)) {
                history.push("/home/project/manage/");
            }
            setLoadingEdit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, projectFetched, loadingProject]);

    useEffect(() => {
        if (!loadingProject && formItems.name !== projectFetched?.name) {
            setLoadingEdit(false);
        }
    }, [projectFetched, formItems, loadingProject]);

    useEffect(() => {
        const project_category = projectCategories.find(
            (category) => category.name === "Other"
        );
        if (project_category && !id) {
            setFormItems((items) => {
                return {
                    ...items,
                    project_category: project_category,
                };
            });
        }
    }, [projectCategories]);

    useEffect(() => {
        if (!id) {
            setInitialState();
            editingProjectRef.current = null;
        }
    }, [id]);

    const isNameSet = () => {
        return !(formItems.name && formItems !== "");
    };

    const setInitialState = () => {
        form.resetFields();
        setFormItems(initialForm);
        setLocation({
            address: null,
            latitude: null,
            longitude: null,
        });
        setUploadedCarrousel([]);
    };

    const [location, setLocation] = useState({
        address: null,
        latitude: null,
        longitude: null,
    });

    const dispatchCreateProject = () => {
        let newProject = { ...formItems };
        delete newProject.ready;
        newProject.carrousel_images = newProject.carrousel_images?.map(
            ({ key, copyright }) => {
                if (!copyright) copyright = null;
                return { key, copyright };
            }
        );
        dispatch(
            id && Number.isInteger(+id)
                ? updateProject(newProject)
                : createProjects(newProject)
        )
            .then(unwrapResult)
            .then(() => {
                SwalAlerts({
                    title: translate("pages").projects.alerts.addProject.success
                        .title,
                    text: translate("pages").projects.alerts.addProject.success
                        .subtitle,
                    type: "success",
                });
                setFormItems(initialForm);
                setLocation({
                    address: null,
                    latitude: null,
                    longitude: null,
                });
                setLoading(false);
                dispatch(getProjectsSilence());
                if (id) {
                    setLoadingEdit(true);
                    dispatch(getProject(id));
                }
            })
            .catch((e) => {
                setLoading(false);
                SwalAlerts({
                    title: translate("pages").projects.alerts.addProject.error
                        .title,
                    text: translate("pages").projects.alerts.addProject.error
                        .subtitle,
                    type: "error",
                });
            });
    };

    const getAddress = (location) => {
        setLocation({
            address: location.address,
            latitude: location.lat,
            longitude: location.lng,
        });
    };

    const getFileReference = (file) => {
        setFormItems((items) => {
            return { ...items, logo: file };
        });
    };
    const getMedia = (name, media) => {
        setFormItems((items) => {
            return { ...items, [name]: media };
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.error("Failed:", errorInfo);
    };

    const uploadImage = async (file) => {
        uploadDrag(`projects/logo`, file, undefined, "png", "image/png")
            .then(({ key }) => {
                setFormItems((items) => {
                    return { ...items, logo: key, ready: true };
                });
            })
            .catch((error) => {
                setLoading(false);
                console.error("hubo un error subiendo el archivo ");
            });
    };

    const uploadCarrousel = async (images) => {
        let auxArr = [];
        let imagesAux = [...images];
        let promisesArr = [];
        let imagesUploaded = [];
        for (const image of imagesAux) {
            if (!image.isUploaded) {
                promisesArr.push({
                    func: () =>
                        uploadCarrouselFiles(
                            `projects/${"carrousel_images"}`,
                            image.file,
                            undefined,
                            "jpg",
                            "image/jpeg"
                        ),
                    image,
                });
            } else {
                imagesUploaded.push(image);
            }
        }
        // console.log({ formItems, promisesArr, imagesUploaded });
        if (promisesArr.length > 0) {
            await Promise.all(
                promisesArr.map((request) =>
                    request
                        .func()
                        .then((key) => {
                            const idx = formItems.carrousel_images.findIndex(
                                (elem) => elem.base64 === request.image.base64
                            );
                            let temp = {
                                ...formItems.carrousel_images[idx],
                                key,
                            };
                            if (imagesUploaded.length > 0) {
                                const clearedImages = imagesUploaded.map(
                                    ({ key, copyright }) => {
                                        if (!copyright) copyright = null;
                                        return { key, copyright };
                                    }
                                );
                                auxArr.push(...clearedImages, temp);
                            } else {
                                auxArr.push(temp);
                            }
                            if (
                                auxArr.length ===
                                formItems.carrousel_images.length
                            ) {
                                setFormItems((items) => {
                                    return {
                                        ...items,
                                        carrousel_images: auxArr,
                                    };
                                });
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            console.log({ error });
                            console.error("hubo un error subiendo el archivo ");
                        })
                )
            )
                .then((data) => {
                    if (formItems.logo && typeof formItems.logo !== "string") {
                        uploadImage(formItems.logo, "logo");
                    } else {
                        setFormItems((items) => {
                            return {
                                ...items,
                                ready: true,
                            };
                        });
                    }
                })
                .catch((error) => {});
        } else {
            if (formItems.logo && typeof formItems.logo !== "string") {
                uploadImage(formItems.logo, "logo");
            } else {
                setFormItems((items) => {
                    return {
                        ...items,
                        ready: true,
                    };
                });
            }
        }
    };

    const onFinish = async () => {
        setLoading(true);
        if (formItems.carrousel_images) {
            await uploadCarrousel(formItems.carrousel_images);
        }

        if (!formItems.carrousel_images && !formItems.logo) {
            setFormItems((items) => {
                return {
                    ...items,
                    ready: true,
                };
            });
        }
    };

    const handleChangeValue = (e, dateName, isDate) => {
        let event = { ...e };
        if (dateName) {
            event.target = {};
            event.target.name = dateName;
            event.target.value = isDate ? format(new Date(e), "MM/dd/yyyy") : e;
        }
        setFormItems((items) => {
            return {
                ...items,
                [event.target.name]: event.target.value,
            };
        });
    };

    useEffect(() => {
        dispatch(getProjectCategories());
    }, []);

    useEffect(() => {
        setFormItems((items) => {
            return {
                ...items,
                address: location.address,
                latitude: location.latitude,
                longitude: location.longitude,
            };
        });
    }, [location]);

    return (
        <>
            {!loadingEdit ? (
                <Form
                    form={form}
                    name="addEvent"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={formItems}
                >
                    <Row justify="end">
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: isNameSet(),
                                        message:
                                            translate("pages").events
                                                .createEventForm.errors
                                                .titleValidation,
                                    },
                                ]}
                            >
                                <CreateElementInput
                                    defaultValue={formItems?.name}
                                    onChange={(e) => handleChangeValue(e)}
                                    name="name"
                                    invisible
                                    placeholder="Community Name"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider style={{ marginTop: -10 }} />
                    <BodyFormLayout>
                        <Row justify="center" gutter={[40, 6]}>
                            <Col flex="auto" span={24}>
                                <Form.Item name="logo">
                                    <FileDragger
                                        isCustom
                                        currentFile={formItems}
                                        label={"Logo"}
                                        getFileReference={getFileReference}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center" gutter={[40, 6]}>
                            <Col flex="auto" span={24}>
                                <Form.Item name="carrousel_images">
                                    <FileDragger
                                        copyright
                                        label="Carrousel Images"
                                        getMedia={getMedia}
                                        carrousel={uploadedCarrousel}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center" gutter={[0, 0]}>
                            <Col flex="auto" span={24}>
                                <Form.Item name="description">
                                    <CreateElementInput
                                        defaultValue={formItems?.description}
                                        name="description"
                                        textArea
                                        special
                                        onChange={(e) => handleChangeValue(e)}
                                        textAreaRows={8}
                                        placeholder={
                                            translate("pages").feed.newsForm
                                                .placeholders.description
                                        }
                                        label={
                                            translate("pages").feed.newsForm
                                                .labels.description
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col flex="auto" span={24}>
                                <Form.Item name="address">
                                    <LocationSearchInput
                                        getAddress={getAddress}
                                        address={location.address}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {location.latitude && location.longitude && (
                            <Row justify="center" gutter={[40, 40]}>
                                <Col flex="auto" span={24}>
                                    <MapComponent
                                        latitude={location.latitude}
                                        longitude={location.longitude}
                                        address={location.address}
                                    />
                                </Col>
                            </Row>
                        )}

                        <Row justify="center" gutter={[40, 0]}>
                            <Col flex="auto" span={24}>
                                <Form.Item name="owner_developer">
                                    <CreateElementInput
                                        customValue={formItems?.owner_developer}
                                        onChange={(e) => handleChangeValue(e)}
                                        name="owner_developer"
                                        placeholder="Add Owner/Developer"
                                        label="Owner Developer"
                                        special
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center" gutter={[40, 0]}>
                            <Col flex="auto" span={24}>
                                <Form.Item name="project_category">
                                    <SelectBox
                                        height="50px"
                                        placeholder="Add your category here"
                                        label="Category"
                                        onChange={(e) =>
                                            handleChangeValue(
                                                e,
                                                "project_category"
                                            )
                                        }
                                        items={projectCategories}
                                        defaultValue={
                                            formItems.project_category?.name
                                        }
                                        disabled={projectCategories.length <= 0}
                                    />
                                </Form.Item>
                                {/* <Form.Item name="project_category">
                                <CreateElementInput
                                    onChange={(e) => handleChangeValue(e)}
                                    name="project_category"
                                    placeholder="write your title here"
                                    label="Category"
                                />
                            </Form.Item> */}
                            </Col>
                        </Row>
                        <Row justify="center" gutter={[40, 0]}>
                            <Col flex="auto" span={24}>
                                <Form.Item name="cost">
                                    <CreateElementInput
                                        onChange={(e) => handleChangeValue(e)}
                                        name="cost"
                                        placeholder="Add project cost"
                                        label="Cost"
                                        customValue={formItems?.cost}
                                        special
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center" gutter={[40, 0]}>
                            <Col flex="auto" span={24}>
                                <Form.Item
                                    name="contact_email"
                                    rules={[
                                        {
                                            type: "email",
                                            pattern: emailRegex,
                                        },
                                    ]}
                                >
                                    <CreateElementInput
                                        customValue={formItems?.contact_email}
                                        onChange={(e) => handleChangeValue(e)}
                                        name="contact_email"
                                        placeholder="Add contact email"
                                        label="Contact email"
                                        special
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row
                            justify="start"
                            align="middle"
                            className="date-picker"
                            gutter={[0, 12]}
                        >
                            <div>
                                <Text text="Project Start" />
                                <Col md={24} sm={24} xs={24}>
                                    <Form.Item name="date">
                                        <DatePicker
                                            name="start_date"
                                            placeholder={
                                                translate("pages").events
                                                    .createEventForm
                                                    .placeholders.dateStart
                                            }
                                            format={dateFormat}
                                            onChange={(e) =>
                                                handleChangeValue(
                                                    e,
                                                    "project_start",
                                                    true
                                                )
                                            }
                                            defaultValue={
                                                formItems.project_start
                                                    ? moment(
                                                          formItems.project_start,
                                                          "MM-DD-YYYY"
                                                      )
                                                    : moment(
                                                          new Date(),
                                                          "MM-DD-YYYY"
                                                      )
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </div>

                            <Col md={0} sm={0} xs={0} lg={2}>
                                <RightOutlined className="arrow" />
                            </Col>
                            <div>
                                <Text text="Estimated Completion" />
                                <Col>
                                    <Form.Item name="date2">
                                        <DatePicker
                                            name="estimated_completion"
                                            placeholder={
                                                translate("pages").events
                                                    .createEventForm
                                                    .placeholders.dateEnd
                                            }
                                            defaultValue={
                                                formItems.estimated_completion
                                                    ? moment(
                                                          formItems.estimated_completion,
                                                          "MM-DD-YYYY"
                                                      )
                                                    : moment(
                                                          new Date(),
                                                          "MM-DD-YYYY"
                                                      )
                                            }
                                            format={dateFormat}
                                            onChange={(e) =>
                                                handleChangeValue(
                                                    e,
                                                    "estimated_completion",
                                                    true
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </div>
                        </Row>
                        <Row justify="bottom" gutter={[0, 30]}>
                            <Col span={5}>
                                <Text
                                    text={
                                        translate("pages").projects
                                            .createProjectForm.labels
                                            .shareProjectStatus
                                    }
                                />
                            </Col>
                            <Col span={18}>
                                <Switch
                                    active={formItems?.share}
                                    onToggle={() =>
                                        setFormItems((items) => {
                                            return {
                                                ...items,
                                                share: !items.share,
                                            };
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                        <Row justify="bottom" gutter={[0, 0]}>
                            <Col span={5}>
                                <Text
                                    text={
                                        translate("pages").projects
                                            .createProjectForm.labels
                                            .privateProject
                                    }
                                />
                            </Col>
                            <Col span={18}>
                                <Switch
                                    active={formItems.is_private}
                                    onToggle={() =>
                                        setFormItems((items) => {
                                            return {
                                                ...items,
                                                is_private: !items.is_private,
                                            };
                                        })
                                    }
                                />
                            </Col>
                        </Row>

                        <Divider style={{ marginTop: 10 }} />
                        <Row justify="end" gutter={[40, 6]}>
                            <Col flex="auto" xl={3} lg={4} md={6} sm={6} xs={8}>
                                <Form.Item>
                                    <PrimaryButton
                                        htmlType="submit"
                                        cssClassName="primary-button-small"
                                        title={
                                            translate("pages").feed.newsForm
                                                .buttons.save
                                        }
                                        loading={loading}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </BodyFormLayout>
                </Form>
            ) : (
                <SpiningPage opacity={1} />
            )}
        </>
    );
};
export default ProjectForm;
