import { Drawer } from "antd";
import React from "react";
import "./side-drawer.scss";

export const LEFT_SIDE = "left";
export const RIGHT_SIDE = "right";

const SideDrawer = ({
  open,
  onClose,
  title,
  children,
  className = "",
  side = RIGHT_SIDE,
  ...props
}) => (
  <Drawer
    {...props}
    className={`side-drawer ${className}`}
    placement={side}
    closable={true}
    onClose={onClose}
    visible={open}
    key="bottom"
  >
    {children}
  </Drawer>
);

export default SideDrawer;
