import React, { useContext } from "react";
import { Route } from "react-router-dom";
import indexRoutes from "./router";
import "./index.scss";
import NotificationDrawer from "./components/organisms/NotificationDrawer";
import { NotificationContextProvider } from "./context/NotificationContext";
import ClientSidebar from "./components/organisms/ClientSidebar";

const Client = () => {
  const { visible } = useContext(NotificationContextProvider);

  return (
    <>
      {indexRoutes.map(({ layout: Layout, routes }) =>
        routes.map(({ path: path, exact, component: Component }) => (
          <Route
            key={path}
            path={`${path}`}
            exact={exact}
            render={(props) => (
              <Layout className="client-layout">
                <div className="client-container">
                  <NotificationDrawer visible={visible} />
                  <ClientSidebar/>
                  <Component {...props} />
                </div>
              </Layout>
            )}
          ></Route>
        ))
      )}
    </>
  );
};
export default Client;
