import React from 'react';
import {Card} from 'antd';
import './AnalyticCard.scss';

const AnalyticCard = ({cssClassName, imgUrl, title, ...props}) => {
  return (
    <Card
      {...props}
      className={cssClassName || 'analytics-card'}
      hoverable
      cover={<img alt="icon" src={imgUrl} />}
    >
      <strong>{title}</strong>
    </Card>
  );
};
export default AnalyticCard;
