import React from "react";
import { ResponsiveScatterPlot } from '@nivo/scatterplot'
import "components/molecules/Graphs/Scatter/Scatter.scss";
import GraphContainer from "components/molecules/Graphs/Container";
import {LEGEND_POSITION, LEGEND_DIRECTION, LAYOUT} from "components/molecules/Graphs";

const getTranslateProps = (data, indexBy, legendDirection, legendPosition) => {
  let translateX = 0, translateY = 0;
  if (legendDirection === LEGEND_DIRECTION.ROW) {
    if(legendPosition === LEGEND_POSITION.BOTTOM) translateY = 45;
    if(legendPosition === LEGEND_POSITION.TOP) translateY = -45;
  } else if(legendDirection === LEGEND_DIRECTION.COLUMN) {
    if(legendPosition === LEGEND_POSITION.RIGHT) translateX = 120;
    if(legendPosition === LEGEND_POSITION.LEFT) translateX = -140;
  }

  return {
    translateX,
    translateY
  }
}

const getMarginProps = (data, indexBy, legendDirection, legendPosition, layout) => {
  let right = 40, bottom = 0, top = 24, left = 50;
  if (legendDirection === LEGEND_DIRECTION.ROW) {
    right=10;
    if(legendPosition === LEGEND_POSITION.BOTTOM) {
      bottom=70;
    } else {
      top=70;
      bottom=100;
    }
  } else if(legendDirection === LEGEND_DIRECTION.COLUMN) {
    if(legendPosition === LEGEND_POSITION.RIGHT) {
      right=170;
      bottom=40;
    } else {
      left=170;
    }
  }

  if(layout === LAYOUT.HORIZONTAL && legendDirection === LEGEND_POSITION.LEFT) left = Math.max(...data.map(item => item[indexBy].length))*6

  return {
    right,
    bottom,
    top,
    left,
  }
}

const getAxisProps = (data, indexBy, layout, { xAxisLabel, yAxisLabel }) => {
  let axisBottom = {
    legend: yAxisLabel,
    legendOffset: 40,
    legendPosition: 'middle'
  };
  let axisLeft = {
    legendOffset: -40,
    legend: xAxisLabel,
    legendPosition: 'middle'
  };

  if(layout === LAYOUT.HORIZONTAL) {
    axisLeft = {
      ...axisLeft,
      tickSize: 0,
    }
  }

  return {
    axisBottom,
    axisLeft
  }
}

const ScatterPlot = ({
  indexBy,
  data,
  keyPoints,
  key = 'value',
  title,
  subtitle,
  height = 400,
  legendDirection=LEGEND_DIRECTION.ROW,
  legendPosition=LEGEND_POSITION.TOP,
  layout=LAYOUT.VERTICAL,
  enableGridX=false,
  varyingSize=false,
  maxXScale=10,
  maxYScale=10,
  xAxisLabel,
  yAxisLabel,
}) => {

  return (
    <GraphContainer style={{ height }} title={title} subtitle={subtitle}>
      <ResponsiveScatterPlot
        data={Object.keys(keyPoints).map(keyPoint => ({
          id: keyPoint,
          data: data.filter(item => item[indexBy] === keyPoint)
        }))}
        keys={keyPoints ? Object.keys(keyPoints) : [key]}
        margin={getMarginProps(data, indexBy, legendDirection, legendPosition, layout)}
        colors={({ serieId }) => keyPoints ? keyPoints[serieId] : data.color}
        xScale={{ type: 'linear', min: 0, max: maxXScale }}
        yScale={{ type: 'linear', min: 0, max: maxYScale }}
        nodeSize={varyingSize ? { key: 'size', values: [0, 4], sizes: [9, 32] } : 10}
        enableGridX={enableGridX}
        blendMode="multiply"
        {...getAxisProps(data, indexBy, layout, { xAxisLabel, yAxisLabel })}
        legends={[
          {
            dataFrom: keyPoints ? 'keys' : 'indexes',
            anchor: legendPosition,
            direction: legendDirection,
            justify: false,
            ...getTranslateProps(data, indexBy, legendDirection, legendPosition),
            itemsSpacing: 0,
            itemWidth: keyPoints ? Math.max(...Object.keys(keyPoints).map(keyPoint => keyPoint.length)) * 8 : 98,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        tooltip={({ node: { data: { [indexBy]: indexName } } }) => <div className='chart-tooltip'>{indexName}</div>}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </GraphContainer>
  );
};

export default ScatterPlot;
