import { Divider } from "antd";
import { EventIcon } from "components/atoms/icons";
import { PostIcon } from "components/atoms/icons";
import { Text } from "components/atoms/texts";
import React, { useContext } from "react";
import Moment from "moment";
import "./NotificationItem.scss";
import { I18nContext } from "utils/i18n/locale";

const NotificationItem = ({ item, read, index, onClick }) => {
  const { translate } = useContext(I18nContext);
  return (
    <div className="notification-item" onClick={() => onClick(index)}>
      <div className="notification-item-content">
        <div className="notification-title">
          {item.type !== "0" ? (
            <>
              <PostIcon /> <Text text={item.subject} />
              {!read ? <div className="notification-read" /> : null}
            </>
          ) : (
            <>
              <EventIcon />
              <Text
                text={
                  translate("client").pages.notifications.title.eventReminder
                }
              />
              {!read ? <div className="notification-read" /> : null}
            </>
          )}
        </div>
        <div className="notification-details">
          {item.type === "0" ? (
            <div className="event-notification">
              <Text className="event-subject" text={item.subject} />
              <Text
                className="event-details"
                text={
                  translate("client").pages.notifications.title.topic +
                  item.body
                }
              />
              <Text
                className="event-details"
                text={
                  translate("client").pages.notifications.title.date +
                  Moment(item.sent_at).format("DD MMM YYYY")
                }
              />
            </div>
          ) : (
            <div className="report-notification">
              <Text className="report-body" text={item.body} />
            </div>
          )}
        </div>
        <div className="notification-time">
          <Text text={Moment(item.sent_at).format("DD MMM YYYY")} />
        </div>
      </div>

      <Divider className="notification-divider" />
    </div>
  );
};

export default NotificationItem;
