import React, { useContext } from "react";
import { EmptyComponentLayout } from "components/atoms/layouts";
import { Title } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";
import { PrimaryButton } from "components/atoms/controls";
import "./LoadErrorLayout.scss";

const LoadErrorLayout = ({ onReload }) => {
  const { translate } = useContext(I18nContext);

  return (
    <EmptyComponentLayout>
      <div className="load-error-layout">
        <Title text={translate("pages").flags.loadError} />
        <PrimaryButton
          title={translate("pages").flags.reload}
          onClick={onReload}
        />
      </div>
    </EmptyComponentLayout>
  );
};

export default LoadErrorLayout;
