import React, { useContext } from "react";
import { Menu } from "antd";
import { EditIcon, DuplicateIcon, DeleteIcon } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import { Text } from "components/atoms/texts";
import "./QuestionOptionDropdown.scss";

const { Item } = Menu;
const QuestionOptionDropdown = ({ handleAction, index }) => {
  const { translate } = useContext(I18nContext);

  const onMenuChange = ({ key }) => {
    handleAction(key, index);
  };
  return (
    <Menu className="question-more-icons" onClick={onMenuChange}>
      <Item key="edit">
        <div className="survey-icon">
          <EditIcon />
        </div>
        <Text text={translate("pages").surveyEditor.title.edit} />
      </Item>
      <Item key="duplicate">
        <div className="survey-icon ">
          <DuplicateIcon />
        </div>
        <Text text={translate("pages").surveyEditor.title.duplicate} />
      </Item>
      <Item key="delete">
        <div className="survey-icon ">
          <DeleteIcon />
        </div>
        <Text
          className="delete-text"
          text={translate("pages").surveyEditor.title.delete}
        />
      </Item>
    </Menu>
  );
};

export default QuestionOptionDropdown;
