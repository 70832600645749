import React, { useContext } from "react";
import { PrimaryButton } from "components/atoms/controls";
import { Text } from "components/atoms/texts";
import { Title } from "components/atoms/texts";
import emptySurveySvg from "assets/img/no-survey-placeholder.svg";
import { Link } from "react-router-dom";
import { I18nContext } from "utils/i18n/locale";
import "./EmptySurveyCard.scss";

const EmptySurveyCard = () => {
  const { translate } = useContext(I18nContext);
  return (
    <div className="empty-card-container">
      <img
        className="placeholder-icon"
        src={emptySurveySvg}
        alt={translate("pages").survey.placeholder.noSurvey}
      />

      <Title level={3} text={translate("pages").survey.placeholder.noSurvey} />

      <Text
        className="no-survey-text"
        text={translate("pages").survey.placeholder.noSurveyMessage}
      />
      <Link to="/home/surveys/survey-gallery/">
        <PrimaryButton
          title={translate("pages").survey.create}
          cssClassName="no-survey-button"
        />
      </Link>
    </div>
  );
};

export default EmptySurveyCard;
