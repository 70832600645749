import React, {useContext} from 'react'
import {I18nContext} from "utils/i18n/locale";
import {LAYOUT, LEGEND_DIRECTION, LEGEND_POSITION} from "components/molecules/Graphs";
import ScatterPlot from "components/molecules/Graphs/Scatter";

const AverageDaysToApproveAndCompleteFlag = () => {
	const {translate} = useContext(I18nContext);

	// This should be replaced with an API Call
	const DATA = [
		{
			"x": 0.15,
			"y": 7.4,
			"size": 2,
			"category": "Animals",
		},
		{
			"x": 0.1,
			"y": 2.3,
			"size": 7,
			"category": "Property Maintenance",
		},
		{
			"x": 0.38,
			"y": 3.2,
			"size": 5,
			"category": "Water Services",
		},
		{
			"x": 0.5,
			"y": 7.65,
			"size": 12,
			"category": "Public Works",
		},
	]

	return (
		<ScatterPlot
			height='100%'
			legendPosition={LEGEND_POSITION.TOP}
			legendDirection={LEGEND_DIRECTION.ROW}
			layout={LAYOUT.HORIZONTAL}
			title={translate("pages").analytics.daysToApprove}
			subtitle={translate("pages").analytics.daysToApproveSub}
			xAxisLabel={translate("pages").analytic.editor.charts.completionVelocity.daysToApprove}
			yAxisLabel={translate("pages").analytic.editor.charts.completionVelocity.daysToComplete}
			data={DATA}
			varyingSize={true}
			indexBy="category"
			maxXScale={0.7}
			maxYScale={10}
			keyPoints={{
				"Animals": "#D1E76C",
				"Property Maintenance": "#75FACF",
				"Water Services": "#DA8BE3",
				"Public Works": "#A080E1",
			}}/>
	)
}

export default AverageDaysToApproveAndCompleteFlag;
