import React from "react";
import Modal from "components/atoms/modal";
import { Text } from "components/atoms/texts";
import { Title } from "components/atoms/texts";
import "./KnowledgeDeleteModal.scss";

const KnowledgeDeleteModal = ({ show, translate, closeModal, onOk }) => {
  return (
    <Modal
      visible={show}
      className="user-list-modal"
      title={
        <Title
          level={2}
          text={translate("pages").knowledge.modal.title}
          className="danger-text modal-title"
        />
      }
      cancelText={<Text text={translate("pages").knowledge.modal.no} />}
      okText={
        <Text
          text={translate("pages").knowledge.modal.delete}
          className="danger-text"
        />
      }
      onCancel={closeModal}
      onOk={onOk}
    >
      {translate("pages").knowledge.modal.body}
    </Modal>
  );
};

export default KnowledgeDeleteModal;
