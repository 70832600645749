import React, { useContext, useRef, useState } from "react";
import BottomDrawer from "components/atoms/bottom-drawer";
import { I18nContext } from "utils/i18n/locale";
import { PrimaryButton } from "components/atoms/controls";
import { AttachImageIcon } from "components/atoms/icons";
import { Button, Form, Row } from "antd";
import { DataInput } from "components/atoms/inputs";
// import { KnowledgeContextProvider } from "context/KnowledgeContext";
import "./KnowledgeCategoryForm.scss";
import { SourceImage } from "components/atoms/media";
import { useDispatch, useSelector } from "react-redux";
import {
  createKnowledgeCategory,
  getKnowledgeCategories,
} from "redux/slices/knowledge";
import { unwrapResult } from "@reduxjs/toolkit";
import { SwalAlerts } from "components/atoms/outputs";
// import { makeId, resizeImage } from "utils/common";
// import { Storage } from "aws-amplify";
// import { FileDragger } from "components/atoms/media";
import { uploadDrag } from "components/atoms/media";
const getFileDetails = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = (e) => {
      resolve({
        file,
        base64: reader.result,
      });
    };

    reader.onerror = (e) => {
      reject(e);
    };

    reader.readAsDataURL(file);
  });
};

const ActionFooter = ({ onSubmit, onAttachImages, loadingAction, onSave }) => {
  const fileRef = useRef(null);

  const onUpload = (e) => {
    if (!e?.target?.files || e?.target?.files.length === 0) return;

    (async () => {
      const file = e?.target?.files[0];
      const image = await getFileDetails(file);
      onAttachImages(image);
    })().finally(() => {});
  };

  return (
    <Row justify="end" align="middle" gutter={2}>
      <Button
        onClick={() => fileRef.current.click()}
        className="attach-image-btn"
        shape="circle"
      >
        <AttachImageIcon />
      </Button>
      <input type="file" multiple onChange={onUpload} hidden ref={fileRef} />
      <PrimaryButton
        onClick={onSubmit}
        htmlType="submit"
        cssClassName="save-btn"
        title={"Save"}
        loading={loadingAction}
      />
    </Row>
  );
};

const KnowledgeCategoryForm = ({ close, setClose }) => {
  const { translate } = useContext(I18nContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [categoryImage, setCategoryImage] = useState(null);
  // const { setCategories } = useContext(KnowledgeContextProvider);
  const loadingAction = useSelector((state) => state.knowledge.loadingAction);
  const dispatch = useDispatch();
  const imageUploaded = (data) => {
    setCategoryImage(data);
  };

  // const getFileReference = (file) => {
  //   setCategoryImage(file);
  // };


  const onRemoveImage = () => {
    setCategoryImage(null);
  };
  const onFinish = (data) => {
    if (categoryImage) {
      // uploadDrag(categoryImage)
      uploadDrag(
        "knowledgebase",
        categoryImage.file,
        undefined,
        "png",
        "image/png"
      )
        .then((img) => {
          let createData = { ...data };
          createData.image = img.key;
          dispatch(createKnowledgeCategory(createData))
            .then(unwrapResult)
            .then((res) => {
              form.resetFields();
              setClose(false);
              SwalAlerts({
                type: "success",
                title: translate("pages").knowledge.alerts.createCategory.title,
                text: translate("pages").knowledge.alerts.createCategory.text,
              });
              dispatch(getKnowledgeCategories());
            })
            .catch((err) => {
              console.log(err, "hubo un error");
            });
        })
        .catch((error) => {});
    } else {
      dispatch(createKnowledgeCategory(data))
        .then(unwrapResult)
        .then((res) => {
          form.resetFields();
          setClose(false);
          SwalAlerts({
            type: "success",
            title: translate("pages").knowledge.alerts.createCategory.title,
            text: translate("pages").knowledge.alerts.createCategory.text,
          });
          dispatch(getKnowledgeCategories());
        })
        .catch((err) => {
          console.log(err, "hubo un error");
        });
    }

    // setCategories((prev) => [...prev, { ...data, image: categoryImage }]);
  };
  return (
    <div className="knowledge-category-create">
      <BottomDrawer
        isSmall
        className="bottom-altern"
        title={translate("pages").knowledge.title.newCategory}
        footer={
          <ActionFooter
            loadingAction={loadingAction}
            onSubmit={() => {
              // setClose(false);
              formRef.current.submit();
            }}
            onAttachImages={imageUploaded}
          />
        }
        open={close}
        onClose={() => setClose(false)}
      >
        <Form
          className="knowledge-category-form"
          onFinish={onFinish}
          ref={formRef}
          form={form}
        >
          <Form.Item name="title" rules={[{ required: true }]}>
            <DataInput
              placeholder={
                translate("pages").knowledge.placeholder.createCategoryTitle
              }
              title={translate("pages").knowledge.title.createCategoryTitle}
            />
          </Form.Item>

          <Form.Item
            name="description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DataInput
              type="textarea"
              rows={6}
              placeholder={translate("pages").knowledge.placeholder.description}
              title={translate("pages").knowledge.title.description}
              allowClear
            />
          </Form.Item>
        </Form>
        {/* <Form.Item name="image">
          <FileDragger
            isSmall
            // currentFile={previewData}
            label={translate("pages").projects.createProjectForm.labels.logo}
            getFileReference={getFileReference}
          />
        </Form.Item> */}
        {categoryImage && (
          <div className="attached-image-container">
            <SourceImage
              onRemove={() => onRemoveImage()}
              width={120}
              alt={categoryImage}
              height={"100%"}
              src={categoryImage.base64}
            />
          </div>
        )}
      </BottomDrawer>
    </div>
  );
};

export default KnowledgeCategoryForm;
