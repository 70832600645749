import React, { useContext, useState } from "react";
import "./UserListHeader.scss";
import { Row, Col } from "antd";
import SearchBar from "components/atoms/search";
import { Title } from "components/atoms/texts";
import { PrimaryButton } from "components/atoms/controls";
import { I18nContext } from "utils/i18n/locale";
import { FilterIcon } from "components/atoms/icons";

const UserListHeader = ({ openDrawer, handleSearch, userList, openInvite }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Row
      mb={5}
      className="user-list-header"
      justify="space-between"
      align="strech"
    >
      <Col md={6} lg={12} align="middle" justify="flex-start">
        <Title
          justify="flex-start"
          align="middle"
          text={
            userList
              ? translate("pages").users.title.userList
              : translate("pages").settings.users.title
          }
          style={{
            textAlign: "left",
          }}
        />
      </Col>
      <Col md={18} lg={12}>
        <Row justify="space-between">
          <Col xs={0} md={2}></Col>
          {userList && (
            <Col xs={14} className="user-list-search-bar-container">
              {/* <FilterIcon className="filter-icon"/> */}
              <SearchBar
                className="user-list-search-bar"
                placeholder={translate("pages").settings.header.searchUsers}
                handleSearch={handleSearch}
              />
            </Col>
          )}
          {userList && (
            <Col md={7} xs={9}>
              <PrimaryButton
                title={translate("pages").settings.header.addAppUser}
                cssClassName="search-button"
                onClick={() => openInvite()}
                // onClick={() => openDrawer(false)}
              />
            </Col>
          )}
          {!userList && (
            <Col md={7} xs={9}>
              <PrimaryButton
                title={translate("pages").settings.header.addUser}
                cssClassName="search-button"
                // onClick={() => openInvite()}
                onClick={() => openDrawer(false)}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default UserListHeader;
