import React, { useReducer } from "react";
import i18n from "i18next";

import EN from "./en.json";
import ES from "./es.json";

const translations = {
  en: EN,
  es: ES,
};

export const getCurrentLocale = () => {
  return (
    i18n.language ||
    (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
    "en"
  ).substring(0, 2);
};

export const getCurrentLanguage = () => {
  return window.navigator.userLanguage || window.navigator.language;
};

const currentLanguage = getCurrentLocale();
const getTranslate = (langCode) => (key) => translations[langCode][key] || key;

const initialState = {
  langCode: currentLanguage,
  translate: getTranslate(currentLanguage),
};

export const I18nContext = React.createContext();

export const I18nContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setLanguage":
        return {
          langCode: action.payload,
          translate: getTranslate(action.payload),
        };
      default:
        return { ...initialState };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <I18nContext.Provider value={{ ...state, dispatch }}>
      {children}
    </I18nContext.Provider>
  );
};
