import ClientHome from "client/pages/Home";
import ClientEvents from "client/pages/Events";
import ViewEvent from "client/pages/ViewEvent";
import ViewProgram from "client/pages/ViewProgram";

import NotificationDetail from "client/pages/Notification/NotificationDetail";
import EditProfile from "client/pages/EditProfile";
import ClientSurvey from "client/pages/SurveySa";
import SaEvents from "client/pages/SaEvents";
import AboutProject from "client/pages/AboutProject";
import FAQ from "client/pages/FAQ";
import Onboarding from "client/pages/Onboarding";

const prefix = "/launchsa";

const routes = [
  {
    path: `${prefix}/home`,
    component: ClientHome,
    exact: true,
  },
  {
    path: `${prefix}/events`,
    component: SaEvents,
    exact: true,
  },
  {
    path: `${prefix}/events/viewevent`,
    component: ViewEvent,
    exact: true,
  },
  {
    path: `${prefix}/programs/viewprograms`,
    component: ViewProgram,
    exact: true,
  },
  // {
  //   path: `${prefix}`,
  //   component: EditProfile,
  //   exact: true,
  // },
  {
    path: `${prefix}/survey`,
    component: ClientSurvey,
    exact: true,
  },
  {
    path: `${prefix}/about`,
    component: AboutProject,
    exact: true,
  },
  {
    path: `${prefix}/FAQ`,
    component: FAQ,
    exact: true,
  },
  {
    path: `${prefix}/onboarding`,
    component: Onboarding,
    exact: true,
  },
];

export default routes;
