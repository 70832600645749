import React, {useContext} from 'react'
import {I18nContext} from "utils/i18n/locale";
import GraphContainer from "components/molecules/Graphs/Container";
import Map from "components/atoms/map";
import "./HeatMap.scss";

const HeatMap = () => {
	const { translate } = useContext(I18nContext);

	return (
		<GraphContainer
			style={{ height: '100%' }}
			className="heat-map-container"
			title={translate("pages").analytic.editor.sidePane.menu.heatMap}>
			<Map
				height='100%'
				heatPoints={{
					positions: [
						{lat: 32.4556198, lng: 74.1847232},
						{lat: 31.3558198, lng: 74.1847019},
					],
					options: {
						radius: 100,
						opacity: 1,
					}
				}}
				showPin={false}
				location={{ lat: 31.3558198, lng: 74.1847019 }}
				zoom={16}
				mapTypeControl={false} />
		</GraphContainer>
	)
}

export default HeatMap;
