import React, {useContext} from 'react'
import BarGraph from "components/molecules/Graphs/Bar";
import {I18nContext} from "utils/i18n/locale";

const FlagsByDepartment = () => {
	const {translate} = useContext(I18nContext);

	// This should be replaced with an API Call
	const BAR_GRAPH_DATA = [
		{
			"department": "Management",
			[translate("pages").analytics.taskClosed]: 200,
			[translate("pages").analytics.flagsAssigned]: 100,
		},
		{
			"department": "Security",
			[translate("pages").analytics.taskClosed]: 150,
			[translate("pages").analytics.flagsAssigned]: 100,
		},
		{
			"department": "Finance",
			[translate("pages").analytics.taskClosed]: 100,
			[translate("pages").analytics.flagsAssigned]: 100,
		},
		{
			"department": "Medical",
			[translate("pages").analytics.taskClosed]: 50,
			[translate("pages").analytics.flagsAssigned]: 100,
		},
		{
			"department": "Education",
			[translate("pages").analytics.taskClosed]: 250,
			[translate("pages").analytics.flagsAssigned]: 100,
		},
	]

	return (
		<BarGraph
			height="100%"
			title={translate("pages").analytics.departmentFlags}
			keyPoints={{
				[translate("pages").analytics.taskClosed]: "#75FACF",
				[translate("pages").analytics.flagsAssigned]: "#00B999"
			}}
			indexBy="department" data={BAR_GRAPH_DATA}/>
	)
}

export default FlagsByDepartment;
