import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import "./checkbox-group.scss";
import { getLocalSettings } from "utils/common";
import { useSelector } from "react-redux";

export const TYPE_ICONS = "icons";

const CheckboxGroup = ({
  options = [],
  type = TYPE_ICONS,
  selected = {},
  className = "",
  onChange,
  multiSelect = false,
  defaultColor = "#FFFFFF",
  selectedColor = "#141414",
  iconSelected,
  ...props
}) => {
  const userSettings = useSelector((state) => state.projects.settings);
  const [settings, setSettings] = useState(userSettings);
  useEffect(() => {
    setSettings(userSettings);
  }, [userSettings]);
  return (
    <Row
      align="middle"
      justify="end"
      className={`checkbox-group ${className}`}
      {...props}
    >
      {options.map((option, index) => {
        const Icon = option.icon;
        return (
          <Col
            onClick={() => {
              onChange(
                multiSelect
                  ? { ...selected, [option.key]: true }
                  : { [option.key]: true }
              );
            }}
            style={{
              backgroundColor: selected[option.key]
                ? settings.sibebar_color ?? selectedColor
                : defaultColor,
            }}
            key={index.toString()}
          >
            <Icon
              color={
                selected[option.key]
                  ? defaultColor
                  : iconSelected || settings.sibebar_color
                  ? settings.sibebar_color
                  : selectedColor
              }
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default CheckboxGroup;
