import VerifyCode from "pages/VerifyCode/";

const prefix = "/code";

const routes = [
  {
    path: `${prefix}`,
    component: VerifyCode,
    exact: true,
  },
];

export default routes;
