import React, { useContext } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Text } from "components/atoms/texts";
import QuestionSettingsDropdownAction from "components/molecules/QuestionSettingsDropdownAction";
import QuestionSettingsToggleAction from "components/molecules/QuestionSettingsToggleAction";
import { I18nContext } from "utils/i18n/locale";
import "./QuestionSettings.scss";

const QuestionSettings = ({
  questionType,
  actionState,
  index,
  handleSettingsClose,
  prevMode,
}) => {
  const { translate } = useContext(I18nContext);
  const questionTypeChoice = {
    multiChoiceSingle: translate("pages").surveyEditor.title.multiChoiceSingle,
    multiChoiceMulti: translate("pages").surveyEditor.title.multiChoiceMulti,
    boolean: translate("pages").surveyEditor.title.boolean,
    text: translate("pages").surveyEditor.title.text,
  };
  const handleSettingsActions = (value, actionKey) => {
    actionState(actionKey, index, value);
  };

  return (
    <div className="question-settings-wrapper">
      <Row className="question-settings-title">
        <Col xs={22} md={14} lg={14} xl={14}>
          <Text
            text={
              <>
                {translate("pages").surveyEditor.title.question} (
                <span className="question-type-choice">
                  {questionTypeChoice[questionType]}
                </span>
                ){` ${translate("pages").surveyEditor.title.settings}`}
              </>
            }
          />
        </Col>
        <Col xs={2} md={10} lg={10} className="close-settings">
          <CloseOutlined onClick={() => handleSettingsClose(index, prevMode)} />
        </Col>
      </Row>
      <QuestionSettingsToggleAction
        text={translate("pages").surveyEditor.title.required}
        defaultChecked
        actionKey="required"
        actionState={handleSettingsActions}
        className="question-action-toggle"
      />
      <QuestionSettingsToggleAction
        text={translate("pages").surveyEditor.title.description}
        actionKey="description"
        actionState={handleSettingsActions}
        className="question-action-description"
      />
      {questionType === "multiChoiceMulti" && (
        <QuestionSettingsDropdownAction
          actionKey="selectionLimit"
          text={translate("pages").surveyEditor.title.limit}
          actionState={handleSettingsActions}
        />
      )}
    </div>
  );
};

export default QuestionSettings;
