import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
} from "react";
import { SpiningPage } from "components/atoms/icons";
import { Alert } from "components/atoms/outputs";
import { I18nContext } from "utils/i18n/locale";
import { useHistory, useLocation } from "react-router-dom";
import { signIn } from "api/amplifyAuthAPI";
import Cookies from "universal-cookie";
const AuthTemplate = lazy(() => import("components/templates/AuthTemplate"));

const renderLoader = () => <SpiningPage opacity={1} />;

const Login = (props) => {
  const cookies = new Cookies();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);
  const isMounted = useRef(null);
  const history = useHistory();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const changeStatusLoading = (statusBoolean) => {
    setLoading(statusBoolean);
  };

  const handleLogin = (values) => {
    changeStatusLoading(true);
    signIn(values)
      .then((resp) => {
        if (resp.challengeName === "NEW_PASSWORD_REQUIRED") {
          Alert(
            "warning",
            `${translate("pages").login.messages.requirenewpassword} `
          );
          history.push({
            pathname: "/change",
            user: resp,
            search: location?.search,
          });
        } else {
          // Alert(
          //   "success",
          //   `${translate("pages").login.messages.loginsuccess} `
          // );
          const token = resp.signInUserSession.accessToken.jwtToken;
          const tokenExpires = resp.signInUserSession.accessToken.payload.exp;
          if (location.search) {
            cookies.set("organization", "launchsa", { path: "/" });
          }

          props.history.push(`/verify?token=${token}&expireIn=${tokenExpires}`);
        }
      })
      .catch((err) => {
        console.log(err, "the err");

        if (err.code === "NetworkError") {
          Alert("error", err.message);
        } else if (err.code === "UserNotConfirmedException") {
          history.push({
            pathname: "/signup/1",
            resend: true,
            credentials: values,
          });
          Alert(
            "info",
            translate("pages").signup.modals.verifyMail.alerts.codeResent
          );
        } else {
          Alert("error", translate("pages").login.messages.loginerror);
        }
        changeStatusLoading(false);
        console.error(err);
      })
      .finally(() => {
        if (isMounted.current) {
          changeStatusLoading(false);
        }
      });
  };
  return (
    <Suspense fallback={renderLoader()}>
      <AuthTemplate handleLogin={handleLogin} loading={loading} login />
    </Suspense>
  );
};
export default Login;
