import React, { useContext } from "react";
import { Card, Divider } from "antd";
import DraftRichText from "components/molecules/DraftRichText";
import { I18nContext } from "utils/i18n/locale";
import PostEditorHeader from "components/organisms/PostEditorHeader";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { PrimaryButton } from "components/atoms/controls";
import "./KnowledgeEditorOrganism.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createKnowledgePost,
  getKnowledgeCategories,
  updateKnowledgePost,
} from "redux/slices/knowledge";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router";
import { SwalAlerts } from "components/atoms/outputs";

const KnowledgeEditorOrganism = () => {
  const { translate } = useContext(I18nContext);
  const history = useHistory();
  const isError = Math.random() < 0.5;

  const dispatch = useDispatch();
  const newPostData = useSelector((state) => state.knowledge.newPost);
  const loading = useSelector((state) => state.knowledge.loadingAction);

  const onFinish = () => {
    let createPostData = { ...newPostData };
    createPostData.content = JSON.stringify(createPostData.content);
    if (createPostData.id) {
      dispatch(updateKnowledgePost(createPostData))
        .then(unwrapResult)
        .then(() => {
          SwalAlerts({
            title: translate("pages").knowledge.alerts.save.success.title,
            text: translate("pages").knowledge.alerts.save.success.subtitle,
            type: "success",
          });
          history.goBack();
        })
        .catch(() => {
          SwalAlerts({
            title: translate("pages").knowledge.alerts.save.error.title,
            text: translate("pages").knowledge.alerts.save.error.subtitle,
            type: "error",
          });
        });
    } else {
      // TODO: validate that title is filled
      dispatch(createKnowledgePost(createPostData))
        .then(unwrapResult)
        .then(() => {
          SwalAlerts({
            title: translate("pages").knowledge.alerts.save.success.title,
            text: translate("pages").knowledge.alerts.save.success.subtitle,
            type: "success",
          });
          dispatch(getKnowledgeCategories());
          history.push({
            pathname: "/home/knowledge/",
          });
        })
        .catch(() => {
          SwalAlerts({
            title: translate("pages").knowledge.alerts.save.error.title,
            text: translate("pages").knowledge.alerts.save.error.subtitle,
            type: "error",
          });
        });
    }
  };

  return (
    <Card className="editor-card">
      <PostEditorHeader />
      <DraftRichText />
      <Divider />
      <div className="post-editor-footer">
        {/* {isError ? (
          <InfoCircleOutlined className="post-editor-fail" />
        ) : (
          <CheckCircleOutlined className="post-editor-check" />
        )} */}
        <PrimaryButton
          title={translate("pages").knowledge.editor.savePost}
          onClick={() => onFinish()}
          loading={loading}
        />
      </div>
    </Card>
  );
};

export default KnowledgeEditorOrganism;
