import React from "react";
import EmptyComponent from "components/organisms/EmptyComponent";
import { EmptyComponentLayout } from "components/atoms/layouts";

const EmptyComponentTemplate = (props) => {
  return (
    <EmptyComponentLayout>
      <EmptyComponent {...props} />
    </EmptyComponentLayout>
  );
};

export default EmptyComponentTemplate;
