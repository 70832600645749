import NewsTable from "components/organisms/DataTable";
import CardContainer from "components/organisms/CardContainer";
import { Fragment, useContext } from "react";
import { Col, Divider, Row } from "antd";
import { PrimaryButton } from "components/atoms/controls";
import { I18nContext } from "utils/i18n/locale";
import SearchBar from "components/atoms/search";
import { SwitchButton } from "components/atoms/controls";
import { PlusOutlined } from "@ant-design/icons";
import Cookies from "universal-cookie";
import "./NewsOrganism.scss";

const NewsOrganism = (props) => {
  const { type, handleView, redirectToNewsForm, handleSearch } = props;
  const { translate } = useContext(I18nContext);

  const cookies = new Cookies();
  const validateLaunchSA = () => {
    const organization = cookies.get("organization");
    if (organization) {
      return true;
    }
  };
  return (
    <Fragment>
      <Row justify="end" gutter={[12, 6]} className="news-organism-container">
        <Col xl={9} md={24} sm={24} xs={24}>
          <span className="title">
            {validateLaunchSA()
              ? translate("pages").feed.news.titleLaunchSa
              : translate("pages").feed.news.title}
          </span>
        </Col>
        <Col xl={8} md={24} sm={24} xs={24}>
          <SearchBar
            placeholder={
              validateLaunchSA()
                ? translate("pages").feed.news.searchBox.placeholderLaunchSa
                : translate("pages").feed.news.searchBox.placeholder
            }
            handleSearch={handleSearch}
          />
        </Col>
        <Col xl={4} md={24} sm={24} xs={24}>
          <PrimaryButton
            onClick={() => {
              redirectToNewsForm();
            }}
            cssClassName="primary-button-icon"
            icon={<PlusOutlined />}
            title={
              validateLaunchSA()
                ? translate("pages").feed.news.buttons.createPrograms
                : translate("pages").feed.news.buttons.createNews
            }
          />
        </Col>
        <Col xl={3} lg={3} md={4} sm={4} xs={6}>
          <SwitchButton handleView={handleView} />
        </Col>
      </Row>
      <Divider />
      <div className="news-organism-container ">
        <span className="subtitle">
          {validateLaunchSA()
            ? translate("pages").feed.news.subtitleLaunchSa
            : translate("pages").feed.news.subtitle}
        </span>
      </div>
      {type === "list" ? (
        <NewsTable props={props} />
      ) : (
        <CardContainer props={props} />
      )}
    </Fragment>
  );
};
export default NewsOrganism;
