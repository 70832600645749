import React, {useState} from 'react';
import "./Eventscontainer.scss";
import EventsMeetingCards from 'client/components/molecules/EventsMeetingCards';
import EventsWelcomeHeader from 'client/components/molecules/EventsWelcomeHeader';

const EventsContainer = ({ eventsList }) => {
  const EventsCardRenderer = () => {
    let value = []
    eventsList.forEach((events, idx) => {
      value.push(
        <EventsMeetingCards events={events} /> 
      )
    })
    return (value)
  }


  return(
    <div className="events-card-container">
      <EventsCardRenderer />
    </div>
  )
}


export default EventsContainer;
