import Flags from "pages/Flags";
import FlagsDetails from "pages/Flags/Details";

const prefix = "/flags";

const routes = [
  {
    path: `${prefix}`,
    component: Flags,
    exact: true,
  },
  {
    path: `${prefix}/details/:id`,
    component: FlagsDetails,
    exact: true,
  },
];

export default routes;
