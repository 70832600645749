import React from "react";
import "./MainOptions.scss";
import MainOption from "components/client/components/molecules/MainOption";
import {ReactComponent as CalendarSvg} from "assets/svgs/calendar.svg"
import {ReactComponent as NewsFeedSvg} from "assets/svgs/newsFeed.svg"
import {ReactComponent as InfoSvg} from "assets/svgs/info.svg"
import {ReactComponent as SurveySvg} from "assets/svgs/survey.svg"


const MainOptions = (props) => {
  const {onClick} = props;
    const data = [
        {title: 'Events',route:'/client/events/', page:'EventsScreen', color: '#75FACF',icon:<CalendarSvg/>},
        {title: 'News',route:'', page:'FeedScreen', color: '#E7FD6C',icon:<NewsFeedSvg/>, param:1},

        {title: 'Projects',route:'', page:'Projects', color: '#B696F7',icon:<InfoSvg/>},
        {title: 'Survey',route:'/client/survey/', page:'Survey', color: '#F79BB3',icon:<SurveySvg/>},
      ];

    return(
        <div className="options-container">
          {data.map((item, index) => (
              <MainOption key={index} item={item} onClick={onClick} />
          ))}
        </div>
    )
}

export default MainOptions;
