export const LEGEND_DIRECTION = {
	ROW: 'row',
	COLUMN: 'column'
}

export const LEGEND_POSITION = {
	TOP: 'top',
	BOTTOM: 'bottom',
	RIGHT: 'right',
	LEFT: 'left',
}

export const LAYOUT = {
	VERTICAL: 'vertical',
	HORIZONTAL: 'horizontal'
}
