import { Typography } from "antd";
import { title_style, subtitle_style, text_style } from "config/styles/text";

const { Title: AntTitle, Text: AntText } = Typography;

export const Text = ({ style, text, isRequired, noInherit, ...props }) => (
  <AntText
    {...props}
    style={
      noInherit
        ? {}
        : {
            ...text_style,
            ...style,
          }
    }
  >
    {text} {isRequired ? <span>*</span> : null}
  </AntText>
);

export const Title = ({ level, style, text, opacity, ...props }) => (
  <AntTitle
    {...props}
    level={level || 3}
    style={{
      opacity: opacity,
      ...(level && level !== 3 ? subtitle_style : title_style),
      ...style,
    }}
  >
    {text}
  </AntTitle>
);

export const SearchText = ({
  style,
  text,
  isRequired,
  innerHTML,
  ...props
}) => (
  <span
    {...props}
    style={{
      ...text_style,
      ...style,
    }}
    dangerouslySetInnerHTML={innerHTML}
  />
);
