import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import communicationAPI from "api/communicationAPI";
import { getCurrentProjectId } from "utils/common";

const awsErrorSerialized = {
  error: "ASW Error",
};

const arrayRemove = (arr, value) => {
  return arr.filter(function (ele) {
    return ele != value;
  });
};

const awsError = (resp) => {
  console.log(resp)
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};

export const handleEmailSelection = (id) => async (dispatch) => {
  try {
    dispatch(startGetForm());
    dispatch(successGetForm(id));
  } catch (error) {
    dispatch(errorGetForm(error.toString()));
  }
};
export const handleEmailUnselection = (id) => async (dispatch) => {
  try {
    dispatch(startDeselectEmail());
    dispatch(successDeselectEmail(id));
  } catch (error) {
    dispatch(errorDeselectEmail(error.toString()));
  }
};
export const handleAddressSelection = (email) => async (dispatch) => {
  try {
    dispatch(startAdrdressSelection());
    dispatch(successAddressSelection(email));
  } catch (error) {
    dispatch(errorAddressSelection(error.toString()));
  }
};
export const handleAddressDeselection = (email) => async (dispatch) => {
  try {
    dispatch(startAdrdressDeselection());
    dispatch(successAddressDeselection(email));
  } catch (error) {
    dispatch(errorAddressDeselection(error.toString()));
  }
};

export const getReceivedEmails = createAsyncThunk(
  "emails/getReceivedEmails",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const receivedEmails = await communicationAPI.getReceivedEmails(
        project_id
      );
      awsError(receivedEmails);
      if (receivedEmails.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return receivedEmails.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getClientReceivedEmails = createAsyncThunk(
  "emails/getClientReceivedEmails",
  async (id, { rejectWithValue }) => {
    try {
      const receivedEmails = await communicationAPI.getReceivedEmails(id);
      awsError(receivedEmails);
      if (receivedEmails.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return receivedEmails.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getReceivedEmailsReload = createAsyncThunk(
  "emails/getReceivedEmailsReload",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const receivedEmails = await communicationAPI.getReceivedEmails(
        project_id
      );
      awsError(receivedEmails);
      if (receivedEmails.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return receivedEmails.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getEmail = createAsyncThunk(
  "emails/getEmail",
  async (id, { rejectWithValue }) => {
    try {
      const receivedEmail = await communicationAPI.getUserEmail(id);
      awsError(receivedEmail);
      if (receivedEmail.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return receivedEmail.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getArchivedEmails = createAsyncThunk(
  "emails/getArchivedEmails",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const archivedEmails = await communicationAPI.getArchivedEmails(
        project_id
      );
      awsError(archivedEmails);
      if (archivedEmails.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return archivedEmails.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getClientArchivedEmails = createAsyncThunk(
  "emails/getArchivedEmails",
  async (project_id, { rejectWithValue }) => {
    try {
      const archivedEmails = await communicationAPI.getArchivedEmails(
        project_id
      );
      awsError(archivedEmails);
      if (archivedEmails.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return archivedEmails.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createEmail = createAsyncThunk(
  "emails/createEmail",
  async (item, { rejectWithValue }) => {
    try {
      const project_id = getCurrentProjectId();
      const email = await communicationAPI.createEmail(project_id, item);
      awsError(email);
      return email.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const createEmailClient = createAsyncThunk(
  "emails/createEmailClient",
  async (item, { rejectWithValue }) => {
    try {
      let data = { ...item };
      let project_id = data.project_id;
      delete data.id;

      const email = await communicationAPI.createEmailClient(project_id, data);
      awsError(email);
      return email.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const createReply = createAsyncThunk(
  "emails/createReply",
  async (item, { rejectWithValue }) => {
    try {
      const id = item.id;

      let create_reply = {
        ...item,
      };
      delete create_reply.id;
      const email = await communicationAPI.createReply(id, create_reply);
      awsError(email);
      return email.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const deleteEmail = createAsyncThunk(
  "knowledges/deleteEmail",
  async (id, { rejectWithValue }) => {
    try {
      const email = await communicationAPI.deleteEmail(id);
      awsError(email);
      return email.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const starrEmail = createAsyncThunk(
  "knowledges/starrEmail",
  async (id, { rejectWithValue }) => {
    try {
      const email = await communicationAPI.starrEmail(id);
      console.log(email);
      awsError(email);
      return email.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const archiveEmail = createAsyncThunk(
  "knowledges/archiveEmail",
  async (id, { rejectWithValue }) => {
    try {
      const email = await communicationAPI.archiveEmail(id);
      awsError(email);
      return email.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const archiveEmails = createAsyncThunk(
  "knowledges/archiveEmails",
  async (emails, { rejectWithValue }) => {
    try {
      const archivedEmails = await communicationAPI.archiveEmails(emails);
      awsError(archivedEmails);
      return archivedEmails.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const searchByEmail = createAsyncThunk(
  "emails/searchByEmail",
  async (email, { rejectWithValue }) => {
    try {
      const project_id = getCurrentProjectId();
      const searched = await communicationAPI.searchWorkerByEmail(
        project_id,
        email
      );
      awsError(searched);
      if (searched.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return searched.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const searchByEmailClient = createAsyncThunk(
  "emails/searchByEmailClient",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const searched = await communicationAPI.searchWorkerByEmail(
        data.project_id,
        data.email
      );
      awsError(searched);
      if (searched.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return searched.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

const initialState = {
  receivedEmails: [],
  archivedEmails: [],
  starredEmails: [],
  searchedEmails: [],
  selectedEmails: [],
  selectedAddress: [],
  displayingEmail: [],
  error: null,
  loading: true,
  reloading: false,
  emailCreated: false,
  loadingAction: false,
  sendingEmail: false,
  sendingReply: false,
};

const emails = createSlice({
  name: "emails",
  initialState,
  reducers: {
    startGetForm(state, action) {},
    successGetForm(state, action) {
      let found = state.selectedEmails.find((item) => item === action.payload);
      if (!found) {
        state.selectedEmails.push(action.payload);
      }
    },
    errorGetForm(state, action) {
      console.log("error en el preview data", action);
    },
    startDeselectEmail(state, action) {},
    successDeselectEmail(state, action) {
      state.selectedEmails = arrayRemove(state.selectedEmails, action.payload);
    },
    errorDeselectEmail(state, action) {
      console.log("error en el deselect data", action);
    },
    startAdrdressSelection(state, action) {},
    successAddressSelection(state, action) {
      state.selectedAddress.push(action.payload);
    },
    errorAddressSelection(state, action) {
      console.log("error en el deselect data", action);
    },
    startAdrdressDeselection(state, action) {},
    successAddressDeselection(state, action) {
      state.selectedAddress = arrayRemove(
        state.selectedAddress,
        action.payload
      );
    },
    errorAddressDeselection(state, action) {
      console.log("error en el deselect address", action);
    },
  },
  extraReducers: {
    //Get Email
    [getEmail.pending]: (state, action) => {
      console.info("getEmail pending");
      state.loading = true;
      state.error = null;
      state.displayingEmail = {};
    },
    [getEmail.fulfilled]: (state, action) => {
      console.info("getEmail fullfilled", action.payload.body);
      state.loading = false;
      state.error = null;
      state.displayingEmail = action.payload.body;
    },
    [getEmail.rejected]: (state, action) => {
      console.error("getEmail rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.displayingEmail = [];
    },

    //Get getClientReceivedEmails
    [getClientReceivedEmails.pending]: (state, action) => {
      console.info("getClientReceivedEmails pending");
      state.loading = true;
      state.error = null;
    },
    [getClientReceivedEmails.fulfilled]: (state, action) => {
      console.info("getClientReceivedEmails fullfilled", action.payload.body);
      state.loading = false;
      state.error = null;
      state.receivedEmails = action.payload.body;
    },
    [getClientReceivedEmails.rejected]: (state, action) => {
      console.error("getClientReceivedEmails rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.receivedEmails = [];
    },

    //Get Received Emails
    [getReceivedEmails.pending]: (state, action) => {
      console.info("getReceivedEmails pending");
      state.loading = true;
      state.reloading = true;
      state.error = null;
      //   state.receivedEmails = [];
    },
    [getReceivedEmails.fulfilled]: (state, action) => {
      console.info("getReceivedEmails fullfilled", action.payload.body);
      state.loading = false;
      state.reloading = false;
      state.error = null;
      state.receivedEmails = action.payload.body;
    },
    [getReceivedEmails.rejected]: (state, action) => {
      console.error("getReceivedEmails rejected =>", action.payload);
      state.loading = false;
      state.reloading = false;
      state.error = action.payload;
      state.receivedEmails = [];
    },

    //Get Received Emails Reload
    [getReceivedEmailsReload.pending]: (state, action) => {
      console.info("getReceivedEmailsReload pending");
      state.reloading = true;
      state.error = null;
      //   state.receivedEmails = [];
    },
    [getReceivedEmailsReload.fulfilled]: (state, action) => {
      console.info("getReceivedEmailsReload fullfilled", action.payload.body);
      state.reloading = false;
      state.error = null;
      state.receivedEmails = action.payload.body;
    },
    [getReceivedEmailsReload.rejected]: (state, action) => {
      console.error("getReceivedEmailsReload rejected =>", action.payload);
      state.reloading = false;
      state.error = action.payload;
      state.receivedEmails = [];
    },

    //Get Archived Emails
    [getArchivedEmails.pending]: (state, action) => {
      console.info("getArchivedEmails pending");
      state.loading = true;
      state.error = null;
      state.archivedEmails = [];
    },
    [getArchivedEmails.fulfilled]: (state, action) => {
      console.info("getArchivedEmails fullfilled", action.payload.body);
      state.loading = false;
      state.error = null;
      state.archivedEmails = action.payload.body;
    },
    [getArchivedEmails.rejected]: (state, action) => {
      console.error("getArchivedEmails rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.archivedEmails = [];
    },
    //Get getClientArchivedEmails
    [getClientArchivedEmails.pending]: (state, action) => {
      console.info("getClientArchivedEmails pending");
      state.loading = true;
      state.error = null;
      state.archivedEmails = [];
    },
    [getClientArchivedEmails.fulfilled]: (state, action) => {
      console.info("getClientArchivedEmails fullfilled", action.payload.body);
      state.loading = false;
      state.error = null;
      state.archivedEmails = action.payload.body;
    },
    [getClientArchivedEmails.rejected]: (state, action) => {
      console.error("getClientArchivedEmails rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.archivedEmails = [];
    },

    //Create Email
    [createEmail.pending]: (state, action) => {
      console.info("createEmail pending");
      state.sendingEmail = true;
      state.error = null;
      state.emailCreated = false;
    },
    [createEmail.fulfilled]: (state, action) => {
      console.info("createEmail fullfilled", action.payload);
      state.sendingEmail = false;
      state.error = null;
      state.emailCreated = true;
    },
    [createEmail.rejected]: (state, action) => {
      state.sendingEmail = false;
      state.emailCreated = false;
      state.error = action.payload.error;
      console.error("createEmail rejected =>", action.payload);
    },
    // createEmailClient
    [createEmailClient.pending]: (state, action) => {
      console.info("createEmailClient pending");
      state.sendingEmail = true;
      state.error = null;
      state.emailCreated = false;
    },
    [createEmailClient.fulfilled]: (state, action) => {
      console.info("createEmailClient fullfilled", action.payload);
      state.sendingEmail = false;
      state.error = null;
      state.emailCreated = true;
    },
    [createEmailClient.rejected]: (state, action) => {
      state.sendingEmail = false;
      state.emailCreated = false;
      state.error = action.payload.error;
      console.error("createEmailClient rejected =>", action.payload);
    },

    //Create reply
    [createReply.pending]: (state, action) => {
      console.info("createReply pending");
      state.sendingReply = true;
      state.error = null;
      state.emailCreated = false;
    },
    [createReply.fulfilled]: (state, action) => {
      console.info("createReply fullfilled", action.payload);
      state.sendingReply = false;
      state.error = null;
      state.emailCreated = true;
    },
    [createReply.rejected]: (state, action) => {
      state.sendingReply = false;
      state.emailCreated = false;
      state.error = action.payload.error;
      console.error("createReply rejected =>", action.payload);
    },

    //Delete Email
    [deleteEmail.pending]: (state, action) => {
      console.info("deleteEmail pending");
      state.loadingAction = true;
      state.error = null;
    },
    [deleteEmail.fulfilled]: (state, action) => {
      console.info("deleteEmail fullfilled");
      state.loadingAction = false;
      state.error = null;
    },
    [deleteEmail.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("deleteEmail rejected =>", action.payload);
    },

    //Starr Email
    [starrEmail.pending]: (state, action) => {
      console.info("starrEmail pending");
      state.loadingAction = true;
      state.error = null;
    },
    [starrEmail.fulfilled]: (state, action) => {
      console.info("starrEmail fullfilled");
      state.loadingAction = false;
      state.error = null;
    },
    [starrEmail.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("starrEmail rejected =>", action.payload);
    },

    //Archive Email
    [archiveEmail.pending]: (state, action) => {
      console.info("archiveEmail pending");
      state.loadingAction = true;
      state.error = null;
    },
    [archiveEmail.fulfilled]: (state, action) => {
      console.info("archiveEmail fullfilled");
      state.loadingAction = false;
      state.error = null;
    },
    [archiveEmail.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("archiveEmail rejected =>", action.payload);
    },

    //Archive Emails
    [archiveEmails.pending]: (state, action) => {
      console.info("archiveEmails pending");
      state.loadingAction = true;
      state.error = null;
    },
    [archiveEmails.fulfilled]: (state, action) => {
      console.info("archiveEmails fullfilled");
      state.loadingAction = false;
      state.error = null;
    },
    [archiveEmails.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("archiveEmails rejected =>", action.payload);
    },

    //Search worker by email
    [searchByEmail.pending]: (state, action) => {
      console.info("searchByEmail pending");
      state.loadingAction = true;
      state.error = null;
      state.searchedEmails = [];
    },
    [searchByEmail.fulfilled]: (state, action) => {
      console.info("searchByEmail fullfilled", action.payload.body);
      state.loadingAction = false;
      state.error = null;
      state.searchedEmails = action.payload.body;
    },
    [searchByEmail.rejected]: (state, action) => {
      console.error("searchByEmail rejected =>", action.payload);
      state.loadingAction = false;
      state.error = action.payload;
      state.searchedEmails = [];
    },

    //Search worker by email for client
    [searchByEmailClient.pending]: (state, action) => {
      console.info("searchByEmailClient pending");
      state.loadingAction = true;
      state.error = null;
      state.searchedEmails = [];
    },
    [searchByEmailClient.fulfilled]: (state, action) => {
      console.info("searchByEmailClient fullfilled", action.payload.body);
      state.loadingAction = false;
      state.error = null;
      state.searchedEmails = action.payload.body;
    },
    [searchByEmailClient.rejected]: (state, action) => {
      console.error("searchByEmailClient rejected =>", action.payload);
      state.loadingAction = false;
      state.error = action.payload;
      state.searchedEmails = [];
    },
  },
});

export const {
  startGetForm,
  startDeselectEmail,
  successDeselectEmail,
  successGetForm,
  errorDeselectEmail,
  errorGetForm,
  startAdrdressSelection,
  successAddressSelection,
  errorAddressSelection,
  startAdrdressDeselection,
  successAddressDeselection,
  errorAddressDeselection,
} = emails.actions;
export default emails.reducer;
