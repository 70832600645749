import React from "react"
import { Divider } from "antd"
import { Text } from "../texts"
import "./previous-page.scss"
import { useHistory } from "react-router"
import { BackArowIcon } from "../icons"
const PreviousPage = ({title, link, className}) =>{
    const history = useHistory()
    return (
      <div className={`previous-page-wrapper ${className}`}>
        <div className="previous-page-navigator">
          <BackArowIcon onClick={() => history.push(`${link}`)} margintop="unset" />
          <Text text={title} onClick={() => history.push(`${link}`)} />
        </div>
        <Divider />
      </div>
    );
}

export default PreviousPage;