import News from "pages/News";

const prefix = "/feed";

const routes = [
  {
    path: `${prefix}`,
    component: News,
    exact: true,
  },
];

export default routes;
