import { Image } from "antd";
import { MenuThreeDotsIcon } from "components/atoms/icons";
import { Text } from "components/atoms/texts";
import React, { useContext } from "react";
import { I18nContext } from "utils/i18n/locale";
import "./DirectoryItemCard.scss";
const DirectoryItemCard = ({ data, index, visible, setVisible }) => {
  const { translate } = useContext(I18nContext);
  const handleVisible = (cardIndex) => {
    cardIndex !== visible.index
      ? setVisible((prev) => ({ ...prev, index: cardIndex, show: true }))
      : setVisible((prev) => ({ ...prev, index: cardIndex, show: !prev.show }));
  };
  return (
    <div className="directory-card-container">
      <div className="directory-item-card-wrapper">
        <Image src={data.image} preview={false} />
        <div className="directory-card-item-content">
          <Text text={data.name} />
          <MenuThreeDotsIcon onClick={() => handleVisible(index)} />
        </div>
      </div>
      {visible.show && visible.index === index && (
        <div className="directory-card-item-content-dropdown">
          <Text
            className="dropdown-text"
            text={translate("pages").directory.title.edit}
          />
          <Text
            className="dropdown-text"
            text={translate("pages").directory.title.rename}
          />
          <Text
            className="dropdown-text delete-text"
            text={translate("pages").directory.title.delete}
          />
        </div>
      )}
    </div>
  );
};

export default DirectoryItemCard;
