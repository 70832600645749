import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
} from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { SpiningPage } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import { Row, Col, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AlertWithfunctions, SwalAlerts } from "components/atoms/outputs";
import { Modal } from "antd";
import { CreateElementInput } from "components/atoms/inputs";
import "./Resources.scss";
import { PrimaryButton } from "components/atoms/controls";
import {
  asyncGetData,
  getResources,
  getResourcesCategories,
  deleteResources,
  duplicateResources,
  publishResources,
  createResourcesCategories,
} from "redux/slices/resources";
import { useHistory } from "react-router-dom";
// import noResources from "assets/img/illustrations/no-resources.svg";
// import EventsOrganism from "components/organisms/EventsOrganism";
import ResourcesOrganism from "components/organisms/ResourcesOrganism";
const EmptyComponentTemplate = lazy(() =>
  import("components/templates/EmptyComponentTemplate")
);

const renderLoader = () => <SpiningPage opacity={1} />;

const Resources = (props) => {
  const { translate } = useContext(I18nContext);
  const resourcesFetched = useSelector((state) => state.resources.resources);
  const loading = useSelector((state) => state.resources.loading);
  const resourcesCategoriesFetched = useSelector(
    (state) => state.resources.resourcesCategories
  );
  const resourcesUpdated = useSelector(
    (state) => state.resources.resourcesUpdated
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [searchRef, setSearchRef] = useState(0);
  const isMounted = useRef(null);
  const dispatch = useDispatch();
  let history = useHistory();

  const [resources, setResources] = useState(resourcesFetched);
  const [resourcesAux, setResourcesAux] = useState(resourcesFetched);
  const [resourceCategories, setResourceCategories] = useState(
    resourcesCategoriesFetched
  );
  const [view, setView] = useState("cards");

  useEffect(() => {
    let updateValue = { previewData: {} };
    dispatch(asyncGetData(updateValue));
    dispatch(getResources());
    dispatch(getResourcesCategories());
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setResources(resourcesFetched);
    setResourcesAux(resourcesFetched);
    setResourceCategories(resourcesCategoriesFetched);
  }, [resourcesFetched, resourcesCategoriesFetched]);

  useEffect(() => {
    if (resourcesUpdated) {
      dispatch(getResources());
      dispatch(getResourcesCategories());
    }
  }, [resourcesUpdated]);
  const OpenCreateResource = () => {
    setModalVisible(true);
  };

  const handleSearch = (query, isEmpty) => {
    let result = resourcesAux.filter((n) =>
      n.title.toLowerCase().includes(query.toLowerCase())
    );
    if (searchRef === result.length) {
    } else {
      if (result.length <= 0) {
        setResources([{}]);
      } else {
        if (isEmpty) {
          setResources(resourcesAux);
        } else {
          setResources(result);
        }
      }
    }
    setSearchRef(result.length);
  };

  const handleFilter = (query, isEmpty) => {
    if (query === "all") {
      setResources(resourcesAux);
    } else {
      let result = resourcesAux.filter((n) =>
        n.resource_category_id.toString().includes(query.toString())
      );
      if (result.length <= 0) {
        setResources([{}]);
      } else {
        if (result.length === resourcesAux.length) {
          return;
        } else {
          if (isEmpty) {
            setResources(resourcesAux);
          } else {
            setResources(result);
          }
        }
      }
    }
  };

  const handleView = (view) => {
    setView(view);
  };

  const getResourceTitle = (values) => {
    let updateValue = { title: values.title };
    dispatch(asyncGetData(updateValue));
    history.push({
      pathname: "/home/resources/manage/",
      state: { resource: updateValue },
    });
  };

  const handleEdit = (item) => {
    history.push({
      pathname: "/home/resources/manage/",
      state: { resource: item },
    });
  };

  const handleSaveTopic = async (values) => {
    dispatch(createResourcesCategories(values))
      .then(unwrapResult)
      .then(() => {
        SwalAlerts({
          title: translate("pages").resources.alerts.save.success.title,
          text: translate("pages").resources.alerts.save.success.subtitle,
          type: "success",
        });
      })
      .catch(() => {
        SwalAlerts({
          title: translate("pages").resources.alerts.save.error.title,
          text: translate("pages").resources.alerts.save.error.subtitle,
          type: "error",
        });
      });
  };

  const handleCopy = (item) => {
    const info = {
      title: translate("pages").resources.alerts.duplicate.question.title,
      text: translate("pages").resources.alerts.duplicate.question.subtitle,
      icon: "info",
      confirm:
        translate("pages").resources.alerts.duplicate.question.buttons.confirm,
      successTitle: translate("pages").resources.alerts.duplicate.success.title,
      successSubtitle:
        translate("pages").resources.alerts.duplicate.success.subtitle,
      errorTitle: translate("pages").resources.alerts.duplicate.error.title,
      errorSubtitle:
        translate("pages").resources.alerts.duplicate.error.subtitle,
      dispatchFunction: duplicateResources,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };
  const handleDelete = (item) => {
    const info = {
      title: translate("pages").resources.alerts.delete.question.title,
      text: translate("pages").resources.alerts.delete.question.subtitle,
      icon: "info",
      confirm:
        translate("pages").resources.alerts.delete.question.buttons.confirm,
      successTitle: translate("pages").resources.alerts.delete.success.title,
      successSubtitle:
        translate("pages").resources.alerts.delete.success.subtitle,
      errorTitle: translate("pages").resources.alerts.delete.error.title,
      errorSubtitle: translate("pages").resources.alerts.delete.error.subtitle,
      dispatchFunction: deleteResources,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };
  const handlePublish = (item) => {
    const info = {
      title: translate("pages").resources.alerts.publish.question.title,
      text: translate("pages").resources.alerts.publish.question.subtitle,
      icon: "info",
      confirm:
        translate("pages").resources.alerts.publish.question.buttons.confirm,
      successTitle: translate("pages").resources.alerts.publish.success.title,
      successSubtitle:
        translate("pages").resources.alerts.publish.success.subtitle,
      errorTitle: translate("pages").resources.alerts.publish.error.title,
      errorSubtitle: translate("pages").resources.alerts.publish.error.subtitle,
      dispatchFunction: publishResources,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };

  return (
    <Suspense fallback={renderLoader()}>
      {loading && <SpiningPage opacity={1} />}

      {modalVisible && (
        <Modal
          className="resources"
          title={translate("pages").resources.createResourceModal.title}
          centered
          visible={modalVisible}
          onOk={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
          footer={false}
        >
          <Form name="resource-name" onFinish={getResourceTitle}>
            <Row justify="end">
              <Col span={24}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message:
                        translate("pages").resources.createResourceModal
                          .validateTitle,
                    },
                  ]}
                >
                  <CreateElementInput
                    placeholder={
                      translate("pages").resources.createResourceModal
                        .placeholder
                    }
                    label={
                      translate("pages").resources.createResourceModal.label
                    }
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <PrimaryButton
                    htmlType="submit"
                    cssClassName="resources save-button"
                    title={
                      translate("pages").resources.createResourceModal.button
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {!loading && resources && resources.length <= 0 && (
        <EmptyComponentTemplate
          // image={noResources}
          onButtonClick={OpenCreateResource}
          title={translate("pages").resources.notFound.title}
          subtitle={translate("pages").resources.notFound.description}
          buttonText={
            translate("pages").resources.notFound.buttons.createResource
          }
        />
      )}
      {resources && resources.length > 0 && !loading && (
        <ResourcesOrganism
          items={resources}
          sidebarItems={resourcesAux}
          type={view}
          categories={resourceCategories}
          handleView={handleView}
          handleSearch={handleSearch}
          handleCopy={handleCopy}
          handleDelete={handleDelete}
          handlePublish={handlePublish}
          handleEdit={handleEdit}
          handleFilter={handleFilter}
          handleSaveTopic={handleSaveTopic}
        />
      )}
    </Suspense>
  );
};
export default Resources;
