import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import projectAPI from "api/projectAPI";
import citizenAPI from "api/citizenAPI";
import { getCurrentOrganizationId, getCurrentProjectId } from "utils/common";

const awsErrorSerialized = {
    error: "ASW Error",
};

const awsError = (resp) => {
    if (resp.data.errorType) {
        throw awsErrorSerialized;
    }
};

export const asyncGetData = (previewData) => async (dispatch) => {
    try {
        dispatch(startGetForm());
        dispatch(successGetForm(previewData));
    } catch (error) {
        dispatch(errorGetForm(error.toString()));
    }
};

export const changeTheme = (settings) => async (dispatch) => {
    try {
        dispatch(startChangeTheme());
        dispatch(successChangeTheme(settings));
    } catch (error) {
        dispatch(errorChangeTheme(error.toString()));
    }
};

export const changeProject = (project) => async (dispatch) => {
    try {
        dispatch(startChangeProject());
        dispatch(successChangeProject(project));
    } catch (error) {
        dispatch(errorChangeProject(error.toString()));
    }
};

export const openCreateProjectModal = (item) => async (dispatch) => {
    try {
        dispatch(startOpenCreateProjectModal());
        dispatch(successOpenCreateProjectModal(item));
    } catch (error) {
        dispatch(errorOpenCreateProjectModal(error.toString()));
    }
};
export const closeCreateProjectModal = () => async (dispatch) => {
    try {
        dispatch(startCloseCreateProjectModal());
        dispatch(successCloseCreateProjectModal());
    } catch (error) {
        dispatch(errorCloseCreateProjectModal(error.toString()));
    }
};

export const asyncClearData = (previewData) => async (dispatch) => {
    try {
        dispatch(startCleanForm());
        dispatch(successCleanForm(previewData));
    } catch (error) {
        dispatch(errorCleanForm(error.toString()));
    }
};

export const getProjects = createAsyncThunk(
    "projects/getProjects",
    async (rejectWithValue) => {
        try {
            const projects = await projectAPI.getProjects();
            // console.log("Projects from API: ", projects);
            awsError(projects);
            if (projects.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return projects.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);
export const getProject = createAsyncThunk(
    "projects/getProject",
    async (id, { rejectWithValue }) => {
        try {
            const project = await projectAPI.getProject(id);
            // console.log("Projects from API: ", projects);
            awsError(project);
            if (project.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return project.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);
export const updateProject = createAsyncThunk(
    "projects/updateProject",
    async (item, { rejectWithValue }) => {
        try {
            console.log({ item });
            const project = await projectAPI.updateProject(item, item.id);
            // console.log("Projects from API: ", projects);
            awsError(project);
            if (project.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return project.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);
export const getProjectCategories = createAsyncThunk(
    "projects/getProjectCategories",
    async (rejectWithValue) => {
        try {
            const categories = await projectAPI.getProjectCategories();
            // console.log("Projects from API: ", projects);
            awsError(categories);
            if (categories.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return categories.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const getProjectsSilence = createAsyncThunk(
    "projects/getProjectsSilence",
    async (rejectWithValue) => {
        try {
            const projects = await projectAPI.getProjects();
            // console.log("Projects from API: ", projects);
            awsError(projects);
            if (projects.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return projects.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const getProjectUsers = createAsyncThunk(
    "projects/getProjectUsers",
    async (rejectWithValue) => {
        try {
            const project_id = getCurrentProjectId();
            const users = await projectAPI.getProjectUsers(project_id);
            // console.log("workers: ", users);
            awsError(users);
            if (users.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return users.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const getProjectCitizen = createAsyncThunk(
    "projects/getProjectCitizen",
    async (rejectWithValue) => {
        try {
            const project_id = getCurrentProjectId();
            const users = await citizenAPI.getProjectCitizen(project_id);
            awsError(users);
            if (users.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return users.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const getProjectRoles = createAsyncThunk(
    "projects/getProjectRoles",
    async (rejectWithValue) => {
        try {
            const project_id = getCurrentProjectId();
            const roles = await projectAPI.getProjectRoles(project_id);
            // console.log("Roles API: ", roles);
            awsError(roles);
            if (roles.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return roles.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const getProjectDepartments = createAsyncThunk(
    "projects/getProjectDepartments",
    async (rejectWithValue) => {
        try {
            const project_id = getCurrentProjectId();
            const departments = await projectAPI.getProjectDepartments(
                project_id
            );
            awsError(departments);
            if (departments.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return departments.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const getProjectResume = createAsyncThunk(
    "projects/getProjectResume",
    async (rejectWithValue) => {
        try {
            const project_id = getCurrentProjectId();
            const departments = await projectAPI.getProjectResume(project_id);
            awsError(departments);
            if (departments.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return departments.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const getProjectResumeLaunchSa = createAsyncThunk(
    "projects/getProjectResumeLaunchSa",
    async (rejectWithValue) => {
        try {
            const launchsa = await projectAPI.getLaunchSaAnalytics();
            awsError(launchsa);
            if (launchsa.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return launchsa.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const enableUser = createAsyncThunk(
    "projects/enableUser",
    async (id, { rejectWithValue }) => {
        try {
            const users = await projectAPI.enableUser(id);
            awsError(users);
            if (users.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return users.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const disableUser = createAsyncThunk(
    "projects/disableUser",
    async (id, { rejectWithValue }) => {
        try {
            const users = await projectAPI.disableUser(id);
            awsError(users);
            if (users.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return users.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const enableCitizen = createAsyncThunk(
    "projects/enableCitizen",
    async (id, { rejectWithValue }) => {
        try {
            const users = await citizenAPI.enableCitizen(id);
            awsError(users);
            if (users.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return users.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const disableCitizen = createAsyncThunk(
    "projects/disableCitizen",
    async (id, { rejectWithValue }) => {
        try {
            const users = await citizenAPI.disableCitizen(id);
            awsError(users);
            if (users.data.statusCode === 404) {
                return { body: undefined };
            } else {
                return users.data;
            }
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const createProjectWorker = createAsyncThunk(
    "projects/createProjectWorker",
    async (item, { rejectWithValue }) => {
        try {
            console.log("Item to create worker: ", item);
            const project_id = getCurrentProjectId();
            const worker = await projectAPI.createProjectWorker(
                item,
                project_id
            );
            console.log(worker, "create create worker");
            awsError(worker);
            return worker.data;
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const createProjects = createAsyncThunk(
    "projects/createProjects",
    async (item, { rejectWithValue }) => {
        try {
            const projects = await projectAPI.createProject(item);
            awsError(projects);
            return projects.data;
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const inviteUser = createAsyncThunk(
    "projects/inviteUser",
    async (item, { rejectWithValue }) => {
        try {
            const project_id = getCurrentProjectId();
            const projects = await projectAPI.inviteUser(item, project_id);
            if (projects.data.statusCode === 500) {
                return rejectWithValue("error");
            }
            console.log(projects, "en el redux");
            awsError(projects);
            return projects.data;
        } catch (error) {
            return rejectWithValue(error.error);
        }
    }
);

export const resetGetData = () => async (dispatch) => {
    dispatch(reset());
};

const initialState = {
    current_project: 0,
    currentProject: [],
    categories: [],
    isModalOpened: false,
    projects: [],
    error: null,
    loading: true,
    projectUpdated: false,
    projectCreated: false,
    workerCreated: false,
    loadingAction: false,
    projectFetchError: false,
    loadingSidebar: true,
    loadingUsers: true,
    resumeError: [],
    resume: [],
    launchsaAnalytics: [],
    userRoles: {},
    users: [],
    citizens: [],
    roles: [],
    departments: [],
    settings: [],
    errorLoading: false,
    inviteLoading: false,
    inviteSuccess: false,
    projectFetched: null,
    previewData: {
        id: undefined,
        organization_id: parseInt(getCurrentOrganizationId()),
        name: "",
        completion_status: 0,
        address: "",
        description: "",
        latitude: "",
        longitude: "",
        logo: undefined,
        is_private: false,
        share: true,
        facebook_url: "",
        twitter_url: "",
        linkedin_url: "",
        instagram_url: "",
    },
    joined: null,
    singleLoading: true,
    // currentProject: null,
    projectData: null,
};

const projects = createSlice({
    name: "projects",
    initialState,
    reducers: {
        startGetForm(state, action) {
            console.log("starting get data from redux");
        },
        successGetForm(state, action) {
            state.previewData = { ...state.previewData, ...action.payload };
        },
        errorGetForm(state, action) {
            console.log("error en el preview data", action);
        },
        startChangeTheme(state, action) {
            console.log("starting theme change");
        },
        successChangeTheme(state, action) {
            state.settings = action.payload;
            if (action.payload !== undefined) {
                localStorage.setItem(
                    "settings",
                    JSON.stringify(action.payload)
                );
            }
        },
        errorChangeTheme(state, action) {
            console.log("error en el theme change", action);
        },
        startChangeProject(state, action) {},
        successChangeProject(state, action) {
            state.current_project = action.payload.project_id;
            // console.log(action.payload, "en el redux");
            // console.log(action.payload?.item, "en el redux EL item");
            state.currentProject = action.payload?.item;
            state.userRoles = action.payload?.item?.permissions;
            localStorage.setItem("current_project", action.payload.project_id);
        },
        errorChangeProject(state, action) {
            console.log("error en el change project ", action);
        },
        startCleanForm(state, action) {},
        successCleanForm(state, action) {
            state.previewData = action.payload;
        },
        errorCleanForm(state, action) {
            console.log("error en el clean data", action);
        },
        startOpenCreateProjectModal(state, action) {
            console.log("starting to open the modal");
            state.isModalOpened = undefined;
        },
        successOpenCreateProjectModal(state, action) {
            console.log("modal opened");
            console.log(action.payload);
            if (action.payload) {
                state.previewData.name = action.payload.project?.name;
                state.previewData.logo = action.payload.project?.logo;
                state.previewData.id = action.payload.project?.id;
            } else {
                state.previewData.name = "";
                state.previewData.logo = undefined;
                state.previewData.id = undefined;
            }
            state.isModalOpened = true;
        },
        errorOpenCreateProjectModal(state, action) {
            console.log("error en el open modal", action);
            state.isModalOpened = undefined;
        },
        startCloseCreateProjectModal(state, action) {
            console.log("starting to close the modal");
            state.isModalOpened = undefined;
        },
        successCloseCreateProjectModal(state, action) {
            console.log("modal opened");
            state.isModalOpened = false;
        },
        errorCloseCreateProjectModal(state, action) {
            console.log("error en el open modal", action);
            state.isModalOpened = undefined;
        },
        reset(state, action) {
            state.joined = null;
            state.currentProject = null;
        },
    },
    extraReducers: {
        //Get Projects
        [getProjects.pending]: (state, action) => {
            console.info("getProjects pending");
            state.loading = true;
            state.loadingAction = true;
            state.loadingSidebar = true;
            state.error = null;
            state.errorLoading = false;
            state.projectsUpdated = false;
            state.projectsCreated = false;
            state.projectFetchError = false;
        },
        [getProjects.fulfilled]: (state, action) => {
            console.info("getProjects fullfilled");
            state.loading = false;
            state.loadingAction = false;
            state.loadingSidebar = false;
            state.error = null;
            state.errorLoading = false;
            state.projectsUpdated = false;
            state.projectFetchError = false;
            // console.log("Projects:", action.payload.body);
            localStorage.setItem("userRoles", JSON.stringify({ test: "test" }));
            state.projects = action.payload.body;
        },
        //Get Project Categories
        [getProjectCategories.pending]: (state, action) => {
            console.info("getProjectCategories pending");
            state.error = null;
            state.errorLoading = false;
        },
        [getProjectCategories.fulfilled]: (state, action) => {
            console.info("getProjectCategories fullfilled");
            state.error = null;
            state.categories = action.payload.body;
        },
        [getProjectCategories.rejected]: (state, action) => {
            console.error("getProjectCategories rejected =>", action.payload);
            state.error = action.payload;
            state.categories = [];
        },
        //Get Projects silece
        [getProjectsSilence.pending]: (state, action) => {
            console.info("getProjectsSilence pending");
        },
        [getProjectsSilence.fulfilled]: (state, action) => {
            console.info("getProjectsilence fullfilled");
            state.projects = action.payload.body;
        },
        [getProjectsSilence.rejected]: (state, action) => {
            console.error("getProjectssilence rejected =>", action.payload);
            state.projects = [];
        },
        //Get Project by id
        [getProject.pending]: (state, action) => {
            console.info("getProject pending");
            state.projectFetched = null;
            state.singleLoading = true;
        },
        [getProject.fulfilled]: (state, action) => {
            console.info("getProject fullfilled");
            state.projectFetched = action.payload.body;
            state.singleLoading = false;
        },
        [getProject.rejected]: (state, action) => {
            console.error("getProject rejected =>", action.payload);
            state.projectFetched = null;
            state.singleLoading = false;
        },
        //UpdateProject
        [updateProject.pending]: (state, action) => {
            console.info("updateProject pending");
            state.loadingAction = true;
        },
        [updateProject.fulfilled]: (state, action) => {
            console.info("updateProject fullfilled");
            state.loadingAction = false;
        },
        [updateProject.rejected]: (state, action) => {
            console.error("updateProject rejected =>", action.payload);
            state.loadingAction = false;
        },

        //Get Project Users
        [getProjectUsers.pending]: (state, action) => {
            console.info("getProjectUsers pending");
            state.loading = true;
            state.loadingUsers = true;
            state.error = null;
            state.projectsUpdated = false;
            state.projectsCreated = false;
        },
        [getProjectUsers.fulfilled]: (state, action) => {
            console.info("getProjectUsers fullfilled");
            state.loading = false;
            state.error = null;
            state.loadingUsers = false;
            state.projectsUpdated = false;
            state.users = action.payload.body;
        },
        [getProjectUsers.rejected]: (state, action) => {
            console.error("getProjectUsers rejected =>", action.payload);
            state.loading = false;
            state.loadingUsers = false;
            state.error = action.payload;
            state.users = [];
        },

        //Get Project Citizen
        [getProjectCitizen.pending]: (state, action) => {
            console.info("getProjectCitizen pending");
            state.loading = true;
            state.error = null;
            state.projectsUpdated = false;
            state.projectsCreated = false;
        },
        [getProjectCitizen.fulfilled]: (state, action) => {
            console.info("getProjectCitizen fullfilled");
            state.loading = false;
            state.error = null;
            state.projectsUpdated = false;
            state.citizens = action.payload.body;
        },
        [getProjectUsers.rejected]: (state, action) => {
            console.error("getProjectCitizen rejected =>", action.payload);
            state.loading = false;
            state.error = action.payload;
            state.citizens = [];
        },

        //Get Project Roles
        [getProjectRoles.pending]: (state, action) => {
            console.info("getProjectRoles pending");
            state.loading = true;
            state.error = null;
            state.projectsUpdated = false;
            state.projectsCreated = false;
        },
        [getProjectRoles.fulfilled]: (state, action) => {
            console.info("getProjectRoles fullfilled");
            state.loading = false;
            state.error = null;
            state.projectsUpdated = false;
            state.roles = action.payload.body;
        },
        [getProjectRoles.rejected]: (state, action) => {
            console.error("getProjectRoles rejected =>", action.payload);
            state.loading = false;
            state.error = action.payload;
            state.roles = [];
        },

        //Get Project Departments
        [getProjectDepartments.pending]: (state, action) => {
            console.info("getProjectDepartments pending");
            state.loading = true;
            state.error = null;
            state.projectsUpdated = false;
            state.projectsCreated = false;
        },
        [getProjectDepartments.fulfilled]: (state, action) => {
            console.info("getProjectDepartments fullfilled");
            state.loading = false;
            state.error = null;
            state.projectsUpdated = false;
            state.departments = action.payload.body;
        },
        [getProjectDepartments.rejected]: (state, action) => {
            console.error("getProjectDepartments rejected =>", action.payload);
            state.loading = false;
            state.error = action.payload;
            state.departments = [];
        },

        //Get Project Resume
        [getProjectResume.pending]: (state, action) => {
            console.info("getProjectResume pending");
            state.loadingAction = true;
            state.error = null;
            state.resumeError = false;
            state.projectsUpdated = false;
            state.projectsCreated = false;
        },
        [getProjectResume.fulfilled]: (state, action) => {
            console.info("getProjectResume fullfilleed");
            state.loadingAction = false;
            state.error = null;
            state.resumeError = false;
            state.projectsUpdated = false;
            state.resume = action.payload.body;
        },
        [getProjectResume.rejected]: (state, action) => {
            console.error("getProjectResume rejected =>", action.payload);
            state.loadingAction = false;
            state.resumeError = true;
            state.error = action.payload;
        },

        //Get Project Resume LaunchSA
        [getProjectResumeLaunchSa.pending]: (state, action) => {
            console.info("getProjectResumeLaunchSa pending");
            state.loadingAction = true;
            state.error = null;
            state.resumeError = false;
            state.projectsUpdated = false;
            state.projectsCreated = false;
        },
        [getProjectResumeLaunchSa.fulfilled]: (state, action) => {
            console.info("getProjectResumeLaunchSa fullfilleed");
            state.loadingAction = false;
            state.error = null;
            state.resumeError = false;
            state.projectsUpdated = false;
            state.launchsaAnalytics = action.payload.body;
        },
        [getProjectResumeLaunchSa.rejected]: (state, action) => {
            console.error(
                "getProjectResumeLaunchSa rejected =>",
                action.payload
            );
            state.loadingAction = false;
            state.resumeError = true;
            state.error = action.payload;
        },

        //Enable User
        [enableUser.pending]: (state, action) => {
            console.info("enableUser pending");
            // state.loading = true;
            state.loadingAction = true;
            state.error = null;
        },
        [enableUser.fulfilled]: (state, action) => {
            console.info("enableUser fullfilled");
            // state.loading = false;
            state.loadingAction = false;
            state.error = null;
        },
        [enableUser.rejected]: (state, action) => {
            console.error("enableUser rejected =>", action.payload);
            // state.loading = false;
            state.loadingAction = false;
            state.error = action.payload;
        },

        //Disable User
        [disableUser.pending]: (state, action) => {
            console.info("disableUser pending");
            // state.loading = true;
            state.loadingAction = true;
            state.error = null;
        },
        [disableUser.fulfilled]: (state, action) => {
            console.info("disableUser fullfilled");
            // state.loading = false;
            state.loadingAction = false;
            state.error = null;
        },
        [disableUser.rejected]: (state, action) => {
            console.error("disableUser rejected =>", action.payload);
            // state.loading = false;
            state.loadingAction = false;
            state.error = action.payload;
        },

        //Enable Citizen
        [enableCitizen.pending]: (state, action) => {
            console.info("enableCitizen pending");
            // state.loading = true;
            state.loadingAction = true;
            state.error = null;
        },
        [enableCitizen.fulfilled]: (state, action) => {
            console.info("enableCitizen fullfilled");
            // state.loading = false;
            state.loadingAction = false;
            state.error = null;
        },
        [enableCitizen.rejected]: (state, action) => {
            console.error("enableCitizen rejected =>", action.payload);
            // state.loading = false;
            state.loadingAction = false;
            state.error = action.payload;
        },

        //Disable User
        [disableCitizen.pending]: (state, action) => {
            console.info("disableCitizen pending");
            // state.loading = true;
            state.loadingAction = true;
            state.error = null;
        },
        [disableCitizen.fulfilled]: (state, action) => {
            console.info("disableCitizen fullfilled");
            // state.loading = false;
            state.loadingAction = false;
            state.error = null;
        },
        [disableCitizen.rejected]: (state, action) => {
            console.error("disableCitizen rejected =>", action.payload);
            // state.loading = false;
            state.loadingAction = false;
            state.error = action.payload;
        },

        //Create Projects
        [createProjects.pending]: (state, action) => {
            console.info("createProjects pending");
            state.loadingAction = true;
            state.error = null;
            state.projectsUpdated = false;
            state.projectsCreated = false;
        },
        [createProjects.fulfilled]: (state, action) => {
            console.info("createProjects fullfilled");
            state.loadingAction = false;
            state.error = null;
            state.projectsCreated = true;
        },
        [createProjects.rejected]: (state, action) => {
            state.loadingAction = false;
            state.error = action.payload.error;
            console.error("createProjects rejected =>", action.payload);
        },

        //Invite Users
        [inviteUser.pending]: (state, action) => {
            console.info("inviteUser pending");
            state.inviteLoading = true;
            state.error = null;
            state.inviteSuccess = false;
        },
        [inviteUser.fulfilled]: (state, action) => {
            console.info("inviteUser fullfilled");
            state.inviteLoading = false;
            state.error = null;
            state.inviteSuccess = true;
        },
        [inviteUser.rejected]: (state, action) => {
            state.inviteLoading = false;
            state.inviteSuccess = false;
            state.error = action.payload.error;
            console.error("inviteUser rejected =>", action.payload);
        },

        //Create Project worker
        [createProjectWorker.pending]: (state, action) => {
            console.info("createProjects pending");
            state.loadingAction = true;
            state.error = null;
            state.workerCreated = false;
        },
        [createProjectWorker.fulfilled]: (state, action) => {
            console.info("createProjects fullfilled");
            state.loadingAction = false;
            state.error = null;
            state.workerCreated = true;
        },
        [createProjectWorker.rejected]: (state, action) => {
            state.loadingAction = false;
            state.error = action.payload.error;
            state.workerCreated = false;
            console.error("createProjects rejected =>", action.payload);
        },
    },
});

export const {
    startGetForm,
    successGetForm,
    errorGetForm,
    startChangeTheme,
    successChangeTheme,
    errorChangeTheme,
    startChangeProject,
    successChangeProject,
    errorChangeProject,
    startCleanForm,
    successCleanForm,
    errorCleanForm,
    startOpenCreateProjectModal,
    successOpenCreateProjectModal,
    errorOpenCreateProjectModal,
    startCloseCreateProjectModal,
    successCloseCreateProjectModal,
    errorCloseCreateProjectModal,
    reset,
} = projects.actions;
export default projects.reducer;
