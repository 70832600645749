import React, { useContext } from "react";
import { Layout } from "antd";
import "./UsersTemplate.scss";
import UserListOrganism from "components/organisms/UserListOrganism";
import { withRouter } from "react-router-dom";

const UsersTemplate = ({ openDrawer, history }) => {
  const openUserDetail = (id) => {
    // console.log(history.location.pathname, "a ver");
    // history.push(`${id}`);
    console.log(id);
  };

  const navigateBackToList = () => {
    history.push(`${history.location.pathname}`);
  };

  return (
    <Layout className="users-template-container">
      <UserListOrganism
        openUserDetail={openUserDetail}
        openDrawer={openDrawer}
      />
    </Layout>
  );
};

export default withRouter(UsersTemplate);
