import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSurveysAPI,
  getSurveyAPI,
  deleteSurveyAPI,
  publishSurveyAPI,
  duplicateSurveyAPI,
  updateSurveyAPI,
  createSurveyAPI,
  getSurveyQuestionsAPI,
  getSurveyResponsesAPI,
  getProfileResponsesAPI,
  getClientSurveyAPI
} from "api/surveysAPI";
import { getCurrentProjectId } from "utils/common";

const awsErrorSerialized = {
  error: "ASW Error",
};

const awsError = (resp) => {
  console.log("resp",resp)
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};
export const getSurveys = createAsyncThunk(
  "surveys/getSurveys",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const surveys = await getSurveysAPI(project_id);
      awsError(surveys);
      return surveys.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getClientSurveys = createAsyncThunk(
  "surveys/getClientSurveys",
  async (id, { rejectWithValue }) => {
    console.log("id", id);
    try {
      const surveys = await getClientSurveyAPI(id);
      awsError(surveys);
      return surveys.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getSurvey = createAsyncThunk(
  "surveys/getSurvey",
  async (id, { rejectWithValue }) => {
    try {
      const survey = await getSurveyAPI(id);
      awsError(survey);
      return survey.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getSurveyResponses = createAsyncThunk(
  "surveys/getSurveyResponses",
  async (id, { rejectWithValue }) => {
    try {
      const responses = await getSurveyResponsesAPI(id);
      awsError(responses);
      return responses.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getProfileResponses = createAsyncThunk(
  "surveys/getProfileResponses",
  async (id, { rejectWithValue }) => {
    try {
      const responses = await getProfileResponsesAPI(id);
      awsError(responses);
      return responses.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getSurveyQuestions = createAsyncThunk(
  "surveys/getSurveyQuestions",
  async (id, { rejectWithValue }) => {
    try {
      const surveyQuestions = await getSurveyQuestionsAPI(id);
      awsError(surveyQuestions);
      return surveyQuestions.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const publishSurvey = createAsyncThunk(
  "surveys/publishSurvey",
  async (data, { rejectWithValue }) => {
    try {
      const publishSurvey = await publishSurveyAPI(data);
      awsError(publishSurvey);
      return publishSurvey.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const createSurvey = createAsyncThunk(
  "surveys/createSurvey",
  async (data, { rejectWithValue }) => {
    try {
      const createSurvey = await createSurveyAPI(data);
      awsError(createSurvey);
      return createSurvey.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const deleteSurvey = createAsyncThunk(
  "surveys/deleteSurvey",
  async (id, { rejectWithValue }) => {
    try {
      const deleteSurvey = await deleteSurveyAPI(id);
      awsError(deleteSurvey);
      return deleteSurvey.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const duplicateSurvey = createAsyncThunk(
  "surveys/duplicateSurvey",
  async (id, { rejectWithValue }) => {
    try {
      const duplicateSurvey = await duplicateSurveyAPI(id);
      awsError(duplicateSurvey);
      return duplicateSurvey.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updateSurvey = createAsyncThunk(
  "surveys/updateSurvey",
  async (data, { rejectWithValue }) => {
    try {
      const updateSurvey = await updateSurveyAPI(data);
      awsError(updateSurvey);
      return updateSurvey.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const resetGetData = () => async (dispatch) => {
  dispatch(reset());
};

const initialState = {
  surveys: [],
  survey: {},
  surveyQuestions: [],
  surveyResponses: [],
  error: null,
  loading: true,
  surveyFetching: false,
  surveyUpdated: false,
  surveyCreated: false,
  surveyPublished: false,
  surveyDuplicated: false,
  surveyFetched: false,
  surveyQuestionsFetched: false,
  surveyResponsesFetched: false,
  surveyQuestionsError: false,
};

const surveys = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    reset(state, action) {
      state.surveyQuestions = [];
    },
  },
  extraReducers: {
    [getClientSurveys.pending]: (state, action) => {
      state.surveyFetching = true;
      state.error = null;
      state.surveyCreated = false;
      state.surveyUpdated = false;
      state.surveyFetched = false;
    },
    [getClientSurveys.fulfilled]: (state, action) => {
      console.log("action.payload.body", action.payload.body);
      state.surveyFetching = false;
      state.error = null;
      state.surveys = action.payload.body;
      state.surveyFetched = true;
    },
    [getClientSurveys.rejected]: (state, action) => {
      state.surveyFetching = false;
      state.error = action.payload;
      state.surveys = [];
    },

    [getSurveys.pending]: (state, action) => {
      state.surveyFetching = true;
      state.loading = true;
      state.error = null;
      state.surveyCreated = false;
      state.surveyUpdated = false;
      state.surveyFetched = false;
    },
    [getSurveys.fulfilled]: (state, action) => {
      state.surveyFetching = false;
      state.loading = false;
      state.error = null;
      state.surveys = action.payload.body;
      state.surveyFetched = true;
    },
    [getSurveys.rejected]: (state, action) => {
      state.surveyFetching = false;
      state.loading = false;
      state.error = action.payload;
      state.surveys = [];
    },

    [getSurvey.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.surveyCreated = false;
      state.surveyUpdated = false;
    },
    [getSurvey.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.survey = action.payload.body;
    },
    [getSurvey.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.survey = {};
    },


    

    [getProfileResponses.pending]: (state, action) => {
      state.loading = true;
      state.surveyResponsesFetched = false;
      state.error = null;
    },
    [getProfileResponses.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.surveyResponsesFetched = true;
      state.surveyResponses = action.payload.body;
      console.log(action)
    },
    [getProfileResponses.rejected]: (state, action) => {
      state.loading = false;
      state.surveyResponsesFetched = false;
      state.error = action.payload;
      state.surveyResponses = [];
    },

    [getSurveyResponses.pending]: (state, action) => {
      state.loading = true;
      state.surveyResponsesFetched = false;
      state.error = null;
    },
    [getSurveyResponses.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.surveyResponsesFetched = true;
      state.surveyResponses = action.payload.body;
    },
    [getSurveyResponses.rejected]: (state, action) => {
      state.loading = false;
      state.surveyResponsesFetched = false;
      state.error = action.payload;
      state.surveyResponses = [];
    },

    [getSurveyQuestions.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.surveyQuestionsFetched = false;
      state.surveyQuestionsError = null;
    },
    [getSurveyQuestions.fulfilled]: (state, action) => {
      console.log("getSurveyQuestions", action.payload);
      state.loading = false;
      state.error = null;
      state.surveyQuestions = action.payload.body;
      state.surveyQuestionsFetched = true;
      state.surveyQuestionsError = null;
    },
    [getSurveyQuestions.rejected]: (state, action) => {
      state.loading = false;
      state.surveyQuestionsError = action.error;
      state.surveyQuestions = [];
    },

    [createSurvey.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.surveyCreated = false;
      state.surveyUpdated = false;
    },
    [createSurvey.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.surveyCreated = true;
      state.survey = action.payload.body;
    },
    [createSurvey.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [publishSurvey.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.surveyPublished = false;
    },
    [publishSurvey.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.surveyPublished = true;
    },
    [publishSurvey.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [duplicateSurvey.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.surveyDuplicated = false;
    },
    [duplicateSurvey.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.surveyDuplicated = true;
    },
    [duplicateSurvey.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [deleteSurvey.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.surveyUpdated = false;
    },
    [deleteSurvey.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.surveyUpdated = true;
    },
    [deleteSurvey.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [updateSurvey.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.surveyUpdated = false;
    },
    [updateSurvey.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.surveyUpdated = true;
    },
    [updateSurvey.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
      state.surveyUpdated = false;
    },
  },
});
export const { reset } = surveys.actions;
export default surveys.reducer;
