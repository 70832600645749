import React, { useContext } from "react";
import PreviousPage from "components/atoms/previous-page";
import KnowledgeHeader from "components/molecules/KnowledgeHeader";
import KnowledgeEditorOrganism from "components/organisms/KnowledgeEditorOrganism";
import { useLocation } from "react-router-dom";
import { I18nContext } from "utils/i18n/locale";

const KnowledgeEditor = () => {
  const { translate } = useContext(I18nContext);

  const location = useLocation();
  const previousTitle = location.pathname.split("/")[1].replace("-", " ");
  const previousLocation = location.pathname.split("/").splice(0, 4).join("/");
  return (
    <>
      <KnowledgeHeader title={"Editor"} hideButton />
      <PreviousPage
        title={translate("pages").knowledge.detail.backToPostList}
        link={
          previousLocation === window.location.pathname
            ? location.pathname.split("/").splice(0, 3).join("/")
            : previousLocation
        }
        className="previous-page"
      />
      <KnowledgeEditorOrganism />
    </>
  );
};

export default KnowledgeEditor;
