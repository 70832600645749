import React, { lazy, Suspense } from "react";
import { SpiningPage } from "components/atoms/icons";
const ResourcesTemplate = lazy(() => import("components/templates/Resources"));

const renderLoader = () => <SpiningPage opacity={1} />;

const Resources = () => {
  return (
    <Suspense fallback={renderLoader()}>
      <ResourcesTemplate />
    </Suspense>
  );
};

export default Resources;
