import { Auth } from "aws-amplify";

export const signUp = async (username, password, fullName ) => {

  try {
    const resp = await Auth.signUp({
        username,
        password,
        attributes: {
          email : username,
          name : fullName
        }
    });
    return resp;
  } catch (error) {
    throw error;
  }
};

export const confirmSignUp = async( username, code ) => {
  try {
  const response =  await Auth.confirmSignUp(username, code);
    return response;
  } catch (error) {
      console.log('error confirming sign up', error);
        return error;
  }
}

export const signIn = async ( email, password) => {
  try {
    const user = await Auth.signIn(email, password);
    return user;
  } catch (error) {
    throw error;
  }
};

export const CompleteNewPasswordAction = async (user, newPassword) => {
  try {
    const userAuthenticated = await Auth.completeNewPassword(
      user, // the Cognito User Object
      newPassword, // the new password
      { name: "irys" }
    );
    return userAuthenticated;
  } catch (error) {
    throw error;
  }
};

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.error("error signing out: ", error);
  }
};

export const sendRecoveryMail = async (email) => {
  // Send confirmation code to user's email
  try {
    const recover = await Auth.forgotPassword(email);
    return recover;
  } catch (error) {
    return error;

  }
};

export const verifyRecoveryCode = async (email, code, password) => {
  console.log("email",email);
  console.log("code",code);
  console.log("password",password);
  // Collect confirmation code and new password, then
  try {
    const changed = await Auth.forgotPasswordSubmit(email, code, password);
    console.log(changed);
    return changed;
  } catch (error) {
    console.log(error)
    return(error)
  }
};

export const currentSessionRetrieve = async () => {
  try {
    return await Auth.currentSession();
  }catch (error) {
    throw error;
  }
}
