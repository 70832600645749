import React, { useContext, useState, useRef, useEffect } from "react";
import Picker from "emoji-picker-react";
import { Col, Form, message, Row, Upload } from "antd";
import { DataInput } from "components/atoms/inputs";
import { PrimaryButton } from "components/atoms/controls";
import { I18nContext } from "utils/i18n/locale";
import BottomDrawer from "components/atoms/bottom-drawer";
import {
  ExpandIcon,
  ShrinkIcon,
  AttachFileIcon,
  AtMentionIcon,
  EmojiIcon,
} from "components/atoms/icons";
import "./ComposeEmailForm.scss";
import ComposeEmailFooter from "components/molecules/ComposeEmailFooter";
import { useDispatch, useSelector } from "react-redux";
import {
  createEmail,
  getReceivedEmailsReload,
  handleAddressDeselection,
  handleAddressSelection,
  searchByEmail,
} from "redux/slices/communication/emails";
import { FileImageOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Storage } from "aws-amplify";
import { unwrapResult } from "@reduxjs/toolkit";
import { getCurrentOrganizationId, makeId } from "utils/common";
import { FileUploader } from "components/atoms/media";

const ComposeEmailForm = ({ show, onClose, sendEmail, messageBoxId }) => {
  const FILE_TYPES = {
    application: "document",
    image: "image",
    video: "video",
    audio: "audio",
  };
  const { translate } = useContext(I18nContext);
  const [composeEmailFormHeight, setComposeEmailFormHeight] = useState("75%");
  const [composeEmailActionSize, setComposeEmailActionSize] =
    useState("size-75");
  const [composeEmailFormWidth, setComposeEmailFormWidth] = useState(
    "compose-form-drawer"
  );
  const [composeEmailMessageRow, setComposeEmailMessageRow] = useState(8);
  const [isComposeFormExpanded, setIsComposeFormExpanded] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [inputs, setInputs] = useState({
    messageBox: "",
  });

  const [emails, setEmails] = useState([]);

  const [fileList, setFileList] = useState([]);

  const dispatch = useDispatch();
  const searchedEmails = useSelector((state) => state.emails.searchedEmails);
  const selectedAddress = useSelector((state) => state.emails.selectedAddress);
  const [loadingAction, setLoadingAction] = useState(false);

  useEffect(() => {
    setEmails(searchedEmails);
  }, [searchedEmails]);

  const onShowEmojiClick = (e) => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const onEmojiClick = (event, emojiObject) => {
    console.log({ event, emojiObject });
    const myElement = document.getElementById("compose-mail-box");
    const startPosition = myElement.selectionStart;

    const boxTextValue =
      inputs.messageBox !== ""
        ? inputs?.messageBox?.slice(0, startPosition) +
          " " +
          emojiObject.emoji +
          " " +
          inputs?.messageBox?.slice(startPosition + 1)
        : emojiObject.emoji + " ";

    console.log({ boxTextValue });

    setInputs((inputs) => ({
      ...inputs,
      messageBox: boxTextValue,
    }));
  };

  const formRef = React.createRef();

  const handleClickOutside = (e) => {
    const getClassName = e.target.className
      ? typeof e.target.className === "string"
        ? e.target.className
        : ""
      : "";
    if (
      getClassName !== "emoji-img" &&
      getClassName.substring(0, 4) !== "icn-"
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const setFormHeight = (height) => {
    setComposeEmailFormHeight(height);
  };
  const onAdjustComposeForm = () => {
    if (!isComposeFormExpanded) {
      setIsComposeFormExpanded(true);
      setFormHeight("95%");
      setComposeEmailMessageRow(16);
      setComposeEmailFormWidth("compose-form-drawer-max");
      setComposeEmailActionSize("size-75-max");
    } else {
      setIsComposeFormExpanded(false);
      setFormHeight("75%");
      setComposeEmailMessageRow(8);
      setComposeEmailFormWidth("compose-form-drawer");
      setComposeEmailActionSize("size-75");
    }
  };
  const composeMailHeader = (title) => (
    <div className="compose-mail-header">
      <div>{title}</div>
      <div>
        {!isComposeFormExpanded ? (
          <ExpandIcon onClick={onAdjustComposeForm} />
        ) : (
          <ShrinkIcon onClick={onAdjustComposeForm} />
        )}
      </div>
    </div>
  );

  const onChangeText = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      messageBox: e.target.value,
    }));
  };

  const handleSearch = (value) => {
    if (value) {
      return dispatch(searchByEmail(value)).then(unwrapResult);
    } else {
      setEmails([]);
    }
  };

  const onSelect = (item) => {
    dispatch(handleAddressSelection(parseInt(item.value)));
  };
  const onDeSelect = (item) => {
    dispatch(handleAddressDeselection(parseInt(item.value)));
  };

  const getFileType = (type) => {
    let newType = type.split("/");
    return FILE_TYPES[newType[0]];
  };

  const handleUpload = async (file) => {
    const uploadName = makeId(10) + "." + file.name.split(".").pop();
    return new Promise((resolve, reject) => {
      Storage.put("attachments/" + uploadName, file, {
        contentType: file.type,
        contentDisposition: "attachment",
      }).then((result) => {
        resolve({
          type: getFileType(file.type),
          url: result.key,
          name: file.name,
          size: (file.size / 1024).toFixed(1) + " kb",
        });
      });
    });
  };

  const onFinish = (e) => {
    setLoadingAction(true);
    const destinataries = selectedAddress.map((item) => {
      return { id: item };
    });

    if (fileList.length > 0) {
      const fileInfo = Promise.all(fileList.map(handleUpload)).then(
        (results) => {
          const createNewEmail = {
            subject: e.subject,
            reply_body: inputs.messageBox,
            organization_id: getCurrentOrganizationId(),
            destinataries,
            attachments: results,
          };

          dispatch(createEmail(createNewEmail))
            .then(unwrapResult)
            .then((data) => {
              dispatch(getReceivedEmailsReload())
                .then(unwrapResult)
                .then((data) => {
                  sendEmail();
                  setLoadingAction(false);
                })
                .catch((error) => {
                  setLoadingAction(false);
                  console.log(error);
                });
            })
            .catch((error) => {
              setLoadingAction(false);
              console.log(error);
            });
        }
      );
    } else {
      const createNewEmail = {
        subject: e.subject,
        reply_body: inputs.messageBox,
        organization_id: getCurrentOrganizationId(),
        destinataries,
      };

      dispatch(createEmail(createNewEmail))
        .then(unwrapResult)
        .then((data) => {
          dispatch(getReceivedEmailsReload())
            .then(unwrapResult)
            .then((data) => {
              sendEmail();
            })
            .catch((error) => {
              setLoadingAction(false);
              console.log(error);
            });
        })
        .catch((error) => {
          setLoadingAction(false);
          console.log(error);
        });
    }
  };

  return (
    <BottomDrawer
      onClose={onClose}
      open={show}
      title={composeMailHeader(
        translate("pages").communication.email.compose.title
      )}
      height={composeEmailFormHeight}
      id={composeEmailFormWidth}
      className="compose-mail-ant-drawer"
    >
      <Form ref={formRef} onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              pattern: selectedAddress.length > 0,
              message: "You have to add at least one email address",
              // required: true,
            },
          ]}
        >
          <DataInput
            isSearch
            showSearch
            onSearch={handleSearch}
            onChange={handleSearch}
            searchedItems={emails}
            onSelect={(e) => onSelect(e)}
            onDeselect={(e) => onDeSelect(e)}
            placeholder={
              translate("pages").communication.email.compose.placeholder
                .recipient
            }
            title={
              translate("pages").communication.email.compose.recipientTitle
            }
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="subject"
          rules={[
            {
              max: 20,
              message: "Maximum 20 characters",
            },
            {
              message: "You have to add a subject",
              required: true,
            },
          ]}
        >
          <DataInput
            placeholder={
              translate("pages").communication.email.compose.placeholder.subject
            }
            title={translate("pages").communication.email.compose.subject}
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="message"
          rules={[
            {
              pattern: inputs.messageBox !== "",
              message: "You must add a message",
            },
          ]}
        >
          <DataInput
            type="textarea"
            rows={composeEmailMessageRow}
            placeholder={
              translate("pages").communication.email.compose.placeholder.message
            }
            title={translate("pages").communication.email.compose.message}
            allowClear
            id={messageBoxId}
            value={inputs.messageBox}
            onChange={onChangeText}
          />
          <ComposeEmailFooter
            cssClass={`compose-new-mail-footer ${composeEmailActionSize}`}
          >
            {/* <AtMentionIcon /> */}
            <Row justify="center" align="middle">
              <Col offset={10} span={1}>
                <EmojiIcon onClick={onShowEmojiClick} />
              </Col>
              <Col offset={3} span={1}>
                <FileUploader
                  showUploadList={false}
                  fileList={fileList}
                  setFileList={setFileList}
                  showIcon={true}
                  isInput
                />
              </Col>
              <Col offset={2} span={7}>
                <PrimaryButton
                  htmlType="submit"
                  loading={loadingAction}
                  cssClassName="save-btn"
                  title={
                    translate("pages").communication.email.compose.buttons.send
                  }
                />
              </Col>
            </Row>
            {showEmojiPicker ? (
              <Picker
                onEmojiClick={onEmojiClick}
                pickerStyle={{ "z-index": 1 }}
              />
            ) : (
              ""
            )}
          </ComposeEmailFooter>
        </Form.Item>
      </Form>
      <Row>
        <Col offset={3}></Col>
        <Col span={21}>
          <FileUploader
            showUploadList={true}
            fileList={fileList}
            setFileList={setFileList}
            showIcon={false}
          />
        </Col>
      </Row>
    </BottomDrawer>
  );
};
export default ComposeEmailForm;
