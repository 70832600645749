import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
} from "react";
import { SpiningPage } from "components/atoms/icons";
import { Alert } from "components/atoms/outputs";
import { I18nContext } from "utils/i18n/locale";
import { CompleteNewPasswordAction } from "api/amplifyAuthAPI";
import { useHistory, useLocation } from "react-router";
const AuthTemplate = lazy(() => import("components/templates/AuthTemplate"));

const renderLoader = () => <SpiningPage opacity={1} />;

const ChangePassword = (props) => {
  const user = props.location?.user;
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    if (!user) {
      history.push({ pathname: "/login", search: location?.search });
    }
    return () => {
      isMounted.current = false;
    };
  });

  const changeStatusLoading = (statusBoolean) => {
    setLoading(statusBoolean);
  };

  const handleChange = (values) => {
    const { password } = values;
    changeStatusLoading(true);

    CompleteNewPasswordAction(user, password)
      .then(() => {
        Alert("success", `${translate("pages").change.messages.changesuccess}`);
        changeStatusLoading(false);
        history.push({ pathname: "/login", search: location?.search });
      })
      .catch((err) => {
        Alert("error", translate("pages").change.messages.changeerror);
        changeStatusLoading(false);
        console.error("err ->", err);
      })
      .finally(() => {
        if (isMounted.current) {
          changeStatusLoading(false);
        }
      });
  };
  return (
    <Suspense fallback={renderLoader()}>
      <AuthTemplate handleChange={handleChange} loading={loading} change />
    </Suspense>
  );
};
export default ChangePassword;
