import React, { useContext } from "react";
import { Menu } from "antd";
import {
  BoolIcon,
  TextIcon,
  MultiCheckIcon,
  CheckIcon,
} from "components/atoms/icons";
import { Title } from "components/atoms/texts";
import { Text } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";
import "./SurveyQuestionTypeDropdown.scss";
import { useSelector } from "react-redux";
import questions from "redux/slices/questions";

const { Item } = Menu;
const SurveyQuestionTypeDropdown = ({ onMenuChange }) => {
  const { translate } = useContext(I18nContext);
  const {
    questions: { questionTypes },
  } = useSelector((state) => state);

  return (
    <Menu className="question-type-dropdown" onClick={onMenuChange}>
      <Title
        text={translate("pages").surveyEditor.title.choiceDropdown}
        className="question-type-title"
      />
      {questionTypes.map((type) => {
        let key;
        let Icon;
        let className;
        if (type.name !== "Dropdown") {
          if (type.name === "Multiple Choice- Multiple") {
            key = "multiChoiceMulti";
            Icon = MultiCheckIcon;
            className = "multi-check-icon";
          } else if (type.name === "Multiple Choice- Single") {
            key = "multiChoiceSingle";
            Icon = CheckIcon;
            className = "check-icon";
          } else if (type.name === "True or False") {
            key = "boolean";
            Icon = BoolIcon;
            className = "bool-icon";
          } else if (type.name === "Text Field") {
            key = "text";
            Icon = TextIcon;
            className = "text-icon";
          }

          return (
            <Item key={type.name}>
              <div className={`icon ${className}`}>
                <Icon color="#fff" opacity={1} />
              </div>
              <Text text={type.name} />
            </Item>
          );
        }
      })}
    </Menu>
  );
};
export default SurveyQuestionTypeDropdown;
