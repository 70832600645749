import React from "react";
import "./toast.scss";

const Toast = ({
  icon,
  text,
  textColor,
  responseText,
  positionStyle,
  close,
  ...props
}) => {
  return (
    <>
      <div
        className={`toast ${textColor}`}
        style={{...positionStyle}}
        {...props}
      >
        <span>{icon}</span>
        <span className={`toast-text`}>{text}</span>
        <span>{responseText}</span>
        <span className="close-text" onClick={close}>
          X
        </span>
      </div>
    </>
  );
};

export default Toast;
