import React, { useContext } from "react";
import { Text } from "components/atoms/texts";
import "./ResourceFeed.scss";
import { I18nContext } from "utils/i18n/locale";
import CommunicationRow from "client/components/molecules/CommunicationRow";
import { PrimaryButton } from "components/atoms/controls";
import { useHistory } from 'react-router-dom';
import CreateMessage from "client/components/organisms/CreateMessage";
import { NoMessage } from "assets/svg/mobile";

const CommunicationFeed = (props) => {
  const { translate } = useContext(I18nContext);
  const { onClick, data, type } = props;
  const history = useHistory();

  return (
    <div className="communication-feed-container">
      {data && data.length > 0 ? (
        data.map((item, index) => (
          <div key={index}>
            <CommunicationRow item={item} index={index} onClick={onClick} />
          </div>
        ))
      ) : (
        <div className="no-message">
          <div className="image">
          <div style={{textAlign:'center'}}>
          <NoMessage/>
          </div>
          <Text
            className="title-textss"
            text={translate("pages").mobile.communication.nomessages}
          />

          </div>
         
          
        </div>
      )}
      <div className="provide-input">
        
        <CreateMessage />
      </div>
    </div>
  );
};

export default CommunicationFeed;
