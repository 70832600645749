import React, {useState, useContext} from 'react';
import {Drawer} from "antd";

import Fab from "components/client/components/atoms/fab";
import {ReactComponent as ProvideInputSvg} from "components/client/components/atoms/ProvideInputSvg.svg";
import {ReactComponent as CommentSvg} from "components/client/components/atoms/CommentSvg.svg";
import {ReactComponent as PinSvg} from "components/client/components/atoms/PinSvg.svg";
import {ReactComponent as QuestionSvg} from "components/client/components/atoms/QuestionSvg.svg";
import {ReactComponent as CloseSvg} from "assets/svgs/close.svg";
import {Text} from "components/atoms/texts";
import {I18nContext} from "utils/i18n/locale";

import "./ProvideInput.scss";

const ProvideInput = () => {
    const {translate} = useContext(I18nContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const actionItems = [
        {
          text: translate("client").pages.home.RESPOND_TO_QUESTIONS,
          icon: () => <CommentSvg />,
          url: 'Survey',
          type:""
        },
        {
          text: translate("client").pages.home.OTHER_ASK_QUESTION,
          icon: () => <QuestionSvg />,
          url: 'OtherInputCategory',
          type:"category"
        },
      
        {
          text: translate("client").pages.home.PROVIDE_INPUT_BY_LOCATION,
          icon: () => <PinSvg />,
          url: 'LocationInput',
          type:"location"
        },
    ];

    return (
        <div className="provide-input-container">
            <div className="fab-container">
                <Fab
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                    icon={() =>
                    isModalOpen ? (
                        <CloseSvg/>
                    ) : (
                        <ProvideInputSvg/>
                    )}
                    isOpen={isModalOpen}
                />
            </div>
            <Drawer
                bodyStyle={{backgroundColor: "#00000000", padding: 0, display: "flex", alignItems: "flex-end"}}
                visible={isModalOpen}
                className="pi-modal"
                closeIcon={false}
                maskClosable={true}
                onClose={() => setIsModalOpen(false)}
                placement="bottom"
            >
                <div className="modal-content">
                    <Text className="pi-modal-header" text={translate("client").pages.home.CHOOSE_ACTION} />
                    {actionItems.map((item, i) => (
                        <div key={i}>
                            <div className="pi-items-container" 
                            onClick={() => {
                                setIsModalOpen(false);
                            }}>
                                <div className="pi-text-container">
                                    <Text className="pi-text" text={item.text} />
                                </div>
                                <div className="pi-icon-container">{item.icon()}</div>
                            </div>
                        </div>
                    ))}
                    <div className="pi-fab-container">
                        <Fab
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                            icon={() => <CloseSvg/>}
                            isOpen={isModalOpen}
                        />
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default ProvideInput;