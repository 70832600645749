import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import AttachmentCard from "client/components/molecules/AttachementCard";
import { Col, Row } from "antd";

import { I18nContext } from "utils/i18n/locale";
import { BackArrowMobile } from "assets/svg/mobile";
import { DeleteMobile } from "assets/svg/mobile";
import { ArchiveMobile } from "assets/svg/mobile";
import { Text } from "components/atoms/texts";
import {
  deleteEmail,
  getEmail,
  archiveEmail,
} from "redux/slices/communication/emails";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import "./communication.scss";
import { unwrapResult } from "@reduxjs/toolkit";
import Moment from "moment";
import { PrimaryButton } from "client/components/atoms/controls";
import MessageImage from "client/components/atoms/messageImage"

const CommunicationDetails = (props) => {
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const historyLocation = useLocation();
  const displayingEmailFetched = useSelector(
    (state) => state.emails.displayingEmail
  );
  const [messageId, setMessageId] = useState(
    historyLocation?.state?.messageId ?? 0
  );

  const history = useHistory();
  const [currentStyle, setCurrentStyle] = useState(null);
  const [displayingEmail, setDisplayingEmail] = useState(
    displayingEmailFetched
  );
  const [replies, setReplies] = useState([]);

  useEffect(() => {
    setDisplayingEmail(displayingEmailFetched);
    setReplies(displayingEmailFetched?.mail?.mail_reply);
  }, [displayingEmailFetched]);

  useEffect(() => {
    const cs_json = JSON.parse(localStorage.getItem("custom_client"));
    if (cs_json) {
      setCurrentStyle(cs_json);
    }
  }, []);


  useEffect(() => {
    dispatch(getEmail(messageId));
  }, [messageId]);

  const deleteMessage = async () => {
    dispatch(deleteEmail(messageId))
      .then(unwrapResult)
      .then((data) => {
        console.log("success deleting");
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const goToReply = () => {
    history.push({
      pathname: "/launchsa/reply/",
      state: {
        messageId: displayingEmail?.mail?.id,
        email: displayingEmail?.mail?.user?.email,
        subject:displayingEmail?.mail?.subject
      },
    });
  };

  const archiveMessage = async () => {
    dispatch(archiveEmail(messageId))
      .then(unwrapResult)
      .then((data) => {
        console.log("success archiving");
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [state, setState] = useState({
    selectedMenu: 0,
    modalVisible: false,
    projectModal: false,
    isFetching: false,
    isFetchingReports: false,
    projectName: "",
    projectOrganization: "",
  });

  const projectClose = () => {
    console.log("click");
    setState({ ...state, projectModal: !state.projectModal });
  };

  return (
    <div id="client-home" className="client-home-container">
      <div className="mobile-header">
        <div className={"back-arrow"}>
          <BackArrowMobile onClick={() => history.goBack()} />
        </div>
        <Text
          className={"resources-title"}
          text={translate("pages").mobile.communication.header}
          style={{ lineHeight: "70px" }}
        />
        <div className={"delete-button"}>
          <DeleteMobile onClick={() => deleteMessage()} />
        </div>
        <div className={"archive-button"}>
          <ArchiveMobile onClick={() => archiveMessage()} />
        </div>
      </div>
      <div className="message-detail-container">
        <Text
          text={displayingEmail?.mail?.subject}
          className="client-message-title"
        />
        <div className="communication-actions"></div>
        {replies?.map((mail) => (
          <div className="comunication-child" key={replies.id}>
            <div>
              <Text
                className="communication-date-text"
                text={Moment(replies.created_at).format("DD MMM YYYY")}
              />

              <div className="communication-horizontal">
                <div className="communication-image">
                 <MessageImage itemImage={mail.user.image}/>
                </div>
                <Text className="communication-title" text={mail.user.name} />
              </div>
              <Text className="communication-body" text={mail.body} />
              <Row justify="left" align="middle" gutter={[20, 0]}>
                  {mail?.reply_attachment?.length > 0 &&
                    mail?.reply_attachment.map((attachment) => (
                      <Col>
                        <AttachmentCard
                          text={attachment.name}
                          fileSize={attachment.size}
                          type={attachment.type}
                          url={attachment.url}
                        />
                      </Col>
                    ))}
                </Row>
              <div className="communication-actions"></div>
            </div>
          </div>
        ))}
        <div style={{ paddingTop: 20 }}>
          <PrimaryButton
            title={translate("pages").mobile.communication.reply}
            color="hollow-button"
            htmlType="submit"
            style={{ width: "100%" }}
            onClick={() => goToReply()}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(CommunicationDetails);
