import React from "react";
import "./Header.scss";
import { ReactComponent as CloseSvg } from "assets/svgs/close.svg";
import { ReactComponent as BackSvg } from "assets/svgs/back.svg";
import { ReactComponent as BellSvg } from "assets/svgs/bell.svg";
import { ReactComponent as MenuSvg } from "assets/svgs/menu.svg";
import { ReactComponent as MessageSvg } from "assets/svgs/messageLaunchSa.svg";
import { Text } from "components/atoms/texts";
import { Link } from "react-router-dom";

/**
          <Link>
            <Image
              src={profileImage}
              preview={false}
              className="profile-image"
            />
          </Link>
 */

const Header = (props) => {
  const {
    title,
    type,
    isMain,
    menuClick,
    notificationClick,
    messageClick,
    onLeftIconPress,
  } = props;
  return (
    <div className="header-container">
      <div className="header-actions">
        {isMain === true ? (
          <div></div>
        ) : (
          <div className="button">
            {type === "close" ? (
              <CloseSvg />
            ) : (
              <BackSvg className="header-back" onClick={onLeftIconPress} />
            )}
          </div>
        )}
      </div>
      <div className="header-title">
        <Text className="text" text={title} />
      </div>
      {isMain === true ? (
        <div className="header-actions-main">
          
          <Link to="/launchsa/communication">
            <MessageSvg className="message" />
          </Link>
          <div style={{marginRight:10,marginLeft:10}}>
          <BellSvg className="bell" onClick={notificationClick} />
          </div>
          
          <MenuSvg className="menu" onClick={menuClick} />
        </div>
      ) : null}
    </div>
  );
};

export default Header;
