import Home from "pages/Home";
import Profile from "pages/Home/Profile";

// FIXME:Handle home page
import Events from "pages/Events";
import EventsManagement from "pages/Events/EventManagement";

import Resources from "pages/Resources";
import ResourcesManagement from "pages/Resources/ResourcesManagement";

import Notifications from "pages/Notifications";
import NotificationsManagement from "pages/Notifications/NotificationsManagement";

import News from "pages/News";

import Flags from "pages/Flags";
import FlagsDetails from "pages/Flags/Details";

import Surveys from "pages/Surveys";
import SurveyGallery from "pages/Surveys/SurveyGallery";
import SurveyEditor from "pages/SurveyEditor";
import Knowledge from "pages/Knowledge";
import KnowledgeEditor from "pages/Knowledge/KnowledgeEditor";
import KnowledgePosts from "pages/Knowledge/KnowledgePosts";
import KnowledgeDetail from "pages/Knowledge/KnowledgeBaseDetail";

import Analytics from "pages/Analytics";
import Demo from "pages/Analytics/Demo";
import AnalyticsDashboardEditor from "pages/Analytics/Editor";

import Settings from "pages/Settings";
import Page404 from "pages/errors/Page404";
import Directory from "pages/Directory";
import SingleSurveyResponse from "components/templates/SurveyResponses/SingleSurveyResponse";

import Users from "pages/User";
import UserProfile from "pages/User/UserProfile";
import NewBusiness from "pages/Directory/NewBusiness";

import Communication from "pages/Communication";
import SingleMail from "pages/Communication/Email/SingleMail";
import SubmissionDetails from "pages/Communication/Submissions/Details";
import { getLocalRoles } from "utils/common";

import ProjectsPage from "pages/Projects";

const PREFIXES = {
    HOME: "/home",
    EVENTS: "events",
    NOTIFICATIONS: "notifications",
    KNOWLEDGE: "knowledge",
    SURVEYS: "surveys",
    FLAGS: "flags",
    FEED: "feed",
    SETTINGS: "settings",
    DIRECTORY: "business-directory",
    USERS: "user-list",
    ANALYTICS: "analytics",
    RESOURCES: "resources",
    COMMUNICATION: "communications",
    PROJECT: "project",
    DEFAULT: "*",
};

// const roles = getLocalRoles();

const routes = [
    // {
    //   path: `${PREFIXES.HOME}`,
    //   component: Home,
    //    exact: true, active:true,
    // },

    {
        path: `${PREFIXES.HOME}/profile`,
        component: Profile,
        exact: true,
        active: true,
        route: PREFIXES.HOME,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.EVENTS}`,
        component: Events,
        exact: true,
        active: true,
        route: PREFIXES.EVENTS,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.EVENTS}/manage`,
        component: EventsManagement,
        exact: true,
        active: true,
        route: PREFIXES.EVENTS,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.NOTIFICATIONS}`,
        component: Notifications,
        exact: true,
        active: true,
        route: PREFIXES.NOTIFICATIONS,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.NOTIFICATIONS}/manage`,
        component: NotificationsManagement,
        exact: true,
        active: true,
        route: PREFIXES.NOTIFICATIONS,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.FEED}`,
        component: News,
        exact: true,
        active: true,
        route: "news",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.FLAGS}`,
        component: Flags,
        exact: true,
        active: true,
        route: PREFIXES.FLAGS,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.FLAGS}/details/:id`,
        component: FlagsDetails,
        exact: true,
        active: true,
        route: PREFIXES.FLAGS,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.SURVEYS}`,
        component: Surveys,
        exact: true,
        active: true,
        route: PREFIXES.SURVEYS,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.SURVEYS}/survey-gallery`,
        component: SurveyGallery,
        exact: true,
        active: true,
        route: PREFIXES.SURVEYS,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.SURVEYS}/editor/:tab?/:id?`,
        component: SurveyEditor,
        exact: true,
        active: true,
        route: PREFIXES.SURVEYS,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.SURVEYS}/response/:id?`,
        component: SingleSurveyResponse,
        exact: true,
        active: true,
        route: PREFIXES.SURVEYS,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.KNOWLEDGE}`,
        component: Knowledge,
        exact: true,
        active: true,
        //route: PREFIXES.KNOWLEDGE,
        route: "/home",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.KNOWLEDGE}/:category/post-editor`,
        component: KnowledgeEditor,
        exact: true,
        active: true,
        //route: PREFIXES.KNOWLEDGE,
        route: "/home",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.KNOWLEDGE}/post-editor`,
        component: KnowledgeEditor,
        exact: true,
        active: true,
        //route: PREFIXES.KNOWLEDGE,
        route: "/home",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.KNOWLEDGE}/:category`,
        component: KnowledgePosts,
        exact: true,
        active: true,
        //route: PREFIXES.KNOWLEDGE,
        route: "/home",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.KNOWLEDGE}/:category/:id`,
        component: KnowledgeDetail,
        exact: true,
        active: true,
        //route: PREFIXES.KNOWLEDGE,
        route: "/home",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.SETTINGS}`,
        component: Settings,
        //  exact: true,
        active: true,
        route: "/home",
    },
    // {
    //   path: `${PREFIXES.HOME}/${PREFIXES.ANALYTICS}`,
    //   component: Analytics,
    //   exact: true,
    //   active: true,
    // },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.ANALYTICS}/demo`,
        component: Demo,
        exact: true,
        active: true,
        route: "/home",
    },
    // {
    //   path: `${PREFIXES.HOME}/${PREFIXES.ANALYTICS}/new`,
    //   component: AnalyticsDashboardEditor,
    //   exact: true,
    //   active: true,
    // },
    // {
    //   path: `${PREFIXES.HOME}/${PREFIXES.ANALYTICS}/:id`,
    //   component: AnalyticsDashboardEditor,
    //   exact: true,
    //   active: true,
    // },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.DIRECTORY}`,
        component: Directory,
        exact: true,
        active: true,
        route: "directory",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.DIRECTORY}/new-business`,
        component: NewBusiness,
        exact: true,
        active: true,
        route: "directory",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.RESOURCES}`,
        component: Resources,
        exact: true,
        active: true,
        route: PREFIXES.RESOURCES,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.RESOURCES}/manage`,
        component: ResourcesManagement,
        exact: true,
        active: true,
        route: PREFIXES.RESOURCES,
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.USERS}`,
        component: Users,
        exact: true,
        active: true,
        route: "users",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.USERS}/user-profile`,
        component: UserProfile,
        exact: true,
        active: true,
        route: "users",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.COMMUNICATION}/submission/:id`,
        component: SubmissionDetails,
        exact: true,
        active: true,
        route: PREFIXES.COMMUNICATION,
        // route: "/communications",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.COMMUNICATION}`,
        component: Communication,
        active: true,
        exact: true,
        route: PREFIXES.COMMUNICATION,
        // route: "/communications",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.COMMUNICATION}/:tab`,
        component: Communication,
        active: true,
        exact: true,
        route: PREFIXES.COMMUNICATION,
        // route: "/communications",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.COMMUNICATION}/email/:id`,
        component: SingleMail,
        exact: true,
        active: true,
        route: PREFIXES.COMMUNICATION,
        // route: "/communications",
    },
    {
        path: `${PREFIXES.HOME}/${PREFIXES.PROJECT}/manage/:id?`,
        component: ProjectsPage,
        exact: true,
        active: true,
        // route: PREFIXES.PROJECT,
        route: "users",
    },
    {
        path: `${PREFIXES.DEFAULT}`,
        component: Page404,
        active: true,
        route: 404,
    },
];

export default routes;
