import React from "react";

import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import queryString from "query-string";
import axios from "axios";

import { signOut } from "api/amplifyAuthAPI";
import {deleteLocalData} from "redux/slices/Citizen";
import AsyncStorage from "@react-native-async-storage/async-storage";

class Verify extends React.PureComponent {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  componentDidMount() {
    const { logout, token, expireIn, launchsa } = queryString.parse(
      this.props.location.search
    );

    if (logout) this.handleClearCredentials(launchsa);
    else this.credentialsRedirection(token, expireIn, launchsa);
  }

  handleSaveCredentials(accessToken, expiredIn, launchsa) {
    const { cookies } = this.props;
    console.log("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      cookies.set("c_access", accessToken, {
        path: "/",
        maxAge: expiredIn,
        httpOnly: false,
        secure: false,
        sameSite: false,
      });
    } else {
      // protect cookie
      cookies.set("c_access", accessToken, {
        path: "/",
        maxAge: expiredIn,
        httpOnly: false,
        secure: false,
        sameSite: false,
      });
    }

    this.redirectToHomePage(launchsa);
  }

  handleClearCredentials(launchsa) {
    const { cookies } = this.props;
    cookies.remove("c_access", {
      path: "/"});
    deleteLocalData();
    signOut();

    localStorage.clear();
    launchsa
      ? window.location.replace(`/launchsa/`)
      : window.location.replace(`/client/login`);
  }

  credentialsRedirection(token, expireIn, launchsa) {
    const { cookies } = this.props;
    if (token && expireIn) {
      this.handleSaveCredentials(token, expireIn, launchsa);
    } else if (cookies.get("c_access")) {
      this.redirectToHomePage(launchsa);
    } else {
      this.redirectToLoginPage(launchsa);
    }
  }

  redirectToHomePage = async(launchsa) => {
    console.log("launchsa redirect", launchsa);
    const { cookies } = this.props;
    const newUser = cookies.get("newUser") || false;
    if (launchsa) {
      // checking if the newuser cookie exists
      if (newUser) {
        await AsyncStorage.setItem("onBoarding", "true");

        this.props.history.push("/launchsa/onboarding");
      } else {
        this.props.history.push("/launchsa/home");

      }
    } else {
      this.props.history.push("home/");
    }
  };

  redirectToLoginPage = (launchsa) => {
    console.log(" redirect");
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // this.props.history.push(`/login`);
      launchsa
        ? window.location.replace(`/launchsa/`)
        : window.location.replace(`/client/login`);
    } else {
      // this.props.history.push(`/login`);
      launchsa
        ? window.location.replace(`/launchsa/`)
        : window.location.replace(`/client/login`);
    }
  };

  render() {
    return <div>Espere...</div>;
  }
}

export default withCookies(Verify);
