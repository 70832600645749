import React, { useContext } from "react";
import KnowledgeHeader from "components/molecules/KnowledgeHeader";
import KnowledgeCategories from "components/organisms/KnowledgeCategories";
import KnowledgeSearch from "components/organisms/KnowledgeSearch";
import { I18nContext } from "utils/i18n/locale";
import { Text } from "components/atoms/texts";
import "./knowledge.scss";
import { getLocalSettings } from "utils/common";
const Knowledge = () => {
  const { translate } = useContext(I18nContext);
  const settings = getLocalSettings();
  console.log({ settings });
  return (
    <>
      <KnowledgeHeader title={translate("pages").knowledge.title.knowledge} />
      <div className="knowledge-search-wrapper">
        <div className="knowledge-tagline">
          <Text
            text={`${translate("pages").knowledge.title.needHelp} ${
              settings.name ?? "your project"
            }?`}
          />
        </div>
        <div className="knowledge-search">
          <KnowledgeSearch />
        </div>
      </div>

      <KnowledgeCategories />
    </>
  );
};

export default Knowledge;
