import React, { useContext, useEffect, useRef, useState } from "react";
import { Form } from "antd";

import { I18nContext } from "utils/i18n/locale";
import { CreateElementInput } from "components/atoms/inputs";

import "./CreateSocialMediaForm.scss";

import { useDispatch, useSelector } from "react-redux";
import { asyncGetData } from "redux/slices/projects";

const CreateSocialMediaForm = ({ show, onClose, globalForm, formRef }) => {
  const { translate } = useContext(I18nContext);
  const projectData = useSelector((state) => state.projects.previewData);
  // const formRef = React.createRef();

  const dispatch = useDispatch();

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const handleFormData = (data) => {
    dispatch(asyncGetData(data));
  };

  const onFinish = (data) => {};

  return (
    <Form
      form={globalForm}
      className="project-form"
      ref={formRef}
      onFinish={onFinish}
      onValuesChange={(e) => {
        handleFormData(e);
      }}
      onFinishFailed={onFinishFailed}
    >
      <h3 className="overview-title">
        {translate("pages").projects.createProjectForm.labels.addSocialLinks}
      </h3>

      <Form.Item
        name="twitter_url"
        rules={[
          {
            //required: true,
          },
        ]}
      >
        <CreateElementInput
          placeholder={
            translate("pages").projects.createProjectForm.placeholders.addLink
          }
          label={translate("pages").projects.createProjectForm.labels.twitter}
          allowClear
        />
      </Form.Item>
      <Form.Item
        name="facebook_url"
        rules={[
          {
            //required: true,
          },
        ]}
      >
        <CreateElementInput
          placeholder={
            translate("pages").projects.createProjectForm.placeholders.addLink
          }
          label={translate("pages").projects.createProjectForm.labels.facebook}
          allowClear
        />
      </Form.Item>
      <Form.Item
        name="linkedin_url"
        rules={[
          {
            //required: true,
          },
        ]}
      >
        <CreateElementInput
          placeholder={
            translate("pages").projects.createProjectForm.placeholders.addLink
          }
          label={translate("pages").projects.createProjectForm.labels.linkedin}
          allowClear
        />
      </Form.Item>
      <Form.Item
        name="instagram_url"
        rules={[
          {
            //required: true,
          },
        ]}
      >
        <CreateElementInput
          placeholder={
            translate("pages").projects.createProjectForm.placeholders.addLink
          }
          label={translate("pages").projects.createProjectForm.labels.instagram}
          allowClear
        />
      </Form.Item>
    </Form>
  );
};
export default CreateSocialMediaForm;
