import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
} from "react";
import { SpiningPage } from "components/atoms/icons";
import { Alert } from "components/atoms/outputs";
import { I18nContext } from "utils/i18n/locale";
import { useHistory, useLocation } from "react-router-dom";
// import { signIn } from "api/amplifyAuthAPI";
import { resendConfirmationCode, signUp } from "api/amplifyAuthAPI";
const AuthTemplate = lazy(() => import("components/templates/AuthTemplate"));

const renderLoader = () => <SpiningPage opacity={1} />;

const SignUp = (props) => {
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);
  const location = useLocation();
  const isMounted = useRef(null);
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    localStorage.clear();
    isMounted.current = true;
    if (location?.resend) {
      setShowModal(true);
      resendConfirmationCode(location?.credentials?.email);
      localStorage.setItem(
        "credentials",
        JSON.stringify({
          ...location?.credentials,
        })
      );
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const changeStatusLoading = (statusBoolean) => {
    setLoading(statusBoolean);
  };

  const handleSignUp = (values) => {
    const { email, password, username } = values;
    changeStatusLoading(true);
    localStorage.setItem(
      "credentials",
      JSON.stringify({
        email,
        password,
        name: username,
        last_name: "",
      })
    );
    signUp(email, username, password)
      .then((data) => {
        changeStatusLoading(false);
        setShowModal(true);
        return true;
      })
      .catch((error) => {
        console.log("en el error:", error);
        if (error.code === "UsernameExistsException") {
          Alert(
            "warning",
            translate("pages").signup.modals.verifyMail.alerts.userAlreadyExists
          );
          history.push("/login");
        }
        changeStatusLoading(false);
        return false;
      })
      .finally(() => {
        if (isMounted.current) {
          changeStatusLoading(false);
        }
      });
  };
  return (
    <Suspense fallback={renderLoader()}>
      <AuthTemplate
        handleSignUp={handleSignUp}
        loading={loading}
        signUp
        showModal={showModal}
      />
    </Suspense>
  );
};
export default SignUp;
