import React, { useEffect, useState } from "react";
import { Spin, Skeleton } from "antd";
import Icon, {
  LoadingOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  RightOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import "./icons.scss";
import { icons } from "antd/lib/image/PreviewGroup";
import { getLocalSettings } from "utils/common";
import { useSelector } from "react-redux";
// const settings = getLocalSettings();
// ant icons
const LoadingIcon = ({ color, opacity, fontSize }) => (
  <LoadingOutlined
    style={{
      fontSize: fontSize || 50,
      color: color || "black",
      opacity: opacity || 0.5,
    }}
    spin
  />
);
// const TrashIcon = <DeleteOutlined style={{ fontSize: 50, color: "black" }} />;
// svg icons
const homeSvg = (color = "white", height = "20", width = "20") => (
  <svg
    viewBox="0 0 22 22"
    width="20"
    height="20"
    className="icon-sidebar"
    fill="none"
  >
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03" transform="translate(-47.000000, -223.000000)" stroke="white">
        <g id="Group-25">
          <g id="Group-8" transform="translate(47.000000, 222.000000)">
            <g id="Group-4" transform="translate(0.000000, 0.870100)">
              <path d="M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z" fill={color} />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const mapSvg = () => (
  <svg viewBox="0 0 22 22" width="20px" height="16px" className="icon-map">
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="59" transform="translate(-43.000000, -228.000000)">
        <g id="Menu">
          <g id="Group-13">
            <g id="Group-18">
              <g id="map" transform="translate(43.000000, 228.000000)">
                <g id="Group-7">
                  <path
                    d="M17.5 0L17.34 0.03L12 2.1L6 0L0.36 1.9C0.15 1.97 0 2.15 0 2.38V17.5C0 17.78 0.22 18 0.5 18L0.66 17.97L6 15.9L12 18L17.64 16.1C17.85 16.03 18 15.85 18 15.62V0.5C18 0.22 17.78 0 17.5 0ZM12 16L6 13.89V2L12 4.11V16Z"
                    fill="white"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const analyticsSvg = (color = "white") => (
  <svg
    viewBox="0 0 22 22"
    width="20px"
    height="16px"
    className="icon-sidebar"
    fill="none"
  >
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03" transform="translate(-47.000000, -223.000000)" stroke={color}>
        <g id="Group-25">
          <g id="Group-8" transform="translate(47.000000, 222.000000)">
            <g id="Group-4" transform="translate(0.000000, 0.870100)">
              <path
                d="M14 0L16.29 2.29L11.41 7.17L7.41 3.17L0 10.59L1.41 12L7.41 6L11.41 10L17.71 3.71L20 6V0H14Z"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const surveySvg = (color = "white") => (
  <svg viewBox="0 0 22 22" width="20px" height="16px" className="icon-sidebar">
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03" transform="translate(-47.000000, -223.000000)" stroke="none">
        <g id="Group-25">
          <g id="Group-8" transform="translate(47.000000, 222.000000)">
            <g id="Group-4" transform="translate(0.000000, 0.870100)">
              <path
                d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM11 16H4V14H11V16ZM14 12H4V10H14V12ZM14 8H4V6H14V8Z"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const communicationSvg = (
  color = "white",
  height = "16px",
  width = "20px",
  margin = 0
) => (
  <svg
    viewBox="0 0 22 22"
    width={width}
    height={height}
    className="icon-sidebar"
  >
    <g id="CRM" stroke="none" strokeWidth="1" fill="white" fillRule="evenodd">
      <g id="37" transform="translate(-43.000000, -584.000000)" stroke="none">
        <g id="Menu">
          <g id="Group-7" transform="translate(43.000000, 584.000000)">
            <g id="Group-11">
              <path
                d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const chatbotSvg = () => (
  <svg
    viewBox="0 0 22 22"
    width="20px"
    height="16px"
    className="icon-sidebar"
    fill="none"
  >
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03" transform="translate(-47.000000, -223.000000)" stroke="white">
        <g id="Group-25">
          <g id="Group-8" transform="translate(47.000000, 222.000000)">
            <g id="Group-4" transform="translate(0.000000, 0.870100)">
              <path
                d="M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0Z"
                fill="white"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const flagSvg = () => (
  <svg
    viewBox="0 0 22 22"
    width="20px"
    height="15px"
    className="icon-flag"
    fill="none"
  >
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03" transform="translate(-47.000000, -223.000000)" stroke="white">
        <g id="Group-25">
          <g id="Group-8" transform="translate(47.000000, 222.000000)">
            <g id="Group-4" transform="translate(0.000000, 0.870100)">
              <path d="M9.4 2L9 0H0V17H2V10H7.6L8 12H15V2H9.4Z" fill="white" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const taskSvg = () => (
  <svg
    viewBox="0 0 22 22"
    width="20px"
    height="16px"
    className="icon-sidebar"
    fill="none"
  >
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03" transform="translate(-47.000000, -223.000000)" stroke="white">
        <g id="Group-25">
          <g id="Group-8" transform="translate(47.000000, 222.000000)">
            <g id="Group-4" transform="translate(0.000000, 0.870100)">
              <path
                d="M18 4H14V2C14 0.89 13.11 0 12 0H8C6.89 0 6 0.89 6 2V4H2C0.89 4 0.00999999 4.89 0.00999999 6L0 17C0 18.11 0.89 19 2 19H18C19.11 19 20 18.11 20 17V6C20 4.89 19.11 4 18 4ZM12 4H8V2H12V4Z"
                fill="white"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const notificationSvg = (
  color = "white",
  height = "16px",
  width = "20px",
  margin = 0
) => (
  <svg
    viewBox="0 0 22 22"
    width={width}
    height={height}
    className="icon-sidebar"
    fill="none"
    style={{ marginLeft: margin }}
  >
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03" transform="translate(-47.000000, -223.000000)" stroke={color}>
        <g id="Group-25">
          <g id="Group-8" transform="translate(47.000000, 222.000000)">
            <g id="Group-4" transform="translate(0.000000, 0.870100)">
              <path
                d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM14 14V9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.63 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14Z"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const mentionSvg = () => (
  <svg
    viewBox="0 0 22 22"
    width="20px"
    height="16px"
    className="icon-sidebar"
    fill="none"
  >
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03" transform="translate(-47.000000, -223.000000)" stroke="white">
        <g id="Group-25">
          <g id="Group-8" transform="translate(47.000000, 222.000000)">
            <g id="Group-4" transform="translate(0.000000, 0.870100)">
              <path
                d="M8 13C5.33 13 0 14.34 0 17V19H16V17C16 14.34 10.67 13 8 13ZM15.76 3.36L14.08 5.05C14.92 6.23 14.92 7.76 14.08 8.94L15.76 10.63C17.78 8.61 17.78 5.56 15.76 3.36ZM19.07 0L17.44 1.63C20.21 4.65 20.21 9.19 17.44 12.37L19.07 14C22.97 10.11 22.98 4.05 19.07 0Z"
                fill="white"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const logoutSvg = (
  color = "white",
  height = "18px",
  width = "18px",
  margin = 0
) => (
  <svg
    viewBox="0 0 20 20"
    width={width}
    height={height}
    className="icon-sidebar"
    fill="none"
    style={{ marginLeft: margin }}
  >
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="03" transform="translate(-47.000000, -223.000000)" stroke={color}>
        <g id="Group-25">
          <g id="Group-8" transform="translate(47.000000, 222.000000)">
            <g id="Group-4" transform="translate(0.000000, 0.870100)">
              <path
                d="M10 0H8V10H10V0ZM14.83 2.17L13.41 3.59C14.99 4.86 16 6.81 16 9C16 12.87 12.87 16 9 16C5.13 16 2 12.87 2 9C2 6.81 3.01 4.86 4.58 3.58L3.17 2.17C1.23 3.82 0 6.26 0 9C0 13.97 4.03 18 9 18C13.97 18 18 13.97 18 9C18 6.26 16.77 3.82 14.83 2.17Z"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const eventsSvg = (color = "white", height = "16px") => (
  <svg
    viewBox="0 0 22 22"
    width="20px"
    height={height}
    className="icon-sidebar"
  >
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="06" transform="translate(-46.000000, -246.000000)">
        <g id="Menu">
          <g id="Group-8" transform="translate(16.000000, 144.000000)">
            <g id="Group-3" transform="translate(28.000000, 102.000000)">
              <g id="Group-6" transform="translate(2.000000, 0.000000)">
                <path
                  d="M14 11H9V16H14V11ZM13 0V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2H15V0H13ZM16 18H2V7H16V18Z"
                  fill={color}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const feedSvg = (color = "white") => (
  <svg viewBox="0 0 22 22" width="16px" height="16px" className="icon-user">
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="22" transform="translate(-44.000000, -338.000000)" stroke="none">
        <g id="Menu">
          <g id="Group-8" transform="translate(16.000000, 152.000000)">
            <g id="Group-7" transform="translate(28.000000, 186.000000)">
              <g id="Group">
                <g id="Group-9">
                  <path
                    d="M6.18 20.0001C7.38398 20.0001 8.36 19.0241 8.36 17.8201C8.36 16.6162 7.38398 15.6401 6.18 15.6401C4.97602 15.6401 4 16.6162 4 17.8201C4 19.0241 4.97602 20.0001 6.18 20.0001Z"
                    fill="white"
                  />
                  <path
                    d="M4 4.43994V7.26994C11.03 7.26994 16.73 12.9699 16.73 19.9999H19.56C19.56 11.4099 12.59 4.43994 4 4.43994ZM4 10.0999V12.9299C7.9 12.9299 11.07 16.0999 11.07 19.9999H13.9C13.9 14.5299 9.47 10.0999 4 10.0999Z"
                    fill={color}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const userSvg = (color = "white") => (
  <svg viewBox="0 0 22 14" width="22" height="14" className="icon-user">
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="37" transform="translate(-43.000000, -584.000000)" stroke="none">
        <g id="Menu">
          <g id="Group-7" transform="translate(43.000000, 584.000000)">
            <g id="Group-11">
              <path
                d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V14H14V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C15.19 8.89 16 10.02 16 11.5V14H22V11.5C22 9.17 17.33 8 15 8Z"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const faqSvg = () => (
  <svg viewBox="0 0 20 20" className="icon-sidebar">
    <g id="CRM" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="50" transform="translate(-48.000000, -674.000000)">
        <g id="Menu">
          <g id="Group-5" transform="translate(48.000000, 674.000000)">
            <g id="Faqu" transform="translate(1.000000, 0.000000)">
              <g id="Group-5" transform="translate(0.000000, 1.000000)">
                <path
                  d="M15.1111 0H1.88889C0.840555 0 0 0.857143 0 1.90476V15.2381C0 16.2857 0.840555 17.1429 1.88889 17.1429H5.66667L8.5 20L11.3333 17.1429H15.1111C16.15 17.1429 17 16.2857 17 15.2381V1.90476C17 0.857143 16.15 0 15.1111 0ZM9.44444 15.2381H7.55556V13.3333H9.44444V15.2381ZM11.3994 7.85714L10.5494 8.73333C9.86944 9.42857 9.44444 10 9.44444 11.4286H7.55556V10.9524C7.55556 9.90476 7.98056 8.95238 8.66056 8.25714L9.83167 7.05714C10.1811 6.71429 10.3889 6.2381 10.3889 5.71429C10.3889 4.66667 9.53889 3.80952 8.5 3.80952C7.46111 3.80952 6.61111 4.66667 6.61111 5.71429H4.72222C4.72222 3.60952 6.41278 1.90476 8.5 1.90476C10.5872 1.90476 12.2778 3.60952 12.2778 5.71429C12.2778 6.55238 11.9378 7.31429 11.3994 7.85714Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const commentsSvg = (color = "white") => (
  <svg width="16px" viewBox="0 0 20 20" fill="none">
    <path
      d="M17.333 0.833344H2.66634C1.65801 0.833344 0.833008 1.65834 0.833008 2.66668V19.1667L4.49967 15.5H17.333C18.3413 15.5 19.1663 14.675 19.1663 13.6667V2.66668C19.1663 1.65834 18.3413 0.833344 17.333 0.833344ZM17.333 13.6667H4.49967L2.66634 15.5V2.66668H17.333V13.6667Z"
      fill={color}
    />
  </svg>
);

const attachImageSvg = () => (
  <svg width="16px" height="16px" viewBox="0 0 19 19" fill="none">
    <path
      d="M16 5V7.99C16 7.99 14.01 8 14 7.99V5H11C11 5 11.01 3.01 11 3H14V0H16V3H19V5H16ZM13 9V6H10V3H2C0.9 3 0 3.9 0 5V17C0 18.1 0.9 19 2 19H14C15.1 19 16 18.1 16 17V9H13ZM2 17L5 13L7 16L10 12L14 17H2Z"
      fill="#141414"
      fillOpacity="0.54"
    />
  </svg>
);

const kanbanSvg = (color = "#5C5C5C") => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" fill="none">
    <path
      d="M2 11V1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1V11C0 11.5523 0.447715 12 1 12C1.55228 12 2 11.5523 2 11Z"
      fill={color}
    />
    <path
      d="M16 8V1C16 0.447715 15.5523 0 15 0C14.4477 0 14 0.447715 14 1V8C14 8.55228 14.4477 9 15 9C15.5523 9 16 8.55228 16 8Z"
      fill={color}
    />
    <path
      d="M9 1V15C9 15.5523 8.55229 16 8 16C7.44772 16 7 15.5523 7 15V1C7 0.447715 7.44772 0 8 0C8.55229 0 9 0.447715 9 1Z"
      fill={color}
    />
  </svg>
);

const listViewSvg = (color = "#5C5C5C") => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" fill="none">
    <path
      d="M1 2H15C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0H1C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2Z"
      fill={color}
    />
    <path
      d="M1 16H15C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14H1C0.447715 14 0 14.4477 0 15C0 15.5523 0.447715 16 1 16Z"
      fill={color}
    />
    <path
      d="M15 9H1C0.447715 9 0 8.55229 0 8C0 7.44772 0.447715 7 1 7H15C15.5523 7 16 7.44772 16 8C16 8.55229 15.5523 9 15 9Z"
      fill={color}
    />
  </svg>
);

const menuThreeDotsSvg = (color = "#141414", direction = "vertical") => (
  <svg
    width={direction === "vertical" ? "4" : "16"}
    height={direction === "vertical" ? "16" : "4"}
    viewBox={direction === "vertical" ? "0 0 4 16" : "0 0 16 4"}
    fill="none"
  >
    <path
      d={
        direction === "vertical"
          ? "M0.00012207 14.0001C0.00012207 15.1001 0.900122 16.0001 2.00012 16.0001C3.10012 16.0001 4.00012 15.1001 4.00012 14.0001C4.00012 12.9001 3.10012 12.0001 2.00012 12.0001C0.900122 12.0001 0.00012207 12.9001 0.00012207 14.0001ZM0.00012207 2.00012C0.00012207 3.10012 0.900122 4.00012 2.00012 4.00012C3.10012 4.00012 4.00012 3.10012 4.00012 2.00012C4.00012 0.900122 3.10012 0.00012207 2.00012 0.00012207C0.900122 0.00012207 0.00012207 0.900122 0.00012207 2.00012ZM0.00012207 8.00012C0.00012207 9.10012 0.900122 10.0001 2.00012 10.0001C3.10012 10.0001 4.00012 9.10012 4.00012 8.00012C4.00012 6.90012 3.10012 6.00012 2.00012 6.00012C0.900122 6.00012 0.00012207 6.90012 0.00012207 8.00012Z"
          : "M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0Z"
      }
      fill={color}
      fillOpacity="0.54"
    />
  </svg>
);

const checkSvg = (color = "#141414", opacity = "0.54") => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none">
    <path
      d="M6 11.17L1.83 7.00003L0.410004 8.41003L6 14L18 2.00003L16.59 0.590027L6 11.17Z"
      fill={color}
      fillOpacity={opacity}
    />
  </svg>
);

const addUserSvg = (color = "#141414") => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none">
    <path
      d="M14 8C16.21 8 18 6.21 18 4C18 1.79 16.21 0 14 0C11.79 0 10 1.79 10 4C10 6.21 11.79 8 14 8ZM5 6V3H3V6H0V8H3V11H5V8H8V6H5ZM14 10C11.33 10 6 11.34 6 14V16H22V14C22 11.34 16.67 10 14 10Z"
      fill={color}
      fillOpacity="0.38"
    />
  </svg>
);

const moveSvg = (color = "#141414") => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path d="M20 4L16 0V3H1V5H16V8L20 4Z" fill={color} fillOpacity="0.54" />
    <path d="M0 12L4 16V13H19V11H4V8L0 12Z" fill={color} fillOpacity="0.54" />
  </svg>
);

const editSvg = (color = "#141414") => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path
      d="M0 15.25V19H3.75L14.81 7.94L11.06 4.19L0 15.25ZM17.71 5.04C18.1 4.65 18.1 4.02 17.71 3.63L15.37 1.29C14.98 0.899998 14.35 0.899998 13.96 1.29L12.13 3.12L15.88 6.87L17.71 5.04Z"
      fill={color}
      fillOpacity="0.54"
    />
  </svg>
);

const crossSvg = (color = "#141414", opacity = 0.54) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill={color}
      fillOpacity={`${opacity}`}
    />
  </svg>
);

const tickSvg = (color = "#141414") => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none">
    <path
      d="M5.99997 11.17L1.82997 7.00003L0.409973 8.41003L5.99997 14L18 2.00003L16.59 0.590027L5.99997 11.17Z"
      fill={color}
      fillOpacity="0.54"
    />
  </svg>
);

const flagsSvg = (color = "white") => (
  <svg viewBox="0 0 15 17" width="100%" fill="none">
    <path
      d="M9 2L8 0H0V17H2V10H7L8 12H15V2H9ZM13 10H9L8 8H2V2H7L8 4H13V10Z"
      fill={color}
    />
  </svg>
);

const personSvg = (color = "#141414") => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M8 1.9C9.16 1.9 10.1 2.84 10.1 4C10.1 5.16 9.16 6.1 8 6.1C6.84 6.1 5.9 5.16 5.9 4C5.9 2.84 6.84 1.9 8 1.9ZM8 10.9C10.97 10.9 14.1 12.36 14.1 13V14.1H1.9V13C1.9 12.36 5.03 10.9 8 10.9ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9Z"
      fill={color}
    />
  </svg>
);

const mapPinSvg = (color = "#141414") => (
  <svg width="14" height="20" viewBox="0 0 14 20" fill="none">
    <path
      d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"
      fill={color}
      fillOpacity="0.54"
    />
  </svg>
);

const topicIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
  >
    <rect width="24" height="24" rx="4" fill="#DA8BE3" />
    <path
      d="M5.25 16.5H18.75V15H5.25V16.5ZM5.25 12.75H18.75V11.25H5.25V12.75ZM5.25 7.5V9H18.75V7.5H5.25Z"
      fill="white"
    />
  </svg>
);
const imageIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
  >
    <rect width="24" height="24" rx="4" fill="#C43A5A" />
    <path
      d="M18.75 17.25V6.75C18.75 5.925 18.075 5.25 17.25 5.25H6.75C5.925 5.25 5.25 5.925 5.25 6.75V17.25C5.25 18.075 5.925 18.75 6.75 18.75H17.25C18.075 18.75 18.75 18.075 18.75 17.25ZM9.375 13.125L11.25 15.3825L13.875 12L17.25 16.5H6.75L9.375 13.125Z"
      fill="white"
    />
  </svg>
);
const locationIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
  >
    <rect width="24" height="24" rx="4" fill="#00B999" />
    <path
      d="M12 4.5C9.0975 4.5 6.75 6.8475 6.75 9.75C6.75 13.6875 12 19.5 12 19.5C12 19.5 17.25 13.6875 17.25 9.75C17.25 6.8475 14.9025 4.5 12 4.5ZM12 11.625C10.965 11.625 10.125 10.785 10.125 9.75C10.125 8.715 10.965 7.875 12 7.875C13.035 7.875 13.875 8.715 13.875 9.75C13.875 10.785 13.035 11.625 12 11.625Z"
      fill="white"
    />
  </svg>
);
const dateIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
  >
    <rect width="24" height="24" rx="4" fill="#64B6F7" />
    <path
      d="M9.75 11.25H8.25V12.75H9.75V11.25ZM12.75 11.25H11.25V12.75H12.75V11.25ZM15.75 11.25H14.25V12.75H15.75V11.25ZM17.25 6H16.5V4.5H15V6H9V4.5H7.5V6H6.75C5.9175 6 5.2575 6.675 5.2575 7.5L5.25 18C5.25 18.825 5.9175 19.5 6.75 19.5H17.25C18.075 19.5 18.75 18.825 18.75 18V7.5C18.75 6.675 18.075 6 17.25 6ZM17.25 18H6.75V9.75H17.25V18Z"
      fill="white"
    />
  </svg>
);
const websiteIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="icon"
  >
    <rect width="24" height="24" rx="4" fill="#A080E1" />
    <path
      d="M5.925 12C5.925 10.7175 6.9675 9.675 8.25 9.675H11.25V8.25H8.25C6.18 8.25 4.5 9.93 4.5 12C4.5 14.07 6.18 15.75 8.25 15.75H11.25V14.325H8.25C6.9675 14.325 5.925 13.2825 5.925 12ZM9 12.75H15V11.25H9V12.75ZM15.75 8.25H12.75V9.675H15.75C17.0325 9.675 18.075 10.7175 18.075 12C18.075 13.2825 17.0325 14.325 15.75 14.325H12.75V15.75H15.75C17.82 15.75 19.5 14.07 19.5 12C19.5 9.93 17.82 8.25 15.75 8.25Z"
      fill="white"
    />
  </svg>
);
const surveyTemplateOneSvg = (props) => (
  <svg
    width="146"
    height="146"
    viewBox="-2 0 160 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0 40C0 17.9086 17.9086 0 40 0H106C128.091 0 146 17.9086 146 40V106C146 128.091 128.091 146 106 146H40C17.9086 146 0 128.091 0 106V40Z"
        fill="#FAC6D4"
      />
      <circle
        cx="72.3074"
        cy="73.7399"
        r="61.1999"
        fill="#141414"
        fillOpacity="0.08"
      />
      <g opacity="0.5" filter="url(#filter0_f)">
        <rect
          x="47.2503"
          y="61.5688"
          width="48.6825"
          height="68.7282"
          rx="9.75081"
          fill="url(#paint0_linear)"
        />
      </g>
      <circle
        cx="176.915"
        cy="5.09412"
        r="104.875"
        stroke="#141414"
        strokeWidth="0.895706"
      />
      <circle
        cx="176.832"
        cy="5.01167"
        r="70.4281"
        stroke="#141414"
        strokeWidth="0.895706"
      />
      <circle
        cx="20.5649"
        cy="148.826"
        r="48.6214"
        stroke="#141414"
        strokeWidth="0.895706"
      />
      <circle
        cx="20.5636"
        cy="148.825"
        r="26.4297"
        stroke="#141414"
        strokeWidth="0.895706"
      />
      <path
        d="M48.0005 65.1334L78.1607 23.5533C81.8172 18.5123 89.0079 17.6875 93.7108 21.7698L122.431 46.6997C127.095 50.7479 127.332 57.9076 122.945 62.2549L86.5628 98.3115C82.6272 102.212 76.3537 102.429 72.158 98.8098L49.6597 79.4034C45.4828 75.8004 44.7617 69.5986 48.0005 65.1334Z"
        fill="#96314B"
        stroke="#141414"
        strokeWidth="1.79141"
      />
      <path
        d="M116.598 40.451L94.2974 21.0936C89.1976 16.6669 81.4001 17.5612 77.435 23.0277L47.2748 64.6078C43.7627 69.4497 44.5447 76.1749 49.0741 80.0818L71.5724 99.4883C73.4131 101.076 75.6226 101.983 77.8839 102.22L116.598 40.451Z"
        fill="black"
      />
      <path
        d="M28.0813 23.2378L10.8093 88.1925C9.15252 94.4231 12.5443 100.884 18.6134 103.059L56.7804 116.737C62.8768 118.922 69.628 116.049 72.2819 110.142L99.7469 49.0104C102.705 42.4264 99.4394 34.7168 92.6522 32.2607L44.2922 14.7602C37.448 12.2835 29.9518 16.2036 28.0813 23.2378Z"
        fill="#F46F92"
        stroke="#141414"
        strokeWidth="1.79141"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.276 38.4941C55.8358 39.3063 55.6311 40.4185 54.8189 40.9783L40.1334 51.0994C39.696 51.4009 39.147 51.492 38.6356 51.3479C38.1242 51.2039 37.7035 50.8396 37.4878 50.3541L34.3125 43.2099C33.9119 42.3084 34.3179 41.2529 35.2193 40.8523C36.1207 40.4517 37.1762 40.8577 37.5768 41.7591L39.8781 46.937L52.7918 38.037C53.604 37.4773 54.7162 37.6819 55.276 38.4941Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.1178 51.0283C52.4413 50.0964 53.4589 49.6032 54.3908 49.9266L78.4035 58.2616C79.3354 58.5851 79.8286 59.6028 79.5052 60.5346C79.1817 61.4665 78.1641 61.9597 77.2322 61.6363L53.2194 53.3013C52.2876 52.9778 51.7943 51.9602 52.1178 51.0283Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.7579 68.0635C45.3177 68.8757 45.113 69.9879 44.3008 70.5476L29.6153 80.6687C29.1779 80.9702 28.6289 81.0613 28.1175 80.9173C27.6061 80.7732 27.1854 80.409 26.9697 79.9235L23.7944 72.7792C23.3938 71.8778 23.7998 70.8223 24.7012 70.4217C25.6026 70.021 26.6581 70.427 27.0587 71.3284L29.36 76.5064L42.2737 67.6064C43.0859 67.0466 44.1981 67.2513 44.7579 68.0635Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.599 80.5954C41.9224 79.6635 42.9401 79.1703 43.872 79.4938L67.8847 87.8288C68.8166 88.1522 69.3098 89.1699 68.9863 90.1018C68.6629 91.0337 67.6452 91.5269 66.7134 91.2034L42.7006 82.8684C41.7687 82.5449 41.2755 81.5273 41.599 80.5954Z"
        fill="white"
      />
      <path
        d="M58.4699 116.364C58.2369 116.297 58.0046 116.223 57.7735 116.14L43.7633 111.12L57.3784 80.1895L58.4699 116.364Z"
        fill="#D65073"
      />
      <rect
        x="54.8971"
        y="70.3645"
        width="65.6931"
        height="65.6931"
        rx="11.2152"
        fill="#F8D8F8"
        stroke="#141414"
        strokeWidth="1.79141"
      />
      <path
        d="M62.2387 134.011C93.9122 115.924 110.913 92.4456 118.308 76.3838C119.029 77.7924 119.435 79.3884 119.435 81.0795V124.342C119.435 130.041 114.815 134.662 109.116 134.662H65.8533C64.5816 134.662 63.3636 134.432 62.2387 134.011Z"
        fill="#FBBFFB"
      />
      <rect
        x="68.0973"
        y="105.396"
        width="8.335"
        height="15.4793"
        rx="1.26895"
        fill="white"
        stroke="#141414"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="83.5758"
        y="97.4561"
        width="8.335"
        height="23.4174"
        rx="1.26895"
        fill="white"
        stroke="#141414"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="99.0565"
        y="88.7246"
        width="8.335"
        height="32.1493"
        rx="1.26895"
        fill="white"
        stroke="#141414"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M40 1H106V-1H40V1ZM145 40V106H147V40H145ZM106 145H40V147H106V145ZM1 106V40H-1V106H1ZM40 145C18.4609 145 1 127.539 1 106H-1C-1 128.644 17.3563 147 40 147V145ZM145 106C145 127.539 127.539 145 106 145V147C128.644 147 147 128.644 147 106H145ZM106 1C127.539 1 145 18.4609 145 40H147C147 17.3563 128.644 -1 106 -1V1ZM40 -1C17.3563 -1 -1 17.3563 -1 40H1C1 18.4609 18.4609 1 40 1V-1Z"
      fill="#141414"
    />
    <defs>
      <filter
        id="filter0_f"
        x="4.29516"
        y="18.6137"
        width="134.593"
        height="154.638"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="21.4776"
          result="effect1_foregroundBlur"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="58.705"
        y1="71.1678"
        x2="70.3232"
        y2="136.743"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#141414" />
        <stop offset="1" stopColor="#141414" />
      </linearGradient>
      <clipPath id="clip0">
        <path
          d="M0 40C0 17.9086 17.9086 0 40 0H106C128.091 0 146 17.9086 146 40V106C146 128.091 128.091 146 106 146H40C17.9086 146 0 128.091 0 106V40Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

const palatteSvg = (color = "#141414") => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M7 0.25C3.2725 0.25 0.25 3.2725 0.25 7C0.25 10.7275 3.2725 13.75 7 13.75C7.6225 13.75 8.125 13.2475 8.125 12.625C8.125 12.3325 8.0125 12.07 7.8325 11.8675C7.66 11.6725 7.5475 11.41 7.5475 11.125C7.5475 10.5025 8.05 10 8.6725 10H10C12.07 10 13.75 8.32 13.75 6.25C13.75 2.935 10.7275 0.25 7 0.25ZM2.875 7C2.2525 7 1.75 6.4975 1.75 5.875C1.75 5.2525 2.2525 4.75 2.875 4.75C3.4975 4.75 4 5.2525 4 5.875C4 6.4975 3.4975 7 2.875 7ZM5.125 4C4.5025 4 4 3.4975 4 2.875C4 2.2525 4.5025 1.75 5.125 1.75C5.7475 1.75 6.25 2.2525 6.25 2.875C6.25 3.4975 5.7475 4 5.125 4ZM8.875 4C8.2525 4 7.75 3.4975 7.75 2.875C7.75 2.2525 8.2525 1.75 8.875 1.75C9.4975 1.75 10 2.2525 10 2.875C10 3.4975 9.4975 4 8.875 4ZM11.125 7C10.5025 7 10 6.4975 10 5.875C10 5.2525 10.5025 4.75 11.125 4.75C11.7475 4.75 12.25 5.2525 12.25 5.875C12.25 6.4975 11.7475 7 11.125 7Z"
      fill="#141414"
      fillOpacity="0.54"
    />
  </svg>
);

const backIconSvg = (color = "#808080") => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.59 1.41L14.17 5H0V7H14.17L10.58 10.59L12 12L18 6L12 0L10.59 1.41ZM19 0V12H21V0H19Z"
      fill={color}
    />
  </svg>
);

const filterIcon = (color = "#808080") => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z" fill={color} />
  </svg>
);

export const TopicIcon = (props) => (
  <Icon component={topicIconSVG} {...props} />
);
export const ImageIcon = (props) => (
  <Icon component={imageIconSVG} {...props} />
);
export const LocationIcon = (props) => (
  <Icon component={locationIconSVG} {...props} />
);
export const DateIcon = (props) => <Icon component={dateIconSVG} {...props} />;
export const WebSiteIcon = (props) => (
  <Icon component={websiteIconSVG} {...props} />
);
const graphIcon = (color = "#00B999") => (
  <svg width="100%" height="100%" viewBox="0 0 20 14" fill="none">
    <path
      d="M1.5 13.4898L7.5 7.47976L11.5 11.4798L20 1.91977L18.59 0.509766L11.5 8.47976L7.5 4.47976L0 11.9898L1.5 13.4898Z"
      fill={color}
    />
  </svg>
);

const scatterDots = (color = "#00B999") => (
  <svg width="100%" height="100%" viewBox="0 0 16 18" fill="none">
    <path
      d="M3 14C4.65685 14 6 12.6569 6 11C6 9.34315 4.65685 8 3 8C1.34315 8 0 9.34315 0 11C0 12.6569 1.34315 14 3 14Z"
      fill={color}
    />
    <path
      d="M7 6C8.65685 6 10 4.65685 10 3C10 1.34315 8.65685 0 7 0C5.34315 0 4 1.34315 4 3C4 4.65685 5.34315 6 7 6Z"
      fill="#00B999"
    />
    <path
      d="M12.5996 17.6001C14.2565 17.6001 15.5996 16.257 15.5996 14.6001C15.5996 12.9432 14.2565 11.6001 12.5996 11.6001C10.9428 11.6001 9.59961 12.9432 9.59961 14.6001C9.59961 16.257 10.9428 17.6001 12.5996 17.6001Z"
      fill="#00B999"
    />
  </svg>
);

const timerIcon = (color = "#00B999") => (
  <svg width="100%" height="100%" viewBox="0 0 18 21" fill="none">
    <path
      d="M12 0H6V2H12V0ZM8 13H10V7H8V13ZM16.03 6.39L17.45 4.97C17.02 4.46 16.55 3.98 16.04 3.56L14.62 4.98C13.07 3.74 11.12 3 9 3C4.03 3 0 7.03 0 12C0 16.97 4.02 21 9 21C13.98 21 18 16.97 18 12C18 9.88 17.26 7.93 16.03 6.39ZM9 19C5.13 19 2 15.87 2 12C2 8.13 5.13 5 9 5C12.87 5 16 8.13 16 12C16 15.87 12.87 19 9 19Z"
      fill="#00B999"
    />
  </svg>
);
const surveyTemplateTwoSvg = (props) => (
  <svg
    width="146"
    height="146"
    viewBox="-2 0 160 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0 40C0 17.9086 17.9086 0 40 0H106C128.091 0 146 17.9086 146 40V106C146 128.091 128.091 146 106 146H40C17.9086 146 0 128.091 0 106V40Z"
        fill="#C4FFF3"
      />
      <circle
        cx="71.9684"
        cy="73.7591"
        r="52.2621"
        fill="#141414"
        fillOpacity="0.08"
      />
      <path
        d="M79.832 45.3899C59.3761 39.1395 48.2503 24.8253 42.6436 9.43936C37.0982 -5.77804 36.9615 -22.0269 38.5612 -32.485H187.328C185.292 -0.658314 181.549 44.1396 176.892 75.7319C175.722 83.6687 174.495 90.7667 173.224 96.6134C171.951 102.468 170.64 107.04 169.31 109.941C168.641 111.399 167.99 112.384 167.376 112.909C167.073 113.167 166.801 113.296 166.557 113.334C166.32 113.371 166.071 113.329 165.797 113.174C165.221 112.847 164.563 112.038 163.861 110.566C163.167 109.112 162.461 107.08 161.749 104.408C154.58 77.5244 147.016 66.6238 134.667 60.5402C128.527 57.5156 121.228 55.6938 112.286 53.6482C111.247 53.4105 110.185 53.1698 109.1 52.9237C100.834 51.0489 91.2067 48.8655 79.832 45.3899Z"
        stroke="#141414"
        strokeWidth="0.895706"
      />
      <path
        d="M-21.4449 115.391L-3.92657 44.7091C3.06437 43.0983 13.8537 42.4846 23.8243 45.7984C33.8965 49.146 43.1369 56.4984 46.8168 70.935C49.1583 80.121 52.6862 85.9457 57.0896 89.5714C61.4944 93.1982 66.7208 94.5794 72.3653 94.9858C76.7569 95.302 81.4413 95.029 86.2126 94.7509C87.5568 94.6726 88.9079 94.5938 90.2613 94.5278C96.4404 94.2261 102.697 94.1848 108.717 95.6014C120.775 98.4386 128.905 104.811 133.941 113.117C138.985 121.434 140.944 131.722 140.589 142.401C140.524 144.345 139.323 146.05 137.031 147.54C134.737 149.031 131.414 150.261 127.267 151.254C118.978 153.238 107.534 154.244 94.8115 154.586C69.4351 155.27 39.0776 153.314 18.8035 151.292L-21.4449 115.391Z"
        stroke="#141414"
        strokeWidth="0.895706"
      />
      <path
        d="M-10.5311 134.439L-24.8156 99.5964C-19.9936 96.7142 -12.0032 93.5585 -3.31538 93.2357C5.55481 92.9061 15.1372 95.5271 22.8531 104.402C32.6538 115.674 41.1213 121.818 50.1587 123.728C59.1971 125.638 68.7099 123.293 80.5476 117.82C92.2886 112.393 104.565 110.716 114.885 113.675C125.183 116.629 133.586 124.212 137.603 137.431C138.368 139.949 137.85 142.318 136.231 144.578C134.601 146.856 131.856 149.016 128.198 151.048C120.885 155.111 110.068 158.584 97.7111 161.488C73.0992 167.273 42.5222 170.77 21.777 172.224L-10.5311 134.439Z"
        stroke="#141414"
        strokeWidth="0.895706"
      />
      <path
        d="M45.9432 70.0444L74.5131 30.6568C77.9768 25.8816 84.7883 25.1003 89.2433 28.9673L116.449 52.5827C120.867 56.4175 121.091 63.1996 116.936 67.3176L82.4721 101.473C78.7441 105.168 72.8013 105.373 68.8269 101.945L47.5149 83.5619C43.5582 80.149 42.8751 74.2742 45.9432 70.0444Z"
        fill="#7B7B7B"
        stroke="#141414"
        strokeWidth="1.69695"
      />
      <path
        d="M110.925 46.6629L89.8002 28.3262C84.9692 24.1329 77.583 24.9801 73.8269 30.1583L45.2571 69.5459C41.9301 74.1325 42.6709 80.5031 46.9614 84.204L68.2734 102.587C70.0171 104.091 72.1101 104.95 74.2521 105.175L110.925 46.6629Z"
        fill="black"
      />
      <path
        d="M27.0738 30.3568L10.7125 91.8865C9.14305 97.7886 12.3559 103.909 18.1051 105.969L54.2595 118.926C60.0344 120.995 66.4296 118.275 68.9437 112.679L94.9605 54.7705C97.7626 48.5336 94.6692 41.2306 88.2398 38.904L42.4298 22.3263C35.9465 19.9801 28.8456 23.6936 27.0738 30.3568Z"
        fill="#E6F0EF"
        stroke="#141414"
        strokeWidth="1.69695"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.8356 44.8096C53.3658 45.579 53.172 46.6325 52.4026 47.1628L38.4915 56.7502C38.0771 57.0358 37.5571 57.1221 37.0726 56.9856C36.5882 56.8491 36.1897 56.5041 35.9853 56.0442L32.9775 49.2767C32.598 48.4228 32.9825 47.4229 33.8364 47.0434C34.6903 46.6639 35.6901 47.0485 36.0696 47.9024L38.2496 52.8073L50.4824 44.3766C51.2518 43.8463 52.3053 44.0402 52.8356 44.8096Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.843 56.6822C50.1494 55.7995 51.1134 55.3323 51.9961 55.6387L74.7427 63.5342C75.6255 63.8406 76.0927 64.8046 75.7863 65.6873C75.4799 66.5701 74.5159 67.0373 73.6331 66.7309L50.8866 58.8354C50.0038 58.529 49.5366 57.565 49.843 56.6822Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.8728 72.8203C43.4031 73.5897 43.2092 74.6433 42.4398 75.1735L28.5287 84.7609C28.1143 85.0465 27.5943 85.1328 27.1099 84.9963C26.6254 84.8599 26.2269 84.5149 26.0225 84.055L23.0147 77.2874C22.6352 76.4335 23.0198 75.4337 23.8736 75.0542C24.7275 74.6747 25.7274 75.0592 26.1069 75.9131L28.2868 80.818L40.5196 72.3873C41.289 71.8571 42.3426 72.0509 42.8728 72.8203Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.8814 84.693C40.1878 83.8102 41.1518 83.343 42.0345 83.6494L64.7811 91.5449C65.6638 91.8513 66.131 92.8153 65.8246 93.6981C65.5182 94.5808 64.5542 95.048 63.6715 94.7416L40.9249 86.8461C40.0422 86.5397 39.575 85.5757 39.8814 84.693Z"
        fill="black"
      />
      <path
        d="M55.8592 118.573C55.6385 118.511 55.4185 118.44 55.1995 118.362L41.9281 113.606L55.1995 98.7068L55.8592 118.573Z"
        fill="#A9A9A9"
      />
      <path
        d="M64.9673 101.429L64.5644 101.678V102.151V120.153V121.002H65.4128H97.5574C102.579 121.002 107.454 119.306 111.392 116.189L134.013 98.2859C136.334 96.4491 136.585 93.0192 134.557 90.8639C132.913 89.118 130.269 88.7679 128.228 90.026L109.79 101.392C107.458 102.83 104.866 103.794 102.161 104.232L92.1275 105.853C91.24 105.997 90.435 105.311 90.435 104.412C90.435 103.752 90.8783 103.174 91.5161 103.003L106.645 98.9401C108.33 98.4876 109.501 96.9603 109.501 95.2156C109.501 93.0858 107.775 91.3592 105.645 91.3592H87.8513C83.5604 91.3592 79.354 92.5524 75.7023 94.8056L64.9673 101.429Z"
        fill="#F4FFB5"
        stroke="black"
        strokeWidth="1.69695"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.5326 99.6091L65.4128 102.151V120.153H79.1008L69.5326 99.6091Z"
        fill="#CDDC77"
      />
      <rect
        x="54.775"
        y="97.8563"
        width="12.0186"
        height="24.3626"
        rx="0.848475"
        fill="#B2D600"
        stroke="#141414"
        strokeWidth="1.69695"
      />
      <path
        d="M53.9265 111.239H67.6421V121.525C67.6421 122.472 66.8745 123.24 65.9277 123.24H55.641C54.6941 123.24 53.9265 122.472 53.9265 121.525V111.239Z"
        fill="black"
      />
      <path
        d="M121.794 72.5792L122.197 72.3306V71.8571V53.8554V53.007H121.349H89.2041C84.1822 53.007 79.3074 54.7026 75.3695 57.8192L52.7483 75.7226C50.4274 77.5595 50.1763 80.9893 52.2048 83.1447C53.848 84.8906 56.4922 85.2407 58.5332 83.9826L76.9711 72.6167C79.3034 71.179 81.8958 70.2141 84.6005 69.777L94.634 68.1554C95.5215 68.0119 96.3265 68.6973 96.3265 69.5963C96.3265 70.2567 95.8832 70.8348 95.2454 71.006L80.1166 75.0684C78.4316 75.5209 77.2603 77.0483 77.2603 78.7929C77.2603 80.9228 78.9869 82.6494 81.1168 82.6494H98.9102C103.201 82.6494 107.408 81.4561 111.059 79.2029L121.794 72.5792Z"
        fill="#F4FFB5"
        stroke="black"
        strokeWidth="1.69695"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.226 74.3989L121.346 71.857V53.8553H107.658L117.226 74.3989Z"
        fill="#CDDC77"
      />
      <rect
        x="131.986"
        y="76.1523"
        width="12.0186"
        height="24.3626"
        rx="0.848475"
        transform="rotate(-180 131.986 76.1523)"
        fill="#B2D600"
        stroke="#141414"
        strokeWidth="1.69695"
      />
      <path
        d="M132.835 62.77H119.119V52.4833C119.119 51.5365 119.887 50.7689 120.834 50.7689H131.121C132.067 50.7689 132.835 51.5365 132.835 52.4833V62.77Z"
        fill="black"
      />
    </g>
    <path
      d="M40 1H106V-1H40V1ZM145 40V106H147V40H145ZM106 145H40V147H106V145ZM1 106V40H-1V106H1ZM40 145C18.4609 145 1 127.539 1 106H-1C-1 128.644 17.3563 147 40 147V145ZM145 106C145 127.539 127.539 145 106 145V147C128.644 147 147 128.644 147 106H145ZM106 1C127.539 1 145 18.4609 145 40H147C147 17.3563 128.644 -1 106 -1V1ZM40 -1C17.3563 -1 -1 17.3563 -1 40H1C1 18.4609 18.4609 1 40 1V-1Z"
      fill="#141414"
    />
    <defs>
      <clipPath id="clip0">
        <path
          d="M0 40C0 17.9086 17.9086 0 40 0H106C128.091 0 146 17.9086 146 40V106C146 128.091 128.091 146 106 146H40C17.9086 146 0 128.091 0 106V40Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
const surveyTemplateThreeSvg = (props) => (
  <svg
    width="146"
    height="146"
    viewBox="-2 0 160 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0 40C0 17.9086 17.9086 0 40 0H106C128.091 0 146 17.9086 146 40V106C146 128.091 128.091 146 106 146H40C17.9086 146 0 128.091 0 106V40Z"
        fill="#D4BBFF"
      />
      <circle
        cx="72.9991"
        cy="73.8951"
        r="57.773"
        fill="#141414"
        fillOpacity="0.08"
      />
      <path
        d="M78.7908 31.1724C65.4575 25.722 57.063 15.2634 51.985 4.36977C46.9742 -6.37958 45.2085 -17.5218 45.1152 -24.6012L137.363 -65.835C148.306 -36.6002 163.49 6.83429 173.693 42.7157C178.822 60.7524 182.686 76.8589 184.125 88.2893C184.846 94.0138 184.952 98.5175 184.33 101.494C184.018 102.984 183.535 104.036 182.907 104.676C182.301 105.293 181.526 105.563 180.507 105.422C169.115 103.853 159.553 99.9165 152.385 92.7227C145.217 85.5293 140.4 75.0339 138.584 60.262C137.676 52.8698 135.584 48.1507 132.363 45.0581C129.146 41.9703 124.867 40.5669 119.73 39.6655C117.019 39.1897 114.063 38.8511 110.873 38.4856C101.932 37.4613 91.1552 36.2267 78.7908 31.1724Z"
        stroke="#141414"
        strokeWidth="0.895706"
      />
      <path
        d="M105.868 16.3936C94.3184 15.7593 84.7467 10.8333 77.4659 4.91667C70.3665 -0.852517 65.4652 -7.5479 63.055 -12.0712L120.918 -51.9631C134.436 -31.1199 152.847 -0.243076 164.484 25.1631C170.343 37.9557 174.463 49.3138 175.399 57.2941C175.868 61.2941 175.526 64.3539 174.295 66.3232C173.096 68.2406 170.988 69.2224 167.629 68.9155C150.518 67.3525 142.477 54.8336 135.018 42.2835C134.734 41.8058 134.451 41.3279 134.168 40.8506C130.751 35.0845 127.382 29.3993 123.241 24.9709C118.74 20.1579 113.303 16.8019 105.868 16.3936Z"
        stroke="#141414"
        strokeWidth="0.895706"
      />
      <path
        d="M53.5002 125.816C64.2308 126.382 72.1088 131.941 77.6111 138.695C82.9868 145.294 86.0737 153.011 87.3046 158.24L41.5294 175.064C36.5546 171.554 29.1778 166.301 22.9658 161.772C19.8192 159.478 16.9741 157.372 14.892 155.773C13.8503 154.973 13.0037 154.303 12.407 153.802C12.108 153.55 11.8773 153.346 11.7179 153.191C11.6378 153.114 11.5814 153.054 11.5449 153.011C11.5313 152.995 11.5229 152.984 11.5182 152.977C11.4695 152.859 11.3397 152.598 11.1598 152.245C10.9539 151.84 10.6626 151.276 10.2952 150.57C9.5602 149.159 8.51852 147.176 7.24177 144.756C4.68817 139.916 1.19298 133.325 -2.67252 126.05C-10.3861 111.534 -19.575 94.2943 -25.7005 82.8159C-26.2801 77.0864 -26.3368 68.5998 -24.6501 61.6352C-23.8021 58.1336 -22.5229 55.0583 -20.6841 52.9083C-18.8603 50.776 -16.4834 49.5471 -13.3707 49.7112C-5.39066 50.132 0.641894 54.8119 4.90264 61.5352C9.16751 68.2649 11.6294 77.0065 12.449 85.4271C14.1107 102.499 25.9795 124.365 53.5002 125.816Z"
        stroke="#141414"
        strokeWidth="0.895706"
      />
      <path
        d="M54.4803 92.5719L54.8561 39.9385C54.8947 34.5396 59.5512 30.3336 64.9262 30.8429L104.515 34.5937C109.845 35.0987 113.621 40.0299 112.718 45.3073L103.864 97.0398C103.054 101.775 98.7379 105.09 93.9542 104.651L62.843 101.802C58.0807 101.365 54.4462 97.354 54.4803 92.5719Z"
        fill="#83B5AF"
        stroke="#141414"
        strokeWidth="1.79141"
      />
      <path
        d="M95.0603 32.7972L66.2818 30.0706C59.7006 29.4471 53.999 34.5969 53.9518 41.2074L53.5928 91.4897C53.551 97.345 58.0011 102.257 63.8322 102.791L92.796 105.444C95.1657 105.661 102.072 98.1062 104.007 97.0081L95.0603 32.7972Z"
        fill="black"
      />
      <path
        d="M10.578 67.9623L45.0723 123.99C48.3862 129.372 55.2707 131.319 60.9129 128.47L96.3403 110.579C102.008 107.716 104.52 100.976 102.109 95.1027L77.1972 34.4118C74.5099 27.8651 66.8226 24.9858 60.4958 28.1563L15.4863 50.7112C9.10637 53.9083 6.83666 61.8855 10.578 67.9623Z"
        fill="#C4FFF3"
        stroke="#141414"
        strokeWidth="1.79141"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.346 105.797C99.2925 107.444 97.7984 108.836 95.9368 109.776L60.5095 127.667C57.5843 129.144 54.2986 129.23 51.4341 128.145L64.4893 84.6279L100.346 105.797Z"
        fill="#90DCCC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.7516 58.9167C40.7055 59.0664 41.3575 59.961 41.2079 60.915L38.5021 78.1633C38.4215 78.6771 38.1163 79.1283 37.6695 79.3945C37.2227 79.6606 36.6806 79.714 36.1904 79.5402L28.9773 76.982C28.0673 76.6592 27.5911 75.6598 27.9139 74.7498C28.2367 73.8397 29.2361 73.3636 30.1462 73.6863L35.374 75.5404L37.7533 60.3731C37.903 59.4191 38.7976 58.7671 39.7516 58.9167Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.5243 69.6031C46.0804 68.7455 46.4157 67.6905 47.2732 67.2466L69.3704 55.8082C70.2279 55.3643 71.283 55.6996 71.7269 56.5572C72.1708 57.4147 71.8354 58.4697 70.9779 58.9136L48.8807 70.352C48.0232 70.7959 46.9682 70.4606 46.5243 69.6031Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.6645 86.3064C54.6184 86.456 55.2705 87.3507 55.1208 88.3046L52.415 105.553C52.3344 106.067 52.0292 106.518 51.5824 106.784C51.1356 107.05 50.5935 107.104 50.1033 106.93L42.8902 104.372C41.9802 104.049 41.504 103.049 41.8268 102.139C42.1496 101.229 43.149 100.753 44.0591 101.076L49.2869 102.93L51.6662 87.7627C51.8159 86.8088 52.7105 86.1567 53.6645 86.3064Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.4376 96.9927C59.9937 96.1352 60.329 95.0802 61.1866 94.6363L83.2837 83.1978C84.1413 82.754 85.1963 83.0893 85.6402 83.9468C86.0841 84.8044 85.7488 85.8594 84.8912 86.3033L62.7941 97.7417C61.9365 98.1856 60.8815 97.8503 60.4376 96.9927Z"
        fill="black"
      />
      <path
        d="M128.226 54.8972H100.657V121.226H128.226V54.8972Z"
        fill="#F4FFB5"
        stroke="#141414"
        strokeWidth="1.79141"
      />
      <rect
        x="-0.895706"
        y="0.895706"
        width="5.57841"
        height="5.57841"
        transform="matrix(-1 0 0 1 116.334 63.9055)"
        fill="#E6F0EF"
        stroke="#141414"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="-0.895706"
        y="0.895706"
        width="5.57841"
        height="5.57841"
        transform="matrix(-1 0 0 1 116.334 79.4629)"
        fill="#E6F0EF"
        stroke="#141414"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="-0.895706"
        y="0.895706"
        width="5.57841"
        height="5.57841"
        transform="matrix(-1 0 0 1 116.334 95.0227)"
        fill="#E6F0EF"
        stroke="#141414"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.948 83.9863C104.948 83.318 104.406 82.7763 103.738 82.7763H64.0035C63.3353 82.7763 62.7936 83.318 62.7936 83.9863V121.382H104.948V83.9863Z"
        fill="white"
        stroke="#141414"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="-0.895706"
        y="0.895706"
        width="5.57841"
        height="5.57841"
        transform="matrix(-1 0 0 1 95.043 89.2908)"
        fill="#E6F0EF"
        stroke="#141414"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="-0.895706"
        y="0.895706"
        width="5.57841"
        height="5.57841"
        transform="matrix(-1 0 0 1 76.4819 89.2908)"
        fill="#E6F0EF"
        stroke="#141414"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.5686 101.922H79.1689V121.353H88.5686V101.922Z"
        fill="#E6F0EF"
        stroke="#141414"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M40 1H106V-1H40V1ZM145 40V106H147V40H145ZM106 145H40V147H106V145ZM1 106V40H-1V106H1ZM40 145C18.4609 145 1 127.539 1 106H-1C-1 128.644 17.3563 147 40 147V145ZM145 106C145 127.539 127.539 145 106 145V147C128.644 147 147 128.644 147 106H145ZM106 1C127.539 1 145 18.4609 145 40H147C147 17.3563 128.644 -1 106 -1V1ZM40 -1C17.3563 -1 -1 17.3563 -1 40H1C1 18.4609 18.4609 1 40 1V-1Z"
      fill="#141414"
    />
    <defs>
      <clipPath id="clip0">
        <path
          d="M0 40C0 17.9086 17.9086 0 40 0H106C128.091 0 146 17.9086 146 40V106C146 128.091 128.091 146 106 146H40C17.9086 146 0 128.091 0 106V40Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

const surveyTemplateFourSvg = (props) => (
  <svg
    width="146"
    height="146"
    viewBox="-2 0 160 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0 40C0 17.9086 17.9086 0 40 0H106C128.091 0 146 17.9086 146 40V106C146 128.091 128.091 146 106 146H40C17.9086 146 0 128.091 0 106V40Z"
        fill="#F8FFD3"
      />
      <path
        d="M63.1472 29.1099C38.0674 23.0191 30.3046 -7.76335 29.5582 -22.3932L164.362 -25.0803C166.303 17.4657 167.497 103.812 156.748 108.828C143.313 115.098 120.024 112.858 113.307 79.2694C106.589 45.6804 94.4969 36.7234 63.1472 29.1099Z"
        stroke="black"
        strokeWidth="0.895706"
      />
      <path
        d="M1.34351 15.6755C3.43349 25.2297 16.2122 44.9651 50.6073 47.4731C93.6012 50.6081 103.454 64.4915 103.902 80.6142C104.35 96.7369 103.902 130.774 145.552 129.43"
        stroke="black"
        strokeWidth="0.895706"
      />
      <circle
        cx="71.9683"
        cy="73.7591"
        r="52.2621"
        fill="#141414"
        fillOpacity="0.08"
      />
      <circle
        cx="-1.48032"
        cy="158.851"
        r="51.8142"
        stroke="#141414"
        strokeWidth="0.895706"
      />
      <path
        d="M46.9892 81.2322L39.153 39.1769C38.2191 34.1646 41.7808 29.4288 46.8555 28.9355L78.3799 25.8714C83.4124 25.3822 87.7983 29.2759 87.9088 34.3309L88.8413 76.9798C88.9405 81.5151 85.5345 85.3637 81.0208 85.8166L56.4 88.2871C51.9065 88.738 47.8165 85.6719 46.9892 81.2322Z"
        fill="white"
        stroke="#141414"
        strokeWidth="1.79141"
      />
      <path
        d="M71.2449 25.6659L46.767 28.0452C41.1693 28.5893 37.2405 33.8131 38.2707 39.3421L46.1068 81.3974C47.0194 86.2947 51.5309 89.6769 56.4877 89.1795L81.1084 86.709C83.1228 86.5068 84.9372 85.6916 86.3764 84.4612L71.2449 25.6659Z"
        fill="black"
      />
      <path
        d="M9.98421 69.3603L39.3305 117.026C42.1863 121.664 48.119 123.342 52.9812 120.887L83.1213 105.666C88.0053 103.199 90.1705 97.391 88.0928 92.3293L66.8986 40.6961C64.5828 35.0544 57.9583 32.5732 52.5061 35.3054L14.214 54.4942C8.71604 57.2493 6.76013 64.1236 9.98421 69.3603Z"
        fill="#558A84"
        stroke="#141414"
        strokeWidth="1.79141"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.918 61.594C35.7296 61.7213 36.2843 62.4824 36.157 63.294L33.855 77.9681C33.7864 78.4052 33.5268 78.7891 33.1467 79.0155C32.7665 79.242 32.3053 79.2874 31.8883 79.1395L25.7517 76.9631C24.9775 76.6885 24.5724 75.8383 24.847 75.064C25.1216 74.2898 25.9719 73.8847 26.7461 74.1593L31.1937 75.7367L33.218 62.8329C33.3453 62.0214 34.1064 61.4666 34.918 61.594Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.6797 70.6855C40.302 69.9559 40.5873 69.0583 41.3169 68.6807L60.1162 58.9494C60.8458 58.5717 61.7434 58.857 62.121 59.5866C62.4987 60.3161 62.2134 61.2137 61.4838 61.5913L42.6845 71.3227C41.9549 71.7003 41.0573 71.415 40.6797 70.6855Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.7549 84.8967C47.5665 85.024 48.1212 85.7851 47.9938 86.5967L45.6918 101.271C45.6233 101.708 45.3637 102.092 44.9835 102.318C44.6034 102.545 44.1422 102.59 43.7252 102.442L37.5886 100.266C36.8143 99.9913 36.4093 99.141 36.6839 98.3668C36.9585 97.5925 37.8087 97.1874 38.583 97.462L43.0306 99.0394L45.0548 86.1357C45.1822 85.3241 45.9433 84.7694 46.7549 84.8967Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.5161 93.988C52.1385 93.2584 52.4237 92.3608 53.1533 91.9832L71.9527 82.2519C72.6822 81.8742 73.5798 82.1595 73.9574 82.889C74.3351 83.6186 74.0498 84.5162 73.3202 84.8938L54.5209 94.6251C53.7913 95.0028 52.8938 94.7175 52.5161 93.988Z"
        fill="white"
      />
      <path
        d="M110.926 74.0022H109.434C96.1615 74.0022 85.4024 84.7614 85.4024 98.0335C85.4024 100.589 87.4741 102.661 90.0296 102.661H130.33C132.886 102.661 134.957 100.589 134.957 98.0335C134.957 84.7614 124.198 74.0022 110.926 74.0022Z"
        fill="#C4FFF3"
        stroke="black"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.872 101.766C122.854 89.0042 112.503 78.6648 99.7368 78.6648H98.2442C97.725 78.6648 97.2099 78.6819 96.6992 78.7156C90.4324 82.8548 86.2981 89.9617 86.2981 98.0341C86.2981 100.095 87.9688 101.766 90.0296 101.766H122.872Z"
        fill="#84E0CD"
      />
      <circle
        cx="110.18"
        cy="58.4803"
        r="8.95568"
        fill="#C4FFF3"
        stroke="black"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.9685 75.8301H85.0292C68.427 75.8301 54.9682 89.2888 54.9682 105.891C54.9682 108.569 57.139 110.74 59.8167 110.74H112.181C114.859 110.74 117.03 108.569 117.03 105.891C117.03 89.2888 103.571 75.8301 86.9685 75.8301Z"
        fill="#C4FFF3"
        stroke="black"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="85.9998"
        cy="54.4966"
        r="11.6365"
        fill="#C4FFF3"
        stroke="black"
        strokeWidth="1.79141"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M40 1H106V-1H40V1ZM145 40V106H147V40H145ZM106 145H40V147H106V145ZM1 106V40H-1V106H1ZM40 145C18.4609 145 1 127.539 1 106H-1C-1 128.644 17.3563 147 40 147V145ZM145 106C145 127.539 127.539 145 106 145V147C128.644 147 147 128.644 147 106H145ZM106 1C127.539 1 145 18.4609 145 40H147C147 17.3563 128.644 -1 106 -1V1ZM40 -1C17.3563 -1 -1 17.3563 -1 40H1C1 18.4609 18.4609 1 40 1V-1Z"
      fill="#141414"
    />
    <defs>
      <clipPath id="clip0">
        <path
          d="M0 40C0 17.9086 17.9086 0 40 0H106C128.091 0 146 17.9086 146 40V106C146 128.091 128.091 146 106 146H40C17.9086 146 0 128.091 0 106V40Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

const gridSvg = (color) => (
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6H5V0H0V6ZM0 13H5V7H0V13ZM6 13H11V7H6V13ZM12 13H17V7H12V13ZM6 6H11V0H6V6ZM12 0V6H17V0H12Z"
      fill={color}
    />
  </svg>
);
const multiCheckSvg = (color = "#fff") => (
  <svg
    width="18"
    height="11"
    viewBox="0 0 18 11"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5001 1.24988L12.4426 0.192383L7.68762 4.94738L8.74512 6.00488L13.5001 1.24988ZM16.6801 0.192383L8.74512 8.12738L5.61012 4.99988L4.55262 6.05738L8.74512 10.2499L17.7451 1.24988L16.6801 0.192383ZM0.307617 6.05738L4.50012 10.2499L5.55762 9.19238L1.37262 4.99988L0.307617 6.05738Z"
      fill={color}
    />
  </svg>
);

const boolSvg = (color = "#fff") => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.245 4.94739L6 0.702393L1.755 4.94739C-0.585 7.28739 -0.585 11.0899 1.755 13.4299C2.925 14.5999 4.4625 15.1849 6 15.1849C7.5375 15.1849 9.075 14.5999 10.245 13.4299C12.585 11.0899 12.585 7.28739 10.245 4.94739ZM6 13.6924C4.8 13.6924 3.6675 13.2274 2.82 12.3724C1.965 11.5174 1.5 10.3924 1.5 9.19239C1.5 7.99239 1.965 6.85989 2.82 6.01239L6 2.82489V13.6924Z"
      fill={color}
    />
  </svg>
);
const filterSvg = (color = "#141414") => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z"
      fill="#141414"
      fillOpacity="0.54"
    />
  </svg>
);

const textSvg = (color = "#fff") => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 9.75V11.25H11.25V9.75H0.75ZM4.125 6.6H7.875L8.55 8.25H10.125L6.5625 0H5.4375L1.875 8.25H3.45L4.125 6.6ZM6 1.485L7.4025 5.25H4.5975L6 1.485Z"
      fill="white"
    />
  </svg>
);
const reloadSvg = (color = "rgba(20, 20, 20, 0.54)") => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6498 2.35C12.1998 0.9 10.2098 0 7.99977 0C3.57977 0 0.00976562 3.58 0.00976562 8C0.00976562 12.42 3.57977 16 7.99977 16C11.7298 16 14.8398 13.45 15.7298 10H13.6498C12.8298 12.33 10.6098 14 7.99977 14C4.68977 14 1.99977 11.31 1.99977 8C1.99977 4.69 4.68977 2 7.99977 2C9.65977 2 11.1398 2.69 12.2198 3.78L8.99977 7H15.9998V0L13.6498 2.35Z"
      fill="#141414"
      fillOpacity="0.54"
    />
  </svg>
);
const settingSvg = (color = "#141414") => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1401 10.94C17.1801 10.64 17.2001 10.33 17.2001 9.99999C17.2001 9.67999 17.1801 9.35999 17.1301 9.05999L19.1601 7.47999C19.3401 7.33999 19.3901 7.06999 19.2801 6.86999L17.3601 3.54999C17.2401 3.32999 16.9901 3.25999 16.7701 3.32999L14.3801 4.28999C13.8801 3.90999 13.3501 3.58999 12.7601 3.34999L12.4001 0.809994C12.3601 0.569994 12.1601 0.399994 11.9201 0.399994H8.08011C7.84011 0.399994 7.65011 0.569994 7.61011 0.809994L7.25011 3.34999C6.66011 3.58999 6.12011 3.91999 5.63011 4.28999L3.24011 3.32999C3.02011 3.24999 2.77011 3.32999 2.65011 3.54999L0.74011 6.86999C0.62011 7.07999 0.66011 7.33999 0.86011 7.47999L2.89011 9.05999C2.84011 9.35999 2.80011 9.68999 2.80011 9.99999C2.80011 10.31 2.82011 10.64 2.87011 10.94L0.84011 12.52C0.66011 12.66 0.61011 12.93 0.72011 13.13L2.64011 16.45C2.76011 16.67 3.01011 16.74 3.23011 16.67L5.62011 15.71C6.12011 16.09 6.65011 16.41 7.24011 16.65L7.60011 19.19C7.65011 19.43 7.84011 19.6 8.08011 19.6H11.9201C12.1601 19.6 12.3601 19.43 12.3901 19.19L12.7501 16.65C13.3401 16.41 13.8801 16.09 14.3701 15.71L16.7601 16.67C16.9801 16.75 17.2301 16.67 17.3501 16.45L19.2701 13.13C19.3901 12.91 19.3401 12.66 19.1501 12.52L17.1401 10.94ZM10.0001 13.6C8.02011 13.6 6.40011 11.98 6.40011 9.99999C6.40011 8.01999 8.02011 6.39999 10.0001 6.39999C11.9801 6.39999 13.6001 8.01999 13.6001 9.99999C13.6001 11.98 11.9801 13.6 10.0001 13.6Z"
      fill="#141414"
      fillOpacity="0.54"
    />
  </svg>
);
const navigateSvg = () => (
  <svg
    width="4"
    height="16"
    viewBox="0 0 4 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
      fill="#141414"
      fillOpacity="0.54"
    />
  </svg>
);

const duplicateSvg = () => (
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0H2C0.9 0 0 0.9 0 2V16H2V2H14V0ZM13 4L19 10V20C19 21.1 18.1 22 17 22H5.99C4.89 22 4 21.1 4 20L4.01 6C4.01 4.9 4.9 4 6 4H13ZM12 11H17.5L12 5.5V11Z"
      fill="#141414"
      fillOpacity="0.54"
    />
  </svg>
);

const deleteSvg = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
      fill="#F44336"
    />
  </svg>
);

const seedSvg = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 26C17.6569 26 19 24.6569 19 23C19 21.3431 17.6569 20 16 20C14.3431 20 13 21.3431 13 23C13 24.6569 14.3431 26 16 26Z"
      fill="#00B999"
    />
    <path
      d="M20 18C21.6569 18 23 16.6569 23 15C23 13.3431 21.6569 12 20 12C18.3431 12 17 13.3431 17 15C17 16.6569 18.3431 18 20 18Z"
      fill="#00B999"
    />
    <path
      d="M25.5996 29.6001C27.2565 29.6001 28.5996 28.257 28.5996 26.6001C28.5996 24.9432 27.2565 23.6001 25.5996 23.6001C23.9428 23.6001 22.5996 24.9432 22.5996 26.6001C22.5996 28.257 23.9428 29.6001 25.5996 29.6001Z"
      fill="#00B999"
    />
  </svg>
);

const eyeSvg = (color = "#00B999") => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
      fill={color}
    />
  </svg>
);

const clockSvg = (color = "#00B999") => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0H6V2H12V0ZM8 13H10V7H8V13ZM16.03 6.39L17.45 4.97C17.02 4.46 16.55 3.98 16.04 3.56L14.62 4.98C13.07 3.74 11.12 3 9 3C4.03 3 0 7.03 0 12C0 16.97 4.02 21 9 21C13.98 21 18 16.97 18 12C18 9.88 17.26 7.93 16.03 6.39ZM9 19C5.13 19 2 15.87 2 12C2 8.13 5.13 5 9 5C12.87 5 16 8.13 16 12C16 15.87 12.87 19 9 19Z"
      fill={color}
    />
  </svg>
);
const growthSvg = (color = "white") => (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 13.49L7.5 7.48001L11.5 11.48L20 1.92001L18.59 0.51001L11.5 8.48001L7.5 4.48001L0 11.99L1.5 13.49Z"
      fill="#00B999"
    />
  </svg>
);

const starSvg = (color = "#141414") => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 7.24L12.81 6.62L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27L16.18 19L14.55 11.97L20 7.24ZM10 13.4L6.24 15.67L7.24 11.39L3.92 8.51L8.3 8.13L10 4.1L11.71 8.14L16.09 8.52L12.77 11.4L13.77 15.68L10 13.4Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);
const starFilledSvg = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 15.27L16.18 19L14.54 11.97L20 7.24L12.81 6.63L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27Z"
      fill="#FFB547"
    />
  </svg>
);

const expandSvg = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 9H0V14H5V12H2V9ZM0 5H2V2H5V0H0V5ZM12 12H9V14H14V9H12V12ZM9 0V2H12V5H14V0H9Z"
      fill="#141414"
      fillOpacity="0.54"
    />
  </svg>
);

const shrinkSvg = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 11H3V14H5V9H0V11ZM3 3H0V5H5V0H3V3ZM9 14H11V11H14V9H9V14ZM11 3V0H9V5H14V3H11Z"
      fill="#141414"
      fillOpacity="0.54"
    />
  </svg>
);

const attachFileSvg = (props) => (
  <svg
    width="11"
    height="22"
    viewBox="0 0 11 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 5V16.5C9.5 18.71 7.71 20.5 5.5 20.5C3.29 20.5 1.5 18.71 1.5 16.5V4C1.5 2.62 2.62 1.5 4 1.5C5.38 1.5 6.5 2.62 6.5 4V14.5C6.5 15.05 6.05 15.5 5.5 15.5C4.95 15.5 4.5 15.05 4.5 14.5V5H3V14.5C3 15.88 4.12 17 5.5 17C6.88 17 8 15.88 8 14.5V4C8 1.79 6.21 0 4 0C1.79 0 0 1.79 0 4V16.5C0 19.54 2.46 22 5.5 22C8.54 22 11 19.54 11 16.5V5H9.5Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const atMentionSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20H15V18H10C5.66 18 2 14.34 2 10C2 5.66 5.66 2 10 2C14.34 2 18 5.66 18 10V11.43C18 12.22 17.29 13 16.5 13C15.71 13 15 12.22 15 11.43V10C15 7.24 12.76 5 10 5C7.24 5 5 7.24 5 10C5 12.76 7.24 15 10 15C11.38 15 12.64 14.44 13.54 13.53C14.19 14.42 15.31 15 16.5 15C18.47 15 20 13.4 20 11.43V10C20 4.48 15.52 0 10 0ZM10 13C8.34 13 7 11.66 7 10C7 8.34 8.34 7 10 7C11.66 7 13 8.34 13 10C13 11.66 11.66 13 10 13Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const successCheckSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM14.59 5.58L8 12.17L5.41 9.59L4 11L8 15L16 7L14.59 5.58Z"
      fill="#4CAF50"
    />
  </svg>
);

const previousSvg = (color = "#141414") => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"
      fill={color}
      fillOpacity="0.54"
    />
  </svg>
);
const locationMarkerSvg = (color = "#141414") => (
  <svg width="14" height="20" viewBox="0 0 14 20">
    <path
      d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"
      fill={color}
    />
  </svg>
);

const emojiSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM13.5 9C14.33 9 15 8.33 15 7.5C15 6.67 14.33 6 13.5 6C12.67 6 12 6.67 12 7.5C12 8.33 12.67 9 13.5 9ZM6.5 9C7.33 9 8 8.33 8 7.5C8 6.67 7.33 6 6.5 6C5.67 6 5 6.67 5 7.5C5 8.33 5.67 9 6.5 9ZM10 15.5C12.33 15.5 14.31 14.04 15.11 12H4.89C5.69 14.04 7.67 15.5 10 15.5Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const attachBoxFileSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 16V2C18 0.9 17.1 0 16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16ZM5.5 10.5L8 13.51L11.5 9L16 15H2L5.5 10.5Z"
      fill="#A080E1"
    />
  </svg>
);

const downloadFileSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 9V16H2V9H0V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V9H16ZM10 9.67L12.59 7.09L14 8.5L9 13.5L4 8.5L5.41 7.09L8 9.67V0H10V9.67Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const eventSvg = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 11H9V16H14V11ZM13 0V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2H15V0H13ZM16 18H2V7H16V18Z"
      fill="#A4A4A4"
    />
  </svg>
);

const calendarSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 6.33333H2.66667V7.66667H4V6.33333ZM6.66667 6.33333H5.33333V7.66667H6.66667V6.33333ZM9.33333 6.33333H8V7.66667H9.33333V6.33333ZM10.6667 1.66667H10V0.333332H8.66667V1.66667H3.33333V0.333332H2V1.66667H1.33333C0.593333 1.66667 0.00666666 2.26667 0.00666666 3L0 12.3333C0 13.0667 0.593333 13.6667 1.33333 13.6667H10.6667C11.4 13.6667 12 13.0667 12 12.3333V3C12 2.26667 11.4 1.66667 10.6667 1.66667ZM10.6667 12.3333H1.33333V5H10.6667V12.3333Z"
      fill="#141414"
      fillOpacity="0.54"
    />
  </svg>
);

const postSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.99 2C19.99 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H16L20 20L19.99 2ZM15 9H11V13H9V9H5V7H9V3H11V7H15V9Z"
      fill="#A4A4A4"
    />
  </svg>
);
const justifySvg = (color = "#141414") => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.9999 4.80889C3.63941 4.80889 3.3999 4.53944 3.3999 4.20889C3.3999 3.87833 3.63941 3.60889 3.9999 3.60889H19.9999C20.3604 3.60889 20.5999 3.87833 20.5999 4.20889C20.5999 4.53944 20.3604 4.80889 19.9999 4.80889H3.9999ZM3.9999 8.80889C3.63941 8.80889 3.3999 8.53944 3.3999 8.20889C3.3999 7.87833 3.63941 7.60889 3.9999 7.60889H19.9999C20.3604 7.60889 20.5999 7.87833 20.5999 8.20889C20.5999 8.53944 20.3604 8.80889 19.9999 8.80889H3.9999ZM3.9999 12.8089C3.63941 12.8089 3.3999 12.5394 3.3999 12.2089C3.3999 11.8783 3.63941 11.6089 3.9999 11.6089H19.9999C20.3604 11.6089 20.5999 11.8783 20.5999 12.2089C20.5999 12.5394 20.3604 12.8089 19.9999 12.8089H3.9999ZM3.9999 16.8089C3.63941 16.8089 3.3999 16.5394 3.3999 16.2089C3.3999 15.8783 3.63941 15.6089 3.9999 15.6089H19.9999C20.3604 15.6089 20.5999 15.8783 20.5999 16.2089C20.5999 16.5394 20.3604 16.8089 19.9999 16.8089H3.9999ZM3.9999 20.8089C3.1999 20.8089 3.1999 19.6089 3.9999 19.6089H19.9999C20.7999 19.6089 20.7999 20.8089 19.9999 20.8089H3.9999Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const justifyCenterSvg = (color = "#141414") => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.62791 5.18605C3.25065 5.18605 3 4.91973 3 4.59302C3 4.26631 3.25065 4 3.62791 4H20.3721C20.7494 4 21 4.26631 21 4.59302C21 4.91973 20.7494 5.18605 20.3721 5.18605H3.62791ZM3.62791 9.13953C3.25065 9.13953 3 8.87322 3 8.54651C3 8.2198 3.25065 7.95349 3.62791 7.95349H20.3721C20.7494 7.95349 21 8.2198 21 8.54651C21 8.87322 20.7494 9.13953 20.3721 9.13953H3.62791ZM3.62791 13.093C3.25065 13.093 3 12.8267 3 12.5C3 12.1733 3.25065 11.907 3.62791 11.907H20.3721C20.7494 11.907 21 12.1733 21 12.5C21 12.8267 20.7494 13.093 20.3721 13.093H3.62791ZM3.62791 17.0465C3.25065 17.0465 3 16.7802 3 16.4535C3 16.1268 3.25065 15.8605 3.62791 15.8605H20.3721C20.7494 15.8605 21 16.1268 21 16.4535C21 16.7802 20.7494 17.0465 20.3721 17.0465H3.62791ZM7.81395 21C7.43669 21 7.18605 20.7337 7.18605 20.407C7.18605 20.0803 7.43669 19.814 7.81395 19.814H16.186C16.5633 19.814 16.814 20.0803 16.814 20.407C16.814 20.7337 16.5633 21 16.186 21H7.81395Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const justifyLeftSvg = (color = "#141414") => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.814 4.79081H4.18897C3.83692 4.79081 3.60303 4.52795 3.60303 4.20547C3.60303 3.88298 3.83692 3.62012 4.18897 3.62012H19.814C20.1661 3.62012 20.4 3.88298 20.4 4.20547C20.4 4.52795 20.1661 4.79081 19.814 4.79081ZM19.814 8.69313H4.18897C3.83692 8.69313 3.60303 8.43027 3.60303 8.10779C3.60303 7.78531 3.83692 7.52244 4.18897 7.52244H19.814C20.1661 7.52244 20.4 7.78531 20.4 8.10779C20.4 8.43027 20.1661 8.69313 19.814 8.69313ZM19.814 12.5955H4.18897C3.83692 12.5955 3.60303 12.3326 3.60303 12.0101C3.60303 11.6876 3.83692 11.4248 4.18897 11.4248H19.814C20.1661 11.4248 20.4 11.6876 20.4 12.0101C20.4 12.3326 20.1661 12.5955 19.814 12.5955ZM19.814 16.4978H4.18897C3.83692 16.4978 3.60303 16.2349 3.60303 15.9124C3.60303 15.5899 3.83692 15.3271 4.18897 15.3271H19.814C20.1661 15.3271 20.4 15.5899 20.4 15.9124C20.4 16.2349 20.1661 16.4978 19.814 16.4978ZM12.0015 20.4001H4.18897C3.83692 20.4001 3.60303 20.1372 3.60303 19.8148C3.60303 19.4923 3.83692 19.2294 4.18897 19.2294H12.0015C12.3535 19.2294 12.5874 19.4923 12.5874 19.8148C12.5874 20.1372 12.3535 20.4001 12.0015 20.4001Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const rightAlignSvg = (color = "#141414") => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.0001 1.77219C8.64799 1.77219 8.41405 1.50901 8.41405 1.18614C8.41405 0.863278 8.64799 0.600098 9.0001 0.600098H16.8141C17.1662 0.600098 17.4001 0.863278 17.4001 1.18614C17.4001 1.50901 17.1662 1.77219 16.8141 1.77219H9.0001ZM1.18614 5.67917C0.834036 5.67917 0.600098 5.41599 0.600098 5.09312C0.600098 4.77026 0.834036 4.50707 1.18614 4.50707H16.8141C17.1662 4.50707 17.4001 4.77026 17.4001 5.09312C17.4001 5.41599 17.1662 5.67917 16.8141 5.67917H1.18614ZM16.8141 9.58614H9.0001C8.64799 9.58614 8.41405 9.32296 8.41405 9.0001C8.41405 8.67723 8.64799 8.41405 9.0001 8.41405H16.8141C17.1662 8.41405 17.4001 8.67723 17.4001 9.0001C17.4001 9.32296 17.1662 9.58614 16.8141 9.58614ZM1.18614 13.4931C0.834036 13.4931 0.600098 13.2299 0.600098 12.9071C0.600098 12.5842 0.834036 12.321 1.18614 12.321H16.8141C17.1662 12.321 17.4001 12.5842 17.4001 12.9071C17.4001 13.2299 17.1662 13.4931 16.8141 13.4931H1.18614ZM9.0001 17.4001C8.64799 17.4001 8.41405 17.1369 8.41405 16.8141C8.41405 16.4912 8.64799 16.228 9.0001 16.228H16.8141C17.1662 16.228 17.4001 16.4912 17.4001 16.8141C17.4001 17.1369 17.1662 17.4001 16.8141 17.4001H9.0001Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const centerAlignSvg = (color = "#141414") => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9072 2.78174H6.09349C5.74139 2.78174 5.50746 2.51872 5.50746 2.19604C5.50746 1.87337 5.74139 1.61035 6.09349 1.61035H13.9072C14.2593 1.61035 14.4933 1.87337 14.4933 2.19604C14.4933 2.51872 14.2593 2.78174 13.9072 2.78174ZM17.8141 6.68636H2.18662C1.83452 6.68636 1.60059 6.42334 1.60059 6.10067C1.60059 5.77799 1.83452 5.51497 2.18662 5.51497H17.8141C18.1662 5.51497 18.4001 5.77799 18.4001 6.10067C18.4001 6.42334 18.1662 6.68636 17.8141 6.68636ZM6.09349 10.591C5.74139 10.591 5.50746 10.328 5.50746 10.0053C5.50746 9.68261 5.74139 9.41959 6.09349 9.41959H13.9072C14.2593 9.41959 14.4933 9.68261 14.4933 10.0053C14.4933 10.328 14.2593 10.591 13.9072 10.591H6.09349ZM17.8141 14.4956H2.18662C1.83452 14.4956 1.60059 14.2326 1.60059 13.9099C1.60059 13.5872 1.83452 13.3242 2.18662 13.3242H17.8141C18.1662 13.3242 18.4001 13.5872 18.4001 13.9099C18.4001 14.2326 18.1662 14.4956 17.8141 14.4956ZM13.9072 18.4002H6.09349C5.74139 18.4002 5.50746 18.1372 5.50746 17.8145C5.50746 17.4919 5.74139 17.2288 6.09349 17.2288H13.9072C14.2593 17.2288 14.4933 17.4919 14.4933 17.8145C14.4933 18.1372 14.2593 18.4002 13.9072 18.4002Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const leftAlignSvg = (color = "#141414") => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0001 2.77219H2.18614C1.83404 2.77219 1.6001 2.50901 1.6001 2.18614C1.6001 1.86328 1.83404 1.6001 2.18614 1.6001H10.0001C10.3522 1.6001 10.5861 1.86328 10.5861 2.18614C10.5861 2.50901 10.3522 2.77219 10.0001 2.77219ZM17.8141 6.67917H2.18614C1.83404 6.67917 1.6001 6.41599 1.6001 6.09312C1.6001 5.77026 1.83404 5.50707 2.18614 5.50707H17.8141C18.1662 5.50707 18.4001 5.77026 18.4001 6.09312C18.4001 6.41599 18.1662 6.67917 17.8141 6.67917ZM2.18614 10.5861C1.83404 10.5861 1.6001 10.323 1.6001 10.0001C1.6001 9.67723 1.83404 9.41405 2.18614 9.41405H10.0001C10.3522 9.41405 10.5861 9.67723 10.5861 10.0001C10.5861 10.323 10.3522 10.5861 10.0001 10.5861H2.18614ZM17.8141 14.4931H2.18614C1.83404 14.4931 1.6001 14.2299 1.6001 13.9071C1.6001 13.5842 1.83404 13.321 2.18614 13.321H17.8141C18.1662 13.321 18.4001 13.5842 18.4001 13.9071C18.4001 14.2299 18.1662 14.4931 17.8141 14.4931ZM10.0001 18.4001H2.18614C1.83404 18.4001 1.6001 18.1369 1.6001 17.8141C1.6001 17.4912 1.83404 17.228 2.18614 17.228H10.0001C10.3522 17.228 10.5861 17.4912 10.5861 17.8141C10.5861 18.1369 10.3522 18.4001 10.0001 18.4001Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const fillSvg = (color = "#141414") => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.96399 3.86942L15.797 8.29822C15.9917 8.40834 16.0583 8.65682 15.9447 8.84956L11.5885 16.2386C10.8205 17.5412 9.12491 17.9861 7.79838 17.2361L2.99272 14.519C1.66131 13.7662 1.2037 12.0937 1.97454 10.7862L2.49971 9.89539C3.59585 10.7114 5.12517 11.2002 6.79429 11.2002C8.21327 11.2002 9.56079 10.863 10.6148 10.2342C11.184 9.8946 11.3701 9.15795 11.0305 8.5888C10.691 8.01965 9.95434 7.83352 9.38519 8.17308C8.71792 8.57116 7.79317 8.80024 6.79429 8.80024C5.47516 8.80024 4.35512 8.37582 3.73086 7.80709L5.40615 4.96546C5.83018 4.86028 6.29815 4.80024 6.79429 4.80024C7.36449 4.80024 7.8418 4.40256 7.96399 3.86942Z"
      fill="#141414"
      fillOpacity="0.38"
    />
    <path
      d="M6.79429 3.20024C7.01521 3.20024 7.19429 3.37933 7.19429 3.60024C7.19429 3.82116 7.01521 4.00024 6.79429 4.00024C4.34133 4.00024 2.4 5.29601 2.4 6.80024C2.4 8.30448 4.34133 9.60024 6.79429 9.60024C7.93751 9.60024 9.00173 9.3334 9.79506 8.8601C9.98478 8.74692 10.2303 8.80896 10.3435 8.99868C10.4567 9.18839 10.3947 9.43394 10.2049 9.54713C9.28171 10.0979 8.07598 10.4002 6.79429 10.4002C3.95162 10.4002 1.6 8.83063 1.6 6.80024C1.6 4.76986 3.95162 3.20024 6.79429 3.20024Z"
      fill="#141414"
      fillOpacity="0.38"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2357 4.80024H15.6C17.1464 4.80024 18.4 6.05385 18.4 7.60024V12.0007C18.4 12.8841 17.6839 13.6002 16.8005 13.6002C15.9171 13.6002 15.2009 12.8842 15.2007 12.0008L15.2006 11.6869L16.6338 9.25585C16.9747 8.67761 16.775 7.9322 16.1907 7.60182L11.2357 4.80024Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const editorAttachImageSvg = (color = "#141414") => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.8 6C6.8 6.44183 7.15817 6.8 7.6 6.8C8.04183 6.8 8.4 6.44183 8.4 6C8.4 5.55817 8.04183 5.2 7.6 5.2C7.15817 5.2 6.8 5.55817 6.8 6Z"
      fill="#141414"
      fillOpacity="0.38"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 15.6022V4.8C2 3.2536 3.2536 2 4.8 2H16C17.5464 2 18.8 3.2536 18.8 4.8V12.6917L14.2125 8.50234C14.0474 8.35411 13.7932 8.36863 13.646 8.53469L10.2498 12.3664L8.19023 10.1303C8.03786 9.96341 7.77761 9.9558 7.61574 10.1135L2 15.6022ZM7.6 7.6C8.48366 7.6 9.2 6.88366 9.2 6C9.2 5.11634 8.48366 4.4 7.6 4.4C6.71634 4.4 6 5.11634 6 6C6 6.88366 6.71634 7.6 7.6 7.6Z"
      fill="#141414"
      fillOpacity="0.38"
    />
    <path
      d="M7.87803 10.9748L2.0752 16.6473C2.36732 17.8816 3.47641 18.8 4.8 18.8H15.0876L7.87803 10.9748Z"
      fill="#141414"
      fillOpacity="0.38"
    />
    <path
      d="M10.7946 12.9579L16.1707 18.7949C17.6376 18.7067 18.8 17.4891 18.8 16V13.7749L13.9777 9.36656L10.7946 12.9579Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const linkSvg = (color = "#141414") => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1532 3.98492L16.0155 2.84719C15.1528 1.98453 13.7542 1.98453 12.8915 2.84719L10.0472 5.6915C9.18453 6.55416 9.18453 7.95281 10.0472 8.81548L10.199 8.96728L8.96728 10.199L8.81548 10.0472C7.95281 9.18453 6.55416 9.18453 5.6915 10.0472L2.84719 12.8915C1.98453 13.7542 1.98453 15.1528 2.84719 16.0155L3.98492 17.1532C4.84758 18.0159 6.24623 18.0159 7.10889 17.1532L9.9532 14.3089C10.8159 13.4462 10.8159 12.0476 9.9532 11.1849L9.81581 11.0475L11.0475 9.81581L11.1849 9.9532C12.0476 10.8159 13.4462 10.8159 14.3089 9.9532L17.1532 7.10889C18.0159 6.24623 18.0159 4.84758 17.1532 3.98492ZM11.8961 8.96728L12.0334 9.10467C12.4275 9.4987 13.0663 9.4987 13.4604 9.10467L16.3047 6.26036C16.6987 5.86633 16.6987 5.22748 16.3047 4.83344L15.1669 3.69572C14.7729 3.30169 14.1341 3.30169 13.74 3.69572L10.8957 6.54003C10.5017 6.93406 10.5017 7.57291 10.8957 7.96695L11.0475 8.11876L11.9831 7.18314C12.238 6.92824 12.5979 6.94941 12.8317 7.18314C13.0654 7.41688 13.0866 7.77676 12.8317 8.03167L11.8961 8.96728ZM8.11876 11.0475L7.18314 11.9831C6.92824 12.238 6.94941 12.5979 7.18314 12.8317C7.41688 13.0654 7.77676 13.0866 8.03167 12.8317L8.96728 11.8961L9.10467 12.0334C9.4987 12.4275 9.4987 13.0663 9.10467 13.4604L6.26036 16.3047C5.86633 16.6987 5.22748 16.6987 4.83344 16.3047L3.69572 15.1669C3.30169 14.7729 3.30169 14.1341 3.69572 13.74L6.54003 10.8957C6.93406 10.5017 7.57291 10.5017 7.96695 10.8957L8.11876 11.0475Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const verticalLineSpacing = (color = "#141414") => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.1537 16.3495V4.56912H11.0306C11.3365 4.56912 11.5845 4.32115 11.5845 4.01527C11.5845 3.70939 11.3365 3.46143 11.0306 3.46143H2.16908C1.8632 3.46143 1.61523 3.70939 1.61523 4.01527C1.61523 4.32115 1.8632 4.56912 2.16908 4.56912H6.046V16.3495C6.046 16.6554 6.29397 16.9034 6.59985 16.9034C6.90573 16.9034 7.1537 16.6554 7.1537 16.3495Z"
      fill="#141414"
      fillOpacity="0.38"
    />
    <path
      d="M14.1691 5.3549V15.2295L12.8988 13.9617C12.6823 13.7456 12.3316 13.746 12.1155 13.9625C11.8994 14.179 11.8998 14.5297 12.1163 14.7458L14.3361 16.7415C14.5526 16.9576 14.9033 16.9573 15.1194 16.7408L17.3303 14.745C17.5464 14.5285 17.5461 14.1778 17.3295 13.9617C17.113 13.7456 16.7624 13.746 16.5463 13.9625L15.2768 15.2346V5.34984L16.5463 6.62189C16.7624 6.8384 17.113 6.83875 17.3295 6.62268C17.5461 6.4066 17.5464 6.05593 17.3303 5.83942L15.1194 3.62403C14.9033 3.40753 14.5526 3.40718 14.3361 3.62325L12.1163 5.83864C11.8998 6.05471 11.8994 6.40539 12.1155 6.62189C12.3316 6.8384 12.6823 6.83875 12.8988 6.62267L14.1691 5.3549Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const horizontalLineSpacing = (color = "#141414") => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.47681 11.0881V1.30349H11.3537C11.6596 1.30349 11.9076 1.05553 11.9076 0.749647C11.9076 0.443766 11.6596 0.195801 11.3537 0.195801H2.4922C2.18631 0.195801 1.93835 0.443766 1.93835 0.749647C1.93835 1.05553 2.18631 1.30349 2.4922 1.30349H6.36912V11.0881C6.36912 11.394 6.61708 11.642 6.92296 11.642C7.22885 11.642 7.47681 11.394 7.47681 11.0881Z"
      fill="#141414"
      fillOpacity="0.38"
    />
    <path
      d="M10.2233 11.4793L11.491 12.7496H2.3549L3.62267 11.4793C3.83875 11.2628 3.8384 10.9122 3.62189 10.6961C3.40539 10.48 3.05471 10.4804 2.83864 10.6969L0.623254 12.9167C0.407179 13.1332 0.407528 13.4839 0.624035 13.6999L2.83942 15.9109C3.05593 16.127 3.4066 16.1266 3.62268 15.9101C3.83875 15.6936 3.8384 15.3429 3.62189 15.1269L2.34984 13.8573H11.4961L10.224 15.1269C10.0075 15.3429 10.0072 15.6936 10.2233 15.9101C10.4393 16.1266 10.79 16.127 11.0065 15.9109L13.2219 13.6999C13.4384 13.4839 13.4387 13.1332 13.2227 12.9167L11.0073 10.6969C10.7912 10.4804 10.4405 10.48 10.224 10.6961C10.0075 10.9122 10.0072 11.2628 10.2233 11.4793Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const underlineSvg = (color = "#141414") => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6 0.601562C12.2686 0.601562 12 0.870192 12 1.20156V8.40156C12 11.163 9.76142 13.4016 7 13.4016C4.23858 13.4016 2 11.163 2 8.40156V1.20156C2 0.870192 1.73137 0.601562 1.4 0.601562C1.06863 0.601562 0.8 0.870192 0.8 1.20156V8.40156C0.8 11.8257 3.57583 14.6016 7 14.6016C10.4242 14.6016 13.2 11.8257 13.2 8.40156V1.20156C13.2 0.870192 12.9314 0.601562 12.6 0.601562Z"
      fill="#141414"
      fillOpacity="0.38"
    />
    <path
      d="M0 16.4016C0 16.7329 0.268629 17.0016 0.6 17.0016H13.4C13.7314 17.0016 14 16.7329 14 16.4016C14 16.0702 13.7314 15.8016 13.4 15.8016H0.6C0.268629 15.8016 0 16.0702 0 16.4016Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const italicSvg = (color = "#141414") => (
  <svg
    width="13"
    height="17"
    viewBox="0 0 13 17"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.18961 14.8014H7.6001C7.93147 14.8014 8.2001 15.07 8.2001 15.4014C8.2001 15.7328 7.93147 16.0014 7.6001 16.0014H4.41791C4.40637 16.0018 4.39478 16.0018 4.38316 16.0014H1.2001C0.868727 16.0014 0.600098 15.7328 0.600098 15.4014C0.600098 15.07 0.868727 14.8014 1.2001 14.8014H3.94418L7.61084 1.60142H5.2001C4.86873 1.60142 4.6001 1.33279 4.6001 1.00142C4.6001 0.670047 4.86873 0.401418 5.2001 0.401418H8.3921C8.39727 0.40135 8.40245 0.40135 8.40764 0.401418L11.6001 0.401418C11.9315 0.401418 12.2001 0.670047 12.2001 1.00142C12.2001 1.33279 11.9315 1.60142 11.6001 1.60142H8.85628L5.18961 14.8014Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

const boldSvg = (color = "#141414") => (
  <svg
    width="13"
    height="17"
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.606836 16.0175C0.275465 16.0175 0.00683594 15.7489 0.00683594 15.4175V1.01748C0.00683594 0.68611 0.275465 0.41748 0.606836 0.41748H7.00684C9.11496 0.41748 10.8068 2.30457 10.8068 4.61748C10.8068 5.85778 10.3203 6.97563 9.54397 7.74569C11.1947 8.2071 12.4068 9.85975 12.4068 11.8175C12.4068 14.1304 10.715 16.0175 8.60684 16.0175H0.606836ZM1.20684 14.8175H8.60684C10.0323 14.8175 11.2068 13.4817 11.2068 11.8175C11.2068 10.1533 10.0323 8.81748 8.60684 8.81748H1.20684V14.8175ZM1.20684 7.61748H7.00684C8.4323 7.61748 9.60684 6.28168 9.60684 4.61748C9.60684 2.95328 8.4323 1.61748 7.00684 1.61748H1.20684V7.61748Z"
      fill="#141414"
      fillOpacity="0.38"
    />
  </svg>
);

export const arrowDown = (color = "#141414") => (
  <svg width="10" height="5" viewBox="0 0 10 5" fill="none">
    <path d="M0 0L5 5L10 0H0Z" fill={color} fillOpacity="0.54" />
  </svg>
);
const alertSvg = () => (
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 3.99L18.53 17H3.47L11 3.99ZM11 0L0 19H22L11 0ZM12 14H10V16H12V14ZM12 8H10V12H12V8Z"
      fill="#141414"
      fill-opacity="0.54"
    />
  </svg>
);
export const navigatorSvg = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.5"
      width="36"
      height="36"
      rx="9"
      fill="#75FACF"
      fillOpacity="0.24"
    />
    <path
      d="M9.66667 16.0769L16.7673 19.2327L19.9231 26.3333L26.3333 9.66666L9.66667 16.0769Z"
      fill="#2C7673"
    />
  </svg>
);

const addReportSvg = () => (
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="38" cy="38" r="38" fill="#E6F0EF" />
    <path
      d="M48.4167 53.0417H23.4167V27.5833H38V23.4167H23.4167C21.125 23.4167 19.25 25.2917 19.25 27.5833V52.5833C19.25 54.875 21.125 56.75 23.4167 56.75H48.4167C50.7083 56.75 52.5833 54.875 52.5833 52.5833V38H48.4167V53.0417Z"
      fill="#00B999"
    />
    <path
      d="M52.5833 17.1667H48.4167V23.4167H42.1667C42.1875 23.4375 42.1667 27.5833 42.1667 27.5833H48.4167V33.8125C48.4375 33.8333 52.5833 33.8125 52.5833 33.8125V27.5833H58.8333V23.4167H52.5833V17.1667Z"
      fill="#00B999"
    />
    <path d="M44.25 31.75H27.5833V35.9167H44.25V31.75Z" fill="#00B999" />
    <path d="M27.5833 38V42.1667H44.25V38H38H27.5833Z" fill="#00B999" />
    <path d="M44.25 44.25H27.5833V48.4167H44.25V44.25Z" fill="#00B999" />
  </svg>
);

export const MenuSvg = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill="#141414" />
  </svg>
);

export const BackArrowSvg = () => (
  <svg
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.67 1.87L9.9 0.1L0 10L9.9 19.9L11.67 18.13L3.54 10L11.67 1.87Z"
      fill="#141414"
    />
  </svg>
);

export const CommentInputSvg = (color = "white") => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.99 2C19.99 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H16L20 20L19.99 2ZM15 9H11V13H9V9H5V7H9V3H11V7H15V9Z"
      fill={color}
      fill-opacity="0.54"
    />
  </svg>
);

export const notificationBuzzSvg = (color = "white") => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.58003 2.08L4.15003 0.65C1.75003 2.48 0.170029 5.3 0.0300293 8.5H2.03003C2.18003 5.85 3.54003 3.53 5.58003 2.08ZM17.97 8.5H19.97C19.82 5.3 18.24 2.48 15.85 0.65L14.43 2.08C16.45 3.53 17.82 5.85 17.97 8.5ZM16 9C16 5.93 14.36 3.36 11.5 2.68V2C11.5 1.17 10.83 0.5 10 0.5C9.17003 0.5 8.50003 1.17 8.50003 2V2.68C5.63003 3.36 4.00003 5.92 4.00003 9V14L2.00003 16V17H18V16L16 14V9ZM10 20C10.14 20 10.27 19.99 10.4 19.96C11.05 19.82 11.58 19.38 11.84 18.78C11.94 18.54 11.99 18.28 11.99 18H7.99003C8.00003 19.1 8.89003 20 10 20Z"
      fill={color}
      fill-opacity="0.5"
    />
  </svg>
);

export const snoozeSvg = (color = "white") => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.88 2.38999L4.6 0.859985L0 4.70999L1.29 6.23998L5.88 2.38999ZM20 4.71999L15.4 0.859985L14.11 2.38999L18.71 6.24999L20 4.71999ZM10 2.99999C5.03 2.99999 1 7.02999 1 12C1 16.97 5.02 21 10 21C14.97 21 19 16.97 19 12C19 7.02999 14.97 2.99999 10 2.99999ZM10 19C6.13 19 3 15.87 3 12C3 8.12999 6.13 4.99999 10 4.99999C13.87 4.99999 17 8.12999 17 12C17 15.87 13.87 19 10 19ZM7 9.99999H10.63L7 14.2V16H13V14H9.37L13 9.79999V7.99999H7V9.99999Z"
      fill={color}
      fill-opacity="0.5"
    />
  </svg>
);

export const NotificatioMainSvg = (color = "white") => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM14 14V9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.63 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14Z"
      fill={color}
      fill-opacity="0.5"
    />
  </svg>
);

export const RightArrowSvg = (color = "white") => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.410034 2.09L6.32003 8L0.410034 13.91L2.00003 15.5L9.50003 8L2.00003 0.5L0.410034 2.09Z"
      fill={color}
    />
  </svg>
);

export const DownArrowSvg = (color = "white") => (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.91 0.409912L8 6.31991L2.09 0.409912L0.5 1.99991L8 9.49991L15.5 1.99991L13.91 0.409912Z"
      fill={color}
    />
  </svg>
);
const newsFeedSvg = () => (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 0H2.5C1.4 0 0.51 0.9 0.51 2L0.5 14C0.5 15.1 1.4 16 2.5 16H18.5C19.6 16 20.5 15.1 20.5 14V2C20.5 0.9 19.6 0 18.5 0ZM13.5 14H2.5V10H13.5V14ZM13.5 9H2.5V5H13.5V9ZM18.5 14H14.5V5H18.5V14Z"
      fill="#141414"
      fillOpacity="0.24"
    />
  </svg>
);

const rewardSvg = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 6.25003L16.75 8.12503V3.05176e-05H20.5V6.25003ZM0.5 3.05176e-05H4.25V8.12503L0.5 6.25003V3.05176e-05ZM5.5 3.05176e-05H15.5V8.75003C14.9661 9.02347 14.4355 9.29039 13.9082 9.55081C13.3809 9.81123 12.847 10.0782 12.3066 10.3516C12.7819 10.5404 13.2148 10.791 13.6055 11.1035C13.9961 11.416 14.3346 11.7741 14.6211 12.1778C14.9076 12.5814 15.1224 13.0241 15.2656 13.5059C15.4089 13.9877 15.487 14.4857 15.5 15C15.5 15.6901 15.3698 16.3379 15.1094 16.9434C14.849 17.5489 14.4941 18.0795 14.0449 18.5352C13.5957 18.9909 13.0651 19.349 12.4531 19.6094C11.8411 19.8698 11.1901 20 10.5 20C9.8099 20 9.16211 19.8698 8.55664 19.6094C7.95117 19.349 7.42057 18.9942 6.96484 18.545C6.50911 18.0957 6.15104 17.5651 5.89062 16.9532C5.63021 16.3412 5.5 15.6901 5.5 15C5.5 14.4857 5.57487 13.9909 5.72461 13.5157C5.87435 13.0404 6.09245 12.5977 6.37891 12.1875C6.66536 11.7774 7.00065 11.416 7.38477 11.1035C7.76888 10.791 8.20508 10.5404 8.69336 10.3516L5.5 8.75003V3.05176e-05Z"
      fill="#141414"
      fillOpacity="0.24"
    />
  </svg>
);

const surveyCommentSvg = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 3.05176e-05H2.5C1.4 3.05176e-05 0.51 0.90003 0.51 2.00003L0.5 20L4.5 16H18.5C19.6 16 20.5 15.1 20.5 14V2.00003C20.5 0.90003 19.6 3.05176e-05 18.5 3.05176e-05ZM6.5 12H4.5V10H6.5V12ZM6.5 9.00003H4.5V7.00003H6.5V9.00003ZM6.5 6.00003H4.5V4.00003H6.5V6.00003ZM13.5 12H8.5V10H13.5V12ZM16.5 9.00003H8.5V7.00003H16.5V9.00003ZM16.5 6.00003H8.5V4.00003H16.5V6.00003Z"
      fill="#141414"
      fillOpacity="0.24"
    />
  </svg>
);
const directorySvg = () => (
  <svg
    viewBox="0 0 22 22"
    width="20px"
    height="16px"
    className="icon-sidebar"
    fill="none"
  >
    <path
      d="M22.8862 6.74755L21.6783 1.7413C21.4252 0.710268 20.5279 0 19.4811 0H3.50246C2.46712 0 1.55833 0.721724 1.31675 1.7413L0.108866 6.74755C-0.167223 7.91605 0.0858586 9.10747 0.822095 10.0469C0.914125 10.1729 1.04067 10.2645 1.1442 10.3791V18.3295C1.1442 19.5897 2.17953 20.6207 3.44494 20.6207H19.5501C20.8155 20.6207 21.8509 19.5897 21.8509 18.3295V10.3791C21.9544 10.276 22.0809 10.1729 22.173 10.0583C22.9092 9.11893 23.1738 7.91605 22.8862 6.74755ZM19.4466 2.27973L20.6545 7.28598C20.7695 7.76713 20.666 8.24827 20.3669 8.62632C20.2058 8.83253 19.8607 9.16475 19.2855 9.16475C18.5838 9.16475 17.9741 8.60341 17.8936 7.85877L17.2264 2.29119L19.4466 2.27973ZM12.6479 2.29119H14.9026L15.5238 7.46927C15.5813 7.91605 15.4433 8.36283 15.1442 8.69506C14.8911 8.99291 14.523 9.16475 14.0514 9.16475C13.2806 9.16475 12.6479 8.48885 12.6479 7.66402V2.29119ZM7.45973 7.46927L8.09243 2.29119H10.3472V7.66402C10.3472 8.48885 9.71446 9.16475 8.86318 9.16475C8.47206 9.16475 8.11544 8.99291 7.83935 8.69506C7.55176 8.36283 7.41372 7.91605 7.45973 7.46927ZM2.34058 7.28598L3.50246 2.29119H5.76869L5.10147 7.85877C5.00944 8.60341 4.41125 9.16475 3.70952 9.16475C3.14584 9.16475 2.78923 8.83253 2.63968 8.62632C2.32908 8.25973 2.22555 7.76713 2.34058 7.28598ZM3.44494 18.3295V11.4216C3.53697 11.433 3.61749 11.4559 3.70952 11.4559C4.71035 11.4559 5.61914 11.0435 6.28635 10.3676C6.97658 11.055 7.89687 11.4559 8.94371 11.4559C9.94453 11.4559 10.8418 11.0435 11.509 10.3905C12.1878 11.0435 13.108 11.4559 14.1434 11.4559C15.1097 11.4559 16.03 11.055 16.7202 10.3676C17.3874 11.0435 18.2962 11.4559 19.297 11.4559C19.3891 11.4559 19.4696 11.433 19.5616 11.4216V18.3295H3.44494Z"
      fill="white"
    />
  </svg>
);
export const avatarSvg = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 6.10352e-05C4.98 6.10352e-05 0.5 4.48006 0.5 10.0001C0.5 15.5201 4.98 20.0001 10.5 20.0001C16.02 20.0001 20.5 15.5201 20.5 10.0001C20.5 4.48006 16.02 6.10352e-05 10.5 6.10352e-05ZM10.5 3.00006C12.16 3.00006 13.5 4.34006 13.5 6.00006C13.5 7.66006 12.16 9.00006 10.5 9.00006C8.84 9.00006 7.5 7.66006 7.5 6.00006C7.5 4.34006 8.84 3.00006 10.5 3.00006ZM10.5 17.2001C8 17.2001 5.79 15.9201 4.5 13.9801C4.53 11.9901 8.5 10.9001 10.5 10.9001C12.49 10.9001 16.47 11.9901 16.5 13.9801C15.21 15.9201 13 17.2001 10.5 17.2001Z"
      fill="#141414"
      fillOpacity="0.24"
    />
  </svg>
);

export const DownloadFileIcon = (props) => (
  <Icon component={downloadFileSvg} {...props} />
);

export const AttachBoxFileIcon = (props) => (
  <Icon component={attachBoxFileSvg} {...props} />
);

export const EmojiIcon = (props) => <Icon component={emojiSvg} {...props} />;

export const PreviousIcon = (props) => (
  <Icon component={previousSvg} {...props} />
);

export const SuccessCheckIcon = (props) => (
  <Icon component={successCheckSvg} {...props} />
);

export const ExpandIcon = (props) => <Icon component={expandSvg} {...props} />;

export const ShrinkIcon = (props) => <Icon component={shrinkSvg} {...props} />;

export const AttachFileIcon = (props) => (
  <Icon component={() => attachFileSvg(props)} {...props} />
);

export const AtMentionIcon = (props) => (
  <Icon component={atMentionSvg} {...props} />
);

export const RefreshIcon = (props) => (
  <span>
    <ReloadOutlined
      {...props}
      style={{
        marginTop: props.marginTop,
        fontSize: props.fontSize || 15,
        color: props.color || "black",
        opacity: props.opacity || 0.6,
      }}
    />
  </span>
);
export const StarIcon = (props) => (
  <Icon
    component={props.filled === "false" ? starSvg : starFilledSvg}
    {...props}
  />
);
export const ChatBotIcon = (props) => (
  <Icon component={chatbotSvg} {...props} />
);
export const LogoutIcon = ({ color, height, width, margin, ...props }) => (
  <Icon component={() => logoutSvg(color, width, height, margin)} {...props} />
);
export const MentionIcon = (props) => (
  <Icon component={mentionSvg} {...props} />
);
export const TaskIcon = (props) => <Icon component={taskSvg} {...props} />;
export const HomeIcon = ({ color, width, height, ...props }) => (
  <Icon component={() => homeSvg(color, height, width)} {...props} />
);
export const AnalyticsIcon = ({ color, ...props }) => (
  <Icon component={() => analyticsSvg(color)} {...props} />
);
export const SurveyIcon = ({ color, ...props }) => (
  <Icon component={() => surveySvg(color)} {...props} />
);
export const EventsIcon = ({ color, height, ...props }) => (
  <Icon component={() => eventsSvg(color, height)} {...props} />
);
export const FeedIcon = ({ color, ...props }) => (
  <Icon component={() => feedSvg(color)} {...props} />
);
export const UserIcon = ({ color, ...props }) => (
  <Icon component={() => userSvg(color)} {...props} />
);
export const FaqIcon = (props) => <Icon component={faqSvg} {...props} />;
export const BackIcon = ({ color, ...props }) => (
  <Icon component={() => backIconSvg(color)} {...props} />
);
export const FilterIcon = ({ color, ...props }) => (
  <Icon component={() => filterIcon(color)} {...props} />
);
export const GraphIcon = ({ color, ...props }) => (
  <Icon component={() => graphIcon(color)} {...props} />
);
export const ScatterDotsIcon = ({ color, ...props }) => (
  <Icon component={() => scatterDots(color)} {...props} />
);
export const TimerIcon = ({ color, ...props }) => (
  <Icon component={() => timerIcon(color)} {...props} />
);
export const FlagIcon = ({ color, ...props }) => (
  <Icon component={() => flagSvg(color)} {...props} />
);
export const AttachImageIcon = (props) => (
  <Icon component={attachImageSvg} {...props} />
);
export const KanbanIcon = ({ color, ...props }) => (
  <Icon component={() => kanbanSvg(color)} {...props} />
);
export const ListViewIcon = ({ color, ...props }) => (
  <Icon component={() => listViewSvg(color)} {...props} />
);
export const MenuThreeDotsIcon = ({
  color,
  direction = "vertical",
  ...props
}) => <Icon component={() => menuThreeDotsSvg(color, direction)} {...props} />;
export const CheckIcon = ({ color, opacity, ...props }) => (
  <Icon component={() => checkSvg(color, opacity)} {...props} />
);
export const AddUserIcon = ({ color, ...props }) => (
  <Icon component={() => addUserSvg(color)} {...props} />
);
export const MoveIcon = ({ color, ...props }) => (
  <Icon component={() => moveSvg(color)} {...props} />
);
export const EditIcon = ({ color, ...props }) => (
  <Icon component={() => editSvg(color)} {...props} />
);
export const CrossIcon = ({ color, opacity, ...props }) => (
  <Icon component={() => crossSvg(color, opacity)} {...props} />
);
export const TickIcon = ({ color, ...props }) => (
  <Icon component={() => tickSvg(color)} {...props} />
);
export const PersonIcon = ({ color, ...props }) => (
  <Icon component={() => personSvg(color)} {...props} />
);
export const MapPinSvg = ({ color, ...props }) => (
  <Icon component={() => mapPinSvg(color)} {...props} />
);

export const NotificationIcon = ({
  color,
  height,
  width,
  margin,
  ...props
}) => (
  <Icon
    component={() => notificationSvg(color, height, width, margin)}
    {...props}
  />
);
export const CommunicationIcon = ({
  color,
  height,
  width,
  margin,
  ...props
}) => (
  <Icon
    component={() => communicationSvg(color, height, width, margin)}
    {...props}
  />
);
export const MapIcon = (props) => <Icon component={mapSvg} {...props} />;

export const FlagsIcon = ({ color, ...props }) => (
  <Icon component={() => flagsSvg(color)} {...props} />
);
export const CommentIcon = ({ color, ...props }) => (
  <Icon component={() => commentsSvg(color)} {...props} />
);
export const CommentInputAddIcon = ({ color, ...props }) => (
  <Icon component={() => CommentInputSvg(color)} {...props} />
);
export const NotificationBuzzIcon = ({ color, ...props }) => (
  <Icon component={() => notificationBuzzSvg(color)} {...props} />
);
export const SnoozeIcon = ({ color, ...props }) => (
  <Icon component={() => snoozeSvg(color)} {...props} />
);
export const NotificatioMainIcon = ({ color, ...props }) => (
  <Icon component={() => NotificatioMainSvg(color)} {...props} />
);
export const RightArrowIcon = ({ color, ...props }) => (
  <Icon component={() => RightArrowSvg(color)} {...props} />
);
export const DownArrowIcon = ({ color, ...props }) => (
  <Icon component={() => DownArrowSvg(color)} {...props} />
);

export const SpiningPage = ({ color, opacity, ...props }) => {
  const userSettings = useSelector((state) => state.projects.settings);
  const [settings, setSettings] = useState(userSettings);
  useEffect(() => {
    setSettings(userSettings);
  }, [userSettings]);

  return (
    <div className="sping-page-skeleton">
      <Skeleton active paragraph={{ rows: 12 }} />
      {/* <Spin
        indicator={
          <LoadingIcon
            color={color ?? settings?.sibebar_color}
            opacity={opacity}
          />
        }
      /> */}
    </div>
  );
};
export const SpiningPageSmall = ({ color, opacity, ...props }) => {
  const userSettings = useSelector((state) => state.projects.settings);
  const [settings, setSettings] = useState(userSettings);
  useEffect(() => {
    setSettings(userSettings);
  }, [userSettings]);

  return (
    <div className="sping-page-skeleton">
      <Skeleton active paragraph={{ rows: 4 }} />
      {/* <Spin
        indicator={
          <LoadingIcon
            color={color ?? settings?.sibebar_color}
            opacity={opacity}
          />
        }
      /> */}
    </div>
  );
};
export const SpinLoader = ({ color, opacity, fontSize, ...props }) => {
  console.log({ color, opacity });
  return (
    <Spin
      indicator={
        <LoadingIcon color={color} opacity={opacity} fontSize={fontSize} />
      }
    />
  );
};

export const TrashIcon = (props) => (
  <span>
    <DeleteOutlined
      {...props}
      style={{
        marginTop: props.marginTop,
        fontSize: props.fontSize || 15,
        color: props.color || "black",
        opacity: props.opacity || 0.6,
      }}
    />
  </span>
);

export const BackArowIcon = (props) => (
  <ArrowLeftOutlined
    {...props}
    style={{
      marginTop: props.margintop ? props.margintop : 18,
      fontSize: props.fontSize ? props.fontSize : 15,
      color: props.color ? props.color : "black",
      opacity: 0.6,
    }}
  />
);

export const sidebarCloseSvg = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 12H5V10H18V12ZM18 7H8V5H18V7ZM18 0V2H5V0H18ZM0 9.59L3.58 6L0 2.41L1.41 1L6.41 6L1.41 11L0 9.59Z"
      fill="#141414"
    />
  </svg>
);

export const infoSvg = (color = "#141414") => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 6.10352e-05C4.98 6.10352e-05 0.5 4.48006 0.5 10.0001C0.5 15.5201 4.98 20.0001 10.5 20.0001C16.02 20.0001 20.5 15.5201 20.5 10.0001C20.5 4.48006 16.02 6.10352e-05 10.5 6.10352e-05ZM11.5 15.0001H9.5V9.00006H11.5V15.0001ZM11.5 7.00006H9.5V5.00006H11.5V7.00006Z"
      fillOpacity="1"
      fill={color}
    />
  </svg>
);

export const gotoSvg = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 3.05176e-05V2.00003H12.09L0.5 13.59L1.91 15L13.5 3.41003V10H15.5V3.05176e-05H5.5Z"
      fill="#141414"
      fillOpacity="0.24"
    />
  </svg>
);

export const MultiCheckIcon = ({ color, ...props }) => (
  <Icon component={() => multiCheckSvg(color)} {...props} />
);

export const BoolIcon = (props) => <Icon component={boolSvg} {...props} />;
export const TextIcon = (props) => <Icon component={textSvg} {...props} />;
export const ReloadIcon = (props) => <Icon component={reloadSvg} {...props} />;
export const SettingIcon = (props) => (
  <Icon component={settingSvg} {...props} />
);
export const NavigateIcon = (props) => (
  <Icon component={navigateSvg} {...props} />
);
export const DuplicateIcon = (props) => (
  <Icon component={duplicateSvg} {...props} />
);
export const DeleteIcon = () => <Icon component={deleteSvg} />;
export const SeedIcon = () => <Icon component={seedSvg} />;
export const EyeIcon = ({ color, ...props }) => (
  <Icon component={() => eyeSvg(color)} {...props} />
);
export const ClockIcon = ({ color, ...props }) => (
  <Icon component={() => clockSvg(color)} {...props} />
);
export const GrowthIcon = ({ color }) => (
  <Icon component={() => growthSvg(color)} />
);
export const IconTrash = (props) => (
  <DeleteOutlined
    {...props}
    style={{
      // marginLeft: 20,
      fontSize: 15,
      color: "black",
      opacity: 0.6,
    }}
  />
);

export const ForwardIcon = (props) => (
  <RightOutlined
    {...props}
    style={{
      marginTop: props.margintop ? props.margintop : 18,
      fontSize: props.fontSize ? props.fontSize : 15,
      color: props.color ? props.color : "black",
      opacity: 0.6,
    }}
  />
);

export const PalatteIcon = ({ color, ...props }) => (
  <Icon component={() => palatteSvg(color)} {...props} />
);
export const SurveyTemplateOneIcon = (props) => (
  <Icon component={surveyTemplateOneSvg} {...props} />
);
export const SurveyTemplateTwoIcon = (props) => (
  <Icon component={surveyTemplateTwoSvg} {...props} />
);
export const SurveyTemplateThreeIcon = (props) => (
  <Icon component={surveyTemplateThreeSvg} {...props} />
);
export const SurveyTemplateFourIcon = (props) => (
  <Icon component={surveyTemplateFourSvg} {...props} />
);
export const GridIcon = ({ color, ...props }) => (
  <Icon component={() => gridSvg(color)} {...props} />
);
export const LocationMarkerIcon = ({ color, ...props }) => (
  <Icon component={() => locationMarkerSvg(color)} {...props} />
);

export const ArrowDown = ({ color, ...props }) => (
  <Icon component={() => arrowDown(color)} {...props} />
);

export const EventIcon = (props) => (
  <Icon component={() => eventSvg()} {...props} />
);
export const PostIcon = (props) => (
  <Icon component={() => postSvg()} {...props} />
);
export const CalendarIcon = (props) => (
  <Icon component={() => calendarSvg()} {...props} />
);
export const NavigatorIcon = () => <Icon component={navigatorSvg} />;
export const AddReportIcon = () => <Icon component={addReportSvg} />;
export const JustifyIcon = (props) => (
  <Icon component={justifySvg} {...props} />
);

export const JustifyCenterIcon = (props) => (
  <Icon component={justifyCenterSvg} {...props} />
);

export const JustifyLeftIcon = (props) => (
  <Icon component={justifyLeftSvg} {...props} />
);

export const RightAlignIcon = (props) => (
  <Icon component={rightAlignSvg} {...props} />
);

export const CenterAlignIcon = (props) => (
  <Icon component={centerAlignSvg} {...props} />
);

export const LeftAlignIcon = (props) => (
  <Icon component={leftAlignSvg} {...props} />
);

export const FillIcon = (props) => <Icon component={fillSvg} {...props} />;

export const EditorAttachImageIcon = (props) => (
  <Icon component={editorAttachImageSvg} {...props} />
);

export const LinkIcon = (props) => <Icon component={linkSvg} {...props} />;

export const VerticalLineSpacingIcon = (props) => (
  <Icon component={verticalLineSpacing} {...props} />
);

export const HorizontalLineSpacingIcon = (props) => (
  <Icon component={horizontalLineSpacing} {...props} />
);

export const UnderlineIcon = (props) => (
  <Icon component={underlineSvg} {...props} />
);

export const ItalicIcon = (props) => <Icon component={italicSvg} {...props} />;

export const BoldIcon = (props) => <Icon component={boldSvg} {...props} />;

export const BackNonArrowIcon = ({ color, ...props }) => (
  <Icon component={() => BackArrowSvg(color)} {...props} />
);
export const MenuIcon = ({ color, ...props }) => (
  <Icon component={() => MenuSvg(color)} {...props} />
);
export const AlertIcon = (props) => <Icon component={alertSvg} />;
export const SideBarCloseIcon = () => <Icon component={sidebarCloseSvg} />;

export const NewsFeedIcon = () => <Icon component={newsFeedSvg} />;

export const RewardIcon = () => <Icon component={rewardSvg} />;

export const InfoIcon = ({ color, ...props }) => (
  <Icon component={() => infoSvg(color)} {...props} />
);

export const SurveyCommentIcon = () => <Icon component={surveyCommentSvg} />;

export const AvatarIcon = () => <Icon component={avatarSvg} />;

export const GotoIcon = () => <Icon component={gotoSvg} />;

export const DirectoryIcon = () => <Icon component={directorySvg} />;
