import React, { Fragment } from "react";
import Menu from "components/atoms/menu";
import { Dropdown } from "components/atoms/controls";
import "./ProjectListDropdown.scss";

export default ({ options = [], title, onClick, className = "" }) => {
  const menu = <Menu options={options} onClick={onClick} />;
  return (
    <div className="project">
      <Dropdown
        getPopupContainer={(trigger) => trigger.parentNode}
        menu={menu}
        trigger={["click"]}
      >
        <span className={`${className}`} onClick={(e) => e.stopPropagation()}>
          {title}
        </span>
      </Dropdown>
    </div>
  );
};
