import WelcomeRt from "client/pages/Welcome";
import LoginRt from "client/pages/Login";
import SignUpRt from "client/pages/Signup";
import ForgotRt from "client/pages/Forgot";
import ValidateRt from "client/pages/Validate";
import Verify from "client/pages/Verify";
import ClientSurvey from "client/pages/Survey";

const prefix = "/client";

const routes = [
  {
    path: `${prefix}`,
    component: WelcomeRt,
    exact: true,
  },
  {
    path: `${prefix}/login`,
    component: LoginRt,
    exact: true,
  },
  {
    path: `${prefix}/signup`,
    component: SignUpRt,
    exact: true,
  },
  {
    path: `${prefix}/forgot`,
    component: ForgotRt,
    exact: true,
  },
  {
    path: `${prefix}/validate`,
    component: ValidateRt,
    exact: true,
  },{
    path: `${prefix}/verify`,
    component: Verify,
    exact: true,

  },
  {
    path: `${prefix}/survey`,
    component: ClientSurvey,
    exact: true,
  }
];

export default routes;
