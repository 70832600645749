import React, { useContext } from 'react';
import { Drawer } from 'antd';
import "./Eventsdatepicker.scss";
import Calendar from 'components/atoms/calendar'
import { I18nContext } from "utils/i18n/locale";

const EventsDatePicker = ({visible, setVisible, state, setState, setHeader}) => {
  const { translate } = useContext(I18nContext)

  return (
    <div className="event-date-picker">
    <Drawer
      title={translate("client").pages.events.common.chooseDate}
      placement={"top"}
      closable={false}
      onClose={() => setVisible(false)}
      visible={visible}
      getContainer={false}
      className="date-picker-container"
      style={{ position: 'absolute' }}
    >
      <Calendar range={state} setRange={setState} setVisible={setVisible} setHeader={setHeader} />
    </Drawer>
    </div>
  )
}

export default EventsDatePicker;