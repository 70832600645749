import React from "react";
import { Modal } from "antd";
import "./CreateSurveyPopup.scss";

const CreateSurveyPopup = ({
  isVisible,
  handleCancel,
  title,
  children,
  className,
}) => {
  return (
    <div className="create-survey-popup-wrapper">
      <Modal
      visible={isVisible}
      onCancel={handleCancel}
      title={title}
      footer={null}
      className={`${className} survey-modal`}
      centered
    >
      {children}
    </Modal>
    </div>
    
  );
};

export default CreateSurveyPopup;
