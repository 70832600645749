import MainLayout from "layouts/MainLayout";
import React, { useContext, useEffect, useState } from "react";
import { I18nContext } from "utils/i18n/locale";
// import { list } from "router/homeRoutes";
import HomeRoute from "router/modules/homeRT";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { SpiningPage } from "components/atoms/icons";

const Home = () => {
  const currentProject = useSelector((state) => state.projects.currentProject);
  const loading = useSelector((state) => state.projects.loadingSidebar);
  const [permissions, setPermissions] = useState(currentProject?.permissions);
  useEffect(() => {
    // console.log(currentProject, "en el home");
    if (currentProject) {
      setPermissions(currentProject.permissions);
    }
  }, [currentProject]);

  return (
    <>
      <Router>
        {/* <Route
        path={`/${"home"}/:key?`}
        render={({ match, history }) => {
        console.log(match, "match"); */}
        {/* return ( */}
        <Switch>
          <Redirect exact from="/home" to="/home/analytics/demo" />
          <MainLayout>
            {loading ? (
              <SpiningPage opacity={1} />
            ) : (
              <Switch>
                {HomeRoute.map((route, index) =>
                  permissions !== undefined &&
                  permissions[route.route]?.read ? (
                    <Route
                      key={route.path}
                      path={route.path}
                      exact={route.exact}
                      children={<route.component />}
                    />
                  ) : (
                    (route.route === 404 || route.route === "/home") && (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        children={<route.component />}
                      />
                    )
                  )
                )}
              </Switch>
            )}

            {/* <Events /> */}
          </MainLayout>
        </Switch>
        {/* ); */}

        {/* /> */}
      </Router>
    </>
  );

  // const { translate } = useContext(I18nContext);
  // return <h1>{translate("pages").home.title}</h1>;
};

export default Home;
