import React, { useContext } from "react";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Divider, Layout, Row } from "antd";
import CheckboxGroup, { TYPE_ICONS } from "components/atoms/checkbox-group";
import { PrimaryButton } from "components/atoms/controls";
import { ListViewIcon, GridIcon } from "components/atoms/icons";
import { StandardInput } from "components/atoms/inputs";
import { Title } from "components/atoms/texts";
import { LAYOUT_GRID, LAYOUT_LIST } from "pages/Surveys";
import { I18nContext } from "utils/i18n/locale";
import "./SurveyHeader.scss";

const SurveyHeader = ({
  title,
  style,
  withActions,
  history,
  onChangeLayout,
  layout = "list",
}) => {
  const { translate } = useContext(I18nContext);
  return (
    <Layout style={{ ...style }} className="survey-header-container">
      <Row gutter={[0, { xs: 8, sm: 16 }]}>
        <Col xs={24} lg={24} xl={8}>
          <Title text={title} className="survey-main-title" />
        </Col>
        {withActions && (
          <Col xs={24} lg={24} xl={16} className="survey-action-container">
            {
              <div className="actions-container">
                {/* TODO:Add search function and logic  */}
                {/* <StandardInput
                  placeholder={
                    translate("pages").survey.placeholder.surveySearch
                  }
                  className="survey-action-search"
                  prefixIcon={<SearchOutlined />}
                /> */}
                <PrimaryButton
                  icon={<PlusOutlined />}
                  onClick={() => history.push("/home/surveys/survey-gallery/")}
                  title={translate("pages").survey.placeholder.addSurvey}
                  cssClassName="survey-header-button"
                />
                <CheckboxGroup
                  className="survey-action-layout"
                  onChange={(value) => onChangeLayout(Object.keys(value)[0])}
                  selected={{ [layout]: true }}
                  options={[
                    {
                      key: LAYOUT_GRID,
                      icon: GridIcon,
                    },
                    {
                      key: LAYOUT_LIST,
                      icon: ListViewIcon,
                    },
                  ]}
                  multiSelect={false}
                  defaultColor="#FFFFFF"
                  // selectedColor="#141414"
                  iconSelected="rgba(20, 20, 20, 0.38)"
                  type={TYPE_ICONS}
                />
              </div>
            }
          </Col>
        )}
      </Row>
      <Divider className="divider" />
    </Layout>
  );
};

export default SurveyHeader;
