import React, { useState } from "react";
import { Table as AntTable } from "antd";
import OpenToggle from "components/atoms/open-toggle";
import "./Table.scss";
import { useMedia } from "react-media";

// const Table = ({ props }) => {
const Table = ({
  columns,
  items,
  loading,
  scroll,
  showPagination = true,
  classes,
  onClick = () => {},
}) => {
  //   const { items, loading } = props;

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  return (
    <AntTable
      className={!classes ? "irys-basic-table" : `${classes}`}
      onRow={(record) => ({ onClick: () => onClick(record) })}
      rowKey={(record) => record.id}
      columns={columns}
      dataSource={items}
      onChange={onChange}
      loading={loading}
      bordered={false}
      scroll={scroll}
      pagination={
        showPagination && {
          size: "small",
          defaultCurrent: 1,
          // simple: true,
          position: ["bottomCenter"],
          defaultPageSize: 5,
          hideOnSinglePage: true,
          // showQuickJumper: true,
        }
      }
    />
  );
};

export const HEADER_NORMAL = "HEADER_NORMAL";
export const HEADER_TRANSLUCENT = "HEADER_TRANSLUCENT";

const HEADER_CLASSES = {
  [HEADER_NORMAL]: "table-normal-header",
  [HEADER_TRANSLUCENT]: "table-translucent-header",
};

export const BADGE_COLORS = {
  GREEN: {
    name: "green",
    value: "#00B999",
  },
  PURPLE: {
    name: "purple",
    value: "#A080E1",
  },
  RED: {
    name: "red",
    value: "#C43A5A",
  },
};

export const BadgeTable = ({
  className = "",
  items = [],
  columns = [],
  headerType = HEADER_NORMAL,
  onClick = () => {},
  options = {
    badgeColor: BADGE_COLORS.GREEN,
    collapsable: false,
    collapsed: false,
    addOption: "Add",
    onAddPressed: null,
  },
  ...props
}) => {
  const [opened, setOpened] = useState(!options.collapsed);
  const isSmallScreen = useMedia({ query: "(max-width: 700px)" });

  return (
    <div className={`badge-table-root ${className}`}>
      {options.collapsable && (
        <OpenToggle
          className="table-toggle"
          color={options.badgeColor.value}
          onChange={(result) => setOpened(result)}
          opened={opened}
        />
      )}
      <AntTable
        {...props}
        className={
          `${HEADER_CLASSES[headerType]} ` +
          `${
            opened
              ? "table-translucent-header-opened"
              : "table-translucent-header-closed"
          } ` +
          `table-translucent-badge-${options.badgeColor.name}`
        }
        columns={columns
          .filter((column) => !(column.canHide && isSmallScreen))
          .map(({ type, ...column }) => {
            if (type === "badge" || type === "action") {
              return {
                ...column,
              };
            }

            return {
              sorter: column.sorter,
              title: column.title,
              dataIndex: column.dataIndex,
            };
          })}
        onRow={(record) => ({ onClick: () => onClick(record) })}
        dataSource={options.collapsable ? (opened ? items : []) : items}
      />
      {options.addOption && options.onAddPressed && (
        <div className="table-add-button" onClick={options.onAddPressed}>
          + {options.addOption}
        </div>
      )}
    </div>
  );
};

export default Table;
