import React from "react";
import { BrowserRouter, Switch, Route, Redirect, Link } from "react-router-dom";
import indexRoutes from "./router";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import "./App.scss";
import Client from "client/index";
import Home from "pages/Home";
import NotificationContext from "client/context/NotificationContext";
import { authRoutes } from "router/modules/authRT";
Amplify.configure(awsconfig);

const App = () => (
    <BrowserRouter>
        <Switch>
            <Redirect from="/" exact to="/home/analytics/demo" />
            {authRoutes.map(({ layout: Layout, routes }) =>
                routes.map(({ path, exact, component: Component }) => (
                    <Route
                        key={path}
                        path={path}
                        exact={exact}
                        render={(props) => (
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        )}
                    ></Route>
                ))
            )}
            <Route path="/home" component={Home} />

            {indexRoutes.map(({ layout: Layout, routes }) =>
                routes.map(({ path, exact, component: Component }) => (
                    <Route
                        key={path}
                        path={path}
                        exact={exact}
                        render={(props) => (
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        )}
                    ></Route>
                ))
            )}
        </Switch>
        <Switch>
            <NotificationContext>
                <Route path="/client" component={Client} />
                <Route path="/launchsa" component={Client} />
            </NotificationContext>
        </Switch>
    </BrowserRouter>
);
export default App;
