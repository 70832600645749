import { SpiningPageSmall } from "components/atoms/icons";
import { SpiningPage } from "components/atoms/icons";
import PreviousPage from "components/atoms/previous-page";
import { Text } from "components/atoms/texts";
import KnowledgeHeader from "components/molecules/KnowledgeHeader";
import KnowledgePostOrganism from "components/organisms/KnowledgePostOrganism";
import { KnowledgeContextProvider } from "context/KnowledgeContext";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getKnowledgeCategories } from "redux/slices/knowledge";
import { I18nContext } from "utils/i18n/locale";

import "./knowledge-posts.scss";

const KnowledgePosts = () => {
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const { categories, setPosts, posts: contextPosts } = useContext(
    KnowledgeContextProvider
  );
  const categoriesFetched = useSelector(
    (state) => state.knowledge.knowledgeCategories
  );
  const [postState, setPoststate] = useState(contextPosts);
  const loading = useSelector((state) => state.knowledge.loading);
  const { category } = useParams();

  const getPosts = (categories) => {
    const posts = categories?.filter(
      (ele) => ele?.title === category?.replaceAll("-", " ")
    )[0]?.posts;

    // let returnPosts = [];
    // for (const post of posts) {
    //   let tempPost = { ...post, knowledge_category_id: category.id };
    //   returnPosts.push(tempPost);
    // }
    // console.log(returnPosts, "post to return");

    return posts;
  };

  useEffect(() => {
    dispatch(getKnowledgeCategories());
  }, []);
  // useEffect(() => {
  //   if (!categories) {
  //     dispatch(getKnowledgeCategories());
  //   }
  //   console.log({ categories });
  //   const temp_posts = getPosts(categories);
  //   setPosts(temp_posts);
  // }, [categories]);

  useEffect(() => {
    const temp_posts = getPosts(categoriesFetched);
    setPosts(temp_posts);
  }, [categoriesFetched]);
  useEffect(() => {
    const temp_posts = getPosts(categories);
    setPosts(temp_posts);
  }, [categories]);
  useEffect(() => {
    setPoststate(contextPosts);
  }, [contextPosts]);

  return (
    <>
      <KnowledgeHeader title={category?.replaceAll("-", " ")} />
      <PreviousPage
        title={translate("pages").knowledge.title.backToKnowledge}
        link="/home/knowledge"
        className="previous-page"
      />
      {postState?.length > 0 && !loading ? (
        <KnowledgePostOrganism posts={postState} />
      ) : loading ? (
        postState?.length >= 3 ? (
          <SpiningPage />
        ) : (
          <SpiningPageSmall />
        )
      ) : (
        <Text text={translate("pages").knowledge.title.noPosts} />
      )}
    </>
  );
};

export default KnowledgePosts;
