import React, { useContext, useEffect, useReducer, useState } from "react";
import "./department.scss";
import { I18nContext } from "utils/i18n/locale";
import { Title } from "components/atoms/texts";
import SearchBar from "components/atoms/search";
import { PrimaryButton } from "components/atoms/controls";
import Table from "components/molecules/Table";

import DepartmentListDropDown from "components/molecules/DepartmentListDropDown";
import { EditIcon, TrashIcon } from "components/atoms/icons";
import { SubCategoryRender } from "components/molecules/DepartmentList";

import Layout from "antd/lib/layout/layout";
import { Col } from "antd";
import { RoundedButton } from "components/atoms/controls";

import CreateCategoryForm from "components/organisms/CreateCategoryForm";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCategories,
  deleteSubCategories,
  getCategories,
  getSubCategories,
} from "redux/slices/categories";
import { unwrapResult } from "@reduxjs/toolkit";
import { AlertWithfunctions } from "components/atoms/outputs";
import { IconPickerItem } from "react-fa-icon-picker";
import MemoizedAvatar from "components/molecules/ProjectSelect/Avatar";
import { cloudFrontUrl } from "utils/common";

const Category = () => {
  const [isModalOpen, openModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  // const [modal, setModal] = useState(false);

  const dispatch = useDispatch();

  const fetchedCategories = useSelector((state) => state.categories.categories);
  const loading = useSelector((state) => state.categories.loading);

  const [searchRef, setSearchRef] = useState(0);
  const [categories, setCategories] = useState(fetchedCategories);
  const [categoriesAux, setCategoriesAux] = useState(fetchedCategories);

  const [isEdit, setEdit] = useState(false);

  const { translate } = useContext(I18nContext);

  useEffect(() => {
    // console.log("categories from API", fetchedCategories);
    setCategories(fetchedCategories);
    setCategoriesAux(fetchedCategories);
  }, [fetchedCategories]);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getSubCategories);
  }, []);

  // const openDeactivateModal = (option, item, id) => {
  //   setModal(true);
  // };

  const closeModal = () => {
    setCurrentId(null);
    setEdit(false);
    // setModal(false);
    openModal(false);
  };

  // const onDeactivate = () => {
  //   let tempState = [...state];
  //   tempState.splice(currentId, 1);
  //   setCurrentId(null);
  //   setState(tempState);
  //   setModal(false);
  // };
  const options = [
    {
      icon: <EditIcon className="icon-style" />,
      key: "edit",
      title: "Edit Category",
    },
    {
      icon: <TrashIcon color="#F44336" className="icon-style" opacity={0} />,
      key: "delete",
      title: "Remove Category",
      color: "#F44336",
    },
  ];

  const editOrDeactivateModal = (option, value, idx) => {
    console.log({ value });
    setCurrentId(value?.id);
    setCurrentCategory(value);
    if (option.key === "edit") {
      setEdit(true);
      openModal(true);
    }
    if (option.key === "delete") {
      handleRemoveCategory(value?.id);
    }
  };

  const handleSearch = (query, isEmpty) => {
    let result = categoriesAux.filter((n) =>
      n.name.toLowerCase().includes(query.toLowerCase())
    );
    if (searchRef === result.length) {
    } else {
      if (result.length <= 0) {
        setCategories([{}]);
      } else {
        if (isEmpty) {
          setCategories(categoriesAux);
        } else {
          setCategories(result);
        }
      }
    }
    setSearchRef(result.length);
  };

  const handleRemoveSubCategory = (item) => {
    const info = {
      title: translate("pages").settings.category.alerts.deleteSubCategory
        .question.title,
      text: translate("pages").settings.category.alerts.deleteSubCategory
        .question.subtitle,
      icon: "info",
      confirm: translate("pages").settings.category.alerts.deleteSubCategory
        .question.buttons.confirm,
      successTitle: translate("pages").settings.category.alerts
        .deleteSubCategory.success.title,
      successSubtitle: translate("pages").settings.category.alerts
        .deleteSubCategory.success.subtitle,
      errorTitle: translate("pages").settings.category.alerts.deleteSubCategory
        .error.title,
      errorSubtitle: translate("pages").settings.category.alerts
        .deleteSubCategory.error.subtitle,
      dispatchFunction: deleteSubCategories,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
      dispatchOnSuccess: getCategories,
    };
    AlertWithfunctions(info);
  };

  const handleRemoveSubCategoryCustom = (item) => {
    return dispatch(deleteSubCategories(item)).then(unwrapResult);
  };

  const handleRemoveCategory = (item) => {
    const info = {
      title: translate("pages").settings.category.alerts.deleteSubCategory
        .question.title,
      text: translate("pages").settings.category.alerts.deleteSubCategory
        .question.subtitle,
      icon: "info",
      confirm: translate("pages").settings.category.alerts.deleteSubCategory
        .question.buttons.confirm,
      successTitle: translate("pages").settings.category.alerts
        .deleteSubCategory.success.title,
      successSubtitle: translate("pages").settings.category.alerts
        .deleteSubCategory.success.subtitle,
      errorTitle: translate("pages").settings.category.alerts.deleteSubCategory
        .error.title,
      errorSubtitle: translate("pages").settings.category.alerts
        .deleteSubCategory.error.subtitle,
      dispatchFunction: deleteCategories,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
      dispatchOnSuccess: getCategories,
    };
    AlertWithfunctions(info);
  };

  const columns = [
    {
      title: translate("pages").settings.category.table.headers.categories,
      dataIndex: "name",
      align: "left",
      width: 250,
    },

    {
      title: translate("pages").settings.category.table.headers.subCategories,
      dataIndex: "typename",
      align: "left",
      render: (item, data, idx) => {
        // dispatch(getSubCategories(data.id))
        //   .then(unwrapResult)
        //   .then((result) => {
        //     console.log(`the data on the category ${data.id}:`, result);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
        return (
          <Col justify="center" align="left">
            {categories.length > 0 && (
              <SubCategoryRender
                data={item}
                categories={data.typename}
                state={categories}
                setState={setCategories}
                key={"typename"}
                index={idx}
                onClick={() => {
                  openModal(true);
                  setCurrentId(data?.id);
                  // console.log({ data });
                  setCurrentCategory(data);
                  setEdit(true);
                }}
                handleRemoveSubCategory={handleRemoveSubCategory}
              />
            )}
          </Col>
        );
      },
      width: 550,
      height: 80,
    },
    {
      title: translate("pages").settings.category.table.headers.icon,
      dataIndex: "image",
      className: "colorpicker-departmernt",
      align: "center",
      render: (item, data, idx) => (
        <Col justify="center" align="center">
          {data.image ? (
            <div
              style={{
                width: 34,
                height: 34,
                borderRadius: 200,
                padding: 5,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: "auto",

                backgroundColor: "#E5E5E5",
                textAlign: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <MemoizedAvatar
                size={20}
                className={null}
                src={cloudFrontUrl(data.image)}
                // logo={data?.image}
                noPlaceHolder
                // customPlaceHolder="https://image.flaticon.com/icons/png/128/727/727399.png"
              />
              {/* <IconPickerItem icon={data?.image} size={17} /> */}
            </div>
          ) : (
            <RoundedButton
              onClick={() => {
                openModal(true);
                setCurrentId(data?.id);
                setCurrentCategory(data);
                setEdit(true);
              }}
              style={{
                backgroundColor: "#E5E5E5",
                borderColor: "#E5E5E5",
                color: " grey",
              }}
              // className="rounded-button"
            />
          )}
        </Col>
      ),
      width: 30,
    },
    {
      title: "",
      dataIndex: "status",
      render: (item, list, idx) => (
        <DepartmentListDropDown
          options={options}
          title="..."
          onClick={(option) => editOrDeactivateModal(option, list, idx)}
        />
      ),
      width: 40,
    },
  ];

  return (
    <div className="department-container">
      <div className="department-top-menu-container">
        <div className="department-top-right-container">
          <Title style={{}} text={translate("pages").settings.category.title} />
        </div>

        <div className="department-left-top-container">
          <span className="department-left-top-items">
            <SearchBar
              placeholder={
                translate("pages").settings.category.placeholders.search
              }
              handleSearch={handleSearch}
              className="department-list-searchbar"
            />
          </span>
          <span className="department-left-top-items">
            <PrimaryButton
              cssClassName="add-department-btn"
              onClick={() => openModal(true)}
              title={
                translate("pages").settings.category.placeholders.buttons.create
              }
            />
          </span>
        </div>
      </div>
      <div className="department-table-container">
        <Table
          columns={columns}
          items={categories}
          classes="table-normal-header department-list-table"
          showPagination={true}
          loading={loading}
        />
      </div>
      <Layout flex="auto" className="user-list-layout">
        <CreateCategoryForm
          state={categories}
          setState={setCategories}
          currentId={currentId}
          isEdit={isEdit}
          onClose={() => closeModal()}
          show={isModalOpen}
          currentCategory={currentCategory}
          handleRemoveSubCategory={handleRemoveSubCategoryCustom}
        />
        {/* <DepartmentDeactivateModal
          show={modal}
          translate={translate}
          closeModal={closeModal}
          onDeactivate={onDeactivate}
        /> */}
      </Layout>
    </div>
  );
};

export default Category;
