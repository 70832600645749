import { Cancel, Message, MessageSA } from "assets/svg/mobile";
import React, { useContext, useState, useEffect } from "react";
import { I18nContext } from "utils/i18n/locale";
import MobileLayout from "../index";
import { useHistory, useLocation } from "react-router-dom";
import { PrimaryButton } from "components/atoms/controls";
import { confirmSignUp, verifyRecoveryCode } from "api/amplifyAuthClientAPI";
import { Text } from "components/atoms/texts";
import "./validate.scss";
import { CodeInput } from "components/atoms/codeInput";
import { Alert } from "components/atoms/outputs";
import { DataInput } from "components/atoms/inputs";

const Validate = ({}) => {
  const { translate } = useContext(I18nContext);
  const history = useHistory();
  const historyLocation = useLocation();
  const [data, setData] = React.useState(["", "", "", "", "", ""]);
  const [step, setStep] = useState(0);

  const [state, setState] = useState({
    password: "",
  });
  const [password, setPassword] = useState(
    historyLocation?.state?.password ?? undefined
  );
  const [email, setEmail] = useState(
    historyLocation?.state?.email ?? undefined
  );
  const [processType, setProcessType] = useState(
    historyLocation?.state?.type ?? undefined
  );
  const [focus, setFocus] = React.useState(0);
  const [currentStyle, setCurrentStyle] = useState(null);

  useEffect(() => {
    const cs_json = JSON.parse(localStorage.getItem("custom_client"));
    if (cs_json) {
      setCurrentStyle(cs_json);
    }
  }, []);

  const formSubmit = () => {
    if (step === 0) {
      var localData = "";

      for (var i = 0; i < data.length; i++) {
        localData = localData + data[i];
      }

      if (processType === "1") {
        confirmSignUp(email, localData)
          .then((resp) => {
            if (resp.code == "CodeMismatchException") {
              Alert(
                "warning",
                `${translate("pages").login.messages.requirenewpassword} `
              );
            } else {
              Alert(
                "success",
                `${translate("pages").login.messages.registrationSuccess} `
              );

              currentStyle
                ? history.push(`/${currentStyle.route}/`)
                : history.push("/client");
            }
          })
          .catch((err) => {
            if (err.code === "NetworkError") {
              Alert("error", err.message);
            } else {
              Alert("error", translate("pages").login.messages.loginerror);
            }
            console.error(err);
          })
          .finally(() => {});
      } else {
        setStep(1);
      }
    } else if (step === 1) {
      var localData = "";

      for (var i = 0; i < data.length; i++) {
        localData = localData + data[i];
      }

      verifyRecoveryCode(email, localData, state.password)
        .then((resp) => {
          setStep(2);
        })
        .catch((err) => {
          if (err.code === "NetworkError") {
            Alert("error", err.message);
          } else {
            Alert("error", translate("pages").login.messages.loginerror);
          }
          console.error(err);
        })
        .finally(() => {});
    } else if (step === 2) {
      currentStyle
        ? history.push(`/${currentStyle.route}/`)
        : history.push("/client/");
    }
  };
  const validate = (text, index) => {
    console.log("validate", text);
    text = text.replace(/[^\d.-]/g, "");

    if (!text || text.length === 0) {
      const newData = data.filter((e) => true);
      newData[focus] = "";
      setData(newData);
      return;
    }

    if (text.length !== 1) {
      setFocus(focus + 1);
      const newData = data.filter((e) => true);
      newData[focus] = data[focus] === text[0] ? text[1] : text[0];

      setData(newData);
      return;
    }

    if (text) {
      const newData = data.filter((e) => true);
      newData[focus] = text;
      setData(newData);
    }
  };

  const passwordChecks = [
    {
      reg: /^[a-zA-Z0-9!@#$%^&*]{8,}$/,
      message: translate("pages").mobile.Signup.regExsErrors.maxChars,
    },
    {
      reg: /^(?=.*[a-z])(?=.*[A-Z])/,
      message: translate("pages").mobile.Signup.regExsErrors.upperAndLower,
    },
    {
      reg: /^(?=.*[-+_!@#$%^&*.,?])/,
      message: translate("pages").mobile.Signup.regExsErrors.specialSymbol,
    },
  ];

  const passwordChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      ["password"]: e.target.value,
    }));
  };
  const showSuccess = (
    <div>
      <Text
        className="title center"
        text={translate("pages").mobile.validate.resetSuccess}
      />
    </div>
  );
  const showNewPassword = (
    <div className="info-forgot">
      <Text
        className="title center"
        text={translate("pages").mobile.validate.newPassword}
      />

      <DataInput
        placeholder={translate("pages").mobile.Signup.placeholders.password}
        name="password"
        customClass="login-input"
        showWithText={true}
        isPassword={true}
        checks={passwordChecks}
        suffix={translate("pages").mobile.Signup.passwordSuffix}
        onChange={passwordChange}
      />
    </div>
  );

  const showConfirm = (
    <div>
      <div className="info-forgot">
        <Text
          className="title center"
          text={translate("pages").mobile.validate.mainText}
        />

        <Text
          className="tagline center-content"
          text={translate("pages").mobile.validate.subText}
        />
      </div>

      <CodeInput
        validate={validate}
        data={data}
        setData={setData}
        focus={focus}
        setFocus={setFocus}
      />
    </div>
  );

  return (
    <MobileLayout mode={!currentStyle}>
      <div className="mobile-component-container">
        <div className="box">
          <Cancel
            onClick={(e) => {
              currentStyle
                ? history.push(`/${currentStyle.route}/`)
                : history.push("/client/");
            }}
            className="cancel"
          />
          {currentStyle ? (
            <MessageSA className="center lock" />
          ) : (
            <Message className="center lock" />
          )}
          {step == 0 ? showConfirm : step == 1 ? showNewPassword : showSuccess}
          <PrimaryButton
            title={
              step == 0
                ? translate("pages").mobile.validate.button
                : step == 1
                ? translate("pages").mobile.validate.button
                : translate("pages").mobile.validate.buttonLogin
            }
            className="client-login-button"
            onClick={() => formSubmit()}
            style={currentStyle ? currentStyle.buttons : {}}
          />

          <div className="center-content">
            {/* <Text
              style={{
                color: "rgb(92, 92, 92)",
              }}
              text={translate("pages").mobile.validate.resendCode}
              className=" margin-twenty buttomtext"
            />

            <Text
              text={translate("pages").mobile.validate.resendCodeMessage}
              type="link"
              className="forgot-password margin-twenty"
            />

*/}
          </div>
        </div>
      </div>
    </MobileLayout>
  );
};

export default Validate;
