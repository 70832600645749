import React, {useContext} from 'react';
import {Text} from 'components/atoms/texts';
import "./EventsFeed.scss";
import {I18nContext} from "utils/i18n/locale";
import NoNews from "assets/img/noNews.png";
import EventsRow from "components/client/components/molecules/EventsRow";

const EventsFeed = (props) => {
    const {translate} = useContext(I18nContext);
    const {onClick, data} = props;

    return (
      <div className="newsfeed-container">
        {data && data.length > 0 ? (
          data.map((item, index) => (
              <div key={index}>
                <EventsRow item={item} index={index} onClick={onClick} />
              </div>
          ))
        ) : (
          <div className="no-news">
            <img className="image" src={NoNews} alt="no-news" />
            <Text className="title-textss" text={translate('client').pages.home.NO_NEWS} />
          </div>
        )}
      </div>
    );

}

export default EventsFeed;
