import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Row, Button } from "antd";
import { DataInput } from "components/atoms/inputs";
import { PrimaryButton } from "components/atoms/controls";
import { I18nContext } from "utils/i18n/locale";
import BottomDrawer from "components/atoms/bottom-drawer";
import { AttachImageIcon } from "components/atoms/icons";
import "./CreateFlagForm.scss";
import { Select } from "components/atoms/select";
import { SourceImage } from "components/atoms/media";
import { createFlag, getFlags, getTypenames } from "redux/slices/flags";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import AutoPlaces from "components/molecules/AutoPlaces";
import { SwalAlerts } from "components/atoms/outputs";
import {
  getCurrentOrganizationId,
  getCurrentProjectId,
  // makeId,
  // resizeImage,
} from "utils/common";
import { uploadDrag } from "components/atoms/media";

const getFileDetails = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = (e) => {
      resolve({
        file,
        base64: reader.result,
      });
    };

    reader.onerror = (e) => {
      reject(e);
    };

    reader.readAsDataURL(file);
  });
};


const ActionFooter = ({ onSubmit, onAttachImages, loadingAction }) => {
  const fileRef = useRef(null);

  const onUpload = (e) => {
    // console.log(e);

    if (!e?.target?.files || e?.target?.files.length === 0) return;

    (async () => {
      let images = [];
      for (const file of e?.target?.files) {
        images = [...images, await getFileDetails(file)];
      }
      onAttachImages(images);
    })().finally(() => {});
  };

  return (
    <Row justify="end" align="middle" gutter={2}>
      <Button
        onClick={() => fileRef.current.click()}
        className="attach-image-btn"
        shape="circle"
      >
        <AttachImageIcon />
      </Button>
      <input type="file" multiple onChange={onUpload} hidden ref={fileRef} />
      <PrimaryButton
        onClick={onSubmit}
        htmlType="submit"
        cssClassName="save-btn"
        title={"Save Flag"}
        loading={loadingAction}
      />
    </Row>
  );
};

const CreateFlagForm = ({ show, onClose }) => {
  const categories = useSelector((state) => state.flags.categories);
  const fetchedDepartments = useSelector((state) => state.flags.departments);
  const typenames = useSelector((state) => state.flags.typenames);
  const loadingAction = useSelector((state) => state.flags.loadingAction);
  const { translate } = useContext(I18nContext);
  const formRef = React.createRef();
  const [category, setCategory] = useState(null);
  const [address, setAddress] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [department, setDepartment] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [images, setImages] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    // dispatch(getDepartments(getCurrentProjectId()));
    if (fetchedDepartments.length > 0) {
      let dep = fetchedDepartments.map(({ name, id }) => {
        return { name: name, value: id };
      });
      setDepartments(dep);
    }
  }, [fetchedDepartments]);

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const onFinish = (data) => {
    let flagData = {
      flag: {
        ...data,
        address,
        latitude,
        longitude,
        organization_id: getCurrentOrganizationId(),
        project_id: getCurrentProjectId(),
        efields: [{}],
        zipcode: 75221,
        source: "web_app",
        typename_id: data.typename,
        // reported_by: 1,
      },
    };

    if (images.length > 0) {
      uploadDrag("reports", images[0].file)
        .then((img) => {
          flagData.flag.images = [img.key];
          // console.log("flagData:", flagData);
          // handleSaveEdited(values);
        })
        .catch((err) => {
          console.error(err, "error al guardar la imagen");
        })
        .finally(() => {
          dispatch(createFlag(flagData))
            .then(unwrapResult)
            .then(() => {
              SwalAlerts({
                title: translate("pages").flags.alerts.created.title,
                text: translate("pages").flags.alerts.created.subtitle,
                type: "success",
              });
              form.resetFields();
              onClose();
              dispatch(getFlags());
            })
            .catch((e) => {
              SwalAlerts({
                title: translate("pages").flags.alerts.error.title,
                text: translate("pages").flags.alerts.error.subtitle,
                type: "error",
              });
            });
        });
    } else {
      dispatch(createFlag(flagData))
        .then(unwrapResult)
        .then(() => {
          SwalAlerts({
            title: translate("pages").flags.alerts.created.title,
            text: translate("pages").flags.alerts.created.subtitle,
            type: "success",
          });
          form.resetFields();
          onClose();
          dispatch(getFlags());
        })
        .catch((e) => {
          SwalAlerts({
            title: translate("pages").flags.alerts.error.title,
            text: translate("pages").flags.alerts.error.subtitle,
            type: "error",
          });
        });
    }
  };

  const onImageAttached = (data) => {
    setImages([...data]);
  };

  const onRemoveImage = (index) => {
    let tempImages = [...images];
    tempImages.splice(index, 1);
    setImages(tempImages);
  };

  return (
    <BottomDrawer
      footer={
        <ActionFooter
          onSubmit={() => formRef.current.submit()}
          loadingAction={loadingAction}
          onAttachImages={onImageAttached}
        />
      }
      onClose={onClose}
      open={show}
      title="Create new Flag"
    >
      <Form
        form={form}
        className="flag-form"
        ref={formRef}
        onValuesChange={({ category, department }) => {
          setCategory(category);
          setDepartment(department);
        }}
        onFinish={onFinish}
        onChange={(e) => {
          // console.log(e);
          setCategory(formRef.current.getFieldValue("category"));
        }}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="category"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={translate("pages").flags.placeholder.category}
            title={translate("pages").flags.title.category}
            options={categories}
            disabled={categories.length <= 0}
            onSelect={(value) => dispatch(getTypenames(value))}
          />
        </Form.Item>
        <Form.Item
          disabled={!category?.length <= 0}
          name="typename"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={
              !category
                ? translate("pages").flags.placeholder.typenameDisabled
                : translate("pages").flags.placeholder.typename
            }
            title={translate("pages").flags.title.typename}
            options={typenames}
            disabled={typenames.length <= 0}
            loading={category && typenames.length === 0}
          />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DataInput
            type="textarea"
            rows={4}
            placeholder={translate("pages").flags.placeholder.description}
            title={translate("pages").flags.title.description}
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="resident"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DataInput
            placeholder={translate("pages").flags.placeholder.resident}
            title={translate("pages").flags.title.resident}
            allowClear
          />
        </Form.Item>
        <AutoPlaces
          setLatitude={setLatitude}
          setLongitude={setLongitude}
          setAddress={(address) => setAddress(address)}
        />
        {/* TODO: verudy if citizen is required  */}
        {/* <Form.Item
          name="citizen"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DataInput
            placeholder={translate("pages").flags.placeholder.citizen}
            title={translate("pages").flags.title.citizen}
            allowClear
          />
        </Form.Item> */}
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DataInput
            placeholder={translate("pages").flags.placeholder.email}
            title={translate("pages").flags.title.email}
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="department"
          // rules={[
          //   {
          //     // required: true,
          //   },
          // ]}
        >
          <Select
            placeholder={translate("pages").flags.placeholder.department}
            title={translate("pages").flags.title.department}
            options={departments}
          />
        </Form.Item>
      </Form>
      <div className="attached-images-container">
        {images.map((image, index) => (
          <div className="attached-image-container" key={`${index}-image`}>
            <SourceImage
              onRemove={() => onRemoveImage(index)}
              width={140}
              alt={`${index}-image`}
              height={90}
              src={image.base64}
            />
          </div>
        ))}
      </div>
    </BottomDrawer>
  );
};
export default CreateFlagForm;
