import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import newsAPI from "api/newsAPI";
import { getCurrentProjectId } from "utils/common";

const awsErrorSerialized = {
  error: "ASW Error",
};

const awsError = (resp) => {
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};


export const getClientNews = createAsyncThunk(
  "news/getClientNews",
  async (id,{rejectWithValue}) => {
    try {
      console.log("id",id)
      const news = await newsAPI.getNews(id);
      awsError(news);
      if (news.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return news.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getNews = createAsyncThunk(
  "news/getNews",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const news = await newsAPI.getNews(project_id);
      awsError(news);
      if (news.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return news.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createNews = createAsyncThunk(
  "news/createNews",
  async (item, { rejectWithValue }) => {
    try {
      const news = await newsAPI.createNews(item);
      awsError(news);
      return news.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updateNews = createAsyncThunk(
  "news/updateNews",
  async (item, { rejectWithValue }) => {
    try {
      let editNews = { ...item };
      delete editNews.id;
      const news = await newsAPI.updateNews(editNews, item.id);
      awsError(news);
      return news.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const publishNews = createAsyncThunk(
  "news/publishNews",
  async (item, { rejectWithValue }) => {
    try {
      const news = await newsAPI.publishNews(item);
      awsError(news);
      return news.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const duplicateNews = createAsyncThunk(
  "news/duplicateNews",
  async (item, { rejectWithValue }) => {
    try {
      const news = await newsAPI.duplicateNews(item);
      awsError(news);
      return news.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const deleteNews = createAsyncThunk(
  "news/deleteNews",
  async (id, { rejectWithValue }) => {
    try {
      const news = await newsAPI.deleteNews(id);
      awsError(news);
      return news.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

const initialState = {
  news: [],
  error: null,
  loading: true,
  newsUpdated: false,
  newsCreated: false,
  loadingAction: false,
};

const news = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: {
    //Get News
    [getNews.pending]: (state, action) => {
      console.info("getNews pending");
      state.loading = true;
      state.error = null;
      state.newsUpdated = false;
      state.newsCreated = false;
    },
    [getNews.fulfilled]: (state, action) => {
      console.info("getNews fullfilled");
      state.loading = false;
      state.error = null;
      state.newsUpdated = false;
      state.news = action.payload.body;
    },
    [getNews.rejected]: (state, action) => {
      console.error("getNews rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.news = [];
    },

    //Get News
    [getClientNews.pending]: (state, action) => {
      console.info("getClientNews pending");
      state.loading = true;
      state.error = null;
      state.newsUpdated = false;
      state.newsCreated = false;
    },
    [getClientNews.fulfilled]: (state, action) => {
      console.info("getClientNews fullfilled",action.payload);
      state.loading = false;
      state.error = null;
      state.newsUpdated = false;
      state.news = action.payload.body;
    },
    [getClientNews.rejected]: (state, action) => {
      console.error("getClientNews rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.news = [];
    },

    //Create News
    [createNews.pending]: (state, action) => {
      state.loadingAction = true;
      state.error = null;
      state.newsUpdated = false;
      state.newsCreated = false;
      console.info("createNews pending");
    },
    [createNews.fulfilled]: (state, action) => {
      state.loadingAction = false;
      state.error = null;
      state.newsCreated = true;
      console.info("createNews fullfilled");
    },
    [createNews.rejected]: (state, action) => {
      console.error("createNews rejected =>", action.payload);
      state.loadingAction = false;
      state.error = action.payload.error;
    },

    //Update News
    [updateNews.pending]: (state, action) => {
      state.loadingAction = true;
      state.error = null;
      state.newsUpdated = false;
      state.newsCreated = false;
      console.info("updateNews pending");
    },
    [updateNews.fulfilled]: (state, action) => {
      state.loadingAction = false;
      state.error = null;
      state.newsCreated = true;
      console.info("updatenews fullfilled");
    },
    [updateNews.rejected]: (state, action) => {
      console.error("updatenews rejected =>", action.payload);
      state.newsCreated = false;
      state.newsUpdated = false;
      state.loadingAction = false;
      state.error = action.payload.error;
    },

    //Delete News
    [deleteNews.pending]: (state, action) => {
      state.loadingAction = true;
      state.error = null;
      state.newsUpdated = false;
      console.info("DeleteNews started");
    },
    [deleteNews.fulfilled]: (state, action) => {
      state.loadingAction = false;
      state.error = null;
      state.newsUpdated = true;
      console.info("DeleteNews success", state.newsUpdated);
    },
    [deleteNews.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = null;
      state.newsUpdated = false;
      state.error = action.payload.error;
      console.error("DeleteNews error=> ", action.payload.error);
    },

    //Publish News
    [publishNews.pending]: (state, action) => {
      state.loadingAction = true;
      state.error = null;
      state.newsUpdated = false;
      console.info("PublishNews pending");
    },
    [publishNews.fulfilled]: (state, action) => {
      state.loadingAction = false;
      state.error = null;
      state.newsUpdated = true;
      console.info("PublishNews fullfiled", state.newsUpdated);
    },
    [publishNews.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload;
      state.newsUpdated = false;
      console.error("PublishNews rejected=> ", action.payload);
    },

    //Duplicate News
    [duplicateNews.pending]: (state, action) => {
      state.loadingAction = true;
      state.error = null;
      state.newsUpdated = false;
      console.info("DuplicateNews pending");
    },
    [duplicateNews.fulfilled]: (state, action) => {
      state.loadingAction = false;
      state.error = null;
      state.newsUpdated = true;
      console.info("DuplicateNews fullfilled", state.newsUpdated);
    },
    [duplicateNews.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = null;
      state.newsUpdated = false;
      state.error = action.payload;
      console.error("DuplicateNews rejected=> ", action.payload);
    },
  },
});

// export const {

// } = news.actions;
export default news.reducer;
