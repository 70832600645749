import React, {
  useContext,
  lazy,
  Suspense,
  useState,
  useEffect,
  useCallback,
} from "react";
import { PlusOutlined } from "@ant-design/icons";
import FAB from "components/atoms/fab";
import Toast from "./../../components/atoms/toast";
import { SpiningPage, SuccessCheckIcon } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import { Text } from "components/atoms/texts";
import ComposeEmailForm from "components/organisms/ComposeEmailForm";
import ComposeNotificationForm from "components/organisms/ComposeNotificationForm";
import Email from "./Email";
import Notification from "./Notification";
import "./communication.scss";
import noMail from "assets/img/illustrations/no-emails-yet.svg";
import noNotification from "assets/img/illustrations/no-notification.svg";
import noSubmissions from "assets/img/illustrations/no-submissions.svg";
import Submissions from "pages/Communication/Submissions";
import { TYPE } from "components/organisms/SubmissionsTable";
import { withRouter } from "react-router-dom";
import ParentTabsMenu from "components/atoms/parent-tab";
import { useDispatch, useSelector } from "react-redux";
import { getReceivedEmails } from "redux/slices/communication/emails";

const EmptyComponentTemplate = lazy(() =>
  import("components/templates/EmptyComponentTemplate")
);

const Communication = ({ history }) => {
  const { translate } = useContext(I18nContext);
  const [isModalOpen, openModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [activeTab, setActiveTab] = useState("email");
  const [emails, setEmails] = useState([]);
  const [markAll, setMarkAll] = useState(false);

  const dispatch = useDispatch();
  const receivedEmails = useSelector((state) => state.emails.receivedEmails);
  const loading = useSelector((state) => state.emails.loading);

  useEffect(() => {
    dispatch(getReceivedEmails());
  }, []);

  useEffect(() => {
    setEmails(receivedEmails);
  }, [receivedEmails]);
  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    }
  }, [showToast]);

  const handleMark = () => {
    setMarkAll(!markAll);
  };

  const changeMarkAll = (status) => {
    setMarkAll(status);
  };

  const onButtonClick = () => {
    openModal(true);
  };

  const submissions = [
    {
      id: "1",
      type: TYPE.ALL,
      key: "1",
      name: "Property Maintenance",
      category: "Overgrown Yard/Trash",
      created: new Date().toDateString(),
      status: "Open",
      location: "62 Harvard Street",
    },
    {
      id: "2",
      type: TYPE.ALL,
      key: "2",
      name: "Sidewalk",
      category: "Overgrown Yard/Trash",
      created: new Date().toDateString(),
      status: "Open",
      location: "62 Harvard Street",
    },
  ];

  const emptyState = (image, title, subtitle, buttonText) => (
    <EmptyComponentTemplate
      image={image}
      title={title}
      subtitle={subtitle}
      buttonText={buttonText}
      onButtonClick={onButtonClick}
    />
  );

  const tabOptions = [
    {
      title: `${translate("pages").communication.email.title} (${
        emails.filter((email) => !email.archive).length
      })`,
      key: "email",
      children: emails.length ? (
        <Email
          parentHistory={history}
          emails={emails.filter((email) => !email.archive)}
          starredMails={emails.filter(
            (email) => email.starred && !email.archive
          )}
          archiveMails={emails.filter((email) => email.archive)}
          handleMark={handleMark}
          changeMarkAll={changeMarkAll}
          markAll={markAll}
        />
      ) : (
        emptyState(
          noMail,
          translate("pages").communication.email.empty.title,
          translate("pages").communication.email.empty.description,
          translate("pages").communication.email.empty.buttons.composeEmail
        )
      ),
    },
    // {
    //   title: translate("pages").communication.notification.title,
    //   key: "notifications",
    //   children: emailData.length ? (
    //     <Notification
    //       parentHistory={history}
    //       emails={emailData}
    //       starredMails={starredEmails}
    //       archiveMails={archiveEmails}
    //     />
    //   ) : (
    //     emptyState(
    //       noNotification,
    //       translate("pages").communication.notification.empty.title,
    //       translate("pages").communication.notification.empty.description,
    //       translate("pages").communication.notification.empty.buttons
    //         .composeNotification
    //     )
    //   ),
    // },
    // {
    //   title: translate("pages").communication.submission.title,
    //   key: "submissions",
    //   children: submissions.length ? (
    //     <Submissions parentHistory={history} data={submissions} />
    //   ) : (
    //     emptyState(
    //       noSubmissions,
    //       translate("pages").communication.submission.empty.title,
    //       translate("pages").communication.submission.empty.description,
    //       translate("pages").communication.submission.empty.button
    //     )
    //   ),
    // },
  ];

  const handleSendEmail = () => {
    setShowToast(true);
    openModal(false);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Suspense fallback={<SpiningPage opacity={1} />}>
      {loading ? (
        <SpiningPage opacity={1} />
      ) : (
        <div className="communications-root">
          <Text
            className="communication-main-title"
            text={translate("pages").communication.mainTitle}
          />
          <ParentTabsMenu
            baseRoute="home/communications"
            options={tabOptions}
            onChange={onTabChange}
          />
          {showToast && (
            <Toast
              text="Message sent successfully"
              positionStyle={{ position: "absolute", right: 102, bottom: 35 }}
              icon={<SuccessCheckIcon />}
              responseText="OK"
              textColor="toast-success"
              close={handleCloseToast}
            />
          )}
          <FAB
            onClick={() => openModal(!isModalOpen)}
            icon={<PlusOutlined />}
          />
        </div>
      )}

      {isModalOpen && activeTab === "email" && (
        <ComposeEmailForm
          onClose={() => openModal(false)}
          show={true}
          sendEmail={handleSendEmail}
          messageBoxId="compose-mail-box"
        />
      )}
      {isModalOpen && activeTab === "notifications" && (
        <ComposeNotificationForm
          onClose={() => openModal(false)}
          show={true}
          sendEmail={handleSendEmail}
          messageBoxId="compose-notification-box"
        />
      )}
    </Suspense>
  );
};
export default withRouter(Communication);
