import React from "react";
import {Text} from 'components/atoms/texts';
import "./links.scss";

const Links = (props) => {
    const {text, selected, onClick} = props;

    return (
      <Text className={`links ${selected}`} onClick={onClick} text={text}/>
    );
};

export default Links;
