import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import flagsAPI from "api/flagsAPI";
import { getCurrentProjectId } from "utils/common";

const awsErrorSerialized = {
  error: "AWS Error",
};

const awsError = (resp) => {
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};

export const getFlags = createAsyncThunk(
  "flags/getFlags",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const flags = await flagsAPI.getFlags(project_id);
      awsError(flags);
      if (flags.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return flags.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getFlag = createAsyncThunk(
  "flags/getFlag",
  async (id, { rejectWithValue }) => {
    try {
      const flag = await flagsAPI.getFlag(id);
      awsError(flag);
      return flag.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const getFlagNoLoad = createAsyncThunk(
  "flags/getFlagNoLoad",
  async (id, { rejectWithValue }) => {
    try {
      const flag = await flagsAPI.getFlag(id);
      awsError(flag);
      return flag.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createFlag = createAsyncThunk(
  "flags/createFlag",
  async (item, { rejectWithValue }) => {
    try {
      const flags = await flagsAPI.createFlag(item);
      awsError(flags);
      return flags.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getFlagCategories = createAsyncThunk(
  "flags/getFlagCategories",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const categories = await flagsAPI.getFlagCategories(project_id);
      awsError(categories);
      if (categories.data.statusCode === 404) {
        return [];
      } else {
        return categories.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getTypenames = createAsyncThunk(
  "flags/getTypenames",
  async (id, { rejectWithValue }) => {
    try {
      const typenames = await flagsAPI.getTypenames(id);
      awsError(typenames);
      return typenames.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updateFlag = createAsyncThunk(
  "flags/updateFlag",
  async (item, { rejectWithValue }) => {
    try {
      let updateFlag = { ...item };
      delete updateFlag.id;
      console.log(updateFlag, "updateFlag");
      const flags = await flagsAPI.updateFlag(item.id, updateFlag);
      awsError(flags);
      return flags.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const approveFlag = createAsyncThunk(
  "flags/approveFlag",
  async (item, { rejectWithValue }) => {
    try {
      let approveFlag = { ...item };
      delete approveFlag.id;
      const flags = await flagsAPI.approveFlag(item.id, approveFlag);
      awsError(flags);
      return flags.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const showFlagOnFeed = createAsyncThunk(
  "flags/showFlagOnFeed",
  async (item, { rejectWithValue }) => {
    try {
      const flag = await flagsAPI.showFlagOnFeed(item);
      awsError(flag);
      return flag.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const rejectFlag = createAsyncThunk(
  "flags/rejectFlag",
  async (item, { rejectWithValue }) => {
    try {
      let rejectFlag = { ...item };
      delete rejectFlag.id;
      const flags = await flagsAPI.rejectFlag(item.id, rejectFlag);
      awsError(flags);
      return flags.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getDepartments = createAsyncThunk(
  "flags/getDepartments",
  async (id, { rejectWithValue }) => {
    try {
      const departments = await flagsAPI.getDepartments(id);
      awsError(departments);
      return departments.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getAssignedUsers = createAsyncThunk(
  "flags/getAssignedUsers",
  async (id, { rejectWithValue }) => {
    try {
      const users = await flagsAPI.getAssignedUsers(id);
      awsError(users);
      if (users.data.statusCode === 500) {
        return [];
      } else {
        return users.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

const initialState = {
  flags: {},
  error: null,
  loading: true,
  loadingAction: false,
  flagCreated: false,
  loadingError: false,
  flag: null,
  categories: [],
  typenames: [],
  flagApproved: undefined,
  flagRejected: undefined,
  flagUpdated: undefined,
  flagApproveFailed: false,
  flagRejectFailed: false,
  departments: [],
  assignedUsers: [],
};

const distributeFlags = (flags) => {
  const item = {
    pending: [],
    open: [],
    rejected: [],
    resolved: [],
    blocked: [],
    closed: [],
  };
  if (flags !== undefined)
    for (let flag of flags) {
      switch (flag.status) {
        case "rejected":
          item.rejected.push(flag);
          break;
        case "open":
          item.open.push(flag);
          break;
        case "resolved":
          item.resolved.push(flag);
          break;
        case "blocked":
          item.blocked.push(flag);
          break;
        case "closed":
          item.closed.push(flag);
          break;
        default:
          item.pending.push(flag);
      }
    }
  return item;
};

const convertToOptions = (values) => {
  const options = [];
  for (let value of values) {
    options.push({ name: value.name, value: value.id });
  }
  return options;
};

const flags = createSlice({
  name: "flags",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Flags
    [getFlags.pending]: (state) => {
      console.log("getFlags pending");
      state.loading = true;
      state.error = null;
      state.loadingError = false;
    },
    [getFlags.fulfilled]: (state, action) => {
      console.log("getFlags fullfilled");
      state.loading = false;
      state.error = null;
      state.flags = distributeFlags(action.payload.body);
    },
    [getFlags.rejected]: (state, action) => {
      console.log("getFlags rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.flags = {};
      state.loadingError = true;
    },
    // Create Flags
    [createFlag.pending]: (state) => {
      state.loadingAction = true;
      state.error = null;
      state.flagCreated = false;
      console.log("createFlag pending");
    },
    [createFlag.fulfilled]: (state) => {
      state.loadingAction = false;
      state.error = null;
      state.flagCreated = true;
      console.log("createFlag fullfilled");
    },
    [createFlag.rejected]: (state, action) => {
      console.log("createFlag rejected", action);
      state.loadingAction = false;
      state.error = action.payload.error;
    },
    // Get Single Flag by Id
    [getFlag.pending]: (state) => {
      console.log("getFlag pending =>");
      state.loading = true;
      state.error = null;
      state.loadingError = false;
    },
    [getFlag.fulfilled]: (state, action) => {
      console.log("getFlag fulfilled =>", action);
      state.loading = false;
      state.error = null;
      state.flag = action.payload.body;
      state.loadingError = false;
    },
    [getFlag.rejected]: (state, action) => {
      console.log("getFlag rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.flag = null;
      state.loadingError = true;
    },
    // Get Single Flag by Id no loader
    [getFlagNoLoad.pending]: (state) => {
      console.log("getFlag pending =>");
      // state.loading = true;
      state.error = null;
      // state.loadingError = false;
    },
    [getFlagNoLoad.fulfilled]: (state, action) => {
      console.log("getFlag fulfilled =>", action);
      // state.loading = false;
      state.error = null;
      state.flag = action.payload.body;
      // state.loadingError = false;
    },
    [getFlagNoLoad.rejected]: (state, action) => {
      console.log("getFlag rejected =>", action.payload);
      // state.loading = false;
      state.error = action.payload;
      state.flag = null;
      // state.loadingError = true;
    },
    // Get Flag Categories
    [getFlagCategories.pending]: (state) => {
      console.log("getFlagCategories pending =>");
    },
    [getFlagCategories.fulfilled]: (state, action) => {
      console.log("getFlagCategories fulfilled =>");
      if (action.payload.body.length > 0) {
        state.categories = convertToOptions(action.payload.body);
      } else {
        state.categories = [];
      }
    },
    [getFlagCategories.rejected]: (state, action) => {
      console.log("getFlagCategories rejected", action.payload);
    },
    // Get Flag Typename
    [getTypenames.pending]: (state) => {
      console.log("getTypenames pending =>");
    },
    [getTypenames.fulfilled]: (state, action) => {
      console.log("getTypenames fulfilled =>");
      state.typenames = convertToOptions(action.payload.body);
    },
    [getTypenames.rejected]: (state, action) => {
      console.log("getTypenames rejected", action.payload);
    },
    // Update Flag
    [updateFlag.pending]: (state) => {
      console.log("updateFlag pending");
      state.loadingAction = true;
      state.flagUpdated = undefined;
      state.error = null;
    },
    [updateFlag.fulfilled]: (state, action) => {
      console.log("updateFlag fulfilled");
      state.loadingAction = false;
      state.flagUpdated = true;
      state.error = null;
    },
    [updateFlag.rejected]: (state, action) => {
      console.log("updateFlag rejected");
      state.loadingAction = false;
      state.flagUpdated = false;
      state.error = action.payload;
    },
    // Approve Flag
    [approveFlag.pending]: (state) => {
      console.log("approveFlag pending");
      state.loadingAction = true;
      state.flagApproved = undefined;
      state.flagRejected = false;
      state.flagApproveFailed = false;
      state.flagRejectFailed = false;
      state.error = null;
    },
    [approveFlag.fulfilled]: (state, action) => {
      console.log("approveFlag fulfilled");
      state.loadingAction = false;
      state.flagApproved = true;
      state.error = null;
    },
    [approveFlag.rejected]: (state, action) => {
      console.log("approvedFlag rejected");
      state.loadingAction = false;
      state.flagApproved = false;
      state.flagApproveFailed = true;
      state.error = action.payload;
    },

    // Show on feed Flag
    [showFlagOnFeed.pending]: (state) => {
      console.log("showFlagOnFeed pending");
      state.loadingAction = true;
      state.flagApproved = undefined;
      state.flagRejected = false;
      state.flagApproveFailed = false;
      state.flagRejectFailed = false;
      state.error = null;
    },
    [showFlagOnFeed.fulfilled]: (state, action) => {
      console.log("showFlagOnFeed fulfilled");
      state.loadingAction = false;
      state.flagApproved = true;
      state.error = null;
    },
    [showFlagOnFeed.rejected]: (state, action) => {
      console.log("showFlagOnFeed rejected");
      state.loadingAction = false;
      state.flagApproved = false;
      state.flagApproveFailed = true;
      state.error = action.payload;
    },

    // Reject Flag
    [rejectFlag.pending]: (state) => {
      console.log("rejectFlag pending");
      state.loadingAction = true;
      state.flagApproved = false;
      state.flagRejected = undefined;
      state.flagApproveFailed = false;
      state.flagRejectFailed = false;
      state.error = null;
    },
    [rejectFlag.fulfilled]: (state, action) => {
      console.log("rejectedFlag fulfilled");
      state.loadingAction = false;
      state.flagRejected = true;
      state.error = null;
    },
    [rejectFlag.rejected]: (state, action) => {
      console.log("rejectedFlag rejected");
      state.loadingAction = false;
      state.flagRejected = false;
      state.error = action.payload;
      state.flagRejectFailed = true;
    },
    // Get Departments
    [getDepartments.pending]: (state) => {
      console.log("getDepartments pending =>");
    },
    [getDepartments.fulfilled]: (state, action) => {
      console.log("getDepartments fulfilled =>");
      state.departments = action.payload.body;
    },
    [getDepartments.rejected]: (state, action) => {
      console.log("getDepartments rejected", action.payload);
    },
    // Get Assigned Users
    [getAssignedUsers.pending]: (state) => {
      console.log("getAssignedUsers pending =>");
    },
    [getAssignedUsers.fulfilled]: (state, action) => {
      console.log("getAssignedUsers fulfilled =>");
      state.assignedUsers = action.payload.body;
    },
    [getAssignedUsers.rejected]: (state, action) => {
      console.log("getAssignedUsers rejected", action.payload);
    },
  },
});

export default flags.reducer;
