import React, { useContext, useEffect, useState } from "react";
import { Form, Row, Layout, Button } from "antd";
import { DataInput } from "components/atoms/inputs";
import { I18nContext } from "utils/i18n/locale";
import "./ProfileForm.scss";
import { Title } from "components/atoms/texts";
import ProfileInfo from "components/molecules/ProfileInfo";
import { emailRegex,  phoneRegex } from "utils/common";
import { useSelector } from "react-redux";
import { SpiningPage } from "components/atoms/icons";
// import { Storage } from "aws-amplify";
import { uploadDrag } from "components/atoms/media";
import { DeleteMedia } from "api/amplifyAuthAPI";

const ProfileForm = ({ user, roles, onUpdate, citizen }) => {
  const onFinishFailed = console.log;

  const { translate } = useContext(I18nContext);
  const profileUpdated = useSelector((state) => state.profile.profileUpdated);

  const [currentProfile, setCurrentProfile] = useState(user);
  const [form] = Form.useForm();


  useEffect(() => {
    setCurrentProfile(user);
  }, [user]);


  return (
    <Layout align="center" className="profile-form">
      {currentProfile !== null &&
      currentProfile !== undefined &&
      currentProfile !== [] ? (
        <>
          <Title
            style={{ width: "100%", textAlign: "start" }}
            level={3}
            text={translate("pages").profile.title}
          />
          <Row justify="center">
            <ProfileInfo
              citizen={citizen}
              // onClickUpload={(image) => console.log("image:", image)}
              onClickUpload={(image) => {
                uploadDrag("profilePics", image?.file)
                  .then((data) => {
                    const updateProfileInfo = {
                      id: currentProfile?.id,
                      image: data?.key,
                    };
                    DeleteMedia(user.image);
                    onUpdate(updateProfileInfo);
                  })
                  .catch((err) => {
                    console.error(err, "error al guardar la imagen");
                  });
              }}
              name={`${currentProfile?.name} ${currentProfile?.last_name}`}
              bages={2}
              flags={2}
              image={currentProfile?.image}
              level={2}
              points={2}
            />
          </Row>
          <Row>
            <Form
              form={form}
              initialValues={currentProfile}
              name="update-profile"
              onFinish={onUpdate}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    message: translate("pages").profile.errors.firstName,
                    required: true,
                  },
                ]}
              >
                <DataInput
                  disabled={citizen ? true : false}
                  placeholder={
                    translate("pages").profile.placeholders.firstname
                  }
                />
              </Form.Item>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    message: translate("pages").profile.errors.lastName,
                    required: true,
                  },
                ]}
              >
                <DataInput
                  disabled={citizen ? true : false}
                  placeholder={translate("pages").profile.placeholders.lastname}
                />
              </Form.Item>
              {/* <Form.Item
            name="role"
            rules={[
              {
                message: translate("pages").profile.errors.role,
                required: true,
              },
            ]}
          >
            <DataInput
              disabled={true}
              placeholder={translate("pages").profile.placeholders.role}
            />
          </Form.Item> */}
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: translate("pages").profile.errors.email,
                    pattern: emailRegex,
                  },
                ]}
              >
                <DataInput
                  disabled={true}
                  placeholder={translate("pages").profile.placeholders.email}
                />
              </Form.Item>
              {/* <Form.Item
                name="username"
                rules={[
                  {
                    message: translate("pages").profile.errors.username,
                  },
                ]}
              >
                <DataInput
                  placeholder={translate("pages").profile.placeholders.username}
                />
              </Form.Item> */}
              <Form.Item
                name="phone"
                rules={[
                  {
                    // required: true,
                    message: translate("pages").profile.errors.phone,
                    pattern: phoneRegex,
                  },
                ]}
              >
                <DataInput
                  disabled={true}
                  placeholder={
                    translate("pages").profile.placeholders.phonenumber
                  }
                />
              </Form.Item>
              {/* <Form.Item
                name="zip"
                rules={[
                  {
                    message: translate("pages").profile.errors.zip,
                  },
                ]}
              >
                <DataInput
                  placeholder={translate("pages").profile.placeholders.zip}
                />
              </Form.Item> */}
              <Form.Item>
                <Button
                  hidden={citizen ? true : false}
                  loading={profileUpdated}
                  className="submit-button"
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size="large"
                  style={{ marginTop: 30 }}
                >
                  {translate("pages").profile.buttons.update}
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </>
      ) : (
        <SpiningPage opacity={1} />
      )}
    </Layout>
  );
};
export default ProfileForm;
