import { Modal } from "antd";
import React from "react";
import "./CreateEventGroupModal.scss";

const CreateEventGroupModal = ({
  isVisible,
  handleCancel,
  title,
  children,
  className,
}) => {
  return (
    <Modal
      visible={isVisible}
      onCancel={handleCancel}
      title={title}
      footer={null}
      className={`${className} survey-modal`}
      centered
    >
      {children}
    </Modal>
  );
};

export default CreateEventGroupModal;
