import NotificationDetail from "client/pages/Notification/NotificationDetail";

const prefix = "/client/notification";
const prefixLaunch = "/launchsa/notification";

const routes = [
  {
    path: `${prefix}/:id`,
    component: NotificationDetail,
    exact: true,
  },
  {
    path: `${prefixLaunch}/:id`,
    component: NotificationDetail,
    exact: true,
  },
];

export default routes;
