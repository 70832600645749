//Modules
import LoginRoute from "./modules/loginRT";
import RecoverRoute from "./modules/recoverRT";
import ChangeRoute from "./modules/changeRT";
import VerifyCodeRoute from "./modules/verifyCodeRT";
import verifyRoute from "./modules/verifyRT";
import signUpRoute from "./modules/signupRT";
import HomeRoute from "./modules/homeRT";
import FeedRoute from "./modules/feedRT";
import EventsRoute from "./modules/eventsRT";
import NotificationsRoute from "./modules/notificationsRT";
import AnalyticRoute from "./modules/analyticRT";
import SurveyRoute from "./modules/surveysRT";
import defaultRoute from "./modules/defaultRT";
import CommunicationRoute from "./modules/communicationRT";
import SettingsRoute from "./modules/settingsRT";
import FlagsRoute from "./modules/flagRT";
import WidgetRoute from "./modules/widgetRT";

//Layouts
import MainLayout from "layouts/MainLayout";
import LoginLayout from "layouts/LoginLayout";
import ErrorPageLayout from "layouts/ErrorPageLayout";
import WidgetLayout from "layouts/WidgetLayout";

//Routes
const listRoutes = [
  // {
  //   layout: LoginLayout,
  //   routes: [
  //     // ...LoginRoute,
  //     ...verifyRoute,
  //     ...RecoverRoute,
  //     ...ChangeRoute,
  //     ...VerifyCodeRoute,
  //     ...signUpRoute,
  //   ],
  // },
  {
    layout: WidgetLayout,
    routes: [...WidgetRoute],
  },
  // {
  //   layout: MainLayout,
  //   routes: [
  //     ...HomeRoute,
  //     ...FeedRoute,
  //     ...AnalyticRoute,
  //     ...SurveyRoute,
  //     ...SettingsRoute,
  //     ...EventsRoute,
  //     ...CommunicationRoute,
  //     ...NotificationsRoute,
  //     ...FlagsRoute,
  //     ...KnowledgeBaseRoute,
  //   ],
  // },

  // {
  //   layout: ErrorPageLayout,
  //   routes: [...defaultRoute],
  // }
];
export default listRoutes;
