import React, { useContext } from "react";
import { Text } from "components/atoms/texts";
import "./SubmissionUserInfoForm.scss";
import { I18nContext } from "utils/i18n/locale";
import { FieldsetInput } from "components/atoms/inputs";

const SubmissionUserInfoForm = () => {
  const { translate } = useContext(I18nContext);
  return (
    <div className="submission-user-info-form-root">
      <Text
        text={translate("pages").communication.submission.details.informationTitle}
        className="header-text"
      />
      <FieldsetInput
        legend={translate("pages").communication.submission.details.email}
        className="header-input"
      />
      <FieldsetInput
        legend={translate("pages").communication.submission.details.age}
        className="header-input"
      />
      <FieldsetInput
        legend={translate("pages").communication.submission.details.postcode}
        className="header-input"
      />
    </div>
  );
};

export default SubmissionUserInfoForm;
