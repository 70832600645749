import WidgetReport from "pages/Widgets/Report";
// import EventsManagement from "pages/Events/EventManagement";

const prefix = "/widgets";

const routes = [
  {
    path: `${prefix}/reports/:organization_id/:project_id`,
    component: WidgetReport,
    exact: true,
  },
];

export default routes;
