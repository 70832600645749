import React, { useContext } from "react";
import Moment from "moment";
import { CalendarIcon } from "components/atoms/icons";
import { ClockIcon } from "components/atoms/icons";
import { CommentIcon } from "components/atoms/icons";
import { NavigatorIcon } from "components/atoms/icons";
import { CrossIcon } from "components/atoms/icons";
import { Text } from "components/atoms/texts";
import { useHistory } from "react-router";
import Map from "components/atoms/map";
import "./notification-detail.scss";
import { PrimaryButton } from "components/atoms/controls";
import { AddReportIcon } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";

const NotificationDetail = () => {
  const history = useHistory();
  const { notification } = history.location;
  console.log("notification",notification)
  const { translate } = useContext(I18nContext);
  return (
    <div className="notification-detail">
      <div className="notification-detail-header">
        <div className="close-notification-modal">
          <CrossIcon
            color="#141414"
            opacity="1"
            onClick={() => history.goBack()}
          />
        </div>
        <div className="notification-detail-title">
          {notification?.type_id == 0 ? (
            <Text
              text={translate("client").pages.notifications.title.upcomingEvent}
            />
          ) : (
            <Text text={notification?.subject} />
          )}
        </div>
      </div>
      <div className="client-notification-detail-content">
        {notification?.type_id == 1 ? (
          <>
            <div className="notification-breakdown">
              <Text
                text={translate("client").pages.notifications.title.about}
                className="text-title"
              />
              <Text
                text={notification.body}
                className="text-description"
              />
            </div>
            <div className="notification-breakdown">
              <Text
                text={
                  translate("client").pages.notifications.title
                    .notificationTitle
                }
                className="text-title"
              />
              <div className="aligned-centered">
                <CommentIcon color="rgba(20, 20, 20, 0.54)" />
                <Text
                  text={notification.subject}
                  className="text-description with-icon"
                />
              </div>
            </div>
            <div className="notification-breakdown">
              <Text
                text={
                  translate("client").pages.notifications.title.notificationDate
                }
                className="text-title"
              />
              <div className="aligned-centered">
                <CalendarIcon />
                <Text
                  text={Moment(notification.sent_at).format("MMMM DD, YYYY")}
                  className="text-description with-icon"
                />
              </div>
            </div>
            <div className="notification-breakdown">
              <Text
                text={
                  translate("client").pages.notifications.title.notificationTime
                }
                className="text-title"
              />
              <div className="aligned-centered">
                <ClockIcon color="rgba(20, 20, 20, 0.54)" />
                <Text
                  text={Moment(notification.sent_at).format("h.mm a")}
                  className="text-description with-icon"
                />
              </div>
            </div>
            <div className="notification-breakdown">
              <Text
                text={
                  translate("client").pages.notifications.title
                    .notificationLocation
                }
                className="text-title"
              />
              <div className="aligned-centered">
                <Text
                  text={notification.location}
                  className="text-description location-address"
                />
                <div className="location-marker">
                  <NavigatorIcon />
                </div>
              </div>
              <Map
                height="120px"
                mapTypeControl={false}
                className="notification-map"
              />
            </div>

            <div className="event-button">
              <PrimaryButton
                title={translate("client").pages.notifications.title.saveEvents}
              />
            </div>
          </>
        ) : (
          <div className="add-report-notification">
            <AddReportIcon />
            <Text
              text={translate("client").pages.notifications.title.postReport}
              className="report-title"
            />
            <Text
              className="report-text"
              text={translate("client").pages.notifications.title.reportText}
            />
            <div className="feedback-button">
              <PrimaryButton
                title={
                  translate("client").pages.notifications.title.leaveFeedback
                }
              />
            </div>
            <div className="report-cancel">
              <Text
                text={translate("client").pages.notifications.title.cancel}
                className="cancel-text"
              />
            </div>
          </div>
        )}
      </div>

    </div>
  );
};
export default NotificationDetail;
