import React, { useContext } from "react";
import "./SubmissionDetailsInfo.scss";
import { I18nContext } from "utils/i18n/locale";
import {Text} from "components/atoms/texts";
import {Col, Row} from "antd";

const SubmissionDetailsInfo = () => {
  const { translate } = useContext(I18nContext);

  const DATA = {
    name: "Something you want to change",
    date: "5 May 2020",
    category: "Water Services",
    location: "Dolarosa, San Antonio TX, 7829, USA",
    description: "The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.\n" +
      "\n" +
      "The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.\n" +
      "\n" +
      "Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum."
  }

  return (
    <div className="submission-user-info-root">
      <img className='feature-image' src="https://picsum.photos/seed/picsum/500/200" alt={DATA.name} />
      <Col align="start">
        <div className='title-container'>
          <Text className='title' text={DATA.name}/>
        </div>
        <Row className='info-table'>
          <Text className='name' text={`${translate("pages").communication.submission.details.date}:`}/>
          <Text className='value' text={DATA.date}/>
        </Row>
        <Row className='info-table'>
          <Text className='name' text={`${translate("pages").communication.submission.details.category}:`}/>
          <Text className='value' text={DATA.category}/>
        </Row>
        <Row className='info-table'>
          <Text className='name' text={`${translate("pages").communication.submission.details.location}:`}/>
          <Text className='value' text={DATA.location}/>
        </Row>
        <Text className='description' text={DATA.description}/>
      </Col>
    </div>
  );
};

export default SubmissionDetailsInfo;
