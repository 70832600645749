import colors from "./colors.json";

const style = {
  fontFamily: "IBMPlex-Reg",
  color: colors.subtitle_color,
};

export const title_style = {
  fontFamily: "IBMPlex-Bold",
  fontWeight: "bold",
  color: colors.title_color,
  fontSize: 24,
  lineHeight: "27px",
  letterSpacing: 0,
  alignSelf: "center",
  justifyContent: "center",
  marginBottom: 22,
  textAlign: "center",
};
export const create_element_title = {
  fontFamily: "IBMPlex-Bold",
  color: colors.title_color,
  fontSize: 28,
  // lineHeight: "27px",
  letterSpacing: 0,
  alignSelf: "left",
  justifyContent: "left",
  textAlign: "left",
};

export const subtitle_style = {
  ...style,
  fontStyle: "normal",
  fontSize: 16,
  marginBottom: 22,
  letterSpacing: 0.15,
  fontWeight: "normal",
  lineHeight: "24px",
  textAlign: "center",
};

export const text_style = {
  ...style,
  fontSize: 16,
  fontWeight: 300,
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  marginBottom: 10,
  whiteSpace: 'pre-wrap'
};
