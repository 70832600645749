import ProfileTemplate from "components/templates/Profile";
import React from "react";
import { useLocation } from "react-router";

const UserProfile = () => {
  const historyLocation = useLocation();
  return (
    <ProfileTemplate
      citizen={historyLocation?.state?.citizen}
      hideFollower={true}
      hideActivity={true}
      title="User Profile"
    />
  );
};

export default UserProfile;
