import React from "react";
import { DataInput } from "components/atoms/inputs";
import "./ComposeEmailFooter.scss";

const ComposeEmailFooter = ({
  children,
  cssClass,
  id,
  inputValue,
  onChange,
  ...props
}) => {
  return (
    <div className={cssClass} {...props}>
      <div className="compose-reply">
        {props.hasReplyBox ? (
          <div className="compose-input">
            <DataInput
              placeholder={props.placeholder}
              title={props.title}
              id={id}
              value={inputValue}
              onChange={onChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="compose-action-menu"> {children}</div>
    </div>
  );
};

export default ComposeEmailFooter;
