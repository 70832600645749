import {Modal as AntModal} from "antd";
import React from "react";
import "./modal.scss";

export const TYPE_DEFAULT = "default";
export const TYPE_SUCCESS = "success";
export const TYPE_DANGER = "danger";

const showCloseIconClass = show => !show ? "modal-main-no-close-icon" : ""

const Modal = ({
	title,
	children,
	loading,
	className = "",
	type=TYPE_SUCCESS,
	showCloseIcon = true,
	...props
}) => <AntModal
	title={title}
	confirmLoading={loading}
	className={`modal-main modal-main-${type} ${className} ${showCloseIconClass(showCloseIcon)}`}
  {...props}
>
	{children}
</AntModal>

export default Modal;
