import React, { useContext } from "react";
import { Text } from "components/atoms/texts";
import { PrimaryButton } from "components/atoms/controls";
import { Col, Divider, Row } from "antd";
import "./KnowledgeHeader.scss";
import { I18nContext } from "utils/i18n/locale";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearKnowledgePostData } from "redux/slices/knowledge";
const KnowledgeHeader = ({ title, hideButton }) => {
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="knowledge-header">
      <Row>
        <Col xs={24} lg={12}>
          <Text text={title} className="knowledge-header-text" />
        </Col>
        <Col xs={24} lg={12} className="knowledge-header-button">
          {!hideButton ? (
            <PrimaryButton
              onClick={() => {
                dispatch(clearKnowledgePostData());
                history.push("/home/knowledge/post-editor");
              }}
              icon={<PlusOutlined />}
              title={translate("pages").knowledge.title.addNewPost}
            />
          ) : null}
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default KnowledgeHeader;
