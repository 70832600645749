import React, {useState, useContext} from 'react';
import "./ViewEventContainer.scss";
import { Text } from 'components/atoms/texts';
import {CommentIcon, CalendarIcon, NavigatorIcon} from "components/atoms/icons";
import { ClockCircleOutlined } from '@ant-design/icons';
import Map from 'components/atoms/map';
import {PrimaryButton} from "components/atoms/controls";
import GreenMapPin from "assets/img/illustrations/mappin-green.svg";
import { Modal } from 'antd';
import GreenTick from "assets/img/icons/green-tick.svg";
import { Image } from "antd";
import { I18nContext } from "utils/i18n/locale";
import { useHistory } from 'react-router-dom';
import moment from "moment";

const ProgramViewContainer = (props) => {

  const [saveModal, setSaveModal] = useState(false)
  const {program} = props;
  const { translate } = useContext(I18nContext)
  const {push} = useHistory()


  return (
    <div className="view-events-container">
      <Modal
        visible={saveModal}
        centered
        getContainer={"#modalMount"}
        mask={true}
        onCancel={() => setSaveModal(false)}
        className="success-modal"
        closable={false}
        cancelButtonProps={false}
      >
        <Image src={GreenTick} preview={false} />
        <Text text={translate("client").pages.events.viewEvents.saveTopic} className="title-message" />
        <p className="message">
          {translate("client").pages.events.viewEvents.saveMessage}
        </p>
      </Modal>
      <div id="modalMount" className="modal-container" />
      <div className="about-container">
        <Text text={translate("client").pages.events.viewEvents.about} className="title" />
        <Text
          text={program? program.description:""}
          className="desc"
        />
      </div>



      <div className="location-container">
        <Text text={translate("client").pages.events.viewEvents.location} className="title" />
        <div className="location-desc">
          <Text text={program?.address} className="text" />
        </div>
        <Map
          height="180px"
          mapTypeControl={false}
          className="notification-map"
          customPin={GreenMapPin}
          location={{lat:program?.latitude,lng:program?.longitude}}
        />
       
      </div>
      <div style={{paddingTop:20}}></div>

      <div className="about-container" onClick={()=>{
          window.open(program? program.website_link : "", '_blank');
      }}>
        <Text text={"Website"} className="title" />
        <Text
        text={program? program.website_link : "" }
          className="desc"
        />
      </div>

    {/*  <div className="location-container">
        <Text text={translate("client").pages.events.viewEvents.location} className="title" />
        <div className="location-desc">
          <Text text="Oct 28, 2020" className="text" />
          <NavigatorIcon />
        </div>
        <Map
          height="90px"
          mapTypeControl={false}
          className="notification-map"
          customPin={GreenMapPin}
        />
        <PrimaryButton
          title={translate("client").pages.events.viewEvents.saveButton}
          onClick={() => saveEvent()}
        />
      </div>*/}
    </div>
  )
}

export default ProgramViewContainer;
