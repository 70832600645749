import React, { useState } from "react";

export const QuestionContext = React.createContext();
const SurveyEditorContext = ({ children }) => {
  const [questions, setQuestions] = useState([]);
  const [surveyAction, setSurveyAction] = useState({
    action: "",
    surveyId: null,
  });
  const [newSurveyTitle, setSurveyTitle] = useState("");
  const [surveyId, setSurveyId] = useState(null);

  const [csvHeaders, setCsvHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const randomGen = (range) => Math.floor(Math.random() * range);
  const responses = new Array(71).fill({}).map((ele) => ({
    email: "sams@cityflag.com",
    completionTime: `${randomGen(60)} min`,
    completionDate: "12/4/2020",
    id: randomGen(1000),
  }));
  return (
    <QuestionContext.Provider
      value={{
        questions,
        setQuestions,
        responses,
        newSurveyTitle,
        setSurveyTitle,
        surveyAction,
        setSurveyAction,
        surveyId,
        setSurveyId,
        csvHeaders,
        setCsvHeaders,
        csvData,
        setCsvData,
      }}
    >
      {children}
    </QuestionContext.Provider>
  );
};

export default SurveyEditorContext;
