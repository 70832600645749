import api from "config/axios/axios.api.config";

const prefix = "notifications";

// const getNotifications = () => api.get(`${prefix}`);

const getNotifications = (id) => api.get(`${prefix}/projects/${id}`);

const deleteNotification = (id) => api.put(`${prefix}/${id}/delete`, { id });

const publishNotification = (id) => api.put(`${prefix}/${id}/publish`, { id });

const unpublishNotification = (id) =>
    api.put(`${prefix}/${id}/unpublish`, { id });

const duplicateNotification = (data) =>
    api.postJson(`${prefix}/${data.id}/duplicate`, data);

const updateNotification = (data, id) => api.put(`${prefix}/${id}`, data);

// const createNotification = (data) => api.postJson(`${prefix}`, data);
const createNotification = (data, id) =>
    api.postJson(`${prefix}/projects/${id}`, data);

// eslint-disable-next-line
export default {
    getNotifications,
    deleteNotification,
    publishNotification,
    unpublishNotification,
    duplicateNotification,
    createNotification,
    updateNotification,
};
