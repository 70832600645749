import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
} from "react";
import { SpiningPage } from "components/atoms/icons";
import { Alert } from "components/atoms/outputs";
import { I18nContext } from "utils/i18n/locale";
import { sendRecoveryMail } from "api/amplifyAuthAPI";
const AuthTemplate = lazy(() => import("components/templates/AuthTemplate"));

const renderLoader = () => <SpiningPage opacity={1} />;

const Recover = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { translate } = useContext(I18nContext);
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const changeStatusLoading = (statusBoolean) => {
    setLoading(statusBoolean);
  };

  const handleRecover = ({ email }) => {
    changeStatusLoading(true);

    sendRecoveryMail(email)
      .then(() => {
        setSuccess(true);
        Alert(
          "success",
          `${translate("pages").recover.messages.recoversuccess}`
        );
      })
      .catch((err) => {
        console.log(err, "->err");
        if (err.code === "NotAuthorizedException") {
          Alert("warning", translate("pages").recover.messages.in_change);
        } else if (err.code === "LimitExceededException") {
          Alert("warning", translate("pages").recover.messages.limit_exceeded);
        } else {
          Alert("error", translate("pages").recover.messages.recovererror);
        }
        changeStatusLoading(false);
      })
      .finally(() => {
        if (isMounted.current) {
          changeStatusLoading(false);
        }
      });
  };
  return (
    <Suspense fallback={renderLoader()}>
      <AuthTemplate
        handleRecover={handleRecover}
        loading={loading}
        success={success}
        recover
      />
    </Suspense>
  );
};
export default Recover;
