import api from "config/axios/axios.api.config";

const prefix = "knowledge";

const getKnowledgePosts = (id) => api.get(`${prefix}/projects/${id}`);

const getKnowledgePost = (id) => api.get(`${prefix}/${id}`);

// const getKnowledgeCategories = (id) =>
//   api.get(`projects/${id}/${prefix}/category`);

const getKnowledgeCategories = (id) =>
  api.get(`${prefix}/projects/${id}/categories_and_knowledge`);

const getFAQS = (id) => api.get(`${prefix}/${id}/faq`);

const createKnowledgePost = (id, data) =>
  api.postJson(`${prefix}/projects/${id}/`, data);

const updateKnowledgePost = (id, data) => api.put(`${prefix}/${id}`, data);

const deleteKnowledgePost = (id) => api.put(`${prefix}/${id}/delete`, { id });

const createKnowledgeCategory = (id, data) =>
  api.postJson(`${prefix}/projects/${id}/category`, data);

export default {
  getKnowledgePosts,
  getKnowledgePost,
  getKnowledgeCategories,
  createKnowledgePost,
  createKnowledgeCategory,
  updateKnowledgePost,
  deleteKnowledgePost,
  getFAQS
};
