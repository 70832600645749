import React from "react";
import { Select as SelectAnt, AutoComplete, Row, Col } from "antd";
import { text_style } from "config/styles/text";
import "./select.scss";
import { Text } from "components/atoms/texts";
const { Option } = AutoComplete;

export const Select = ({
  style = {},
  options = [],
  title,
  className = "",
  gutter = 0,
  floatPlaceholder,
  ...props
}) => {
  return (
    <Row
      className={`select-input ${className}`}
      gutter={gutter}
      align="middle"
      style={style}
      justify="space-between"
    >
      {title && (
        <Col xl={6} sm={24}>
          <Text text={title} className="select-text" />
        </Col>
      )}
      <Col {...(title ? { xl: 18, xs: 24 } : { xs: 24 })}>
        {floatPlaceholder ? (
          <div className="float-label">
            <label className={`label-float`}>{floatPlaceholder}</label>
            <SelectAnt
              {...props}
              style={{ ...text_style, ...style }}
              getPopupContainer={(trigger) => trigger.parentNode}
              className="select-item"
            >
              {options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.name}
                </Option>
              ))}
            </SelectAnt>
          </div>
        ) : (
          <SelectAnt
            {...props}
            style={{ ...text_style, ...style }}
            getPopupContainer={(trigger) => trigger.parentNode}
            className="select-item"
          >
            {options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.name}
              </Option>
            ))}
          </SelectAnt>
        )}
      </Col>
    </Row>
  );
};
