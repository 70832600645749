import React from "react";
import { Text } from "components/atoms/texts";
import "./UserProjectItem.scss";
import { cloudFrontUrl } from "utils/common";

const Image = React.memo(function Image({ src, classname }) {
  return <img src={src} className={classname} alt="loading..." />;
});

const UserProjectItem = (props) => {
  const { title, city, updatedAt, imageSource, onClick } = props;
  return (
    <div className="UPI-item-container" onClick={onClick}>
      <div className="UPI-image-container">
        <Image
          src={
            imageSource
              ? cloudFrontUrl(imageSource)
              : "https://jpassport.asia/static/image-not-available.png"
          }
          classname="UPI-image"
        />
      </div>

      <div className="item-content">
        <Text text={title} className="UPI-title" />
        <Text text={city} className="UPI-city" />
        <Text text={updatedAt} className="UPI-updatedAt" />
      </div>
    </div>
  );
};

export default UserProjectItem;
