import React, { useContext, useState, useEffect } from "react";
import { Col, Layout, Row } from "antd";
import { PrimaryButton } from "components/atoms/controls";
import { CreateElementInput } from "components/atoms/inputs";
import { Text } from "components/atoms/texts";
import CreateSurveyPopup from "components/molecules/CreateSurveyPopup";
import SurveyHeader from "components/molecules/SurveyHeader";
import SurveyTemplatesContainer from "components/organisms/SurveyTemplatesContainer";
import { I18nContext } from "utils/i18n/locale";
import "./survey-gallery.scss";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSurvey } from "redux/slices/surveys";
import { QuestionContext } from "context/SurveyEditorContext";
import { SpinLoader } from "components/atoms/icons";
import { Alert } from "components/atoms/outputs";
import { unwrapResult } from "@reduxjs/toolkit";
import { getCurrentOrganizationId, getCurrentProjectId } from "utils/common";

const SurveyGallery = () => {
  const { translate } = useContext(I18nContext);
  const [isVisible, setIsVisible] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState("");
  const [surveyCreating, setSurveyCreating] = useState(false);
  const dispatch = useDispatch();
  const {
    surveys: { loading, error, surveyCreated, survey },
  } = useSelector((state) => state);
  const history = useHistory();
  const { setSurveyTitle: setTitle, setSurveyId } = useContext(QuestionContext);

  const handleSurveyCreate = () => {
    setSurveyCreating(true);
    dispatch(
      createSurvey({
        title: surveyTitle,
        description: "description",
        image: "survey.png",
        project_id: getCurrentProjectId(),
        organization_id: getCurrentOrganizationId(),
      })
    )
      .then(unwrapResult)
      .catch((err) => {
        <Alert type="error" title={err} />;
      });
  };

  useEffect(() => {
    if (surveyCreated && surveyCreating) {
      setSurveyCreating(false);
      history.push({
        pathname: `/home/surveys/editor/builder/${survey.id}`,
        state: { surveyTitle, surveyId: survey.id },
      });
    }
  }, [surveyCreated, error]);

  return (
    <Layout className="gallery-container">
      <SurveyHeader title={translate("pages").survey.title.gallery} />
      <Row>
        <Col span={4}>
          <PrimaryButton
            title={translate("pages").survey.title.fromScratch}
            style={{ width: "unset", textTransform: "uppercase" }}
            onClick={() => setIsVisible(true)}
          />
        </Col>
      </Row>
      {/* TODO:Add functionallity to survey gallery */}
      {/* <Text
        text={translate("pages").survey.title.recommendText}
        className="gallery-tag"
      />
      <SurveyTemplatesContainer /> */}
      <CreateSurveyPopup
        isVisible={isVisible}
        title={translate("pages").survey.title.createSurvey}
        className="modal"
        handleCancel={() => setIsVisible(false)}
      >
        <CreateElementInput
          className="modal-input"
          label={translate("pages").survey.placeholder.surveyLabel}
          placeholder={translate("pages").survey.placeholder.createSurvey}
          onChange={(e) => setSurveyTitle(e.target.value)}
        />

        <div className="modal-button create-survey-button">
          <PrimaryButton
            title={
              loading && surveyCreating ? (
                <SpinLoader color="#fff" opacity={1} fontSize={20} />
              ) : (
                translate("pages").survey.title.continue
              )
            }
            onClick={handleSurveyCreate}
            disabled={loading && surveyCreating ? true : false}
          />

          {/* <Link to={{ pathname: "/surveys/builder", surveyTitle }}>
          </Link> */}
        </div>
      </CreateSurveyPopup>
    </Layout>
  );
};

export default SurveyGallery;
