import React, { useContext, useEffect, useState,useMemo} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./communication.scss";

import { I18nContext } from "utils/i18n/locale";
import CommunicationFeed from "client/components/organisms/CommunicationFeed";
import { BackArrowMobile } from "assets/svg/mobile";
import { DotMenu } from "assets/svg/mobile";
import { getClientReceivedEmails,getClientArchivedEmails } from "redux/slices/communication/emails";
import { Text } from "components/atoms/texts";
import { Menu, Dropdown, Button, Space } from "antd";

import { useHistory } from "react-router";
import SearchBar from "components/atoms/search";
import { distanceFromHash } from "jimp";

const Communication = (props) => {
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const messagesFetched = useSelector((state) => state.emails.receivedEmails);
  const archivedMessagesFetched = useSelector((state) => state.emails.archivedEmails);

  const [messages, setMessages] = useState(messagesFetched);
  const [archivedMessages, setArchivedMessages] = useState(archivedMessagesFetched);
  const [messagesAux, setMessagesAux] = useState(messagesFetched);
  const [currentPageResponses, setCurrentPageResponses] = useState([]);

  const history = useHistory();
  const [currentStyle, setCurrentStyle] = useState(null);
  const [search, setSearch] = useState('');

  const [searchRef, setSearchRef] = useState(0);

  const handleSearch = (query, isEmpty) => {
    let result = messagesAux.filter(
      (n) =>
        n.mail.subject?.toLowerCase().includes(query.toLowerCase()) 
    );
    if (searchRef === result.length) {
    } else {
      if (result.length <= 0) {
         setMessages([]);
      } else {
        if (isEmpty) {
          setMessages(messagesAux);
        } else {
          setMessages(result);
        }
      }
    }
    setSearchRef(result.length);
  };
  useEffect(() => {
    const cs_json = JSON.parse(localStorage.getItem("custom_client"));
    if (cs_json) {
      setCurrentStyle(cs_json);
    }
  }, []);

  useEffect(() => {
    if (currentStyle != null) {
      dispatch(getClientReceivedEmails(currentStyle.projectId));
      dispatch(getClientArchivedEmails(currentStyle.projectId))
    }
  }, [currentStyle]);

  useEffect(() => {
    console.log("messagesFetched", messagesFetched);
    setMessages(messagesFetched);
    setMessagesAux(messagesFetched)
  }, [messagesFetched]);

  useEffect(()=>{
    console.log("archivedMessagesFetched", archivedMessagesFetched);
    setArchivedMessages(archivedMessagesFetched);
  },[archivedMessagesFetched]);

  const handleResourcesClick = (index) => {
    history.push({
      pathname: "/launchsa/message/",
      state: { messageId: index },
    });
  };

  const handleArchivedClick = (index) =>{
    history.push({
      pathname: "/launchsa/message/",
      state: { messageId: index },
    });
  }

  const [state, setState] = useState({
    selectedMenu: 0,
    isFetching: false,
    isFetchingReports: false
  });

  const changeList = (type) => {
    if (type == 0) {
    

    setState((prevState) => ({
      ...prevState,
      ["selectedMenu"]: 0
    }));
    } else if (type == 1) {
      setState((prevState) => ({
        ...prevState,
        ["selectedMenu"]: 1
      }));
    } else if (type == 2) {
      setState((prevState) => ({
        ...prevState,
        ["selectedMenu"]: 2
      }));
    }
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <div onClick={() => changeList(0)}>Received</div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => changeList(1)}>Archived</div>
      </Menu.Item>
     
    </Menu>
  );

  return (
    <div id="client-home" className="client-home-container">
      <div className="mobile-header">
        <div className={"back-arrow"}>
          <BackArrowMobile onClick={() => history.goBack()} />
        </div>
        <Text
          className={"resources-title"}
          text={translate("pages").mobile.communication.header}
          style={{ lineHeight: "70px" }}
        />
        <div className={"delete-button"}>
          <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
            <DotMenu />
          </Dropdown>
        </div>
      </div>
      <div className="home-wrapper">
        <div className="search-container">
          <SearchBar
            placeholder={translate("pages").mobile.communication.search}
            handleSearch={(item) => handleSearch(item)}
          />
       
        </div>

        {state.selectedMenu === 0 ? (
          <div>
            <CommunicationFeed
              data={messages.filter((message) => !message.archive)}
              onClick={handleResourcesClick}
              isFetching={state.isFetching}
              type={0}
            />
          </div>
        ) : (

          <div>
            <CommunicationFeed
              data={archivedMessages}
              onClick={handleArchivedClick}
              isFetching={state.isFetching}
              type={0}
            />
          </div>
        )
        
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Communication);
