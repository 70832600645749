import React, { useContext, useEffect, useRef, useState } from "react";
import { Title, Text } from "components/atoms/texts";
import "./PostEditorHeader.scss";
import { EditIcon } from "components/atoms/icons";
import { Dropdown } from "components/atoms/controls";
import { CaretDownOutlined, LoadingOutlined } from "@ant-design/icons";
import Menu from "components/atoms/menu";
import { I18nContext } from "utils/i18n/locale";
import { DataInput } from "components/atoms/inputs";
import { useDispatch, useSelector } from "react-redux";
import {
  getKnowledgeCategories,
  handleKnowledgePostData,
} from "redux/slices/knowledge";

// const options = [{ key: "1", title: "Best Practices" }];

const PostEditorHeader = () => {
  const { translate } = useContext(I18nContext);
  const [selected, setSelected] = useState();
  const [editTitle, setEditTitle] = useState(false);
  const dispatch = useDispatch();
  const fetchedCategories = useSelector(
    (state) => state.knowledge.knowledgeCategories
  );
  const loading = useSelector((state) => state.knowledge.loading);
  const newPost = useSelector((state) => state.knowledge.newPost);
  const [categories, setCategories] = useState([]);
  const categoriesAux = [];
  const [value, setValue] = useState();

  const inputRef = useRef();

  useEffect(() => {
    for (const category of fetchedCategories) {
      categoriesAux.push({ key: category?.id, title: category?.title });
    }
    if (!newPost?.knowledge_category_id) {
      setSelected(categoriesAux[0]);
    }

    setCategories(categoriesAux);
  }, [fetchedCategories]);

  useEffect(() => {
    if (newPost.title) {
      setValue(newPost.title);
    }
    if (newPost?.knowledge_category_id) {
      const selected_temp = categories.filter(
        (category) => category.key === newPost.knowledge_category_id
      );
      setSelected(selected_temp[0]);
    }
  }, [newPost]);

  useEffect(() => {
    dispatch(getKnowledgeCategories());
  }, []);
  useEffect(() => {
    if (selected) {
      dispatch(
        handleKnowledgePostData({ knowledge_category_id: selected?.key })
      );
    }
  }, [selected]);

  useEffect(() => {
    if (value) {
      dispatch(handleKnowledgePostData({ title: value }));
    }
  }, [value]);

  const saveTitle = (e) => {
    e.preventDefault();
    setEditTitle(false);
  };

  const openEditInput = () => {
    setEditTitle(true);
  };

  useEffect(() => {
    if (editTitle) {
      inputRef?.current?.querySelector("input").focus();
    }
  }, [editTitle]);

  return (
    <div className="post-editor-header">
      <div ref={inputRef} className="editor-text" onClick={openEditInput}>
        {editTitle ? (
          <form onSubmit={saveTitle}>
            <input
              onBlur={() => setEditTitle(false)}
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="title-edit"
            />
          </form>
        ) : value ? (
          <Title text={newPost?.title} />
        ) : (
          <Title
            text={translate("pages").knowledge.placeholder.addTitle}
            opacity={0.3}
          />
        )}
        {!editTitle ? (
          <div className="edit-button" onClick={openEditInput}>
            <EditIcon />
          </div>
        ) : null}
      </div>
      <div className="editor-select-container">
        {loading ? (
          <LoadingOutlined />
        ) : (
          <>
            <Text
              text={`${translate("pages").knowledge.detail.selectCategory}:`}
            />

            <Dropdown
              className="select-category-dropdown"
              trigger={["click"]}
              disabled={newPost.id ? true : false}
              menu={
                <Menu
                  onClick={(option) => setSelected(option)}
                  options={categories}
                />
              }
            >
              <p className="dropdown-selected">
                {selected?.title} <CaretDownOutlined />
              </p>
            </Dropdown>
          </>
        )}
      </div>
    </div>
  );
};

export default PostEditorHeader;
