import React, { useState, useContext } from "react";
import { Col, Row } from "antd";
import { Text } from "components/atoms/texts";
import { Dropdown } from "components/atoms/controls";
import Menu from "components/atoms/menu";
import { EditIcon, TrashIcon } from "components/atoms/icons";
import "./KnowledgePostCard.scss";
import { I18nContext } from "utils/i18n/locale";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Modal from "components/organisms/KnowledgeDeleteModal";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  deleteKnowledgePost,
  handleKnowledgePostData,
  getKnowledgeCategories,
} from "redux/slices/knowledge";
import { AlertWithfunctions } from "components/atoms/outputs";
import { unwrapResult } from "@reduxjs/toolkit";

const KnowledgePostCard = ({
  id,
  content,
  title,
  description,
  lastUpdate,
  categoryId,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const { translate } = useContext(I18nContext);

  const options = [
    {
      icon: <EditIcon className="users-edit-icon" />,
      key: "edit",
      title: translate("pages").knowledge.detail.editPost,
    },
    {
      icon: <TrashIcon color="#F44336" margintop={1} />,
      key: "delete",
      title: translate("pages").knowledge.detail.deletePost,
      color: "#F44336",
    },
  ];

  const editOrDeletePost = (e) => {
    if (e.key === "delete") {
      deletePost();
      // setDeleteModal(true);
    } else {
      let reduxData = {
        id,
        title,
        content: JSON.parse(content),
        knowledge_category_id: categoryId,
      };
      dispatch(handleKnowledgePostData(reduxData));
      history.push(`/home/knowledge/${params?.category}/post-editor`);
    }
  };

  const deletePost = () => {
    const info = {
      title: translate("pages").knowledge.alerts.delete.question.title,
      text: translate("pages").knowledge.alerts.delete.question.subtitle,
      icon: "info",
      confirm:
        translate("pages").knowledge.alerts.delete.question.buttons.confirm,
      successTitle: translate("pages").knowledge.alerts.delete.success.title,
      successSubtitle:
        translate("pages").knowledge.alerts.delete.success.subtitle,
      errorTitle: translate("pages").knowledge.alerts.delete.error.title,
      errorSubtitle: translate("pages").knowledge.alerts.delete.error.subtitle,
      dispatchFunction: deleteKnowledgePost,
      dispatcher: dispatch,
      unwrapResult,
      values: id,
      dispatchOnSuccess: getKnowledgeCategories,
      // history: history,
      // goBack: true,
      // path: "/home/events/",
    };
    console.log(info);
    AlertWithfunctions(info);
    setDeleteModal(false);
    // dispatch(deleteKnowledgePost(params?.id))
    //   .then(unwrapResult)
    //   .then(() => {
    //     SwalAlerts({
    //       title: translate("pages").events.alerts.save.success.title,
    //       text: translate("pages").events.alerts.save.success.subtitle,
    //       type: "success",
    //     });
    //     history.goBack();
    //   })
    //   .catch(() => {
    //     SwalAlerts({
    //       title: translate("pages").events.alerts.save.error.title,
    //       text: translate("pages").events.alerts.save.error.subtitle,
    //       type: "error",
    //     });
    //   });
  };

  return (
    <>
      <Modal
        translate={translate}
        show={deleteModal}
        closeModal={() => setDeleteModal(false)}
        onOk={deletePost}
      />
      <Row
        onClick={() => history.push(location.pathname + `/${id}`)}
        className="knowledge-post-card-wrapper"
      >
        <Col xs={20} className="knowledge-post-text">
          <Text text={title} className="post-title" />
          <Text
            text={`${
              description
                ? description + "..."
                : translate("pages").knowledge.title.noDescription
            }`}
            className="post-description"
          />
          <Text
            text={`Updated ${moment(lastUpdate).fromNow()}`}
            className="post-last-update"
          />
        </Col>
        <Col xs={4} className="post-card-menu-wrapper">
          <Dropdown
            trigger={["click"]}
            menu={<Menu onClick={editOrDeletePost} options={options} />}
            placement={"bottomRight"}
          />
        </Col>
      </Row>
    </>
  );
};

export default KnowledgePostCard;
