import React, {useContext, useEffect, useState} from "react";
import {I18nContext} from "utils/i18n/locale";
import {HEADER_TRANSLUCENT, BadgeTable as Table, BADGE_COLORS} from "components/molecules/Table";
import "./SubmissionsTable.scss"
import {ArrowDown, EyeIcon, MenuThreeDotsIcon} from "components/atoms/icons";
import {Dropdown, Row} from "antd";
import ReactDOM from "react-dom";
import SubmissionsMenu from "components/molecules/SubmissionsMenu";
import Menu from "components/atoms/menu";
import Calendar from "components/molecules/Calendar";

export const TYPE = {
  ALL: "ALL",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
}

export const TYPE_COLORS = {
  ALL: BADGE_COLORS.GREEN,
  APPROVED: BADGE_COLORS.PURPLE,
  REJECTED: BADGE_COLORS.RED,
}

const CategoryDropdown = ({title, onOptionSelected}) => {
  const {translate} = useContext(I18nContext);

  const CATEGORIES = [
    {
      id: "1",
      name: "Graffiti",
    },
    {
      id: "2",
      name: "Parks",
    },
    {
      id: "3",
      name: "Property Maintenance",
    },
    {
      id: "4",
      name: "Streets/Traffic",
    },
    {
      id: "5",
      name: "Scooters/Dockless Vehicles",
    },
    {
      id: "6",
      name: "Sidewalks",
    },
    {
      id: "7",
      name: "Water Services",
    },
    {
      id: "8",
      name: "Public Works",
    },
    {
      id: "9",
      name: "Animals",
    },
    {
      id: "10",
      name: "Garbage/Dumping",
    }
  ]

  return <Dropdown
    className="category-table-dropdown"
    overlay={
      <Menu className="category-table-menu" onClick={onOptionSelected} options={[
        {
          title: translate("pages").communication.submission.table.showAll,
          key: "showAll",
        },
        ...(CATEGORIES.map(({id, name}) => ({
          key: id,
          title: name
        })))
      ]}/>
    }
    trigger={['click']}
    placement="bottomCenter" arrow>
    <Row justify='center' align='middle'>
      <div className='title'>{title}</div>
      <ArrowDown/>
    </Row>
  </Dropdown>
}

const DateDropdown = ({onApply, onCancel, title}) => {

  return <Dropdown
    className="category-table-dropdown"
    overlay={
      <Calendar handleApplyClick={onApply} handleCancelClick={onCancel}/>
    }
    trigger={['click']}
    placement="bottomCenter" arrow>
    <Row justify='center' align='middle'>
      <div className='title'>{title}</div>
      <ArrowDown/>
    </Row>
  </Dropdown>
}

const SubmissionsTable = ({data, type = TYPE.ALL, heading, onClick}) => {
  const {translate} = useContext(I18nContext);
  const [openDropdown, setOpenDropdown] = useState(null);

  const onOptionSelected = option => {
    setOpenDropdown(option)
  }

  const detectClickListener = e => {
    if (!ReactDOM.findDOMNode(this) || !(ReactDOM.findDOMNode(this)).contains(e.target)) {
      setOpenDropdown(null)
    }
  }

  useEffect(() => {
    document.addEventListener('click', detectClickListener, true)
  }, [])

  return (
    <Table
      className='submissions-table-root'
      pagination={false}
      options={{collapsable: true, badgeColor: TYPE_COLORS[type], addOption: translate("pages").flags.activity.addFlag}}
      headerType={HEADER_TRANSLUCENT}
      items={data}
      onClick={onClick}
      columns={[
        {
          title: `${heading} (${data.length})`,
          dataIndex: 'name',
          type: 'badge',
          key: 'badge',
          render: text => <b>{text}</b>
        },
        {
          title: <CategoryDropdown title={translate("pages").communication.submission.table.category}/>,
          dataIndex: 'category',
          key: 'category',
        },
        {
          title: translate("pages").communication.submission.table.location,
          dataIndex: 'location',
          key: 'location',
          canHide: true,
        },
        {
          title: <DateDropdown title={translate("pages").communication.submission.table.date}/>,
          dataIndex: 'created',
          key: 'created',
        },
        {
          title: translate("pages").communication.submission.table.action,
          key: 'action',
          dataIndex: 'id',
          type: 'action',
          render: (id) => {
            return <Row className='submission-actions-root' align='middle' justify='center'>
              <EyeIcon onClick={e => e.stopPropagation()} color='#808080'/>
              <Dropdown visible={id === openDropdown} overlay={<SubmissionsMenu onOptionSelected={onOptionSelected}/>}
                        trigger={['click']} placement="bottomLeft" arrow>
                <MenuThreeDotsIcon direction='horizontal' onClick={(e) => {
                  e.stopPropagation();
                  setOpenDropdown(id);
                }}/>
              </Dropdown>
            </Row>
          }
        },
      ]}/>
  );
};

export default SubmissionsTable;
