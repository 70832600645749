import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
} from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { SpiningPage } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import { Row, Col, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AlertWithfunctions, SwalAlerts } from "components/atoms/outputs";
import { Modal } from "antd";
import { CreateElementInput } from "components/atoms/inputs";
import "./Notifications.scss";
import { PrimaryButton } from "components/atoms/controls";
import {
  asyncGetData,
  getNotifications,
  deleteNotifications,
  duplicateNotifications,
  publishNotifications,
} from "redux/slices/notifications";
import { useHistory } from "react-router-dom";
import noNotifications from "assets/img/illustrations/no-notifications.svg";
import NotificationsOrganism from "components/organisms/NotificationsOrganism";
const EmptyComponentTemplate = lazy(() =>
  import("components/templates/EmptyComponentTemplate")
);

const renderLoader = () => <SpiningPage opacity={1} />;

const Notifications = (props) => {
  const { translate } = useContext(I18nContext);
  const notificationsFetched = useSelector(
    (state) => state.notifications.notifications
  );
  const notificationsUpdated = useSelector(
    (state) => state.notifications.notificationsUpdated
  );
  const loading = useSelector((state) => state.notifications.loading);

  const [modalVisible, setModalVisible] = useState(false);
  const [searchRef, setSearchRef] = useState(0);
  const isMounted = useRef(null);
  const dispatch = useDispatch();
  let history = useHistory();

  const [notifications, setNotifications] = useState(notificationsFetched);
  const [notificationsAux, setNotificationsAux] = useState(
    notificationsFetched
  );

  const [view, setView] = useState("cards");

  useEffect(() => {
    dispatch(getNotifications());
    let updateValue = { previewData: {} };
    dispatch(asyncGetData(updateValue));
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setNotifications(notificationsFetched);
    setNotificationsAux(notificationsFetched);
  }, [notificationsFetched]);

  useEffect(() => {
    if (notificationsUpdated) {
      dispatch(getNotifications());
    }
  }, [notificationsUpdated]);
  const OpenCreateEvent = () => {
    setModalVisible(true);
  };

  const handleSearch = (query, isEmpty) => {
    let result = notificationsAux.filter((n) =>
      n.subject.toLowerCase().includes(query.toLowerCase())
    );
    if (searchRef === result.length) {
    } else {
      if (result.length <= 0) {
        setNotifications([{}]);
      } else {
        if (isEmpty) {
          setNotifications(notificationsAux);
        } else {
          setNotifications(result);
        }
      }
    }
    setSearchRef(result.length);
  };

  const handleView = (view) => {
    setView(view);
  };

  const getNotificationSubject = (values) => {
    let updateValue = { subject: values.subject };
    console.log("updateValue", updateValue);
    dispatch(asyncGetData(updateValue));
    history.push({
      pathname: "/home/notifications/manage/",
      state: { notification: updateValue },
    });
  };

  const handleEdit = (item) => {
    history.push({
      pathname: "/home/notifications/manage/",
      state: { notification: item },
    });
  };

  const handleCopy = (item) => {
    const info = {
      title: translate("pages").notifications.alerts.duplicate.question.title,
      text: translate("pages").notifications.alerts.duplicate.question.subtitle,
      icon: "info",
      confirm: translate("pages").notifications.alerts.duplicate.question
        .buttons.confirm,
      successTitle: translate("pages").notifications.alerts.duplicate.success
        .title,
      successSubtitle: translate("pages").notifications.alerts.duplicate.success
        .subtitle,
      errorTitle: translate("pages").notifications.alerts.duplicate.error.title,
      errorSubtitle: translate("pages").notifications.alerts.duplicate.error
        .subtitle,
      dispatchFunction: duplicateNotifications,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };
  const handleDelete = (item) => {
    const info = {
      title: translate("pages").notifications.alerts.delete.question.title,
      text: translate("pages").notifications.alerts.delete.question.subtitle,
      icon: "info",
      confirm: translate("pages").notifications.alerts.delete.question.buttons
        .confirm,
      successTitle: translate("pages").notifications.alerts.delete.success
        .title,
      successSubtitle: translate("pages").notifications.alerts.delete.success
        .subtitle,
      errorTitle: translate("pages").notifications.alerts.delete.error.title,
      errorSubtitle: translate("pages").notifications.alerts.delete.error
        .subtitle,
      dispatchFunction: deleteNotifications,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };
  const handlePublish = (item) => {
    const info = {
      title: translate("pages").notifications.alerts.publish.question.title,
      text: translate("pages").notifications.alerts.publish.question.subtitle,
      icon: "info",
      confirm: translate("pages").notifications.alerts.publish.question.buttons
        .confirm,
      successTitle: translate("pages").notifications.alerts.publish.success
        .title,
      successSubtitle: translate("pages").notifications.alerts.publish.success
        .subtitle,
      errorTitle: translate("pages").notifications.alerts.publish.error.title,
      errorSubtitle: translate("pages").notifications.alerts.publish.error
        .subtitle,
      dispatchFunction: publishNotifications,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };

  return (
    <Suspense fallback={renderLoader()}>
      {modalVisible && (
        <Modal
          className="notifications"
          title={translate("pages").notifications.createNotificationModal.title}
          centered
          visible={modalVisible}
          onOk={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
          footer={false}
        >
          <Form name="notification-name" onFinish={getNotificationSubject}>
            <Row justify="end">
              <Col span={24}>
                <Form.Item
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: translate("pages").notifications
                        .createNotificationModal.validateTitle,
                    },
                  ]}
                >
                  <CreateElementInput
                    placeholder={
                      translate("pages").notifications.createNotificationModal
                        .placeholder
                    }
                    label={
                      translate("pages").notifications.createNotificationModal
                        .label
                    }
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <PrimaryButton
                    htmlType="submit"
                    cssClassName="notifications save-button"
                    title={
                      translate("pages").notifications.createNotificationModal
                        .button
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {(notifications === undefined || notifications.length <= 0) &&
        !loading && (
          <EmptyComponentTemplate
            image={noNotifications}
            onButtonClick={OpenCreateEvent}
            title={translate("pages").notifications.notFound.title}
            subtitle={translate("pages").notifications.notFound.description}
            buttonText={
              translate("pages").notifications.notFound.buttons.createEvent
            }
          />
        )}
      {notifications !== undefined && notifications.length > 0 && !loading && (
        <NotificationsOrganism
          items={notifications}
          type={view}
          handleView={handleView}
          handleSearch={handleSearch}
          handleCopy={handleCopy}
          handleDelete={handleDelete}
          handlePublish={handlePublish}
          handleEdit={handleEdit}
        />
      )}

      {loading && <SpiningPage opacity={1} />}
    </Suspense>
  );
};
export default Notifications;
