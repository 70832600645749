import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Image } from "antd";
import { I18nContext } from "utils/i18n/locale";
import SideDrawer, { RIGHT_SIDE } from "components/atoms/side-drawer";
import { BackIcon } from "components/atoms/icons";
import MemoizedAvatar from "components/molecules/ProjectSelect/Avatar";
import NoNotifications from "assets/img/illustrations/no-notifications.svg";
import FlagImage from "assets/img/navbarProfile/flags.png";
import MentionImage from "assets/img/navbarProfile/mentions.png";
import MessageImage from "assets/img/navbarProfile/messages.png";
import "./NotificationsPane.scss";

const COLORS = {
  read: "white",
  unread: "#cecece25",
  // unread: "#fafafa",
};

const IMAGE_TYPES = {
  flag: FlagImage,
  message: MessageImage,
  mention: MentionImage,
};

const NOTIFICATIONS = [
  // {
  //   id: 1,
  //   title: "Albes mentioned you in a flag!",
  //   subtitle: "Today, 09:12 AM",
  //   isRead: false,
  //   image: "mentions.png",
  //   type: "message",
  //   element: {
  //     id: 1,
  //     description: "bla bla",
  //     image: "image.png",
  //   },
  // },
  // {
  //   id: 2,
  //   title: "New email received",
  //   subtitle: "You have 4 unread email",
  //   isRead: true,
  //   image: "messages.png",
  //   type: "message",
  //   element: {
  //     id: 1,
  //     description: "bla bla",
  //     image: "image.png",
  //   },
  // },
  // {
  //   id: 3,
  //   title: "You have a new flag to review!",
  //   subtitle: "You have 2 new flag",
  //   isRead: false,
  //   image: "flags.png",
  //   type: "flag",
  //   element: {
  //     id: 1,
  //     description: "bla bla",
  //     image: "image.png",
  //   },
  // },
  // {
  //   id: 4,
  //   title: "New notification received",
  //   subtitle: "You have 12 unread notifications",
  //   isRead: true,
  //   image: "messages.png",
  //   type: "message",
  //   element: {
  //     id: 1,
  //     description: "bla bla",
  //     image: "image.png",
  //   },
  // },
  // {
  //   id: 5,
  //   title: "Charles mentioned you in a flag!",
  //   subtitle: "Today, 09:19 AM",
  //   isRead: false,
  //   image: "flags.png",
  //   type: "flag",
  //   element: {
  //     id: 1,
  //     description: "bla bla",
  //     image: "image.png",
  //   },
  // },
  // {
  //   id: 1,
  //   title: "Albes mentioned you in a flag!",
  //   subtitle: "Today, 09:12 AM",
  //   isRead: false,
  //   image: "mentions.png",
  //   type: "mention",
  //   element: {
  //     id: 1,
  //     description: "bla bla",
  //     image: "image.png",
  //   },
  // },
  // {
  //   id: 2,
  //   title: "New email received",
  //   subtitle: "You have 4 unread email",
  //   isRead: false,
  //   image: "messages.png",
  //   type: "message",
  //   element: {
  //     id: 1,
  //     description: "bla bla",
  //     image: "image.png",
  //   },
  // },
  // {
  //   id: 3,
  //   title: "You have a new flag to review!",
  //   subtitle: "You have 2 new flag",
  //   isRead: false,
  //   image: "flags.png",
  //   type: "flag",
  //   element: {
  //     id: 1,
  //     description: "bla bla",
  //     image: "image.png",
  //   },
  // },
  // {
  //   id: 4,
  //   title: "New notification received",
  //   subtitle: "You have 12 unread notifications",
  //   isRead: true,
  //   image: "messages.png",
  //   type: "message",
  //   element: {
  //     id: 1,
  //     description: "bla bla",
  //     image: "image.png",
  //   },
  // },
  // {
  //   id: 5,
  //   title: "Charles mentioned you in a flag!",
  //   subtitle: "Today, 09:19 AM",
  //   isRead: false,
  //   image: "mentions.png",
  //   type: "mention",
  //   element: {
  //     id: 1,
  //     description: "bla bla",
  //     image: "image.png",
  //   },
  // },
];

const NotificationsPane = ({ show, onClose, reference }) => {
  const { translate } = useContext(I18nContext);

  const [userNotifications, setUserNotifications] = useState(NOTIFICATIONS);

  useEffect(() => {
    setUserNotifications(NOTIFICATIONS);
  }, [NOTIFICATIONS]);

  return (
    <SideDrawer
      className="notifications-pane-drawer"
      closeIcon={null}
      side={RIGHT_SIDE}
      onClose={onClose}
      open={show}
    >
      <div ref={reference}>
        <div className="notifications-pane-header">
          <div className="notifications-header-left">
            <div className="title">
              {translate("organisms").navbar.notificationsPane.title}
            </div>
          </div>
          <div onClick={() => onClose()} className="notifications-header-right">
            <BackIcon className="back-icon" />
          </div>
        </div>
        <div className="items-container">
          {userNotifications?.length > 0 &&
            userNotifications.map((notification, index) => {
              return (
                <Row
                  className="item-container"
                  style={{
                    backgroundColor: notification.isRead
                      ? COLORS.read
                      : COLORS.unread,
                  }}
                  align="middle"
                  justify="start"
                  gutter={[0, 0]}
                  onClick={() => console.log("notification: ", notification)}
                >
                  <Col span={4} offset={1}>
                    <div className="item">
                      <div className="notification-icon-container">
                        {" "}
                        <MemoizedAvatar
                          className="avatar"
                          size={20}
                          radius={0}
                          localImage={IMAGE_TYPES[notification.type].toString()}
                          // logo={notification.image}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col span={17}>
                    <div className="item">
                      <span className="title">
                        {notification.title} <br />
                        <span className="subtitle">
                          {notification.subtitle}
                        </span>
                      </span>
                    </div>
                  </Col>
                  <Col span={2}>
                    <div className="item">
                      <div
                        hidden={notification.isRead}
                        className="notification-dot"
                      ></div>
                    </div>
                  </Col>
                </Row>
              );
            })}

          {userNotifications?.length <= 0 && (
            <Row
              className="empty-item-container"
              align="middle"
              justify="center"
              gutter={[0, 12]}
              style={{ marginTop: 80 }}
            >
              <Col span={24}>
                <div className="item">
                  <Image
                    src={NoNotifications}
                    preview={false}
                    width={215}
                    height={215}
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="item">
                  <span className="title">
                    {
                      translate("organisms").navbar.notificationsPane
                        .noNotifications
                    }
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </SideDrawer>
  );
};
export default NotificationsPane;
