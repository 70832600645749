import React from "react";
import "./TabMenu.scss";
import Links from "components/client/components/atoms/links";

const TabMenu = (props) => {
    const {selected, onClick,data} = props;
    return(
        <div>
        <hr className="tab-separator"/>
            <div className="tab-row">
                {data.map((item, key) => {
                    return (
                        <div className="tab-container" key={key}>
                            <Links onClick={onClick} text={item} selected={key === selected ? 'selected-link' : null } />
                        </div>
                    )
                    })
                }
            </div>
            <hr className="tab-separator"/>
        </div>
    );
}

export default TabMenu;
