import { Row, Col, Form } from "antd";
import { StandardInput } from "components/atoms/inputs";
import { Select } from "components/atoms/select";
import { Text } from "components/atoms/texts";
import React, { useContext, useState } from "react";
import { I18nContext } from "utils/i18n/locale";
import "./QuestionSettingsDropdownAction.scss";

const QuestionSettingsDropdownAction = ({ text, actionState, actionKey }) => {
  const { translate } = useContext(I18nContext);
  const options = [
    {
      name: translate("pages").surveyEditor.title.unlimited,
      value: "unlimited",
    },
    {
      name: translate("pages").surveyEditor.title.range,
      value: "range",
    },
    {
      name: translate("pages").surveyEditor.title.exactNumber,
      value: "exactNumber",
    },
  ];

  const [inputType, setInputType] = useState(undefined);
  const [minRange, setMinRange] = useState(0);
  const handleDropdownSelect = (value) => {
    if (value === "unlimited") {
      actionState(actionKey, value);
    }
    setInputType(value);
  };
  const handleRangeChange = (changedValues, allValues) => {
    const changedKey = Object.keys(changedValues)[0];
    if (changedKey === "max") {
      actionState(actionKey, allValues);
    } else if (changedKey === "min") {
      setMinRange(parseInt(changedValues.min));
    }
  };
  const handleInputChange = (changedValues) => {
    actionState(actionKey, changedValues.limit);
  };
  return (
    <div className="question-settings-dropdown-wrapper">
      <Row className="question-settings-dropdown-title">
        <Text text={text} />
      </Row>
      <Row>
        <Col xs={24} lg={5}>
          <Select
            options={options}
            defaultActiveFirstOption
            dropdownClassName="settings-dropdown"
            defaultValue={translate("pages").surveyEditor.placeholder.selectOptions}
            onChange={handleDropdownSelect}
          />
        </Col>
      </Row>
      {inputType === "range" && (
        <Form onValuesChange={handleRangeChange}>
          <Row className="settings-range">
            <div className="input">
              <Form.Item name="min">
                <StandardInput type="number" />
              </Form.Item>
            </div>
            <Text text={translate("pages").surveyEditor.title.to} />
            <div className="input">
              <Form.Item name="max">
                <StandardInput type="number" min={minRange + 1} />
              </Form.Item>
            </div>
          </Row>
        </Form>
      )}
      {inputType === "exactNumber" && (
        <Form onValuesChange={handleInputChange}>
          <Row className="settings-range">
            <div className="input">
              <Form.Item name="limit">
                <StandardInput type="number" />
              </Form.Item>
            </div>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default QuestionSettingsDropdownAction;
