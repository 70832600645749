import React from "react";
import { ResponsivePie } from "@nivo/pie";
import "./Pie.scss";
import GraphContainer from "components/molecules/Graphs/Container";
import { LEGEND_POSITION, LEGEND_DIRECTION } from "components/molecules/Graphs";

export const TYPE = {
  HALF: "half",
  FULL: "full",
};

const getTypeAngle = (type = TYPE.FULL, reverse = false) => {
  return type === TYPE.FULL
    ? { startAngle: reverse ? 360 : 0, endAngle: reverse ? 0 : 360 }
    : type === TYPE.HALF
    ? { startAngle: reverse ? 90 : -90, endAngle: reverse ? -90 : 90 }
    : {};
};

const PieGraph = ({
  data,
  title,
  height = 470,
  enableRadialLabels = false,
  enableSliceLabels = false,
  type = TYPE.FULL,
  innerRadius = 0.75,
  reverse = false,
  showLegend = true,
  legendDirection = LEGEND_DIRECTION.ROW,
  legendPosition = LEGEND_POSITION.BOTTOM,
}) => {
  return (
    <GraphContainer style={{ height }} title={title}>
      <ResponsivePie
        {...getTypeAngle(type, reverse)}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: legendDirection === LEGEND_DIRECTION.ROW ? 60 : 100,
          left: 20,
        }}
        valueFormat=">-.0%"
        colors={({ data: { color } }) => color}
        innerRadius={innerRadius}
        borderWidth={0}
        radialLabelsLinkStrokeWidth={0}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        enableSliceLabels={enableSliceLabels}
        enableRadialLabels={enableRadialLabels}
        legends={
          showLegend && [
            {
              anchor: legendPosition,
              direction: legendDirection,
              justify: false,
              translateX: legendDirection === LEGEND_DIRECTION.ROW ? 0 : -210,
              translateY: legendDirection === LEGEND_DIRECTION.ROW ? 30 : 0,
              itemsSpacing: legendDirection === LEGEND_DIRECTION.ROW ? 18 : 4,
              itemWidth: legendDirection === LEGEND_DIRECTION.ROW ? 60 : 0,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 12,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]
        }
        // tooltip={(data) => {
        //   const {
        //     datum: { label, value },
        //   } = data;
        //   return (
        //     <div
        //       {...data}
        //       className={`chart-tooltip cityflag-chart-pie-tooltip`}
        //     >{`${label}: ${value}`}</div>
        //   );
        // }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </GraphContainer>
  );
};

export default PieGraph;
