// all api goes in here
import api from "config/axios/axios.api.config";

const prefix = "surveys";

// export const getSurveysAPI = () => api.get(`${prefix}`);
export const getSurveysAPI = (id) => api.get(`${prefix}/projects/${id}`);
export const getSurveyAPI = (id) => api.get(`${prefix}/${id}`);
export const getClientSurveyAPI = (id) =>
    api.get(`${prefix}/projects/${id}?status=available`);
export const getSurveyResponsesAPI = (id) =>
    api.get(`${prefix}/${id}/responses`);

export const getProfileResponsesAPI = (id) =>
    api.get(`${prefix}/${id}/responses/profile`);

export const getSurveyQuestionsAPI = (id) =>
    api.get(`${prefix}/${id}/questions`);
export const deleteSurveyAPI = (id) => api.put(`${prefix}/${id}/delete`);
export const publishSurveyAPI = ({ surveyId, available_until }) =>
    api.put(`${prefix}/${surveyId}/publish`, { available_until });
export const duplicateSurveyAPI = (id) =>
    api.postJson(`${prefix}/${id}/duplicate`, { id });
export const updateSurveyAPI = ({ id, ...data }) =>
    api.put(`${prefix}/${id}`, data);
export const createSurveyAPI = (data) => api.postJson(`${prefix}`, data);
