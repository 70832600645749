import React from 'react';
import "./EventsHeader.scss";
import EventsTopbar from 'client/components/molecules/EventsTopbar';
import EventsWelcomeHeader from 'client/components/molecules/EventsWelcomeHeader';

const EventsHeader = ({eventsList, setChooseDate, calendarHeader}) => {
  return(
    <div className="events-top-header">  
      <EventsTopbar header={calendarHeader} eventsList={eventsList} openCalendar={setChooseDate} />
      {eventsList.length ? <EventsWelcomeHeader userName={"Salma"} />: null}
    </div>
  )
}

export default EventsHeader;