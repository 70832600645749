import React, { useContext, Suspense, useEffect, useState, lazy } from "react";
import { Row, Col } from "antd";
import { PrimaryButton } from "components/atoms/controls";
import { SpiningPage } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import AnalyticsTemplate from "components/templates/Analytics";
import AnalyticCard from "components/molecules/AnalyticCard";
import "pages/Analytics/Gallery/Gallery.scss";
import placeholder from "assets/img/illustrations/no-dashboards.svg";
import SimpleInputsModal from "components/molecules/SimpleInputsModal";
import { useHistory } from "react-router";

const EmptyComponentTemplate = lazy(() =>
  import("components/templates/EmptyComponentTemplate")
);

const AnalyticsGallery = () => {
  const history = useHistory();
  const { translate } = useContext(I18nContext);
  const [data, setData] = useState([]);
  const [openDashboardPrompt, setOpenDashboardPrompt] = useState(false);

  useEffect(() => {
    if (
      !history?.location?.state?.items ||
      history?.location?.state?.items?.length === 0
    )
      return;

    setData([...history.location.state.items]);
  }, []);

  const addNewDashboard = () => {
    setOpenDashboardPrompt(true);
  };

  return (
    <Suspense fallback={<SpiningPage opacity={1} />}>
      {!data || data.length === 0 ? (
        <EmptyComponentTemplate
          image={placeholder}
          title={translate("pages").analytic.empty.title}
          subtitle={translate("pages").analytic.empty.description}
          buttonText={translate("pages").analytic.empty.buttons.createDashboard}
          onButtonClick={addNewDashboard}
        />
      ) : (
        <AnalyticsTemplate>
          <PrimaryButton
            onClick={addNewDashboard}
            cssClassName="analytics-button"
            title={translate("pages").analytic.gallery.button}
          />
          <div className="message-title">
            {translate("pages").analytic.gallery.messageTitle}
          </div>
          <Row gutter={[16, 16]}>
            {data.map((dashboard, index) => (
              <Col
                xl={24 / 4}
                lg={24 / 3}
                md={24 / 2}
                xs={24}
                key={`${index.toString()}-dashboard`}
              >
                <AnalyticCard
                  onClick={() => {
                    history.push(`/home/analytics/${dashboard.id}`, {
                      data: dashboard,
                    });
                  }}
                  hoverable
                  imgUrl={dashboard.img}
                  bordered={true}
                  title={dashboard.title}
                />
              </Col>
            ))}
          </Row>
        </AnalyticsTemplate>
      )}
      <SimpleInputsModal
        visible={openDashboardPrompt}
        onSubmit={({ name }) =>
          history.push("/home/analytics/new", {
            data: {
              title: name,
            },
          })
        }
        onCancel={() => setOpenDashboardPrompt(false)}
        title={translate("pages").analytic.gallery.createDashboardModal.title}
        inputs={[
          {
            name: "name",
            required: true,
            placeholder: translate("pages").analytic.gallery
              .createDashboardModal.placeholder,
            errorMessage: translate("pages").analytic.gallery
              .createDashboardModal.errorMessage,
          },
        ]}
        okText="Continue"
      />
    </Suspense>
  );
};
export default AnalyticsGallery;
