import React from "react";
import Modal from "components/atoms/modal";
import { Text } from "components/atoms/texts";
import { Title } from "components/atoms/texts";
import "./UserListModal.scss";

const UserDeactivateModal = ({ show, translate, closeModal, onDeactivate }) => {
  return (
    <Modal
      visible={show}
      className="user-list-modal"
      title={
        <Title
          level={2}
          text={translate("pages").settings.modal.title}
          className="danger-text modal-title"
        />
      }
      cancelText={<Text text={translate("pages").settings.modal.no} />}
      okText={
        <Text
          text={translate("pages").settings.modal.deactivate}
          className="danger-text"
        />
      }
      onCancel={closeModal}
      onOk={onDeactivate}
    >
      {translate("pages").settings.modal.deactivateBody}
    </Modal>
  );
};

export default UserDeactivateModal;
