import React from "react";
import "./communication.scss";
import LaunchSaLogo from "assets/img/launchSaLogo.png";
import { cloudFrontUrl } from "utils/common";

const Image = React.memo(function Image({ src, classname }) {
  return <img src={src} className={classname} />;
});

const MessageImage = (props) => {
  const { itemImage } = props;
  return (
    <div className="communication-image">
      <Image
        classname="communication-image-placeholder"
        src={itemImage ? cloudFrontUrl(itemImage) : LaunchSaLogo}
      />
    </div>
  );
};

export default MessageImage;
