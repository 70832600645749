import Settings from "pages/Settings";

const prefix = "/settings";

const routes = [
  {
    path: `${prefix}`,
    component: Settings,
  },
];

export default routes;
