import { DownOutlined } from "@ant-design/icons";
import React from "react";
import "./open-toggle.scss"

const OpenToggle = ({
  opened = true,
  onChange,
  color,
  className = "",
  ...props
}) => {

  return (
    <DownOutlined
      {...props}
      className={`open-toggle-root ${className}`}
      onClick={() => onChange(!opened)}
      style={{
        borderColor: opened ? color : 'transparent',
        backgroundColor: opened ? 'transparent' : color,
        color: opened ? color : '#F0F2F5'
      }}
      rotate={opened ? 0 : -90} />
  )
};

export default OpenToggle;
