import { MoreOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Text } from "components/atoms/texts";
import React from "react";
import "./DirectoryListItem.scss";
const DirectoryListItem = ({ directory }) => {
  return (
    <Row className="directory-list-item">
      <Col xs={10} lg={8}>
        <Text text={directory.name} className="list-item-title" />
      </Col>
      <Col xs={0} lg={7} className="list-item-wrapper">
        <Text text={directory.createdAt} className="list-item-text" />
      </Col>

      <Col xs={10} lg={7} className="list-item-wrapper">
        <Text text={directory.createdAt} className="list-item-text" />
      </Col>
      <Col xs={2} lg={1} offset={1}>
        <div className="list-icon">
          <MoreOutlined />
        </div>
      </Col>
    </Row>
  );
};
export default DirectoryListItem;
