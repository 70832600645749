import React from "react";
import "./Container.scss";
import {Col, Row} from "antd";
import {MenuThreeDotsIcon} from "components/atoms/icons";

const GraphContainer = ({ children, title, subtitle, className = "", ...props }) => {
  return (
    <Col className={`cityflag-graph-container ${className}`} {...props} flex='auto'>
      <Row justify='space-between' align='middle' className='graph-container-header'>
        <Col>
          {title}
          {
            subtitle && <div className='subtitle'>
              {subtitle}
            </div>
          }
        </Col>
        <MenuThreeDotsIcon/>
      </Row>
      {children}
    </Col>
  );
};

export default GraphContainer;
