import React, {useContext, useState, useEffect} from "react";
import Picker from "emoji-picker-react";
import {Form} from "antd";
import {DataInput} from "components/atoms/inputs";
import {PrimaryButton} from "components/atoms/controls";
import {I18nContext} from "utils/i18n/locale";
import BottomDrawer from "components/atoms/bottom-drawer";
import {
  ExpandIcon,
  ShrinkIcon,
  AttachFileIcon,
  AtMentionIcon,
  EmojiIcon,
} from "components/atoms/icons";
import "./ComposeNotificationForm.scss";
import ComposeEmailFooter from "components/molecules/ComposeEmailFooter";

const ComposeNotificationForm = ({show, onClose, sendEmail, messageBoxId}) => {
  const {translate} = useContext(I18nContext);
  const [composeEmailFormHeight, setComposeEmailFormHeight] = useState("75%");
  const [composeEmailMessageRow, setComposeEmailMessageRow] = useState(8);
  const [isComposeFormExpanded, setIsComposeFormExpanded] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [inputs, setInputs] = useState({
    messageBox: "",
  });

  const onShowEmojiClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const onEmojiClick = (event, emojiObject) => {
    const myElement = document.getElementById("compose-notification-box");
    const startPosition = myElement.selectionStart;

    const boxTextValue =
      inputs.messageBox !== ""
        ? inputs?.messageBox?.slice(0, startPosition) +
          " " +
          emojiObject.emoji +
          " " +
          inputs?.messageBox?.slice(startPosition + 1)
        : emojiObject.emoji + " ";

    setInputs((inputs) => ({
      ...inputs,
      messageBox: boxTextValue,
    }));
  };

  const handleClickOutside = (e) => {
    const getClassName = e.target.className
      ? typeof e.target.className === "string"
        ? e.target.className
        : ""
      : "";
    if (
      getClassName !== "emoji-img" &&
      getClassName.substring(0, 4) !== "icn-"
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const formRef = React.createRef();

  const setFormHeight = (height) => {
    setComposeEmailFormHeight(height);
  };
  const onAdjustComposeForm = () => {
    if (!isComposeFormExpanded) {
      setIsComposeFormExpanded(true);
      setFormHeight("90%");
      setComposeEmailMessageRow(16);
    } else {
      setIsComposeFormExpanded(false);
      setFormHeight("75%");
      setComposeEmailMessageRow(8);
    }
  };
  const composeMailHeader = (title) => (
    <div className="compose-mail-header">
      <div>{title}</div>
      <div>
        {!isComposeFormExpanded ? (
          <ExpandIcon onClick={onAdjustComposeForm} />
        ) : (
          <ShrinkIcon onClick={onAdjustComposeForm} />
        )}
      </div>
    </div>
  );

  const onChangeText = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      messageBox: e.target.value,
    }));
  };

  const onSubmit = () => {
    sendEmail();
  };

  return (
    <BottomDrawer
      onClose={onClose}
      open={show}
      title={composeMailHeader(
        translate("pages").communication.notification.compose.title
      )}
      height={composeEmailFormHeight}
      id="compose-form-drawer"
      className="compose-mail-ant-drawer"
    >
      <Form ref={formRef} onFinish={onSubmit}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DataInput
            placeholder={
              translate("pages").communication.notification.compose.placeholder
                .recipient
            }
            title={
              translate("pages").communication.notification.compose
                .recipientTitle
            }
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="subject"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DataInput
            placeholder={
              translate("pages").communication.notification.compose.placeholder
                .subject
            }
            title={
              translate("pages").communication.notification.compose.subjectTitle
            }
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="message"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DataInput
            type="textarea"
            rows={composeEmailMessageRow}
            placeholder={
              translate("pages").communication.email.compose.placeholder.message
            }
            title={translate("pages").communication.email.compose.message}
            allowClear
            id={messageBoxId}
            value={inputs.messageBox}
            onChange={onChangeText}
          />
          <ComposeEmailFooter cssClass="compose-new-mail-footer size-75">
            <AtMentionIcon />
            <AttachFileIcon />
            {showEmojiPicker ? <Picker onEmojiClick={onEmojiClick} /> : ""}
            <EmojiIcon onClick={onShowEmojiClick} />
            <PrimaryButton
              htmlType="submit"
              cssClassName="save-btn"
              title={
                translate("pages").communication.email.compose.buttons.send
              }
              onClick={() => onSubmit()}
            />
          </ComposeEmailFooter>
        </Form.Item>
      </Form>
    </BottomDrawer>
  );
};
export default ComposeNotificationForm;
