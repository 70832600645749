import React, {useContext} from 'react';
import "./AboutProject.scss";
import ViewEventTopbar from 'client/components/molecules/ViewEventTopBar';
import ViewAboutContainer from 'client/components/organisms/ViewAboutContainer'
import { I18nContext } from "utils/i18n/locale";

const ViewEvent = ({location}) => {
  let id = location.search.replace("?id=", "");
  const { translate } = useContext(I18nContext)

  return (
    <div className="view-event-wrapper">
      <ViewEventTopbar title={translate("client").pages.aboutProject.title} />
      <ViewAboutContainer />
    </div>
  )
}

export default ViewEvent;
