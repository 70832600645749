import React from "react";
import { useHistory } from "react-router";
import "./GetAssistance.scss";
import { ReactComponent as CommunicationIcon } from "assets/svgs/communicationSvgWhite.svg";

const GetAssistance = (props) => {
  const history = useHistory();

  return (
    <div
      className="get-assistance-button"
      onClick={() => history.push("/launchsa/newMessage/")}
    >
      <CommunicationIcon />
      <text>Get Assistance</text>
    </div>
  );
};

export default GetAssistance;
