import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notificationAPI from "api/notificationAPI";
import { getCurrentProjectId } from "utils/common";

const awsErrorSerialized = {
  error: "ASW Error",
};

const awsError = (resp) => {
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};

export const asyncGetData = (previewData) => async (dispatch) => {
  try {
    dispatch(startGetForm());
    dispatch(successGetForm(previewData));
  } catch (error) {
    dispatch(errorGetForm(error.toString()));
  }
};

export const asyncClearData = (previewData) => async (dispatch) => {
  try {
    dispatch(startCleanForm());
    dispatch(successCleanForm(previewData));
  } catch (error) {
    dispatch(errorCleanForm(error.toString()));
  }
};

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const notifications = await notificationAPI.getNotifications(project_id);
      awsError(notifications);
      if (notifications.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return notifications.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getClientNotifications = createAsyncThunk(
  "notifications/getClientNotifications",
  async (id, { rejectWithValue }) => {
    try {
      const notifications = await notificationAPI.getNotifications(id);
      awsError(notifications);
      if (notifications.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return notifications.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createNotifications = createAsyncThunk(
  "notifications/createNotifications",
  async (item, { rejectWithValue }) => {
    try {
      const project_id = getCurrentProjectId();
      const notifications = await notificationAPI.createNotification(
        item,
        project_id
      );
      awsError(notifications);
      return notifications.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updateNotifications = createAsyncThunk(
  "notifications/updateNotifications",
  async (item, { rejectWithValue }) => {
    try {
      let editNotifications = { ...item };
      delete editNotifications.id;
      const notifications = await notificationAPI.updateNotification(
        editNotifications,
        item.id
      );
      awsError(notifications);
      return notifications.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const publishNotifications = createAsyncThunk(
  "notifications/publishNotifications",
  async (item, { rejectWithValue }) => {
    try {
      const notifications = await notificationAPI.publishNotification(item);
      awsError(notifications);
      return notifications.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const unpublishNotifications = createAsyncThunk(
  "notifications/unpublishNotifications",
  async (item, { rejectWithValue }) => {
    try {
      const notifications = await notificationAPI.unpublishNotification(item);
      console.log(notifications, "unpublish");
      awsError(notifications);
      return notifications.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const duplicateNotifications = createAsyncThunk(
  "notifications/duplicateNotifications",
  async (item, { rejectWithValue }) => {
    try {
      const notifications = await notificationAPI.duplicateNotification(item);
      console.log(notifications, "duplicate");
      awsError(notifications);
      return notifications.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const deleteNotifications = createAsyncThunk(
  "notifications/deleteNotifications",
  async (id, { rejectWithValue }) => {
    try {
      const notifications = await notificationAPI.deleteNotification(id);
      awsError(notifications);
      return notifications.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

const initialState = {
  previewData: {
    subject: "",
    body: "",
    location: "",
    date: "",
  },
  notifications: [],
  error: null,
  loading: true,
  notificationUpdated: false,
  notificationCreated: false,
  loadingAction: false,
};

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    startGetForm(state, action) {},
    successGetForm(state, action) {
      state.previewData = { ...state.previewData, ...action.payload };
    },
    errorGetForm(state, action) {
      console.log("error en el preview data", action);
    },
    startCleanForm(state, action) {},
    successCleanForm(state, action) {
      state.previewData = { ...action.payload };
    },
    errorCleanForm(state, action) {
      console.log("error en el clean data", action);
    },
  },
  extraReducers: {
    //Get Notifications
    [getClientNotifications.pending]: (state, action) => {
      console.info("getClientNotifications pending");
      state.loading = true;
      state.error = null;
      state.notificationsUpdated = false;
      state.notificationsCreated = false;
      state.notifications = [];
    },
    [getClientNotifications.fulfilled]: (state, action) => {
      console.info("getClientNotifications fullfilled", action.payload.body);
      state.loading = false;
      state.error = null;
      state.notificationsUpdated = false;
      state.notifications = action.payload.body;
    },
    [getClientNotifications.rejected]: (state, action) => {
      console.error("getClientNotifications rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.notifications = [];
    },
    //Get Notifications
    [getNotifications.pending]: (state, action) => {
      console.info("getNotifications pending");
      state.loading = true;
      state.error = null;
      state.notificationsUpdated = false;
      state.notificationsCreated = false;
      // state.notifications = [];
    },
    [getNotifications.fulfilled]: (state, action) => {
      console.info("getNotifications fullfilled");
      state.loading = false;
      state.error = null;
      state.notificationsUpdated = false;
      state.notifications = action.payload.body;
    },
    [getNotifications.rejected]: (state, action) => {
      console.error("getNotifications rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.notifications = [];
    },

    //Create Notifications
    [createNotifications.pending]: (state, action) => {
      console.info("createNotifications pending");
      state.loadingAction = true;
      state.error = null;
      state.notificationsUpdated = false;
      state.notificationsCreated = false;
    },
    [createNotifications.fulfilled]: (state, action) => {
      console.info("createNotifications fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.notificationsCreated = true;
    },
    [createNotifications.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("createNotifications rejected =>", action.payload);
    },

    //Update Notifications
    [updateNotifications.pending]: (state, action) => {
      console.info("updateNotifications pending");
      state.loadingAction = true;
      state.error = null;
      state.notificationsUpdated = false;
      state.notificationsCreated = false;
    },
    [updateNotifications.fulfilled]: (state, action) => {
      console.info("updatenotifications fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.notificationsCreated = true;
    },
    [updateNotifications.rejected]: (state, action) => {
      console.error("updatenotifications rejected =>", action.payload);
      state.notificationsCreated = false;
      state.notificationsUpdated = false;
      state.loadingAction = false;
      state.error = action.payload.error;
    },

    //Delete Notifications
    [deleteNotifications.pending]: (state, action) => {
      console.info("DeleteNotifications started");
      state.loadingAction = true;
      state.error = null;
      state.notificationsUpdated = false;
    },
    [deleteNotifications.fulfilled]: (state, action) => {
      console.info("DeleteNotifications success", state.notificationsUpdated);
      state.loadingAction = false;
      state.error = null;
      state.notificationsUpdated = true;
    },
    [deleteNotifications.rejected]: (state, action) => {
      console.error("DeleteNotifications error=> ", action.payload.error);
      state.loadingAction = false;
      state.error = null;
      state.notificationsUpdated = false;
      state.error = action.payload.error;
    },

    //Publish Notifications
    [publishNotifications.pending]: (state, action) => {
      console.info("PublishNotifications pending");
      state.loadingAction = true;
      state.error = null;
      state.notificationsUpdated = false;
    },
    [publishNotifications.fulfilled]: (state, action) => {
      console.info(
        "PublishNotifications fullfiled",
        state.notificationsUpdated
      );
      state.loadingAction = false;
      state.error = null;
      state.notificationsUpdated = true;
    },
    [publishNotifications.rejected]: (state, action) => {
      console.error("PublishNotifications rejected=> ", action.payload);
      state.loadingAction = false;
      state.error = action.payload;
      state.notificationsUpdated = false;
    },

    //Publish Notifications
    [unpublishNotifications.pending]: (state, action) => {
      console.info("unPublishNotifications pending");
      state.loadingAction = true;
      state.error = null;
      state.notificationsUpdated = false;
    },
    [unpublishNotifications.fulfilled]: (state, action) => {
      console.info(
        "unPublishNotifications fullfiled",
        state.notificationsUpdated
      );
      state.loadingAction = false;
      state.error = null;
      state.notificationsUpdated = true;
    },
    [unpublishNotifications.rejected]: (state, action) => {
      console.error("unPublishNotifications rejected=> ", action.payload);
      state.loadingAction = false;
      state.error = action.payload;
      state.notificationsUpdated = false;
    },

    //Duplicate Notifications
    [duplicateNotifications.pending]: (state, action) => {
      console.info("DuplicateNotifications pending");
      state.loadingAction = true;
      state.error = null;
      state.notificationsUpdated = false;
    },
    [duplicateNotifications.fulfilled]: (state, action) => {
      console.info(
        "DuplicateNotifications fullfilled",
        state.notificationsUpdated
      );
      state.loadingAction = false;
      state.error = null;
      state.notificationsUpdated = true;
    },
    [duplicateNotifications.rejected]: (state, action) => {
      console.error("DuplicateNotifications rejected=> ", action.payload);
      state.loadingAction = false;
      state.error = null;
      state.notificationsUpdated = false;
      state.error = action.payload;
    },
  },
});

export const {
  startGetForm,
  successGetForm,
  errorGetForm,
  startCleanForm,
  successCleanForm,
  errorCleanForm,
} = notifications.actions;
export default notifications.reducer;
