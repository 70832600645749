import React from "react";
import {
    DownOutlined,
    EllipsisOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { Row, Col } from "antd";

import { PrimaryButton } from "components/atoms/controls";
import { I18nContext } from "utils/i18n/locale";
import { EditIcon, TrashIcon } from "components/atoms/icons";
import SearchBar from "components/atoms/search";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import ProjectListDropdown from "../ProjectListDropdown";
import { updateProfile } from "redux/slices/profile";
import "./ProjectSelect.scss";
import {
    changeProject,
    openCreateProjectModal,
    getProjects,
} from "redux/slices/projects";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import InternalAvatar from "./Avatar";
import Avatar from "react-avatar";
import { cloudFrontUrl, getCurrentProjectId, textTruncate } from "utils/common";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
// import Tour from "reactour";

const steps = [
    {
        selector: '[data-tour="my-first-step"]',
        content: "This is my first Step",
    },
    // ...
];

const ProjectSelect = ({ active }) => {
    const projectsFetched = useSelector((state) => state.projects.projects);
    const errorFetching = useSelector(
        (state) => state.projects.projectFetchError
    );

    const node = useRef();
    const node_two = useRef();
    const dispatch = useDispatch();
    const { translate } = useContext(I18nContext);

    // useEffect(() => {
    //   dispatch(getProjects());
    // }, []);

    // useEffect(() => {
    //   if (errorFetching) dispatch(getProjects());
    // }, [errorFetching]);

    const [searchRef, setSearchRef] = useState(0);
    const [projects, setProjects] = useState(projectsFetched);
    const [projectsAux, setProjectsAux] = useState(projectsFetched);
    const profile = useSelector((state) => state.profile.profile);
    const currentProject = useSelector(
        (state) => state.projects.currentProject
    );

    const ls_project_id = getCurrentProjectId();
    const [currentProjectId, setCurrentProjectId] = useState(ls_project_id);

    // const [projectSelected, setProjectSelected] = useState([]);
    const history = useHistory();

    const profileLoaded = useSelector((state) => state.profile.profileLoaded);

    const [isReady, setIsReady] = useState(profileLoaded);

    const userSettings = useSelector((state) => state.projects.settings);
    const [settings, setSettings] = useState(userSettings);

    const [projectSelected, setProjectSelected] = useState(currentProject);

    useEffect(() => {
        setProjectSelected(currentProject);
    }, [currentProject]);

    useEffect(() => {
        setSettings(userSettings);
    }, [userSettings]);

    const getCurrentProjectSelected = (projects, currentProjectId) => {
        // console.log("Projects: ", projects);
        // console.log("Current PId: ", currentProjectId);
        if (projects.length > 0) {
            let project = projects.filter(
                (p) =>
                    p?.project?.id?.toString() === currentProjectId?.toString()
            );
            if (project) {
                dispatch(
                    changeProject({
                        item: project[0],
                        project_id: currentProjectId,
                    })
                );
                setProjectSelected(project[0]);
            }
        }
    };

    // useEffect(() => {
    //   // console.log("cambio en el profile", profile);
    //   if ((profile !== undefined) & (profile !== null)) {
    //     setCurrentProjectId(
    //       profile?.user?.configurations?.project_id ??
    //         profile?.configurations?.project_id
    //     );
    //   }
    // }, [profile]);

    // useEffect(() => {
    //   if (projectSelected[0]?.organization?.id) {
    //     localStorage.setItem(
    //       "current_organization",
    //       projectSelected[0]?.organization?.id
    //     );
    //   }
    //   // localStorage.setItem(
    //   //   "current_organization",
    //   //   action.payload.body?.organization_id
    //   // );
    // }, [projectSelected]);

    useEffect(() => {
        // getCurrentProjectSelected(projectsFetched, currentProjectId);
        setProjects(projectsFetched);
        setProjectsAux(projectsFetched);
    }, [projectsFetched]);

    useEffect(() => {
        setIsReady(profileLoaded);
    }, [profileLoaded]);

    useEffect(() => {
        if (isReady) {
            // dispatch(getProjects());
        }
    }, [isReady]);

    useEffect(() => {
        getCurrentProjectSelected(projects, currentProjectId);
    }, [currentProjectId]);

    const handleSearch = (query, isEmpty) => {
        let result = projectsAux.filter(
            (n) =>
                n.organization?.name
                    ?.toLowerCase()
                    .includes(query.toLowerCase()) ||
                n.project?.name.toLowerCase().includes(query.toLowerCase())
        );
        if (searchRef === result.length) {
        } else {
            if (result.length <= 0) {
                setProjects([]);
            } else {
                if (isEmpty) {
                    setProjects(projectsAux);
                } else {
                    setProjects(result);
                }
            }
        }
        setSearchRef(result.length);
    };

    // const dispatchUpdateProfile = (data) => {
    //   dispatch(updateProfile({ ...data }))
    //     .then(unwrapResult)
    //     .catch(() => {
    //       Alert("error", translate("generic").tryAgain);
    //     });
    // };

    const handleChangeProject = (item) => {
        Swal.fire({
            title: translate("pages").projects.alerts.changeProject.question
                .title,
            text: translate("pages").projects.alerts.changeProject.question
                .subtitle,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: settings?.sibebar_color ?? "#000000",
            cancelButtonColor: "#d33",
            confirmButtonText:
                translate("pages").projects.alerts.changeProject.question
                    .buttons.confirm,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return dispatch(
                    updateProfile({
                        configurations: { project_id: item?.project?.id },
                    })
                )
                    .then(unwrapResult)
                    .then(() => {
                        dispatch(
                            changeProject({
                                item: item,
                                project_id: item?.project?.id,
                            })
                        )
                            .then(() => {
                                setCurrentProjectId(item?.project?.id);
                                // dispatchUpdateProfile({ configurations: { project_id: item.id } });
                                Swal.fire({
                                    title: translate("pages").projects.alerts
                                        .changeProject.success.title,
                                    text: translate("pages").projects.alerts
                                        .changeProject.success.subtitle,
                                    icon: "success",
                                    timer: 2000,
                                    timerProgressBar: true,
                                    showConfirmButton: false,
                                    didRender: () => {
                                        history.push({
                                            pathname: "/home/",
                                        });
                                    },
                                    didClose: () => {
                                        // window.location.replace("/home/surveys");
                                        // history.push({
                                        //   pathname: "/home/",
                                        // });
                                    },

                                    // didOpen: () => {
                                    //   Swal.showLoading();
                                    // },
                                });
                            })
                            .catch(() => {
                                Swal.fire({
                                    title: translate("pages").projects.alerts
                                        .changeProject.error.title,
                                    text: translate("pages").projects.alerts
                                        .changeProject.error.subtitle,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    showConfirmButton: false,
                                    // didOpen: () => {
                                    //   Swal.showLoading();
                                    // },
                                    icon: "error",
                                });
                            });
                    })
                    .catch(() => {
                        Swal.fire({
                            title: translate("pages").projects.alerts
                                .changeProject.error.title,
                            text: translate("pages").projects.alerts
                                .changeProject.error.subtitle,
                            timer: 2000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                            // didOpen: () => {
                            //   Swal.showLoading();
                            // },
                            icon: "error",
                        });
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        });
    };

    const options = [
        {
            icon: <EditIcon className="users-edit-icon" />,
            key: "edit",
            title: "Edit",
        },
        // {
        //   icon: <TrashIcon color="#F44336" margintop={1} />,
        //   key: "deactivate",
        //   title: "Delete",
        //   color: "#F44336",
        // },
    ];

    const [dropdownActive, setDropdownActive] = useState(active);
    const handleClickOutside = (e) => {
        if (
            node.current.contains(e.target) ||
            node_two.current.contains(e.target)
        ) {
            // inside click

            return;
        }
        // outside click
        setDropdownActive(false);
    };

    const handleChange = (selectedValue) => {
        handleChangeProject(selectedValue);
        setDropdownActive(false);
    };

    useEffect(() => {
        if (dropdownActive) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownActive]);

    return (
        <Fragment>
            {/* <Tour
        steps={steps}
        isOpen={true}
        // onRequestClose={setDropdownActive(false)}
      /> */}
            <Row
                className="project"
                align="middle"
                ref={node_two}
                onClick={() => {
                    setDropdownActive(!dropdownActive);
                }}
                style={{ cursor: "pointer" }}
            >
                <Col offset={3} span={5}>
                    <div className="image-container">
                        <div className="image-container-inner">
                            {projectSelected?.project?.logo ? (
                                <InternalAvatar
                                    src={cloudFrontUrl(
                                        projectSelected?.project?.logo
                                    )}
                                    // logo={projectSelected?.project?.logo}
                                    size={44}
                                    radius={5}
                                    // isSquare
                                />
                            ) : (
                                <Avatar
                                    name={projectSelected?.project?.name}
                                    size={47}
                                    round="5px"
                                    style={{
                                        letterSpacing: 1,
                                        fontSize: 30,
                                        fontFamily: "IBMPlex-SemiBold",
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </Col>
                <Col offset={2} span={10}>
                    <div className="title-container">
                        <div className="title">
                            {/* <div className="title mover-1"> */}{" "}
                            {textTruncate(
                                projectSelected?.project?.name,
                                8,
                                "..."
                            )}
                        </div>
                        <div className="subtitle">
                            {textTruncate(
                                projectSelected?.organization?.name,
                                10,
                                "..."
                            )}
                        </div>
                    </div>
                </Col>
                <Col span={4}>
                    <DownOutlined />
                </Col>
            </Row>
            {/* {dropdownActive && ( */}
            <div
                className="project-list-container"
                ref={node}
                hidden={!dropdownActive}
            >
                <div className="project-list-header">
                    <SearchBar
                        className="project-search-bar"
                        placeholder={"Search Project"}
                        handleSearch={(item) => handleSearch(item)}
                    />
                </div>
                <div className="projects-list-elements">
                    {projects.length > 0 ? (
                        projects.map((item) => (
                            <Fragment>
                                <Row
                                    className="list"
                                    align="middle"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleChange(item)}
                                >
                                    <Col offset={1} span={3}>
                                        <div className="image-container">
                                            {item?.project?.logo ? (
                                                <InternalAvatar
                                                    // logo={item?.project?.logo}
                                                    src={cloudFrontUrl(
                                                        item?.project?.logo
                                                    )}
                                                />
                                            ) : (
                                                <Avatar
                                                    name={item?.project?.name}
                                                    size={25}
                                                    round="5px"
                                                    style={{ letterSpacing: 1 }}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                    <Col
                                        span={
                                            item.organization?.name.length > 5
                                                ? 4
                                                : 2
                                        }
                                    >
                                        <div>
                                            <div className="title">
                                                {" "}
                                                {textTruncate(
                                                    item.organization?.name,
                                                    8,
                                                    "..."
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        span={11}
                                        offset={
                                            item.organization?.name.length > 5
                                                ? 2
                                                : 4
                                        }
                                    >
                                        <div className="subtitle">
                                            {textTruncate(
                                                item.project?.name,
                                                18,
                                                "..."
                                            )}
                                        </div>
                                    </Col>
                                    {/* edit options */}
                                    <Col span={1}>
                                        <ProjectListDropdown
                                            options={options}
                                            title={
                                                <EllipsisOutlined
                                                    key="ellipsis"
                                                    rotate={90}
                                                    style={{
                                                        opacity: 1,
                                                        fontSize: 25,
                                                        fontWeight: "bolder",
                                                        verticalAlign: "top",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            }
                                            // className="ellipse-button"
                                            onClick={() => {
                                                history.push(
                                                    `/home/project/manage/${item?.project?.id}`
                                                );
                                                setDropdownActive(false);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {projects.length > 1 && (
                                    <hr className="list-divider" />
                                )}
                            </Fragment>
                        ))
                    ) : (
                        <Row className="list" align="middle">
                            <Col offset={6} span={18}>
                                <div>
                                    <div className="title">
                                        {" "}
                                        <span>NO PROJECTS FOUND</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
                <PrimaryButton
                    onClick={() => {
                        // dispatch(openCreateProjectModal());
                        history.push("/home/project/manage");
                        setDropdownActive(false);
                    }}
                    cssClassName="secondary-button-icon"
                    icon={<PlusOutlined />}
                    title={"Add Project"}
                />
            </div>
            {/* )} */}
        </Fragment>
    );
};
const MemoizedSelect = React.memo(ProjectSelect);
export default MemoizedSelect;
