import React, { useContext, useEffect, useState } from "react";
import { I18nContext } from "utils/i18n/locale";
import Modal, { TYPE_SUCCESS } from "components/atoms/modal";
import "./ApproveFlagModal.scss";
import { Row, Col, Form, DatePicker } from "antd";
import { Select } from "components/atoms/select";
import { StandardInput } from "components/atoms/inputs";
import { useSelector, useDispatch } from "react-redux";
import {
  approveFlag,
  getDepartments,
  getFlags,
  showFlagOnFeed,
  getFlagNoLoad,
} from "redux/slices/flags";
import { SwalAlerts } from "components/atoms/outputs";
import Switch from "components/atoms/switch";
import { useParams } from "react-router-dom";
import { getCurrentProjectId } from "utils/common";

const PRIORITIES = [
  {
    name: "High",
    value: "high",
  },
  {
    name: "Medium",
    value: "medium",
  },
  {
    name: "Low",
    value: "low",
  },
];

const ApproveFlagModal = ({ data, departments, assignedUsers, ...props }) => {
  const objectId = data;
  const mappedDepartments = departments.map(({ name, id }) => {
    return { name: name, value: id };
  });
  const mappedAssignedUsers =
    assignedUsers?.length > 0
      ? assignedUsers?.map(({ user }) => {
          return { name: user.name, value: user.id };
        })
      : [];
  const { translate } = useContext(I18nContext);
  const priorities = PRIORITIES;
  const dispatch = useDispatch();
  const params = useParams();

  const loadingAction = useSelector((state) => state.flags.loadingAction);
  const flag = useSelector((state) => state.flags.flag);
  const flagApproved = useSelector((state) => state.flags.flagApproved);
  const flagApproveFailed = useSelector(
    (state) => state.flags.flagApproveFailed
  );
  const [publishOnFeed, setPublishOnFeed] = useState(false);

  useEffect(() => {
    if (objectId) {
      dispatch(getFlagNoLoad(objectId));
    }
  }, [objectId]);

  useEffect(() => {
    setPublishOnFeed(flag?.public);
  }, [flag]);

  const onFinishFailed = (error) => {};

  const onFinish = (value) => {
    let changeFlag = { show_in_app: publishOnFeed, ...value };
    // dispatch(approveFlag({ id: params.id ?? objectId, ...changeFlag }))
    dispatch(
      showFlagOnFeed({ id: params.id ?? objectId, show_in_app: publishOnFeed })
    )
      .then(() => {
        SwalAlerts({
          title: translate("pages").flags.flagDetails.modal.approveSuccess
            .title,
          text: translate("pages").flags.flagDetails.modal.approveSuccess.text,
          type: "success",
        });
        props.onCancel();
        dispatch(getFlags());
      })
      .catch(() => {
        SwalAlerts({
          title: translate("pages").flags.flagDetails.modal.approveFailed.title,
          text: translate("pages").flags.flagDetails.modal.approveFailed.text,
          type: "error",
        });
      });
  };

  useEffect(() => {
    dispatch(getDepartments(getCurrentProjectId()));
  }, []);

  return (
    <Modal
      className="flag-approve-modal"
      data={data}
      showCloseIcon={false}
      type={TYPE_SUCCESS}
      okText={translate("pages").flags.flagDetails.approve}
      title={translate("pages").flags.flagDetails.approveFlag}
      okButtonProps={{
        form: "approve-flag-form",
        key: "submit",
        htmlType: "submit",
        loading: loadingAction,
      }}
      {...props}
    >
      <div className="approve-modal-category-name">{data?.category}</div>
      <Form
        id="approve-flag-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row className="approve-row-container" gutter={[8, 0]}>
          <Col md={12} xs={24}>
            <Form.Item
              name="department_id"
              rules={[
                {
                  // required: true,
                  message: translate("pages").flags.flagDetails.form.approve
                    .department,
                },
              ]}
            >
              <Select
                placeholder={translate("pages").flags.flagDetails.selectDept}
                options={mappedDepartments}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="assigned_user_id"
              rules={[
                {
                  // required: true,
                  message: translate("pages").flags.flagDetails.form.approve
                    .assigning,
                },
              ]}
            >
              <Select
                placeholder={translate("pages").flags.flagDetails.assigning}
                options={mappedAssignedUsers}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="approve-row-container" gutter={[8, 0]} align="middle">
          <Col md={12} xs={24}>
            <Form.Item
              name="priority"
              rules={[
                {
                  // required: true,
                  message: translate("pages").flags.flagDetails.form.approve
                    .priority,
                },
              ]}
            >
              <Select
                placeholder={translate("pages").flags.flagDetails.priority}
                options={priorities}
              />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              name="due_date"
              rules={[
                {
                  // required: true,
                  message: translate("pages").flags.flagDetails.form.approve
                    .dueDate,
                },
              ]}
            >
              <DatePicker
                placeholder={translate("pages").flags.flagDetails.due}
                className="approve-input"
              />
            </Form.Item>
          </Col>
          <Col>
            <h3>{translate("pages").flags.flagDetails.showOnApp}</h3>
          </Col>
          <Col>
            <Switch
              active={publishOnFeed}
              onToggle={() => setPublishOnFeed(!publishOnFeed)}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default ApproveFlagModal;
