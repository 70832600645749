import React, { useContext, useEffect, useState } from "react";
import { KnowledgeContextProvider } from "context/KnowledgeContext";
import "./KnowledgeCategories.scss";
import { Col, Row } from "antd";
import KnowledgeCategoryCard from "components/molecules/KnowledgeCategoryCard";
import { Text } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";
import KnowledgeCategoryForm from "../KnowledgeCategoryForm";
import { useDispatch, useSelector } from "react-redux";
import { getKnowledgeCategories } from "redux/slices/knowledge";
import bestPractices from "assets/img/best-practices.svg";
import { SpiningPage } from "components/atoms/icons";

const KnowledgeCategories = () => {
  const { categories, setCategories } = useContext(KnowledgeContextProvider);
  const { translate } = useContext(I18nContext);
  const [close, setClose] = useState(false);
  const knowledgeCategoriesFetched = useSelector(
    (state) => state.knowledge.knowledgeCategories
  );
  const loading = useSelector((state) => state.knowledge.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getKnowledgeCategories());
  }, []);
  useEffect(() => {
    setCategories(knowledgeCategoriesFetched);
  }, [knowledgeCategoriesFetched]);

  return (
    <div className="knowledge-categories">
      {loading ? (
        <SpiningPage opacity={1} />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            {categories?.map(({ image, title, description, posts }, index) => (
              <Col xs={24} lg={12} key={index}>
                <KnowledgeCategoryCard
                  image={image ?? bestPractices}
                  isLocal={!image}
                  title={title}
                  description={description}
                  noOfPosts={posts?.length}
                />
              </Col>
            ))}
          </Row>
          <Text
            text={translate("pages").knowledge.title.addCategory}
            className="add-knowledge-category"
            onClick={() => setClose(true)}
          />
          <KnowledgeCategoryForm close={close} setClose={setClose} />
        </>
      )}
    </div>
  );
};

export default KnowledgeCategories;
