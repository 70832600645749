import Events from "pages/Events";
import EventsManagement from "pages/Events/EventManagement";

const prefix = "/events";

const routes = [
  {
    path: `${prefix}`,
    component: Events,
    exact: true,
  },
  {
    path: `${prefix}/manage`,
    component: EventsManagement,
    exact: true,
  },
];

export default routes;
