import React, { useContext, useEffect, useState } from "react";
import TabsMenu from "components/atoms/tabs";
import { Route, useRouteMatch, withRouter } from "react-router-dom";
import BrandingPage from "pages/Settings/Branding";
import UserPage from "pages/Settings/Users/index";
import UserDetailPage from "pages/Settings/UserDetail";
import Department from "pages/Settings/Department";
import Category from "pages/Settings/Category";
import { I18nContext } from "utils/i18n/locale";
import "./Settings.scss";
import GeneralSettings from "./General";
import { useSelector } from "react-redux";

const Settings = ({ history }) => {
  const { translate } = useContext(I18nContext);
  const [activeTab, setActiveTab] = useState("users");
  const currentTab = history.location.pathname.split("/")[2];
  let { path } = useRouteMatch();

  const [defaultActiveKey, setDefaultActiveKey] = useState(currentTab);

  const currentProject = useSelector((state) => state.projects.currentProject);
  const [permissions, setPermissions] = useState(currentProject?.permissions);
  useEffect(() => {
    if (currentProject) {
      setPermissions(currentProject.permissions);
    }
  }, [currentProject]);

  const tabChildren = (
    <>
      <Route path={`${path}/users`} component={UserPage} exact />
      <Route path={`${path}/branding`} component={BrandingPage} exact />
      <Route
        path={`${path}/general-settings`}
        component={GeneralSettings}
        exact
      />
      <Route path={`${path}/users/:id`} component={UserDetailPage} />
      <Route path={`${path}/department`} component={Department} exact />
      <Route path={`${path}/categories`} component={Department} exact />
    </>
  );

  useEffect(() => {
    const newTab = history.location.pathname.split("/")[3];
    if (newTab === undefined) {
      history.replace("/home/settings/users");
    } else {
      setActiveTab(newTab);
    }
  }, [history.location.pathname.split("/")[2]]);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="settings-main-title">
        {translate("pages").settings.title}
      </div>
      <TabsMenu
        baseRoute="home/settings"
        onChange={onTabChange}
        // onChange={(item) => history.push(`home/settings/${item}`)}
        activeKey={activeTab}
        options={[
          {
            title: translate("pages").settings.tab.users,
            key: "users",
            children: <UserPage />,
          },
          // {
          //   title: translate("pages").settings.tab.branding,
          //   key: "branding",
          //   children: <BrandingPage />,
          // },
          // {
          //   title: translate("pages").settings.tab.department,
          //   key: "department",
          //   children: <Department />,
          // },
          permissions?.settings?.categories?.read
            ? {
                title: translate("pages").settings.tab.category,
                key: "categories",
                children: <Category />,
              }
            : "",
          // {
          //   title: translate("pages").settings.tab.generalSettings,
          //   key: "general-settings",
          //   children: <GeneralSettings />,
          // },
        ]}
      />
    </>
  );
};

export default withRouter(Settings);
