// import React, { useEffect, useState, useContext } from "react";
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { apiInstance as axios } from "config/axios/axios.api.config";
import { getProfile } from "redux/slices/profile";
// import { I18nContext } from "utils/i18n/locale";
// import accountAPI from "api/accountAPI";

import { SpiningPage } from "components/atoms/icons";
import { useDispatch, useSelector } from "react-redux";

export const AuthContext = React.createContext();

const AuthenticationContextProvider = (props) => {
  const location = useLocation();
  // const { dispatch } = useContext(I18nContext);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [cookies, setCookies] = useCookies();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile.profile);
  const profileLoaded = useSelector((state) => state.profile.profile);

  // const profile = useSelector((state) => state.profile.profile);

  const loadProfile = () => {
    dispatch(getProfile());
  };

  useEffect(() => {
    if (profile?.sub) {
      window.location.replace(`/verify?logout=true`);
    }
  }, [profile]);

  useEffect(() => {
    const cookieSessionName = "c_access";

    Auth.currentAuthenticatedUser()
      .then((user) => {
        const token = user.signInUserSession.accessToken.jwtToken;
        const expiredIn = user.signInUserSession.accessToken.payload.exp;
        axios.defaults.headers.common.Authorization = `${token}`;
        // setUser(user);
        setCookies(cookieSessionName, token, {
          path: "/",
          maxAge: expiredIn,
          httpOnly: false,
          secure: false,
          sameSite: false,
        });
        let current_project = localStorage.getItem("current_project");
        if (!current_project) {
          loadProfile();
        }
      })
      .catch((err) => {
        handleGoLogin();
      });

    // if (cookies[cookieSessionName]) {
    //   console.log(
    //     "Entra al if porque existe la cookie: ",
    //     cookies[cookieSessionName]
    //   );
    //   setLoadingUser(true);

    //   // accountAPI
    //   //   .getUser()
    //   //   .then(({ data }) => {
    //   //     dispatch({
    //   //       type: "setLanguage",
    //   //       payload: data.lang,
    //   //     });
    //   //     setUser(data);
    //   //     setLoadingUser(false);
    //   //   })
    //   //   .catch((e) => {
    //   //     setLoadingUser(false);
    //   //     handleGoLogin();
    //   //   });
    //   setLoadingUser(false);
    // } else {
    //   handleGoLogin();
    //   setLoadingUser(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoLogin = () => {
    // history.push({ pathname: "/login", search: location?.search });
    window.location.replace(`/login${location?.search}`);
  };

  return (
    <AuthContext.Provider value={{ user }}>
      {!loadingUser ? props.children : <SpiningPage opacity={1} />}
    </AuthContext.Provider>
  );
};

export default AuthenticationContextProvider;
