import React, {useContext} from 'react'
import {I18nContext} from "utils/i18n/locale";
import {LAYOUT, LEGEND_DIRECTION, LEGEND_POSITION} from "components/molecules/Graphs";
import LinePlot from "components/molecules/Graphs/Line";

const FlagStatusLineChart = () => {
	const {translate} = useContext(I18nContext);

	// This should be replaced with an API Call
	const DATA = [
		{
			"x": '2018-01-11',
			"y": 300,
			"category": "Pending",
		},
		{
			"x": '2018-01-12',
			"y": 300,
			"category": "Pending",
		},
		{
			"x": '2018-01-13',
			"y": 300,
			"category": "Pending",
		},
		{
			"x": '2018-01-14',
			"y": 300,
			"category": "Pending",
		},
		{
			"x": '2018-01-15',
			"y": 300,
			"category": "Pending",
		},
		{
			"x": '2018-01-16',
			"y": 300,
			"category": "Pending",
		},
		{
			"x": '2018-01-17',
			"y": 300,
			"category": "Pending",
		},
		{
			"x": '2018-01-18',
			"y": 300,
			"category": "Pending",
		},
		{
			"x": '2018-01-19',
			"y": 300,
			"category": "Pending",
		},
		{
			"x": '2018-01-20',
			"y": 300,
			"category": "Pending",
		},{
			"x": '2018-01-11',
			"y": 100,
			"category": "Open",
		},
		{
			"x": '2018-01-12',
			"y": 100,
			"category": "Open",
		},
		{
			"x": '2018-01-13',
			"y": 100,
			"category": "Open",
		},
		{
			"x": '2018-01-14',
			"y": 100,
			"category": "Open",
		},
		{
			"x": '2018-01-15',
			"y": 100,
			"category": "Open",
		},
		{
			"x": '2018-01-16',
			"y": 100,
			"category": "Open",
		},
		{
			"x": '2018-01-17',
			"y": 100,
			"category": "Open",
		},
		{
			"x": '2018-01-18',
			"y": 100,
			"category": "Open",
		},
		{
			"x": '2018-01-19',
			"y": 100,
			"category": "Open",
		},
		{
			"x": '2018-01-20',
			"y": 100,
			"category": "Open",
		},
	]

	return (
		<LinePlot
			height='100%'
			legendPosition={LEGEND_POSITION.BOTTOM}
			legendDirection={LEGEND_DIRECTION.ROW}
			layout={LAYOUT.HORIZONTAL}
			title={translate("pages").analytics.flagStatus}
			data={DATA}
			indexBy="category"
			maxXScale={10}
			maxYScale={1000}
			animate={true}
			enableSlices='x'
			xScale={{
				type: 'time',
				format: '%Y-%m-%d',
				useUTC: false,
				precision: 'day',
			}}
			yScale={{
				max: 400
			}}
			varyingSize={false}
			keyPoints={{
				"Pending": "#75FACF",
				"Open": "#DA8BE3",
			}}/>
	)
}

export default FlagStatusLineChart;
