import DataTable from "components/organisms/DataTable";
import CardContainer from "components/organisms/EventCardContainer";
import { Fragment, useContext, useEffect, useState } from "react";
import { Col, Divider, Row } from "antd";
import { PrimaryButton } from "components/atoms/controls";
import { I18nContext } from "utils/i18n/locale";
import SearchBar from "components/atoms/search";
import { SwitchButton } from "components/atoms/controls";
import { PlusOutlined } from "@ant-design/icons";
import "./ResourcesOrganism.scss";
import EventSidebar from "../EventSidebar";
import { useHistory } from "react-router-dom";
import { ListViewIcon, GridIcon } from "components/atoms/icons";

const ResourcesOrganism = (props) => {
  const {
    type,
    handleView,
    handleSearch,
    handleFilter,
    sidebarItems,
    handleSaveTopic,
  } = props;

  const history = useHistory();
  const { translate } = useContext(I18nContext);
  const [grouped, setGrouped] = useState([]);
  let gropedByTopicID;

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x.resource_category);
      return rv;
    }, {});
  };

  useEffect(() => {
    gropedByTopicID = groupBy(sidebarItems, "resource_category_id");
    let aux = {};
    let GroupedAux = [];
    for (const element in gropedByTopicID) {
      aux = {
        id: gropedByTopicID[element][0]?.id,
        name: gropedByTopicID[element][0]?.name,
        count: gropedByTopicID[element]?.length,
      };
      GroupedAux.push(aux);
    }
    setGrouped(GroupedAux);
  }, [sidebarItems]);

  const deletedWorkspaces = {
    count: 0,
    workspaces: [],
  };

  const dummieTopics = [
    {
      id: 1,
      name: "General",
    },
  ];

  const [topics, setTopics] = useState(dummieTopics);
  const redirectToCreateResource = () => {
    history.push({
      pathname: "/home/resources/manage/",
      state: { resource: undefined },
    });
  };
  return (
    <Fragment>
      <Row
        justify="end"
        align="top"
        gutter={[12, 6]}
        className="resources-organism-container"
      >
        <Col xl={9} md={24} sm={24} xs={24}>
          <span className="title">{translate("pages").resources.title}</span>
        </Col>
        <Col xxl={7} xl={7} md={24} sm={24} xs={24}>
          <SearchBar
            placeholder={translate("pages").resources.searchBox.placeholder}
            handleSearch={handleSearch}
          />
        </Col>
        <Col xxl={5} xl={5} md={24} sm={24} xs={24}>
          <PrimaryButton
            onClick={() => {
              redirectToCreateResource();
            }}
            cssClassName="primary-button-icon"
            icon={<PlusOutlined />}
            title={translate("pages").resources.buttons.createResource}
          />
        </Col>
        {/* <Col xxl={0} xl={0} lg={0} md={24} sm={24} xs={24}>
          <SelectBox
            placeholder={
              translate("pages").resources.createEventForm.placeholders.topic
            }
            onChange={(e) => {
              console.log(e);
            }}
            items={topics}
          />
        </Col> */}
        <Col xl={3} lg={3} md={4} sm={4} xs={6}>
          <SwitchButton
            leftIcon={<GridIcon />}
            rightIcon={<ListViewIcon />}
            handleView={handleView}
          />
        </Col>
      </Row>
      <Divider />

      <Row>
        <Col xxl={24 / 4} xl={24 / 4} lg={24 / 3} md={0} sm={0} xs={0}>
          {grouped && grouped.length > 0 && (
            <EventSidebar
              workspaces={grouped}
              deletedWorkspaces={deletedWorkspaces}
              eventCount={10}
              handleFilter={handleFilter}
              handleSaveTopic={handleSaveTopic}
            />
          )}
        </Col>
        <Col xxl={18} xl={18} lg={16} md={24} sm={24} xs={24}>
          <div className="resources-organism-container ">
            <span className="subtitle">
              {translate("pages").resources.subtitle}
            </span>
          </div>
          {type === "list" ? (
            <DataTable props={props} />
          ) : (
            <CardContainer props={props} />
          )}
        </Col>
      </Row>
    </Fragment>
  );
};
export default ResourcesOrganism;
