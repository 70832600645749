import React, {useContext} from 'react';
import "./Viewevent.scss";
import {BackNonArrowIcon} from "components/atoms/icons";
import { Text } from 'components/atoms/texts';
import { useHistory } from 'react-router-dom';

const ViewEventTopbar = (props) => {
  const {goBack} = useHistory();
  const {title} = props
  return (
    <div className="view-event-topbar-container">
      <div className="icon" onClick={() => goBack()}>
        <BackNonArrowIcon />
      </div>
      <div className="head">
        <Text
          text={title}
          className="view-event-text"
        />
      </div>
    </div>
  )
}

export default ViewEventTopbar;
