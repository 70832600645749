import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, Row, Col } from "antd";
import { DataInput } from "components/atoms/inputs";
import { PrimaryButton } from "components/atoms/controls";
import { I18nContext } from "utils/i18n/locale";
import BottomDrawer from "components/atoms/bottom-drawer";
import { cloudFrontUrl, getCurrentOrganizationId } from "utils/common";
import "./CreateCategoryForm.scss";
import { CloseOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategories,
  getCategories,
  updateCategories,
} from "redux/slices/categories";
import { SwalAlerts } from "components/atoms/outputs";
import { unwrapResult } from "@reduxjs/toolkit";
import { IconPicker, IconPickerItem } from "react-fa-icon-picker";
import IconSelector from "./IconSelector";
import MemoizedAvatar from "components/molecules/ProjectSelect/Avatar";

const ActionFooter = ({ onSubmit, loading }) => {
  return (
    <Row justify="end" align="middle" gutter={2}>
      <PrimaryButton
        onClick={onSubmit}
        htmlType="submit"
        cssClassName="save-btn"
        title={"Save"}
        loading={loading}
      />
    </Row>
  );
};

const CreateCategoryForm = ({
  show,
  onClose,
  currentId,
  state,
  setState,
  isEdit,
  currentCategory,
  handleRemoveSubCategory,
}) => {
  const { translate } = useContext(I18nContext);

  const formRef = createRef();
  const node = useRef();

  const loading = useSelector((state) => state.categories.loadingAction);
  const [categoryName, setCategoryName] = useState(currentCategory?.name);
  const [icon, setIcon] = useState(currentCategory?.image);
  const [subCategories, setSubcategories] = useState(currentCategory?.typename);
  const [subCategoriesAux, setSubcategoriesAux] = useState(
    currentCategory?.typename
  );
  const [auxSubCategory, setAuxSubCategory] = useState("");
  const [showIconSelector, setShowIconSelector] = useState(false);
  // const [icon, setIcon] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (formRef.current) {
      formRef.current.resetFields();
      setSubcategories([]);
      setCategoryName(null);
      setIcon(null);
    }
    return () => {
      if (formRef.current) {
        formRef.current.resetFields();
      }
    };
  }, [show]);

  useEffect(() => {
    console.log({ currentCategory });
    if (currentCategory?.name) {
      setCategoryName(currentCategory?.name);
      formRef.current?.setFieldsValue({
        category: currentCategory?.name,
      });
    }
    if (currentCategory?.typename) {
      setSubcategories(currentCategory?.typename);
      setSubcategoriesAux(currentCategory?.typename);
    }
    if (currentCategory?.image) {
      setIcon(currentCategory.image);
    }
  }, [currentCategory]);

  const handleNewSubCategory = (key) => {
    let found = false;
    if (key === "Enter") {
      if (subCategories.length > 0) {
        subCategories.filter(function (value) {
          if (
            value.name === auxSubCategory.name ||
            auxSubCategory.name === "" ||
            !auxSubCategory.name
          ) {
            setSubcategories([...subCategories]);
            found = true;
          } else if (!found) {
            setSubcategories([...subCategories, auxSubCategory]);
          }
        });
      } else {
        setSubcategories([...subCategories, auxSubCategory]);
      }
      formRef.current?.setFieldsValue({
        subcategory: "",
      });
      setAuxSubCategory("");
    }
  };

  const handleSave = async (values) => {
    if (isEdit) {
      let updatedValues = {
        id: currentCategory?.id,
        ...values,
      };
      updatedValues.subcategories = updatedValues.subcategories.slice(
        subCategoriesAux.length
      );
      dispatch(updateCategories(updatedValues))
        .then(unwrapResult)
        .then(() => {
          SwalAlerts({
            title: translate("pages").events.alerts.save.success.title,
            text: translate("pages").events.alerts.save.success.subtitle,
            type: "success",
          });
          onClose();
          dispatch(getCategories());
          setIcon("");
        })
        .catch(() => {
          SwalAlerts({
            title: translate("pages").events.alerts.save.error.title,
            text: translate("pages").events.alerts.save.error.subtitle,
            type: "error",
          });
        });
    } else {
      dispatch(createCategories(values))
        .then(unwrapResult)
        .then(() => {
          SwalAlerts({
            title: translate("pages").events.alerts.save.success.title,
            text: translate("pages").events.alerts.save.success.subtitle,
            type: "success",
          });
          onClose();
          dispatch(getCategories());
        })
        .catch(() => {
          SwalAlerts({
            title: translate("pages").events.alerts.save.error.title,
            text: translate("pages").events.alerts.save.error.subtitle,
            type: "error",
          });
        });
    }
  };

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    // outside click
    setShowIconSelector(false);
  };

  useEffect(() => {
    if (showIconSelector) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showIconSelector]);

  const onFinish = () => {
    let createCategory = {
      name: categoryName,
      organization_id: parseInt(getCurrentOrganizationId()),
      subcategories: subCategories,
      image: icon,
    };
    formRef.current
      .validateFields()
      .then(() => {
        handleSave(createCategory);
      })
      .catch((err) => {
        console.error(err, "Missing fields");
      });
  };

  return (
    <BottomDrawer
      isSmall
      className="bottom-altern"
      // className="department-bottom-drawer"
      footer={<ActionFooter onSubmit={onFinish} loading={loading} />}
      onClose={onClose}
      open={show}
      title={
        currentId !== null
          ? translate("pages").settings.category.form.edit
          : translate("pages").settings.category.form.create
      }
    >
      <Form
        className="category-form"
        ref={formRef}
        onValuesChange={({}, { category, subcategory }) => (
          category && setCategoryName(category),
          subcategory && setAuxSubCategory({ name: subcategory })
          // console.log(subcategory)
        )}
        onFinish={onFinish}
      >
        <Form.Item
          name="category"
          rules={[
            {
              max: 25,
              message:
                "The name for your category must be 25 characters or less",
            },
            {
              required: true,
              message: "Category is required",
            },
          ]}
          initialValue={isEdit ? categoryName : ""}
        >
          <DataInput
            placeholder={
              translate("pages").settings.category.placeholders.inputs.category
            }
            title={translate("pages").settings.category.labels.category}
            allowClear
          />
        </Form.Item>

        <Form.Item name="image">
          <Row>
            <Col span={6}>
              <h3>{translate("pages").feed.newsForm.labels.selectImage}</h3>
            </Col>
            <Col span={18}>
              <span
                style={{
                  position: "absolute",
                  opacity: 0.5,
                  fontSize: 16,
                  border: "dashed .01px rgba(20, 20, 20, 0.38)",
                  height: "50px",
                  width: "100%",
                  padding: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowIconSelector(!showIconSelector);
                }}
              >
                {translate("pages").settings.category.placeholders.inputs.icon}
                <span style={{ marginLeft: 80 }}>
                  <PlusCircleTwoTone
                    twoToneColor="#B0B0B0"
                    style={{ fontSize: 24 }}
                  />
                </span>
              </span>
              <div ref={node} hidden={!showIconSelector}>
                <IconSelector
                  onSelect={setShowIconSelector}
                  setIcon={setIcon}
                />
              </div>

              {/* <IconPicker
                // value={"Hola"}
                onChange={(v) => setIcon(v)}
                containerStyles={{ width: "100%", border: "solid .1vh" }}
                pickerIconStyles={{
                  // backgroundColor: "red",
                  border: "solid .001vmin",
                  borderColor: "#B0B0B0",
                  margin: 3,
                  padding: 6,
                  width: 42,
                }}
                buttonStyles={{
                  width: "100%",
                  border: "dashed .01px",
                  height: "50px",
                  color: "rgba(20, 20, 20, 0.38)",
                }}
              /> */}
            </Col>
            <Col
              offset={6}
              style={{
                marginTop: 20,
              }}
            >
              <span>
                {icon && (
                  <MemoizedAvatar
                    size={30}
                    className={null}
                    src={icon ? cloudFrontUrl(icon) : ""}
                    // logo={icon}
                    noPlaceHolder
                    // customPlaceHolder="https://image.flaticon.com/icons/png/128/727/727399.png"
                  />
                )}
              </span>
              {/* <IconPickerItem icon={icon} size={30} /> */}
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          name="subcategory"
          rules={[
            {
              // required: true,
            },
          ]}
          value
          initialValue={auxSubCategory}
          // initialValue={isEdit ? subCategories : ""}
        >
          <DataInput
            placeholder={
              translate("pages").settings.category.placeholders.inputs
                .subCategory
            }
            title={translate("pages").settings.category.labels.subCategory}
            allowClear
            onKeyDown={(e) => handleNewSubCategory(e.key)}
          />
        </Form.Item>
        {subCategories?.length > 0 && (
          <div className="overflow-wrapper">
            <div className="selected-container-cat">
              {subCategories.map((subCategory) => (
                <div className="selected-items">
                  <div className="selected-items-wrapper">
                    <p className="selected-item-text">{subCategory?.name}</p>
                  </div>
                  <div className="selected-items-wrapper">
                    {!subCategoriesAux.find((aux) => aux === subCategory) ? (
                      <div
                        className="cross-icon"
                        onClick={() => {
                          let filtered = subCategories.filter(function (value) {
                            if (value.name !== subCategory.name) {
                              return value;
                            }
                          });
                          setSubcategories(filtered);
                        }}
                      >
                        <CloseOutlined color="#5C5C5C" fontSize={8.17} />
                      </div>
                    ) : (
                      <div
                        className="cross-icon"
                        onClick={() => {
                          handleRemoveSubCategory(subCategory.id)
                            .then((data) => {
                              let filtered = subCategories.filter(function (
                                value
                              ) {
                                if (value.name !== subCategory.name) {
                                  return value;
                                }
                              });
                              setSubcategories(filtered);
                              dispatch(getCategories())
                            })
                            .catch((error) => {
                              console.log("error");
                            });
                        }}
                      >
                        <CloseOutlined color="#5C5C5C" fontSize={8.17} />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Form>
    </BottomDrawer>
  );
};
export default CreateCategoryForm;
