import { Form, Image, Layout } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {  useDispatch, useSelector } from "react-redux";
import "client/pages/EditProfile/EditProfile.scss";
import { Text } from "components/atoms/texts";
import { PrimaryButton } from "components/atoms/controls";
import Profile from "assets/img/profileClient.png";
import { DataInput } from "components/atoms/inputs";
import { I18nContext } from "utils/i18n/locale";
import { Select } from "client/components/atoms/select";
import CheckboxSingle from "components/atoms/checkbox";
import MonthlyCalendar from "components/organisms/MonthlyCalendar";
import BackIcon from "assets/img/back.svg";
import moment from "moment";
import { readCitizenProfile, updateCitizenProfile } from "redux/slices/Citizen";
import { getProfileResponses } from "redux/slices/surveys";
import { updateProfileQuestions } from "redux/slices/responses";

import { useHistory } from "react-router";
import { Alert } from "components/atoms/outputs";
import { Tabs } from "antd";
import { cloudFrontUrl } from "utils/common";
import { uploadDrag } from "components/atoms/media";

const EditProfile = () => {
  const history = useHistory();

  const { translate } = useContext(I18nContext);
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState();
  const [dateAux, setDateAux] = useState(undefined);
  const PRETY_DATE_FORMAT = "DD/MM/YYYY";
  // const MAX_IMAGE_SIZE = 500000;
  const { TabPane } = Tabs;

  const {
    surveys: { surveyResponsesFetched, surveyResponses },
  } = useSelector((state) => state);

  const [currentPageResponses, setCurrentPageResponses] = useState([]);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [profileState, setProfileState] = useState({});
  const [answerId, setAnswerId] = useState(0);

  useEffect(() => {
    console.log("surveyResponses", surveyResponses);
    if (
      surveyResponses &&
      surveyResponses != undefined &&
      surveyResponses != null
    ) {
      if (surveyResponses?.survey_answer) {
        console.log(
          "surveyResponses",
          surveyResponses?.survey_answer[0]?.answer
        );

        const tempArray = surveyResponses?.survey_answer[0]?.answer;
        setAnswerId(surveyResponses?.survey_answer[0]?.id);
        if (tempArray === undefined) return;
        for (const item of tempArray) {
          const itemId = item.question_id;
          console.log(
            "itemId",
            item.response[Object.keys(item.response)[0]][0]
          );
          setUserAnswers((prevAns) => ({
            ...prevAns,
            [itemId]: item.response[Object.keys(item.response)[0]][0],
          }));
        }

        setCurrentPageResponses(surveyResponses?.survey_answer[0]?.answer);
      }

      if (surveyResponses?.question) {
        setCurrentQuestions(surveyResponses?.question);
      }
    }
  }, [surveyResponsesFetched]);

  useEffect(() => {
    console.log("surveyResponses", surveyResponses);
    if (
      surveyResponses &&
      surveyResponses != undefined &&
      surveyResponses != null
    ) {
      if (surveyResponses?.survey_answer) {
        console.log(
          "surveyResponses",
          surveyResponses?.survey_answer[0]?.answer
        );

        const tempArray = surveyResponses?.survey_answer[0]?.answer;
        setAnswerId(surveyResponses?.survey_answer[0]?.id);
        if (tempArray === undefined) return;
        for (const item of tempArray) {
          const itemId = item.question_id;
          console.log(
            "itemId",
            item.response[Object.keys(item.response)[0]][0]
          );
          setUserAnswers((prevAns) => ({
            ...prevAns,
            [itemId]: item.response[Object.keys(item.response)[0]][0],
          }));
        }

        setCurrentPageResponses(surveyResponses?.survey_answer[0]?.answer);
      }
    }
  }, [currentQuestions]);
  const ethnicityValues = [
    {
      name: "White",
      value: "1",
    },
    {
      name: "Hispanic or Latino",
      value: "2",
    },
    {
      name: "Black or African American",
      value: "3",
    },
    {
      name: "Native American or American Indian",
      value: "4",
    },
    {
      name: "Asian / Pacific Islander",
      value: "5",
    },
    {
      name: "Other",
      value: "6",
    },
  ];
  const genderValues = [
    {
      name: "Not known",
      value: "0",
    },
    {
      name: "Male",
      value: "1",
    },
    {
      name: "Female",
      value: "2",
    },
    {
      name: "Non Binary",
      value: "3",
    },
  ];

  const onFinish = (values) => {
    formSubmit();
  };

  const onFinishProfile = (values) => {
    formSubmit();
  };

  const [localState, setLocalState] = useState({
    zipcode: "",
    residentType: "",
    name: "",
    last_name: "",
    birthday: "",
    gender: "",
    ethnicity: "",
  });
  const {
    citizen: { citizenProfile, loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const formRef = React.createRef();
  const questionRef = React.createRef();

  const [data, setData] = useState(citizenProfile);
  const [displayImage, setDisplayImage] = useState();
  const [uploadedImage, setUploadedImage] = useState();
  const [localImage, setLocalImage] = useState();

  const handleInputProfileChange = (text, name) => {
    console.log("text", text.target.value);
    console.log("name", name);

    // HANDLE UPDATING TEXT FROM INPUT //
    setProfileState((prevState) => ({
      ...prevState,
      [name]: text.target.value,
    }));
  };
  const handleInputChange = (text, name) => {
    console.log("text", text.target.value);
    console.log("name", name);

    // HANDLE UPDATING TEXT FROM INPUT //
    setLocalState((prevState) => ({
      ...prevState,
      [name]: text.target.value,
    }));
  };

  const selectChangeGender = (value) => {
    console.log(`selected ${value}`);

    const dataToUse = genderValues[value].value;

    setLocalState((prevState) => ({
      ...prevState,
      ["gender"]: dataToUse,
    }));
  };

  const selectChangeEthnicity = (value) => {
    console.log(`selected ${value}`);
    const dataToUse = ethnicityValues[value - 1].value;
    setLocalState((prevState) => ({
      ...prevState,
      ["ethnicity"]: dataToUse,
    }));
  };

  const citizenUpdated = useSelector(
    (state) => state.citizen.citizenProfileUpdate
  );
  const citizenProfileUpdated = useSelector(
    (state) => state.responses.questionsUpdated
  );
  const citizenProfileLoading = useSelector((state) => state.responses.loading);
  const [citizenConfirmation, setcitizenConfirmation] =
    useState(citizenUpdated);

  useEffect(() => {
    dispatch(readCitizenProfile());
    dispatch(getProfileResponses("47"));
    //dispatch(getProfileResponses("76"));
  }, []);

  useEffect(() => {
    if (citizenUpdated) {
      Alert("success", "Profile updated");
    }
  }, [citizenUpdated]);
  useEffect(() => {
    if (citizenProfileUpdated) {
      Alert("success", "Profile questions updated");
    }
  }, [citizenProfileUpdated]);

  const getImage = (key) => {
    if (key !== null) {
      const url = cloudFrontUrl(key);
      return url; // get key from Storage.list
    } else {
      return Profile;
    }
  };

  const printImage = (imageData) => {
    setDisplayImage(getImage(imageData));
  };
  useEffect(() => {
    console.log("citizenProfile", citizenProfile);
    setData(citizenProfile);
    if (citizenProfile && citizenProfile.image) {
      printImage(citizenProfile.image);
    } else {
      setDisplayImage(Profile);
    }
  }, [citizenProfile]);
  useEffect(() => {
    formRef?.current?.resetFields();
  }, [data]);
  const hiddenFileInput = React.useRef(null);

  const handleImageClick = () => {
    hiddenFileInput.current.click();
  };
  const makeId = (length) => {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const updateProfile = async () => {
    var tempArray = [];

    Object.entries(profileState).forEach(([key, value]) => {
      var keyData = key.toString();
      const tempValue = [];
      tempValue.push(value);
      console.log("tempValue", tempValue);

      tempArray.push({
        response: { [keyData]: tempValue },
        question_id: keyData,
        resources: [],
      });
      console.log(keyData, value);
    });

    const itemToSend = {
      answers: tempArray,
      id: answerId,
    };
    dispatch(updateProfileQuestions(itemToSend));
  };
  const formSubmit = async () => {
    setLocalState((prevState) => ({
      ...prevState,
      ["updated_at"]: new Date(),
    }));

    console.log("localState", localState);

    if (localImage && localImage != "") {
      const uploadName = makeId(10) + ".jpeg";

      return new Promise((resolve, reject) => {
        uploadDrag("userProfiles", localImage).then((result) => {
          console.log("result", result.key);

          const dataToSend = {
            image: result.key,
          };

          if (date && date != "") {
            dataToSend.birthday = date;
          }
          if (localState.ethnicity && localState.ethnicity != "") {
            dataToSend.ethnicity = localState.ethnicity;
          }
          if (localState.gender && localState.gender != "") {
            dataToSend.gender = localState.gender;
          }
          if (localState.name && localState.name != "") {
            dataToSend.name = localState.name;
          }
          if (localState.last_name && localState.last_name != "") {
            dataToSend.last_name = localState.last_name;
          }

          if (localState.zipcode && localState.zipcode != "") {
            dataToSend.zipcode = localState.zipcode;
          }

          if (localState.self_gender && localState.self_gender != "") {
            dataToSend.self_gender = localState.self_gender;
          }

          console.log("data", dataToSend);

          dispatch(updateCitizenProfile(dataToSend));
        });
      });
    } else {
      const dataToSend = {};

      if (date && date != "") {
        dataToSend.birthday = date;
      }
      if (localState.ethnicity && localState.ethnicity != "") {
        dataToSend.ethnicity = localState.ethnicity;
      }
      if (localState.gender && localState.gender != "") {
        dataToSend.gender = localState.gender;
      }
      if (localState.name && localState.name != "") {
        dataToSend.name = localState.name;
      }
      if (localState.last_name && localState.last_name != "") {
        dataToSend.last_name = localState.last_name;
      }

      if (localState.zipcode && localState.zipcode != "") {
        dataToSend.zipcode = localState.zipcode;
      }
      if (localState.self_gender && localState.self_gender != "") {
        dataToSend.self_gender = localState.self_gender;
      }

      console.log("data", dataToSend);

      dispatch(updateCitizenProfile(dataToSend));
    }
  };

  const uploadMedia = async (id) => {
    try {
      let file = document.getElementById(id).files[0];
      setDisplayImage(URL.createObjectURL(file));
      setLocalImage(file);
    } catch (e) {
      console.log(" error", e);
    }
  };

  return (
    <div className="edit-profile-container">
      <div className="back-icon-container">
        <Image
          src={BackIcon}
          preview={false}
          className="back-icon"
          onClick={() => history.push("/launchsa/home")}
        />
      </div>

      <div className="profile-image-container">
        <Image src={displayImage} preview={false} className="profile-image" />
        <Text
          text={translate("client").pages.profile.editProfile}
          className="section-subtitle"
          onClick={() => handleImageClick()}
        />
        <input
          type="file"
          accept="image/*"
          id="file"
          hidden
          ref={hiddenFileInput}
          onChange={() => uploadMedia("file")}
        />
      </div>

      <Tabs defaultActiveKey="1">
        <TabPane
          tab={translate("client").pages.profile.section.residentInfo.title}
          key="1"
          centered={true}
        >
          <Layout className="layout-wrapper">
            <Form
              onFinish={onFinish}
              ref={formRef}
              initialValues={{
                firstName: data?.name,
                lastName: data?.last_name,
                zipCode: data?.zipcode,
              }}
            >
              <Form.Item
                name="zipCode"
                rules={[
                  {
                    required: true,
                    message: "Please input a valid Zipcode.",
                    pattern: /\d{5}$/g,
                  },
                ]}
              >
                <DataInput
                  placeholder={
                    translate("client").pages.profile.section.residentInfo.zip
                  }
                  name="zipCode"
                  customClass="profile-input"
                  onChange={(text) => handleInputChange(text, "zipcode")}
                  maxLength={5}
                />
              </Form.Item>
              <Text
                text={
                  translate("client").pages.profile.section.profileInfo.title
                }
                className="section-title"
              />
              <Form.Item name="firstName">
                <DataInput
                  placeholder={
                    translate("client").pages.profile.section.profileInfo.name
                  }
                  name="firstName"
                  customClass="profile-input"
                  onChange={(text) => handleInputChange(text, "name")}
                />
              </Form.Item>
              <Form.Item name="lastName">
                <DataInput
                  placeholder={
                    translate("client").pages.profile.section.profileInfo
                      .lastName
                  }
                  name="lastName"
                  customClass="profile-input"
                  onChange={(text) => handleInputChange(text, "last_name")}
                />
              </Form.Item>
              <Form.Item name="birthday">
                <div onClick={() => setShowCalendar(true)}>
                  <DataInput
                    placeholder={
                      translate("client").pages.profile.section.profileInfo
                        .birthday
                    }
                    name="birthday"
                    customClass="profile-input"
                    value={moment(
                      date
                        ? date
                        : data?.birthday
                        ? new Date(data.birthday)
                        : date
                    ).format(PRETY_DATE_FORMAT)}
                    datePicker
                  />
                </div>
                {showCalendar && (
                  <div className="calendar-filter-wrapper">
                    <MonthlyCalendar
                      sidePane={false}
                      lg={19}
                      handleCancelClick={() => {
                        setShowCalendar(false);
                        if (!(dateAux !== "" && dateAux !== undefined)) {
                          console.log("1");
                          setDate("");
                        } else if (dateAux !== date) {
                          console.log("2");
                          setDate(dateAux);
                        }
                      }}
                      handleApplyClick={() => {
                        setShowCalendar(false);
                        setDateAux(date);
                      }}
                      setDate={setDate}
                      customClass={"calender"}
                    />
                  </div>
                )}
              </Form.Item>
              <Form.Item name="gender">
                <Select
                  onChange={selectChangeGender}
                  defaultValue={
                    data?.gender
                      ? genderValues[data.gender].value
                      : translate("client").pages.profile.section.profileInfo
                          .gender
                  }
                  name="gender"
                  className="client-select"
                  options={genderValues}
                  floatPlaceholder={
                    translate("client").pages.profile.section.profileInfo.gender
                  }
                />
              </Form.Item>
              <Form.Item name="ethnicity">
                <Select
                  onChange={selectChangeEthnicity}
                  defaultValue={
                    data?.ethnicity && !isNaN(data.ethnicity)
                      ? ethnicityValues[data.ethnicity - 1].value
                      : translate("client").pages.profile.section.profileInfo
                          .ethnicity
                  }
                  name="ethnicity"
                  className={`client-select client-select-margin`}
                  options={ethnicityValues}
                  floatPlaceholder={
                    translate("client").pages.profile.section.profileInfo
                      .ethnicity
                  }
                />
              </Form.Item>
              <div className="checkbox-container">
                <CheckboxSingle
                  text={translate("client").pages.profile.optionReceiveEmails}
                />
              </div>
              <div className="checkbox-container">
                <CheckboxSingle
                  text={
                    translate("client").pages.profile.optionPushNotification
                  }
                />
              </div>
              <Form.Item>
                <PrimaryButton
                  title={translate("client").pages.login.title.updateProfile}
                  className="client-login-button"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={loading}
                />
              </Form.Item>
            </Form>
          </Layout>
        </TabPane>
        <TabPane
          tab={translate("client").pages.profile.section.profileQuestions.title}
          key="2"
        >
          <Layout className="layout-wrapper">
            <Form
              onFinish={updateProfile}
              ref={questionRef}
              initialValues={userAnswers}
            >
              {currentQuestions
                ? currentQuestions.map((item, index) => (
                    <Form.Item name={item.id}>
                      <DataInput
                        placeholder={item.title}
                        name={item.title}
                        customClass="edit-profile-input"
                        onChange={(text) =>
                          handleInputProfileChange(text, item.id)
                        }
                      />
                    </Form.Item>
                  ))
                : null}

              <Form.Item>
                <PrimaryButton
                  title={translate("client").pages.login.title.updateProfile}
                  className="client-login-button"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={citizenProfileLoading}
                />
              </Form.Item>
            </Form>
          </Layout>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default EditProfile;
