import { Layout } from "antd";
import { I18nContext } from "utils/i18n/locale";
import { Text } from "components/atoms/texts";
import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "../index.scss";
import "./Survey.scss";
import {
  BackArrowMobile,
  NoSurvey,
  Cancel,
  SurveyEnd,
  Whatsapp,
  Instagram,
  Twitter,
  Facebook,
} from "assets/svg/mobile";
import SurveyCard from "./surveyCard";
import { RadioGroup } from "components/atoms/inputs";
import { CheckboxSurvey } from "components/atoms/inputs";
import { useHistory } from "react-router";

import { ProgressBar } from "components/atoms/progress";
import { PrimaryButton } from "components/atoms/controls";
import Modal from "components/atoms/modal";
import { withRouter } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getClientSurveys } from "redux/slices/surveys";
import { getSurveyQuestions } from "redux/slices/surveys";
import { createResponses, postAnswers } from "redux/slices/responses";
import { DataInput } from "components/atoms/inputs";

const ClientSurvey = ({ history }) => {
  const { translate } = useContext(I18nContext);

  const [mode, setMode] = useState(false);
  const [modal, setModal] = useState(false);
  const [surveyId, setSurveyId] = useState(0);
  const [currentStyle, setCurrentStyle] = useState(null);

  useEffect(() => {
    const cs_json = JSON.parse(localStorage.getItem("custom_client"));
    if (cs_json) {
      setCurrentStyle(cs_json);
    }
  }, []);

  const {
    surveys: { surveys, loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [projectId, setProjectId] = useState(0);

  useEffect(() => {
    if (projectId != 0) {
      dispatch(getClientSurveys(projectId));
    }
  }, [projectId]);
  useEffect(() => {
    async function getProject() {
      const rawProject = await AsyncStorage.getItem("projectData");
      let project = JSON.parse(rawProject);
      console.log("project", project);
      setProjectId(project.project.id);
    }
    getProject();
  }, []);

  return (
    <div
      className={` client-surveys client-mobile-container ${
        !modal ? "modal-custom" : ""
      }`}
    >
      <Modal
        getContainer="#modalMount"
        visible={modal}
        onCancel={() => {
          history.goBack();
        }}
        footer={null}
        centered
      >
        <div className="survey-end">
          <SurveyEnd />

          <div className="survey-end-text">
            <Text
              noInherit={true}
              className={"survey-end-header"}
              text={translate("pages").mobile.survey.questionEnd}
            />

            <Text
              noInherit={true}
              className={"survey-end-mid-text"}
              text={translate("pages").mobile.survey.questionText}
            />

            <Text
              noInherit={true}
              className={"survey-end-footer"}
              text={translate("pages").mobile.survey.shareTo}
            />
          </div>

          <div className="survey-social">
            <Facebook />
            <Instagram />
            <Twitter />
            <Whatsapp />
          </div>
        </div>
      </Modal>
      <Layout id="modalMount" className="mobile-wrapper ">
        <div className="header">
          {mode ? (
            <Cancel
              onClick={(e) => {
                setMode(false);
              }}
            />
          ) : (
            <BackArrowMobile onClick={() => history.goBack()} />
          )}

          <Text
            className={"survey-title"}
            text={
              currentStyle
                ? "Questionnaire"
                : translate("pages").mobile.survey.header
            }
          />
        </div>
        <div className="survey-content">
          {surveys.length > 0 &&
            (mode ? (
              <Questions
                setMode={setMode}
                setModal={setModal}
                surveyId={surveyId}
                currentStyle={currentStyle}
              ></Questions>
            ) : (
              <SurveyAvailable
                setMode={setMode}
                surveys={surveys}
                setSurveyId={setSurveyId}
                customClient={currentStyle}
                projectId={projectId}
              />
            ))}
          {surveys.length === 0 && (
            <div className="no-survey">
              <NoSurvey />

              <Text
                className={"no-survey-title"}
                noInherit={true}
                text={translate("pages").mobile.survey.noSurvey}
              />
            </div>
          )}
        </div>
      </Layout>
    </div>
  );
};

const SurveyAvailable = ({ surveys, setMode, setSurveyId, customClient,projectId }) => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="client-surveys">
      <Text
        noInherit={true}
        className={"survey-content-title"}
        text={
          customClient
            ? "Complete the following questionnaires"
            : translate("pages").mobile.survey.subText
        }
      />

      <div className="client-survey-cards-holder">
        {surveys.map((survey) => {
          return (
            <SurveyCard
              survey={survey}
              onClick={(e) => {
                setSurveyId(survey.id);
                setMode(true);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const Questions = ({ setModal, setMode, surveyId, currentStyle,projectId }) => {
  const { translate } = useContext(I18nContext);
  const [index, setIndex] = useState(0);
  const [user, setUser] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const dispatch = useDispatch();
  const [response, setResponse] = useState([]);

  const responseCreated = useSelector((state) => state.responses.response);
  const {
    surveys: { surveyQuestions },
  } = useSelector((state) => state);
  const answerCreated = useSelector((state) => state.responses.answers);
  useEffect(() => {
    setResponse(responseCreated);
  }, [responseCreated]);
  useEffect(() => {
    async function getUser() {
      const rawUser = await AsyncStorage.getItem("citizenData");
      let parsedUser = JSON.parse(rawUser);
      setUser(parsedUser);
    }
    getUser();
    setIndex(0);
    //  setCompleted(false);
    setUserAnswers({});
  }, []);
  const onChangeRadio = (selected, question) => {
    console.log("selected", selected);

    setUserAnswers((prevAns) => ({
      ...prevAns,
      [question.id]: selected.target.value,
    }));
  };
  const onChange = (selected, question, index) => {
    const tempArr = [];

    for (const sel of selected) {
      tempArr.push(sel);
    }

    console.log("tempArr", tempArr);

    setUserAnswers((prevAns) => ({
      ...prevAns,
      [question.id]: tempArr,
    }));
  };

  useEffect(() => {
    console.log("setUserAnswers changed", userAnswers);
  }, [userAnswers]);

  const onChangeField = (e, current) => {
    console.log("value", e.target.value);
    setUserAnswers((prevAns) => ({
      ...prevAns,
      [surveyQuestions[current].id]: e.target.value,
    }));
  };

  useEffect(() => {
    if (user != null) {
      const itemToSend = {
        survey_id: surveyId,
        project_id: projectId,
        organization_id: 1,
        user_id: user.id,
      };
      console.log(itemToSend);
      dispatch(createResponses(itemToSend));
      dispatch(getSurveyQuestions(surveyId));
    }
  }, [user]);

  const changeIndex = (directions) => {
    if (directions === 0) {
      if (index > 0) {
        setIndex(index - 1);
      } else {
        setMode(false);
      }
    } else {
      if (index < surveyQuestions.length - 1) {
        console.log("userAnswers", userAnswers);

        var value = userAnswers[surveyQuestions[index].id];
        console.log("value", value);
        console.log("currentIndex", index);
        console.log(
          "surveyQuestions[currentIndex].id",
          surveyQuestions[index].id
        );
        const itemToSend = {
          id: response.id,
          response: {
            [surveyQuestions[index].id]: [value],
          },
          project_id: projectId,
          organization_id: 1,
          completion_time: 0,
        };
        console.log("itemToSend", itemToSend);
        dispatch(postAnswers(itemToSend));
        setIndex(index + 1);
      } else {
        console.log("userAnswers", userAnswers);

        var value = userAnswers[surveyQuestions[index].id];
        console.log("value", value);
        console.log("currentIndex", index);
        console.log(
          "surveyQuestions[currentIndex].id",
          surveyQuestions[index].id
        );
        const itemToSend = {
          id: response.id,
          response: {
            [surveyQuestions[index].id]: [value],
          },
          project_id: projectId,
          organization_id: 1,
          completion_time: 0,
        };
        console.log("itemToSend", itemToSend);
        dispatch(postAnswers(itemToSend));
        setIndex(index + 1);

        setModal(true);
      }
    }
  };

  return (
    <div className="client-surveys">
      <Text
        noInherit={true}
        className={"survey-question-header"}
        text={`${translate("pages").mobile.survey.questionsText} ${index + 1} ${
          translate("pages").mobile.survey.questionsText2
        } ${surveyQuestions.length}`}
      />
      <ProgressBar
        trailColor={currentStyle ? "#F7B334" : "#10F1C3"}
        strokeColor={currentStyle ? "#F38B00" : "#10F1C3"}
        percent={100}
        successPercent={((index + 1) / surveyQuestions.length) * 100}
      />
      <Question
        setIndex={changeIndex}
        index={index}
        question={surveyQuestions[index]}
        userAnswers={userAnswers}
        onChangeField={onChangeField}
        onChange={onChange}
        onChangeRadio={onChangeRadio}
        currentStyle={currentStyle}
      />
    </div>
  );
};

const Question = ({
  setIndex,
  index,
  question,
  onChangeField,
  onChange,
  userAnswers,
  onChangeRadio,
  currentStyle,
}) => {
  const { translate } = useContext(I18nContext);
  const [state, setState] = useState(false);

  return (
    <div className="client-surveys">
      <div className="survey-question">
        <Text
          noInherit={true}
          className={"survey-question"}
          text={question && question.title ? question.title : ""}
        />
        {question &&
        question.question_type_id &&
        question.question_type_id == "1" ? (
          <RadioGroup
            className={"radio-input"}
            onChange={(e) => onChangeRadio(e, question)}
            value={userAnswers[question.id] ? userAnswers[question.id] : ""}
            options={question.options}
          />
        ) : question &&
          question.question_type_id &&
          question.question_type_id == "2" ? (
          <CheckboxSurvey
            className={"radio-input"}
            onChange={(e) => onChange(e, question, index)}
            options={question.options.map((ele) => ele.title)}
            value={userAnswers[question.id] ? userAnswers[question.id] : []}
          />
        ) : question &&
          question.question_type_id &&
          question.question_type_id == "3" ? (
          <RadioGroup
            className={"radio-input"}
            onChange={(e) => onChangeRadio(e, question)}
            value={userAnswers[question.id] ? userAnswers[question.id] : ""}
            options={["True,False"]}
          />
        ) : (
          <DataInput
            type="textarea"
            rows={3}
            id={index}
            onChange={(value) => onChangeField(value, index)}
            value={
              question && userAnswers[question.id]
                ? userAnswers[question.id]
                : ""
            }
          />
        )}
        <div className="survey-button">
          <PrimaryButton
            color="secondary"
            title={translate("pages").mobile.survey.buttonBack}
            onClick={() => {
              setIndex(0);
            }}
          />
          <PrimaryButton
            title={translate("pages").mobile.survey.buttonNext}
            onClick={() => {
              setIndex(1);
            }}
            style={currentStyle ? currentStyle.buttons : {}}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ClientSurvey);
