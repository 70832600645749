import React, {useContext} from 'react'
import {I18nContext} from "utils/i18n/locale";
import PieGraph, {TYPE} from "components/molecules/Graphs/Pie";

const FlagLifecycle = () => {
	const {translate} = useContext(I18nContext);

	// This should be replaced with an API Call
	const DATA = [
		{
			"id": "1",
			"label": '1',
			"value": 50,
			"color": "#00B999"
		},
		{
			"id": "2",
			"label": '2',
			"value": 38,
			"color": "#D1E76C"
		},
		{
			"id": "3",
			"label": '3',
			"value": 12,
			"color": "#C43A5A"
		}
	]

	return (
		<PieGraph
			height="100%"
			type={TYPE.FULL}
			showLegend={false}
			reverse={true}
			title={translate("pages").analytic.editor.sidePane.menu.flagLifecycle}
			data={DATA} />
	)
}

export default FlagLifecycle;
