import React, {useContext} from 'react'
import BarGraph from "components/molecules/Graphs/Bar";
import {I18nContext} from "utils/i18n/locale";
import {LEGEND_DIRECTION, LEGEND_POSITION} from "components/molecules/Graphs";

const InputBySubcategory = () => {
	const {translate} = useContext(I18nContext);

	// This should be replaced with an API Call
	const BAR_BY_CATEGORY = [
		{
			category: "Environment",
			value: 2,
			color: "#00B999"
		},
		{
			category: "Building and Streets",
			value: 4,
			color: "#28E1C1"
		},
		{
			category: "Traffic and Transport",
			value: 8,
			color: "#6DFFE2"
		},
		{
			category: "Community Safety",
			value: 6.5,
			color: "#3CF5D5"
		},
		{
			category: "Leisure and Recreation",
			value: 2.5,
			color: "#14CDAD"
		},
		{
			category: "Community Facilities",
			value: 6,
			color: "#61E6BB"
		},
		{
			category: "Housing",
			value: 1,
			color: "#75FACF"
		},
	]

	return (
		<BarGraph
			height='100%'
			legendPosition={LEGEND_POSITION.RIGHT}
			legendDirection={LEGEND_DIRECTION.COLUMN}
			title={translate("pages").analytics.bySubcategory}
			indexBy="category" data={BAR_BY_CATEGORY} />
	)
}

export default InputBySubcategory;
