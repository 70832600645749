import React, { useContext, useState } from "react";
import { Col, Form, Layout, Row } from "antd";
import {
  BoolIcon,
  TextIcon,
  MultiCheckIcon,
  CheckIcon,
  NavigateIcon,
  ReloadIcon,
  SettingIcon,
} from "components/atoms/icons";
import "./SurveyQuestionCreate.scss";
import { CreateElementInput } from "components/atoms/inputs";
import { I18nContext } from "utils/i18n/locale";
import { PrimaryButton } from "components/atoms/controls";
import { SpinLoader } from "components/atoms/icons";

const SurveyQuestionCreate = ({
  questionType,
  index,
  onCreate,
  question,
  choices: defaultChoices,
  handleAction,
  questionCreating,
  setQuestionCreating,
}) => {
  const { translate } = useContext(I18nContext);
  const [choicesCount, setChoicesCount] = useState(
    defaultChoices ? defaultChoices.length + 1 : 1
  );
  const ChoicesInputGenerator = (count, onClick) => {
    let choices = [];
    const handleIncrease = (index) => {
      if (index === choices.length - 1) {
        onClick();
      }
    };

    for (let index = 0; index < count; index++) {
      choices.push(
        <Form.Item
          name={`choice${index + 1}`}
          initialValue={
            defaultChoices ? defaultChoices[index]?.title : undefined
          }
        >
          <CreateElementInput
            invisible
            placeholder={translate("pages").surveyEditor.placeholder.choice}
            className="question"
            onClick={() => handleIncrease(index)}
            defaultValue={defaultChoices ? defaultChoices[index] : undefined}
          />
        </Form.Item>
      );
    }
    return choices;
  };
  const handleFinish = (values) => {
    setQuestionCreating(true);
    const { question, ...choices } = values;
    let data = {};
    Object.entries(choices).length === 0
      ? (data = {
          question,
        })
      : (data = {
          question,
          options: [...Object.values(choices)]
            .filter((choice) => choice !== undefined)
            .map((ele) => ({ title: ele, valid: true })),
        });
    onCreate(data, index);
  };
  const handleActionSelected = (key, index) => {
    handleAction(key, index);
  };

  return (
    <Layout className="question-create-layout">
      <Form onFinish={handleFinish}>
        <Row className="question-container">
          <Col xs={24} lg={20} xl={20}>
            {questionType === "Multiple Choice- Single" ? (
              <div className="icon check-icon">
                <CheckIcon color="#fff" opacity={1} /> {index + 1}
              </div>
            ) : questionType === "Multiple Choice- Multiple" ? (
              <div className="icon multi-check-icon">
                <MultiCheckIcon /> {index + 1}
              </div>
            ) : questionType === "True or False" ? (
              <div className="icon bool-icon">
                <BoolIcon /> {index + 1}
              </div>
            ) : questionType === "Text Field" ? (
              <div className="icon text-icon">
                <TextIcon /> {index + 1}
              </div>
            ) : null}
            <div className="input-container">
              <Form.Item
                name="question"
                initialValue={question ? question : undefined}
              >
                <CreateElementInput
                  invisible
                  placeholder={
                    translate("pages").surveyEditor.placeholder.typeQuestion
                  }
                  className="question"
                  defaultValue={question ? question : undefined}
                  key={index}
                />
              </Form.Item>
              {(questionType === "Multiple Choice- Single" ||
                questionType === "Multiple Choice- Multiple") &&
                ChoicesInputGenerator(choicesCount, () =>
                  setChoicesCount(choicesCount + 1)
                )}
            </div>
          </Col>
          <Col xs={24} lg={4} xl={4} className="question-save">
            <PrimaryButton
              title={
                questionCreating ? (
                  <SpinLoader color="#fff" opacity={1} fontSize={20} />
                ) : (
                  translate("pages").surveyEditor.title.save
                )
              }
              htmlType="submit"
            />
          </Col>
        </Row>
      </Form>
      <Row className="question-actions">
        {/* TODO: handle functions of a question */}
        {/* <ReloadIcon className="action-icons" />
        <SettingIcon
          className="action-icons"
          onClick={() => handleActionSelected("settings", index)}
        /> */}
        <NavigateIcon className="action-icons" />
      </Row>
    </Layout>
  );
};

export default SurveyQuestionCreate;
