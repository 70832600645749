import React, {useContext} from 'react';
import "./fab.scss";
import {I18nContext} from "utils/i18n/locale";

const Fab = ({icon, onClick, isOpen}) => {
  const {translate} = useContext(I18nContext);

  return (
    <div className="fab-flex">
      <div className={`base-container ${"container-large background-close" }`}
        onClick={onClick}
        >
        <div className="child-container">
          {icon?.()}
        </div>
      </div>
    </div>
)};

export default Fab;
