import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

import "./search.scss";

const SearchBar = ({ placeholder, handleSearch, setSearch, className }) => {
  const onSearch = (e) => {
    let isEmpty;
    if (e.target.value) {
      if (e.target.value === "") {
        isEmpty = true;
      } else {
        isEmpty = false;
      }
    } else {
      if (e.currentTarget) {
        if (e.currentTarget.value === "") {
          isEmpty = true;
        } else {
          isEmpty = false;
        }
      }
    }

    if(setSearch){
      setSearch(e.target.value)
    } else {
      handleSearch(e.target.value, isEmpty);
    }
  };
  return (
    <Input
      prefix={<SearchOutlined className="soft-icon" />}
      placeholder={placeholder}
      allowClear
      onChange={onSearch}
      className={`search-bar ${className}`}
    />
  );
};

export default SearchBar;
