import { PrimaryButton } from "components/atoms/controls";
import { Text } from "components/atoms/texts";
import { Title } from "components/atoms/texts";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { I18nContext } from "utils/i18n/locale";
import "./EmptyDirectoryCard.scss"

const EmptyDirectoryCard = () => {

  const {translate} = useContext(I18nContext)
  return (
    <div className="empty-card-container">
      <Title
        className="empty-directory-title"
        level={3}
        text={translate("pages").directory.title.noDirectory}
      />

      <Text
        className="no-directory-text"
        text={translate("pages").directory.title.noDirectoryText}
      />
      <Link to="/home/surveys/survey-gallery/">
        <PrimaryButton
          title={translate("pages").directory.title.noDirectoryButton}
          cssClassName="no-directory-button"
        />
      </Link>
    </div>
  );
};

export default EmptyDirectoryCard;
