import React, { useContext } from 'react';
import "./Eventstopbar.scss";
import {BackNonArrowIcon, MenuIcon, CalendarIcon} from "components/atoms/icons";
import { Text } from 'components/atoms/texts';
import { useHistory } from 'react-router-dom';
import {CaretDownOutlined} from '@ant-design/icons'
import { Image } from "antd";
import Calendar from "assets/svgs/CalendarSvg.svg";
import { NotificationContextProvider } from "client/context/NotificationContext";


const EventsTopbar = ({eventsList, openCalendar, header}) => {
  const history = useHistory();
   const { setVisible, setSidebarVisible } = useContext(
     NotificationContextProvider
   );
  return(
    <div className="events-topbar-container">
      <div className="back-button" onClick={() => history.goBack()}>
        <BackNonArrowIcon />
      </div>
      <div className="calendar-item" onClick={() => openCalendar(true)}>
        <Image  src={Calendar} preview={false} />
        <Text 
          text={header}
          className="calendar-text"
        />
        <CaretDownOutlined />
      </div>
      {eventsList.length ?
      <div className="drawer-item">
        <MenuIcon onClick={()=>setSidebarVisible(true)} />
      </div>: null}
    </div>
  )
}

export default EventsTopbar;