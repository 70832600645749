import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
} from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { SpiningPage } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import { Row, Col, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AlertWithfunctions, SwalAlerts } from "components/atoms/outputs";
import { Modal } from "antd";
import { CreateElementInput } from "components/atoms/inputs";
import "./Events.scss";
import { PrimaryButton } from "components/atoms/controls";
import {
  asyncGetData,
  getEvents,
  getEventsTopics,
  deleteEvents,
  duplicateEvents,
  publishEvents,
  createEventsTopics,
} from "redux/slices/events";
import EventsOrgnism from "components/organisms/EventsOrganism";
import { useHistory } from "react-router-dom";
import noEvents from "assets/img/illustrations/no-events.svg";
const EmptyComponentTemplate = lazy(() =>
  import("components/templates/EmptyComponentTemplate")
);

const renderLoader = () => <SpiningPage opacity={1} />;

const Events = (props) => {
  const { translate } = useContext(I18nContext);
  const eventsFetched = useSelector((state) => state.events.events);
  const loading = useSelector((state) => state.events.loading);
  const eventsTopicsFetched = useSelector((state) => state.events.eventsTopics);
  const eventsUpdated = useSelector((state) => state.events.eventsUpdated);

  const [modalVisible, setModalVisible] = useState(false);
  const [searchRef, setSearchRef] = useState(0);
  const isMounted = useRef(null);
  const dispatch = useDispatch();
  let history = useHistory();

  const [events, setEvents] = useState(eventsFetched);
  const [eventsAux, setEventsAux] = useState(eventsFetched);
  const [eventTopics, setEventTopics] = useState(eventsTopicsFetched);
  const [view, setView] = useState("cards");

  useEffect(() => {
    let updateValue = { previewData: {} };
    dispatch(asyncGetData(updateValue));
    dispatch(getEvents());
    dispatch(getEventsTopics());
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setEvents(eventsFetched);
    setEventsAux(eventsFetched);
    setEventTopics(eventsTopicsFetched);
  }, [eventsFetched, eventsTopicsFetched]);

  useEffect(() => {
    if (eventsUpdated) {
      dispatch(getEvents());
      dispatch(getEventsTopics());
    }
  }, [eventsUpdated]);
  const OpenCreateEvent = () => {
    setModalVisible(true);
  };

  const handleSearch = (query, isEmpty) => {
    let result = eventsAux.filter((n) =>
      n.title.toLowerCase().includes(query.toLowerCase())
    );
    if (searchRef === result.length) {
    } else {
      if (result.length <= 0) {
        setEvents([{}]);
      } else {
        if (isEmpty) {
          setEvents(eventsAux);
        } else {
          setEvents(result);
        }
      }
    }
    setSearchRef(result.length);
  };

  const handleFilter = (query, isEmpty) => {
    if (query === "all") {
      setEvents(eventsAux);
    } else {
      let result = eventsAux.filter((n) =>
        n.topic_id.toString().includes(query.toString())
      );
      if (result.length <= 0) {
        setEvents([{}]);
      } else {
        if (result.length === eventsAux.length) {
          return;
        } else {
          if (isEmpty) {
            setEvents(eventsAux);
          } else {
            setEvents(result);
          }
        }
      }
    }
  };

  const handleView = (view) => {
    setView(view);
  };

  const getEventTitle = (values) => {
    let updateValue = { title: values.title };
    dispatch(asyncGetData(updateValue));
    history.push({
      pathname: "/home/events/manage/",
      state: { event: updateValue },
    });
  };

  const handleEdit = (item) => {
    history.push({
      pathname: "/home/events/manage/",
      state: { event: item },
    });
  };

  const handleSaveTopic = async (values) => {
    dispatch(createEventsTopics(values))
      .then(unwrapResult)
      .then(() => {
        SwalAlerts({
          title: translate("pages").events.alerts.save.success.title,
          text: translate("pages").events.alerts.save.success.subtitle,
          type: "success",
        });
      })
      .catch(() => {
        SwalAlerts({
          title: translate("pages").events.alerts.save.error.title,
          text: translate("pages").events.alerts.save.error.subtitle,
          type: "error",
        });
      });
  };

  const handleCopy = (item) => {
    const info = {
      title: translate("pages").events.alerts.duplicate.question.title,
      text: translate("pages").events.alerts.duplicate.question.subtitle,
      icon: "info",
      confirm: translate("pages").events.alerts.duplicate.question.buttons
        .confirm,
      successTitle: translate("pages").events.alerts.duplicate.success.title,
      successSubtitle: translate("pages").events.alerts.duplicate.success
        .subtitle,
      errorTitle: translate("pages").events.alerts.duplicate.error.title,
      errorSubtitle: translate("pages").events.alerts.duplicate.error.subtitle,
      dispatchFunction: duplicateEvents,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };
  const handleDelete = (item) => {
    const info = {
      title: translate("pages").events.alerts.delete.question.title,
      text: translate("pages").events.alerts.delete.question.subtitle,
      icon: "info",
      confirm: translate("pages").events.alerts.delete.question.buttons.confirm,
      successTitle: translate("pages").events.alerts.delete.success.title,
      successSubtitle: translate("pages").events.alerts.delete.success.subtitle,
      errorTitle: translate("pages").events.alerts.delete.error.title,
      errorSubtitle: translate("pages").events.alerts.delete.error.subtitle,
      dispatchFunction: deleteEvents,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };
  const handlePublish = (item) => {
    const info = {
      title: translate("pages").events.alerts.publish.question.title,
      text: translate("pages").events.alerts.publish.question.subtitle,
      icon: "info",
      confirm: translate("pages").events.alerts.publish.question.buttons
        .confirm,
      successTitle: translate("pages").events.alerts.publish.success.title,
      successSubtitle: translate("pages").events.alerts.publish.success
        .subtitle,
      errorTitle: translate("pages").events.alerts.publish.error.title,
      errorSubtitle: translate("pages").events.alerts.publish.error.subtitle,
      dispatchFunction: publishEvents,
      dispatcher: dispatch,
      unwrapResult,
      values: item,
    };
    AlertWithfunctions(info);
  };

  return (
    <Suspense fallback={renderLoader()}>
      {loading && <SpiningPage opacity={1} />}

      {modalVisible && (
        <Modal
          className="events"
          title={translate("pages").events.createEventModal.title}
          centered
          visible={modalVisible}
          onOk={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
          footer={false}
        >
          <Form name="event-name" onFinish={getEventTitle}>
            <Row justify="end">
              <Col span={24}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: translate("pages").events.createEventModal
                        .validateTitle,
                    },
                  ]}
                >
                  <CreateElementInput
                    placeholder={
                      translate("pages").events.createEventModal.placeholder
                    }
                    label={translate("pages").events.createEventModal.label}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <PrimaryButton
                    htmlType="submit"
                    cssClassName="events save-button"
                    title={translate("pages").events.createEventModal.button}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {events && events.length <= 0 && !loading && (
        <EmptyComponentTemplate
          image={noEvents}
          onButtonClick={OpenCreateEvent}
          title={translate("pages").events.notFound.title}
          subtitle={translate("pages").events.notFound.description}
          buttonText={translate("pages").events.notFound.buttons.createEvent}
        />
      )}
      {events && events.length > 0 && !loading && (
        <EventsOrgnism
          items={events}
          sidebarItems={eventsAux}
          type={view}
          categories={eventTopics}
          handleView={handleView}
          handleSearch={handleSearch}
          handleCopy={handleCopy}
          handleDelete={handleDelete}
          handlePublish={handlePublish}
          handleEdit={handleEdit}
          handleFilter={handleFilter}
          handleSaveTopic={handleSaveTopic}
        />
      )}
    </Suspense>
  );
};
export default Events;
