import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryAPI from "api/categoryAPI";
import { getCurrentProjectId } from "utils/common";

const awsErrorSerialized = {
  error: "ASW Error",
};

const awsError = (resp) => {
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};

export const asyncGetData = (previewData) => async (dispatch) => {
  try {
    dispatch(startGetForm());
    dispatch(successGetForm(previewData));
  } catch (error) {
    dispatch(errorGetForm(error.toString()));
  }
};

export const asyncClearData = (previewData) => async (dispatch) => {
  try {
    dispatch(startCleanForm());
    dispatch(successCleanForm(previewData));
  } catch (error) {
    dispatch(errorCleanForm(error.toString()));
  }
};

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const categories = await categoryAPI.getCategories(project_id);
      awsError(categories);
      if (categories.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return categories.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getSubCategories = createAsyncThunk(
  "categories/getSubCategories",
  async (id, { rejectWithValue }) => {
    try {
      const categories = await categoryAPI.getSubCategories(id);
      awsError(categories);
      if (categories.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return categories.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createCategories = createAsyncThunk(
  "categories/createCategories",
  async (item, { rejectWithValue }) => {
    try {
      const project_id = getCurrentProjectId();
      const categories = await categoryAPI.createCategory(item, project_id);
      awsError(categories);
      return categories.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createSubCategories = createAsyncThunk(
  "categories/createSubCategories",
  async (item, { rejectWithValue }) => {
    try {
      const project_id = getCurrentProjectId();
      const categories = await categoryAPI.createSubCategory(item, project_id);
      awsError(categories);
      return categories.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updateCategories = createAsyncThunk(
  "categories/updateCategories",
  async (item, { rejectWithValue }) => {
    try {
      let editCategories = { ...item };
      delete editCategories.id;
      // console.log("Item antes de guardar: ", item);
      const categories = await categoryAPI.updateCategory(
        editCategories,
        item.id
      );
      awsError(categories);
      return categories.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const deleteCategories = createAsyncThunk(
  "categories/deleteCategories",
  async (id, { rejectWithValue }) => {
    try {
      const categories = await categoryAPI.deleteCategory(id);
      console.log({ categories });
      awsError(categories);
      return categories.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const deleteSubCategories = createAsyncThunk(
  "categories/deleteSubCategories",
  async (id, { rejectWithValue }) => {
    try {
      const categories = await categoryAPI.deleteSubCategory(id);
      console.log({ categories });
      awsError(categories);
      return categories.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

const initialState = {
  previewData: {
    subject: "",
    body: "",
    location: "",
    date: "",
  },
  categories: [],
  subCategories: [],
  error: null,
  loading: true,
  categoryUpdated: false,
  categoryCreated: false,
  loadingAction: false,
};

const categories = createSlice({
  name: "categories",
  initialState,
  reducers: {
    startGetForm(state, action) {},
    successGetForm(state, action) {
      state.previewData = { ...state.previewData, ...action.payload };
    },
    errorGetForm(state, action) {
      console.log("error en el preview data", action);
    },
    startCleanForm(state, action) {},
    successCleanForm(state, action) {
      state.previewData = { ...action.payload };
    },
    errorCleanForm(state, action) {
      console.log("error en el clean data", action);
    },
  },
  extraReducers: {
    //Get Categories
    [getCategories.pending]: (state, action) => {
      console.info("getCategories pending");
      state.loading = true;
      state.error = null;
      state.categoriesUpdated = false;
      state.categoriesCreated = false;
      state.categories = [];
    },
    [getCategories.fulfilled]: (state, action) => {
      console.info("getCategories fullfilled");
      state.loading = false;
      state.error = null;
      state.categoriesUpdated = false;
      state.categories = action.payload.body;
    },
    [getCategories.rejected]: (state, action) => {
      console.error("getCategories rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.categories = [];
    },

    //Get SubCategories
    [getSubCategories.pending]: (state, action) => {
      console.info("getSubCategories pending");
      state.loading = true;
      state.error = null;
      state.categoriesUpdated = false;
      state.categoriesCreated = false;
      state.subCategories = [];
    },
    [getSubCategories.fulfilled]: (state, action) => {
      console.info("getSubCategories fullfilled");
      state.loading = false;
      state.error = null;
      state.categoriesUpdated = false;
      state.subCategories = action.payload.body;
    },
    [getSubCategories.rejected]: (state, action) => {
      console.error("getSubCategories rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.subCategories = [];
    },

    //Create Categories
    [createCategories.pending]: (state, action) => {
      console.info("createCategories pending");
      state.loadingAction = true;
      state.error = null;
      state.categoriesUpdated = false;
      state.categoriesCreated = false;
    },
    [createCategories.fulfilled]: (state, action) => {
      console.info("createCategories fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.categoriesCreated = true;
    },
    [createCategories.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("createCategories rejected =>", action.payload);
    },

    //Create SubCategories
    [createSubCategories.pending]: (state, action) => {
      console.info("createSubCategories pending");
      state.loadingAction = true;
      state.error = null;
      state.categoriesUpdated = false;
      state.categoriesCreated = false;
    },
    [createSubCategories.fulfilled]: (state, action) => {
      console.info("createSubCategories fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.categoriesCreated = true;
    },
    [createSubCategories.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("createSubCategories rejected =>", action.payload);
    },

    //Update Categories
    [updateCategories.pending]: (state, action) => {
      console.info("updateCategories pending");
      state.loadingAction = true;
      state.error = null;
      state.categoriesUpdated = false;
      state.categoriesCreated = false;
    },
    [updateCategories.fulfilled]: (state, action) => {
      console.info("updatecategories fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.categoriesCreated = true;
    },
    [updateCategories.rejected]: (state, action) => {
      console.error("updatecategories rejected =>", action.payload);
      state.categoriesCreated = false;
      state.categoriesUpdated = false;
      state.loadingAction = false;
      state.error = action.payload.error;
    },

    //Delete Categories
    [deleteCategories.pending]: (state, action) => {
      console.info("DeleteCategories started");
      state.loadingAction = true;
      state.error = null;
      state.categoriesUpdated = false;
    },
    [deleteCategories.fulfilled]: (state, action) => {
      console.info("DeleteCategories success", state.categoriesUpdated);
      state.loadingAction = false;
      state.error = null;
      state.categoriesUpdated = true;
    },
    [deleteCategories.rejected]: (state, action) => {
      console.error("DeleteCategories error=> ", action.payload.error);
      state.loadingAction = false;
      state.error = null;
      state.categoriesUpdated = false;
      state.error = action.payload.error;
    },

    //Delete SubCategories
    [deleteSubCategories.pending]: (state, action) => {
      console.info("deleteSubCategories started");
      state.loadingAction = true;
      state.error = null;
      state.categoriesUpdated = false;
    },
    [deleteSubCategories.fulfilled]: (state, action) => {
      console.info("deleteSubCategories success", state.categoriesUpdated);
      state.loadingAction = false;
      state.error = null;
      state.categoriesUpdated = true;
    },
    [deleteSubCategories.rejected]: (state, action) => {
      console.error("deleteSubCategories error=> ", action.payload.error);
      state.loadingAction = false;
      state.error = null;
      state.categoriesUpdated = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  startGetForm,
  successGetForm,
  errorGetForm,
  startCleanForm,
  successCleanForm,
  errorCleanForm,
} = categories.actions;
export default categories.reducer;
