import api from "config/axios/axios.api.config";

const prefix = "workers";

const getProfile = () => api.get(`${prefix}/profile`);

const enrollUser = (data) => api.postJson(`${prefix}/organizations`, data);

const updateOrganization = (data, id) => api.put(`organizations/${id}`, data);

//MISSING: api workers.js
const updateProfile = (data) => api.put(`${prefix}/profile`, data);

// eslint-disable-next-line
export default {
    getProfile,
    enrollUser,
    updateProfile,
    updateOrganization,
};
