import React, { useContext } from "react";
import { Text } from "components/atoms/texts";
import "./SurveyResponseParticipationDetail.scss";
import { I18nContext } from "utils/i18n/locale";
import { FieldsetInput } from "components/atoms/inputs";

const SurveyResponseParticipationDetail = ({ details }) => {
  const { translate } = useContext(I18nContext);
  return (
    <div className="survey-response-participation-wrapper">
      <Text
        text={translate("pages").surveyEditor.title.participationDetail}
        className="header-text"
      />
      <FieldsetInput
        legend={translate("pages").surveyEditor.title.email}
        className="header-input"
        value={details?.email ?? "Anonymous"}
      />
      <FieldsetInput
        legend={translate("pages").surveyEditor.title.startedOn}
        className="header-input"
        value={details.startedOn}
      />
      <FieldsetInput
        legend={translate("pages").surveyEditor.title.lastUpdated}
        className="header-input"
        value={details.lastUpdated}
      />
      <FieldsetInput
        legend={translate("pages").surveyEditor.title.duration}
        className="header-input"
        value={details.duration}
      />
    </div>
  );
};

export default SurveyResponseParticipationDetail;
