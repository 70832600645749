import React from "react";
import { Checkbox } from "antd";

const CheckboxSingle = ({ ...props }) => {
  return (
    <Checkbox onChange={props.onChange} id={props.id} {...props}>
      {props.text || ""}
    </Checkbox>
  );
};

export default CheckboxSingle;
