import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import SideBar from "components/organisms/SideBar";
import NavBar from "components/organisms/NavBar";
import AuthenticationContextProvider from "context/AuthenticationContext";
import Breadcrum from "components/atoms/breadcrums";
import "./MainLayout.scss";
import SurveyEditorContext from "context/SurveyEditorContext";
import CreateProjectTabs from "components/organisms/CreateProjectTabs";
import { useDispatch, useSelector } from "react-redux";
import { closeCreateProjectModal } from "redux/slices/projects";
import KnowledgeContext from "context/KnowledgeContext";
import SignUpModal from "./SignUpModal";

const MainLayout = (props) => {
  const reduxModalOpened = useSelector((state) => state.projects.isModalOpened);
  const [isModalOpen, openModal] = useState(reduxModalOpened);
  const { Content } = Layout;
  const dispatch = useDispatch();

  const handleModalOpen = () => {
    dispatch(closeCreateProjectModal());
  };

  useEffect(() => {
    openModal(reduxModalOpened);
    // reduxModalOpened?openModal(re)
    // if (reduxModalOpened){

    // } openModal(reduxModalOpened);
  }, [reduxModalOpened]);
  return (
    <AuthenticationContextProvider>
      <Layout style={{ height: window.innerHeight }} className="app-layout">
        <SideBar />
        <Layout className="app-layout-content" >
          <NavBar className="navbar" />
          <SurveyEditorContext>
            <KnowledgeContext>
              <Content className="app-container">
                <Breadcrum />
                <div className="app-content-child">{props.children}</div>
                <CreateProjectTabs
                  onClose={() => handleModalOpen()}
                  show={isModalOpen}
                />
                <SignUpModal />
              </Content>
            </KnowledgeContext>
          </SurveyEditorContext>
        </Layout>
      </Layout>
      {/* <Layout style={{ height: window.innerHeight }} className="app-layout">
        <SideBar />
        <Layout className="app-layout-content">
        <NavBar className="navbar" />
          <SurveyEditorContext>
            <KnowledgeContext>
              <Content className="app-container">
                <Breadcrum />
                <div className="app-content-child">{props.children}</div>
                <CreateProjectTabs
                  onClose={() => handleModalOpen()}
                  show={isModalOpen}
                />
                <SignUpModal />
              </Content>
            </KnowledgeContext>
          </SurveyEditorContext>
        </Layout>
      </Layout> */}
    </AuthenticationContextProvider>
  );
};
export default MainLayout;
