import React, { useContext, useEffect, useState } from "react";
import Layout from "antd/lib/layout/layout";
import "./UserListOrganism.scss";
import UserListHeader from "components/molecules/UserListHeader";
import UserListTable from "components/organisms/UserListTable";
import { I18nContext } from "utils/i18n/locale";
import UserListModal from "components/organisms/UserListModal";
import { useDispatch, useSelector } from "react-redux";
import {
  disableUser,
  enableUser,
  getProjectUsers,
} from "redux/slices/projects";
import { unwrapResult } from "@reduxjs/toolkit";
import { SwalAlerts } from "components/atoms/outputs";
import emptyUserSvg from "assets/img/empty-user.svg";
import { SpiningPage } from "components/atoms/icons";
import EmptyUserCard from "components/molecules/EmptyUserCard";
import { Title } from "components/atoms/texts";

const UserListOrganism = ({ openDrawer, openUserDetail }) => {
  const usersFetched = useSelector((state) => state.projects.users);
  const loading = useSelector((state) => state.projects.loadingUsers);
  const { translate } = useContext(I18nContext);
  const [tableData, setTableData] = useState(usersFetched);
  const [modal, setModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [showDropdown, setShowDropdown] = useState("1");

  const dispatch = useDispatch();

  const openDeactivateModal = (id) => {
    setModal(true);
    setCurrentId(id);
  };

  const closeModal = () => {
    setModal(false);
    setCurrentId(null);
  };

  const onDeactivate = () => {};

  const handleSearch = (query, isEmpty) => {
    if (!isEmpty) {
    }
  };

  const changeStatus = (value, id) => {
    if (value === "enable") {
      dispatch(enableUser(id))
        .then(unwrapResult)
        .then(() => {
          SwalAlerts({
            title: translate("pages").settings.alerts.enableUser.success.title,
            text: translate("pages").settings.alerts.enableUser.success
              .subtitle,
            type: "success",
          });
        })
        .catch(() => {
          SwalAlerts({
            title: translate("pages").settings.alerts.enableUser.error.title,
            text: translate("pages").settings.alerts.enableUser.error.subtitle,
            type: "error",
          });
        });
    }
    if (value === "disable") {
      dispatch(disableUser(id))
        .then(unwrapResult)
        .then(() => {
          SwalAlerts({
            title: translate("pages").settings.alerts.disableUser.success.title,
            text: translate("pages").settings.alerts.disableUser.success
              .subtitle,
            type: "success",
          });
        })
        .catch(() => {
          SwalAlerts({
            title: translate("pages").settings.alerts.disableUser.error.title,
            text: translate("pages").settings.alerts.disableUser.error.subtitle,
            type: "error",
          });
        });
    }
  };

  useEffect(() => {
    dispatch(getProjectUsers());
    // window.history.pushState({}, null, "home/settings/users");
  }, []);

  useEffect(() => {
    setTableData(usersFetched);
  }, [usersFetched]);

  return (
    <Layout flex="auto" className="user-list-layout">
      {/* {loading && <SpiningPage opacity={1} />} */}
      <UserListHeader openDrawer={openDrawer} handleSearch={handleSearch} />
      {tableData?.length <= 0 && !loading ? (
        <div style={{ textAlign: "center" }}>
          <img
            className="placeholder-icon"
            src={emptyUserSvg}
            alt={translate("pages").users.placeholder.noUser}
          />
          <Title level={3} text={translate("pages").users.placeholder.noUser} />
        </div>
      ) : (
        <UserListTable
          loading={loading}
          data={tableData}
          onAdd={() => {}}
          onClick={(e) => console.log(e)}
          openEditModal={openDrawer}
          openDeactivateModal={openDeactivateModal}
          onClick={openUserDetail}
          showDropdown={showDropdown}
          changeStatus={changeStatus}
        />
      )}

      <UserListModal
        show={modal}
        translate={translate}
        closeModal={closeModal}
        onDeactivate={onDeactivate}
      />
    </Layout>
  );
};

export default UserListOrganism;
