import React from "react";
import { Col, Divider, Row } from "antd";
import "./QuestionSettingsToggleAction.scss";
import { Text } from "components/atoms/texts";
import { Switch } from "antd";

const QuestionSettingsToggleAction = ({
  text,
  actionState,
  defaultChecked,
  actionKey,
  className,
}) => {
  return (
    <div className={`toggle-action-wrapper ${className}`}>
      <Row>
        <Col lg={12}>
          <Text text={text} />
        </Col>
        <Col lg={12} className="toggle-icon">
          <Switch
            defaultChecked={defaultChecked}
            onChange={(checked) => actionState(checked, actionKey)}
          />
        </Col>
      </Row>
      <Divider />
    </div>
  );
};

export default QuestionSettingsToggleAction;
