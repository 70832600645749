import React, { useEffect, useState } from "react";
import { Button } from "antd";
import "./fab.scss";
import { primary_style } from "config/styles/button";
import { useSelector } from "react-redux";

const FAB = ({
  icon,
  size = "large",
  style = { position: "fixed", right: 32, bottom: 32 },
  ...props
}) => {
  const userSettings = useSelector((state) => state.projects.settings);
  const [settings, setSettings] = useState(userSettings);
  useEffect(() => {
    setSettings(userSettings);
  }, [userSettings]);

  return (
    <Button
      {...props}
      className="fab-main"
      style={{
        backgroundColor: settings?.sibebar_color,
        borderColor: settings?.sibebar_color,
        ...style,
      }}
      type="primary"
      size={size}
      shape="circle"
      icon={icon}
    />
  );
};

export default FAB;
