import React from "react";

import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import queryString from "query-string";
import axios from "axios";
import { signOut } from "api/amplifyAuthAPI";

class Verify extends React.PureComponent {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  componentDidMount() {
    const { logout, token, expireIn } = queryString.parse(
      this.props.location.search
    );

    if (logout) this.handleClearCredentials();
    else this.credentialsRedirection(token, expireIn);
  }

  handleSaveCredentials(accessToken, expiredIn) {
    const { cookies } = this.props;
    // axios.defaults.headers.Authorization = `${accessToken}`;
    // axios.defaults.headers.common.Authorization = `${accessToken}`;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      cookies.set("c_access", accessToken, {
        path: "/",
        maxAge: expiredIn,
        httpOnly: false,
        secure: false,
        sameSite: false,
      });
    } else {
      // protect cookie
      cookies.set("c_access", accessToken, {
        path: "/",
        maxAge: expiredIn,
        httpOnly: false,
        secure: false,
        sameSite: false,
      });
    }

    this.redirectToHomePage();
  }

  handleClearCredentials() {
    const { cookies } = this.props;
    cookies.remove("c_access");
    signOut();
    localStorage.clear();
    this.credentialsRedirection();
  }

  credentialsRedirection(token, expireIn) {
    const { cookies } = this.props;
    if (token && expireIn) {
      this.handleSaveCredentials(token, expireIn);
    } else if (cookies.get("c_access")) {
      this.redirectToHomePage();
    } else {
      this.redirectToLoginPage();
    }
  }

  redirectToHomePage = () => {
    this.props.history.push("home/analytics/demo");
  };

  redirectToLoginPage = (errors) => {
    const { cookies } = this.props;
    const organization = cookies.get("organization");
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      cookies.remove("organization");
      // this.props.history.push(`/login`);
      window.location.replace(
        organization === "launchsa" ? `/login?organization=launchsa` : "/login"
      );
    } else {
      cookies.remove("organization");
      // this.props.history.push(`/login`);
      window.location.replace(
        organization === "launchsa" ? `/login?organization=launchsa` : "/login"
      );
    }
  };

  render() {
    return <div>Espere...</div>;
  }
}

export default withCookies(Verify);
