import DataTable from "components/organisms/DataTable";
import CardContainer from "components/organisms/CardContainer";
import { Fragment, useContext, useEffect, useState } from "react";
import { Col, Divider, Row } from "antd";
import { PrimaryButton } from "components/atoms/controls";
import { I18nContext } from "utils/i18n/locale";
import SearchBar from "components/atoms/search";
import { SwitchButton } from "components/atoms/controls";
import { PlusOutlined } from "@ant-design/icons";
import "./EventsOrganism.scss";
import { useHistory } from "react-router-dom";
import { SelectBox } from "components/atoms/inputs";
import { ListViewIcon, GridIcon } from "components/atoms/icons";

const NotificationsOrganism = (props) => {
  const {
    type,
    handleView,
    handleSearch,
    handleFilter,
    categories,
    items,
    sidebarItems,
    handleSaveTopic,
  } = props;

  const history = useHistory();
  const { translate } = useContext(I18nContext);

  const redirectToCreateNotification = () => {
    history.push({
      pathname: "/home/notifications/manage/",
      state: { event: undefined },
    });
  };
  return (
    <Fragment>
      <Row justify="end" gutter={[12, 6]} className="news-organism-container">
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
          <span className="title">
            {translate("pages").notifications.title}
          </span>
        </Col>
        <Col xl={7} lg={7} md={24} sm={24} xs={24}>
          <SearchBar
            placeholder={translate("pages").notifications.searchBox.placeholder}
            handleSearch={handleSearch}
          />
        </Col>
        <Col xl={6} lg={6} md={24} sm={24} xs={24}>
          <PrimaryButton
            onClick={() => {
              redirectToCreateNotification();
            }}
            cssClassName="primary-button-icon"
            icon={<PlusOutlined />}
            title={translate("pages").notifications.buttons.createEvent}
          />
        </Col>
        <Col xl={3} lg={3} md={4} sm={4} xs={6}>
          <SwitchButton
            leftIcon={<GridIcon />}
            rightIcon={<ListViewIcon />}
            handleView={handleView}
          />
        </Col>
      </Row>
      <Divider />

      <Row>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="news-organism-container ">
            <span className="subtitle">
              {translate("pages").notifications.subtitle}
            </span>
          </div>
          {type === "list" ? (
            <DataTable props={props} />
          ) : (
            <CardContainer props={props} />
          )}
        </Col>
      </Row>
    </Fragment>
  );
};
export default NotificationsOrganism;
