import api from "config/axios/axios.api.config";

const prefix = "resources";

const getResources = (id) => api.get(`${prefix}/projects/${id}`);

const getClientMyResources = (id) => api.get(`${prefix}/my/projects/${id}`);

const getResourceById = (id) => api.get(`${prefix}/${id}`);

const getResourcesCategories = (id) =>
    api.get(`${prefix}/categories/projects/${id}`);

const createResourcesCategories = (data, id) =>
    api.postJson(`${prefix}/categories/projects/${id}`, data);

const deleteResource = (id) => api.put(`${prefix}/${id}/delete`, { id });

const publishResource = (id) => api.put(`${prefix}/${id}/publish`, { id });

const unpublishResource = (id) => api.put(`${prefix}/${id}/unpublish`, { id });

const duplicateResource = (data) =>
    api.put(`${prefix}/${data.id}/duplicate`, data);

const updateResource = (data, id) => api.put(`${prefix}/${id}`, data);

const createResource = (data, id) =>
    api.postJson(`/${prefix}/projects/${id}`, data);

// eslint-disable-next-line
export default {
    getResources,
    getResourceById,
    deleteResource,
    publishResource,
    unpublishResource,
    duplicateResource,
    createResource,
    updateResource,
    getResourcesCategories,
    createResourcesCategories,
    getClientMyResources,
};
