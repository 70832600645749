import Page404 from 'pages/errors/Page404';

const prefix = '*';

const routes = [
  {
    path: `${prefix}`,
    component: Page404,
    exact: true,
  },
];

export default routes;
