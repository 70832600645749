import { Dropdown, CrudOptionsMenu } from "components/atoms/controls";
import Table from "components/molecules/Table";
import moment from "moment";
import { I18nContext } from "utils/i18n/locale";
import { useContext } from "react";
const dateFormat = "DD/MM/YYYY h:mm A";

const NewsTable = ({ props }) => {
  const { items } = props;
  const { translate } = useContext(I18nContext);
  const columns = [
    {
      title: translate("pages").feed.news.table.headers.title,
      dataIndex: items[0].title ? "title" : "subject",
      //   sorter: (a, b) => a.title.length - b.title.length,
      //   sortDirections: ["descend", "ascend"],
      align: "left",
      width: 300,
    },
    {
      title: translate("pages").feed.news.table.headers.created,
      dataIndex: "created_at",
      render: (item) => {
        const renderDate = new Date(item);
        return moment(renderDate).format(dateFormat);
      },
      //   defaultSortOrder: "descend",
      //   sorter: (a, b) => a.created - b.created,
      //   sortDirections: ["descend", "ascend"],
      align: "center",
      width: 200,
    },
    {
      title: translate("pages").feed.news.table.headers.lastEdited,
      dataIndex: "last_edit",
      render: (item) => {
        if (item === undefined || item === "") {
          return "--";
        } else {
          const renderDate = new Date(item);
          return moment(renderDate).format(dateFormat);
        }
      },
      //   defaultSortOrder: "descend",
      //   sorter: (a, b) => a.last_edited - b.last_edited,
      //   sortDirections: ["descend", "ascend"],
      align: "center",
      width: 200,
    },
    {
      render: (item) => (
        <Dropdown
          menu={<CrudOptionsMenu props={props} item={item} key={"newsTable"} />}
        />
      ),
      //   title:" "LAST EDITED"",
      //   dataIndex: "last_edited",
      //   defaultSortOrder: "descend",
      //   sorter: (a, b) => a.last_edited - b.last_edited,
      //   sortDirections: ["descend", "ascend"],
      align: "center",
      width: 20,
    },
  ];
  return <Table columns={columns} items={items} />;
};

export default NewsTable;
