import React, { useContext } from "react";
import { I18nContext } from "utils/i18n/locale";
import {
  HEADER_TRANSLUCENT,
  BadgeTable as Table,
} from "components/molecules/Table";
import "./FlagsTable.scss";

const FlagsTable = ({ data, color, onAdd, heading, onClick }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Table
      pagination={false}
      options={{ collapsable: true, badgeColor: color }}
      // options={{ collapsable: true, badgeColor: color, addOption: translate("pages").flags.activity.addFlag, onAddPressed: onAdd }}
      headerType={HEADER_TRANSLUCENT}
      items={data}
      onClick={onClick}
      columns={[
        {
          title: heading,
          dataIndex: ["typename", "typename_category", "name"],
          type: "badge",
          key: "badge",
          render: (text) => <b>{text}</b>,
        },
        {
          title: translate("pages").flags.activity.typename,
          dataIndex: ["typename", "name"],
          key: "name",
        },
        {
          title: translate("pages").flags.activity.reference,
          dataIndex: "id",
          key: "id",
          canHide: true,
        },
        {
          title: translate("pages").flags.activity.created,
          dataIndex: "created_at",
          key: "created_at",
          type: "badge",
          render: (text) => <span>{new Date(text).toDateString()}</span>,
        },
        {
          title: translate("pages").flags.activity.closed,
          dataIndex: "closed_at",
          key: "closed_at",
          type: "badge",
          render: (text) => (
            <span>{text ? new Date(text).toDateString() : ""}</span>
          ),
          canHide: true,
        },
      ]}
    />
  );
};

export default FlagsTable;
