import React from "react";
import { Tabs } from "antd";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./tabs.scss";

const { TabPane } = Tabs;

const TabsMenu = ({
  onChange,
  options,
  variant = "default",
  activeKey,
  defaultActiveKey,
  baseRoute = null,
  isSurvey,
}) => {
  const urlSplit = window.location.href.split("/");
  const urlId = urlSplit[urlSplit.length - 1];

  return baseRoute ? (
    <Router>
      <Route
        path={`/${baseRoute}/:tab?`}
        render={({ match, history }) => {
          return (
            <Switch>
              <Tabs
                hideAdd
                className={[
                  variant === "expanded" && "expanded-tabs",
                  "tab-ops-hidden",
                ]}
                defaultActiveKey={match.params.tab || options[0]?.key}
                onChange={(key) => history.push(`/${baseRoute}/${key}`)}
              >
                {options.map(({ title, key, children }, index) => (
                  <TabPane tab={title} key={key || index.toString()}>
                    {children}
                  </TabPane>
                ))}
              </Tabs>
            </Switch>
          );
        }}
      />
    </Router>
  ) : (
    <Tabs
      hideAdd
      className={[variant === "expanded" && "expanded-tabs", "tab-ops-hidden"]}
      activeKey={activeKey}
      defaultActiveKey={
        isSurvey ? `${defaultActiveKey}/${urlId}` : defaultActiveKey
      }
      onChange={onChange}
    >
      {options.map(({ title, key, children, id }, index) => (
        <TabPane
          tab={title}
          key={isSurvey ? `${key}/${id ?? urlId}` : key || index.toString()}
        >
          {children}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default TabsMenu;
