import { Drawer } from "antd";
import React from "react";
import "./bottom-drawer.scss";

const BottomDrawer = ({
  open,
  onClose,
  title,
  children,
  footer,
  className = "",
  isSmall,
  ...props
}) => (
  <Drawer
    {...props}
    className={!isSmall ? `bottom-drawer ${className}` : className}
    title={title}
    placement="bottom"
    closable={true}
    onClose={onClose}
    visible={open}
    footer={footer}
    key="bottom"
    height={props.height || "90%"}
    width={props.width || "35%"}
  >
    {children}
  </Drawer>
);
export default BottomDrawer;
