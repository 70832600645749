import React, { useContext } from "react";
import IrysLogo from "assets/img/irysLogo.svg";
import "./welcome.scss";
import { Text } from "components/atoms/texts";
import { PrimaryButton } from "components/atoms/controls";
import { useHistory } from "react-router-dom";

import WalkthroughSvg from "assets/img/walkthrough.svg";
import { Image } from "antd";
import MobileLayout from "../index";

import { I18nContext } from "utils/i18n/locale";
const ClientLogin = ({  }) => {
  const { translate } = useContext(I18nContext);
  const history =  useHistory()
  return (
    <MobileLayout>
      <div className="mobile-component-container">
        <div className="center-logo">
          <div className="icon-logo">
            <img src={IrysLogo} width={86} height={35} />
            <Text text={translate("client").pages.login.title.logoTitle} />
          </div>
        </div>

        <div className={"back-image"}>
          <Image src={WalkthroughSvg} preview={false} />
        </div>
        <div className="mobile-title center-all-child ">
          <Text
            className="big-title"
            noInherit={true}
            text={translate("pages").mobile.welcome.mainText}
          />

          <Text
            noInherit={true}
            className="welcome-tagline"
            text={translate("pages").mobile.welcome.subText}
          />
        </div>

        <div className="down-button">
          <PrimaryButton
            title={translate("pages").mobile.welcome.button}
            className="client-login-button"
            onClick={() => {
              history.push('/client/login');
            }}
          />
        </div>
      </div>
    </MobileLayout>
  );
};

export default ClientLogin;
