import React, {useContext} from 'react'
import BarGraph from "components/molecules/Graphs/Bar";
import {I18nContext} from "utils/i18n/locale";
import {LAYOUT, LEGEND_DIRECTION, LEGEND_POSITION} from "components/molecules/Graphs";

const TaskCompletedOnTime = () => {
	const {translate} = useContext(I18nContext);

	// This should be replaced with an API Call
	const DATA = [
		{
			category: "Graffiti",
			[translate("pages").analytics.completedBefore]: 604,
			[translate("pages").analytics.completedAfter]: -155,
		},
		{
			category: "Parks",
			[translate("pages").analytics.completedBefore]: 32,
			[translate("pages").analytics.completedAfter]: -20,
		},
		{
			category: "Property Maintenace",
			[translate("pages").analytics.completedBefore]: 300,
			[translate("pages").analytics.completedAfter]: -101,
		},
		{
			category: "Streets/Traffic",
			[translate("pages").analytics.completedBefore]: 7,
			[translate("pages").analytics.completedAfter]: 0,
		},
		{
			category: "Scooters/Dockless Vehicles",
			[translate("pages").analytics.completedBefore]: 430,
			[translate("pages").analytics.completedAfter]: -168,
		},
		{
			category: "Sidewalks",
			[translate("pages").analytics.completedBefore]: 604,
			[translate("pages").analytics.completedAfter]: -155,
		},
		{
			category: "Water Services",
			[translate("pages").analytics.completedBefore]: 32,
			[translate("pages").analytics.completedAfter]: -20,
		},
		{
			category: "Public Works",
			[translate("pages").analytics.completedBefore]: 300,
			[translate("pages").analytics.completedAfter]: -101,
		},
		{
			category: "Animals",
			[translate("pages").analytics.completedBefore]: 7,
			[translate("pages").analytics.completedAfter]: 0,
		},
		{
			category: "Garbage/Dumping",
			[translate("pages").analytics.completedBefore]: 430,
			[translate("pages").analytics.completedAfter]: -168,
		},
	]

	return (
		<BarGraph
			height='100%'
			legendPosition={LEGEND_POSITION.BOTTOM}
			legendDirection={LEGEND_DIRECTION.ROW}
			layout={LAYOUT.HORIZONTAL}
			reverse={true}
			title={translate("pages").analytics.taskCompletedTitle}
			indexBy="category" data={DATA}
			keyPoints={{
				[translate("pages").analytics.completedAfter]: "#C43A5A",
				[translate("pages").analytics.completedBefore]: "#00B999",
			}}/>
	)
}

export default TaskCompletedOnTime;
