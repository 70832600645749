import React, {useState, useContext, useEffect} from 'react';
import "./calendar.scss";
import { Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Text } from "components/atoms/texts";
import moment from "moment";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { I18nContext } from "utils/i18n/locale";
import {RightArrowIcon, DownArrowIcon} from "components/atoms/icons";
import {PrimaryButton} from "components/atoms/controls";

const MONTH_FORMAT = "MMMM";
const YEAR_FORMAT = "YYYY"

const Calendar = ({ range, setRange, setVisible, setHeader }) => {
  const { translate } = useContext(I18nContext)

  const [currentMonth, setCurrentMonth] = useState(
    moment().format(MONTH_FORMAT)
  )

  const [calendarOpen, setCalendarOpen] = useState(false);

  useEffect(() => {
    setRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ])
  }, [])

  const [currentYear, setCurrentYear] = useState(
    moment().format(YEAR_FORMAT)
  )

  moment.updateLocale('en', {
    weekdaysMin : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  });

  const setThisMonth = (header) => {
    const startOfMonth = moment().clone().startOf('month')._d;
    const endOfMonth   = moment().clone().endOf('month')._d;
    setRange([{
      startDate: startOfMonth,
      endDate: endOfMonth,
    }])
    setHeader(header)
    setVisible(false)
  }

  const setThisWeek = (header) => {
    const startOfWeek = moment().clone().startOf('week')._d;
    const endOfWeek   = moment().clone().endOf('week')._d;
    setRange([{
      startDate: startOfWeek,
      endDate: endOfWeek,
    }])
    setHeader(header)
    setVisible(false)
  }

  const setToday = (header) => {
    const today = moment()._d
    setRange([
      {
        startDate: today,
        endDate: today,
      }
    ])
    setHeader(header)
    setVisible(false)
  }

  const setNextMonth = (header) => {
    const start = moment(moment(new Date()).format("MM/DD/YYYY")).add(1, 'months').startOf('month')._d
    const end = moment(moment(new Date()).format("MM/DD/YYYY")).add(1, 'months').endOf('month')._d
    setRange([
      {
        startDate: start,
        endDate: end,
      }
    ])
    setHeader(header)
    setVisible(false)
  }

  const handleChange = (item) => {
    const data = item.selection ? item.selection: item.range1;
    const header = `${moment(data.startDate).format("MM-DD-YYYY")} - ${moment(data.endDate).format("MM-DD-YYYY")}`
    setHeader(header)
    setRange([data])
  }
 
  return(
    <div className="calendar-container">
      <Text 
        className="items-text" 
        text={translate("client").pages.events.calendar.today} 
        onClick={() => setToday(translate("client").pages.events.calendar.today)} 
      />
      <Text 
        className="items-text" 
        text={translate("client").pages.events.calendar.week} 
        onClick={() => setThisWeek(translate("client").pages.events.calendar.week)} 
      />
      <Text 
        className="items-text" 
        text={translate("client").pages.events.calendar.month} 
        onClick={() => setThisMonth(translate("client").pages.events.calendar.month)} 
      />
      <Text className="items-text" 
        text={translate("client").pages.events.calendar.nextMonth} 
        onClick={() => setNextMonth(translate("client").pages.events.calendar.nextMonth)} 
      />
      <div className="items-container">
        <div className="calendar-picker" onClick={() => setCalendarOpen(!calendarOpen)}>
          <Text 
            className={`items-text items-text-picker ${calendarOpen ? "item-text-picker-selected": ""}`} 
            text={translate("client").pages.events.calendar.pick} 
          />
          {!calendarOpen ? <RightArrowIcon className="icon" color={"#A4A4A4"} /> : <DownArrowIcon className="icon" color={"#A4A4A4"} />}
        </div>
      </div>
      {
        calendarOpen ?
        <>
        <DateRangePicker
            onChange={item => handleChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={range}
            direction="vertical"
            showPreview={false}
            scroll={true}
          />
          <PrimaryButton
            title={translate("client").pages.events.calendar.confirmButton}
            onClick={() => setVisible(false)}
          />
        </>
        : null}
    </div>
    
  )
}

export default Calendar;

