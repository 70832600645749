import React, { useContext, Suspense } from "react";
import { SpiningPage } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import "./Submissions.scss";
import { withRouter } from "react-router-dom";
import SubmissionsTable, { TYPE } from "components/organisms/SubmissionsTable";
import { Col, Row } from "antd";

const Submissions = ({ data = [], parentHistory }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Suspense fallback={<SpiningPage opacity={1} />}>
      <Row gutter={[0, 24]}>
        <Col xs={24}>
          <SubmissionsTable
            onClick={({ id }) => {
              parentHistory.push(`home/communications/submission/${id}`);
            }}
            heading={
              translate("pages").communication.submission.table.allEntries
            }
            type={TYPE.ALL}
            data={data}
          />
        </Col>
        <Col xs={24}>
          <SubmissionsTable
            onClick={() => {}}
            heading={translate("pages").communication.submission.table.approved}
            type={TYPE.APPROVED}
            data={data}
          />
        </Col>
        <Col xs={24}>
          <SubmissionsTable
            onClick={() => {}}
            heading={translate("pages").communication.submission.table.rejected}
            type={TYPE.REJECTED}
            data={data}
          />
        </Col>
      </Row>
    </Suspense>
  );
};
export default withRouter(Submissions);
