import React from "react";
import { Divider } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import "./Nav.scss";

const Nav = ({
  rightIcons,
  leftIcons,
  pagination,
  totalMail,
  startPage,
  endPage,
  handlePagination,
  title,
}) => {
  return (
    <>
      <div className="nav-container">
        <div className="left-bar">
          <span className="mail-subject">{title}</span>
          {leftIcons.map((icon) => (
            <>
              <span
                onClick={icon.clickAction}
                className={icon.cssClass}
                // key={`nav-icon-left-${Math.random()}`}
              >
                {" "}
                {icon.component}
              </span>
            </>
          ))}
        </div>
        <div className="right-bar">
          {pagination ? (
            <span>
              Show {startPage} - {endPage} of {totalMail}
              <span className="pagination-arrow-circle">
                <LeftOutlined onClick={() => handlePagination("prev")} />
              </span>
              <span className="pagination-arrow-circle">
                <RightOutlined onClick={() => handlePagination("next")} />
              </span>
            </span>
          ) : (
            ""
          )}

          {rightIcons.map((icon) => (
            <span
              onClick={icon.clickAction}
              className={icon.cssClass}
              key={`nav-icon-right-${Math.random()}`}
            >
              {icon.component}
            </span>
          ))}
        </div>
      </div>
      <Divider className="divider" />
    </>
  );
};
export default Nav;
