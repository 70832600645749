import React, { useState, useContext } from "react";
import { Drawer } from "antd";

import Fab from "client/components/atoms/fab";
import { NewMessageSvg } from "assets/svg/mobile";
import { useHistory } from 'react-router-dom';

import { I18nContext } from "utils/i18n/locale";

import "./ProvideInput.scss";

const CreateMessage = () => {
  const { translate } = useContext(I18nContext);
  const history = useHistory();

  return (
    <div className="provide-input-container">
      <div className="fab-container">
        <Fab
          onClick={() => {
            history.push("/launchsa/newMessage/");
          }}
          icon={() => ( <NewMessageSvg />)}
          isOpen={false}
        />
      </div>
    </div>
  );
};

export default CreateMessage;
