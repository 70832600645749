import React, { useCallback, useContext } from "react";
import { Button } from "antd";
import { useDropzone } from "react-dropzone";
import { I18nContext } from "utils/i18n/locale";
import "./DropUpload.scss";

const DropUpload = ({ setFileSrc }) => {
  const { translate } = useContext(I18nContext);
  const onDrop = useCallback((acceptedFiles) => {
    setFileSrc(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="drag-container" {...getRootProps()}>
      <input {...getInputProps()} />
      <Button color="white" type="dashed" className="logo-upload-button">
        <span>{translate("pages").settings.branding.logo.button.drop}</span>
        {"  "}
        <span className="logo-upload-button-underlined">
          {translate("pages").settings.branding.logo.button.choose}
        </span>
      </Button>
    </div>
  );
}

export default DropUpload;
