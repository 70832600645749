import React, { useContext } from "react";
import { Text } from "components/atoms/texts";
import "./DepartmentList.scss";
import { I18nContext } from "utils/i18n/locale";
import {
  PlusOutlined,
  UserAddOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { AvatarStack, AvatarWithName } from "components/atoms/avatarstack";
import { Popover } from "antd";
import SearchBar from "components/atoms/search";
import { Dropdown } from "antd";

const PopOverContent = ({ data, children }) => {
  const handleSearch = () => {};
  if (!data?.length) {
    return (
      <div>
        <Text style={{ fontSize: 16 }} text={"No Items"} />
      </div>
    );
  } else {
    return (
      <div className="popover-menu-container">
        <div className="popover-search-container">
          <SearchBar
            placeholder={"search"}
            handleSearch={() => handleSearch(data)}
            className="department-list-searchbar"
          />
        </div>
        <div className="popover-list-container">{children}</div>
      </div>
    );
  }
};

const DepartmentCategoryListItem = ({ item, exists }) => {
  return (
    <div className="selected-items">
      <div className="selected-items-wrapper">
        <span className="selected-item-text">{item?.name}</span>
      </div>
      <div className="selected-items-wrapper">
        <div className="cross-icon">
          <CloseOutlined color="#5C5C5C" fontSize={8.17} />
        </div>
      </div>
    </div>
  );
};

const DepartmentCategoryList = ({ data, addRemove, checker, onClick }) => {
  const { translate } = useContext(I18nContext);

  const DataIterator = data.map((item, idx) => {
    return (
      <div onClick={() => addRemove(item)}>
        <DepartmentCategoryListItem item={item} exists={checker(item)} />
      </div>
    );
  });

  return (
    <div className="overflow-wrapper">
      <div className="selected-container-cat">
        {DataIterator}
        <span onClick={onClick}> add more</span>
      </div>

      {/* <span>
        {translate("pages").settings.department.table.category.nonemptylabel}
      </span> */}
      <div className="overflow-text">
        {/* <Text
          style={{ fontSize: 14 }}
          text={
            translate("pages").settings.department.table.category.nonemptylabel
          }
        /> */}
      </div>
    </div>
  );
};

const DepartmentPopOverContent = ({ data, exists }) => {
  return (
    <div className={`option-menu ${exists ? "option-items-selected" : ""}`}>
      <div className={`option-items`}>
        <Text style={{ color: "#141414" }} text={data} />
      </div>
    </div>
  );
};

const DepartmentPopOverContentList = ({ data, addRemove, checker }) => {
  const DataIterator = data.map((item, idx) => {
    return (
      <div onClick={() => addRemove(item)}>
        <DepartmentPopOverContent data={item} exists={checker(item)} />
      </div>
    );
  });

  return DataIterator;
};

const AddCategoryButton = ({ onClick, item }) => {
  const { translate } = useContext(I18nContext);
  return (
    <div className="select-when-empty-filler" onClick={() => onClick()}>
      <div className="select-when-empty-filler-items">
        <PlusOutlined />
      </div>
      <div className="select-when-empty-filler-items">
        <Text
          text={
            translate("pages").settings.department.table.category.emptylabel
          }
        />
      </div>
    </div>
  );
};

const DepartmentUserItem = ({ item, addRemove, exists }) => {
  return (
    <div className={`option-menu ${exists ? "option-items-selected" : ""}`}>
      <div className="option-items" onClick={() => addRemove(item)}>
        <AvatarWithName item={item} className={"popup-avatar"} size={24} />
      </div>
    </div>
  );
};

const DepartmentUsersList = ({ data, addRemove, checker }) => {
  const DataIterator = data.map((item, idx) => {
    return (
      <DepartmentUserItem
        item={item}
        addRemove={addRemove}
        exists={checker(item)}
      />
    );
  });

  return <div>{DataIterator}</div>;
};

const UsersAvatarRender = ({ data, users, state, setState, key, index }) => {
  key = "users";
  const AddRemoveUsers = (item) => {
    let tempState = [...state];
    let currentValue = tempState[index][key];
    let check = currentValue.some(
      (checker) => JSON.stringify(checker) === JSON.stringify(item)
    );
    if (!check) {
      currentValue.push(item);
    } else {
      let findIndex = currentValue.findIndex((x) => x.id === item.id);
      currentValue = currentValue.splice(findIndex, 1);
    }
    setState(tempState);
  };

  const checker = (item) => {
    let tempState = [...state];
    let currentValue = tempState[index][key];
    return currentValue.some(
      (checker) => JSON.stringify(checker) === JSON.stringify(item)
    );
  };

  return (
    <div className="users-tab-container">
      <AvatarStack fontSize={14} items={data} />
      <Dropdown
        overlay={
          <PopOverContent data={users}>
            <DepartmentUsersList
              data={users}
              addRemove={AddRemoveUsers}
              checker={checker}
            />
          </PopOverContent>
        }
        overlayClassName={"custom-popover-style"}
        trigger="click"
        placement={"bottomLeft"}
      >
        <div>
          <UserAddOutlined
            className="add-user"
            style={{
              width: "22px",
              height: "16px",
              color: "rgba(20, 20, 20, 0.38)",
            }}
          />
        </div>
      </Dropdown>
    </div>
  );
};

const CategoryRender = ({ data, categories, state, setState, key, index }) => {
  key = "categories";
  const AddRemoveCategories = (item) => {
    let tempState = [...state];
    let currentValue = tempState[index][key];
    let check = currentValue.some(
      (checker) => JSON.stringify(checker) === JSON.stringify(item)
    );
    if (!check) {
      tempState[index][key].push(item);
    } else {
      tempState[index][key] = tempState[index][key].filter(
        (checker) => checker !== item
      );
    }
    setState(tempState);
  };

  const checker = (item) => {
    let tempState = [...state];
    let currentValue = tempState[index][key];
    return currentValue.some(
      (checker) => JSON.stringify(checker) === JSON.stringify(item)
    );
  };

  return (
    <Dropdown
      overlay={
        <PopOverContent data={categories}>
          <DepartmentPopOverContentList
            data={categories}
            addRemove={AddRemoveCategories}
            checker={checker}
          />
        </PopOverContent>
      }
      overlayClassName={"custom-popover-style"}
      trigger="click"
      placement={"bottomLeft"}
    >
      <div className="custom">
        {!data.length ? (
          <AddCategoryButton />
        ) : (
          <DepartmentCategoryList
            data={data}
            addRemove={AddRemoveCategories}
            checker={checker}
          />
        )}
      </div>
    </Dropdown>
  );
};
const SubCategoryRender = ({
  data,
  categories,
  state,
  setState,
  key,
  index,
  onClick,
  handleRemoveSubCategory,
}) => {
  // console.log({ data, categories, state, key, index });
  key = "typename";
  const AddRemoveCategories = (item) => {
    handleRemoveSubCategory(item.id);
  };

  const checker = (item) => {
    let tempState = [...state];
    let currentValue = tempState[index][key];
    return currentValue.some(
      (checker) => JSON.stringify(checker) === JSON.stringify(item)
    );
  };

  return (
    // <Dropdown
    //   overlay={
    //     <PopOverContent data={categories}>
    //       <DepartmentPopOverContentList
    //         data={categories}
    //         addRemove={AddRemoveCategories}
    //         checker={checker}
    //       />
    //     </PopOverContent>
    //   }
    //   overlayClassName={"custom-popover-style"}
    //   trigger="click"
    //   placement={"bottomLeft"}
    // >
    <div className="custom">
      {!data?.length ? (
        <AddCategoryButton item={data} onClick={onClick} />
      ) : (
        <DepartmentCategoryList
          data={data}
          addRemove={AddRemoveCategories}
          checker={checker}
          onClick={onClick}
        />
      )}
    </div>
    // </Dropdown>
  );
};

export {
  DepartmentCategoryList,
  DepartmentPopOverContentList,
  AddCategoryButton,
  DepartmentUsersList,
  UsersAvatarRender,
  PopOverContent,
  CategoryRender,
  SubCategoryRender,
};
