import React from "react";
import { DataInput } from "components/atoms/inputs";
import PlacesAutocomplete from "react-places-autocomplete";
import "./AutoPlaces.scss";
import { getLatLng, geocodeByAddress } from "react-places-autocomplete";

export default class LocationSearchInput extends React.Component {
  state = {
    address: "",
  };

  handleAddressChange = (address) => {
    this.setState({ address });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.address !== this.props.address) {
      this.setState({ address: nextProps.address });
    }
  }

  onAddressSelect = (address) => {
    geocodeByAddress(address)
      .then(async (results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        this.setState({ address });
        this.props.setAddress(address);
        this.props.setLatitude(latLng.lat);
        this.props.setLongitude(latLng.lng);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  render() {
    const { address } = this.state;

    return (
      <PlacesAutocomplete
        onChange={this.handleAddressChange}
        onSelect={this.onAddressSelect}
        value={address}
      >
        {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
          <React.Fragment>
            <DataInput
              title="Address"
              placeholder="Enter Address"
              {...getInputProps({
                id: "address-input",
                className: "ant-input",
              })}
            />
            {suggestions ? (
              suggestions.length > 0 ? (
                <div className="flag-places">
                  <div className="autocomplete-dropdown-container">
                    {loading ? <div>Loading...</div> : null}
                    {suggestions.map((suggestion) => {
                      console.log(suggestion);
                      const className = suggestion.active
                        ? "suggestion-item-active"
                        : "suggestion-item";
                      const spread = {
                        ...getSuggestionItemProps(suggestion, {
                          className,
                        }),
                      };

                      return (
                        <div {...spread} key={suggestion.id}>
                          <div>{suggestion.description}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null
            ) : null}
          </React.Fragment>
        )}
      </PlacesAutocomplete>
    );
  }
}
