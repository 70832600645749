import SignUp from "pages/SignUp";

const prefix = "/signup";

const routes = [
  {
    path: `${prefix}/:plan_id`,
    component: SignUp,
    // exact: true,
  },
];

export default routes;
