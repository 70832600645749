import { Divider, Row } from "antd";
import React, { useState } from "react";
import {
  BoolIcon,
  TextIcon,
  MultiCheckIcon,
  NavigateIcon,
  ReloadIcon,
  SettingIcon,
} from "components/atoms/icons";
import "./SurveyQuestion.scss";
import { Text } from "components/atoms/texts";
import QuestionOptionDropdown from "components/molecules/QuestionOptionDropdown";
import { RadioGroup } from "components/atoms/inputs";
import { CheckboxInput } from "components/atoms/inputs";
import { CheckIcon } from "components/atoms/icons";
const SurveyQuestion = ({ question, index, handleAction }) => {
  const [show, setShow] = useState(false);

  const handleActionSelected = (key, index) => {
    setShow(false);
    handleAction(key, index);
  };

  return (
    <div className="d-flex">
      <Row className="survey-question-created-container">
        {question.questionType === "Multiple Choice- Single" ? (
          <div className="icon check-icon">
            <CheckIcon color="#ffffff" opacity={1} />
          </div>
        ) : question.questionType === "Multiple Choice- Multiple" ? (
          <div className="icon multi-check-icon">
            <MultiCheckIcon />
          </div>
        ) : question.questionType === "True or False" ? (
          <div className="icon bool-icon">
            <BoolIcon />
          </div>
        ) : question.questionType === "Text Field" ? (
          <div className="icon text-icon">
            <TextIcon />
          </div>
        ) : null}
        <Text text={question.question} className="question-title" />
      </Row>
      {question.questionType === "Multiple Choice- Single" &&
      question.options ? (
        <Row className="question-choices">
          <RadioGroup
            isList
            options={question.options.map((ele) => ele.title)}
          />
        </Row>
      ) : question.questionType === "Multiple Choice- Multiple" &&
        question.options ? (
        <Row className="question-choices">
          <CheckboxInput
            options={question.options.map((ele) => ele.title)}
            className="check-box-choices"
          />
        </Row>
      ) : null}
      <Row className="question-actions">
        {/* <ReloadIcon className="action-icons" />
        <SettingIcon
          className="action-icons"
          onClick={() => handleActionSelected("settings", index)}
        /> */}
        <div className="dropdown-icon-container">
          <NavigateIcon
            className="action-icons"
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="dropdown-container">
              <QuestionOptionDropdown
                handleAction={handleActionSelected}
                index={index}
              />
            </div>
          )}
        </div>
      </Row>
      <Divider />
    </div>
  );
};

export default SurveyQuestion;
