import { Col, Divider, Row, Layout, Pagination } from "antd";
import EmptyUserCard from "components/molecules/EmptyUserCard";
import UserListTable from "components/organisms/UserListTable";
import React, { useContext, useEffect, useState } from "react";
import avatar from "assets/img/arcadis.jpg";
import "./user.scss";
import UserListHeader from "components/molecules/UserListHeader";
import { Text } from "components/atoms/texts";
// import Pagination from "components/atoms/pagination";
import { useHistory } from "react-router";
import { I18nContext } from "utils/i18n/locale";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { SwalAlerts } from "components/atoms/outputs";
import {
  enableCitizen,
  disableCitizen,
  getProjectCitizen,
} from "redux/slices/projects";
import { SpiningPage } from "components/atoms/icons";
import { Fragment } from "react";
import InviteForm from "components/organisms/InviteUserForm";

const User = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { translate } = useContext(I18nContext);

  const citizens = useSelector((state) => state.projects.citizens);
  const loading = useSelector((state) => state.projects.loading);
  const loadingAction = useSelector((state) => state.projects.loadingAction);

  const [users, setUsers] = useState(citizens);
  const [usersAux, setUsersAux] = useState(citizens);
  const [showInvite, setShowInvite] = useState(false);

  useEffect(() => {
    setUsers(citizens);
    setUsersAux(citizens);
  }, [citizens]);

  useEffect(() => {
    dispatch(getProjectCitizen());
  }, []);

  const handleClick = (citizen) => {
    history.push({
      pathname: "/home/user-list/user-profile",
      state: { citizen },
    });
    // window.location.assign(`/home/user-list/user-profile`);
  };

  const handleSearch = (query, isEmpty) => {
    let result = usersAux.filter((n) =>
      n.user?.name.toLowerCase().includes(query.toLowerCase())
    );

    if (result.length <= 0) {
      setUsers([{}]);
    } else {
      if (isEmpty) {
        setUsers(usersAux);
      } else {
        setUsers(result);
      }
    }
  };

  const openInvite = () => {
    setShowInvite(true);
  };

  const closeDrawer = () => {
    setShowInvite(false);
  };

  const changeStatus = (value, id) => {
    if (value === "enable") {
      dispatch(enableCitizen(id))
        .then(unwrapResult)
        .then(() => {
          SwalAlerts({
            title: translate("pages").settings.alerts.enableUser.success.title,
            text: translate("pages").settings.alerts.enableUser.success
              .subtitle,
            type: "success",
          });
        })
        .catch(() => {
          SwalAlerts({
            title: translate("pages").settings.alerts.enableUser.error.title,
            text: translate("pages").settings.alerts.enableUser.error.subtitle,
            type: "error",
          });
        });
    }
    if (value === "disable") {
      dispatch(disableCitizen(id))
        .then(unwrapResult)
        .then(() => {
          SwalAlerts({
            title: translate("pages").settings.alerts.disableUser.success.title,
            text: translate("pages").settings.alerts.disableUser.success
              .subtitle,
            type: "success",
          });
        })
        .catch(() => {
          SwalAlerts({
            title: translate("pages").settings.alerts.disableUser.error.title,
            text: translate("pages").settings.alerts.disableUser.error.subtitle,
            type: "error",
          });
        });
    }
  };

  return (
    <Fragment>
      {loading && <SpiningPage opacity={1} />}
      <div className="users-page-header" hidden={loading}>
        {/* <span className="title">{translate("pages").users.title.users}</span> */}
        <Text
          text={translate("pages").users.title.users}
          className="users-page-header-title"
        />
        <Divider />
      </div>

      {users.length < 1 && !loading ? (
        <Row className="flags-placeholder-row" justify="middle" align="center">
          <Col lg={12} md={18} sm={24}>
            <EmptyUserCard
              className="empty-user-card"
              openInvite={openInvite}
            />
          </Col>
        </Row>
      ) : (
        <div className="user-list-table-container">
          {!loading && (
            <>
              <UserListHeader
                userList
                handleSearch={handleSearch}
                openInvite={openInvite}
              />
              <div className="user-list-table">
                <UserListTable
                  userList
                  data={users}
                  onClick={handleClick}
                  changeStatus={changeStatus}
                  loading={loading}
                />
              </div>
            </>
          )}

          {/* <Pagination
            data={users}
            defaultCurrent={1}
            defaultPageSize={10}
            onChange={handlePaginationChange}
            total={users?.length}
          /> */}
        </div>
      )}
      <InviteForm show={showInvite} onClose={closeDrawer} />
    </Fragment>
  );
};

export default User;
