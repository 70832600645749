import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import eventAPI from "api/eventAPI";
import { getCurrentProjectId } from "utils/common";

const awsErrorSerialized = {
  error: "ASW Error",
};

const awsError = (resp) => {
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};

export const asyncGetData = (previewData) => async (dispatch) => {
  try {
    dispatch(startGetForm());
    dispatch(successGetForm(previewData));
  } catch (error) {
    dispatch(errorGetForm(error.toString()));
  }
};
export const asyncClearData = (previewData) => async (dispatch) => {
  try {
    dispatch(startCleanForm());
    dispatch(successCleanForm(previewData));
  } catch (error) {
    dispatch(errorCleanForm(error.toString()));
  }
};

export const getEvents = createAsyncThunk(
  "events/getEvents",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const events = await eventAPI.getEvents(project_id);
      awsError(events);
      return events.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getClientEvents = createAsyncThunk(
  "events/getClientEvents",
  async (id, { rejectWithValue }) => {
    try {
      const events = await eventAPI.getClientEvents(id);
      awsError(events);
      return events.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getEventsTopics = createAsyncThunk(
  "events/getEventsTopics",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const eventsTopics = await eventAPI.getEventsTopics(project_id);
      awsError(eventsTopics);
      return eventsTopics.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createEventsTopics = createAsyncThunk(
  "events/createEventsTopics",
  async (item, { rejectWithValue }) => {
    try {
      const project_id = getCurrentProjectId();
      const events = await eventAPI.createEventTopic(item, project_id);
      awsError(events);
      return events.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createEvents = createAsyncThunk(
  "events/createEvents",
  async (item, { rejectWithValue }) => {
    try {
      const events = await eventAPI.createEvent(item);
      awsError(events);
      return events.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updateEvents = createAsyncThunk(
  "events/updateEvents",
  async (item, { rejectWithValue }) => {
    try {
      let editEvents = { ...item };
      delete editEvents.id;
      const events = await eventAPI.updateEvent(editEvents, item.id);
      console.log(events, "editado");
      awsError(events);
      return events.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const publishEvents = createAsyncThunk(
  "events/publishEvents",
  async (item, { rejectWithValue }) => {
    try {
      const events = await eventAPI.publishEvent(item);
      awsError(events);
      return events.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const unpublishEvents = createAsyncThunk(
  "events/unpublishEvents",
  async (item, { rejectWithValue }) => {
    try {
      const events = await eventAPI.unpublishEvent(item);
      awsError(events);
      return events.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const duplicateEvents = createAsyncThunk(
  "events/duplicateEvents",
  async (item, { rejectWithValue }) => {
    try {
      const events = await eventAPI.duplicateEvent(item);
      awsError(events);
      return events.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const deleteEvents = createAsyncThunk(
  "events/deleteEvents",
  async (id, { rejectWithValue }) => {
    try {
      const events = await eventAPI.deleteEvent(id);
      awsError(events);
      return events.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

const initialState = {
  previewData: {
    title: "",
    topic: "",
    description: "",
    website_link: "",
    location: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    timezone: "",
    eventbrite_url: "",
  },
  events: [],
  eventsTopics: [],
  error: null,
  loading: true,
  eventUpdated: false,
  eventCreated: false,
  loadingAction: false,
};

const events = createSlice({
  name: "events",
  initialState,
  reducers: {
    startGetForm(state, action) {},
    successGetForm(state, action) {
      state.previewData = { ...state.previewData, ...action.payload };
    },
    errorGetForm(state, action) {
      console.log("error en el preview data", action);
    },
    startCleanForm(state, action) {},
    successCleanForm(state, action) {
      state.previewData = { ...action.payload };
    },
    errorCleanForm(state, action) {
      console.log("error en el clean data", action);
    },
  },
  extraReducers: {
    //Get Events

    [getClientEvents.pending]: (state, action) => {
      console.info("getClientEvents pending");
      state.loading = true;
      state.error = null;
      state.eventsUpdated = false;
      state.eventsCreated = false;
      state.events = [];
    },
    [getClientEvents.fulfilled]: (state, action) => {
      console.info("getClientEvents fullfilled");
      state.loading = false;
      state.error = null;
      state.eventsUpdated = false;
      state.events = action.payload.body;
    },
    [getClientEvents.rejected]: (state, action) => {
      console.error("getClientEvents rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.events = [];
    },

    [getEvents.pending]: (state, action) => {
      console.info("getEvents pending");
      state.loading = true;
      state.error = null;
      state.eventsUpdated = false;
      state.eventsCreated = false;
      // state.events = [];
    },
    [getEvents.fulfilled]: (state, action) => {
      console.info("getEvents fullfilled");
      state.loading = false;
      state.error = null;
      state.eventsUpdated = false;
      state.events = action.payload.body;
    },
    [getEvents.rejected]: (state, action) => {
      console.error("getEvents rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.events = [];
    },

    //Get EventsTopics
    [getEventsTopics.pending]: (state, action) => {
      console.info("getEventsTopics pending");
      // state.loadingAction = true;
      state.error = null;
      state.eventsUpdated = false;
      state.eventsCreated = false;
    },
    [getEventsTopics.fulfilled]: (state, action) => {
      console.info("getEventsTopics fullfilled");
      // state.loadingAction = false;
      state.error = null;
      state.eventsUpdated = false;
      state.eventsTopics = action.payload.body;
    },
    [getEventsTopics.rejected]: (state, action) => {
      console.error("getEventsTopics rejected =>", action.payload);
      // state.loadingAction = false;
      state.error = action.payload;
      state.eventsTopics = [];
    },

    //Create EventsTopics
    [createEventsTopics.pending]: (state, action) => {
      console.info("createEventsTopics pending");
      state.loadingAction = true;
      state.error = null;
      state.eventsUpdated = false;
      state.eventsCreated = false;
    },
    [createEventsTopics.fulfilled]: (state, action) => {
      console.info("createEventsTopics fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.eventsUpdated = false;
    },
    [createEventsTopics.rejected]: (state, action) => {
      console.error("createEventsTopics rejected =>", action.payload);
      state.loadingAction = false;
      state.error = action.payload;
    },

    //Create Events
    [createEvents.pending]: (state, action) => {
      console.info("createEvents pending");
      state.loadingAction = true;
      state.error = null;
      state.eventsUpdated = false;
      state.eventsCreated = false;
    },
    [createEvents.fulfilled]: (state, action) => {
      console.info("createEvents fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.eventsCreated = true;
    },
    [createEvents.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("createEvents rejected =>", action.payload);
    },

    //Update Events
    [updateEvents.pending]: (state, action) => {
      console.info("updateEvents pending");
      state.loadingAction = true;
      state.error = null;
      state.eventsUpdated = false;
      state.eventsCreated = false;
    },
    [updateEvents.fulfilled]: (state, action) => {
      console.info("updateevents fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.eventsCreated = true;
    },
    [updateEvents.rejected]: (state, action) => {
      console.error("updateevents rejected =>", action.payload);
      state.eventsCreated = false;
      state.eventsUpdated = false;
      state.loadingAction = false;
      state.error = action.payload.error;
    },

    //Delete Events
    [deleteEvents.pending]: (state, action) => {
      console.info("DeleteEvents started");
      state.loadingAction = true;
      state.error = null;
      state.eventsUpdated = false;
    },
    [deleteEvents.fulfilled]: (state, action) => {
      console.info("DeleteEvents success", state.eventsUpdated);
      state.loadingAction = false;
      state.error = null;
      state.eventsUpdated = true;
    },
    [deleteEvents.rejected]: (state, action) => {
      console.error("DeleteEvents error=> ", action.payload.error);
      state.loadingAction = false;
      state.error = null;
      state.eventsUpdated = false;
      state.error = action.payload.error;
    },

    //Publish Events
    [publishEvents.pending]: (state, action) => {
      console.info("PublishEvents pending");
      state.loadingAction = true;
      state.error = null;
      state.eventsUpdated = false;
    },
    [publishEvents.fulfilled]: (state, action) => {
      console.info("PublishEvents fullfiled", state.eventsUpdated);
      state.loadingAction = false;
      state.error = null;
      state.eventsUpdated = true;
    },
    [publishEvents.rejected]: (state, action) => {
      console.error("PublishEvents rejected=> ", action.payload);
      state.loadingAction = false;
      state.error = action.payload;
      state.eventsUpdated = false;
    },

    //Publish Events
    [unpublishEvents.pending]: (state, action) => {
      console.info("unPublishEvents pending");
      state.loadingAction = true;
      state.error = null;
      state.eventsUpdated = false;
    },
    [unpublishEvents.fulfilled]: (state, action) => {
      console.info("unPublishEvents fullfiled", state.eventsUpdated);
      state.loadingAction = false;
      state.error = null;
      state.eventsUpdated = true;
    },
    [unpublishEvents.rejected]: (state, action) => {
      console.error("unPublishEvents rejected=> ", action.payload);
      state.loadingAction = false;
      state.error = action.payload;
      state.eventsUpdated = false;
    },

    //Duplicate Events
    [duplicateEvents.pending]: (state, action) => {
      console.info("DuplicateEvents pending");
      state.loadingAction = true;
      state.error = null;
      state.eventsUpdated = false;
    },
    [duplicateEvents.fulfilled]: (state, action) => {
      console.info("DuplicateEvents fullfilled", state.eventsUpdated);
      state.loadingAction = false;
      state.error = null;
      state.eventsUpdated = true;
    },
    [duplicateEvents.rejected]: (state, action) => {
      console.error("DuplicateEvents rejected=> ", action.payload);
      state.loadingAction = false;
      state.error = null;
      state.eventsUpdated = false;
      state.error = action.payload;
    },
  },
});

export const {
  startGetForm,
  successGetForm,
  errorGetForm,
  startCleanForm,
  successCleanForm,
  errorCleanForm,
} = events.actions;
export default events.reducer;
