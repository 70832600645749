import React, {  useContext, useEffect, useState } from "react";
import { Row, Form } from "antd";
// import "./CreateProjectTabs.scss";
import TabsMenu from "components/atoms/tabs";
import { I18nContext } from "utils/i18n/locale";
import CreateProjectForm from "../CreateProjectForm";
import BottomDrawer from "components/atoms/bottom-drawer";
// import { AttachImageIcon } from "components/atoms/icons";
import { PrimaryButton } from "components/atoms/controls";
import CreateSocialMediaForm from "../CreateProjectSocialMediaForm";
import { useDispatch, useSelector } from "react-redux";
// import { Storage } from "aws-amplify";
import {
  asyncGetData,
  asyncClearData,
  createProjects,
  // getProjects,
  closeCreateProjectModal,
  getProjectsSilence,
} from "redux/slices/projects";
import { getCurrentOrganizationId, makeId } from "utils/common";
import { SwalAlerts } from "components/atoms/outputs";
import { unwrapResult } from "@reduxjs/toolkit";
import { uploadDrag } from "components/atoms/media";

const ACTIVE_KEYS = {
  OVERVIEW: "overview",
  MEDIA: "media",
  ENGAGEMENT: "engagement",
  PARTER: "partner",
  USERS: "users",
};

// const getFileDetails = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();

//     reader.onloadend = (e) => {
//       resolve({
//         file,
//         base64: reader.result,
//       });
//     };

//     reader.onerror = (e) => {
//       reject(e);
//     };

//     reader.readAsDataURL(file);
//   });
// };

const ActionFooter = ({
  onSubmit,
  onAttachImages,
  loadingAction,
  hasNext,
  setActiveKey,
}) => {
  const { translate } = useContext(I18nContext);
  // const fileRef = useRef(null);

  // const onUpload = (e) => {
  //   // console.log(e);

  //   if (!e?.target?.files || e?.target?.files.length === 0) return;

  //   (async () => {
  //     let images = [];
  //     for (const file of e?.target?.files) {
  //       images = [...images, await getFileDetails(file)];
  //     }
  //     onAttachImages(images);
  //   })().finally(() => {});
  // };

  return (
    <Row justify="end" align="middle" gutter={2}>
      {/* <Button
        onClick={() => fileRef.current.click()}
        className="attach-image-btn"
        shape="circle"
      >
        <AttachImageIcon />
      </Button>
      <input type="file" multiple onChange={onUpload} hidden ref={fileRef} /> */}
      {hasNext ? (
        <PrimaryButton
          onClick={() => {
            setActiveKey(ACTIVE_KEYS.MEDIA);
          }}
          // htmlType="submit"
          cssClassName="save-btn"
          title={translate("pages").projects.createProjectForm.buttons.next}
          // loading={loadingAction}
        />
      ) : (
        <PrimaryButton
          onClick={onSubmit}
          // htmlType="submit"
          cssClassName="save-btn"
          title={translate("pages").projects.createProjectForm.buttons.save}
          loading={loadingAction}
        />
      )}
    </Row>
  );
};

const CreateProjectTabs = ({ show, onClose }) => {
  const projectData = useSelector((state) => state.projects.previewData);
  const loadingAction = useSelector((state) => state.projects.loadingAction);
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);
  const [hasNext, setHasNext] = useState(true);
  const [activeKey, setActiveKey] = useState(ACTIVE_KEYS.OVERVIEW);
  const formRef = React.createRef();
  const [form] = Form.useForm();
  const [file, setFiles] = useState();
  const [isSavePressed, setIsSavePressed] = useState(false);
  const [projectCompletionPercentage, setProjectCompletionPercentage] =
    useState(0);
  const [privateProject, setPrivateProject] = useState(false);
  const [showProgress, setShowProgress] = useState(true);

  const [location, setLocation] = useState({
    // address: preData?.address ?? null,
    // latitude: preData?.latitude ?? null,
    // longitude: preData?.longitude ?? null,
    address: null,
    latitude: null,
    longitude: null,
  });

  const getAddress = (location) => {
    setLocation({
      address: location.address,
      latitude: location.lat,
      longitude: location.lng,
    });
  };

  const dispatch = useDispatch();

  const getFileReference = (file) => {
    setFiles(file);
  };

  useEffect(() => {
    if (isSavePressed) {
      dispatchFunction();
    }
  }, [projectData, isSavePressed]);

  useEffect(() => {
    if (activeKey === ACTIVE_KEYS.MEDIA) {
      setHasNext(false);
    } else {
      setHasNext(true);
    }
  }, [activeKey]);

  const dispatchFunction = () => {
    let create_project = { ...projectData };
    create_project.organization_id = parseInt(getCurrentOrganizationId());
    dispatch(createProjects(create_project))
      .then(unwrapResult)
      .then(() => {
        SwalAlerts({
          title: translate("pages").projects.alerts.addProject.success.title,
          text: translate("pages").projects.alerts.addProject.success.subtitle,
          type: "success",
        });
        // form.resetFields();
        // onClose();
        setLoading(false);
        setIsSavePressed(false);
        let updateValue = { previewData: {} };
        dispatch(asyncClearData(updateValue));
        formRef?.current?.resetFields();
        form.resetFields();
        setActiveKey(ACTIVE_KEYS.OVERVIEW);
        setFiles([]);
        setPrivateProject(false);
        setProjectCompletionPercentage(0);
        setShowProgress(true);
        getAddress({
          address: "",
          lat: "",
          lng: "",
        });
        setLocation({
          address: "",
          latitude: "",
          longitude: "",
        });

        dispatch(closeCreateProjectModal());

        dispatch(getProjectsSilence());
      })
      .catch((e) => {
        setLoading(false);
        SwalAlerts({
          title: translate("pages").projects.alerts.addProject.error.title,
          text: translate("pages").projects.alerts.addProject.error.subtitle,
          type: "error",
        });
      });
  };
  
  const handleSaveProject = () => {
    setLoading(true);
    if (file) {
      uploadDrag("projects", file, makeId(10) )
        .then((img) => {
          dispatch(asyncGetData({ logo: img.key }));
          setIsSavePressed(true);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err, "error al guardar la imagen");
        })
        .finally(() => {
          // dispatch(createFlag("flagData"))
          //   .then(unwrapResult)
          //   .then(() => {
          //     SwalAlerts({
          //       title: translate("pages").flags.alerts.created.title,
          //       text: translate("pages").flags.alerts.created.subtitle,
          //       type: "success",
          //     });
          //     form.resetFields();
          //     onClose();
          //     dispatch(getFlags());
          //   })
          //   .catch((e) => {
          //     SwalAlerts({
          //       title: translate("pages").flags.alerts.error.title,
          //       text: translate("pages").flags.alerts.error.subtitle,
          //       type: "error",
          //     });
          //   });
        });
    } else {
      dispatchFunction();
    }
  };

  return (
    // <Layout className="followers-pane">
    <BottomDrawer
      footer={
        <ActionFooter
          // onSubmit={() => formRef.current.submit()}
          onSubmit={() => {
            // formRef.current.submit();
            formRef.current
              .validateFields()
              .then(() => {
                handleSaveProject();
              })
              .catch((err) => {
                setActiveKey(ACTIVE_KEYS.OVERVIEW);
                console.error(err, "Missing fields");
              });
            // console.log(formRef.current.validateFields(), "formRefCurrent");

            // handleSaveProject();
          }}
          hasNext={hasNext}
          setActiveKey={setActiveKey}
          loadingAction={loading}
          //   onAttachImages={onImageAttached}
        />
      }
      onClose={onClose}
      open={show}
      title="Add new Project"
    >
      <TabsMenu
        // variant="expanded"
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        onChange={(item) => setActiveKey(item)}
        options={[
          {
            title: "OVERVIEW",
            key: "overview",
            children: (
              <CreateProjectForm
                globalForm={form}
                formRef={formRef}
                tab={activeKey}
                shareFile={getFileReference}
                projectCompletionPercentage={projectCompletionPercentage}
                setProjectCompletionPercentage={setProjectCompletionPercentage}
                privateProject={privateProject}
                setPrivateProject={setPrivateProject}
                showProgress={showProgress}
                setShowProgress={setShowProgress}
                location={location}
                getAddress={getAddress}
              />
            ),
          },
          {
            title: "SOCIAL",
            key: "media",
            children: (
              <CreateSocialMediaForm
                globalForm={form}
                formRef={formRef}
                tab={activeKey}
              />
            ),
          },
          //   {
          //     title: "ENGAGEMENT",
          //     key: "engagement",
          //     children: <CreateProjectForm />,
          //   },
          //   {
          //     title: "PARTNER",
          //     key: "partner",
          //     children: <CreateProjectForm />,
          //   },
          //   {
          //     title: "USERS",
          //     key: "users",
          //     children: <CreateProjectForm />,
          //   },
        ]}
      />
      {/* </Layout> */}
    </BottomDrawer>
  );
};

export default CreateProjectTabs;
