import React, { Fragment, useContext, useEffect, useState } from "react";
import { I18nContext } from "utils/i18n/locale";
import { Col, Row } from "antd";
import FlagsKanbanLane from "components/organisms/FlagsKanbanLane";
import "./FlagsActivityKanbanView.scss";
import KanbanAddPlaceholder from "components/molecules/KanbanAddPlaceholder";
import { Text } from "components/atoms/texts";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import RejectFlagModal from "../RejectFlagModal";
import ApproveFlagModal from "../ApproveFlagModal";
import {
  getAssignedUsers,
  getDepartments,
  getFlags,
  updateFlag,
} from "redux/slices/flags";
import { SwalAlerts } from "components/atoms/outputs";
import { unwrapResult } from "@reduxjs/toolkit";
import { getCurrentProjectId } from "utils/common";

const COLORS = {
  pending: "#A080E1",
  open: "#00B999",
  close: "#C43A5A",
  rejected: "#C43A5A",
  resolved: "#00B999",
  blocked: "#C43A5A",
};

const FlagsActivityKanbanView = ({ data, openModal, onClick }) => {
  const { translate } = useContext(I18nContext);
  const [activities, setActivities] = useState(data);
  const [activitiesAux, setActivitiesAux] = useState(data);
  const flagDetail = useSelector((state) => state.flags.flag);
  const assignedUsers = useSelector((state) => state.flags.assignedUsers);
  const departments = useSelector((state) => state.flags.departments);
  const flagRejected = useSelector((state) => state.flags.flagRejected);
  const [flagChanged, setFlagChanged] = useState(false);
  const dispatch = useDispatch();
  const [openReject, setOnOpenReject] = useState(false);
  const [openApprove, setOnOpenApprove] = useState(false);

  const [cancelMove, setCancelMove] = useState(false);

  const [flag, setFlag] = useState(undefined);

  useEffect(() => {
    setActivities(data);
  }, [data]);
  useEffect(() => {
    if (!flagRejected) {
      setActivities(activitiesAux);
    }
  }, [flagRejected]);

  useEffect(() => {
    // console.log("activities:", activities);
  }, [activities]);

  useEffect(() => {
    dispatch(getDepartments(getCurrentProjectId()));
    dispatch(getAssignedUsers(getCurrentProjectId()));
  }, [openApprove]);

  const onCancelMove = (setVisibleFunction) => {
    setVisibleFunction(false);
    setActivities(activitiesAux);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (
    itemId,
    source,
    destination,
    droppableSource,
    droppableDestination
  ) => {
    switch (droppableDestination.droppableId) {
      case "rejected":
        setFlag(itemId);
        setOnOpenReject(true);
        break;
      case "open":
        setFlag(itemId);
        setOnOpenApprove(true);
        break;
      case "resolved":
        dispatch(updateFlag({ id: itemId, status: "resolved" }))
          .then(unwrapResult)
          .then(() => {
            SwalAlerts({
              title: translate("pages").flags.flagDetails.modal.resolvedSuccess
                .title,
              text: translate("pages").flags.flagDetails.modal.resolvedSuccess
                .text,
              type: "success",
            });
            // props.onCancel();
          })
          .catch(() => {
            SwalAlerts({
              title: translate("pages").flags.flagDetails.modal.resolvedFailed
                .title,
              text: translate("pages").flags.flagDetails.modal.resolvedFailed
                .text,
              type: "error",
            });
          });
        break;
      case "blocked":
        dispatch(updateFlag({ id: itemId, status: "blocked" }))
          .then(unwrapResult)
          .then(() => {
            SwalAlerts({
              title: translate("pages").flags.flagDetails.modal.blockedSuccess
                .title,
              text: translate("pages").flags.flagDetails.modal.blockedSuccess
                .text,
              type: "success",
            });
            // props.onCancel();
          })
          .catch(() => {
            SwalAlerts({
              title: translate("pages").flags.flagDetails.modal.blockedFailed
                .title,
              text: translate("pages").flags.flagDetails.modal.blockedFailed
                .text,
              type: "error",
            });
          });
        break;
      case "closed":
        dispatch(updateFlag({ id: itemId, status: "closed" }))
          .then(unwrapResult)
          .then(() => {
            SwalAlerts({
              title: translate("pages").flags.flagDetails.modal.closedSuccess
                .title,
              text: translate("pages").flags.flagDetails.modal.closedSuccess
                .text,
              type: "success",
            });
            // props.onCancel();
          })
          .catch(() => {
            SwalAlerts({
              title: translate("pages").flags.flagDetails.modal.closedFailed
                .title,
              text: translate("pages").flags.flagDetails.modal.closedFailed
                .text,
              type: "error",
            });
          });
        break;
      case "pending":
        dispatch(updateFlag({ id: itemId, status: "pending" }))
          .then(unwrapResult)
          .then(() => {
            SwalAlerts({
              title: translate("pages").flags.flagDetails.modal.pendingSuccess
                .title,
              text: translate("pages").flags.flagDetails.modal.pendingSuccess
                .text,
              type: "success",
            });
            // props.onCancel();
          })
          .catch(() => {
            SwalAlerts({
              title: translate("pages").flags.flagDetails.modal.pendingFailed
                .title,
              text: translate("pages").flags.flagDetails.modal.pendingFailed
                .text,
              type: "error",
            });
          });
        break;
      default:
      // item.pending.push(flag);
    }

    // if (droppableDestination.droppableId === "rejected") {
    //   setFlag(itemId);
    //   setOnOpenReject(true);
    // }

    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    return {
      ...activities,
      [droppableSource.droppableId]: sourceClone,
      [droppableDestination.droppableId]: destClone,
    };
  };

  const onDragEnd = (dragResult) => {
    // console.log("Moved: =>", dragResult);
    const { source, destination } = dragResult;
    // console.log(source, destination);

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        activities[source.droppableId],
        source.index,
        destination.index
      );

      let state = { ...activities, [source.droppableId]: items };

      // if (source.droppableId === 'droppable2') {
      //   state = { selected: items };
      // }

      setActivities(state);
    } else {
      const result = move(
        dragResult.draggableId,
        activities[source.droppableId],
        activities[destination.droppableId],
        source,
        destination
      );
      setActivities(result);
      // const items = reorder(
      //   activities[source.droppableId],
      //   source.index,
      //   destination.index
      // );
      // let state = { ...activities, [source.droppableId]: items };

      // if (!flagRejected) {
      //   setActivities(state);
      // }
    }
  };

  return (
    <Fragment>
      <RejectFlagModal
        data={flag}
        onCancel={() => onCancelMove(setOnOpenReject)}
        visible={openReject}
      />

      <ApproveFlagModal
        data={flag}
        onCancel={() => onCancelMove(setOnOpenApprove)}
        // onCancel={() => setOnOpenApprove(false)}
        departments={departments}
        assignedUsers={assignedUsers}
        visible={openApprove}
      />

      <Col>
        <Text
          className="flag-section-title"
          text={translate("pages").flags.activity.title}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <Row gutter={[4, 0]}>
            {Object.keys(activities).map((item) => (
              <Col lg={24 / 4} md={24 / 3} sm={24 / 2} xs={24} key={item}>
                <FlagsKanbanLane
                  key={item}
                  droppableId={item}
                  onClick={onClick}
                  onAddPressed={openModal}
                  color={COLORS[item]}
                  title={translate("pages").flags.activity[item]}
                  data={activities[item]}
                />
              </Col>
            ))}
            {/* TODO: handle function to add new status  */}
            {/* <Col lg={24 / 4} md={24 / 3} sm={24 / 2} xs={24}>
              <KanbanAddPlaceholder
                onClick={() => console.log("Add Placeholder!")}
              />
            </Col> */}
          </Row>
        </DragDropContext>
      </Col>
    </Fragment>
  );
};
export default FlagsActivityKanbanView;
