import React, { useContext, useEffect, useRef, useState } from "react";
import { Menu, Layout, Badge } from "antd";
import MemoizedAvatar from "components/molecules/ProjectSelect/Avatar";
import "./NavBar.scss";
import {
  DownOutlined,
  LogoutOutlined,
  MailFilled,
  QuestionCircleOutlined,
  SettingFilled,
  UserOutlined,
} from "@ant-design/icons";
import { I18nContext } from "utils/i18n/locale";
import { NotificationIcon } from "components/atoms/icons";
import { CommunicationIcon } from "components/atoms/icons";
import { useSelector } from "react-redux";
import { cloudFrontUrl, getCurrentProjectId, textTruncate } from "utils/common";
import { useHistory } from "react-router";
import { Fragment } from "react";
import NotificationsPane from "../NotificationsPane";
import NavbarSearch from "../NavBarSearch";

const { Header } = Layout;

const NavBar = () => {
  const notifications = [];
  const { translate } = useContext(I18nContext);
  const profile = useSelector((state) => state.profile.profile);
  const currentProject = useSelector((state) => state.projects.currentProject);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [feedbackActive, setFeedbackActive] = useState(false);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [permissions, setPermissions] = useState(currentProject?.permissions);
  const [showNotificationsPane, setShowNotificationsPane] = useState(false);
  const node = useRef();
  const node_profile_list = useRef();
  const node_two = useRef();
  const node_feedback_button = useRef();
  const node_icon = useRef();
  const node_icon_list = useRef();

  const history = useHistory();

  const PREDOMINANT_COLOR = "rgba(20, 20, 20, 0.54)";

  useEffect(() => {
    setCurrentProfile(profile);
  }, [profile]);

  useEffect(() => {
    setPermissions(currentProject?.permissions);
  }, [currentProject]);
  useEffect(() => {
    if (currentProfile === null || currentProfile === undefined) {
      const ls_profile = JSON.parse(localStorage.getItem("current_user"));
      if (ls_profile) {
        setCurrentProfile(ls_profile);
      }
    }
  }, [currentProfile]);

  const handleClickOutside = (e) => {
    if (
      node?.current?.contains(e.target) ||
      node_profile_list?.current?.contains(e.target) ||
      node_feedback_button?.current?.contains(e.target) ||
      node_two?.current?.contains(e.target) ||
      node_icon?.current?.contains(e.target) ||
      node_icon_list?.current?.contains(e.target)
    ) {
      // inside click
      return;
    } else {
      setFeedbackActive(false);
      setDropdownActive(false);
      setShowNotificationsPane(false);
    }
  };

  useEffect(() => {
    if (dropdownActive) {
      setFeedbackActive(false);
      setShowNotificationsPane(false);
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownActive]);

  useEffect(() => {
    if (feedbackActive) {
      setDropdownActive(false);
      setShowNotificationsPane(false);
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [feedbackActive]);

  const handleMenuClick = (route) => {
    history.push(route);
    setFeedbackActive(false);
    setDropdownActive(false);
  };

  useEffect(() => {
    if (showNotificationsPane) {
      setFeedbackActive(false);
      setDropdownActive(false);
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNotificationsPane]);

  return (
    <Fragment>
      <NotificationsPane
        reference={node_icon_list}
        show={showNotificationsPane}
        onClose={() => setShowNotificationsPane(false)}
      />
      <Header className="navbar navbar-styles">
        <div className="main-block">
          <NavbarSearch />
          <div className="blocks profile-block">
            <div
              ref={node}
              className="buttons profile-rounded-button"
              onClick={() => {
                setDropdownActive(!dropdownActive);
              }}
              style={{
                backgroundColor: dropdownActive ? "rgba(20, 20, 20, 0.04)" : "",
              }}
            >
              <MemoizedAvatar
                size={40}
                radius={100}
                className={null}
                {...{
                  ...(currentProfile?.image
                    ? {
                        src: cloudFrontUrl(currentProfile?.image),
                      }
                    : {
                        avatarName: true,
                        avatarSize: 42,
                        userName: `${currentProfile?.name} ${currentProfile?.last_name}`,
                      }),
                }}
                // src={
                //   currentProfile?.image
                //     ? cloudFrontUrl(currentProfile?.image)
                //     : undefined
                // }
                // logo={currentProfile?.image ?? ""}
                customPlaceHolder="https://image.flaticon.com/icons/png/128/727/727399.png"
              />
              <span className="profile-name">
                {currentProfile?.name}{" "}
                {currentProfile?.last_name?.length <= 17
                  ? currentProfile?.last_name
                  : textTruncate(currentProfile?.last_name, 14, "...")}
              </span>
              <DownOutlined
                style={{
                  color: PREDOMINANT_COLOR,
                  right: 0,
                  marginLeft: "auto",
                  marginRight: 10,
                  fontSize: 12,
                  fontWeight: "bolder",
                }}
              />
            </div>
          </div>
          <div
            className="profile-info-list lists"
            ref={node_profile_list}
            hidden={!dropdownActive}
          >
            <Menu className="profile-options">
              <Menu.Item
                key="0"
                icon={
                  <UserOutlined
                    style={{ color: PREDOMINANT_COLOR, fontSize: 18 }}
                  />
                }
                onClick={() => handleMenuClick("/home/profile")}
              >
                {translate("organisms").navbar.profileOptions.myProfile}
              </Menu.Item>
              {/* <Menu.Item
                key="1"
                icon={<MailFilled style={{ color: PREDOMINANT_COLOR , fontSize: 18 }} />}
              >
                {translate("organisms").navbar.profileOptions.email}
              </Menu.Item> */}
              {/* {permissions?.settings?.read && ( */}
              <Menu.Item
                key="2"
                icon={
                  <SettingFilled
                    style={{ color: PREDOMINANT_COLOR, fontSize: 18 }}
                  />
                }
                onClick={() => handleMenuClick("/home/settings")}
              >
                {translate("organisms").navbar.profileOptions.settings}
              </Menu.Item>
              {/* )} */}

              <Menu.Divider />
              <Menu.Item
                key="3"
                icon={
                  <LogoutOutlined
                    style={{ color: PREDOMINANT_COLOR, fontSize: 18 }}
                  />
                }
                onClick={() => window.location.replace(`/verify?logout=true`)}
              >
                {translate("organisms").navbar.profileOptions.logout}
              </Menu.Item>
            </Menu>
          </div>
          <div
            className="feedback-info-list lists"
            hidden={!feedbackActive}
            ref={node_feedback_button}
          >
            <Menu className="profile-options">
              {/* <Menu.Item
                key="0"
                icon={
                  <QuestionCircleOutlined
                    style={{ color: PREDOMINANT_COLOR, fontSize: 18 }}
                  />
                }
                // onClick={() => handleMenuClick("/home/profile")}
              >
                {translate("organisms").navbar.feedbackOptions.feedback}
              </Menu.Item> */}
              <Menu.Item
                key="2"
                icon={
                  <QuestionCircleOutlined
                    style={{ color: PREDOMINANT_COLOR, fontSize: 18 }}
                  />
                }
                onClick={() => handleMenuClick("/home/knowledge")}
              >
                {translate("organisms").navbar.feedbackOptions.knowledge}
              </Menu.Item>
            </Menu>
          </div>
          <div className="blocks small-block">
            <div
              className="buttons small-rounded-button"
              ref={node_two}
              onClick={() => {
                setFeedbackActive(!feedbackActive);
              }}
              style={{
                backgroundColor: feedbackActive ? "rgba(20, 20, 20, 0.04)" : "",
              }}
            >
              <QuestionCircleOutlined
                style={{
                  fontSize: 20,
                  width: 30,
                  height: 20,
                  color: PREDOMINANT_COLOR,
                }}
              />
              {/* <MailFilled
                style={{
                  width: 20,
                  height: 20,
                  color: PREDOMINANT_COLOR,
                }}
              /> */}
            </div>
          </div>
          <div
            className="blocks small-block"
            onClick={() => setShowNotificationsPane(!showNotificationsPane)}
          >
            <div
              className="buttons small-rounded-button"
              ref={node_icon}
              onClick={() => setShowNotificationsPane(!showNotificationsPane)}
              style={{
                backgroundColor: showNotificationsPane
                  ? "rgba(20, 20, 20, 0.04)"
                  : "",
              }}
              // onClick={() => setShowNotificationsPane(!showNotificationsPane)}
            >
              <Badge
                dot={notifications.length > 0}
                offset={[4, -6]}
                title={translate("organisms").navbar.notificationsPane.dotHelp}
                onClick={() => setShowNotificationsPane(!showNotificationsPane)}
              >
                <NotificationIcon
                  color={PREDOMINANT_COLOR}
                  height="20px"
                  width="20px"
                  margin={5}
                  onClick={() =>
                    setShowNotificationsPane(!showNotificationsPane)
                  }
                />
              </Badge>
            </div>
          </div>
        </div>
      </Header>
    </Fragment>
  );
};

export default NavBar;
