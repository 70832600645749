import React, { useState } from "react";
import {
  Input,
  AutoComplete,
  Layout,
  Row,
  Col,
  Radio,
  Checkbox,
  Select,
  Spin,
} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { text_style, create_element_title } from "config/styles/text";
import "./inputs.scss";
import { Text } from "components/atoms/texts";
import debounce from "lodash/debounce";
import { unwrapResult } from "@reduxjs/toolkit";
// const { Option } = AutoComplete;
const { Option } = Select;
const { TextArea } = Input;

function DebounceSelect({
  fetchOptions,
  debounceTimeout = 800,
  onSelect,
  onDeselect,
  placeholder,
  style,
  ...props
}) {
  const { Option } = Select;
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value)?.then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions.body);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      mode="multiple"
      tokenSeparators={[","]}
      filterOption={false}
      onSearch={debounceFetcher}
      // notFoundContent={fetching ? <Spin size="small" /> : null}
      loading={fetching}
      {...props}
      onSelect={onSelect}
      onDeselect={onDeselect}
      showSearch
      // value={value}
      placeholder={placeholder}
      style={style}
      defaultActiveFirstOption={false}
      showArrow={true}
      filterOption={false}
      // onSearch={onSearch}
      // onChange={(e) => console.log(e)}
      notFoundContent={null}
    >
      {options.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.email}
        </Option>
      ))}
    </Select>
  );
} // Usage of DebounceSelect

export const FloatLabel = (props) => {
  const { children, label } = props;
  const { value, id } = children.props;
  const labelClass = value ? "label label-float" : "label";
  return (
    <div className="float-label">
      {children}
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
    </div>
  );
};

export const DataInput = ({
  style,
  placeholder,
  isPassword,
  mailAutocomplete,
  customClass,
  className,
  checks = [],
  title,
  type = "text",
  showWithText,
  datePicker,
  age,
  disabled,
  isSearch,
  searchedItems,
  // value,
  onSearch,
  // onChange,
  onSelect,
  onDeselect,
  ...props
}) => {
  const [result, setResult] = useState([]);
  const [errors, setErrors] = useState([]);
  const [value, setValue] = useState([]);
  const handleSearch = (value) => {
    let res = [];
    if (!value || value.indexOf("@") >= 0) {
      res = [];
    } else {
      res = ["heyirys.com", "gmail.com", "hotmail.com", "yahoo.com"].map(
        (domain) => `${value}@${domain}`
      );
    }
    setResult(res);
  };

  const InputComponent = type === "textarea" ? TextArea : Input;
  const inputClassname =
    type === "textarea" ? "title-textarea-form-item" : "title-form-item";
  const inputAlign = type === "textarea" ? "start" : "middle";

  return isPassword ? (
    <Layout
      className={`input-layout ${customClass} ${
        errors && errors.length > 0 ? "error" : ""
      } `}
    >
      {" "}
      <FloatLabel label={placeholder}>
        <Input.Password
          className="form-item"
          {...props}
          iconRender={(visible) =>
            visible && showWithText ? (
              "Hide"
            ) : !visible && showWithText ? (
              "Show"
            ) : visible ? (
              <EyeTwoTone />
            ) : (
              <EyeInvisibleOutlined />
            )
          }
          placeholder={placeholder}
          style={{ ...text_style, ...style }}
        />
      </FloatLabel>
      {errors.length > 0 && (
        <div className="error-box">
          <div className="error-message">
            {errors.map((text, index) => {
              return <Text index={index} text={text} />;
            })}
          </div>
        </div>
      )}
    </Layout>
  ) : mailAutocomplete ? (
    <Layout>
      <FloatLabel label={placeholder}>
        <AutoComplete
          {...props}
          style={{ ...text_style, ...style }}
          onSearch={handleSearch}
          className="form-item"
          placeholder={placeholder}
        >
          {result.map((email) => (
            <Option key={email} value={email}>
              {email}
            </Option>
          ))}
        </AutoComplete>
      </FloatLabel>
    </Layout>
  ) : title ? (
    isSearch ? (
      <Row align={inputAlign} justify="space-between">
        <Col xl={6} xs={24}>
          <Text text={title} />
        </Col>
        <Col xl={18} xs={24}>
          <DebounceSelect
            placeholder={placeholder}
            style={{ ...text_style, ...style }}
            fetchOptions={onSearch}
            onSelect={onSelect}
            onDeselect={onDeselect}
          />
          {/* <Select
            mode="tags"
            tokenSeparators={[","]}
            showSearch
            // value={value}
            placeholder={placeholder}
            style={{ ...text_style, ...style }}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={onSearch}
            // onChange={(e) => console.log(e)}
            onSelect={onSelect}
            onDeselect={onDeselect}
            notFoundContent={null}
          >
            {searchedItems.map((item) => (
              <Option key={item.id}>{item.email}</Option>
            ))}
          </Select> */}
          {/* <InputComponent
            className={inputClassname}
            {...props}
            style={{ ...text_style, ...style }}
            placeholder={placeholder}
          /> */}
        </Col>
      </Row>
    ) : (
      <Row align={inputAlign} justify="space-between">
        <Col xl={6} xs={24}>
          <Text text={title} />
        </Col>
        <Col xl={18} xs={24}>
          <InputComponent
            className={inputClassname}
            {...props}
            style={{ ...text_style, ...style }}
            placeholder={placeholder}
          />
        </Col>
      </Row>
    )
  ) : datePicker ? (
    <Layout className={`select-input`}>
      <div className="float-label-select">
        <label className={`label-float-select`}>{placeholder}</label>
        <Layout className={`select-item`}>
          <Text text={props.value} className="select-text-date" />
          {age && <Text text={age} className="select-text-date-2" />}
        </Layout>
      </div>
    </Layout>
  ) : (
    <Layout
      className={`input-layout ${customClass} ${
        errors && errors.length > 0 ? "error" : ""
      } `}
    >
      <FloatLabel label={placeholder}>
        <InputComponent
          disabled={disabled}
          className={`form-item ${className}`}
          {...props}
          style={{ ...text_style, ...style }}
          placeholder={placeholder}
        />
      </FloatLabel>
      {errors.length > 0 && (
        <div className="error-box">
          <div className="error-message">
            {errors.map((text, index) => {
              return <Text index={index} text={text} />;
            })}
          </div>
        </div>
      )}
    </Layout>
  );
};
export const CreateElementInput = ({
  style,
  placeholder,
  label,
  textArea,
  invisible,
  customClass,
  textAreaRows = 1,
  defaultValue,
  customValue,
  className,
  special,
  ...props
}) => {

  return textArea && !special ? (
    <Layout className="input-layout">
      <Text text={label} />
      <Input.TextArea
        allowClear
        rows={textAreaRows}
        className="create-element"
        {...props}
        defaultValue={defaultValue}
        placeholder={placeholder}
        style={{ ...text_style, ...style }}
      />
    </Layout>
  ) : invisible ? (
    <Layout>
      <Input
        {...props}
        value={defaultValue}
        className={`create-element-invisible ${className}`}
        // allowClear
        // defaultValue={placeholder}
        bordered={false}
        style={{ ...create_element_title, ...style }}
        placeholder={placeholder}
      />
    </Layout>
  ) : (special && textArea)? (

    <Layout className="input-layout">
      <Text text={label} />
      <Input.TextArea
        {...props}
        defaultValue={defaultValue}
        value={defaultValue}
        allowClear
        rows={textAreaRows}
        className="create-element"
        placeholder={placeholder}
        style={{ ...text_style, ...style }}
      />
    </Layout>
  ) :special  ? (
    <Layout className="input-layout">
    <Text text={label} />
    <Input
      {...props}
      value={customValue}
      defaultValue={customValue}
      allowClear
      className={className ?? "create-element"}
      style={{ ...text_style, ...style }}
      placeholder={placeholder}
    />
  </Layout>
  ): (
    <Layout className="input-layout">
      <Text text={label} />
      <Input
        value={customValue}
        defaultValue={customValue}
        allowClear
        className={className ?? "create-element"}
        {...props}
        style={{ ...text_style, ...style }}
        placeholder={placeholder}
      />
    </Layout>
  );
};
export const StandardInput = ({ prefixIcon, suffixIcon, ...props }) => (
  <Input prefix={prefixIcon} suffix={suffixIcon} {...props} />
);
export const FieldsetInput = ({ legend, className, ...props }) => (
  <fieldset className={`${className} fieldset-input`}>
    <legend>{legend}</legend>
    <Input {...props} />
  </fieldset>
);
export const RadioGroup = ({
  // onChange = () => {},
  value,
  options,
  defaultValue,
  className,
  isList,
  ...props
}) => {
  const [v, setValue] = React.useState(false);
  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <Radio.Group
      // onChange={onChange}
      value={v}
      // defaultValue={defaultValue}
      className={`${className}  ${isList ? "radio-input" : ""} `}
    >
      {options?.map((option, index) => (
        <Radio key={index} value={option.title ?? option}>
          {option.title ?? option}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export const CheckboxSurvey = ({
  options,
  defaultValue,
  className,
  isSurvey,
  onChange,
  value,
}) => {
  let surveyOptions = [];
  if (isSurvey) {
    for (const option of options) {
      let formatedData = { label: option.title, value: option.title };
      surveyOptions.push(formatedData);
    }
  } else {
    surveyOptions = options;
  }
  return (
    <div className={`checkbox-input ${className}}`}>
      <Checkbox.Group
        options={surveyOptions}
        defaultValue={defaultValue}
        disabled={isSurvey}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export const CheckboxInput = ({
  options,
  defaultValue,
  className,
  isSurvey,
}) => {
  let surveyOptions = [];
  if (isSurvey) {
    for (const option of options) {
      let formatedData = { label: option.title, value: option.title };
      surveyOptions.push(formatedData);
    }
  } else {
    surveyOptions = options;
  }
  return (
    <div className={`checkbox-input ${className}}`}>
      <Checkbox.Group
        options={surveyOptions}
        defaultValue={defaultValue}
        disabled={isSurvey}
      />
    </div>
  );
};
export const SelectBox = ({
  placeholder,
  onChange,
  label,
  items,
  defaultValue,
  isTimeZone,
  disabled,
  height
}) => {
  const { Option } = Select;
  return (
    <>
    <Text text ={label} />
      <Select
        disabled={disabled}
        showSearch
        style={{height, width: "100%", marginTop:"10px"}}
        placeholder={placeholder}
        defaultValue={defaultValue}
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {!isTimeZone &&
          items.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        {/* {isTimeZone &&
          items.map((item) => (
            <Option key={item.text} value={item.text}>
              {item.text}
            </Option>
          ))} */}
        {isTimeZone &&
          Object.keys(items).map((item) => (
            <Option key={item} value={item}>
              {items[item]}
            </Option>
          ))}
      </Select>
    </>
  );
};
