import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import citizenAPI from 'api/citizenAPI';

const initialState = {
  previewData: {
    gender: '',
    name: '',
    last_name: '',
    phone: '',
    email: '',
  },
  citizenDetails: [],
  citizenProfile: null,
  citizenUpdate: false,
  citizenCreated: false,
  citizenProfileUpdate: false,
  error: null,
  citizenData: [],
  loading: true,
  projects:null,
  projectLoaded:false,
  custom:false

};

const awsErrorSerialized = {
  error: 'ASW Error',
};

const awsError = (resp) => {
  console.log('data', resp); // print to validate response
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};

export const asyncGetData = (previewData) => async (dispatch) => {
  try {
    dispatch(startGetForm());
    dispatch(successGetForm(previewData));
  } catch (error) {
    dispatch(errorGetForm(error.toString()));
  }
};

export const saveCitizen = createAsyncThunk(
  'citizen/saveCitizen',
  async (item, {rejectWithValue}) => {
    try {
      const citizens = await citizenAPI.saveCitizen(item); // Call to API
      awsError(citizens);
      return citizens.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  },
);

export const saveCitizenProject = createAsyncThunk(
  'citizen/saveCitizenProject',
  async (item, {rejectWithValue}) => {
    try {

      let dataToSend = { ...item };
      delete dataToSend.id;
      console.log("readyToSend",dataToSend)
      const citizens = await citizenAPI.saveCitizenProject(item.id,dataToSend); // Call to API
      awsError(citizens);
      return citizens.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  },
);

export const readCitizen = createAsyncThunk(
  'citizen/readCitizen',
  async (id, {rejectWithValue}) => {
    try {

      const citizens = await citizenAPI.readCitizen(id);
      awsError(citizens);
      return citizens.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  },
);

export const updateCitizen = createAsyncThunk(
  'citizen/updateCitizen',
  async (id, {rejectWithValue}) => {
    try {
      const citizens = await citizenAPI.updateCitizen(id);
      awsError(citizens);
      return citizens.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  },
);

export const getAllCitizen = createAsyncThunk(
  'citizen/getAllCitizen',
  async (payload, {dispatch, rejectWithValue}) => {
    try {
      const citizens = await citizenAPI.getAllCitizen();
      awsError(citizens);
      return citizens.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  },
);

export const readCitizenProjects = createAsyncThunk(
  'citizen/readCitizenProjects',
  async (rejectWithValue) => {
    try {
      const projects = await citizenAPI.readCitizenProjects();
      awsError(projects);
      return projects.data;
    } catch (error) {
        console.log("error", error)
      return rejectWithValue(error.error);
    }
  },
);

export const readCitizenProfile = createAsyncThunk(
  'citizen/readCitizenProfile',
  async (payload, {rejectWithValue}) => {
    try {
      const citizens = await citizenAPI.readCitizenProfile();
      awsError(citizens);
      return citizens.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  },
);

export const updateCitizenProfile = createAsyncThunk(
  'citizen/updateCitizenProfile',
  async (data, {rejectWithValue}) => {
    try {
      const citizens = await citizenAPI.updateCitizenProfile(data);
      awsError(citizens);
      return citizens.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  },
);

export const customClientSet = (projectData) => async (dispatch) => {
  console.log("setCustom");
  dispatch(setCustom(projectData));
};
export const deleteLocalData = () => async (dispatch) => {
  console.log("deleteLocalData");
  dispatch(deleteLocalData());
};
const citizen = createSlice({
  name: 'citizen',
  initialState,
  reducers: {
    setCustom(state, action) {
      console.log(action.payload)

      state.custom = action.payload;
    },
    deleteLocalData(state,action){
      state.citizenProfile = null;
    }
  },
  extraReducers: {



    //saveCitizen
    [saveCitizen.pending]: (state, action) => {
      console.info('saveCitizen pending');
      state.loading = true;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = false;
      state.citizenProfileUpdate = false;
    },
    [saveCitizen.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = true;
      state.citizenProfileUpdate = false;
      console.info('saveCitizen fullfilled =>', action.payload);
    },
    [saveCitizen.rejected]: (state, action) => {
      console.error('saveCitizen rejected =>', action.payload);
      state.loading = false;
      state.error = action.payload;
    },

    [saveCitizenProject.pending]: (state, action) => {
      console.info('saveCitizenProject pending');
      state.loading = true;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = false;
      state.citizenProfileUpdate = false;
    },
    [saveCitizenProject.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = true;
      state.citizenProfileUpdate = false;
      console.info('saveCitizenProject fullfilled =>', action.payload);
    },
    [saveCitizenProject.rejected]: (state, action) => {
      console.error('saveCitizenProject rejected =>', action.payload);
      state.loading = false;
      state.error = action.payload;
    },

    

    //readCitizen
    [readCitizen.pending]: (state, action) => {
      console.info('readCitizen pending');
      state.loading = true;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = false;
      state.citizenProfileUpdate = false;
    },
    [readCitizen.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = false;
      state.citizenProfileUpdate = false;
      console.info('readCitizen fullfilled');
      state.citizenDetails = action.payload.body;
    },
    [readCitizen.rejected]: (state, action) => {
      console.error('readCitizen rejected =>', action.payload);
      state.loading = false;
      state.error = action.payload;
      state.citizenDetails = [];
    },

    //updateCitizen
    [updateCitizen.pending]: (state, action) => {
      console.info('updateCitizen pending');
      state.loading = true;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = false;
      state.citizenProfileUpdate = false;
    },
    [updateCitizen.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.citizenUpdate = true;
      state.citizenCreated = false;
      state.citizenProfileUpdate = true;
      console.info('updateCitizen fullfilled');
    },
    [updateCitizen.rejected]: (state, action) => {
      console.error('updateCitizen rejected =>', action.payload);
      state.loading = false;
      state.error = action.payload;
    },

    //getAllCitizen
    [getAllCitizen.pending]: (state, action) => {
      console.info('getAllCitizen pending');
      state.loading = true;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = false;
      state.citizenProfileUpdate = false;
    },
    [getAllCitizen.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = false;
      state.citizenProfileUpdate = false;
      console.info('getAllCitizen fullfilled');
      state.citizenData = action.payload.body;
    },
    [getAllCitizen.rejected]: (state, action) => {
      console.error('getAllCitizen rejected =>', action.error);
      state.loading = false;
      state.error = action.payload;
      state.citizenData = [];
    },

    //readCitizenProfile
    [readCitizenProfile.pending]: (state, action) => {
      console.info('readCitizenProfile pending');
      state.loading = true;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = false;
      state.citizenProfileUpdate = false;
    },
    [readCitizenProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = false;
      state.citizenProfileUpdate = false;
      console.info('readCitizenProfile fullfilled',action.payload.body);
      state.citizenProfile = action.payload.body;
    },
    [readCitizenProfile.rejected]: (state, action) => {
      console.error('readCitizenProfile rejected =>', action.payload);
      state.loading = false;
      state.error = true;
      state.citizenProfile = [];
          },

    //readCitizenProjects
    [readCitizenProjects.pending]: (state, action) => {
      console.info('readProjects pending');
      state.projectLoaded = false;
      state.error = null;
      state.projects = [];
    },
    [readCitizenProjects.fulfilled]: (state, action) => {
      state.projectLoaded = true;
      state.error = null;
      console.info('readProjects fullfilled');
      state.projects = action.payload.body;
    },
    [readCitizenProjects.rejected]: (state, action) => {
      console.error('readProjects rejected =>', action.payload);
      state.projectLoaded = false;
      state.error = action.payload;
      state.projects = [];
    },

    //updateCitizenProfile
    [updateCitizenProfile.pending]: (state, action) => {
      console.info('updateCitizenProfile pending');
      state.loading = true;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = false;
      state.citizenProfileUpdate = false;
    },
    [updateCitizenProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.citizenUpdate = false;
      state.citizenCreated = false;
      state.citizenProfileUpdate = true;
      console.info('updateCitizenProfile fullfilled');
    },
    [updateCitizenProfile.rejected]: (state, action) => {
      console.error('updateCitizenProfile rejected =>', action.payload);
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {startGetForm, successGetForm, errorGetForm,setCustom} = citizen.actions;
export default citizen.reducer;
