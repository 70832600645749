import React, { useContext, useState } from "react";
import { Col, Layout, Row } from "antd";
import { Text } from "components/atoms/texts";
import SurveyListItem from "components/molecules/SurveyListItem";
import { options } from "pages/Surveys";
import DataTable from "components/organisms/DataTable";
import { I18nContext } from "utils/i18n/locale";
import "./SurveyListTemplate.scss";

const SurveyListTemplate = ({ surveyItems }) => {
  const { translate } = useContext(I18nContext);
  const [currentIndex, setCurrentIndex] = useState(-1);
  return (
    <Layout>
      <Row className="survey-list-header">
        <Col xs={11} lg={8}>
          <Text
            text={translate("pages").survey.title.name}
            className="survey-list-header-text survey-list-header-title"
          />
        </Col>
        <Col xs={11} lg={7} className="survey-list-header-text-wrapper">
          <Text
            text={translate("pages").survey.title.created}
            className="survey-list-header-text"
          />
        </Col>
        <Col xs={0} lg={7} className="survey-list-header-text-wrapper">
          <Text
            text={translate("pages").survey.title.lastEdit}
            className="survey-list-header-text"
          />
        </Col>
      </Row>
      {surveyItems.map((item, index) => (
        <SurveyListItem
          data={item}
          dropdownOptions={options}
          index={index}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          key={index}
        />
      ))}
    </Layout>
    // <DataTable props={{ items: surveyItems }} />
  );
};

export default SurveyListTemplate;
