import React, { Suspense, useState } from "react";
import { SpiningPage } from "components/atoms/icons";
import UsersTemplate from "components/templates/UsersTemplate";
import UserFormDrawer from "components/organisms/UserListForm";

const renderLoader = () => <SpiningPage opacity={1} />;

const Users = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [preData, setPredata] = useState();

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const openDrawer = (edit, data) => {
    setShowDrawer(true);
    if (edit) {
      setIsEditForm(true);
      setPredata(data);
    } else {
      setPredata(undefined);
      setIsEditForm(false);
    }
  };

  return (
    <Suspense fallback={renderLoader()}>
      <UsersTemplate openDrawer={openDrawer} />
      <UserFormDrawer
        show={showDrawer}
        onClose={closeDrawer}
        isEdit={isEditForm}
        preData={preData}
      />
    </Suspense>
  );
};

export default Users;
