import React, { useContext } from "react";
import { Layout, Menu } from "antd";
import "./SurveyCardDropdown.scss";
import { QuestionContext } from "context/SurveyEditorContext";
import { I18nContext } from "utils/i18n/locale";
import { deleteSurvey, duplicateSurvey, getSurveys } from "redux/slices/surveys";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { AlertWithfunctions } from "components/atoms/outputs";
const SurveyCardDropdown = ({ options, id, setShow }) => {
  const { SubMenu } = Menu;
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  
  const surveyActionComplete = () => {
    setShow(false);
    dispatch(getSurveys())
  };

  const handleActions = (action) => {
    if (action === "Delete") {
      const info = {
        title: translate("pages").survey.alerts.delete.survey.title,
        text: translate("pages").survey.alerts.delete.survey.subtitle,
        icon: "info",
        confirm: translate("pages").survey.alerts.delete.survey.buttons
          .confirm,
        successTitle: translate("pages").survey.alerts.delete.success.title,
        successSubtitle: translate("pages").survey.alerts.delete.success
          .subtitle,
        errorTitle: translate("pages").survey.alerts.delete.error.title,
        errorSubtitle: translate("pages").survey.alerts.delete.error.subtitle,
        dispatchFunction: deleteSurvey,
        dispatcher: dispatch,
        destroyFunction: surveyActionComplete,
        destroyFuncArgs: [],
        unwrapResult,
        values: id,
      };
      AlertWithfunctions(info);
    } else if (action === "Duplicate") {
      const info = {
        title: translate("pages").survey.alerts.duplicate.survey.title,
        text: translate("pages").survey.alerts.duplicate.survey.subtitle,
        icon: "info",
        confirm: translate("pages").survey.alerts.duplicate.survey.buttons
          .confirm,
        successTitle: translate("pages").survey.alerts.duplicate.success.title,
        successSubtitle: translate("pages").survey.alerts.duplicate.success
          .subtitle,
        errorTitle: translate("pages").survey.alerts.duplicate.error.title,
        errorSubtitle: translate("pages").survey.alerts.duplicate.error.subtitle,
        dispatchFunction: duplicateSurvey,
        dispatcher: dispatch,
        destroyFunction: surveyActionComplete,
        destroyFuncArgs: [],
        unwrapResult,
        values: id,
      };
      AlertWithfunctions(info);
    }
  };
  return (
    <Layout className="survey-card-dropdown">
      <Menu>
        {options.map((option) => (
          <>
            {option.isSubMenu ? (
              <SubMenu title={option.key}>
                {option.subMenu.map((submenu) => (
                  <Menu.Item
                    key={submenu.key}
                    onClick={() => handleActions(submenu.key)}
                  >
                    {submenu.key}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item
                danger={option.isDelete}
                key={option.key}
                onClick={() => handleActions(option.key)}
              >
                {option.key}
              </Menu.Item>
            )}
          </>
        ))}
      </Menu>
    </Layout>
  );
};

export default SurveyCardDropdown;
