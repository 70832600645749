import React, {useContext} from 'react'
import {I18nContext} from "utils/i18n/locale";
import {LAYOUT, LEGEND_DIRECTION, LEGEND_POSITION} from "components/molecules/Graphs";
import RadarGraph from "components/molecules/Graphs/Radar";

const CompletionTimeCharts = () => {
	const {translate} = useContext(I18nContext);

	// This should be replaced with an API Call
	const DATA = [
		{
			"category": "Less than a Week",
			"Streets/Traffic": 50,
			"Parks": 40,
			"Sidewalks": 0,
		},
		{
			"category": "More than Four Weeks",
			"Streets/Traffic": 50,
			"Parks": 30,
			"Sidewalks": 12
		},
		{
			"category": "Two Weeks",
			"Streets/Traffic": 40,
			"Parks": 5,
			"Sidewalks": 30
		},
		{
			"category": "Four Weeks",
			"Streets/Traffic": 50,
			"Parks": 60,
			"Sidewalks": 40
		},
		{
			"category": "Three Weeks",
			"Streets/Traffic": 40,
			"Parks": 70,
			"Sidewalks": 5
		}
	]

	return (
		<RadarGraph
			height='100%'
			legendPosition={LEGEND_POSITION.TOP}
			legendDirection={LEGEND_DIRECTION.ROW}
			layout={LAYOUT.HORIZONTAL}
			title={translate("pages").analytics.radarTimeToCompleteTask}
			data={DATA}
			indexBy="category"
			animate={false}
			varyingSize={false}
			keyPoints={{
				"Streets/Traffic": "#0364FF",
				"Parks": "#79C481",
				"Sidewalks": "#5C5C5C",
			}}/>
	)
}

export default CompletionTimeCharts;
