import React, { Suspense, useContext, useState, useEffect } from "react";
import "./flags.scss";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import FAB from "components/atoms/fab";
import { KanbanIcon, ListViewIcon, SpiningPage } from "components/atoms/icons";
import CreateFlagForm from "components/organisms/CreateFlagForm";
import FlagsActivityListView from "components/organisms/FlagsActivityListView";
import FlagsTemplate, {
  LAYOUT_KANBAN,
  LAYOUT_LIST,
} from "components/templates/Flags";
import FlagsActivityKanbanView from "components/organisms/FlagsActivityKanbanView";
import { Select } from "components/atoms/select";
import { StandardInput } from "components/atoms/inputs";
import CheckboxGroup, { TYPE_ICONS } from "components/atoms/checkbox-group";
import { I18nContext } from "utils/i18n/locale";
import NoFlagPlaceholder from "components/organisms/NoFlagPlaceholder";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getFlags,
  getFlagCategories,
  getDepartments,
} from "redux/slices/flags";
import LoadErrorLayout from "components/molecules/LoadErrorLayout";
import { getCurrentProjectId } from "utils/common";
import { useHistory } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { PrimaryButton } from "components/atoms/controls";


const FLAG_STATUS = [
  "pending",
  "open",
  "blocked",
  "resolved",
  "rejected",
  "closed",
];

const SOMETHING_CATEGORIES = {
  love: "Something I love",
  change: "Something I want to change",
  add: "Something I want to add",
};

const renderLoader = () => <SpiningPage opacity={1} />;

const Actions = ({ onChangeLayout, layout = "kanban", data }) => {
  const { translate } = useContext(I18nContext);

  let csv_data = [];

  // const getSignedUrls = async (key) => {
  //   const signedURL = await Storage.get(key);
  //   return signedURL;
  // };

  for (const status of FLAG_STATUS) {
    data[status].map((item) => {
      csv_data.push({
        Status: item?.status,
        Description: item?.description ?? "N/A",
        Address: item?.formatted_address ?? "N/A",
        Latitude: item?.latitude ?? "N/A",
        Longitude: item?.longitude ?? "N/A",
        Type: item?.type ?? "N/A",
        Category: item?.typename?.name
          ? item?.typename?.name
          : item?.something
          ? SOMETHING_CATEGORIES[item?.something]
          : "N/A",
        SubCategory: item?.typename?.typename_category?.name ?? "N/A",
        Source: item?.source ?? "N/A",
        // Images:
        //   item?.images.length > 0
        //     ? item?.images.map((image) =>
        //         getSignedUrls(image).then((key) => key)
        //       )
        //     : "N/A",
      });
    });
  }

  return (
    <div className="actions-container">
      {/* <Row justify="end">
        <Col> */}
      <CSVLink
        data={csv_data}
        filename={`flags.csv`}
        // filename={`${newSurveyTitle ?? "responses"}.csv`}
      >
        {" "}
        <PrimaryButton
          title={translate("pages").surveyEditor.title.exportBtn}
          cssClassName="primary-button-icon"
        />
      </CSVLink>
      {/* </Col>
      </Row> */}
      {/* TODO: handle functions to filter and search flags  */}
      {/* <Select
        className="flag-action-select"
        placeholder="Category"
        options={[
          {
            name: "Open",
            value: "Open",
          },
          {
            name: "Pending",
            value: "pending",
          },
          {
            name: "Close",
            value: "close",
          },
        ]}
      />
      <StandardInput
        placeholder={translate("pages").flags.activity.searchPlaceholder}
        className="flag-action-search"
        prefixIcon={<SearchOutlined />}
      /> */}
      <CheckboxGroup
        className="flag-action-layout"
        onChange={(value) => onChangeLayout(Object.keys(value)[0])}
        selected={{ [layout]: true }}
        options={[
          {
            key: LAYOUT_KANBAN,
            icon: KanbanIcon,
          },
          {
            key: LAYOUT_LIST,
            icon: ListViewIcon,
          },
        ]}
        multiSelect={false}
        defaultColor="#FFFFFF"
        // selectedColor="#141414"
        type={TYPE_ICONS}
      />
    </div>
  );
};

const Flags = (props) => {
  const [isModalOpen, openModal] = useState(false);
  const [layout, setLayout] = useState("kanban");
  const dispatch = useDispatch();

  const flags = useSelector((state) => state.flags.flags);
  const loadingError = useSelector((state) => state.flags.loadingError);
  const loading = useSelector((state) => state.flags.loading);

  const flagRejected = useSelector((state) => state.flags.flagRejected);
  const flagApproved = useSelector((state) => state.flags.flagApproved);
  const flagUpdated = useSelector((state) => state.flags.flagUpdated);
  const flagRejectFailed = useSelector((state) => state.flags.flagRejectFailed);

  const history = useHistory();

  useEffect(() => {
    if (flagRejected) {
      dispatch(getFlags());
    }
  }, [flagRejected]);

  useEffect(() => {
    if (flagUpdated) {
      dispatch(getFlags());
    }
  }, [flagUpdated]);

  useEffect(() => {
    // console.log("FlagApproved=>", flagApproved);
    if (flagApproved) {
      dispatch(getFlags());
    }
  }, [flagApproved]);

  useEffect(() => {
    // console.log("Flags: ", flags);
  }, [flags]);

  const onClickFlag = (item) => {
    history.push(`flags/details/${item.id}`);
  };

  useEffect(() => {
    dispatch(getFlagCategories());
    dispatch(getFlags());
    dispatch(getDepartments(getCurrentProjectId()));
  }, []);
  useEffect(() => {
    // console.log(flags, "flags fetched");
  }, [flags]);

  const hasNoFlags = () =>
    Object.values(flags).every(
      (flagsList) => !flagsList || flagsList.length === 0
    );

  let flagsContent;
  if (loading) {
    flagsContent = <SpiningPage opacity={1} />;
  } else if (loadingError) {
    flagsContent = <LoadErrorLayout onReload={() => dispatch(getFlags())} />;
  } else {
    flagsContent = hasNoFlags() ? (
      <Row className="flags-placeholder-row" justify="middle" align="center">
        <Col lg={12} md={18} sm={24}>
          <NoFlagPlaceholder />
        </Col>
      </Row>
    ) : layout === LAYOUT_LIST ? (
      <FlagsActivityListView
        onClick={onClickFlag}
        data={flags}
        openModal={() => openModal(true)}
      />
    ) : layout === LAYOUT_KANBAN ? (
      <FlagsActivityKanbanView
        onClick={onClickFlag}
        data={flags}
        openModal={() => openModal(true)}
      />
    ) : null;
  }

  return (
    <Suspense fallback={renderLoader()}>
      {loading && <SpiningPage opacity={1} />}
      <FlagsTemplate
        action={
          !hasNoFlags() && (
            <Actions onChangeLayout={setLayout} layout={layout} data={flags} />
          )
        }
      >
        {flagsContent}
      </FlagsTemplate>
      <FAB onClick={() => openModal(!isModalOpen)} icon={<PlusOutlined />} />
      <CreateFlagForm onClose={() => openModal(false)} show={isModalOpen} />
    </Suspense>
  );
};

export default Flags;
