import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import responsesAPI from 'api/responsesAPI';

const initialState = {
  answers:null,
  response: null,
  error: null,
  loading: false,
  loadingAction: false,
  questionsUpdated:false
};

const awsErrorSerialized = {
  error: 'ASW Error',
};

const awsError = (resp) => {
  console.log('response info', resp.data);
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};



export const postAnswers = createAsyncThunk(
  'responses/postAnswers',
  async (item, {rejectWithValue}) => {
    try {
      let answerData = { ...item };
      let id = answerData.id;
      delete answerData.id;
      console.log("answerData",answerData)
      console.log("id",id)
      const answers = await responsesAPI.postResponses(answerData, id);

      awsError(answers);
      return answers.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  },
);

export const updateProfileQuestions = createAsyncThunk(
  'responses/updateProfileQuestions',
  async (item, {rejectWithValue}) => {
    try {
      let answerData = { ...item };
      let id = answerData.id;
      delete answerData.id;
      console.log("answerData",answerData)
      console.log("id",id)
      const answers = await responsesAPI.updateProfileQuestions(answerData, id);

      awsError(answers);
      return answers.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  },
);


export const createResponses = createAsyncThunk(
  'responses/createResponses',
  async (item, {rejectWithValue}) => {
    try {
      let responsesData = { ...item };
      let id = responsesData.survey_id;
      console.log("dataSend",responsesData)
      console.log("id",id)
      const response = await responsesAPI.createResponses(responsesData, id);
        //console.log("response",response)
      awsError(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  },
);



const responses = createSlice({
  name: 'response',
  initialState,
  reducers: {},
  extraReducers: {
    //Get categories
    [createResponses.pending]: (state, action) => {
      console.info('createResponses pending');
      state.loading = true;
      state.error = null;
    },
    [createResponses.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;

      state.response = action.payload.body;
      console.info('createResponses fullfilled');
    },
    [createResponses.rejected]: (state, action) => {
      console.error('createResponses rejected =>', action);
      state.loading = false;
      state.error = action.payload;
      state.response = null;
    },


    

    [updateProfileQuestions.pending]: (state, action) => {
      console.info('updateProfileQuestions pending');
      state.loading = true;
      state.error = null;
      state.questionsUpdated=false;
    },
    [updateProfileQuestions.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.answers = action.payload.body;
      state.questionsUpdated=true;
      console.info('updateProfileQuestions fullfilled');
    },
    [updateProfileQuestions.rejected]: (state, action) => {
      console.error('updateProfileQuestions rejected =>', action);
      state.loading = false;
      state.error = action.payload;
      state.questionsUpdated=false;
      state.responses = [];
    },
    [postAnswers.pending]: (state, action) => {
      console.info('postResponses pending');
      state.loading = true;
      state.error = null;
    },
    [postAnswers.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.answers = action.payload.body;
      console.info('postResponses fullfilled');
    },
    [postAnswers.rejected]: (state, action) => {
      console.error('postResponses rejected =>', action);
      state.loading = false;
      state.error = action.payload;
      state.responses = [];
    }

  },
});

export default responses.reducer;
