import React, { useContext } from "react";
import PreviousPage from "components/atoms/previous-page";
import KnowledgeHeader from "components/molecules/KnowledgeHeader";
import KnowledgeCard from "components/molecules/KnowledgeBaseDetailCard";
import { I18nContext } from "utils/i18n/locale";
import { useLocation } from "react-router-dom";

const KnowledgeBaseDetail = () => {
  const { translate } = useContext(I18nContext);

  const location = useLocation();
  const previousTitle = location.pathname.split("/")[3].replace("-", " ");
  const previousLocation = location.pathname.split("/").splice(0, 4).join("/");

  return (
    <>
      <KnowledgeHeader title={previousTitle} />
      <PreviousPage
        title={translate("pages").knowledge.detail.backToPostList}
        link={previousLocation}
        className="previous-page"
      />
      <KnowledgeCard />
    </>
  );
};

export default KnowledgeBaseDetail;
