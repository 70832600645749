import api from "config/axios/axios.api.config";

const prefix = "responses";
const surveysPrefix = "surveys";

const postResponses = (data, id) => api.postJson(`${surveysPrefix}/${prefix}/${id}/answers`, data);
const createResponses = (data, id) => api.postJson(`${surveysPrefix}/${id}/responses`, data);
const updateProfileQuestions  = (data, id) => api.put(`${surveysPrefix}/${prefix}/${id}/answers`, data);

export default {
  postResponses,
  createResponses,
  updateProfileQuestions
};
