import { useContext, useEffect, useState } from "react";
import { SourceImage } from "components/atoms/media";
import singupIMG from "assets/img/signup_two.png";
import singupIMGTwo from "assets/img/signup_three.png";
import { PrimaryButton } from "components/atoms/controls";
import { Col, Row, Modal, Form } from "antd";
import { CreateElementInput } from "components/atoms/inputs";
import { FileDragger } from "components/atoms/media";
import { I18nContext } from "utils/i18n/locale";
import { openCreateProjectModal } from "redux/slices/projects";
import { useDispatch, useSelector } from "react-redux";
import { updateOrganization } from "redux/slices/profile";
import { unwrapResult } from "@reduxjs/toolkit";
import { uploadDrag } from "components/atoms/media";

const SignUpModal = ({ openModal }) => {
  const { translate } = useContext(I18nContext);
  const [files, setFiles] = useState();
  const profile = useSelector((state) => state.profile.profile);
  const loading = useSelector((state) => state.profile.loadingAction);
  const [currentUser, setCurrentUser] = useState(profile);

  const [step, setStep] = useState(1);

  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const getFileReference = (file) => {
    setFiles(file);
  };


  useEffect(() => {
    if (
      profile?.organizations?.length > 0 &&
      localStorage.getItem("reloaded")
    ) {
      setVisible(!profile.organizations[0].complete);
    }
    setCurrentUser(profile);
  }, [profile]);

  const onFinish = (values) => {
    let update_organization = { name: values?.organization };
    if (files) {
      uploadDrag("organizations", files, values?.organization)
        .then((data) => {
          update_organization.avatar = data.key;
          dispatch(updateOrganization(update_organization))
            .then(unwrapResult)
            .then((resp) => {
              setStep(2);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((err) => {
          console.error(err, "error al guardar la imagen");
        });
    } else {
      dispatch(updateOrganization(update_organization))
        .then(unwrapResult)
        .then((resp) => {
          setStep(2);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onFinishFailed = () => {
    console.error("there was an error");
  };
  return (
    <div className="signup-modal">
      <Modal
        centered
        zIndex={10000}
        visible={visible}
        // onOk={() => setVisible(false)}
        // onCancel={() => setVisible(false)}
        width={1000}
        bodyStyle={{
          backgroundColor: "rgba(0,0,0, .5)",
          border: "none",
        }}
        footer={null}
        closable={false}
      >
        <div>
          <div
            style={{
              backgroundColor: "white",
              position: "relative",
              width: "60%",
              height: "450px",
              float: "left",
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              marginTop: -25,
              marginLeft: -25,
              opacity: 1,
            }}
            className="left-modal"
          >
            {step === 1 ? (
              <>
                <Row align="middle" gutter={[12, 0]} style={{ marginTop: 30 }}>
                  <Col offset={2}>
                    <h1 className="signup-title">
                      {
                        translate("pages").signup.modals.customizeOrganization
                          .title
                      }
                    </h1>
                  </Col>
                </Row>
                <Form
                  name="organization-form"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[12, 0]} justify="flex-start">
                    <Col
                      flex="auto"
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      offset={2}
                    >
                      {/* <Col flex="auto" xs={24} md={12} lg={12} xl={12} xxl={12}> */}
                      <Form.Item
                        name="organization"
                        rules={[
                          {
                            // required: true,
                          },
                        ]}
                      >
                        <CreateElementInput
                          placeholder={
                            translate("pages").signup.modals
                              .customizeOrganization.placeholders.orgName
                          }
                          label={
                            translate("pages").signup.modals
                              .customizeOrganization.inputs.orgName
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[12, 40]} justify="flex-start">
                    <Col
                      flex="auto"
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      xxl={12}
                      offset={2}
                    >
                      <FileDragger
                        isCustom
                        // currentFile={preData}
                        label={
                          translate("pages").signup.modals.customizeOrganization
                            .inputs.orgAvatar
                        }
                        getFileReference={getFileReference}
                      />
                    </Col>
                  </Row>
                  <Row justify="center" align="bottom" justify="flex-start">
                    <Col offset={2}>
                      <Form.Item>
                        <PrimaryButton
                          htmlType="submit"
                          cssClassName="primary-button verify-button signup-button"
                          title={
                            translate("pages").signup.modals
                              .customizeOrganization.nextButton
                          }
                          loading={loading}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <Row align="middle" gutter={[12, 40]}>
                  <Col offset={2}>
                    <span className="resend" onClick={() => setStep(2)}>
                      {
                        translate("pages").signup.modals.customizeOrganization
                          .skipButton
                      }
                    </span>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                {" "}
                <Row align="middle" gutter={[12, 0]} style={{ marginTop: 130 }}>
                  <Col offset={2}>
                    <h1 className="signup-title">
                      {translate("pages").signup.modals.createProject.title}
                      {currentUser?.name ??
                        translate("pages").signup.modals.createProject.noUser}
                      .
                    </h1>
                  </Col>
                </Row>
                <Row align="middle" gutter={[12, 40]}>
                  <Col offset={2}>
                    <h3>
                      {translate("pages").signup.modals.createProject.subtitle}
                    </h3>
                  </Col>
                </Row>
                <Row
                  justify="center"
                  align="bottom"
                  justify="flex-start"
                  gutter={[12, 40]}
                >
                  <Col offset={2}>
                    <PrimaryButton
                      //   htmlType="submit"
                      onClick={() => {
                        setVisible(false);
                        dispatch(openCreateProjectModal());
                      }}
                      cssClassName="primary-button verify-button signup-button"
                      title={
                        translate("pages").signup.modals.createProject
                          .createProjectButton
                      }
                      // loading={loading}
                    />
                  </Col>
                </Row>
                <Row align="middle" gutter={[12, 40]}>
                  <Col offset={2}>
                    <span
                      className="resend"
                      onClick={() => {
                        setVisible(false);
                        setStep(1);
                      }}
                    >
                      {
                        translate("pages").signup.modals.createProject
                          .skipButton
                      }
                    </span>
                  </Col>
                </Row>
              </>
            )}
          </div>
          <div
            style={{
              backgroundColor: "#75E4B9",
              position: "relative",
              width: "40%",
              height: "450px",
              float: "left",
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              marginTop: -25,
            }}
          >
            <SourceImage
              src={step == 1 ? singupIMG : singupIMGTwo}
              preview={false}
              className="signup-illustration"
              style={{
                position: "relative",
                width: "500px",
                zIndex: 1000000,
                // height: "200px",
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SignUpModal;
