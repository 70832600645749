import api from "config/axios/axios.api.config";

const prefix = "citizens";

const saveCitizen = (data) => api.postJson(`${prefix}`, data);

const saveCitizenProject = (id, data) =>
  api.postJson(`${prefix}/projects/${id}/launchsa`, data);

const readCitizen = (id) => api.get(`${prefix}/${id}`, id);

const updateCitizen = (id) => api.put(`${prefix}/${id}`, id);

const getAllCitizen = () => api.get(`${prefix}`);

const readCitizenProfile = () => api.get(`${prefix}/profile`);

const readCitizenProjects = () => api.get(`${prefix}/projects`);

const updateCitizenProfile = (data) => api.put(`${prefix}/profile`, data);

const getProjectCitizen = (id) => api.get(`${prefix}/projects/${id}`);

const enableCitizen = (id) => api.put(`${prefix}/${id}/enable`);

const disableCitizen = (id) => api.put(`${prefix}/${id}/disable`);

const deleteCitizen = (id) => api.put(`${prefix}/${id}/delete`);

export default {
  saveCitizen,
  readCitizen,
  updateCitizen,
  getAllCitizen,
  readCitizenProfile,
  updateCitizenProfile,
  readCitizenProjects,
  getProjectCitizen,
  enableCitizen,
  disableCitizen,
  deleteCitizen,
  saveCitizenProject,
};
