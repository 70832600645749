import FlagsByDepartment from "components/organisms/Analytics/FlagsByDepartment";
import FlagsStatus from "components/organisms/Analytics/FlagsStatus";
import TaskPriority from "components/organisms/Analytics/TaskPriority";
import HeatMap from "components/organisms/Analytics/HeatMap";
import SurveyResults from "components/organisms/Analytics/SurveyResults";
import SubmissionsByCategory from "components/organisms/Analytics/SubmissionsByCategory";
import InputByCategory from "components/organisms/Analytics/InputByCategory";
import InputBySubcategory from "components/organisms/Analytics/InputBySubcategory";
import ApprovedOrRejected from "components/organisms/Analytics/ApprovedOrRejected";
import OnTimeOrLate from "components/organisms/Analytics/OnTimeOrLate";
import OpenOrExpired from "components/organisms/Analytics/OpenOrExpired";
import FlagLifecycle from "components/organisms/Analytics/FlagLifecycle";
import TaskCompletedOnTime from "components/organisms/Analytics/TaskCompletedOnTime";
import AverageDaysToApproveAndCompleteFlag from "components/organisms/Analytics/AverageDaysToApproveAndCompleteFlag";
import CompletionTimeCharts from "components/organisms/Analytics/CompletionTimeCharts";
import FlagApprovalScatterPlot from "components/organisms/Analytics/FlagApprovalScatterPlot";
import FlagStatusLineChart from "components/organisms/Analytics/FlagStatusLineChart";
import CreatedFlags from "components/organisms/Analytics/CreatedFlags";
import RankByZipcode from "components/organisms/Analytics/RankByZipcode";

export const TYPES = {
	FLAGS_BY_DEPARTMENT: "FLAGS_BY_DEPARTMENT",
	TASKS_PRIORITY: "TASKS_PRIORITY",
	FLAGS_STATUS: "FLAGS_STATUS",
	HEAT_MAP: "HEAT_MAP",
	SURVEY_RESULTS: "SURVEY_RESULTS",
	SUBMISSIONS_BY_CATEGORY: "SUBMISSIONS_BY_CATEGORY",
	SUBMISSIONS_BY_ZIPCODE: "SUBMISSIONS_BY_ZIPCODE",
	INPUT_BY_CATEGORY: "INPUT_BY_CATEGORY",
	INPUT_BY_SUBCATEGORY: "INPUT_BY_SUBCATEGORY",
	INPUT_BY_SUBCATEGORY2: "INPUT_BY_SUBCATEGORY2",
	APPROVED_OR_REJECTED: "APPROVED_OR_REJECTED",
	ON_TIME_OR_LATE: "ON_TIME_OR_LATE",
	OPEN_OR_EXPIRED: "OPEN_OR_EXPIRED",
	FLAG_LIFECYCLE: "FLAG_LIFECYCLE",
	TASKS_COMPLETED_ON_TIME: "TASKS_COMPLETED_ON_TIME",
	VELOCITY_OF_COMPLETION: "VELOCITY_OF_COMPLETION",
	COMPLETION_TIME_CHARTS: "COMPLETION_TIME_CHARTS",
	SCATTER_PLOT_OF_FLAG_APPROVAL: "SCATTER_PLOT_OF_FLAG_APPROVAL",
	FLAG_STATUS_LINE: "FLAG_STATUS_LINE",
	CREATED_FLAGS: "CREATED_FLAGS",
}

export const CHARTS = {
	FLAGS_BY_DEPARTMENT: {
		component: FlagsByDepartment,
		width: 6,
		height: 5,
		minH: 3,
		minW: 3,
	},
	FLAGS_STATUS: {
		component: FlagsStatus,
		width: 12,
		height: 2,
		minH: 2,
		maxH: 2,
		minW: 6,
	},
	TASKS_PRIORITY: {
		component: TaskPriority,
		width: 6,
		height: 5,
		minH: 3,
		minW: 3,
	},
	HEAT_MAP: {
		component: HeatMap,
		width: 12,
		height: 6,
		minH: 3,
		minW: 4,
	},
	SURVEY_RESULTS: {
		component: SurveyResults,
		width: 12,
		height: 2,
		minH: 2,
		maxH: 2,
		minW: 12,
	},
	SUBMISSIONS_BY_CATEGORY: {
		component: SubmissionsByCategory,
		width: 6,
		height: 5,
		minH: 3,
		minW: 4,
	},
	INPUT_BY_CATEGORY: {
		component: InputByCategory,
		width: 6,
		height: 5,
		minH: 3,
		minW: 4,
	},
	INPUT_BY_SUBCATEGORY: {
		component: InputBySubcategory,
		width: 6,
		height: 5,
		minH: 3,
		minW: 4,
	},
	APPROVED_OR_REJECTED: {
		component: ApprovedOrRejected,
		width: 6,
		height: 5,
		minH: 3,
		minW: 4,
	},
	ON_TIME_OR_LATE: {
		component: OnTimeOrLate,
		width: 6,
		height: 5,
		minH: 3,
		minW: 4,
	},
	OPEN_OR_EXPIRED: {
		component: OpenOrExpired,
		width: 6,
		height: 5,
		minH: 3,
		minW: 4,
	},
	FLAG_LIFECYCLE: {
		component: FlagLifecycle,
		width: 6,
		height: 5,
		minH: 3,
		minW: 4,
	},
	TASKS_COMPLETED_ON_TIME: {
		component: TaskCompletedOnTime,
		width: 12,
		height: 7,
		minH: 5,
		minW: 4,
	},
	VELOCITY_OF_COMPLETION: {
		component: AverageDaysToApproveAndCompleteFlag,
		width: 12,
		height: 8,
		minH: 3,
		minW: 6,
	},
	COMPLETION_TIME_CHARTS: {
		component: CompletionTimeCharts,
		width: 12,
		height: 8,
		minH: 7,
		minW: 4,
	},
	SCATTER_PLOT_OF_FLAG_APPROVAL: {
		component: FlagApprovalScatterPlot,
		width: 12,
		height: 8,
		minH: 6,
		minW: 4,
	},
	FLAG_STATUS_LINE: {
		component: FlagStatusLineChart,
		width: 6,
		height: 5,
		minH: 4,
		minW: 4,
	},
	CREATED_FLAGS: {
		component: CreatedFlags,
		width: 6,
		height: 5,
		minH: 3,
		minW: 4,
	},
	SUBMISSIONS_BY_ZIPCODE: {
		component: RankByZipcode,
		width: 6,
		height: 5,
		minH: 3,
		minW: 4,
	}
}
