import React, {useContext} from "react";
import "./NoFlagPlaceholder.scss";
import {I18nContext} from "utils/i18n/locale";
import emptyCommentsSvg from "assets/img/no-flag-placeholder.svg";
import {Title} from "components/atoms/texts";
import {PrimaryButton} from "components/atoms/controls";

const NoFlagPlaceholder = (props) => {
  const { translate } = useContext(I18nContext);
  return (
    <div
      {...props}
      className='no-flags-placeholder-container'
    >
      <img className='placeholder-image' src={emptyCommentsSvg} alt={translate("pages").flags.placeholder.noFlags}/>
      <Title level={3} text={translate("pages").flags.placeholder.noFlags}/>
      <div className='no-flag-desc'>{translate("pages").flags.placeholder.noFlagsMessage}</div>
      <PrimaryButton cssClassName='create-flag-btn' title={translate("pages").flags.activity.createFlag}/>
    </div>
  );
};

export default NoFlagPlaceholder;
