import { Menu as MenuAnt } from "antd";
import React from "react";
import "./menu.scss";

const Menu = ({ options, onClick, ...props }) => {
  return (
    <MenuAnt className="ant-menu-custom" {...props}>
      {options.map(({ icon, className = "", ...option }) => (
        <MenuAnt.Item
          key={option.key}
          icon={icon}
          onClick={(e) => {
            e.domEvent.stopPropagation();
            onClick(option);
          }}
          className={`ant-menu-custom-item ${className}`}
          style={{ color: option.color ? option.color : null }}
        >
          <div className="option-title">
            {option.title}
          </div>
        </MenuAnt.Item>
      ))}
    </MenuAnt>
  );
};

export default Menu;
