import React, { useEffect, useRef, useState } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  AtomicBlockUtils,
  CompositeDecorator,
  convertFromRaw,
  KeyBindingUtil,
  Modifier,
  convertToRaw,
} from "draft-js";
import "./DraftRichText.scss";
import "draft-js/dist/Draft.css";
import {
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  HorizontalLineSpacingIcon,
  VerticalLineSpacingIcon,
  LinkIcon,
  EditorAttachImageIcon,
  FillIcon,
  LeftAlignIcon,
  CenterAlignIcon,
  RightAlignIcon,
  JustifyCenterIcon,
  JustifyIcon,
  JustifyLeftIcon,
} from "components/atoms/icons";
import { Dropdown } from "components/atoms/controls";
import Menu from "components/atoms/menu";
import { Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { handleKnowledgePostData } from "redux/slices/knowledge";
import { stateToHTML } from "draft-js-export-html";
import Interweave from "interweave";
import parse from "html-react-parser";

const ToolbarDropdown = ({ overlay, children }) => {
  const [toolbarDropdown, setToolbarDropdown] = useState(false);
  return (
    <div className="toolbar-dropdown-container">
      <div
        onMouseLeave={() => setToolbarDropdown(false)}
        onMouseOver={() => setToolbarDropdown(true)}
        className="toolbar-dropdown-children"
      >
        {children}
      </div>
      <div
        onMouseOver={() => setToolbarDropdown(true)}
        onMouseLeave={() => setToolbarDropdown(false)}
        className={`toolbar-dropdown-content ${
          toolbarDropdown ? "toolbar-dropdown-container-active" : ""
        }`}
      >
        {overlay}
      </div>
    </div>
  );
};

const RenderImage = (props) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src } = entity.getData();
  return <img src={src} className="draftImg" />;
};

const EditorToolbar = ({
  editorState,
  onToggleInline,
  onToggleBlock,
  setTextAlign,
  setTextAlignLast,
  currentAlign,
  urlValue,
  setUrlValue,
  addImage,
  setLink,
  imageUrl,
  setImageUrl,
}) => {
  const horizontalOptions = [
    { title: "0px", key: "horizontal-spacing-0" },
    { title: "1px", key: "horizontal-spacing-1" },
    { title: "2px", key: "horizontal-spacing-2" },
    { title: "3px", key: "horizontal-spacing-3" },
    { title: "4px", key: "horizontal-spacing-4" },
  ];

  const colorOptions = [
    {
      title: <div className="color-option black"></div>,
      key: "black",
      isColor: true,
    },
    {
      title: <div className="color-option red"></div>,
      key: "red",
      isColor: true,
    },
    {
      title: <div className="color-option blue"></div>,
      key: "blue",
      isColor: true,
    },
    {
      title: <div className="color-option green"></div>,
      key: "green",
      isColor: true,
    },
    {
      title: <div className="color-option yellow"></div>,
      key: "yellow",
      isColor: true,
    },
  ];

  const verticalOptions = [
    { title: "h1", key: "header-one" },
    { title: "h2", key: "header-two" },
    { title: "h3", key: "header-three" },
    { title: "h4", key: "header-four" },
    { title: "h5", key: "header-five" },
    { title: "h6", key: "header-six" },
    { title: "paragraph", key: "unstyled" },
  ];

  const TOOLBAR_BUTTONS = [
    { icon: <BoldIcon color="#141414" />, style: "BOLD", type: "inline" },
    { icon: <ItalicIcon color="#141414" />, style: "ITALIC", type: "inline" },
    {
      icon: <UnderlineIcon color="#141414" />,
      style: "UNDERLINE",
      type: "inline",
    },
    // {
    //   icon: <HorizontalLineSpacingIcon color="#141414" />,
    //   type: "block",
    //   style: "horizontal-spacing",
    //   options: horizontalOptions,
    // },
    {
      icon: <VerticalLineSpacingIcon color="#141414" />,
      type: "block",
      style: "vertical-spacing",
      options: verticalOptions,
    },
    {
      icon: <LinkIcon color="#141414" />,
      style: "link",
      type: "inline",
      customDropdown: true,
    },
    {
      icon: <EditorAttachImageIcon color="#141414" />,
      type: "block",
      style: "image",
      customDropdown: true,
    },
    {
      icon: <FillIcon color="#141414" />,
      type: "inline",
      style: "COLOR",
      options: colorOptions,
    },
    { icon: <LeftAlignIcon color="#141414" />, type: "align", align: "left" },
    {
      icon: <CenterAlignIcon color="#141414" />,
      type: "align",
      align: "center",
    },
    { icon: <RightAlignIcon color="#141414" />, type: "align", align: "right" },
    {
      icon: <JustifyLeftIcon color="#141414" />,
      type: "align",
      align: "left",
      extra: "justify-left",
    },
    {
      icon: <JustifyCenterIcon color="#141414" />,
      type: "align",
      align: "center",
      extra: "justify-center",
    },
    {
      icon: <JustifyIcon color="#141414" />,
      type: "align",
      align: "justify",
      extra: "justify",
    },
  ];

  const currentStyle = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const onClick = (style, type, align, isColor) => {
    if (type === "inline") {
      onToggleInline(style, isColor);
    } else if (type === "align") {
      setTextAlign(align);
    } else {
      onToggleBlock(style);
    }
  };

  const onMouseDown = (e = null, item) => {
    if (e) {
      e.preventDefault();
    }
    if (item.style !== "COLOR") {
      onClick(item.style, item.type, item.align);
    }
  };

  const onSetSelection = (option, type, isColor) => {
    //aquí 2
    onClick(option.key, type, null, isColor);
  };

  const addImageUrl = (e) => {
    e.preventDefault();
    addImage();
  };

  const submitUrl = (e) => {
    e.preventDefault();
    setLink();
  };

  return (
    <div className="editor-toolbar">
      {TOOLBAR_BUTTONS.map((item, i) => {
        let isActive = false;
        if (item.type === "align") {
          isActive = Boolean(currentAlign === item.align);
        } else {
          isActive =
            currentStyle.has(item.style) ||
            blockType === item.type ||
            blockType === item.style;
        }
        let toolbarButtons;
        if (
          item.style !== "COLOR" &&
          item.style !== "horizontal-spacing" &&
          item.style !== "vertical-spacing" &&
          item.style !== "image" &&
          item.style !== "link"
        ) {
          toolbarButtons = (
            <span
              className={isActive ? "editor-button-active" : "editor-button"}
              onMouseDown={(e) => onMouseDown(e, item)}
              key={i}
            >
              {item.icon}
            </span>
          );
        } else if (item.customDropdown) {
          toolbarButtons = (
            <ToolbarDropdown
              key={i}
              overlay={
                <div key={i}>
                  {item.style === "link" ? (
                    <form className="add-url-form" onSubmit={submitUrl}>
                      <p>Add Link Below</p>
                      <input
                        required
                        value={urlValue}
                        onChange={(e) => setUrlValue(e.target.value)}
                        placeholder="insert url"
                      />
                    </form>
                  ) : (
                    <form className="add-url-form" onSubmit={addImageUrl}>
                      <p>Add Image url</p>
                      <input
                        value={imageUrl}
                        onChange={(e) => setImageUrl(e.target.value)}
                        required
                        placeholder="insert image url"
                      />
                    </form>
                  )}
                </div>
              }
            >
              {item.icon}
            </ToolbarDropdown>
          );
        } else {
          //Estilos generales aquí
          toolbarButtons = (
            <Dropdown
              key={i}
              menu={
                <Menu
                  onClick={(option) => {
                    if (option.isInput) {
                    } else {
                      //aqui
                      onSetSelection(option, item.type, option.isColor);
                    }
                  }}
                  className="toolbar-dropdown"
                  options={item.options}
                />
              }
            >
              <span className={isActive ? "editor-button-active" : ""} key={i}>
                {item.icon}
              </span>
            </Dropdown>
          );
        }
        return toolbarButtons;
      })}
    </div>
  );
};

const UrlLink = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} className="editor-link">
      {props.children}
    </a>
  );
};

const RichEditorExample = (props) => {
  const newPostData = useSelector((state) => state.knowledge.newPost);

  const dispatch = useDispatch();
  // useEffect(() => {
  //   // console.log (newPostData);
  // }, [newPostData]);

  const createWithRawContent = () => {
    const contentState = convertFromRaw(newPostData?.content);
    const newEditorState = EditorState.createWithContent(contentState);
    return newEditorState;
  };

  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  };

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: UrlLink,
    },
  ]);

  const [editorState, setEditorState] = useState(
    EditorState.set(createWithRawContent(), { decorator })
  );
  const [htmlState, setHtmlState] = useState();
  const [textAlign, setTextAlign] = useState();
  const [textAlignLast, setTextAlignLast] = useState();
  const [urlValue, setUrlValue] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [history, setHistory] = useState([]);

  useEffect(() => {
    dispatch(handleKnowledgePostData({ content_html: htmlState }));
  }, [htmlState]);

  // console.log ({ htmlState });

  const editorRef = useRef();
  const onChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    let options = {
      inlineStyles: {
        // Override default element (`strong`).
        // BOLD: { element: "b" },
        // ITALIC: {
        //   // Add custom attributes. You can also use React-style `className`.
        //   attributes: { class: "foo" },
        //   // Use camel-case. Units (`px`) will be added where necessary.
        //   // style: { fontSize: 12 },
        // },
        // Use a custom inline style. Default element is `span`.
        red: { style: { color: "red" } },
        green: { style: { color: "green" } },
        yellow: { style: { color: "yellow" } },
        blue: { style: { color: "blue" } },
        black: { style: { color: "black" } },
      },
      // blockRenderers: {
      //   atomic: (block) => {
      //     // console.log ({ block });
      //     let data = block.getData();
      //     // console.log ({ data });
      //     if (data.get("type") === "header-one") {
      //       return {
      //         style: {
      //           fontSize: 240,
      //         },
      //       };
      //     }
      //   },
      // },
      entityStyleFn: (entity) => {
        // console.log ({ entity });
        const entityType = entity.get("type").toLowerCase();
        if (entityType === "img") {
          const data = entity.getData();
          return {
            element: "img",
            attributes: {
              src: data.src,
            },
            style: {
              width: "550px",
              heigth: "100%",
              // Put styles here...
            },
          };
        }
      },
    };

    const injectHTML = (html, align = "left", justify) => {
      return `
      <body>
      <div style="background:#fff;text-align:${
        justify ? "justify" : align
      };text-align-last:${
        justify
          ? align === "justify-center"
            ? "justify"
            : align === "justify-left"
            ? "left"
            : align === "justify-right"
            ? "right"
            : ""
          : ""
      }">
    ${html}
    </div>
    
     
      
      
      
      </body>
     `;
    };

    let html = stateToHTML(contentState, options);

    // console.log ({ injected: injectHTML(html) });

    setHtmlState(injectHTML(html, textAlign, textAlignLast));
    const rawJson = convertToRaw(contentState);
    // console.log ({ rawJson });
    const jsonStr = JSON.stringify(rawJson, null, 1);

    const description = rawJson?.blocks[0]?.text;
    dispatch(handleKnowledgePostData({ content: rawJson, description }));
    // // console.log (rawJson, "rawjson");
    //TODO: here we need to sabe status
    // // console.log (jsonStr);
  };

  const trackKeyHistory = (e) => {
    let newHistory = [...history];
    const { key } = e;

    if (newHistory.length > 2) {
      newHistory = newHistory.slice(1);
    }

    if (!KeyBindingUtil.hasCommandModifier(e)) {
      newHistory.push(key);
      setHistory(newHistory);
    }
    return newHistory;
  };

  const listTypeMap = {
    "start-unordered-list": "unordered-list-item",
    "start-ordered-list": "ordered-list-item",
  };

  const startList = (editorState, block, command) => {
    const listType = listTypeMap[command];
    const newEditorState = RichUtils.toggleBlockType(editorState, listType);
    const contentState = newEditorState.getCurrentContent();
    const selection = newEditorState.getSelection();
    const blockSelection = selection.merge({
      anchorOffset: 0,
      focusOffset: block.getLength(),
    });

    const newContentState = Modifier.replaceText(
      contentState,
      blockSelection,
      ""
    );

    return EditorState.push(newEditorState, newContentState);
  };

  const getSelectedBlock = (editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockStartKey = selection.getStartKey();

    return contentState.getBlockMap().get(blockStartKey);
  };

  const shouldStartList = (block) => {
    return (
      block.getType() === "unstyled" &&
      block.getDepth() === 0 &&
      (block.getText() === "*" ||
        block.getText() === "1." ||
        block.getText() === "-")
    );
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    if (
      command === "start-unordered-list" ||
      command === "start-ordered-list"
    ) {
      const block = getSelectedBlock(editorState);

      if (shouldStartList(block)) {
        const newEditorState = startList(editorState, block, command);

        onChange(newEditorState);
        return "handled";
      }
    }
    return false;
  };

  const mapKeyToEditorCommand = (e) => {
    const charHistory = trackKeyHistory(e);

    if (e.keyCode === 32) {
      const previousChar = charHistory[charHistory.length - 2];

      if (previousChar === "*" || previousChar === "-") {
        return "start-unordered-list";
      }

      const previousInput = charHistory.slice(0, 2).join("");
      if (previousInput === "1.") {
        return "start-ordered-list";
      }
    }

    if (e.keyCode === 9) {
      const newEditorState = RichUtils.onTab(e, editorState, 4);
      if (newEditorState !== editorState) {
        onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = async (blockType) => {
    // console.log ("Aqui");
    await onChange(RichUtils.toggleBlockType(editorState, blockType));
    await editorRef.current.focus();
  };

  useEffect(() => {
    toggleInlineStyle({ align: textAlign });
  }, [textAlign]);
  useEffect(() => {
    toggleInlineStyle({ align: textAlignLast });
  }, [textAlignLast]);

  // TODO Para cambiar estilos al html
  const toggleInlineStyle = async (inlineStyle, isColor) => {
    let contentState = editorState.getCurrentContent();
    const styles = editorState.getCurrentInlineStyle();
    const selection = editorState.getSelection();

    const style_colors = {
      red: "red",
      green: "green",
      yellow: "yellow",
      black: "black",
      blue: "blue",
    };

    const text_styles = {
      BOLD: "bold",
      ITALIC: "italic",
      UNDERLINE: "underline",
    };

    if (isColor) {
      Object.keys(style_colors).forEach((style) => {
        if (styles.has(style)) {
          contentState = Modifier.removeInlineStyle(
            contentState,
            selection,
            style
          );
        }
      });

      contentState = Modifier.applyInlineStyle(
        contentState,
        selection,
        inlineStyle
      );
      await onChange(
        EditorState.push(editorState, contentState, "change-inline-style")
      );
    } else {
      await onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    }

    // this.onChange(

    // );
    // // console.log ({ contentState, styles, selection });

    // const newContent = auxState.mergeEntityData("color", {
    //   color: "green",
    // });
    // await onChange(newContent);

    // if (isColor) {
    //   await onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    // } else {
    //   await onChange(
    //     EditorState.push(editorState, contentState, "change-inline-style")
    //   );
    // }
    await editorRef.current.focus();
  };

  // TODO: inline this
  const getBlockStyle = (block) => {
    // console.log (block.getType(), "Block Block ");
    switch (block.getType()) {
      case "blockquote":
        return "RichEditor-blockquote";
      case "header-one":
        return "header-one";
      case "horizontal-spacing-0":
        return "horizontal-spacing-0";
      case "horizontal-spacing-1":
        return "horizontal-spacing-1";
      case "horizontal-spacing-2":
        return "horizontal-spacing-2";
      case "horizontal-spacing-3":
        return "horizontal-spacing-3";
      case "horizontal-spacing-4":
        return "horizontal-spacing-4";
      default:
        return null;
    }
  };

  const setSelection = () => {
    const selectionState = editorState.getSelection();
    const offset = selectionState.getAnchorOffset();
    const focusOffset = selectionState.getFocusOffset();

    const newSelection = selectionState.merge({
      anchorOffset: offset,
      focusOffset: focusOffset,
    });

    const newEditorState = EditorState.forceSelection(
      editorState,
      newSelection
    );
    setEditorState(newEditorState);
  };

  const insertImage = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "img",
      "IMMUTABLE",
      { src: imageUrl }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    const newEditorStateWithBlock = AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      " "
    );
    setImageUrl("");
    onChange(newEditorStateWithBlock);
  };

  const blockRendererFn = (contentBlock) => {
    if (contentBlock.getType() === "atomic") {
      return {
        component: RenderImage,
        editable: false,
      };
    }
    return null;
  };

  const confirmLink = async () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    await onChange(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );
    await editorRef.current.focus();
  };

  let className = "RichEditor-editor";
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== "unstyled") {
      className += " RichEditor-hidePlaceholder";
    }
  }

  return (
    <>
      {/* {htmlState && parse(htmlState)} */}
      {/* <Interweave
        content={htmlState}
        disableFilters={true}
        allowAttributes={true}
        allowElements={true}
      /> */}

      <div className={!props.readOnly ? "editing" : ""}>
        {!props.readOnly ? (
          <>
            <Divider className="upper-divider" />
            <EditorToolbar
              editorState={editorState}
              onToggleInline={toggleInlineStyle}
              onToggleBlock={toggleBlockType}
              setTextAlign={(align) => setTextAlign(align)}
              setTextAlignLast={(align) => setTextAlignLast(align)}
              currentAlign={textAlign}
              setSelection={setSelection}
              urlValue={urlValue}
              setUrlValue={(value) => setUrlValue(value)}
              imageUrl={imageUrl}
              setImageUrl={(value) => setImageUrl(value)}
              addImage={insertImage}
              setLink={confirmLink}
            />
          </>
        ) : null}
        <div
          className={`${
            props.readOnly ? "editor" : className
          } justify-styles-${textAlign} editor`}
        >
          <Editor
            blockStyleFn={getBlockStyle}
            blockRendererFn={blockRendererFn}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={onChange}
            placeholder="Start Typing..."
            ref={editorRef}
            readOnly={props.readOnly}
            spellCheck={true}
          />
        </div>
      </div>
    </>
  );
};

const styleMap = {
  red: {
    color: "red",
  },
  black: {
    color: "black",
  },
  blue: {
    color: "blue",
  },
  green: {
    color: "green",
  },
  yellow: {
    color: "yellow",
  },
};

export default RichEditorExample;
