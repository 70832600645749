import React, { useContext, useState, useEffect } from "react";
import { Col, Layout, Row } from "antd";
import { Title } from "components/atoms/texts";
import EmptySurveyCard from "components/molecules/EmptySurveyCard";
import SurveyHeader from "components/molecules/SurveyHeader";
import SurveySidebar from "components/organisms/SurveySidebar";
import SurveyCardTemplate from "components/templates/SurveyCardTemplate";
import SurveyListTemplate from "components/templates/SurveyListTemplate";
import { I18nContext } from "utils/i18n/locale";
import DataTable from "components/organisms/DataTable";
import "./surveys.scss";
import { useSelector, useDispatch } from "react-redux";
import { getSurveys } from "redux/slices/surveys";
import { SpiningPage } from "components/atoms/icons";
import { useHistory } from "react-router-dom";

export const LAYOUT_GRID = "grid";
export const LAYOUT_LIST = "list";

// TODO: Add missing functions to menu
export const options = [
  // {
  //   key: "View Details",
  //   isSubMenu: false,
  // },
  // {
  //   key: "General Results",
  //   isSubMenu: false,
  // },
  // {
  //   key: "Rename",
  //   isSubMenu: false,
  // },
  {
    key: "Duplicate",
    isSubMenu: false,
  },
  // {
  //   key: "Copy",
  //   isSubMenu: true,
  //   subMenu: [
  //     {
  //       key: "Copy Up",
  //     },
  //     {
  //       key: "Copy Down",
  //     },
  //   ],
  // },
  // {
  //   key: "Move",
  //   isSubMenu: true,
  //   subMenu: [
  //     {
  //       key: "Move Up",
  //     },
  //     {
  //       key: "Move Down",
  //     },
  //   ],
  // },
  // {
  //   key: "Share",
  //   isSubMenu: false,
  // },
  {
    key: "Delete",
    isSubMenu: false,
    isDelete: true,
  },
];

const Surveys = (props) => {
  const {
    surveys: { surveys, surveyFetching },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [layout, setLayout] = useState("list");
  const history = useHistory();

  const profileLoaded = useSelector((state) => state.profile.profileLoaded);

  const [isReady, setIsReady] = useState(profileLoaded);

  useEffect(() => {
    setIsReady(profileLoaded);
  }, [profileLoaded]);

  useEffect(() => {
    dispatch(getSurveys());
  }, []);

  useEffect(() => {
    if (isReady) {
      dispatch(getSurveys());
    }
  }, [isReady]);

  const { Sider } = Layout;
  const { translate } = useContext(I18nContext);

  const collapsePanels = [
    {
      name: translate("pages").survey.collapse.deleted,
      groups: ["group 1", "group 2", "group 3"],
    },
  ];

  return (
    <>
      {surveyFetching ? (
        <SpiningPage opacity={1} />
      ) : (
        <>
          <SurveyHeader
            {...props}
            history={history}
            title={translate("pages").survey.title.surveys}
            withActions={surveys.length > 0 ? true : false}
            layout={layout}
            onChangeLayout={setLayout}
          />
          {surveys.length < 1 ? (
            <Row
              className="flags-placeholder-row"
              justify="middle"
              align="center"
            >
              <Col lg={12} md={18} sm={24}>
                <EmptySurveyCard />
              </Col>
            </Row>
          ) : (
            <Layout className="survey-container">
              {/* TODO:Add functions to sidebar */}
              {/* <Sider
                collapsible
                theme="light"
                breakpoint="md"
                collapsedWidth="0"
                className="survey-sider"
              >
                <SurveySidebar
                  collapsePanels={collapsePanels}
                  surveyCount={surveys.length}
                />
              </Sider> */}
              <Layout className="survey-card-container">
                <Title
                  text={translate("pages").survey.title.allSurvey}
                  className="survey-title"
                />
                {layout === "list" ? (
                  <SurveyListTemplate surveyItems={surveys} />
                ) : layout === "grid" ? (
                  <SurveyCardTemplate surveyItems={surveys} />
                ) : null}
              </Layout>
            </Layout>
          )}
        </>
      )}
    </>
  );
};

export default Surveys;
