
import React from "react";
import Menu from "components/atoms/menu";
import "./DepartmentListDropDown.scss";
import { MenuThreeDotsIcon } from "components/atoms/icons"; 
import { Dropdown } from 'antd';

export default ({
  options = [],
  onClick,
}) => {
  const menu = <Menu options={options} onClick={onClick} />;
  return (
    <Dropdown
      overlay={menu}
      trigger="click"
    >
      <div className="department-action-icon">
        <MenuThreeDotsIcon />
      </div>
    </Dropdown>
  );
};