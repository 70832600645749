import React, { useContext, Suspense } from "react";
import { SpiningPage } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import "./Details.scss";
import { Col, Divider, Row } from "antd";
import SubmissionUserInfoForm from "components/organisms/SubmissionUserInfoForm";
import SubmissionDetailsInfo from "components/organisms/SubmissionDetailsInfo";
import { PrimaryButton, SECONDARY_COLOR } from "components/atoms/controls";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { Text } from "components/atoms/texts";

const Actions = withRouter(({ openReject, openApprove, history }) => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="actions-container">
      <div className="back-action" onClick={() => history.goBack()}>
        <ArrowLeftOutlined />
        <Text
          className="caption"
          text={translate("pages").communication.submission.details.backText}
        >
          {translate("pages").communication.submission.details.backText}
        </Text>
      </div>
      <Row className="buttons-container">
        <PrimaryButton
          onClick={openReject}
          cssClassName="action-buttons"
          title={translate("pages").flags.flagDetails.reject}
          color={SECONDARY_COLOR}
        />
        <PrimaryButton
          onClick={openApprove}
          cssClassName="action-buttons"
          title={translate("pages").flags.flagDetails.approve}
        />
      </Row>
    </div>
  );
});

const SubmissionDetails = () => {
  const { translate } = useContext(I18nContext);

  const onApprove = () => console.log("Approve");
  const onReject = () => console.log("Reject");

  return (
    <div className="submission-details-container">
      <Suspense fallback={<SpiningPage opacity={1} />}>
        <Actions openReject={onReject} openApprove={onApprove} />
        <Divider />
        <Row gutter={[16, 0]}>
          <Col xs={24} md={8}>
            <SubmissionUserInfoForm />
          </Col>
          <Col xs={24} md={16}>
            <SubmissionDetailsInfo />
          </Col>
        </Row>
      </Suspense>
    </div>
  );
};
export default SubmissionDetails;
