import React, { useContext, useState } from "react";
import { BackArowIcon } from "components/atoms/icons";
import { Text } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";
import "./UserDetailNav.scss";

const UserDetailNav = ({ onBack }) => {
  const { translate } = useContext(I18nContext);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(10);

  const counter = (value) => {
    if (value === -1) {
      if (current > 1) {
        setCurrent(current + value);
      }
    } else {
      if (current < total) {
        setCurrent(current + value);
      }
    }
  };

  return (
    <div className="user-detail-nav">
      <div className="user-detail-back" onClick={onBack}>
        <Text text={<BackArowIcon />} />
        <Text text={translate("pages").settings.userDetail.nav.back} />
      </div>
      <div className="user-detail-counter">
        <Text
          text={`${
            translate("pages").settings.userDetail.nav.show
          } ${current} ${
            translate("pages").settings.userDetail.nav.of
          } ${total}`}
        />
        <Text text={<span onClick={() => counter(-1)} >{" < "}</span>} />
        <Text text={<span onClick={() => counter(1)} >{" > "}</span>} />
      </div>
    </div>
  );
};

export default UserDetailNav;
