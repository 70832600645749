import React, {useState, useContext} from 'react';
import "./ViewAboutContainer.scss";
import { Text } from 'components/atoms/texts';
import {CommentIcon, CalendarIcon, NavigatorIcon} from "components/atoms/icons";
import { ClockCircleOutlined } from '@ant-design/icons';
import Map from 'components/atoms/map';
import {PrimaryButton} from "components/atoms/controls";
import GreenMapPin from "assets/img/illustrations/mappin-green.svg";
import { Modal } from 'antd';
import GreenTick from "assets/img/icons/green-tick.svg";
import { Image } from "antd";
import { I18nContext } from "utils/i18n/locale";
import { useHistory } from 'react-router-dom';

const ViewAboutContainer = () => {

  const [saveModal, setSaveModal] = useState(false)
  const { translate } = useContext(I18nContext)
  const {push} = useHistory()
  const gotoWebsite = () => {
    window.open('https://launchsa.org/', '_blank');
  }

  return (
    <div className="view-events-container">

      <div id="modalMount" className="modal-container" />
      <div className="about-container">
        <Text text={"About Launch SA"} className="title" />
        <Text
        text={"We support entrepreneurs at all stages, in all industries, and from all backgrounds to start or grow their business in San Antonio. Whether you need a space to work, connections to mentors or subject matter experts, education on business-related topics, or just a community of others pursuing the same goals, Launch SA is available to support the journey. "}
          className="desc"
        />
      </div>


      <div className="location-container">
        <Text text={translate("client").pages.events.viewEvents.location} className="title" />
        <div className="location-desc">
          <Text text="600 Soledad St, San Antonio, TX 78205" className="text" />
        </div>
        <Map
          height="150px"
          mapTypeControl={false}
          className="notification-map"
          customPin={GreenMapPin}
          location= {
            {lat: 29.4324504,
            lng: -98.4950961}
          }
        />
        <PrimaryButton
          title={translate("client").pages.aboutProject.goToSite}
          onClick={() => gotoWebsite()}
        />
      </div>
    </div>
  )
}

export default ViewAboutContainer;
