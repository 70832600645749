import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import knowledgeAPI from "api/knowledgeAPI";
import { getCurrentProjectId } from "utils/common";

const awsErrorSerialized = {
  error: "ASW Error",
};

const awsError = (resp) => {
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};

export const handleKnowledgePostData = (newPost) => async (dispatch) => {
  try {
    dispatch(startGetForm());
    dispatch(successGetForm(newPost));
  } catch (error) {
    dispatch(errorGetForm(error.toString()));
  }
};
export const clearKnowledgePostData = () => async (dispatch) => {
  try {
    dispatch(startCleanForm());
    dispatch(successCleanForm());
  } catch (error) {
    dispatch(errorCleanForm(error.toString()));
  }
};

export const getKnowledgeCategories = createAsyncThunk(
  "knowledge/getKnowledgeCategories",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const knowledgeCategories = await knowledgeAPI.getKnowledgeCategories(
        project_id
      );
      awsError(knowledgeCategories);
      if (knowledgeCategories.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return knowledgeCategories.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getKnowledgePosts = createAsyncThunk(
  "knowledge/getKnowledgePosts",
  async (rejectWithValue) => {
    try {
      const project_id = getCurrentProjectId();
      const knowledgePosts = await knowledgeAPI.getKnowledgePosts(project_id);
      awsError(knowledgePosts);
      if (knowledgePosts.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return knowledgePosts.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getKnowledgePost = createAsyncThunk(
  "knowledge/getKnowledgePost",
  async (id, { rejectWithValue }) => {
    try {
      const knowledgePost = await knowledgeAPI.getKnowledgePost(id);
      awsError(knowledgePost);
      if (knowledgePost.data.statusCode === 404) {
        return { body: undefined };
      } else {
        return knowledgePost.data;
      }
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createKnowledgePost = createAsyncThunk(
  "knowledges/createKnowledgePost",
  async (item, { rejectWithValue }) => {
    try {
      const project_id = getCurrentProjectId();
      const knowledgePost = await knowledgeAPI.createKnowledgePost(
        project_id,
        item
      );
      awsError(knowledgePost);
      return knowledgePost.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updateKnowledgePost = createAsyncThunk(
  "knowledges/updateKnowledgePost",
  async (item, { rejectWithValue }) => {
    try {
      let updatePostValues = { ...item };
      const post_id = item.id;
      delete updatePostValues.id;
      const knowledgePost = await knowledgeAPI.updateKnowledgePost(
        post_id,
        updatePostValues
      );
      awsError(knowledgePost);
      return knowledgePost.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const deleteKnowledgePost = createAsyncThunk(
  "knowledges/deleteKnowledgePost",
  async (id, { rejectWithValue }) => {
    try {
      console.log(id, "a borrar");
      const knowledgePost = await knowledgeAPI.deleteKnowledgePost(id);
      awsError(knowledgePost);
      return knowledgePost.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const createKnowledgeCategory = createAsyncThunk(
  "knowledges/createKnowledgeCategory",
  async (item, { rejectWithValue }) => {
    try {
      const project_id = getCurrentProjectId();
      const knowledgeCategory = await knowledgeAPI.createKnowledgeCategory(
        project_id,
        item
      );
      awsError(knowledgeCategory);
      return knowledgeCategory.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

const initialState = {
  newPost: {
    title: "",
    content: {
      blocks: [
        //   {
        //     key: "5h45l",
        //     text: "",
        //     type: "unstyled",
        //     depth: 0,
        //     inlineStyleRanges: [],
        //     entityRanges: [],
        //     data: {},
        //   },
        //   {
        //     key: "7ns78",
        //     text:
        //       "Dashboard: Panel for Data Visualization in a variety of graphs.",
        //     type: "ordered-list-item",
        //     depth: 0,
        //     inlineStyleRanges: [
        //       {
        //         offset: 0,
        //         length: 9,
        //         style: "BOLD",
        //       },
        //     ],
        //     entityRanges: [],
        //     data: {},
        //   },
        //   {
        //     key: "adu28",
        //     text: "Communication: Sends Notifications and Emails.",
        //     type: "ordered-list-item",
        //     depth: 0,
        //     inlineStyleRanges: [
        //       {
        //         offset: 0,
        //         length: 13,
        //         style: "BOLD",
        //       },
        //     ],
        //     entityRanges: [],
        //     data: {},
        //   },
        //   {
        //     key: "er6ru",
        //     text: "Users: Tracks the activity each user within the app.",
        //     type: "ordered-list-item",
        //     depth: 0,
        //     inlineStyleRanges: [
        //       {
        //         offset: 0,
        //         length: 5,
        //         style: "BOLD",
        //       },
        //     ],
        //     entityRanges: [],
        //     data: {},
        //   },
        //   {
        //     key: "erpru",
        //     text: "Test: This is a test to check global status.",
        //     type: "ordered-list-item",
        //     depth: 0,
        //     inlineStyleRanges: [
        //       {
        //         offset: 0,
        //         length: 5,
        //         style: "BOLD",
        //       },
        //     ],
        //     entityRanges: [],
        //     data: {},
        //   },
        //   {
        //     key: "aijur",
        //     text:
        //       "Flags: Handles the creation and approval or rejection of flags raised.",
        //     type: "ordered-list-item",
        //     depth: 0,
        //     inlineStyleRanges: [
        //       {
        //         offset: 0,
        //         length: 5,
        //         style: "BOLD",
        //       },
        //     ],
        //     entityRanges: [],
        //     data: {},
        //   },
        //   {
        //     key: "cn69t",
        //     text:
        //       "Tasks: Once a flag is approved it is assigned to a department until it is resolved.",
        //     type: "ordered-list-item",
        //     depth: 0,
        //     inlineStyleRanges: [
        //       {
        //         offset: 0,
        //         length: 5,
        //         style: "BOLD",
        //       },
        //     ],
        //     entityRanges: [],
        //     data: {},
        //   },
        //   {
        //     key: "2thr2",
        //     text: "Maps: Data Visualization in a geographical representation.",
        //     type: "ordered-list-item",
        //     depth: 0,
        //     inlineStyleRanges: [
        //       {
        //         offset: 0,
        //         length: 4,
        //         style: "BOLD",
        //       },
        //     ],
        //     entityRanges: [],
        //     data: {},
        //   },
        //   {
        //     key: "estc6",
        //     text:
        //       "Settings: Allow the creation and edition of new CRM users, departments and categories (different type of city services the user may contact).",
        //     type: "ordered-list-item",
        //     depth: 0,
        //     inlineStyleRanges: [
        //       {
        //         offset: 0,
        //         length: 8,
        //         style: "BOLD",
        //       },
        //     ],
        //     entityRanges: [],
        //     data: {},
        //   },
      ],
      entityMap: {},
    },
    content_html: "",
    knowledge_category_id: undefined,
    id: undefined,
  },
  knowledgePosts: [],
  knowledgePost: {},
  knowledgeCategories: [],
  error: null,
  loading: true,
  knowledgeUpdated: false,
  knowledgeCreated: false,
  loadingAction: false,
};

const knowledges = createSlice({
  name: "knowledge",
  initialState,
  reducers: {
    startGetForm(state, action) {},
    successGetForm(state, action) {
      state.newPost = { ...state.newPost, ...action.payload };
    },
    errorGetForm(state, action) {
      console.log("error en el preview data", action);
    },
    startCleanForm(state, action) {},
    successCleanForm(state, action) {
      state.newPost = {
        title: "",
        content: {
          blocks: [],
          entityMap: {},
        },
        knowledge_category_id: undefined,
        id: undefined,
      };
    },
    errorCleanForm(state, action) {
      console.log("error en el clean data", action);
    },
  },
  extraReducers: {
    //Get Knowledge Posts
    [getKnowledgePosts.pending]: (state, action) => {
      console.info("getKnowledgePosts pending");
      state.loading = true;
      state.error = null;
      state.knowledgesUpdated = false;
      state.knowledgesCreated = false;
      state.knowledgePosts = [];
    },
    [getKnowledgePosts.fulfilled]: (state, action) => {
      console.info("getKnowledgePosts fullfilled", action.payload.body);
      state.loading = false;
      state.error = null;
      state.knowledgesUpdated = false;
      state.knowledgePosts = action.payload.body;
    },
    [getKnowledgePosts.rejected]: (state, action) => {
      console.error("getKnowledgePosts rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.knowledgePosts = [];
    },

    //Get Knowledge Post
    [getKnowledgePost.pending]: (state, action) => {
      console.info("getKnowledgePost pending");
      state.loading = true;
      state.error = null;
      state.knowledgesUpdated = false;
      state.knowledgesCreated = false;
      state.knowledgePost = {};
    },
    [getKnowledgePost.fulfilled]: (state, action) => {
      console.info("getKnowledgePost fullfilled", action.payload.body);
      state.loading = false;
      state.error = null;
      state.knowledgesUpdated = false;
      state.knowledgePost = action.payload.body;
    },
    [getKnowledgePost.rejected]: (state, action) => {
      console.error("getKnowledgePost rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.knowledgePost = {};
    },
    //Get Knowledge Categories
    [getKnowledgeCategories.pending]: (state, action) => {
      console.info("getKnowledgeCategories pending");
      state.loading = true;
      state.error = null;
      state.knowledgesUpdated = false;
      state.knowledgesCreated = false;
      //   state.knowledgeCategories = [];
    },
    [getKnowledgeCategories.fulfilled]: (state, action) => {
      console.info("getKnowledgeCategories fullfilled", action.payload.body);
      state.loading = false;
      state.error = null;
      state.knowledgesUpdated = false;
      state.knowledgeCategories = action.payload.body;
    },
    [getKnowledgeCategories.rejected]: (state, action) => {
      console.error("getKnowledgeCategories rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.knowledgeCategories = [];
    },

    //Create Knowledge Post
    [createKnowledgePost.pending]: (state, action) => {
      console.info("createKnowledgePost pending");
      state.loadingAction = true;
      state.error = null;
      state.knowledgesUpdated = false;
      state.knowledgesCreated = false;
    },
    [createKnowledgePost.fulfilled]: (state, action) => {
      console.info("createKnowledgePost fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.knowledgesCreated = true;
    },
    [createKnowledgePost.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("createKnowledgePost rejected =>", action.payload);
    },

    //Update Knowledge Post
    [updateKnowledgePost.pending]: (state, action) => {
      console.info("updateKnowledgePost pending");
      state.loadingAction = true;
      state.error = null;
      state.knowledgesUpdated = false;
      state.knowledgesCreated = false;
    },
    [updateKnowledgePost.fulfilled]: (state, action) => {
      console.info("updateKnowledgePost fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.knowledgesCreated = true;
    },
    [updateKnowledgePost.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("updateKnowledgePost rejected =>", action.payload);
    },

    //Create Knowledge Post
    [deleteKnowledgePost.pending]: (state, action) => {
      console.info("deleteKnowledgePost pending");
      state.loadingAction = true;
      state.error = null;
      state.knowledgesUpdated = false;
      state.knowledgesCreated = false;
    },
    [deleteKnowledgePost.fulfilled]: (state, action) => {
      console.info("deleteKnowledgePost fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.knowledgesCreated = true;
    },
    [deleteKnowledgePost.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("deleteKnowledgePost rejected =>", action.payload);
    },

    //Create Knowledge Category
    [createKnowledgeCategory.pending]: (state, action) => {
      console.info("createKnowledgeCategory pending");
      state.loadingAction = true;
      state.error = null;
      state.knowledgesUpdated = false;
      state.knowledgesCreated = false;
    },
    [createKnowledgeCategory.fulfilled]: (state, action) => {
      console.info("createKnowledgeCategory fullfilled");
      state.loadingAction = false;
      state.error = null;
      state.knowledgesCreated = true;
    },
    [createKnowledgeCategory.rejected]: (state, action) => {
      state.loadingAction = false;
      state.error = action.payload.error;
      console.error("createKnowledgeCategory rejected =>", action.payload);
    },
  },
});

export const {
  startGetForm,
  successGetForm,
  errorGetForm,
  startCleanForm,
  successCleanForm,
  errorCleanForm,
} = knowledges.actions;
export default knowledges.reducer;
