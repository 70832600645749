import React, { useContext } from "react";
import KanbanLaneHeader from "components/molecules/KanbanLaneHeader";
import { Col } from "antd";
import KanbanCard from "components/molecules/KanbanCard";
import "./FlagsKanbanLane.scss";
import { I18nContext } from "utils/i18n/locale";
import { Droppable, Draggable } from "react-beautiful-dnd";

const FlagsKanbanLane = ({
  data,
  title,
  color = "#00B999",
  onAddPressed,
  onClick,
  droppableId = "droppable",
}) => {
  const { translate } = useContext(I18nContext);

  return (
    <Droppable droppableId={droppableId}>
      {(provided, _) => (
        <Col className="kanban-lane-container" ref={provided.innerRef}>
          <KanbanLaneHeader
            color={color}
            title={`${title} (${data?.length})`}
          />
          {data.map((value, index) => (
            <Draggable
              key={value.id}
              draggableId={value.id.toString()}
              index={index}
            >
              {(provided, _) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <KanbanCard
                    onClick={() => onClick(data[index])}
                    date={value.created_at}
                    title={value.typename ? value.typename.name : ""}
                    typename={value.typename ? value.typename.name : ""}
                    description={value.description ?? ""}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {/* TODO: handle function to add flags  */}
          {/* <div className='kanban-add-button' onClick={onAddPressed}>+ {translate("pages").flags.activity.addFlag}</div> */}
        </Col>
      )}
    </Droppable>
  );
};
export default FlagsKanbanLane;
