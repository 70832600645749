import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileApi from "api/profileAPI";
import { getCurrentOrganizationId } from "utils/common";

const awsErrorSerialized = {
  error: "AWS Error",
};

const awsError = (resp) => {
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (rejectWithValue) => {
    try {
      const profile = await profileApi.getProfile();
      // console.log("Profile from API", profile);
      awsError(profile);
      return profile.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const enrollUser = createAsyncThunk(
  "profile/enrollUser",
  async (data, { rejectWithValue }) => {
    try {
      const enrolled = await profileApi.enrollUser(data);
      awsError(enrolled);
      return enrolled.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updateOrganization = createAsyncThunk(
  "profile/updateOrganization",
  async (data, { rejectWithValue }) => {
    try {
      const organization_id = getCurrentOrganizationId();
      const updated = await profileApi.updateOrganization(
        data,
        organization_id
      );
      awsError(updated);
      return updated.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (item, { rejectWithValue }) => {
    try {
      let editProfile = { ...item };
      delete editProfile.id;
      const profile = await profileApi.updateProfile(editProfile);
      awsError(profile);
      return profile.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

const initialState = {
  profile: null,
  profileLoaded: false,
  userEnrolled: false,
  error: null,
  organizationUpdated: false,
  loading: true,
  loadingAction: false,
  profileUpdated: false,
  loadingError: false,
};

const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: {
    // Get Profile
    [getProfile.pending]: (state, action) => {
      console.log("getProfile pending =>");
      state.loading = true;
      state.error = null;
      state.profileUpdated = false;
      state.loadingError = false;
    },
    [getProfile.fulfilled]: (state, action) => {
      console.log("getProfile fulfilled =>", action.payload);
      state.loading = false;
      state.profile = action.payload.body;

      const currentProjectId = localStorage.getItem("current_project");
      const current_user = {
        order: action.payload.body.id,
        name: action.payload.body.name,
        last_name: action.payload.body.last_name,
        email: action.payload.body.email,
        image: action.payload.body.image,
      };

      localStorage.setItem("current_user", JSON.stringify(current_user));
      if (!getCurrentOrganizationId()) {
        localStorage.setItem(
          "current_organization",
          action.payload.body?.organizations?.length > 0
            ? action.payload.body?.organizations[0]?.id
            : undefined
        );
      }
      if (
        !currentProjectId ||
        currentProjectId === null ||
        currentProjectId === "null" ||
        currentProjectId === undefined
      ) {
        let p_id =
          // action.payload.body?.configurations?.project_id ??
          action.payload.body?.projects?.length > 0
            ? action.payload.body?.projects[0]?.project?.id
            : undefined;
        localStorage.setItem("current_project", p_id);
        if (
          action.payload.body?.organizations[0]?.complete 
          // action.payload.body?.organizations[0]?.complete ||
          // action.payload.body?.organizations?.length <= 0
        ) {
          window.location.reload();
        }
      }

      state.profileLoaded = true;
    },
    [getProfile.rejected]: (state, action) => {
      console.log("getProfile rejected");
      state.loading = false;
      state.loadingError = true;
      state.error = action.payload;
      state.profileLoaded = false;
      state.profile = {};
    },
    // Update Profile
    [updateProfile.pending]: (state, action) => {
      console.log("updateProfile pending");
      state.error = null;
      state.profileUpdated = true;
    },
    [updateProfile.fulfilled]: (state, action) => {
      console.log("updateProfile fulfilled", action.payload);
      state.loading = false;
      state.profileUpdated = false;

      const current_user = {
        order: action.payload.body.id,
        name: action.payload.body.name,
        last_name: action.payload.body.last_name,
        email: action.payload.body.email,
        image: action.payload.body.image,
      };

      localStorage.setItem("current_user", JSON.stringify(current_user));
      state.profile = action.payload.body;

      // state.profile = {
      //   roles: state?.profile?.roles,
      //   user: { ...state?.profile?.user, ...action?.payload?.body },
      // };
    },
    [updateProfile.rejected]: (state, action) => {
      console.log("updateProfile rejected");
      state.loading = false;
      state.profileUpdated = false;
      state.error = action.payload;
    },
    [enrollUser.pending]: (state, action) => {
      console.log("enrollUser pending");
      state.error = null;
      state.userEnrolled = false;
    },
    [enrollUser.fulfilled]: (state, action) => {
      console.log("enrollUser fullfiled");
      state.error = null;
      state.userEnrolled = true;
    },
    [enrollUser.rejected]: (state, action) => {
      console.log("enrollUser rejected");
      state.error = null;
      state.profileUpdated = true;
      state.userEnrolled = true;
    },
    [updateOrganization.pending]: (state, action) => {
      console.log("updateOrganization pending");
      state.error = null;
      state.loadingAction = true;
      state.organizationUpdated = false;
    },
    [updateOrganization.fulfilled]: (state, action) => {
      console.log("updateOrganization fullfiled=>");
      state.error = null;
      state.loadingAction = false;
      state.organizationUpdated = true;
    },
    [updateOrganization.rejected]: (state, action) => {
      console.log("updateOrganization rejected", action);
      state.error = null;
      state.loadingAction = false;
      state.organizationUpdated = false;
    },
  },
});

export default profile.reducer;
