import ChangePassword from 'pages/ChangePassword';

const prefix = '/change';

const routes = [
  {
    path: `${prefix}`,
    component: ChangePassword,
    exact: true,
  },
];

export default routes;
