import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getQuestionsAPI,
  getQuestionAPI,
  deleteQuestionAPI,
  duplicateQuestionAPI,
  updateQuestionAPI,
  createQuestionAPI,
  getQuestionTypesAPI,
  orderQuestion,
} from "api/questionsAPI";

const awsErrorSerialized = {
  error: "AWS Error",
};

const awsError = (resp) => {
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};
export const getQuestions = createAsyncThunk(
  "questions/getQuestions",
  async (rejectWithValue) => {
    try {
      const questions = await getQuestionsAPI();
      awsError(questions);
      return questions.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const getQuestion = createAsyncThunk(
  "questions/getQuestion",
  async (id, { rejectWithValue }) => {
    try {
      const question = await getQuestionAPI(id);
      awsError(question);
      return question.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const getQuestionTypes = createAsyncThunk(
  "questions/getQuestionType",
  async (data, { rejectWithValue }) => {
    try {
      const questionTypes = await getQuestionTypesAPI();
      awsError(questionTypes);
      return questionTypes.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const createQuestion = createAsyncThunk(
  "questions/createQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const createQuestion = await createQuestionAPI(data);
      awsError(createQuestion);
      return createQuestion.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const deleteQuestion = createAsyncThunk(
  "questions/deleteQuestion",
  async (id, { rejectWithValue }) => {
    try {
      const deleteQuestion = await deleteQuestionAPI(id);
      awsError(deleteQuestion);
      return deleteQuestion.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);
export const duplicateQuestion = createAsyncThunk(
  "questions/duplicateQuestion",
  async (id, { rejectWithValue }) => {
    try {
      const duplicateQuestion = await duplicateQuestionAPI(id);
      awsError(duplicateQuestion);
      return duplicateQuestion.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updateQuestion = createAsyncThunk(
  "questions/updateQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const updateQuestion = await updateQuestionAPI(data);
      awsError(updateQuestion);
      return updateQuestion.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const reOrderQuestion = createAsyncThunk(
  "questions/reOrderQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const updateQuestion = await orderQuestion(data);
      awsError(updateQuestion);
      return updateQuestion.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const resetGetData = () => async (dispatch) => {
  dispatch(reset());
};


const initialState = {
  questions: [],
  question: {},
  questionTypes: [],
  error: null,
  loading: true,
  questionUpdated: false,
  questionTypesFetched: false,
  questionCreated: false,
  questionCreating: false,
  questionDeleting: false,
  questionPublished: false,
  questionDuplicated: false,
  questionTypeError: null,
};

const questions = createSlice({
  name: "questions",
  initialState,
  reducers: {
    reset(state, action) {

      state.questions = [];
    },
  },
  extraReducers: {
    [getQuestions.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.questionCreated = false;
      state.questionUpdated = false;
    },
    [getQuestions.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.questions = action.payload.body;
    },
    [getQuestions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.questions = [];
    },

    [getQuestion.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.questionCreated = false;
      state.questionUpdated = false;
    },
    [getQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.question = action.payload.body;
    },
    [getQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
      state.question = {};
    },

    [getQuestionTypes.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getQuestionTypes.fulfilled]: (state, action) => {
      state.loading = false;
      state.questionTypesFetched = true;
      state.questionTypeError = null;
      state.questionTypes = action.payload.body;
    },
    [getQuestionTypes.rejected]: (state, action) => {
      state.loading = false;
      state.questionTypeError = action.error;
      state.questionTypes = [];
    },

    [createQuestion.pending]: (state, action) => {
      state.questionCreating = true;
      state.error = null;
      state.questionCreated = false;
      state.questionUpdated = false;
    },
    [createQuestion.fulfilled]: (state, action) => {
      state.questionCreating = false;
      state.error = null;
      state.questionCreated = true;
      state.question = action.payload.body;
    },
    [createQuestion.rejected]: (state, action) => {
      state.questionCreating = false;
      state.error = action.payload.error;
    },

    [duplicateQuestion.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.questionDuplicated = false;
    },
    [duplicateQuestion.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.questionDuplicated = true;
    },
    [duplicateQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [deleteQuestion.pending]: (state, action) => {
      state.questionDeleting = true;
      state.error = null;
      state.questionUpdated = false;
    },
    [deleteQuestion.fulfilled]: (state, action) => {
      state.questionDeleting = false;
      state.error = null;
      state.questionUpdated = true;
    },
    [deleteQuestion.rejected]: (state, action) => {
      state.questionDeleting = false;
      state.error = action.payload.error;
    },

    [updateQuestion.pending]: (state, action) => {
      state.questionCreating = true;
      state.error = null;
      state.questionUpdated = false;
    },
    [updateQuestion.fulfilled]: (state, action) => {
      state.questionCreating = false;
      state.error = null;
      state.questionUpdated = true;
    },
    [updateQuestion.rejected]: (state, action) => {
      state.questionCreating = false;
      state.error = action.payload.error;
    },

    [reOrderQuestion.pending]: (state, action) => {
      // state.questionCreating = true;
      state.error = null;
      state.questionUpdated = false;
    },
    [reOrderQuestion.fulfilled]: (state, action) => {
      // state.questionCreating = false;
      state.error = null;
      state.questionUpdated = true;
    },
    [reOrderQuestion.rejected]: (state, action) => {
      state.questionUpdated = false;
      // state.questionCreating = false;
      state.error = action.payload.error;
    },
  },
});
export const {

  reset

} = questions.actions;
export default questions.reducer;
