import ClientEvents from "client/pages/Events";
import ViewEvent from 'client/pages/ViewEvent'

const prefix = "/client/events";

const routes = [
  {
    path: `${prefix}`,
    component: ClientEvents,
    exact: true,
  },
  {
    path: `${prefix}/viewevent`,
    component: ViewEvent,
    exact: true,
  },
];

export default routes;
