import React, {useRef} from "react";
import Modal, {TYPE_DEFAULT} from "components/atoms/modal";
import "./SimpleInputsModal.scss"
import {Form} from "antd";
import {StandardInput} from "components/atoms/inputs";
import {CrossIcon} from "components/atoms/icons";

const SimpleInputsModal = ({
  inputs = [],
  title,
  okText = "Submit",
  type=TYPE_DEFAULT,
  onSubmit,
  centered=true,
  showCloseIcon=true,
  onError = () => {},
  ...props
}) => {
  const ref = useRef();
  return (
    <Modal
      className="irys-simple-inputs-modal"
      showCloseIcon={showCloseIcon}
      type={type}
      centered={centered}
      okText={okText}
      title={title}
      onOk={() => ref.current.submit()}
      okButtonProps={{ key: 'submit', htmlType: 'submit' }}
      closeIcon={<CrossIcon/>}
      {...props}>
      <Form ref={ref} onFinish={onSubmit} onFinishFailed={onError}>
        {
          inputs.map(({ name, errorMessage, placeholder = null, required = false }, index) => (
            <Form.Item
              key={index.toString()}
              name={name}
              rules={[
                {
                  required,
                  message: errorMessage
                },
              ]}
            >
              <StandardInput placeholder={placeholder} className="plain-input" />
            </Form.Item>
          ))
        }
      </Form>
    </Modal>
  );
};
export default SimpleInputsModal;
