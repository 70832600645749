import React from "react";
import Avatar from "react-avatar";
// import { Storage } from "aws-amplify";
import "./UserProfileBadge.scss";
import { cloudFrontUrl } from "utils/common";

const UserProfileBadge = React.memo(function UserProfileBadge(props) {
  // const [image, setImage] = useState(undefined);
  // const selectedColor = useRef(
  //   Math.floor(Math.random() * 10) % 2 ? "#0CAFA0" : "#F38B00"
  // );

  // useEffect(async () => {
  //   if (props.image) {
  //     const signedURL = await Storage.get(props.image);
  //     setImage(signedURL);
  //   }
  // }, [props]);

  // const userImage = useRef(props.image);
  return (
    <div className="badge-container">
      <span className="badge-image-box">
        {props.image ? (
          <img
            src={cloudFrontUrl(props.image)}
            className="badge-image"
            alt={props.image}
          />
        ) : (
          <Avatar
            name={props.name}
            size={40}
            round="100px"
            color={Avatar.getRandomColor("sitebase", ["#0CAFA0", "#F38B00"])}
            style={{ letterSpacing: 1 }}
          />
        )}
      </span>
      <div className="badge-details">
        <span className="name">{props.name}</span>
        <br />
        <span className="email">{props.email ? `${props.email}` : ""}</span>
      </div>
    </div>
  );
});
const MemoizedUserProfileBadge = React.memo(UserProfileBadge);
export default MemoizedUserProfileBadge;
