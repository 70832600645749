import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import flagsAPI from "api/flagsAPI";

const awsErrorSerialized = {
  error: "ASW Error",
};

const awsError = (resp) => {
  if (resp.data.errorType) {
    throw awsErrorSerialized;
  }
};

export const asyncGetData = (previewData) => async (dispatch) => {
  try {
    dispatch(startGetForm());
    dispatch(successGetForm(previewData));
  } catch (error) {
    dispatch(errorGetForm(error.toString()));
  }
};

export const createReport = createAsyncThunk(
  "report/createReport",
  async (item, { rejectWithValue }) => {
    // console.log("item", item);
    try {
      const report = await flagsAPI.createReport(item);
      awsError(report);
      return report.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const resetGetData = () => async (dispatch) => {
  dispatch(reset());
};

export const getReports = createAsyncThunk(
  "report/getReports",
  async (id, { rejectWithValue }) => {
    try {
      const reports = await flagsAPI.getFlags(id);
      awsError(reports);
      if (reports.data.statusCode === 404) {
        return { body: [] };
      } else {
        return reports.data;
      }
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.error);
    }
  }
);

// export const changeProject = (report) => async (dispatch) => {
//   try {
//     dispatch(startChangeProject());
//     dispatch(successChangeProject(report));
//   } catch (error) {
//     dispatch(errorChangeProject(error.toString()));
//   }
// };

// export const openCreateProjectModal = () => async (dispatch) => {
//   try {
//     dispatch(startOpenCreateProjectModal());
//     dispatch(successOpenCreateProjectModal());
//   } catch (error) {
//     dispatch(errorOpenCreateProjectModal(error.toString()));
//   }
// };
// export const closeCreateProjectModal = () => async (dispatch) => {
//   try {
//     dispatch(startCloseCreateProjectModal());
//     dispatch(successCloseCreateProjectModal());
//   } catch (error) {
//     dispatch(errorCloseCreateProjectModal(error.toString()));
//   }
// };

// export const asyncClearData = (previewData) => async (dispatch) => {
//   try {
//     dispatch(startCleanForm());
//     dispatch(successCleanForm(previewData));
//   } catch (error) {
//     dispatch(errorCleanForm(error.toString()));
//   }
// };

// export const getProjects = createAsyncThunk(
//   "reports/getProjects",
//   async (rejectWithValue) => {
//     try {
//       const reports = await flagsAPI.getProjects();
//       awsError(reports);
//       if (reports.data.statusCode === 404) {
//         return { body: undefined };
//       } else {
//         return reports.data;
//       }
//     } catch (error) {
//       return rejectWithValue(error.error);
//     }
//   }
// );

// export const createProjects = createAsyncThunk(
//   "reports/createProjects",
//   async (item, { rejectWithValue }) => {
//     try {
//       const reports = await flagsAPI.createProject(item);
//       console.log(reports, "create reports");
//       awsError(reports);
//       return reports.data;
//     } catch (error) {
//       return rejectWithValue(error.error);
//     }
//   }
// );

const initialState = {
  widget_report: {
    project_id: "",
    reported_by: null,
    type: "location",
    organization_id: "",
    something: "",
    address: "",
    source: "widget",
    zipcode: "",
    latitude: 0,
    longitude: 0,
    description: "",
    images: [],
    efields: [],
  },
  loadingAction: false,
  reportCreated: false,
  error: false,
  report: null,
  reportCreated: false,
  userReports: [],
};

const reports = createSlice({
  name: "reports",
  initialState,
  reducers: {
    startGetForm(state, action) {
      // console.log("starting get data from redux");
    },
    successGetForm(state, action) {
      state.widget_report = { ...state.widget_report, ...action.payload };
    },
    errorGetForm(state, action) {
      console.log("error en el  widget_report", action);
    },
    reset(state, action) {
      state.loading = true;
      state.loadingAction = false;
      state.report = null;
      state.reportCreated = false;
    },
    // startChangeProject(state, action) {},
    // successChangeProject(state, action) {
    //   state.current_report = action.payload;
    // },
    // errorChangeProject(state, action) {
    //   console.log("error en el change report ", action);
    // },
    // startCleanForm(state, action) {},
    // successCleanForm(state, action) {
    //   state.current_report = action.payload;
    // },
    // errorCleanForm(state, action) {
    //   console.log("error en el clean data", action);
    // },
    // startOpenCreateProjectModal(state, action) {
    //   console.log("starting to open the modal");
    //   state.isModalOpened = undefined;
    // },
    // successOpenCreateProjectModal(state, action) {
    //   console.log("modal opened");
    //   state.isModalOpened = true;
    // },
    // errorOpenCreateProjectModal(state, action) {
    //   console.log("error en el open modal", action);
    //   state.isModalOpened = undefined;
    // },
    // startCloseCreateProjectModal(state, action) {
    //   console.log("starting to close the modal");
    //   state.isModalOpened = undefined;
    // },
    // successCloseCreateProjectModal(state, action) {
    //   console.log("modal opened");
    //   state.isModalOpened = false;
    // },
    // errorCloseCreateProjectModal(state, action) {
    //   console.log("error en el open modal", action);
    //   state.isModalOpened = undefined;
    // },
  },
  extraReducers: {
    // CREATE FLAG
    [createReport.pending]: (state, action) => {
      state.loadingAction = true;
      state.error = null;
      state.reportCreated = false;
      console.info("createReport pending");
    },
    [createReport.fulfilled]: (state, action) => {
      state.loadingAction = false;
      state.error = null;
      state.reportCreated = true;
      console.info("createReport fullfilled");
    },
    [createReport.rejected]: (state, action) => {
      console.error("createReport rejected =>", action);
      state.loadingAction = false;
      state.error = action.payload;
    },
    //Get Reports
    [getReports.pending]: (state, action) => {
      console.info("getReports pending");
      state.loading = true;
      state.error = null;
    },
    [getReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;

      state.reports = action.payload.body;
      console.info("getReports fullfilled");
    },
    [getReports.rejected]: (state, action) => {
      console.error("getReports rejected =>", action.payload);
      state.loading = false;
      state.error = action.payload;
      state.reports = [];
    },

    // //Get Projects
    // [getProjects.pending]: (state, action) => {
    //   console.info("getProjects pending");
    //   state.loading = true;
    //   state.error = null;
    //   state.reportsUpdated = false;
    //   state.reportsCreated = false;
    // },
    // [getProjects.fulfilled]: (state, action) => {
    //   console.info("getProjects fullfilled");
    //   state.loading = false;
    //   state.error = null;
    //   state.reportsUpdated = false;
    //   state.reports = action.payload.body;
    // },
    // [getProjects.rejected]: (state, action) => {
    //   console.error("getProjects rejected =>", action.payload);
    //   state.loading = false;
    //   state.error = action.payload;
    //   state.reports = [];
    // },
    // //Create Projects
    // [createProjects.pending]: (state, action) => {
    //   console.info("createProjects pending");
    //   state.loadingAction = true;
    //   state.error = null;
    //   state.reportsUpdated = false;
    //   state.reportsCreated = false;
    // },
    // [createProjects.fulfilled]: (state, action) => {
    //   console.info("createProjects fullfilled");
    //   state.loadingAction = false;
    //   state.error = null;
    //   state.reportsCreated = true;
    // },
    // [createProjects.rejected]: (state, action) => {
    //   state.loadingAction = false;
    //   state.error = action.payload.error;
    //   console.error("createProjects rejected =>", action.payload);
    // },
  },
});

export const {
  startGetForm,
  successGetForm,
  errorGetForm,
  reset
  //   startChangeProject,
  //   successChangeProject,
  //   errorChangeProject,
  //   startCleanForm,
  //   successCleanForm,
  //   errorCleanForm,
  //   startOpenCreateProjectModal,
  //   successOpenCreateProjectModal,
  //   errorOpenCreateProjectModal,
  //   startCloseCreateProjectModal,
  //   successCloseCreateProjectModal,
  //   errorCloseCreateProjectModal,
} = reports.actions;
export default reports.reducer;
