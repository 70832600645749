import Communication from "pages/Communication";
import SingleMail from "pages/Communication/Email/SingleMail";
import SubmissionDetails from "pages/Communication/Submissions/Details";

const prefix = "/communication";

const routes = [
  {
    path: `${prefix}/submission/:id`,
    component: SubmissionDetails,
    exact: true,
  },
  {
    path: `${prefix}`,
    component: Communication,
  },
  {
    path: `${prefix}/email/:id`,
    component: SingleMail,
    exact: true,
  },
];

export default routes;
