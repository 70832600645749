import React, { useContext, useEffect, useState } from "react";
import { Menu, Layout, Image, Skeleton } from "antd";
import { Link, useHistory } from "react-router-dom";
import { I18nContext } from "utils/i18n/locale";
import { useDispatch, useSelector } from "react-redux";
import {
  HomeIcon,
  ChatBotIcon,
  NotificationIcon,
  MentionIcon,
  TaskIcon,
  SurveyIcon,
  FlagIcon,
  EventsIcon,
  AnalyticsIcon,
  FeedIcon,
  UserIcon,
  MapIcon,
  CommunicationIcon,
  LogoutIcon,
} from "components/atoms/icons";
import logo from "assets/img/irys.png";
import "./SideBar.scss";
import { SettingOutlined } from "@ant-design/icons";
import ProjectSelect from "components/molecules/ProjectSelect";
import { DirectoryIcon } from "components/atoms/icons";
import { changeProject, changeTheme, getProjects } from "redux/slices/projects";
import { getCurrentProjectId, getLocalSettings } from "utils/common";
import { SpiningPage } from "components/atoms/icons";
import { Fragment } from "react";
import Cookies from "universal-cookie";
import launchLogo from "assets/img/launch.png";
import IrysLogo from "assets/img/irys_w.png";
import { getProfile } from "redux/slices/profile";
const route = "";
const { Sider } = Layout;
const { SubMenu } = Menu;
const SideBar = (props) => {
  const { translate } = useContext(I18nContext);
  // const settings = getLocalSettings();
  // console.log(settings);
  const cookies = new Cookies();
  const history = useHistory();
  const currentProjectId = getCurrentProjectId();

  const [projectSelected, setProjectSelected] = useState();

  // const roles = useSelector((state) => state.projects.userRoles);
  const profile = useSelector((state) => state.profile.profile);
  const projects = useSelector((state) => state.projects.projects);
  const errorLoading = useSelector((state) => state.projects.errorLoading);
  const loading = useSelector((state) => state.projects.loadingSidebar);

  const [roles, setRoles] = useState([]);
  const [userSettings, setUserSettings] = useState();

  const dispatch = useDispatch();

  const currentProject = useSelector((state) => state.projects.currentProject);
  const [permissions, setPermissions] = useState(currentProject?.permissions);

  // useEffect(() => {
  //   console.log({ projectSelected });
  //   if (projectSelected) {
  //     // console.log(currentProject, "en el permissions");
  //     setPermissions(currentProject.permissions);
  //   }
  // }, [projectSelected]);

  const getCurrentProjectSelected = (projects, currentProjectId) => {
    if (!currentProject || currentProject === null) {
      window.location.replace(`/verify?logout=true`);
    }
    if (projects?.length > 1) {
      // console.log("entra al if", projects);
      let project = projects.filter(
        (p) => p?.project?.id?.toString() === currentProjectId?.toString()
      );
      // console.log(" sale el if", project);
      setProjectSelected(project[0]);
      dispatch(
        changeProject({ item: project[0], project_id: currentProjectId })
      );
    } else if (projects?.length === 1) {
      // console.log("entra al elseif", projects[0]);
      setProjectSelected(projects[0]);
      // console.log(" sale el elseif", projects);
      dispatch(
        changeProject({ item: projects[0], project_id: currentProjectId })
      );
    }
  };

  useEffect(() => {
    // dispatch(getProfile());
    setTimeout(() => {
      dispatch(getProjects());
    }, 500);
  }, []);
  useEffect(() => {
    if (errorLoading) {
      dispatch(getProjects());
      // dispatch(getProfile());
    }
  }, [errorLoading]);

  useEffect(() => {
    getCurrentProjectSelected(projects, currentProjectId);
  }, [projects]);
  useEffect(() => {
    if (profile?.organizations?.length > 0) {
      if (
        !localStorage.getItem("reloaded") &&
        !profile?.organizations[0]?.complete
      ) {
        localStorage.setItem("reloaded", true);
        // window.location.reload();
      }
    }
  }, [profile]);

  useEffect(() => {
    if (projectSelected) {
      // console.log("project selected ", projectSelected);
      setRoles(projectSelected ? projectSelected?.permissions : []);
      setPermissions(projectSelected ? projectSelected.permissions : []);
      setUserSettings(projectSelected ? projectSelected?.project : []);
      dispatch(changeTheme(projectSelected ? projectSelected?.project : []));
      if (projectSelected && projectSelected?.organization?.id) {
        localStorage.setItem(
          "current_organization",
          projectSelected?.organization?.id
        );
        localStorage.setItem("current_project", projectSelected?.project?.id);
      }
    }
  }, [projectSelected]);

  const validateLaunchSA = () => {
    const organization = cookies.get("organization");
    if (organization) {
      return true;
    }
  };

  const menuRoutes = [
    // {
    //   key: "home",
    //   icon: <HomeIcon />,
    //   path: "home",
    //   translate: translate("organisms").sidebar.home,
    // },
    // {
    //   key: "map",
    //   icon: <MapIcon />,
    //   path: "map",
    //   translate: translate("organisms").sidebar.map,
    // },
    {
      key: "analytics",
      icon: <AnalyticsIcon />,
      path: "home/analytics/demo",
      translate: translate("organisms").sidebar.dashboard,
      active: true,
    },
    {
      key: "surveys",
      icon: <SurveyIcon />,
      path: "home/surveys",
      translate: translate("organisms").sidebar.surveys,
      active: roles?.surveys?.read,
    },
    {
      key: "communication",
      icon: <CommunicationIcon />,
      path: "home/communications",
      translate: translate("organisms").sidebar.communication,
      active: roles?.communications?.read,
    },
    // {
    //   key: 'chatbot',
    //   icon: <ChatBotIcon />,
    //   path: 'chatbot',
    //   translate: translate('organisms').sidebar.chatbot,
    // },
    {
      key: "events",
      icon: <EventsIcon />,
      path: "home/events",
      translate: translate("organisms").sidebar.events,
      active: roles?.events?.read,
    },
    {
      key: "user",
      icon: <UserIcon />,
      path: "home/user-list",
      translate: translate("organisms").sidebar.users,
      active: roles?.users?.read,
    },
    {
      key: "flags",
      icon: <FlagIcon />,
      path: "home/flags",
      translate: translate("organisms").sidebar.flags,
      active: roles?.flags?.read,
    },
    {
      key: "directory",
      icon: <DirectoryIcon />,
      path: "home/business-directory",
      translate: translate("organisms").sidebar.directory,
    },
    // {
    //   key: 'tasks',
    //   type: 'submenu',
    //   titleIcon: <TaskIcon />,
    //   titleTranslate: translate('organisms').sidebar.tasks,
    //   itemGroup: [
    //     {
    //       title: 'Item 1',
    //       key: 'item-1',
    //       options: [
    //         {
    //           key: 'option-1',
    //           title: 'Option 1',
    //         },
    //       ],
    //     },
    //     {
    //       title: 'Item 2',
    //       key: 'item-2',
    //       options: [
    //         {
    //           key: 'option-3',
    //           title: 'Option 3',
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      key: "notifications",
      icon: <NotificationIcon />,
      path: "home/notifications",
      translate: translate("organisms").sidebar.notifications,
      active: true,
      active: roles?.notifications?.read,
    },
    {
      key: "feed",
      icon: <FeedIcon />,
      path: "home/feed",
      translate: validateLaunchSA()
        ? translate("organisms").sidebar.programs
        : translate("organisms").sidebar.feed,
      active: roles?.news?.read,
    },
    {
      key: "resources",
      icon: <TaskIcon />,
      path: "home/resources",
      translate: translate("organisms").sidebar.resources,
      // active: true,
      active: roles?.resources?.read,
    },
    // {
    //   key: 'mentions',
    //   icon: <MentionIcon />,
    //   path: 'mentions',
    //   translate: translate('organisms').sidebar.mentions,
    // },
    // {
    //   key: "Settings",
    //   icon: <SettingOutlined />,
    //   path: "home/settings",
    //   menuClass: "events",
    //   translate: translate("organisms").sidebar.settings,
    //   active: true,
    //   // active: roles?.settings?.read,
    // },
    // {
    //   key: "logout",
    //   icon: <LogoutIcon />,
    //   path: "verify",
    //   search: "?logout=true",
    //   menuClass: "sider-menu-logout",
    //   translate: translate("organisms").sidebar.logout,
    // },
  ];

  return (
    // <Fragment>
    <Sider
      style={{
        height: window.innerHeight,
        backgroundColor: userSettings?.sibebar_color ?? "black",
      }}
      className="sider"
      breakpoint="md"
      collapsedWidth="0"
    >
      {loading && (
        <Skeleton.Input style={{ width: 200 }} active={true} size={1000} />
      )}

      <Menu
        style={{
          height: window.innerHeight,
          backgroundColor: userSettings?.sibebar_color ?? "black",
        }}
        defaultSelectedKeys={"1"}
        defaultOpenKeys={["1"]}
        mode="inline"
        theme="dark"
        className="sider-menu"
      >
        {/* <div className="image-container">
            <Image className="image" src={logo} alt="logo" />
          </div> */}
        <div className="select-container">
          {permissions?.projects?.read ? (
            <ProjectSelect />
          ) : (
            <Image
              src={validateLaunchSA() ? launchLogo : IrysLogo}
              style={{ width: "75%" }}
              preview={false}
            />
          )}
        </div>

        {menuRoutes.map((menu) =>
          menu.type !== "submenu" ? (
            menu.active && (
              <Menu.Item
                className={menu.menuClass || "sider-menu-item"}
                key={`menu-${menu.key}`}
                icon={menu.icon}
              >
                <Link
                  to={{
                    pathname: `/${menu.path}`,
                    search: menu.search,
                  }}
                >
                  <span>{menu.translate}</span>
                </Link>
              </Menu.Item>
            )
          ) : (
            <SubMenu
              key={`menu-${menu.key}`}
              title={
                <span>
                  {menu.titleIcon}
                  <span>{menu.titleTranslate}</span>
                </span>
              }
              className="sider-menu-item"
            >
              {menu.itemGroup.map((item) => (
                <Menu.ItemGroup
                  className="sider-menu-item"
                  key={item.key}
                  title={item.title}
                >
                  {item.options.map((option) => (
                    <Menu.Item className="sider-menu-item" key={option.key}>
                      {option.title}
                    </Menu.Item>
                  ))}
                </Menu.ItemGroup>
              ))}
            </SubMenu>
          )
        )}
        <Menu.Item
          className="sider-menu-logout"
          key={784}
          onClick={() => window.location.replace(`/verify?logout=true`)}
        >
          <LogoutIcon /> {translate("organisms").sidebar.logout}
        </Menu.Item>
        {/* <div
            className="sider-menu-logout"
            onClick={() => window.location.replace(`/verify?logout=true`)}
          >
            <LogoutIcon /> logout
          </div> */}
      </Menu>
    </Sider>
  );
};
// const MemoizedSideBar = React.memo(SideBar);
export default SideBar;
