import React, { useContext, useState } from "react";
import { Layout, Divider, Tag } from "antd";
import { StandardInput } from "components/atoms/inputs";
import { Text } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import LocationCard from "components/molecules/LocationCard";
import "./LocationAutoSuggest.scss";

const LocationAutoSuggest = ({
  setLocation,
  zipLocations,
  setZipLocations,
  handleZipLocationDelete,
}) => {
  const { translate } = useContext(I18nContext);
  const [active, setActive] = useState("city");
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: { types: ["(regions)"] },
    debounce: 300,
  });
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();

    getGeocode({ address: description })
      .then((results) => {
        if (active === "city") {
          return getLatLng(results[0]);
        } else {
          return results[0];
        }
      })
      .then(({ lat, lng, ...results }) => {
        if (active === "city") {
          setLocation({ lat, lng });
        } else {
          const postalCode = results.address_components.filter(
            (result) => result.types.filter((type) => type === "postal_code")[0]
          )[0].long_name;
          setZipLocations((prev) => [...prev, postalCode]);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };
  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <LocationCard
          key={id}
          location={{
            main_text: main_text,
            secondary_text: secondary_text,
          }}
          onClick={handleSelect(suggestion)}
        />
      );
    });
  return (
    <Layout className="general-settings-map-suggest">
      <Text
        text={translate("pages").settings.generalSettings.title.coverageArea}
        className="coverage-text"
      />
      <div className="location-select">
        <StandardInput
          placeholder={
            translate("pages").settings.generalSettings.placeholder.addLocation
          }
          suffixIcon={<MapSearchType active={active} setActive={setActive} />}
          className="location-input"
          value={value}
          disabled={!ready}
          onChange={handleInput}
        />
        {status == "OK" && (
          <div className="location-suggestions">{renderSuggestions()}</div>
        )}
      </div>
      <div className="zip-codes">
        {active === "zipCode" &&
          zipLocations.map((zipLocation, index) => (
            <Tag closable onClose={(e) => handleZipLocationDelete(e,index)}>
              {zipLocation}
            </Tag>
          ))}
      </div>
    </Layout>
  );
};

export const MapSearchType = ({ active, setActive }) => {
  const { translate } = useContext(I18nContext);
  const handleTypeClick = (type) => {
    setActive(type);
  };
  return (
    <div className="map-search-type-wrapper">
      <Text
        text={translate("pages").settings.generalSettings.title.city}
        className={`${active === "city" && "active"}`}
        onClick={() => handleTypeClick("city")}
      />
      <Divider type="vertical" />
      <Text
        text={translate("pages").settings.generalSettings.title.zipCode}
        className={`${active === "zipCode" && "active"}`}
        onClick={() => handleTypeClick("zipCode")}
      />
    </div>
  );
};
export default LocationAutoSuggest;
