import Analytics from "pages/Analytics";
import Demo from "pages/Analytics/Demo";
import AnalyticsDashboardEditor from "pages/Analytics/Editor";

const prefix = "/analytics";

const routes = [
  {
    path: `${prefix}`,
    component: Analytics,
    exact: true,
  },
  {
    path: `${prefix}/demo`,
    component: Demo,
    exact: true,
  },
  {
    path: `${prefix}/new`,
    component: AnalyticsDashboardEditor,
    exact: true,
  },
  {
    path: `${prefix}/:id`,
    component: AnalyticsDashboardEditor,
    exact: true,
  },
];

export default routes;
