import React, { useState, useContext } from "react";
import "./ViewEventContainer.scss";
import { Text } from "components/atoms/texts";
import {
  CommentIcon,
  CalendarIcon,
  NavigatorIcon,
} from "components/atoms/icons";
import { ClockCircleOutlined } from "@ant-design/icons";
import Map from "components/atoms/map";
import { PrimaryButton } from "components/atoms/controls";
import GreenMapPin from "assets/img/illustrations/mappin-green.svg";
import { Modal } from "antd";
import GreenTick from "assets/img/icons/green-tick.svg";
import { ReactComponent as WorldIcon } from "assets/svgs/world.svg";
import { ReactComponent as EventbriteIcon } from "assets/svgs/eventbrite.svg";
import { Image } from "antd";
import { I18nContext } from "utils/i18n/locale";
import { useHistory } from "react-router-dom";
import Moment from 'moment-timezone';

const ViewEventContainer = (props) => {
  const [saveModal, setSaveModal] = useState(false);
  const { evento } = props;
  const { translate } = useContext(I18nContext);
  const { push } = useHistory();

  const changeTimeZone = (dateData, timeZoneData, type) => {
    console.log('dateData', dateData);
    console.log('timeZoneData', timeZoneData);

    const timeToConvert = Moment.utc(dateData).tz(timeZoneData, true);
    if (type == 0) {
      return timeToConvert.local().format('HH:mm A');
    } else {
      return timeToConvert.local().format('DD MMM YYYY');
    }
  };

  const saveEvent = () => {
    setSaveModal(true);
    setTimeout(() => {
      setSaveModal(false);
      push("/client/events");
    }, 2000);
  };

  return (
    <div className="view-events-container">
      <Modal
        visible={saveModal}
        centered
        getContainer={"#modalMount"}
        mask={true}
        onCancel={() => setSaveModal(false)}
        className="success-modal"
        closable={false}
        cancelButtonProps={false}
      >
        <Image src={GreenTick} preview={false} />
        <Text
          text={translate("client").pages.events.viewEvents.saveTopic}
          className="title-message"
        />
        <p className="message">
          {translate("client").pages.events.viewEvents.saveMessage}
        </p>
      </Modal>
      <div id="modalMount" className="modal-container" />
      <div className="about-container">
        <Text
          text={translate("client").pages.events.viewEvents.about}
          className="title"
        />
        <Text text={evento ? evento.description : ""} className="desc" />
      </div>
      <div className="topic-container">
        <Text
          text={translate("client").pages.events.viewEvents.topicOfInterest}
          className="title"
        />
        <div className="topic-desc">
          <CommentIcon color={"#5C5C5C "} className="icon" />
          <Text text={evento ? evento.event_topic.name : ""} className="text" />
        </div>
      </div>
      <div className="date-container">
        <Text
          text={translate("client").pages.events.viewEvents.date}
          className="title"
        />
        <div className="date-desc">
          <CalendarIcon color={"#5C5C5C "} className="icon" />
          <Text
            text={evento ? changeTimeZone(evento.start_at,evento.timezone ,1) : ""}
            className="text"
          />
        </div>
      </div>
      <div className="time-container">
        <Text
          text={translate("client").pages.events.viewEvents.time}
          className="title"
        />
        <div className="time-desc">
          <ClockCircleOutlined className="icon" />
          <Text
            text={evento ? changeTimeZone(evento.start_at,evento.timezone ,0) : ""}
            className="text"
          />
        </div>
      </div>
      <div className="location-container">
        <Text text={translate("client").pages.events.viewEvents.location} className="title" />
        <div className="location-desc">
          <Text text={evento?.address} className="text" />
        </div>
        <Map
          height="180px"
          mapTypeControl={false}
          className="notification-map"
          customPin={GreenMapPin}
          location={{lat:evento?.latitude,lng:evento?.longitude}}
        />
       
      </div>
      <div
        className="about-container"
        onClick={() => {
          window.open(evento ? evento.website_link : "", "_blank");
        }}
      >
        <div className="website-container">
          <Text text={"Website"} className="title" />
          <div className="website-desc">
            <WorldIcon />
            <Text text={evento ? evento.website_link : ""} className="desc" />
          </div>
        </div>
      </div>

      {evento.eventbrite_url ? (
        <div
          className="about-container"
          onClick={() => {
            window.open(evento ? evento.eventbrite_url : "", "_blank");
          }}
        >
          <div className="website-container">
            <Text text={"Eventbrite Link"} className="title" />
            <div className="website-desc">
              <EventbriteIcon />
              <Text
                text={evento ? evento.eventbrite_url : ""}
                className="desc"
              />
            </div>
          </div>
        </div>
      ) : null}

    
    </div>
  );
};

export default ViewEventContainer;
