import React, {useContext} from 'react'
import PieGraph, {TYPE} from "components/molecules/Graphs/Pie";
import {I18nContext} from "utils/i18n/locale";

const TaskPriority = () => {
	const { translate } = useContext(I18nContext);

	const PIE_TASK_PRIORITY = [
		{
			"id": "low",
			"label": translate("pages").analytics.low,
			"value": 233,
			"color": "#00B999"
		},
		{
			"id": "medium",
			"label": translate("pages").analytics.medium,
			"value": 466,
			"color": "#FFB547"
		},
		{
			"id": "high",
			"label": translate("pages").analytics.high,
			"value": 298,
			"color": "#F44336"
		}
	]

	return (
		<PieGraph
			height="100%"
			type={TYPE.HALF}
			title={translate("pages").analytics.taskPriority}
			data={PIE_TASK_PRIORITY} />
	)
}

export default TaskPriority;
