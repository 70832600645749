import React, { useContext, useEffect, useState } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import { Text } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";
import "./AddSurveyQuestion.scss";
import { CreateElementInput } from "components/atoms/inputs";
import { useSelector } from "react-redux";

const AddSurveyQuestion = ({
  onClick,
  showEdit,
  onTitleChange,
  questionTitle,
}) => {
  const userSettings = useSelector((state) => state.projects.settings);
  const [settings, setSettings] = useState(userSettings);
  useEffect(() => {
    setSettings(userSettings);
  }, [userSettings]);
  const { translate } = useContext(I18nContext);
  return (
    <div className="add-question-action">
      <PlusCircleFilled
        className="add-question-icon"
        onClick={onClick}
        style={{ color: settings?.sibebar_color }}
      />
      {!showEdit ? (
        <Text
          text={translate("pages").surveyEditor.title.addQuestion}
          onClick={onClick}
        />
      ) : (
        <div className="add-question-input-wrapper">
          <CreateElementInput
            invisible
            placeholder={translate("pages").surveyEditor.title.addQuestion}
            className="add-question-input"
            onChange={onTitleChange}
            defaultValue={questionTitle}
          />
        </div>
      )}
    </div>
  );
};

export default AddSurveyQuestion;
