import { Row, Col } from "antd";
import DirectoryHeader from "components/molecules/DirectoryHeader";
import EmptyDirectoryCard from "components/molecules/EmptyDirectoryCard";
import DirectoryCardTemplate from "components/templates/DirectoryCardTemplate";
import DirectoryListTemplate from "components/templates/DirectoryListTemplate";
import React, { useContext, useState } from "react";
import { I18nContext } from "utils/i18n/locale";
import baseImg from "assets/img/Rectangle34.png";
import "./directory.scss";

const Directory = () => {
  const { translate } = useContext(I18nContext);
  const data = Array(17)
    .fill(0)
    .map(() => ({
      name: "Directory1",
      createdAt: "11/02/2020  4:30 PM",
      lastEdited: "11/02/2020  4:30 PM",
      image: baseImg,
    }));

  const [view, setView] = useState("list");
  return (
    <>
      <DirectoryHeader
        title={translate("pages").directory.title.businessDirectory}
        withActions={data.length > 0 ? true : false}
        onChangeLayout={setView}
        layout={view}
      />
      {data < 1 ? (
        <Row
          className="directory-placeholder-row"
          justify="center"
          align="middle"
        >
          <Col lg={12} md={18} sm={24} xxl={10}>
            <EmptyDirectoryCard />
          </Col>
        </Row>
      ) : (
        <>
          {view === "list" ? (
            <DirectoryListTemplate data={data} />
          ) : (
            <DirectoryCardTemplate data={data} />
          )}
        </>
      )}
    </>
  );
};

export default Directory;
