import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./KanbanCard.scss";
import {
  AddUserIcon,
  CheckIcon,
  MenuThreeDotsIcon,
} from "components/atoms/icons";
import { Col, Row, Dropdown } from "antd";
import FlagsMenu from "components/molecules/FlagsMenu";

const KanbanCard = ({ description, title, typename, date, ...props }) => {
  const [expanded, setExpanded] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);

  const onOptionSelected = (option) => {
    setOpenDropdown(!openDropdown);
  };

  const detectClickListener = (e) => {
    if (
      !ReactDOM.findDOMNode(this) ||
      !ReactDOM.findDOMNode(this).contains(e.target)
    ) {
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", detectClickListener, true);
  }, []);

  return (
    <Col
      style={{ marginBottom: 20 }}
      className="kanban-card-container"
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      {...props}
    >
      <div>
        <div className="title">
          <b> {typename}</b>
        </div>
        <div className="typename">{title}</div>
        <div className="description">{description}</div>
      </div>
      <Row className="footer" justify="space-between">
        <div>{new Date(date).toLocaleDateString()}</div>
        {/* TODO: handle dato to kanban card menus and icons  */}
        {/* <div>
          <AddUserIcon />
        </div> */}
      </Row>
      {/* {(expanded || openDropdown) && (
        <Row
          onClick={(e) => e.stopPropagation()}
          className="actions-container"
          justify="end"
        >
          <CheckIcon />
          <Dropdown
            visible={openDropdown}
            overlay={<FlagsMenu onOptionSelected={onOptionSelected} />}
            trigger={["click"]}
            placement="bottomLeft"
            arrow
          >
            <MenuThreeDotsIcon onClick={() => setOpenDropdown(!openDropdown)} />
          </Dropdown>
        </Row>
      )} */}
    </Col>
  );
};

export default KanbanCard;
