import React, {useContext, useState} from "react";
import "./CreateFlagComment.scss"
import {Row, Col, Mentions, Avatar} from "antd";
import {EditIcon} from "components/atoms/icons";
import {I18nContext} from "utils/i18n/locale";
import {PrimaryButton} from "components/atoms/controls";

// Missing create flag comment 

const { Option } = Mentions;

const CreateFlagComment = ({ onSubmit, onSelect, options = [] }) => {
  const { translate } = useContext(I18nContext);
  const [isInputFocused, setInputFocused] = useState(false);
  const [comment, setComment] = useState("");

  return <Col className="comments-create-container">
    <Row className="header" align="middle">
      <EditIcon />
      <b>
        {translate("pages").flags.flagDetails.addComments}
      </b>
    </Row>
    <Mentions
      className="comment-input"
      style={{ width: '100%' }}
      onChange={setComment}
      onSelect={onSelect}
      value={comment}
      autoSize={isInputFocused ? {minRows: 3, maxRows: 6} : false}
      onBlur={() => setInputFocused(false)}
      onFocus={() => setInputFocused(true)}
      placeholder={`${translate("pages").flags.flagDetails.comments}...`}
    >
      {
        options.map(({value, name, image}) => <Option key={value} value={value}><Avatar size="small" src={image}/> {name}</Option>)
      }
    </Mentions>
    {
      isInputFocused && <Row justify="end">
        <PrimaryButton cssClassName="send-btn" onClick={() => onSubmit(comment)} title={translate("pages").flags.flagDetails.send} />
      </Row>
    }
  </Col>
};
export default CreateFlagComment;
