import api from "config/axios/axios.api.config";

const prefix = "reports";

// const getFlags = () => api.get(`${prefix}`);

const getFlags = (id) => api.get(`${prefix}/projects/${id}/`);

const createReport = (data) => api.postJson(`${prefix}/widget`, data);

// const getReports = (id) => api.get(`projects/${id}/${prefix}`, id);

const getFlag = (id) => api.get(`${prefix}/${id}`);

// const getFlagCategories = () => api.get(`category`);

const getFlagCategories = (id) =>
    api.get(`${prefix}/projects/${id}/categories`);

const getTypenames = (id) => api.get(`${prefix}/categories/${id}/typenames`);

const createFlag = (data) => api.postJson(`${prefix}`, data);

const approveFlag = (id, data) => api.put(`${prefix}/${id}/approve`, data);

const showFlagOnFeed = (item) =>
    api.put(`${prefix}/${item.id}/show`, { show_in_app: item.show_in_app });

const rejectFlag = (id, data) => api.put(`${prefix}/${id}/reject`, data);

const updateFlag = (id, data) => api.put(`${prefix}/${id}/`, data);

const getDepartments = (id) => api.get(`projects/${id}/departments`);

const getAssignedUsers = (id) => api.get(`workers/projects/${id}`);

export default {
    getFlags,
    getFlag,
    createFlag,
    createReport,
    getFlagCategories,
    getTypenames,
    approveFlag,
    rejectFlag,
    getDepartments,
    getAssignedUsers,
    updateFlag,
    showFlagOnFeed,
};
