import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";

export const SaveMedia = (uploadName, resizedMedia) => {
    return Storage.put(uploadName, resizedMedia);
};
export const SaveMediaAsync = async (uploadName, resizedMedia) => {
    return new Promise((resolve, reject) => {
        Storage.put(uploadName, resizedMedia).then((result) => {
            resolve(result.key);
        });
    });
};

export const DeleteMedia = (key) => {
    return Storage.remove(key);
};

export const signIn = async ({ email, password }) => {
    try {
        const user = await Auth.signIn(email, password);
        return user;
    } catch (error) {
        throw error;
    }
};

export const CompleteNewPasswordAction = async (user, newPassword) => {
    try {
        const userAuthenticated = await Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword, // the new password
            { name: "irys" }
        );
        return userAuthenticated;
    } catch (error) {
        throw error;
    }
};

export const signOut = async () => {
    try {
        await Auth.signOut();
    } catch (error) {
        console.error("error signing out: ", error);
    }
};

export const sendRecoveryMail = async (email) => {
    // Send confirmation code to user's email
    try {
        const recover = await Auth.forgotPassword(email);
        return recover;
    } catch (error) {
        throw error;
    }
};

export const verifyRecoveryCode = async (email, { code, password }) => {
    // Collect confirmation code and new password, then
    try {
        const changed = await Auth.forgotPasswordSubmit(email, code, password);
        return changed;
    } catch (error) {
        throw error;
    }
};

export const signUp = async (email, name, password) => {
    const username = email;
    try {
        const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
                email, // optional
                name, // optional - E.164 number convention
                // other custom attributes
            },
        });
        return user;
    } catch (error) {
        throw error;
    }
};

export const confirmSignUp = async (username, code) => {
    try {
        const resp = await Auth.confirmSignUp(username, code);
        return resp;
    } catch (error) {
        console.error("error confirming sign up", error);
        return error;
    }
};

export const resendConfirmationCode = async (username) => {
    try {
        await Auth.resendSignUp(username);
    } catch (err) {
        console.error("error resending code: ", err);
    }
};
