import React, { useContext, useState, useEffect } from "react";
import "./SurveyResponses.scss";
import { Col, Pagination, Row } from "antd";
import { Text } from "components/atoms/texts";
import { StandardInput } from "components/atoms/inputs";
import {
  SearchOutlined,
  VerticalRightOutlined,
  VerticalLeftOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { FilterIcon } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import SurveyResponseBody from "components/organisms/SurveyResponseBody";
import Calendar from "components/molecules/Calendar";
import { QuestionContext } from "context/SurveyEditorContext";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyResponses } from "redux/slices/surveys";
import { CSVLink, CSVDownload } from "react-csv";
import { PrimaryButton } from "components/atoms/controls";
import Table, { HEADER_TRANSLUCENT } from "components/molecules/Table";
import { dateTimeFormat } from "utils/common";
import moment from "moment";
import { useHistory } from "react-router";
import SearchBar from "components/atoms/search";
const HOUR_FORMAT = "HH:mm:ss";

const SurveyResponses = ({ ...props }) => {
  const history = useHistory();
  const { translate } = useContext(I18nContext);
  const { responses, surveyId, questions, newSurveyTitle } =
    useContext(QuestionContext);
  const [currentPageResponses, setCurrentPageResponses] = useState([]);
  const [currentPageResponsesAux, setCurrentPageResponsesAux] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [searchRef, setSearchRef] = useState(0);
  // const [paginationIndex, setPaginationIndex] = useState({ start: 0, end: 11 });
  // const [currentPage, setCurrentPage] = useState(1);
  // const handlePaginationChange = (page, pageSize) => {
  //   let start = page === 1 ? 0 : page * pageSize - 9;
  //   const end = start + 10;
  //   setCurrentPage(page);
  //   setPaginationIndex({ start, end });
  //   start = start === responses.length ? start - 1 : start;
  //   setCurrentPageResponses(responses?.slice(start, end));
  // };
  const dispatch = useDispatch();
  const {
    surveys: { surveyResponsesFetched, surveyResponses },
  } = useSelector((state) => state);
  const urlSplit = window.location.href.split("/");
  const urlId = urlSplit[urlSplit.length - 1];

  useEffect(() => {
    dispatch(getSurveyResponses(surveyId ?? urlId));
  }, []);

  useEffect(() => {
    setCurrentPageResponses(surveyResponses);
    setCurrentPageResponsesAux(surveyResponses);
  }, [surveyResponsesFetched]);

  const handleSearch = (answers, questionId) => {
    if (Array.isArray(answers)) {
      for (const answer of answers) {
        if (parseInt(Object.keys(answer.response)[0]) === questionId) {
          return answer.response[questionId].length <= 1
            ? answer.response[questionId][0]
            : answer.response[questionId];
        }
      }
    }
  };

  const getCompletionTime = (arrayData) => {
    let time = 0;
    if (arrayData?.answer?.length > 0) {
      for (const answer of arrayData.answer) {
        if (answer.completion_time) {
          time += answer.completion_time;
        }
      }
      return time;
    }
  };

  let userData = {};
  const data = [
    ...surveyResponses.map((response, idx) => {
      userData = {};
      userData.email = response.user.email ?? "Anonymous";
      userData["Completion time(seconds)"] = getCompletionTime(response);
      userData["Completion Date"] = response.created_at;

      for (const question of questions) {
        userData[question.title] =
          handleSearch(response.answer, question.id) !== null
            ? handleSearch(response.answer, question.id)
            : "";
      }

      return userData;
    }),
  ];

  const handleSearchEmail = (query, isEmpty) => {
    let result = currentPageResponsesAux.filter(
      (n) =>
        n.user?.email?.toLowerCase().includes(query.toLowerCase()) ||
        n.user?.name.toLowerCase().includes(query.toLowerCase())
    );
    if (searchRef === result.length) {
    } else {
      if (result.length <= 0) {
        setCurrentPageResponses([]);
      } else {
        if (isEmpty) {
          setCurrentPageResponses(currentPageResponsesAux);
        } else {
          setCurrentPageResponses(result);
        }
      }
    }
    setSearchRef(result.length);
  };

  const handleRedirect = (citizen) => {
    history.push({
      pathname: "/home/user-list/user-profile",
      state: { citizen },
    });
    // window.location.assign(`/home/user-list/user-profile`);
  };

  return (
    <div className="survey-responses-wrapper">
      {/* <Row justify="end">
        <Col>
          <CSVLink
            data={data}
            filename={`${newSurveyTitle ?? "responses"}.csv`}
          >
            {" "}
            <PrimaryButton
              title={translate("pages").surveyEditor.title.exportBtn}
              cssClassName="save-btn"
            />
          </CSVLink>
        </Col>
      </Row> */}
      <Row
        className="response-header"
        gutter={[0, 16]}
        justify="start"
        align="middle"
      >
        <Col xs={24} lg={8}>
          <Text
            text={translate("pages").surveyEditor.title.individualResponses}
            className="response-header-text"
          />
        </Col>
        {/* TODO:Add filters and searchbox */}
        <Col xs={24} lg={11} className="response-header-filter">
          {/* <div className="calendar-filter-wrapper">
            <FilterIcon onClick={() => setShowCalendar(!showCalendar)} />
            {showCalendar && (
              <Calendar
                handleCancelClick={() => setShowCalendar(false)}
                handleApplyClick={() => setShowCalendar(false)}
              />
            )}
          </div> */}
          <SearchBar
            // className="project-search-bar"
            placeholder={
              translate("pages").surveyEditor.placeholder.searchByMail
            }
            handleSearch={(item) => handleSearchEmail(item)}
          />
          {/* <StandardInput
            prefixIcon={<SearchOutlined />}
            placeholder={
              translate("pages").surveyEditor.placeholder.searchByMail
            }
          /> */}
        </Col>
        <Col xs={24} lg={2} offset={1}>
          <CSVLink
            data={data}
            filename={`${newSurveyTitle ?? "responses"}.csv`}
          >
            {" "}
            <PrimaryButton
              title={translate("pages").surveyEditor.title.exportBtn}
              cssClassName="big-button"
            />
          </CSVLink>
        </Col>
      </Row>
      <Table
        classes="table-normal-header user-list-table"
        scroll={{ x: true }}
        pagination={true}
        options={{
          collapsable: true,
          // badgeColor: "red",
          addOption: "some option",
          onAddPressed: () => console.log("add pressed"),
        }}
        loading={!surveyResponsesFetched}
        showPagination={true}
        headerType={HEADER_TRANSLUCENT}
        items={currentPageResponses}
        // onClick={(e) =>
        //   history.push({
        //     pathname: `/home/surveys/response/${e.id}`,
        //     responseData: e,
        //     completionTime: getCompletionTime(e),
        //   })
        // }
        columns={[
          {
            title: translate("pages").surveyEditor.title.email,
            dataIndex: "user",
            render: (item, value, i) => (
              <div
                onClick={() => {
                  history.push({
                    pathname: `/home/surveys/response/${value.id}`,
                    responseData: value,
                    completionTime: getCompletionTime(value),
                  });
                }}
              >
                {item?.email}
              </div>
            ),
          },
          {
            title: "User",
            dataIndex: "user",
            render: (item, value) => (
              <span
                style={{
                  color: " #0CAFA0",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleRedirect(value);
                }}
              >
                <UserOutlined />
                <u style={{ marginLeft: "5px" }}>
                  <span>{item?.name}</span>
                </u>
              </span>
            ),
            align: "center",
          },
          {
            title: translate("pages").surveyEditor.title.completionTime,
            // dataIndex: "department",
            render: (item, value) => (
              <div
                onClick={() => {
                  history.push({
                    pathname: `/home/surveys/response/${value.id}`,
                    responseData: value,
                    completionTime: getCompletionTime(value),
                  });
                }}
              >
                {getCompletionTime(item)
                  ? moment
                      .utc(getCompletionTime(item) * 1000)
                      .format(HOUR_FORMAT)
                  : "-"}
              </div>
            ),
            align: "center",
            width: 180,
          },
          {
            title: translate("pages").surveyEditor.title.completionDate,
            dataIndex: "created_at",
            render: (item, value) => (
              <div
                onClick={() => {
                  history.push({
                    pathname: `/home/surveys/response/${value.id}`,
                    responseData: value,
                    completionTime: getCompletionTime(value),
                  });
                }}
              >
                {dateTimeFormat(item?.created_at)}
              </div>
            ),
            width: 300,
            align: "center",
          },
        ]}
      />
      {/* <SurveyResponseBody responses={currentPageResponses} {...props} />
      <div className="survey-responses-pagination">
        <VerticalRightOutlined
          className={`${
            currentPage === 1
              ? "paginated-button-inactive"
              : "paginated-button-active"
          }`}
          onClick={() =>
            surveyResponses?.length > 10
              ? handlePaginationChange(1, 10)
              : console.log("Not Allowed")
          }
        />
        <Pagination
          current={currentPage}
          size="small"
          pageSize={10}
          total={surveyResponses.length}
          onChange={handlePaginationChange}
          showSizeChanger={false}
        />
        <VerticalLeftOutlined
          className={`${
            currentPage === Math.ceil(surveyResponses.length / 10)
              ? "paginated-button-inactive"
              : "paginated-button-active"
          }`}
          onClick={() =>
            surveyResponses?.length > 10
              ? handlePaginationChange(
                  Math.ceil(surveyResponses.length / 10),
                  10
                )
              : console.log("Not Allowed")
          }
        />
      </div> */}
    </div>
  );
};

export default SurveyResponses;
