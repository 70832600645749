import React, { useContext, useState, useEffect } from "react";
import { Divider, Layout, Row } from "antd";
import { PrimaryButton } from "components/atoms/controls";
import AddSurveyQuestion from "components/molecules/AddSurveyQuestion";
import QuestionSettings from "components/organisms/QuestionSettings";
import SurveyQuestion from "components/organisms/SurveyQuestion";
import SurveyQuestionCreate from "components/organisms/SurveyQuestionCreate";
import SurveyQuestionTypeDropdown from "components/organisms/SurveyQuestionTypeDropdown";
import { I18nContext } from "utils/i18n/locale";
import "./SurveyBuilder.scss";
import { QuestionContext } from "context/SurveyEditorContext";
import { useDispatch, useSelector } from "react-redux";
import {
  createQuestion,
  deleteQuestion,
  getQuestionTypes,
  updateQuestion,
  reOrderQuestion,
} from "redux/slices/questions";
import { unwrapResult } from "@reduxjs/toolkit";
import { Alert } from "components/atoms/outputs";
import { AlertWithfunctions } from "components/atoms/outputs";
import { SpiningPage } from "components/atoms/icons";
import { Text } from "components/atoms/texts";
import { getSurveyQuestions } from "redux/slices/surveys";
import { getCurrentOrganizationId, getCurrentProjectId } from "utils/common";
import ReactDragListView from "react-drag-listview";
const { Content } = Layout;

const SurveyBuilder = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [questionTitle, setQuestionTitle] = useState("");
  const [currentEditIndex, setCurrentEditIndex] = useState(0);
  const [questionCreating, setQuestionCreating] = useState(false);
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const { questions, setQuestions, surveyId } = useContext(QuestionContext);

  const [auxQuestions, setAuxQuestions] = useState(questions);
  const urlSplit = window.location.href.split("/");
  const urlId = urlSplit[urlSplit.length - 1];

  const {
    questions: { questionTypes, questionUpdated, questionTypeError },
    surveys: { surveyQuestionsError, loading },
  } = useSelector((state) => state);
  useEffect(() => {}, [questionUpdated]);

  useEffect(() => {
    setAuxQuestions(questions);
  }, [questions]);

  // useEffect(() => {
  //   console.log("Aux questions changed: ", auxQuestions);
  // }, [auxQuestions]);

  const handleMenuChange = ({ key }) => {
    setShowDropdown(false);
    setQuestions((prev) => [
      ...prev,
      {
        question: questionTitle,
        editMode: true,
        questionType: key,
        surveyId: surveyId ?? urlId,
        settingMode: false,
        required: true,
        description: "",
        created: false,
      },
    ]);
    setCurrentEditIndex(questions.length);
    setEditTitle(false);
  };

  const handleQuestionCreate = (data, index) => {
    let newArr = [...questions];
    const question_type_id = questionTypes.filter(
      (type) => type?.name === newArr[index].questionType
    )[0].id;

    const createData = {
      title: data.question,
      options: data.options || [],
      required: false,
      question_type_id,
      surveyId: surveyId ?? urlId,
      project_id: getCurrentProjectId(),
      organization_id: getCurrentOrganizationId(),
      required: newArr[index].required,
      description: newArr[index].description,
      settings: {},
    };
    if (!newArr[index].created) {
      setQuestionCreating(true);
      dispatch(createQuestion(createData))
        .then(unwrapResult)
        .then((data) => {
          if (data.statusCode >= 500) {
            throw new Error(data.message.en);
          } else {
            return data;
          }
        })
        .then((result) => {
          Alert("success", translate("pages").survey.alerts.questionCreate);
          setQuestionCreating(false);
          newArr[index] = {
            ...newArr[index],
            ...data,
            editMode: false,
            created: true,
            questionId: result.body.id,
          };
          setQuestions(newArr);
          dispatch(getSurveyQuestions(surveyId ?? urlId));
        })
        .catch((err) => {
          setQuestionCreating(false);
          Alert("error", translate("pages").survey.alerts.questionCreateError);
        });
    } else {
      dispatch(updateQuestion({ id: newArr[index].questionId, createData }))
        .then(unwrapResult)
        .then((result) => {
          setQuestionCreating(false);
          Alert("success", translate("pages").survey.alerts.questionUpdate);
          newArr[index] = {
            ...newArr[index],
            ...data,
            editMode: false,
          };
          setQuestions(newArr);
        })
        .catch((err) => {
          setQuestionCreating(false);
          Alert("error", translate("pages").survey.alerts.questionUpdateError);
        });
    }
  };
  const questionDeleted = (...args) => {
    const { newArr, index } = args[0];
    newArr.splice(index, 1);
    setQuestions(newArr);
  };
  const handleAction = (action, index) => {
    let newArr = [...questions];
    if (action === "edit") {
      newArr[index] = { ...newArr[index], editMode: true };
      setQuestions(newArr);
      setCurrentEditIndex(index);
    } else if (action === "duplicate") {
      const copiedQuestion = newArr[index];
      newArr.splice(index, 0, copiedQuestion);
      setQuestions(newArr);
    } else if (action === "delete") {
      const info = {
        title: translate("pages").survey.alerts.delete.question.title,
        text: translate("pages").survey.alerts.delete.question.subtitle,
        icon: "info",
        confirm: translate("pages").survey.alerts.delete.question.buttons
          .confirm,
        successTitle: translate("pages").survey.alerts.delete.success.title,
        successSubtitle: translate("pages").survey.alerts.delete.success
          .subtitle,
        errorTitle: translate("pages").survey.alerts.delete.error.title,
        errorSubtitle: translate("pages").survey.alerts.delete.error.subtitle,
        dispatchFunction: deleteQuestion,
        dispatcher: dispatch,
        destroyFunction: questionDeleted,
        destroyFuncArgs: [{ newArr, index }],
        unwrapResult,
        values: newArr[index].questionId,
      };
      AlertWithfunctions(info);
    } else if (action === "settings") {
      setCurrentEditIndex(index);
      newArr[index] = {
        ...newArr[index],
        settingMode: true,
        prevMode: newArr[index].editMode,
        editMode: false,
      };
      setQuestions(newArr);
    }
  };

  const handleSettingsActions = (actionKey, index, value) => {
    let newArr = [...questions];
    newArr[index] = { ...newArr[index], [actionKey]: value };
    setQuestions(newArr);
  };

  const handleSettingsClose = (index, prevMode) => {
    let newArr = [...questions];
    newArr[index] = {
      ...newArr[index],
      editMode: prevMode,
      settingMode: false,
    };
    setQuestions(newArr);
  };

  const handleShowDropdown = () => {
    setShowDropdown(!showDropdown);
    setEditTitle(!editTitle);
  };

  const handleRetry = () => {
    if (surveyQuestionsError) {
      dispatch(getSurveyQuestions(surveyId ?? urlId));
    }
    if (questionTypeError) {
      dispatch(getQuestionTypes());
    }
  };
  useEffect(() => {}, [surveyQuestionsError, questionTypeError]);

  const onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area

    // const items = auxQuestions;
    // console.log("Ref: ", questionRef);
    // const item = auxQuestions[fromIndex];

    const orderedQuestions = [...auxQuestions];
    const item = orderedQuestions.splice(fromIndex, 1)[0];
    orderedQuestions.splice(toIndex, 0, item);

    let updateItem = {
      id: item.id,
      order: questions[toIndex].order,
    };

    setQuestions(orderedQuestions);
    dispatch(reOrderQuestion(updateItem))
      .then(unwrapResult)
      .then((result) => {
        // setQuestionCreating(false);
        // setQuestions(orderedQuestions);
        Alert("success", translate("pages").survey.alerts.questionUpdate);
        dispatch(getSurveyQuestions(surveyId ?? urlId));
      })
      .catch((err) => {
        setQuestions(questions);
        // setQuestionCreating(false);
        Alert("error", translate("pages").survey.alerts.questionUpdateError);
      });
  };

  return (
    <div className="survey-builder-container">
      {loading ? (
        <SpiningPage opacity={1} />
      ) : questionTypeError || surveyQuestionsError ? (
        <div className="question-error">
          <Text text={translate("pages").survey.title.errorFetching} />
          <PrimaryButton
            title={translate("pages").survey.title.tryAgain}
            onClick={handleRetry}
          />
        </div>
      ) : (
        <>
          <ReactDragListView
            nodeSelector=".created-question"
            onDragEnd={onDragEnd}
          >
            {loading && <SpiningPage opacity={1} />}
            {questions?.map((question, index) => {
              if (currentEditIndex === index && question.editMode === true) {
                return (
                  <Row className="question-row">
                    <SurveyQuestionCreate
                      questionType={question.questionType}
                      index={index}
                      onCreate={handleQuestionCreate}
                      question={question.question}
                      choices={question.options}
                      handleAction={handleAction}
                      questionCreating={questionCreating}
                      setQuestionCreating={setQuestionCreating}
                    />
                  </Row>
                );
              } else if (
                currentEditIndex === index &&
                question.settingMode === true
              ) {
                return (
                  <Row>
                    <QuestionSettings
                      questionType={question.questionType}
                      index={index}
                      actionState={handleSettingsActions}
                      handleSettingsClose={handleSettingsClose}
                      prevMode={question.prevMode}
                    />
                  </Row>
                );
              } else {
                return (
                  <Row className="created-question">
                    {/* <div> */}
                    <SurveyQuestion
                      question={question}
                      index={index}
                      handleAction={handleAction}
                    />
                    {/* </div> */}
                  </Row>
                );
              }
            })}
          </ReactDragListView>
          <Content>
            <div className="add-survey-action">
              <AddSurveyQuestion
                onClick={handleShowDropdown}
                showEdit={editTitle}
                onTitleChange={(e) => setQuestionTitle(e.target.value)}
                defaultValue={questionTitle}
              />
              {showDropdown && (
                <div className="add-survey-action-dropdown">
                  <SurveyQuestionTypeDropdown onMenuChange={handleMenuChange} />
                </div>
              )}
            </div>
          </Content>
          {/* {questions.length > 0 &&
            questions.filter((question) => question.editMode === false).length >
              0 && (
              <div className="save-all-container">
                <Divider />
                <div className="save-all">
                  <PrimaryButton
                    title={translate("pages").surveyEditor.title.save}
                  />
                </div>
              </div>
            )} */}
        </>
      )}
    </div>
  );
};

export default SurveyBuilder;
