import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";
import ProfileForm from "components/organisms/ProfileForm";
import FollowersPane from "components/organisms/FollowersPane";
import "./ProfileInfoOrganism.scss";
import { useSelector, useDispatch } from "react-redux";
import { getProfile, updateProfile } from "redux/slices/profile";
import { SpiningPage } from "components/atoms/icons";
import LoadErrorLayout from "components/molecules/LoadErrorLayout";
import { unwrapResult } from "@reduxjs/toolkit";
import { Alert } from "components/atoms/outputs";
import { I18nContext } from "utils/i18n/locale";

const ProfileInfo = ({ hideFollower, citizen }) => {
  const { translate } = useContext(I18nContext);
  const hide = true;
  const profile = useSelector((state) => state.profile.profile);
  const loading = useSelector((state) => state.profile.loading);
  const loadingError = useSelector((state) => state.profile.loadingError);

  const [currentUser, setCurrentUser] = useState(
    citizen ? citizen.user : profile
  );

  const dispatch = useDispatch();

  const loadProfile = () => {
    dispatch(getProfile());
  };

  const dispatchUpdateProfile = (data) => {
    dispatch(updateProfile({ ...data }))
      .then(unwrapResult)
      .catch(() => {
        Alert("error", translate("generic").tryAgain);
      });
  };

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    if (!citizen) {
      setCurrentUser(profile);
    }
  }, [profile]);

  return (
    <>
      {loading && <SpiningPage opacity={1} />}
      <Row className="profile-container" gutter={8}>
        {loadingError ? (
          <Col flex="auto" md={24}>
            <LoadErrorLayout onReload={loadProfile} />
          </Col>
        ) : (
          <>
            {!loading && (
              <Col flex="auto">
                <ProfileForm
                  user={currentUser}
                  onUpdate={dispatchUpdateProfile}
                  citizen={citizen}
                />
              </Col>
            )}

            {!hide ||
              (!hideFollower && hideFollower !== undefined && (
                <Col flex="auto">
                  <FollowersPane />
                </Col>
              ))}
          </>
          // }
        )}
        ;
      </Row>
    </>
  );
};
export default ProfileInfo;
