import React from "react";
import {
  VerticalRightOutlined,
  VerticalLeftOutlined,
} from "@ant-design/icons";
import { Pagination } from "antd";
import "./pagination.scss"
const ListPagination = ({data,currentPage,handlePaginationChange, ...props}) => {
  return (
    <div className="pagination-container">
      <VerticalRightOutlined
        className={`${
          currentPage === 1
            ? "paginated-button-inactive"
            : "paginated-button-active"
        }`}
        onClick={() =>
          data?.length > 10
            ? handlePaginationChange(1, 10)
            : console.log("Not Allowed")
        }
      />
      <Pagination
        current={currentPage}
        size="small"
        pageSize={10}
        total={data?.length}
        onChange={handlePaginationChange}
        showSizeChanger={false}
      />
      <VerticalLeftOutlined
        className={`${
          currentPage === Math.ceil(data?.length / 10)
            ? "paginated-button-inactive"
            : "paginated-button-active"
        }`}
        onClick={() =>
          data?.length > 10
            ? handlePaginationChange(Math.ceil(data?.length / 10), 10)
            : console.log("Not Allowed")
        }
      />
    </div>
  );
};

export default ListPagination;
