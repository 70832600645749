import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Divider, Row } from "antd";
import "./KnowledgeBaseDetailCard.scss";
import { Title } from "components/atoms/texts";
import { Text } from "components/atoms/texts";
import { Dropdown } from "components/atoms/controls";
import Menu from "components/atoms/menu";
import { EditIcon, TrashIcon } from "components/atoms/icons";
import Editor from "components/molecules/DraftRichText/index";
import { I18nContext } from "utils/i18n/locale";
import Modal from "components/organisms/KnowledgeDeleteModal";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteKnowledgePost,
  getKnowledgePost,
  handleKnowledgePostData,
} from "redux/slices/knowledge";
import { SpiningPage } from "components/atoms/icons";
import moment from "moment";
import { unwrapResult } from "@reduxjs/toolkit";
import { AlertWithfunctions } from "components/atoms/outputs";
import parse from "html-react-parser";

const KnowledgeBaseDetailCard = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [formatedData, setFormatedData] = useState();
  const knowledgePost = useSelector((state) => state.knowledge.knowledgePost);
  const loading = useSelector((state) => state.knowledge.loading);
  const dispatch = useDispatch();
  const params = useParams();

  const history = useHistory();

  const { translate } = useContext(I18nContext);

  const options = [
    {
      icon: <EditIcon className="users-edit-icon" />,
      key: "edit",
      title: translate("pages").knowledge.detail.editPost,
    },
    {
      icon: <TrashIcon color="#F44336" margintop={1} />,
      key: "delete",
      title: translate("pages").knowledge.detail.deletePost,
      color: "#F44336",
    },
  ];

  const getFormattedData = (rawData) => {
    if (rawData) {
      const parsed_data = JSON.parse(rawData);
      let texts = [];

      for (const block of parsed_data.blocks) {
        if (block.text !== "") texts.push(block.text);
      }
      setFormatedData(texts);
    }
  };
  useEffect(() => {
    dispatch(getKnowledgePost(params?.id));
  }, [params]);

  useEffect(() => {
    getFormattedData(knowledgePost?.content);
    dispatch(
      handleKnowledgePostData({
        knowledge_category_id: knowledgePost?.faq_category_id,
      })
    );
  }, [knowledgePost]);

  const editOrDeletePost = (e) => {
    if (e.key === "delete") {
      // setDeleteModal(true);
      deletePost();
    } else {
      let reduxData = {
        id: knowledgePost.id,
        title: knowledgePost.title,
        content: JSON.parse(knowledgePost.content),
        knowledge_category_id: knowledgePost.faq_category_id,
      };
      dispatch(handleKnowledgePostData(reduxData));
      history.push(`/home/knowledge/${params?.category}/post-editor`);
    }
  };

  const deletePost = () => {
    const info = {
      title: translate("pages").knowledge.alerts.delete.question.title,
      text: translate("pages").knowledge.alerts.delete.question.subtitle,
      icon: "info",
      confirm:
        translate("pages").knowledge.alerts.delete.question.buttons.confirm,
      successTitle: translate("pages").knowledge.alerts.delete.success.title,
      successSubtitle:
        translate("pages").knowledge.alerts.delete.success.subtitle,
      errorTitle: translate("pages").knowledge.alerts.delete.error.title,
      errorSubtitle: translate("pages").knowledge.alerts.delete.error.subtitle,
      dispatchFunction: deleteKnowledgePost,
      dispatcher: dispatch,
      unwrapResult,
      values: params?.id,
      // dispatchOnSuccess:
      history: history,
      goBack: true,
      // path: "/home/events/",
    };
    AlertWithfunctions(info);
    setDeleteModal(false);
    // dispatch(deleteKnowledgePost(params?.id))
    //   .then(unwrapResult)
    //   .then(() => {
    //     SwalAlerts({
    //       title: translate("pages").events.alerts.save.success.title,
    //       text: translate("pages").events.alerts.save.success.subtitle,
    //       type: "success",
    //     });
    //     history.goBack();
    //   })
    //   .catch(() => {
    //     SwalAlerts({
    //       title: translate("pages").events.alerts.save.error.title,
    //       text: translate("pages").events.alerts.save.error.subtitle,
    //       type: "error",
    //     });
    //   });
  };

  return (
    <>
      {loading ? (
        <SpiningPage />
      ) : (
        <>
          {" "}
          <Modal
            translate={translate}
            show={deleteModal}
            closeModal={() => setDeleteModal(false)}
            onOk={deletePost}
          />
          <Card>
            <Row justify="space-between">
              <Col xs={23}>
                <Title
                  level={3}
                  className="knowledge-card-title"
                  align="left"
                  text={knowledgePost?.title}
                />
                {/* <span className="knowledge-card-title">
                  {knowledgePost?.title}
                </span> */}
              </Col>
              <Col xs={1}>
                <Dropdown
                  menu={<Menu onClick={editOrDeletePost} options={options} />}
                  placement={"bottomRight"}
                />
              </Col>
            </Row>
            {knowledgePost.content_html && parse(knowledgePost.content_html)}
            {/* {formatedData?.map((text) => (
              <>
                {" "}
                <span className="knowledge-sub-heading">{text}</span>
                <br />
              </>
            ))} */}
            {/* <span className="knowledge-sub-heading">
              {knowledgePost?.description}
            </span> */}
            {/* <Text
              className="knowledge-sub-heading"
              text={knowledgePost?.description}
            /> */}
            <Divider />
            <div className="updated-time">
              <span>{`Updated ${moment(
                knowledgePost?.last_edit
              ).fromNow()}`}</span>
              {/* <Text
                text={`Updated ${moment(knowledgePost?.last_edit).fromNow()}`}
              /> */}
            </div>
            {/* <Editor readOnly={true} /> */}
          </Card>
        </>
      )}
    </>
  );
};

export default KnowledgeBaseDetailCard;
