import React from "react";
import "./AddChartItem.scss";
import {Text} from "components/atoms/texts";
import {SourceImage} from "components/atoms/media";

const AddChartItem = ({ icon, title, ...props }) => {

  return (
    <div className='add-chart-item-container' {...props}>
      <SourceImage preview={false} imageClassName='chart-icon-img' draggable={false} src={icon}/>
      <Text className="name" text={title}/>
    </div>
  );
};
export default AddChartItem;
