import React from "react";
import "./index.scss";
import { Input } from "antd";
export const CodeInput = (props) => {

  const {validate,data,setData,focus, setFocus} = props;

  const validateBK = (text, index) => {
    text = text.replace(/[^\d.-]/g, "");

    if (!text || text.length === 0) {
      const newData = data.filter((e) => true);
      newData[focus] = "";
      setData(newData);
      return;
    }

    if (text.length !== 1) {
      setFocus(focus + 1);
      const newData = data.filter((e) => true);
      newData[focus] = data[focus] === text[0] ? text[1] : text[0];

      setData(newData);
      return;
    }

    if (text) {
      const newData = data.filter((e) => true);
      newData[focus] = text;
      setData(newData);
    }
  };
  return (
    <div className="validateform">
      <Input
        className={focus === 0 ? "focus" : ""}
        type="text"
        onClick={(e) => {
          setFocus(0);
        }}
        onChange={(e) => {
          validate(e.target.value, 0);
        }}
        value={data[0]}
        name=""
        id=""
      />
      <Input
        className={focus === 1 ? "focus" : ""}
        onChange={(e) => {
          validate(e.target.value, 1);
        }}
        onClick={(e) => {
          setFocus(1);
        }}
        value={data[1]}
        type="text"
        name=""
        id=""
      />
      <Input
        className={focus === 2 ? "focus" : ""}
        onChange={(e) => {
          validate(e.target.value, 2);
        }}
        onClick={(e) => {
          setFocus(2);
        }}
        value={data[2]}
        type="text"
        name=""
        id=""
      />
      <Input
        className={focus === 3 ? "focus" : ""}
        onChange={(e) => {
          validate(e.target.value, 3);
        }}
        onClick={(e) => {
          setFocus(3);
        }}
        value={data[3]}
        type="text"
        name=""
        id=""
      />
      <Input
        className={focus === 4 ? "focus" : ""}
        onChange={(e) => {
          validate(e.target.value, 4);
        }}
        onClick={(e) => {
          setFocus(4);
        }}
        value={data[4]}
        type="text"
        name=""
        id=""
      />
      <Input
        className={focus === 5 ? "focus" : ""}
        onChange={(e) => {
          validate(e.target.value, 5);
        }}
        onClick={(e) => {
          setFocus(5);
        }}
        value={data[5]}
        type="text"
        name=""
        id=""
      />
    </div>
  );
};
