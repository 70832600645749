import React from "react";
import "./KanbanLaneHeader.scss";
import { MenuThreeDotsIcon } from "components/atoms/icons";

const KanbanLaneHeader = ({ title, color = "#00B999" }) => {
  return (
    <div
      className="kanban-lane-header-container"
      style={{ borderTopColor: color, borderTopWidth: 4 }}
    >
      <div>{title}</div>
      {/* TODO: handle data to category header  */}
      {/* <MenuThreeDotsIcon /> */}
    </div>
  );
};

export default KanbanLaneHeader;
