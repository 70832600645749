import React, {
  useContext,
  useState,
  lazy,
  Suspense,
  useEffect,
  useCallback,
} from "react";
import { SpiningPage, RefreshIcon, TrashIcon } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import Nav from "components/templates/Communication/Nav";
import CheckboxSingle from "components/atoms/checkbox";
import "./email.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  archiveEmails,
  getReceivedEmailsReload,
  starrEmail,
} from "redux/slices/communication/emails";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import ParentTabsMenu from "components/atoms/parent-tab";
import { Popover } from "antd";
import { FolderOpenOutlined, SwapOutlined } from "@ant-design/icons";

const CommmunicationMessage = lazy(() =>
  import("components/molecules/CommmunicationMessage")
);
const Email = ({
  emails,
  starredMails,
  archiveMails,
  parentHistory,
  handleMark,
  markAll,
  changeMarkAll,
}) => {
  const [emailList, setEmailList] = useState(emails || []);
  const [starredEmailList, setStarredEmailList] = useState(starredMails || []);
  const [archiveEmailList, setArchiveEmailList] = useState(archiveMails || []);
  const [activeTab, setActiveTab] = useState("inbox");
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const [totalMails, setTotalMails] = useState(240);
  const { translate } = useContext(I18nContext);
  const emailWasCreated = useSelector((state) => state.emails.emailCreated);
  const receivedEmails = useSelector((state) => state.emails.receivedEmails);
  const selectedEmails = useSelector((state) => state.emails.selectedEmails);
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.emails.reloading);

  const POPOVER_CONTENT = {
    ARCHIVE: <span>Click here to archive the selected emails</span>,
    RESTORE: <span>Click here to restore the selected emails</span>,
    REFRESH: <span>Click here to refresh your inbox</span>,
  };

  useEffect(() => {
    setEmailList(receivedEmails.filter((email) => !email.archive));
    setStarredEmailList(
      receivedEmails.filter((email) => email.starred && !email.archive)
    );
    setArchiveEmailList(receivedEmails.filter((email) => email.archive));
  }, [emailWasCreated, receivedEmails]);

  useEffect(() => {
    changeMarkAll(false);
  }, [activeTab]);

  const viewSingleMail = (id) => {
    parentHistory.push(`/home/communications/email/${id}`);
  };

  const updateToggle = (id) => {
    dispatch(starrEmail(id))
      .then(unwrapResult)
      .then((data) => {
        const updatedEmailList = emailList.map((element) =>
          element.id === id
            ? { ...element, starred: !element.starred }
            : element
        );
        setEmailList(updatedEmailList);
        setStarredEmailList(updatedEmailList.filter((email) => email.starred));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const archiveToggle = () => {
    const emailsToArchive = { emails: selectedEmails };
    dispatch(archiveEmails(emailsToArchive))
      .then(unwrapResult)
      .then((data) => {
        const updatedEmailList = emailList.map((element) =>
          selectedEmails.find((item) => item === element.id)
            ? // element.id === id
              { ...element, archive: !element.archive }
            : element
        );
        setEmailList(updatedEmailList.filter((email) => !email.archive));
        setStarredEmailList(
          updatedEmailList.filter((email) => !email.archive && email.starred)
        );
        setArchiveEmailList(
          [
            ...updatedEmailList.filter((email) => email.archive),
            ...archiveEmailList,
          ].sort((a, b) => new Date(b.last_edit) - new Date(a.last_edit))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const recoverToggle = () => {
    const emailsToRecover = { emails: selectedEmails };
    dispatch(archiveEmails(emailsToRecover))
      .then(unwrapResult)
      .then((data) => {
        const updatedEmailList = archiveEmailList.map((element) =>
          selectedEmails.find((item) => item === element.id)
            ? // element.id === id
              { ...element, archive: !element.archive }
            : element
        );
        setEmailList(
          [
            ...updatedEmailList.filter((email) => !email.archive),
            ...emailList,
          ].sort((a, b) => new Date(b.last_edit) - new Date(a.last_edit))
        );
        setStarredEmailList(
          [
            ...updatedEmailList.filter(
              (email) => !email.archive && email.starred
            ),
            ...starredEmailList,
          ].sort((a, b) => new Date(b.last_edit) - new Date(a.last_edit))
        );
        setArchiveEmailList(
          [
            ...updatedEmailList.filter((email) => email.archive),
            // ...archiveEmailList,
          ].sort((a, b) => new Date(b.last_edit) - new Date(a.last_edit))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Old version
  //   <CommmunicationMessage
  //   email={email}
  //   key={`mail-messages-${email.id}`}
  //   viewSingleMail={viewSingleMail}
  //   markAll={markAll}
  //   updateToggle={updateToggle}
  //   // handleMark={handleMark}
  //   // changeMarkAll={changeMarkAll}
  //   // archiveToggle={archiveToggle}
  //   // recoverToggle={recoverToggle}
  //   activeTab={activeTab}
  // />;

  // const CommunicationMessageWithProps = ({ email }) => {
  //   <CommmunicationMessage
  //     email={email}
  //     key={`mail-messages-${email.id}`}
  //     viewSingleMail={viewSingleMail}
  //     markAll={markAll}
  //     updateToggle={updateToggle}
  //     activeTab={activeTab}
  //   />;
  // };

  const tabOptions = [
    {
      title: `${translate("pages").communication.email.inbox.title} (${
        emailList.length
      })`,
      key: "inbox",
      children: emailList.map((email) => (
        <CommmunicationMessage
          email={email}
          key={`mail-messages-${email.id}`}
          viewSingleMail={viewSingleMail}
          markAll={markAll}
          updateToggle={updateToggle}
          // handleMark={handleMark}
          // changeMarkAll={changeMarkAll}
          // archiveToggle={archiveToggle}
          // recoverToggle={recoverToggle}
          activeTab={activeTab}
        />
      )),
    },
    {
      title: `${translate("pages").communication.email.starred.title} (${
        starredEmailList.length
      })`,
      key: "starred",
      children: starredEmailList.map((email) => (
        <CommmunicationMessage
          email={email}
          key={`mail-starred-messages-${email.id}`}
          viewSingleMail={viewSingleMail}
          markAll={markAll}
          updateToggle={updateToggle}
          // handleMark={handleMark}
          // changeMarkAll={changeMarkAll}
          // archiveToggle={archiveToggle}
          // recoverToggle={recoverToggle}
          activeTab={activeTab}
        />
      )),
    },
    {
      title: `${translate("pages").communication.email.archive.title} (${
        archiveEmailList.length
      })`,
      key: "archive",
      children: archiveEmailList.map((email) => (
        <CommmunicationMessage
          email={email}
          key={`mail-archive-messages-${email.id}`}
          viewSingleMail={viewSingleMail}
          markAll={markAll}
          updateToggle={updateToggle}
          // handleMark={handleMark}
          // changeMarkAll={changeMarkAll}
          // archiveToggle={archiveToggle}
          // recoverToggle={recoverToggle}
          activeTab={activeTab}
        />
      )),
    },
  ];

  const leftIcons = [
    {
      component: <CheckboxSingle checked={markAll} />,
      clickAction: handleMark,
    },
    {
      component: (
        <Popover content={POPOVER_CONTENT.REFRESH}>
          <RefreshIcon />
        </Popover>
      ),
      clickAction: () => dispatch(getReceivedEmailsReload()),
    },
    activeTab === "archive"
      ? {
          component: (
            <Popover content={POPOVER_CONTENT.RESTORE}>
              <SwapOutlined />
            </Popover>
          ),
          clickAction: recoverToggle,
          cssClass: "outline",
        }
      : "",
    activeTab !== "archive"
      ? {
          component: (
            <Popover content={POPOVER_CONTENT.ARCHIVE}>
              <FolderOpenOutlined />
            </Popover>
          ),
          clickAction: archiveToggle,
          cssClass: "outline",
        }
      : "",
  ];

  const rightIcons = [];

  const handlePagination = (type) => {
    if (type === "next" && endPage < totalMails) {
      setStartPage(startPage + 10);
      setEndPage(endPage + 10);
    } else if (type === "prev" && startPage > 9) {
      setStartPage(startPage - 10);
      setEndPage(endPage - 10);
    }
  };

  return (
    <Suspense fallback={<SpiningPage opacity={1} />}>
      <Nav
        rightIcons={rightIcons}
        leftIcons={leftIcons}
        totalMail={totalMails}
        pagination={false}
        startPage={startPage}
        endPage={endPage}
        handlePagination={handlePagination}
      />
      <div className="email-list-box">
        <ParentTabsMenu
          options={tabOptions}
          onChange={setActiveTab}
          loading={loading}
        />
      </div>
    </Suspense>
  );
};
export default Email;
