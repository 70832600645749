import React from "react";
import "./MainOptions.scss";
import MainOptionSA from "components/client/components/molecules/MainOptionSA";
import { ReactComponent as Questionaries } from "assets/svg/mobile/questionaries.svg";
import { ReactComponent as ResourcesSvg } from "assets/svg/mobile/resources.svg";
import { ReactComponent as AboutSvg } from "assets/svg/mobile/about.svg";
import { ReactComponent as SurveySvg } from "assets/svgs/survey.svg";
import { ReactComponent as CommunicationSvg } from "assets/svgs/communicationSvgWhite.svg";

const MainOptionsSA = (props) => {
  const { onClick } = props;
  const data = [
    {
      title: "Questionnaires",
      route: "/launchsa/survey/",
      page: "EventsScreen",
      color: "#003F4F",
      icon: <Questionaries />,
    },
    {
      title: "Resources",
      route: "/launchsa/resources/",
      page: "FeedScreen",
      color: "#E7FD6C",
      icon: <ResourcesSvg />,
      param: 1,
    },
    {
      title: "Communication",
      route: "/launchsa/communication",
      page: "FeedScreen",
      color: "#0cafa0",
      icon: <CommunicationSvg />,
      param: 1,
    },
    {
      title: "About LaunchSA",
      route: "/launchsa/about/",
      page: "Survey",
      color: "#F79BB3",
      icon: <AboutSvg />,
    },
  ];

  return (
    <div className="options-container-sa">
      {data.map((item, index) => (
        <MainOptionSA key={index} item={item} onClick={onClick} />
      ))}
    </div>
  );
};

export default MainOptionsSA;
