import React, { useContext, useEffect } from "react";
import { Col, Divider, Row } from "antd";
import SurveyResponseParticipationDetail from "components/organisms/SurveyResponseParticiplationDetail";
import { RadioGroup } from "components/atoms/inputs";
import "./SingleSurveyResponse.scss";
import { Text } from "components/atoms/texts";
import { CheckboxInput } from "components/atoms/inputs";
import { useLocation } from "react-router-dom";
import { QuestionContext } from "context/SurveyEditorContext";

import moment from "moment";
const DATE_FORMAT = "DD/MM/YYYY";
const HOUR_FORMAT = "HH:mm:ss";

// TODO:Verify and validate

const SingleSurveyResponse = () => {
  const historyLocation = useLocation();
  const { questions } = useContext(QuestionContext);
  const { responseData, completionTime } = historyLocation;

  const handleSearch = (answers, questionId) => {
    if (Array.isArray(answers)) {
      for (const answer of answers) {
        if (parseInt(Object.keys(answer.response)[0]) === questionId) {
          return answer.response[questionId].length <= 1
            ? answer.response[questionId][0]
            : answer.response[questionId];
        }
      }
    }
  };

  const data = {
    email: responseData?.user?.email,
    startedOn: moment(responseData?.created_at).format(DATE_FORMAT),
    lastUpdated: moment(
      responseData?.answer[responseData?.answer?.length - 1]?.created_at
    ).format(DATE_FORMAT),
    duration: moment.utc(completionTime * 1000).format(HOUR_FORMAT),
    questions,
  };

  // const data = {
  //   email: "sams@cityflag",
  //   startedOn: "20/03/2020",
  //   lastUpdated: "14/05/2020",
  //   duration: "00:10:20",
  //   radioData: [
  //     { value: "weekly", label: "Weekly", selected: true },
  //     { value: "monthly", label: "Monthly" },
  //     { value: "quarterly", label: "Quarterly" },
  //   ],
  //   userAnswers: [
  //     {
  //       question: "How often do you conduct surveys?",
  //       options: ["weekly", "monthly", "quarterly"],
  //       questionType: "multiChoiceSingle",
  //       selected: "monthly",
  //     },
  //     {
  //       question: "What types of credit card do you have ?",
  //       options: ["visa", "mastercard", "discover", "american express"],
  //       questionType: "multiChoiceMulti",
  //       selected: ["visa", "mastercard"],
  //     },
  //     {
  //       question: "Louis Walsh is older than Simon Cowell?",
  //       options: ["yes", "no"],
  //       questionType: "boolean",
  //       selected: "no",
  //     },
  //     {
  //       question: "What are Multiple Choice Questions?",
  //       options: ["yes", "no"],
  //       questionType: "text",
  //       text: `The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.
  //         The passage experienced a surge in popularity during the 1960s when Letraset used it on their dry-transfer sheets, and again during the 90s as desktop publishers bundled the text with their software. Today it's seen all around the web; on templates, websites, and stock designs. Use our generator to get your own, or read on for the authoritative history of lorem ipsum.`,
  //     },
  //   ],
  // };
  return (
    <Row gutter={[16, 16]} className="single-response-wrapper">
      <Col xs={24} lg={9}>
        <SurveyResponseParticipationDetail details={data} />
      </Col>
      <Col xs={24} lg={15}>
        <div className="survey-single-response-data">
          {data.questions?.map((question, index) => (
            <div key={index} className="survey-answer">
              <Text
                text={question.question}
                className="survey-question-title"
              />
              {question.questionType === "Multiple Choice- Single" ? (
                <div className="survey-question-answers">
                  <RadioGroup
                    isList
                    options={question.options}
                    // defaultValue={question.options[1].title}
                    defaultValue={handleSearch(
                      responseData.answer,
                      question.id
                    )}
                  />
                </div>
              ) : question.questionType === "Dropdown" ? (
                <div className="survey-question-answers">
                  <Text
                    text={handleSearch(responseData.answer, question.id)}
                    className="text-answer"
                  />
                </div>
              ) : question.questionType === "Multiple Choice- Multiple" ? (
                <div className="survey-question-answers">
                  <CheckboxInput
                    options={question.options}
                    defaultValue={handleSearch(
                      responseData.answer,
                      question.id
                    )}
                    isSurvey
                  />
                </div>
              ) : question.questionType === "True or False" ? (
                <div className="survey-question-answers">
                  <RadioGroup
                    isList
                    options={["True", "False"]}
                    defaultValue={
                      handleSearch(responseData.answer, question.id)
                        ? "True"
                        : "False"
                    }
                  />
                </div>
              ) : question.questionType === "Text Field" ? (
                <div className="survey-question-answers ">
                  <Text
                    text={handleSearch(responseData.answer, question.id)}
                    className="text-answer"
                  />
                </div>
              ) : null}
            </div>
          ))}
          {/* <Divider /> */}
        </div>
      </Col>
    </Row>
  );
};

export default SingleSurveyResponse;
