import React, { useContext } from "react";
import { Row, Col } from "antd";
import { Text } from "components/atoms/texts";
import "./UserListName.scss";
import MemoizedAvatar from "../ProjectSelect/Avatar";
import { I18nContext } from "utils/i18n/locale";
import { cloudFrontUrl } from "utils/common";

const UserListName = ({ image, name, online, level, userList, localImage }) => {
  const { translate } = useContext(I18nContext);
  let indicatorColor = "#F44336";
  if (online) {
    indicatorColor = "#4caf50";
  }
  return (
    <Row gutter={20} justify="flex-start" align="middle">
      <Col sx={3}>
        <div className="avatar-container">
          <MemoizedAvatar
            className="avatar"
            size={50}
            radius={50}
            {...(localImage
              ? { localImage }
              : { src: image ? cloudFrontUrl(image) : undefined })}
            {...(!localImage && !image
              ? {
                  avatarName: true,
                  userName: name,
                }
              : // ? {
                //     customPlaceHolder:
                //       "https://image.flaticon.com/icons/png/128/727/727399.png",
                //   }
                "")}
          />
          {!userList && (
            <span
              className="online-indicator"
              style={{ background: indicatorColor }}
            ></span>
          )}
        </div>
      </Col>
      <Col sx={20} className="user-list-name-container">
        <Text className="user-list-name" text={name} />
        {/* {userList && (
          <Text
            className="user-list-level"
            text={`${translate("pages").users.title.level} ${level}`}
          />
        )} */}
      </Col>
    </Row>
  );
};

export default UserListName;
