import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./home.scss";
import MainOptions from "components/client/components/organisms/MainOptions";
import MainOptionsSA from "components/client/components/organisms/MainOptionsSA";

import Header from "components/client/components/molecules/Header";
import TabMenu from "components/client/components/molecules/TabMenu";
import { I18nContext } from "utils/i18n/locale";
import ReportFeed from "components/client/components/organisms/ReportFeed";
import PopularNews from "components/client/components/organisms/PopularNews";
import NewsFeed from "components/client/components/organisms/NewsFeed";
import EventsFeed from "components/client/components/organisms/EventsFeed";
import ProgramFeed from "client/components/organisms/ProgramFeed";

import { apiInstance as axios } from "config/axios/axios.api.config";
import Profile from "assets/img/profileClient.png";

import ProjectInfo from "components/client/components/molecules/ProjectInfo";
import ProvideInput from "components/client/components/organisms/ProvideInput";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { NotificationContextProvider } from "client/context/NotificationContext";
import { Text } from "components/atoms/texts";
import ProjectModal from "components/client/components/organisms/ProjectModal";
import {
  readCitizenProfile,
  readCitizenProjects,
  customClientSet,
} from "redux/slices/Citizen";
import GetAssistance from "client/components/organisms/GetAssistance";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getClientNews } from "redux/slices/news";
import { getReports } from "redux/slices/reports";
import { getClientEvents } from "redux/slices/events";

import { resetGetData } from "redux/slices/projects";
import { useHistory } from "react-router";
import { Auth } from "aws-amplify";

import { Image } from "antd";
import { cloudFrontUrl } from "utils/common";

const Home = (props) => {
  const history = useHistory();

  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const citizenFetched = useSelector((state) => state.citizen.citizenProfile);
  const citizenError = useSelector((state) => state.citizen.error);
  const [cookies, setCookies] = useCookies();

  const citizenProjects = useSelector((state) => state.citizen.projects);
  const joinedNewProject = useSelector((state) => state.projects.joined);
  const projectLoaded = useSelector((state) => state.citizen.projectLoaded);
  const newsFetched = useSelector((state) => state.news.news);
  const reportsFetched = useSelector((state) => state.reports.reports);
  const eventsFetched = useSelector((state) => state.events.events);

  const [news, setNews] = useState(newsFetched);
  const [events, setEvents] = useState(eventsFetched);
  const [programs, setPrograms] = useState(newsFetched);
  const [projectId, setProjectId] = useState();
  const [reports, setReports] = useState(reportsFetched);
  const [projects, setProjects] = useState([]);
  const [newsAux, setNewsAux] = useState(newsFetched);
  const {
    setVisible,
    setSidebarVisible,

    setProjectIdNotifications,
  } = useContext(NotificationContextProvider);

  const [currentStyle, setCurrentStyle] = useState(null);
  useEffect(() => {
    const url = history.location.pathname;
    var n = url.includes("launchsa");
    if (n) {
      const projectStyle = {
        mainColor: "#003F4F",
        secondaryColor: "#F38B00",
        route: "launchsa",
        buttons: {
          height: 50,
          marginTop: 50,
          backgroundColor: "#003F4F",
          borderRadius: 4,
        },
        secondaryButtons: {
          height: 50,
          marginTop: 50,
          backgroundColor: "white",
          borderRadius: 4,
          color: "#141414",
        },
        disabledButtons: {
          height: 50,
          marginTop: 50,
          backgroundColor: "white",
          borderRadius: 4,
          color: "#141414",
        },

        projectId: `${process.env.REACT_APP_PROJECT_ID}`,
        organizationId: `${process.env.REACT_APP_ORGANIZATION_ID}`,
      };

      localStorage.setItem("custom_client", JSON.stringify(projectStyle));
      dispatch(customClientSet(projectStyle.projectId));
      setCurrentStyle(projectStyle);
      dispatch(customClientSet(projectStyle.projectId));
      dispatch(readCitizenProfile());
    } else {
      dispatch(readCitizenProjects());
      dispatch(readCitizenProfile());
    }
  }, []);

  // useEffect(() => {
  //   const cs_json = JSON.parse(localStorage.getItem("custom_client"));
  //   if (cs_json) {
  //     setCurrentStyle(cs_json);
  //     dispatch(customClientSet(cs_json.projectId));
  //   } else {
  //     dispatch(readCitizenProjects());
  //   }
  //   dispatch(readCitizenProfile());
  // }, []);

  useEffect(() => {
    console.log("citizenError", citizenError);
    if (citizenError == true) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          const token = user.signInUserSession.accessToken.jwtToken;
          const expiredIn = user.signInUserSession.accessToken.payload.exp;
          axios.defaults.headers.Authorization = `${token}`;
          // setUser(user);
          setCookies("c_access", token, {
            path: "/",
            maxAge: expiredIn,
            httpOnly: false,
            secure: false,
            sameSite: false,
          });
          window.location.reload();
        })
        .catch((err) => {
          history.push("/launchsa/");
        });
    }
  }, [citizenError]);

  const tabMenuData = [
    currentStyle
      ? translate("client").pages.home.PROGRAMS
      : translate("client").pages.home.FEED,
    currentStyle
      ? translate("client").pages.home.EVENTS
      : translate("client").pages.home.NEWS,
  ];



  useEffect(() => {
    async function setUser() {
      await AsyncStorage.setItem("citizenData", JSON.stringify(citizenFetched));
    }
    setUser();
    console.log("<----Citizen-Fetched---->", citizenFetched);
  }, [citizenFetched]);

  useEffect(() => {
    if (currentStyle != null) {
      dispatch(getReports(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    console.log("currentStyle", currentStyle);
    if (currentStyle != null) {
      console.log("currentStyle", currentStyle.projectId);
      dispatch(getClientEvents(currentStyle.projectId));
      dispatch(getClientNews(currentStyle.projectId));
    }
  }, [currentStyle]);

  useEffect(() => {
    console.log("newsFetched", newsFetched);
    setNews(newsFetched);
    setPrograms(newsFetched);
    setNewsAux(newsFetched);
  }, [newsFetched]);

  useEffect(() => {
    console.log("eventsFetched", eventsFetched);
    setEvents(eventsFetched);
  }, [eventsFetched]);

  useEffect(() => {
    console.log("reportsFetched", reportsFetched);
    setReports(reportsFetched);
  }, [reportsFetched]);

  useEffect(() => {
    console.log("citizenProjects", citizenProjects);
    setProjects(citizenProjects);
  }, [citizenProjects]);

  const [state, setState] = useState({
    selectedMenu: 0,
    modalVisible: false,
    projectModal: false,
    isFetching: false,
    isFetchingReports: false,
    projectName: "",
    projectOrganization: "",
  });

  const projectClose = () => {
    console.log("click");
    setState({ ...state, projectModal: !state.projectModal });
  };

  const tabPress = ({ nativeEvent }) => {
    const selected = nativeEvent.target.innerText;
    const tab1 = currentStyle
      ? translate("client").pages.home.PROGRAMS
      : translate("client").pages.home.FEED;
    const tab2 = currentStyle
      ? translate("client").pages.home.EVENTS
      : translate("client").pages.home.NEWS;
    setState({
      ...state,
      selectedMenu:
        selected.replace(/\s/g, "") == tab1.replace(/\s/g, "") ? 0 : 1,
    });
  };

  const handleNewsClick = (index) => {
    history.push({
      pathname: "/launchsa/programs/viewprograms",
      state: { program: programs[index] },
    });
  };

  const handleProjectClick = async (item) => {
    console.log("project click", item);
    setState({
      ...state,
      projectName: item.project.name,
      projectOrganization: item.organization.name,
      image: item.project.logo,
      projectModal: !state.projectModal,
    });

    setProjectId(item.project.id);
    setProjectIdNotifications(item.project.id);
    await AsyncStorage.setItem("projectData", JSON.stringify(item));
  };

  const handleReportClick = (index) => {
    console.log(index);
  };

  const mainOptionsClick = (route) => {
    history.push({
      pathname: route,
      state: { data: null },
    });
  };

  const handleEventClick = (index) => {
    history.push({
      pathname: "/launchsa/events/viewevent",
      state: { evento: events[index] },
    });
  };

  useEffect(() => {
    async function setLocalProject() {
      console.log("citizenProjects useEffect", projects);
      console.log("loading useEffect", projectLoaded);

      if (projectLoaded == true) {
        if (projects.length > 0) {
          let rawProject = await AsyncStorage.getItem("projectData");
          console.log("rawProject", rawProject);

          if (joinedNewProject && joinedNewProject.id > 0) {
            let indeX = projects.findIndex((x) => x.id === joinedNewProject.id);
            if (indeX >= 0) {
              console.log("found project index", indeX);

              console.log("project set", projects[indeX]);
              setState({
                ...state,
                projectName: projects[indeX].project.name,
                projectOrganization: projects[indeX].organization.name,
                image: projects[indeX].project.logo,
              });

              await AsyncStorage.setItem(
                "projectData",
                JSON.stringify(projects[indeX])
              );

              setProjectId(projects[indeX].project.id);
              setProjectIdNotifications(projects[indeX].project.id);
              dispatch(resetGetData);
            }
          } else if (rawProject != null) {
            let projectJson = JSON.parse(rawProject);
            let indeX = projects.findIndex((x) => x.id === projectJson.id);

            console.log("found project index", indeX);
            if (indeX >= 0) {
              console.log("project set", projects[indeX]);
              setState({
                ...state,
                projectName: projects[indeX].project.name,
                projectOrganization: projects[indeX].organization.name,
                image: projects[indeX].project.logo,
              });
              await AsyncStorage.setItem(
                "projectData",
                JSON.stringify(projects[indeX])
              );

              setProjectId(projects[indeX].project.id);
              setProjectIdNotifications(projects[indeX].project.id);
            }
          } else {
            await AsyncStorage.setItem(
              "projectData",
              JSON.stringify(projects[0])
            );
            console.log("project click", projects[0]);
            setState({
              ...state,
              projectName: projects[0].project.name,
              projectOrganization: projects[0].organization.name,
              image: projects[0].project.logo,
            });

            setProjectId(projects[0].project.id);
            setProjectIdNotifications(projects[0].project.id);
          }
        } else {
          //props.navigation.navigate('SearchProject');
        }
      }
    }
    setLocalProject();
  }, [projects]);

  return (
    <div id="client-home" className="client-home-container">
      <div className="home-header">
        <Link to="/launchsa/profile">
          <Image
            preview={false}
            src={
              citizenFetched?.image
                ? cloudFrontUrl(citizenFetched.image)
                : Profile
            }
            className="profile-photo"
          />
        </Link>
        <Header
          className="home-header"
          title=""
          isMain={true}
          notificationClick={setVisible}
          messageClick={() => {}}
          menuClick={setSidebarVisible}
          projectClose={projectClose}
        />
      </div>
      <div className="home-wrapper">
        <div className="home-project">
          {currentStyle ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text
                className="title-text-launch"
                text={`Hi ${citizenFetched ? citizenFetched.name : ""},`}
              />
              <Text
                className="news-title-text"
                text={"Find your own pathway today!"}
              />
            </div>
          ) : (
            <ProjectInfo
              image={
                state.image
                  ? cloudFrontUrl(state.image)
                  : "https://jpassport.asia/static/image-not-available.png"
              }
              projectOrganization={state.projectOrganization}
              projectName={state.projectName}
              onClick={projectClose}
            />
          )}

          {currentStyle ? (
            <MainOptionsSA onClick={mainOptionsClick} />
          ) : (
            <MainOptions onClick={mainOptionsClick} />
          )}
        </div>
        <TabMenu
          selected={state.selectedMenu}
          data={tabMenuData}
          onClick={tabPress}
          main={true}
        />

        {state.selectedMenu === 0 ? (
          <div>
            {currentStyle ? (
              <ProgramFeed data={programs} onClick={handleNewsClick} />
            ) : (
              <ReportFeed
                data={reports}
                onClick={handleReportClick}
                isFetching={state.isFetchingReports}
              />
            )}
          </div>
        ) : (
          <div className="home-newsfeed-container">
            {/* {news && news.length > 0 ? (
              <Text
                className="news-title-text"
                text={translate("client").pages.home.POPULAR_AROUND}
              />
            ) : null}
            <PopularNews data={news} onClick={handleNewsClick} />
            {news && news.length > 0 ? (
              <Text
                className="news-title-text"
                text={translate("client").pages.home.RECENT_NEWS}
              />
            ) : null} */}
            {currentStyle ? (
              <EventsFeed
                data={events}
                onClick={handleEventClick}
                isFetching={state.isFetching}
              />
            ) : (
              <NewsFeed
                data={news}
                onClick={handleNewsClick}
                isFetching={state.isFetching}
              />
            )}
          </div>
        )}
      </div>
      <div className="provide-input">
        {currentStyle ? null : <ProvideInput />}
      </div>
      <ProjectModal
        modalVisible={state.projectModal}
        closeClick={projectClose}
        handleProjectClick={handleProjectClick}
        data={projects && projects.length > 0 ? projects : []}
      />
      <GetAssistance />
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Home);
