import { Slider as AntSlider } from "antd";
import "./slider.scss";

export const Slider = ({ defaultValue, disabled, onChange }) => {
  const formatter = (defaultValue) => {
    return `${defaultValue}%`;
  };
  return (
    <AntSlider
      value={defaultValue}
      disabled={disabled}
      // value={defaultValue}
      tipFormatter={formatter}
      marks={{ 10: `${defaultValue}% Complete` }}
      onChange={onChange}
    />
  );
};
