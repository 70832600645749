import React, { useContext } from "react";
import { Text } from "components/atoms/texts";
import "./ResourceFeed.scss";
import { I18nContext } from "utils/i18n/locale";
import NoNews from "assets/img/noNews.png";
import ResourcesRow from "components/client/components/molecules/ResourcesRow";
import { PrimaryButton } from "components/atoms/controls";
import { useHistory } from 'react-router-dom';

const ResourcesFeed = (props) => {
  const { translate } = useContext(I18nContext);
  const { onClick, data, type } = props;
  const history = useHistory();

  return (
    <div className="resources-feed-container">
      {data && data.length > 0 ? (
        data.map((item, index) => (
          <div key={index}>
            <ResourcesRow item={type == 1 ? item.resources: item} index={index} onClick={onClick} />
          </div>
        ))
      ) : (
        <div className="no-resources">
          <Text
            className="title-textss"
            text={translate("pages").mobile.resources.noresources}
          />

          <PrimaryButton
            onClick={() => history.push("/launchsa/survey/")}

            title={translate("pages").mobile.resources.getstarted}
            style={{width:'60%',marginLeft:'20%',height:50,marginTop:50,backgroundColor:'#003F4F',borderRadius:4}}

          />
        </div>
      )}
    </div>
  );
};

export default ResourcesFeed;
