import { Layout, Row, Col } from "antd";
import { SourceImage } from "../media";
import logo from "assets/img/irys.png";
import launchLogo from "assets/img/launch.png";
import "./layouts.scss";
import queryString from "query-string";
import { useLocation } from "react-router";
import singupIMG from "assets/img/signup.png";

export const AuthenticationLayout = ({ style, children, signUp }) => {
  const location = useLocation();
  const organization = queryString.parse(location.search);

  return (
    <div
      className={
        organization?.organization === "launchsa" ? "auth-container" : ""
      }
    >
      <Row justify="center" className="logo-container">
        <Col>
          <SourceImage
            src={organization?.organization === "launchsa" ? launchLogo : logo}
            alt="logo"
            className="login-logo"
            preview={false}
          />
        </Col>
      </Row>
      <Row justify="center" className="signup-container">
        {signUp && (
          <Col>
            <SourceImage
              src={singupIMG}
              preview={false}
              className="signup-logo"
            />
          </Col>
        )}
      </Row>
      <Row
        justify="center"
        style={{
          marginTop: signUp ? "90px" : "2%",
          backgroundColor: "transparent",
        }}
      >
        <Col style={{ backgroundColor: "transparent" }}>
          <Layout style={style} className="login-form">
            {children}
          </Layout>
        </Col>
      </Row>
    </div>
  );
};

export const WidgetLayout = ({
  style,
  children,
  isFull,
  noColor,
  fullContent,
}) => {
  return (
    <div className="widgets">
      <div className="reports">
        <Layout
          style={style}
          className={
            isFull
              ? "main-container-full"
              : noColor
              ? "main-container-no-color"
              : "main-container"
          }
        >
          {!noColor ? (
            children
          ) : (
            <div
              className={
                noColor && !fullContent
                  ? "content"
                  : fullContent
                  ? "full-content"
                  : ""
              }
            >
              {children}
            </div>
          )}

          {/* <div className={noColor ? "content" : ""}>{children}</div> */}
        </Layout>
      </div>
    </div>
  );
};

export const EmptyComponentLayout = ({ style, children }) => {
  return (
    <Row justify="center" align="middle">
      <Col>
        <Layout style={style} className="empty-component">
          {children}
        </Layout>
      </Col>
    </Row>
  );
};
export const BodyFormLayout = ({ style, children }) => {
  return (
    <Layout style={style} className="body-form">
      {children}
    </Layout>
  );
};
