import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import "client/pages/FAQ/FAQ.scss";
import { useHistory } from "react-router";
import { Text } from "components/atoms/texts";
import { BackArrowMobile } from "assets/svg/mobile";
import Collapse from "client/components/atoms/collapse";
//import { getFAQS } from "redux/slices/FAQs";
import faqAPI from "api/faqAPI";
const FAQ = (props) => {
  //const dispatch = useDispatch();
  //const FAQS = useSelector((state) => state.FAQS);
  /**
  const fetchFAQ = async () => {
    const faqList = awaitList faqAPI();
    console.log("F A Q ----> ", faqList);
  };
List  **/
  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentStyle, setCurrentStyle] = useState(null);

 

  useEffect(() => {
    const cs_json = JSON.parse(localStorage.getItem("custom_client"));
    if (cs_json) {
      setCurrentStyle(cs_json);
    }
  }, []);



  useEffect(() => {
    if (currentStyle != null) {
      faqAPI(currentStyle.projectId).then((res) => {
        setFaqList(res.data.body);
        setLoading(false);
      });
    }

  }, [currentStyle]);

  const history = useHistory();
  return (
    <div className="client-faqList-containLister">
      <div className="header">
        <BackArrowMobile onClick={() => history.goBack()} />
        <Text className="faq-title" text="FAQs" />
      </div>
      <div className="faqList-wrapperList">
        <Typography.Text className="faq-heading">
          Frequently asked questions
        </Typography.Text>

        <div className="questions-container">
          {loading
            ? null
            : faqList.map((FAQ) => {
                console.log(FAQ);
                return (
                  <Collapse
                    openColor="#0CAFA0"
                    closedColor="#ECF6F5"
                    titleText={FAQ.title}
                    bodyText={FAQ.description}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(FAQ);
