import api from "config/axios/axios.api.config";

const prefix = "categories";

const getCategories = (id) =>
    api.get(`reports/projects/${id}/${prefix}?subcategories=true`);

const getSubCategories = (id) => api.get(`reports/${prefix}/${id}/typenames`);

const createCategory = (data, id) =>
    api.postJson(`reports/projects/${id}/${prefix}`, data);

const createSubCategory = (data, id) =>
    api.postJson(`reports/projects/${id}/typereports`, data);

const updateCategory = (data, id) => api.put(`reports/${prefix}/${id}`, data);

const deleteCategory = (id) =>
    api.put(`reports/${prefix}/${id}/delete`, { id });

const deleteSubCategory = (id) =>
    api.put(`reports/typereports/${id}/delete`, { id });

// eslint-disable-next-line
export default {
    getCategories,
    getSubCategories,
    createCategory,
    createSubCategory,
    updateCategory,
    deleteCategory,
    deleteSubCategory,
};
