import React from "react";
import { ResponsiveRadar } from '@nivo/radar'
import "./Radar.scss";
import GraphContainer from "components/molecules/Graphs/Container";
import {LEGEND_POSITION, LEGEND_DIRECTION, LAYOUT} from "components/molecules/Graphs";

const getTranslateProps = (data, indexBy, legendDirection, legendPosition) => {
  let translateX = 0, translateY = 0;
  if (legendDirection === LEGEND_DIRECTION.ROW) {
    if(legendPosition === LEGEND_POSITION.BOTTOM) translateY = 60;
    if(legendPosition === LEGEND_POSITION.TOP) translateY = -75;
  } else if(legendDirection === LEGEND_DIRECTION.COLUMN) {
    if(legendPosition === LEGEND_POSITION.RIGHT) translateX = 120;
    if(legendPosition === LEGEND_POSITION.LEFT) translateX = -140;
  }

  return {
    translateX,
    translateY
  }
}

const getMarginProps = (data, indexBy, legendDirection, legendPosition, layout) => {
  let right = 40, bottom = 0, top = 24, left = 50;
  if (legendDirection === LEGEND_DIRECTION.ROW) {
    right=10;
    if(legendPosition === LEGEND_POSITION.BOTTOM) {
      bottom=70;
    } else {
      top=100;
      bottom=30;
    }
  } else if(legendDirection === LEGEND_DIRECTION.COLUMN) {
    if(legendPosition === LEGEND_POSITION.RIGHT) {
      right=170;
      bottom=40;
    } else {
      left=170;
    }
  }

  if(layout === LAYOUT.HORIZONTAL && legendDirection === LEGEND_POSITION.LEFT) left = Math.max(...data.map(item => item[indexBy].length))*6

  return {
    right,
    bottom,
    top,
    left,
  }
}

const getAxisProps = (data, indexBy, layout) => {
  let axisBottom = {
    legendPosition: 'middle',
  };
  let axisLeft = {
    legendOffset: -40,
  };

  if(layout === LAYOUT.HORIZONTAL) {
    axisLeft = {
      ...axisLeft,
      tickSize: 0,
    }
  }

  return {
    axisBottom,
    axisLeft
  }
}

const RadarGraph = ({
  indexBy,
  key='value',
  data,
  keyPoints,
  title,
  subtitle,
  height = 400,
  legendDirection=LEGEND_DIRECTION.ROW,
  legendPosition=LEGEND_POSITION.TOP,
  layout=LAYOUT.VERTICAL,
  gridLevels=8,
  dotColor="#FFFFFF"
}) => {

  return (
    <GraphContainer style={{ height }} title={title} subtitle={subtitle}>
      <ResponsiveRadar
        data={data}
        keys={keyPoints ? Object.keys(keyPoints) : [key]}
        colors={({ key }) => keyPoints[key]}
        curve="linearClosed"
        indexBy={indexBy}
        maxValue={80}
        gridLevels={gridLevels}
        margin={getMarginProps(data, indexBy, legendDirection, legendPosition, layout)}
        {...getAxisProps(data, indexBy, layout)}
        enableDots={true}
        fillOpacity={0}
        gridShape="linear"
        gridLabelOffset={12}
        animate={true}
        motionConfig="wobbly"
        isInteractive={true}
        dotSize={10}
        enableDotLabel={true}
        dotLabel="value"
        dotLabelYOffset={-12}
        dotColor={dotColor}
        dotBorderWidth={2}
        dotBorderColor={{ from: 'color' }}
        legends={[
          {
            dataFrom: keyPoints ? 'keys' : 'indexes',
            anchor: legendPosition,
            direction: legendDirection,
            justify: false,
            ...getTranslateProps(data, indexBy, legendDirection, legendPosition),
            itemsSpacing: 0,
            itemWidth: keyPoints ? Math.max(...Object.keys(keyPoints).map(keyPoint => keyPoint.length)) * 8 : 98,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </GraphContainer>
  );
};

export default RadarGraph;
