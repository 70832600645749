import React, {useContext, useState} from "react";
import {I18nContext} from "utils/i18n/locale";
import SideDrawer, {RIGHT_SIDE} from "components/atoms/side-drawer";
import "./AddChartsPane.scss"
import {BackIcon, FilterIcon} from "components/atoms/icons";
import AddChartItem from "components/molecules/AddChartItem";
import flagsStatus from "assets/img/charts/flags-status.svg";
import flagStatus from "assets/img/charts/flag-status.svg";
import approvedOrRejected from "assets/img/charts/approve-and-rejected.svg";
import completionTimeCharts from "assets/img/charts/completion-time-chart.svg";
import completionVelocity from "assets/img/charts/completion-velocity.svg";
import createdFlags from "assets/img/charts/created-flags.svg";
import flagsDepartment from "assets/img/charts/flags-by-department.svg";
import heatMap from "assets/img/charts/heat-map.svg";
import categoryInput from "assets/img/charts/input-by-category.svg";
import subcategoryInput from "assets/img/charts/input-by-subcategory.svg";
import subcategoryInput2 from "assets/img/charts/input-by-subcatgory-2.svg";
import flagLifecycle from "assets/img/charts/lifecycle-of-flag.svg";
import onTimeOrLate from "assets/img/charts/on-time-and-late.svg";
import onTimeCompletion from "assets/img/charts/ontime-completeion.svg";
import flagApprovalScatterPlot from "assets/img/charts/scatter-plot-approval-and-completion.svg";
import categorySubmissions from "assets/img/charts/submission-by-category.svg";
import zipcodeSubmissions from "assets/img/charts/submission-by-zipcode.svg";
import surveyResults from "assets/img/charts/survey-result.svg";
import taskPriority from "assets/img/charts/task-priority.svg";
import {Row, Col} from "antd";
import {StandardInput} from "components/atoms/inputs";
import {SearchOutlined} from "@ant-design/icons";
import {CHARTS, TYPES} from "components/organisms/Analytics";

const AddChartsPane = ({show, onClose}) => {
	const {translate} = useContext(I18nContext);
	const [query, setQuery] = useState("");

	const MENU_ITEMS = [
		{
			icon: flagsStatus,
			title: translate("pages").analytic.editor.sidePane.menu.flagsStatus,
			component: TYPES.FLAGS_STATUS
		},
		{
			icon: flagsDepartment,
			title: translate("pages").analytic.editor.sidePane.menu.flagsDepartment,
			component: TYPES.FLAGS_BY_DEPARTMENT
		},
		{
			icon: taskPriority,
			title: translate("pages").analytic.editor.sidePane.menu.taskPriority,
			component: TYPES.TASKS_PRIORITY
		},
		{
			icon: heatMap,
			title: translate("pages").analytic.editor.sidePane.menu.heatMap,
			component: TYPES.HEAT_MAP
		},
		{
			icon: surveyResults,
			title: translate("pages").analytic.editor.sidePane.menu.surveyResults,
			component: TYPES.SURVEY_RESULTS
		},
		{
			icon: categorySubmissions,
			title: translate("pages").analytic.editor.sidePane.menu.categorySubmissions,
			component: TYPES.SUBMISSIONS_BY_CATEGORY
		},
		{
			icon: zipcodeSubmissions,
			title: translate("pages").analytic.editor.sidePane.menu.zipcodeSubmissions,
			component: TYPES.SUBMISSIONS_BY_ZIPCODE
		},
		{
			icon: categoryInput,
			title: translate("pages").analytic.editor.sidePane.menu.categoryInput,
			component: TYPES.INPUT_BY_CATEGORY
		},
		{
			icon: subcategoryInput,
			title: translate("pages").analytic.editor.sidePane.menu.subcategoryInput,
			component: TYPES.INPUT_BY_SUBCATEGORY
		},
		{
			icon: subcategoryInput2,
			title: translate("pages").analytic.editor.sidePane.menu.subcategoryInput,
			component: TYPES.INPUT_BY_SUBCATEGORY2
		},
		{
			icon: approvedOrRejected,
			title: translate("pages").analytic.editor.sidePane.menu.approvedOrRejected,
			component: TYPES.APPROVED_OR_REJECTED
		},
		{
			icon: onTimeOrLate,
			title: translate("pages").analytic.editor.sidePane.menu.onTimeOrLate,
			component: TYPES.ON_TIME_OR_LATE
		},
		{
			icon: heatMap,
			title: translate("pages").analytic.editor.sidePane.menu.openOrExpired,
			component: TYPES.OPEN_OR_EXPIRED
		},
		{
			icon: flagLifecycle,
			title: translate("pages").analytic.editor.sidePane.menu.flagLifecycle,
			component: TYPES.FLAG_LIFECYCLE
		},
		{
			icon: onTimeCompletion,
			title: translate("pages").analytic.editor.sidePane.menu.onTimeCompletedTasks,
			component: TYPES.TASKS_COMPLETED_ON_TIME
		},
		{
			icon: completionVelocity,
			title: translate("pages").analytic.editor.sidePane.menu.completionVelocity,
			component: TYPES.VELOCITY_OF_COMPLETION
		},
		{
			icon: completionTimeCharts,
			title: translate("pages").analytic.editor.sidePane.menu.completionTimeCharts,
			component: TYPES.COMPLETION_TIME_CHARTS
		},
		{
			icon: flagApprovalScatterPlot,
			title: translate("pages").analytic.editor.sidePane.menu.flagApprovalScatterPlot,
			component: TYPES.SCATTER_PLOT_OF_FLAG_APPROVAL
		},
		{
			icon: flagStatus,
			title: translate("pages").analytic.editor.sidePane.menu.flagStatus,
			component: TYPES.FLAG_STATUS_LINE
		},
		{
			icon: createdFlags,
			title: translate("pages").analytic.editor.sidePane.menu.createdFlags,
			component: TYPES.CREATED_FLAGS
		}
	]

	return (
		<SideDrawer className='charts-pane-drawer' closeIcon={null} side={RIGHT_SIDE}
								onClose={onClose}
								open={show}>
			<div className='charts-pane-header'>
				<div className='charts-header-left'>
					<div className="title">
						{translate("pages").analytic.editor.sidePane.title}
					</div>
					<div className="subtitle">
						{translate("pages").analytic.editor.sidePane.subtitle}
					</div>
				</div>
				<div onClick={() => onClose()} className="charts-header-right">
					<BackIcon className='back-icon'/>
				</div>
			</div>
			<div className='charts-filter-container'>
				<div className='filter'>
					<FilterIcon color="#808080"/>
				</div>
				<StandardInput
					onChange={e => setQuery(e.target.value)}
					placeholder={translate("pages").analytic.editor.sidePane.searchPlaceholder}
					className="charts-filter-search"
					prefixIcon={<SearchOutlined color='#7C7C7C'/>}
				/>
			</div>
			<Row className='items-container' gutter={[16, 16]}>
				{
					MENU_ITEMS
						.filter(({component, title}) => title.toLowerCase().includes(query.toLowerCase()) && !!CHARTS[component])
						.map(({component, ...item}, index) => (
							<Col key={index.toString()} md={12} xs={24}>
								<AddChartItem draggable={true}
															unselectable="on"
															onDragStart={e => {
																e.dataTransfer.setData("component", component);
															}}
															{...item}/>
							</Col>
						))
				}
			</Row>
		</SideDrawer>
	);
};
export default AddChartsPane;
