/**
 * Chandler Barlow
 * Onboarding screen to prompt new users to complete the profile questionnaire.
 * Only shown to new users with a new user cookie
 */
import "client/pages/Onboarding/onboarding.scss";
import { useHistory } from "react-router";
import { withCookies } from "react-cookie";
import { Text } from "components/atoms/texts";
import { ReactComponent as CancelIcon } from "assets/svg/mobile/cancel.svg";
import React, { useEffect, useSelector } from "react";
import { saveCitizenProject } from "redux/slices/Citizen";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch } from "react-redux";

const Onboarding = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();
  // Clearing the cookie ensures this screen does not show up for previous users
  // The cookie is set in the signup "/client/pages/Signup/index.jsx" screen in formSubmit()
  // The logic for checking for the cookie is in the verify page at redirectToHomePage()
  const clearCookie = () => {
    const { cookies } = props;
    cookies.remove("newUser", { path: "/" });
  };

  useEffect(() => {
    async function getDataToSend() {
      const checkReload = await AsyncStorage.getItem("onBoarding");

      if (checkReload && checkReload == "true") {
        await AsyncStorage.removeItem("onBoarding");
        history.go(0);
      } else {
        const rawData = await AsyncStorage.getItem("dataToSend");
        let dataToSend = JSON.parse(rawData);
        console.log("dataToSend", dataToSend);
        dispatch(saveCitizenProject(dataToSend));
      }
    }
    getDataToSend();
  }, []);

  return (
    <div className="client-onboarding-container">
      <div className="header">
        <CancelIcon
          onClick={() => {
            clearCookie();
            history.push("/launchsa/home");
          }}
        />
        <Text className="onboarding-title" text="Questionnaire" />
      </div>
      <h1 className="onboarding-header">Take Your First Step</h1>
      <h3 className="onboarding-body">
        Complete the following questionnaire and find your own pathway today!
      </h3>
      <div
        className="onboarding-button"
        onClick={() => {
          clearCookie();
          history.push({
            pathname: "/launchsa/survey",
            state: { newUser: true },
          });
        }}
      >
        <h3>GET STARTED</h3>
      </div>
      <h3
        className="onboarding-sub-link"
        onClick={() => {
          clearCookie();
          history.push("/launchsa/home");
        }}
      >
        I'll do it later
      </h3>
    </div>
  );
};

export default withCookies(Onboarding);
