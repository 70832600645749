import React, { useState } from "react";
import bestPractices from "assets/img/best-practices.svg";
import dataAnalytics from "assets/img/data-analytics.svg";
export const KnowledgeContextProvider = React.createContext();

const KnowledgeContext = ({ children }) => {
  const [categories, setCategories] = useState();
  const [posts, setPosts] = useState([]);

  return (
    <KnowledgeContextProvider.Provider
      value={{
        categories,
        setCategories,
        posts,
        setPosts,
      }}
    >
      {children}
    </KnowledgeContextProvider.Provider>
  );
};

export default KnowledgeContext;
