import React, { useContext } from "react";
import { Col, Row } from "antd";
import FlagsTable from "components/organisms/FlagsTable";
import { BADGE_COLORS } from "components/molecules/Table";
import { I18nContext } from "utils/i18n/locale";
import { Text } from "components/atoms/texts";

const FlagsActivityListView = ({ data, openModal, onClick }) => {
  const { translate } = useContext(I18nContext);

  return (
    <Col>
      <Text
        className="flag-section-title"
        text={translate("pages").flags.activity.title}
      />
      <Row gutter={[0, 24]}>
        <Col xs={24}>
          <FlagsTable
            onClick={onClick}
            heading={translate("pages").flags.activity.pending}
            color={BADGE_COLORS.PURPLE}
            data={data.pending}
            onAdd={() => openModal()}
          />
        </Col>
        <Col xs={24}>
          <FlagsTable
            onClick={onClick}
            heading={translate("pages").flags.activity.open}
            color={BADGE_COLORS.GREEN}
            data={data.open}
            onAdd={() => openModal()}
          />
        </Col>
        <Col xs={24}>
          <FlagsTable
            onClick={onClick}
            heading={translate("pages").flags.activity.close}
            color={BADGE_COLORS.RED}
            data={data.closed}
            onAdd={() => openModal()}
          />
        </Col>

        <Col xs={24}>
          <FlagsTable
            onClick={onClick}
            heading={translate("pages").flags.activity.resolved}
            color={BADGE_COLORS.GREEN}
            data={data.resolved}
            onAdd={() => openModal()}
          />
        </Col>
        <Col xs={24}>
          <FlagsTable
            onClick={onClick}
            heading={translate("pages").flags.activity.blocked}
            color={BADGE_COLORS.RED}
            data={data.blocked}
            onAdd={() => openModal()}
          />
        </Col>
        <Col xs={24}>
          <FlagsTable
            onClick={onClick}
            heading={translate("pages").flags.activity.rejected}
            color={BADGE_COLORS.RED}
            data={data.rejected}
            onAdd={() => openModal()}
          />
        </Col>
      </Row>
    </Col>
  );
};
export default FlagsActivityListView;
