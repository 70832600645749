import React from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Card } from "antd";
import { Text } from "components/atoms/texts";
import "./SurveyTemplateCard.scss";

const SurveyTemplateCard = ({
  backgroundColor,
  Icon,
  text,
  menu,
  onMoreClick,
  index,
  onClick,
}) => {
  return (
    <Card bordered={false} className="template-container" onClick={onClick}>
      <div
        className="survey-icon-container"
        style={{ backgroundColor: backgroundColor }}
      >
        <Icon />
      </div>
      <div className="survey-card-text">
        <Text text={text} />
        {menu && (
          <div className="menu-icon">
            <MoreOutlined onClick={() => onMoreClick(index)} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default SurveyTemplateCard;
