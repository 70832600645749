import React from "react";
import { Avatar } from "antd";
import "./FollowerItem.scss";
import {Text} from "components/atoms/texts";

const FollowerItem = ({ image, name, level, points }) => {

  return (
    <div className='follower-item-container'>
      <Avatar className="avatar" size={45} src={image}/>
      <div className='follower-item-right'>
        <Text className="name" text={name}/>
        <Text className="subtitle" text={`Level ${level} - ${points} Pints`}/>
      </div>
    </div>
  );
};
export default FollowerItem;
