import React, { lazy, Suspense } from "react";
import { SpiningPage } from "components/atoms/icons";
const NotificationsTemplate = lazy(() =>
  import("components/templates/Notifications")
);

const renderLoader = () => <SpiningPage opacity={1} />;

const Notifications = () => {
  return (
    <Suspense fallback={renderLoader()}>
      <NotificationsTemplate />
    </Suspense>
  );
};

export default Notifications;
