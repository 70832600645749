import React, { useState, useContext, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getClientNotifications } from "redux/slices/notifications";

export const NotificationContextProvider = React.createContext();

const NotificationContext = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [projectIdNotifications, setProjectIdNotifications] = useState(0);

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const dispatch = useDispatch();
  const notificationsFetched = useSelector(
    (state) => state.notifications.notifications
  );
  const [currentStyle, setCurrentStyle] = useState(false);
  const customClient = useSelector((state) => state.citizen.custom);

  useEffect(() => {
    console.log("customClient", customClient);

    setCurrentStyle(customClient);
  }, [customClient]);

  const [notifications, setNotifications] = useState(notificationsFetched);

  useEffect(() => {
    console.log("projectIdNotifications", projectIdNotifications);
    console.log("currentStyle", currentStyle);
    if(currentStyle != null){
      dispatch(
        getClientNotifications(currentStyle ?  currentStyle : projectIdNotifications)
      );
    }

  }, [currentStyle]);
  
  useEffect(() => {
    setNotifications(notificationsFetched);
  }, [notificationsFetched]);

  return (
    <NotificationContextProvider.Provider
      value={{
        visible,
        setVisible,
        notifications,
        setNotifications,
        sidebarVisible,
        setSidebarVisible,
        projectIdNotifications,
        setProjectIdNotifications,
      }}
    >
      {children}
    </NotificationContextProvider.Provider>
  );
};
export default NotificationContext;
