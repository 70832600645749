import React, {useState, useMemo, useContext} from "react";
import { Drawer } from "antd";
import UserProjectItem from "components/client/components/organisms/UserProjectItem";
import Header from "components/client/components/molecules/Header";
import SearchBar from "components/atoms/search";
import { I18nContext } from 'utils/i18n/locale';

import "./ProjectModal.scss";

const ProjectModal = (props) => {
    const { translate } = useContext(I18nContext);
    const {modalVisible, closeClick, data, handleProjectClick} = props;
    const [search, setSearch] = useState('');
    const searchItems = useMemo(
      () =>
        data.filter(
          (cat) => cat.project.name.toLowerCase().indexOf(search.toLowerCase()) !== -1,
        ),
      [search, data],
    );

    return (
        <Drawer
            bodyStyle={{backgroundColor: "#00000000", padding: 0}}
            visible={modalVisible}
            className={`project-modal ${modalVisible ? "" : "onClose"}`}
            closeIcon={false}
            onClose={closeClick}
            maskClosable={true}
            placement="top"
        >
            <div className="modal-view">
                <Header 
                    title={translate("client").pages.home.MY_PROJECTS}
                    onLeftIconPress={() => closeClick()} 
                />
                <div className="search-container">
                    <SearchBar
                        placeholder={translate("client").pages.home.SEARCH_PROJECT}
                        setSearch={setSearch}
                    />
                </div>
                <div style={{flex:1}}>
                    <div style={{paddingTop: 16}}>
                        {searchItems.map((item, index) => (
                            <div key={index}>
                                <UserProjectItem
                                    title={item.organization.name}
                                    city={item.project.name}
                                    onClick={() => handleProjectClick(item)}
                                    imageSource={item.project.logo}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

export default ProjectModal;