import React, { useContext, useEffect, useState } from "react";
import { Form } from "antd";
import IrysLogo from "assets/img/irysLogo.svg";
import "./login.scss";
import { Text } from "components/atoms/texts";
import { PrimaryButton } from "components/atoms/controls";
import { DataInput } from "components/atoms/inputs";
import { I18nContext } from "utils/i18n/locale";
import { signIn } from "api/amplifyAuthClientAPI";
import { Alert } from "components/atoms/outputs";
import { customClientSet } from "redux/slices/Citizen";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { connect, useDispatch } from "react-redux";

import MobileLayout from "../index";
import { useHistory } from "react-router-dom";

const ClientLogin = (props) => {
  const { translate } = useContext(I18nContext);
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [emailNotFound, setEmailNotFound] = useState(false);
  const [userNotExist, setUserNotExist] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [customClient, setCustomClient] = useState(false);
  const [loading, setLoading] = useState(false);
  const changeStatusLoading = (statusBoolean) => {
    setLoading(statusBoolean);
  };
  useEffect(() => {
    const url = history.location.pathname;
    var n = url.includes("launchsa");
    if (n) {
      setCustomClient(true);
      const projectStyle = {
        mainColor: "#003F4F",
        secondaryColor: "#F38B00",
        route: "launchsa",
        buttons: {
          height: 50,
          marginTop: 50,
          backgroundColor: "#003F4F",
          borderRadius: 4,
        },
        secondaryButtons: {
          height: 50,
          marginTop: 50,
          backgroundColor: "white",
          borderRadius: 4,
          color: "#141414",
        },
        disabledButtons: {
          height: 50,
          marginTop: 50,
          backgroundColor: "white",
          borderRadius: 4,
          color: "#141414",
        },

        projectId: `${process.env.REACT_APP_PROJECT_ID}`,
        organizationId: `${process.env.REACT_APP_ORGANIZATION_ID}`,
      };

      localStorage.setItem("custom_client", JSON.stringify(projectStyle));
      dispatch(customClientSet(projectStyle.projectId));
    } else {
      setCustomClient(false);
    }
  }, []);

  const emailChanged = (e) => {
    setState((prevState) => ({
      ...prevState,
      ["email"]: e.target.value,
    }));
  };

  const passwordChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      ["password"]: e.target.value,
    }));
  };
  const _emailFocused = () => {
    setEmailNotFound(false);
    setEmailFocused(true);
  };

  const changeScreen = (route) => {
    customClient
      ? history.push("/launchsa/" + route)
      : history.push("/client/" + route);
  };

  const sendData = async(dataToSend,token,tokenExpires) => {

    await AsyncStorage.setItem("dataToSend", JSON.stringify(dataToSend));
    props.history.push(
      `/client/verify?token=${token}&expireIn=${tokenExpires}&launchsa=${customClient}`
    );

  }
  const formSubmit = async() => {
    changeStatusLoading(true);
    signIn(state.email, state.password)
      .then((resp) => {
        if (resp.challengeName === "NEW_PASSWORD_REQUIRED") {
          Alert(
            "warning",
            `${translate("pages").login.messages.requirenewpassword} `
          );
          changeStatusLoading(false);
        } else {
          Alert(
            "success",
            `${translate("pages").login.messages.loginsuccess} `
          );

          const dataToSend = {
            gender: 0,
            name: resp.attributes.name,
            last_name: "",
            cognito_sub: resp.attributes.sub,
            organization_id: `${process.env.REACT_APP_ORGANIZATION_ID}`,
            email: resp.attributes.email,
            id:`${process.env.REACT_APP_PROJECT_ID}`,
          };

          console.log("dataToSend", dataToSend);
        

         // dispatch(saveCitizen(dataToSend));

          const token = resp.signInUserSession.accessToken.jwtToken;
          const tokenExpires = resp.signInUserSession.accessToken.payload.exp;
          changeStatusLoading(false);

          sendData(dataToSend,token,tokenExpires);
          //  props.history.push(`/verify?token=${token}&expireIn=${tokenExpires}`);
        }
      })
      .catch((err) => {
        if (err.code === "NetworkError") {
          Alert("error", err.message);
          changeStatusLoading(false);
        } else {
          Alert("error", translate("pages").login.messages.loginerror);
          changeStatusLoading(false);
        }
        console.error(err);
        changeStatusLoading(false);
      })
      .finally(() => {
        changeStatusLoading(false);
      });
  };
  return (
    <MobileLayout mode={!customClient}>
      <div className="mobile-component-container">
        {!customClient ? (
          <div className="login-logo">
            <img src={IrysLogo} width={86} height={35} />
            <Text text={translate("client").pages.login.title.logoTitle} />
          </div>
        ) : (
          <div />
        )}

        <div className="mobile-title">
          <Text
            className="title"
            text={translate("client").pages.login.title.loginTitle}
          />
          <Text
            className="tagline"
            text={
              customClient
                ? translate("client").pages.login.title.tagLineLauncha
                : translate("client").pages.login.title.tagline
            }
          />
        </div>

        <Form
          onFinish={(values) => {
            ///form values
          }}
          className="client-login-form"
        >
          {userNotExist && (
            <div className="error-message">
              <Text text={translate("client").pages.login.title.userNotExist} />
            </div>
          )}
          <DataInput
            placeholder={
              translate("client").pages.login.placeholders.emailAddress
            }
            name="email"
            customClass={`login-input margin-twenty ${
              emailNotFound ? "error" : emailFocused ? "focused" : " "
            }`}
            onChange={emailChanged}
            onFocus={_emailFocused}
          />
          {emailNotFound && (
            <div className="error-message">
              <Text
                text={translate("client").pages.login.title.emailNotFound}
              />
            </div>
          )}
          <DataInput
            placeholder={translate("client").pages.login.placeholders.password}
            name="password"
            customClass="login-input"
            showWithText={true}
            isPassword={true}
            onChange={passwordChange}
            suffix={translate("client").pages.login.passwordSuffix}
          />
        </Form>

        <Text
          onClick={(e) => {
            customClient
              ? history.push("/launchsa/forgot")
              : history.push("/client/forgot");
          }}
          text={translate("client").pages.login.title.forgotPassword}
          type="link"
          className={
            customClient
              ? "forgot-password-launchsa margin-twenty"
              : "forgot-password margin-twenty"
          }
        />

        <PrimaryButton
          title={translate("client").pages.login.title.login}
          className="client-login-button"
          onClick={() => formSubmit()}
          loading={loading}
          style={
            customClient
              ? {
                  height: 50,
                  marginTop: 50,
                  backgroundColor: "#003F4F",
                  borderRadius: 4,
                }
              : {}
          }
        />
        <div className="new-user-sign-up">
          <Text
            text={translate("client").pages.login.title.noAccount}
            className="no-account-text"
          />
          <Text
            onClick={() => {
              changeScreen("signup");
            }}
            text={translate("client").pages.login.title.signUp}
            className={customClient ? "sign-up-link-launchsa" : "sign-up-link"}
          />
        </div>
      </div>
    </MobileLayout>
  );
};

export default ClientLogin;
