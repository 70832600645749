import { Image, Layout } from "antd";
import React  from "react";
 
import WalkthroughSvg from "assets/img/walkthrough.svg";
import "./index.scss";
const ClientHome = ({mode,children}) => {
   return (
    <div className="client-mobile-container">
      <Layout className="mobile-wrapper">{children}</Layout>
      {mode === true && (
        <div className="walkthrough-image">
          <Image src={WalkthroughSvg} preview={false} />
        </div>
      )}
    </div>
  );
};

export default ClientHome;
