import React from "react";
import "./Switch.scss";

const Switch = ({ active, onToggle }) => {
  return (
    <div className="atom-switch" onClick={onToggle}>
      <div
        className="switch-tail"
        style={{
          background: active ? "rgba(117,250,207,1)" : "rgba(0,0,0,0.25)",
        }}
      ></div>
      <div
        className="switch-head"
        style={{
          marginLeft: active ? "calc(100% - 20px)" : 0,
          background: active ? "rgba(0,185,153,1)" : "rgba(0,0,0,0.85)",
        }}
      ></div>
    </div>
  );
};

export default Switch;
