import { Form } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { withCookies } from "react-cookie";
import IrysLogo from "assets/img/irysLogo.svg";
import "./signup.scss";
import { Text } from "components/atoms/texts";
import { PrimaryButton } from "components/atoms/controls";
import { DataInput } from "components/atoms/inputs";
import { I18nContext } from "utils/i18n/locale";
import MobileLayout from "../index";
import { useHistory, useLocation } from "react-router-dom";
import { Alert } from "components/atoms/outputs";
import { signUp } from "api/amplifyAuthClientAPI";

const ClientSignup = (props) => {
  const { translate } = useContext(I18nContext);
  const historyLocation = useLocation();

  const [state, setState] = useState({
    email: "",
    password: "",
    name: "",
  });

  const history = useHistory();

  const [focus, setFocus] = useState(false);
  const [currentStyle, setCurrentStyle] = useState(null);

  useEffect(() => {
    const cs_json = JSON.parse(localStorage.getItem("custom_client"));
    if (cs_json) {
      setCurrentStyle(cs_json);
    }
  }, []);
  const formSubmit = () => {
    signUp(state.email, state.password, state.name)
      .then((resp) => {
        if (resp.userConfirmed) {
          Alert(
            "warning",
            `${translate("pages").login.messages.requirenewpassword} `
          );
        } else {
          // This cookie is set to ensure that the onboarding screen is shown to the
          // user after they signup for the platform
          // The logic for checking for the cookie is in the verify page at redirectHome()
          const { cookies } = props;
          cookies.set("newUser", true, { path: "/" });
          history.push({
            pathname: "/client/validate/",
            state: { email: state.email, type: "1" },
          });
        }
      })
      .catch((err) => {
        if (err.code === "NetworkError") {
          Alert("error", err.message);
        } else {
          Alert("error", translate("pages").login.messages.loginerror);
        }
        console.error(err);
      })
      .finally(() => {});
  };

  const passwordChecks = [
    {
      reg: /^[a-zA-Z0-9!@#$%^&*]{8,}$/,
      message: translate("pages").mobile.Signup.regExsErrors.maxChars,
    },
    {
      reg: /^(?=.*[a-z])(?=.*[A-Z])/,
      message: translate("pages").mobile.Signup.regExsErrors.upperAndLower,
    },
    {
      reg: /^(?=.*[-+_!@#$%^&*.,?])/,
      message: translate("pages").mobile.Signup.regExsErrors.specialSymbol,
    },
  ];

  const emailChecks = [
    {
      reg: /\S+@\S+\.\S+/,
      message: translate("pages").mobile.Signup.regExsErrors.emailCheck,
    },
  ];

  const emailChanged = (e) => {
    setState((prevState) => ({
      ...prevState,
      ["email"]: e.target.value,
    }));
  };

  const passwordChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      ["password"]: e.target.value,
    }));
  };

  const nameChanged = (e) => {
    setState((prevState) => ({
      ...prevState,
      ["name"]: e.target.value,
    }));
  };

  return (
    <MobileLayout mode={!currentStyle}>
      <div className="mobile-component-container">
        {!currentStyle ? (
          <div className="login-logo">
            <img src={IrysLogo} width={86} height={35} />
            <Text text={translate("client").pages.login.title.logoTitle} />
          </div>
        ) : (
          <div />
        )}

        <div className="login-title">
          <Text
            className="title"
            text={translate("pages").mobile.Signup.mainText}
          />
          <Text
            className="tagline"
            text={
              currentStyle
                ? translate("pages").mobile.Signup.subTextLaunchSa
                : translate("pages").mobile.Signup.subText
            }
          />
        </div>

        <Form
          onFinish={(values) => {
            ///form values
          }}
          className="client-login-form"
        >
          <DataInput
            placeholder={translate("pages").mobile.Signup.placeholders.name}
            name="name"
            customClass={`login-input margin-twenty ${
              focus !== 0 ? "" : focus === 0 ? "focused" : " "
            }`}
            onBlur={() => {}}
            onFocus={() => {
              setFocus(0);
            }}
            onChange={nameChanged}
          />

          <DataInput
            placeholder={translate("pages").mobile.Signup.placeholders.email}
            name="email"
            customClass={`login-input margin-twenty ${
              focus !== 1 ? "" : focus === 1 ? "focused" : " "
            }`}
            onBlur={() => {}}
            checks={emailChecks}
            onFocus={() => {
              setFocus(1);
            }}
            onChange={emailChanged}
          />

          <DataInput
            placeholder={translate("pages").mobile.Signup.placeholders.password}
            name="password"
            customClass="login-input"
            showWithText={true}
            isPassword={true}
            checks={passwordChecks}
            suffix={translate("pages").mobile.Signup.passwordSuffix}
            onChange={passwordChange}
          />
        </Form>

        <Text
          text={translate("pages").mobile.Signup.termsAndConditions}
          type="link"
          className={
            currentStyle
              ? "forgot-password-launchsa margin-twenty"
              : "forgot-password margin-twenty"
          }
        />

        <PrimaryButton
          title={translate("pages").mobile.Signup.button}
          className="client-login-button"
          onClick={() => formSubmit()}
          style={currentStyle ? currentStyle.buttons : {}}
        />
        <div className="new-user-sign-up">
          <Text
            text={translate("pages").mobile.Signup.account}
            className="no-account-text"
          />
          <Text
            onClick={(e) => {
              currentStyle
                ? history.push(`/${currentStyle.route}/`)
                : history.push("/client/login");
            }}
            text={translate("pages").mobile.Signup.accountText}
            className={currentStyle ? "sign-up-link-launchsa" : "sign-up-link"}
          />
        </div>
      </div>
    </MobileLayout>
  );
};

export default withCookies(ClientSignup);
