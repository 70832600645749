import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./ViewProgram.scss";
import ViewEventTopbar from 'client/components/molecules/ViewEventTopBar';
import ProgramViewContainer from 'client/components/organisms/ProgramViewContainer'
import { I18nContext } from "utils/i18n/locale";
import { useHistory, useLocation } from "react-router-dom";

const ViewProgram = ({location}) => {
  const { translate } = useContext(I18nContext)
  const historyLocation = useLocation();

const [program, setProgram] = useState(
  historyLocation?.state?.program ?? null
);
  return (
    <div className="view-event-wrapper">
      <ViewEventTopbar title={translate("client").pages.program.viewProgram.title} />
      <ProgramViewContainer program={program} />
    </div>
  )
}

export default ViewProgram;
