import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import "./Bar.scss";
import GraphContainer from "components/molecules/Graphs/Container";
import {
  LEGEND_POSITION,
  LEGEND_DIRECTION,
  LAYOUT,
} from "components/molecules/Graphs";

const getTranslateProps = (data, indexBy, legendDirection, legendPosition) => {
  let translateX = 0,
    translateY = 0;
  if (legendDirection === LEGEND_DIRECTION.ROW) {
    if (legendPosition === LEGEND_POSITION.BOTTOM) translateY = 30;
  } else if (legendDirection === LEGEND_DIRECTION.COLUMN) {
    if (legendPosition === LEGEND_POSITION.RIGHT) translateX = 100;
    if (legendPosition === LEGEND_POSITION.LEFT) translateX = -140;
  }

  return {
    translateX,
    translateY,
  };
};

const getMarginProps = (
  data,
  indexBy,
  legendDirection,
  legendPosition,
  layout
) => {
  let right = 30,
    bottom = 0,
    top = 24,
    left = 30;
  if (legendDirection === LEGEND_DIRECTION.ROW) {
    if (legendPosition === LEGEND_POSITION.BOTTOM) {
      right = 10;
      bottom = 52;
    }
  } else if (legendDirection === LEGEND_DIRECTION.COLUMN) {
    if (legendPosition === LEGEND_POSITION.RIGHT) {
      right = 170;
      bottom = 40;
    } else {
      left = 170;
    }
  }

  if (layout === LAYOUT.HORIZONTAL)
    left = Math.max(...data.map((item) => item[indexBy].length)) * 6;

  return {
    right,
    bottom,
    top,
    left,
  };
};

const getAxisProps = (data, indexBy, layout) => {
  let axisBottom = null,
    axisLeft = true;

  if (layout === LAYOUT.HORIZONTAL) {
    axisLeft = {
      tickSize: 0,
    };
  }

  return {
    axisBottom,
    axisLeft,
  };
};

const BarGraph = ({
  indexBy,
  data,
  keyPoints,
  key = "value",
  title,
  height = 450,
  reverse = false,
  legendDirection = LEGEND_DIRECTION.ROW,
  legendPosition = LEGEND_POSITION.BOTTOM,
  layout = LAYOUT.VERTICAL,
}) => {
  return (
    <GraphContainer style={{ height }} title={title}>
      <ResponsiveBar
        data={data}
        keys={keyPoints ? Object.keys(keyPoints) : [key]}
        indexBy={indexBy}
        margin={getMarginProps(
          data,
          indexBy,
          legendDirection,
          legendPosition,
          layout
        )}
        padding={0.25}
        reverse={reverse}
        colors={({ id, data }) => (keyPoints ? keyPoints[id] : data.color)}
        enableLabel={false}
        labelSkipWidth={9}
        labelSkipHeight={12}
        layout={layout}
        {...getAxisProps(data, indexBy, layout)}
        legends={[
          {
            dataFrom: keyPoints ? "keys" : "indexes",
            anchor: legendPosition,
            direction: legendDirection,
            justify: false,
            ...getTranslateProps(
              data,
              indexBy,
              legendDirection,
              legendPosition
            ),
            itemsSpacing: 0,
            itemWidth: keyPoints
              ? Math.max(
                  ...Object.keys(keyPoints).map((keyPoint) => keyPoint.length)
                ) * 8
              : 98,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        tooltip={({ data }) => (
          <div className="chart-tooltip">
            {data.data?.length > 0 ? (
              <>
                <span>
                  {" "}
                  <b>{`${data[indexBy]}(${data[key]})`}</b>
                </span>
                <br />
                {data.data.map((item) => (
                  <>
                    {/* <span>{`${item.name}:`}</span> */}
                    <span>
                      {/* <strong> {`${item.value}`}</strong> */}
                    </span>
                    <br />
                  </>
                ))}
              </>
            ) : (
              <>
                <span>{`${data[indexBy]}:`}</span>
                <span>
                  <strong> {`${data[key]}`}</strong>
                </span>
              </>
            )}
          </div>
        )}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </GraphContainer>
  );
};

export default BarGraph;
