import React, {useContext} from 'react'
import PieGraph, {TYPE} from "components/molecules/Graphs/Pie";
import {I18nContext} from "utils/i18n/locale";
import {LEGEND_DIRECTION, LEGEND_POSITION} from "components/molecules/Graphs";

const SubmissionsByCategory = () => {
	const { translate } = useContext(I18nContext);

	const DATA = [
		{
			"id": "medium",
			"label": 'Something you love',
			"value": 10,
			"color": "#6DFFE2"
		},
		{
			"id": "high",
			"label": 'Something you want to change',
			"value": 65,
			"color": "#4CCEB8"
		},
		{
			"id": "low",
			"label": 'Something you want to add',
			"value": 25,
			"color": "#2CB999"
		}
	]

	return (
		<PieGraph
			type={TYPE.FULL}
			height='100%'
			legendDirection={LEGEND_DIRECTION.COLUMN}
			legendPosition={LEGEND_POSITION.BOTTOM}
			title={translate("pages").analytics.byCategory}
			data={DATA} />
	)
}

export default SubmissionsByCategory;
