import { Col, Layout, Row } from "antd";
import DirectoryItemCard from "components/molecules/DirectoryItemCard";
import React, { useState } from "react";

const DirectoryCardTemplate = ({ data, ...props }) => {
  const [visible, setVisible] = useState({ status: false, index: -1 });
  return (
    <Layout className="directory-card-template">
      <Row gutter={[16, 16]}>
        {data.map((item, index) => (
          <Col xs={24} md={12} lg={8}>
            <DirectoryItemCard
              data={item}
              visible={visible}
              setVisible={setVisible}
              index={index}
            />
          </Col>
        ))}
      </Row>
    </Layout>
  );
};

export default DirectoryCardTemplate;
