import React, {useContext} from 'react'
import {Col, Row} from "antd";
import StatCard from "components/molecules/Graphs/StatCard";
import {CheckIcon, GraphIcon, ScatterDotsIcon, TimerIcon} from "components/atoms/icons";
import {I18nContext} from "utils/i18n/locale";
import "./SurveyResults.scss";

const SurveyResults = () => {
	const { translate } = useContext(I18nContext);

	return (
		<Row className='survey-results-graph-root' gutter={[2, 0]}>
			<Col md={24/4} sm={24} flex="auto">
				<StatCard value={12} icon={GraphIcon} name={translate("pages").analytic.editor.charts.surveyResult.totalResponses}/>
			</Col>
			<Col md={24/4} sm={24} flex="auto">
				<StatCard value={"100.0%"} icon={ScatterDotsIcon} name={translate("pages").analytic.editor.charts.surveyResult.participationRate}/>
			</Col>
			<Col md={24/4} sm={24} flex="auto">
				<StatCard value={"100.0%"} icon={CheckIcon} name={translate("pages").analytic.editor.charts.surveyResult.completionRate}/>
			</Col>
			<Col md={24/4} sm={24} flex="auto">
				<StatCard value={"00:33 min"} icon={TimerIcon} name={translate("pages").analytic.editor.charts.surveyResult.avgCompletionTime}/>
			</Col>
		</Row>
	)
}

export default SurveyResults;
