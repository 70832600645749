import React, {useContext} from "react";
import "./SubmissionsMenu.scss";
import {DeleteIcon, TickIcon} from "components/atoms/icons";
import Menu from "components/atoms/menu";
import {I18nContext} from "utils/i18n/locale";

const SubmissionsMenu = ({onOptionSelected}) => {
  const {translate} = useContext(I18nContext);

  return <Menu className="submissions-menu-container" onClick={onOptionSelected} options={[
    {
      title: translate("pages").communication.submission.table.approve,
      icon: <TickIcon/>,
      key: "approve",
    },
    {
      title: translate("pages").communication.submission.table.reject,
      icon: <DeleteIcon/>,
      className: 'reject-flag',
      key: "reject",
    },
  ]}/>;
};
export default SubmissionsMenu;
