import { Cancel, Lock, LockSA } from "assets/svg/mobile";
import { DataInput } from "components/atoms/inputs";
import React, { useState, useContext, useEffect } from "react";
import { I18nContext } from "utils/i18n/locale";
import MobileLayout from "../index";
import { useHistory } from "react-router-dom";
import { Form } from "antd";
import { sendRecoveryMail } from "api/amplifyAuthClientAPI";
import { Text } from "components/atoms/texts";
import "./forgot.scss";
import { PrimaryButton } from "components/atoms/controls";
import { Alert } from "components/atoms/outputs";

const ClientForgot = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const history = useHistory();

  const { translate } = useContext(I18nContext);
  const [currentStyle, setCurrentStyle] = useState(null);

  useEffect(() => {
    const cs_json = JSON.parse(localStorage.getItem("custom_client"));
    if (cs_json) {
      setCurrentStyle(cs_json);
    }
  }, []);

  const emailChecks = [
    {
      reg: /\S+@\S+\.\S+/,
      message: translate("pages").mobile.forgot.regExsErrors.emailCheck,
    },
  ];
  const emailChanged = (e) => {
    setState((prevState) => ({
      ...prevState,
      ["email"]: e.target.value,
    }));
  };
  const onSubmit = () => {
    console.log("email", state.email);
    sendRecoveryMail(state.email)
      .then((resp) => {
        if (resp.code === "UserNotFoundException") {
          Alert(
            "warning",
            `${translate("client").pages.login.title.userNotExist} `
          );
        } else {
          const path = currentStyle
            ? `/${currentStyle.route}/validate`
            : "/client/validate/";

          history.push({
            pathname: path,
            state: { email: state.email, type: "2" },
          });
        }
      })
      .catch((err) => {
        if (err.code === "NetworkError") {
          Alert("error", err.message);
        } else {
          Alert("error", translate("pages").login.messages.loginerror);
        }
        console.error(err);
      })
      .finally(() => {});
  };

  const [focus, setFocus] = useState(false);
  return (
    <MobileLayout mode={!currentStyle}>
      <div className="mobile-component-container">
        <div className="box">
          <Cancel
            onClick={(e) => {
              history.push(
                currentStyle ? `/${currentStyle.route}/` : "/client/"
              );
            }}
            className="cancel"
          />
          {currentStyle ? (
            <LockSA className="center lock" />
          ) : (
            <Lock className="center lock" />
          )}
          <div className="info-forgot">
            <Text
              className="title"
              text={translate("pages").mobile.forgot.mainText}
            />

            <Text
              className="tagline"
              text={translate("pages").mobile.forgot.subText}
            />
          </div>

          <Form onFinish={onSubmit} className="form">
            <DataInput
              placeholder={translate("pages").mobile.forgot.placeholders.email}
              name="email"
              customClass={`login-input margin-twenty ${
                focus !== 1 ? "" : focus === 1 ? "focused" : " "
              }`}
              checks={emailChecks}
              onChange={emailChanged}
              onBlur={() => {}}
              onFocus={() => {
                setFocus(1);
              }}
            />
          </Form>
          <PrimaryButton
            title={translate("pages").mobile.forgot.button}
            className="client-login-button"
            onClick={() => {
              onSubmit();
            }}
            style={currentStyle ? currentStyle.buttons : { marginTop: 20 }}
          />
        </div>
      </div>
    </MobileLayout>
  );
};

export default ClientForgot;
