import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Row, Button, Col } from "antd";
import { I18nContext } from "utils/i18n/locale";
import { CreateElementInput } from "components/atoms/inputs";
import "./CreateProjectForm.scss";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { Slider } from "components/atoms/slider";
import { FileDragger } from "components/atoms/media";
import { LocationSearchInput } from "components/molecules/Map";
import Switch from "components/atoms/switch";
import { asyncGetData } from "redux/slices/projects";

const CreateProjectForm = ({
  show,
  onClose,
  tab,
  globalForm,
  formRef,
  shareFile,
  projectCompletionPercentage,
  setProjectCompletionPercentage,
  privateProject,
  setPrivateProject,
  showProgress,
  setShowProgress,
  location,
  getAddress,
}) => {
  const projectData = useSelector((state) => state.projects.previewData);

  const { translate } = useContext(I18nContext);
  const [files, setFiles] = useState();

  let preData = {};

  const [previewData, setPreviewData] = useState(projectData);

  useEffect(() => {
    // if (!preData.id) {
    //   globalForm.resetFields();
    // }
  }, [previewData]);

  const dispatch = useDispatch();

  useEffect(() => {
    setPreviewData(projectData);
    // console.log({ projectData });
  }, [projectData]);

  useEffect(() => {
    // console.log({ changed: location });
    let updateValue = {
      address: location.address,
      latitude: location.latitude,
      longitude: location.longitude,
    };
    dispatch(asyncGetData(updateValue));
  }, [location]);

  useEffect(() => {
    // console.log(projectCompletionPercentage, "changed");
    let updateValue = {
      completion_status: projectCompletionPercentage,
    };
    dispatch(asyncGetData(updateValue));
  }, [projectCompletionPercentage]);
  useEffect(() => {
    let updateValue = {
      is_private: privateProject,
    };
    dispatch(asyncGetData(updateValue));
  }, [privateProject]);

  useEffect(() => {
    let updateValue = {
      share: showProgress,
    };
    dispatch(asyncGetData(updateValue));
  }, [showProgress]);

  const [form] = Form.useForm();

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const getFileReference = (file) => {
    setFiles(file);
    shareFile(file);
  };

  const handleFormData = (data) => {
    dispatch(asyncGetData(data));
  };

  const onChangeSlider = (value) => {
    setProjectCompletionPercentage(value);
  };

  return (
    <Form
      form={globalForm}
      className="project-form"
      ref={formRef}
      initialValues={previewData}
      // onChange={handleFormData}
      // onChange={(e) => {
      //   console.log(e, "onchange");
      // }}
      onValuesChange={(e) => {
        handleFormData(e);
      }}
      // onValuesChange={({ category, department }) => {
      //   setCategory(category);
      //   setDepartment(department);
      // }}
      // onFinish={onFinish}
      // onChange={(e) => {
      //   // console.log(e);
      //   // setCategory(formRef.current.getFieldValue("category"));
      // }}
      onFinishFailed={onFinishFailed}
    >
      <div>
        <div className="slider-container">
          <h3 className="overview-title">
            {translate("pages").projects.createProjectForm.labels.overview}
          </h3>
          <h4 className="status-title">
            {translate("pages").projects.createProjectForm.labels.projectStatus}
          </h4>
          <Slider
            defaultValue={projectCompletionPercentage}
            onChange={onChangeSlider}
          />
        </div>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CreateElementInput
            placeholder={
              translate("pages").projects.createProjectForm.placeholders.name
            }
            label={translate("pages").projects.createProjectForm.labels.name}
            allowClear
          />
        </Form.Item>
        <Form.Item name="image">
          <FileDragger
            currentFile={previewData}
            label={translate("pages").projects.createProjectForm.labels.logo}
            getFileReference={getFileReference}
          />
        </Form.Item>
        <Form.Item
          name="address"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <LocationSearchInput
            getAddress={getAddress}
            address={location.address}
          />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CreateElementInput
            textArea
            textAreaRows={5}
            placeholder={
              translate("pages").projects.createProjectForm.placeholders
                .description
            }
            label={
              translate("pages").projects.createProjectForm.labels.description
            }
            allowClear
          />
        </Form.Item>
        {/* <Form.Item name="department">
        <h3>{translate("pages").projects.createProjectForm.labels.category}</h3>
        <Select
          placeholder={
            translate("pages").projects.createProjectForm.placeholders.category
          }
          options={departments}
        />
      </Form.Item> */}

        <Row justify="start" align="middle">
          <Col span={6}>
            <h3>
              {
                translate("pages").projects.createProjectForm.labels
                  .privateProject
              }
            </h3>
          </Col>
          <Col span={2}>
            <Switch
              active={privateProject}
              onToggle={() => setPrivateProject(!privateProject)}
            />
          </Col>
          <Col span={8} offset={2}>
            <h3>
              {
                translate("pages").projects.createProjectForm.labels
                  .shareProjectStatus
              }
            </h3>
          </Col>
          <Col span={2}>
            <Switch
              active={showProgress}
              onToggle={() => setShowProgress(!showProgress)}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
};
export default CreateProjectForm;
