import React, {useContext} from "react";
import { I18nContext } from "utils/i18n/locale";
import {Col, Divider} from "antd";
import {Text} from "components/atoms/texts";
import {withRouter} from "react-router-dom";
import "./FlagsTemplate.scss";

export const LAYOUT_LIST = "list";
export const LAYOUT_KANBAN = "kanban";

const FlagsTemplate = ({ children, className="", action = <div/> }) => {
  const { translate } = useContext(I18nContext);

  return (
    <div>
      <Col className={`flags-content-container ${className}`} xs={24}>
        <div className='flag-header-row'>
          <Text
            className="flag-main-title"
            text={translate("pages").home.flags.title}
          />
          <div className="flag-action-container">
            {action}
          </div>
        </div>
        <Divider type="horizontal"/>
        {children}
      </Col>
    </div>
  );
};

export default withRouter(FlagsTemplate);
