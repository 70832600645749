import React from "react";
import { Avatar } from "antd";
import "./ActivityItem.scss";
import {Text} from "components/atoms/texts";

const ActivityItem = ({ icon, title, subtitle, color }) => {

  return (
    <div className='activity-item-container'>
      <Avatar style={{ backgroundColor: color }} className="avatar" size={45} icon={icon}/>
      <div className='activity-item-right'>
        <Text className="name" text={title}/>
        <Text className="subtitle" text={subtitle}/>
      </div>
    </div>
  );
};
export default ActivityItem;
