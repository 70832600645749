import React, { useContext, useRef, useState } from "react";
import "./ProfileInfo.scss";
import { Avatar, Button } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import { I18nContext } from "utils/i18n/locale";
import MemoizedAvatar from "../ProjectSelect/Avatar";
import { cloudFrontUrl } from "utils/common";

const ProfileInfo = ({
  image = "",
  name = "",
  level = 0,
  flags = 0,
  bages = 0,
  points = 0,
  citizen,
  onClickUpload = () => {},
}) => {
  const { translate } = useContext(I18nContext);
  const fileRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(image);
  const [isLocal, setIsLocal] = useState(false);

  const onUpload = (e) => {
    if (!e?.target?.files || e?.target?.files.length === 0) return;
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onloadend = function (e) {
      setImageSrc(reader.result);
      setIsLocal(true);

      onClickUpload({
        file,
        base64: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="container">
      <MemoizedAvatar
        className="avatar"
        size={150}
        radius={1000}
        {...{
          ...(imageSrc
            ? {
                src: cloudFrontUrl(imageSrc),
              }
            : {
                avatarName: true,
                avatarSize: 110,
                fontSize: 50,
                userName: name,
              }),
        }}
        // src={cloudFrontUrl(imageSrc)}
        // logo={imageSrc}
        localImage={isLocal ? imageSrc : ""}
      />
      {/* <Avatar
        size={{ xs: 50, sm: 75, md: 90, lg: 100, xl: 120, xxl: 150 }}
        src={imageSrc}
      /> */}
      <Button
        hidden={citizen ? true : false}
        className="upload-button"
        onClick={() => fileRef.current.click()}
        type="primary"
        shape="round"
        icon={<ArrowUpOutlined />}
        size="large"
      >
        {translate("pages").profile.buttons.upload}
      </Button>
      <input type="file" onChange={onUpload} hidden ref={fileRef} />
      <div className="name">{name}</div>
      {/* <div className="subtitle">Level {level} Cityflagger</div> */}
      <div className="subtitle">
        {/* {flags} Flags - {bages} Bages - {points} Points */}
      </div>
    </div>
  );
};
export default ProfileInfo;
