// all api goes in here
import api from "config/axios/axios.api.config";

const prefix = "surveys/questions";

export const getQuestionsAPI = () => api.get(`${prefix}`);
export const getQuestionAPI = (id) => api.get(`${prefix}/${id}`);
export const deleteQuestionAPI = (id) => api.put(`${prefix}/${id}/delete`);
export const publishQuestionAPI = (id) => api.put(`${prefix}/${id}/publish`);
export const duplicateQuestionAPI = (data) =>
    api.postJson(`${prefix}/${data.id}/duplicate`, data);
export const updateQuestionAPI = ({ id, createData }) =>
    api.put(`${prefix}/${id}`, createData);
export const createQuestionAPI = (data) =>
    api.postJson(`surveys/${data.surveyId}/questions`, data);
export const getQuestionTypesAPI = () => api.get(`${prefix}/types`);
export const orderQuestion = (data) =>
    api.put(`${prefix}/${data.id}/order`, data);
