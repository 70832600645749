import React, { useContext, useState } from "react";
import moment from "moment";
import { Calendar, Col, Row } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Text } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";
import { PrimaryButton } from "components/atoms/controls";
import "./MonthlyCalendar.scss";
const DATE_FORMAT = "MMMM YYYY";
const PRETY_DATE_FORMAT = "DD/MM/YYYY";
const SAVE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const MonthlyCalendar = ({
  handleApplyClick,
  handleCancelClick,
  setDate,
  sidePane = true,
  lg,
  customClass = "",
}) => {
  const [currentMonth, setCurrentMonth] = useState(
    moment().format(DATE_FORMAT)
  );

  const [currentYear, setCurrentYear] = useState(moment().format(DATE_FORMAT));

  const [currentDate, setCurrentDate] = useState(moment());

  function onPanelChange(value) {
    setCurrentMonth(value.format(DATE_FORMAT));
  }
  const { translate } = useContext(I18nContext);

  const TODAY = moment();
  const LAST_WEEK = moment().subtract(1, "week");
  const LAST_MONTH = moment().subtract(1, "month");
  const LAST_YEAR = moment().subtract(1, "year");

  const DATE_TYPES = {
    TODAY: "today",
    LAST_WEEK: "week",
    LAST_MONTH: "month",
    LAST_YEAR: "year",
  };

  const handleDateOptions = (type) => {
    switch (type) {
      case DATE_TYPES.TODAY:
        setCurrentDate(TODAY);
        setCurrentMonth(TODAY.format(DATE_FORMAT));
        setDate(TODAY.format(SAVE_FORMAT));
        break;
      case DATE_TYPES.LAST_WEEK:
        setCurrentDate(LAST_WEEK);
        setCurrentMonth(LAST_WEEK.format(DATE_FORMAT));
        setDate(LAST_WEEK.format(SAVE_FORMAT));
        break;
      case DATE_TYPES.LAST_MONTH:
        setCurrentDate(LAST_MONTH);
        setCurrentMonth(LAST_MONTH.format(DATE_FORMAT));
        setDate(LAST_MONTH.format(SAVE_FORMAT));
        break;
      case DATE_TYPES.LAST_YEAR:
        setCurrentDate(LAST_YEAR);
        setCurrentMonth(LAST_YEAR.format(DATE_FORMAT));
        setDate(LAST_YEAR.format(SAVE_FORMAT));
        break;
    }
  };

  const handleDateChange = (date) => {
    setCurrentDate(date);
    setDate(moment(date).format(SAVE_FORMAT));
  };

  return (
    <div className="site-calendar-customize-header-wrapper-monthly">
      <Row>
        {sidePane && (
          <Col xs={24} lg={7} className="calendar-date-example">
            <Text
              text={translate("molecule").calendar.today}
              onClick={() => {
                handleDateOptions("today");
              }}
            />
            <Text
              text={translate("molecule").calendar.lastWeek}
              onClick={() => {
                handleDateOptions("week");
              }}
            />
            <Text
              text={translate("molecule").calendar.lastMonth}
              onClick={() => {
                handleDateOptions("month");
              }}
            />
            <Text
              text={translate("molecule").calendar.lastYear}
              onClick={() => {
                handleDateOptions("year");
              }}
            />
          </Col>
        )}
        <Col
          xs={24}
          lg={lg || 17}
          className={`calendar-container ${customClass}`}
        >
          <Calendar
            fullscreen={false}
            headerRender={({ value, onChange }) => {
              return (
                <div>
                  <Row gutter={8} className="calendar-header">
                    <LeftOutlined
                      onClick={() => onChange(value.clone().subtract(1, "M"))}
                    />
                    <Text text={currentMonth} />
                    {/* <Text text={currentYear} /> */}
                    <RightOutlined
                      onClick={() => onChange(value.clone().add(1, "M"))}
                    />
                  </Row>
                </div>
              );
            }}
            defaultValue={currentDate}
            value={currentDate}
            onPanelChange={onPanelChange}
            dateFullCellRender={(value) => moment(value).format("D")}
            onSelect={(value) => handleDateChange(moment(value))}
          />
          <Row className="calendar-buttons">
            <PrimaryButton
              title={translate("molecule").calendar.apply}
              onClick={handleApplyClick}
            />
            <PrimaryButton
              title={translate("molecule").calendar.cancel}
              onClick={handleCancelClick}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MonthlyCalendar;
