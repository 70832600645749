import { Layout, Select } from "antd";
import { I18nContext } from "utils/i18n/locale";
import { Text } from "components/atoms/texts";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../index.scss";
import "./Survey.scss";
import {
  BackArrowMobile,
  NoSurvey,
  Cancel,
  SuccessIcon,
} from "assets/svg/mobile";
import SurveyCard from "./surveyCard";
import { RadioGroup } from "client/components/atoms/inputs";
import { CheckboxSurvey } from "components/atoms/inputs";
import { useHistory } from "react-router-dom";
import { readCitizenProfile } from "redux/slices/Citizen";
import { PrimaryButton } from "components/atoms/controls";
import Modal from "components/atoms/modal";
import { withRouter } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getClientSurveys } from "redux/slices/surveys";
import { getSurveyQuestions, resetGetData } from "redux/slices/surveys";

import { createResponses, postAnswers } from "redux/slices/responses";
import { DataInput } from "components/atoms/inputs";
const { Option } = Select;

const ClientSurvey = ({ location, history }) => {
  const { translate } = useContext(I18nContext);

  const [mode, setMode] = useState(false);
  const [modal, setModal] = useState(false);
  const [surveyId, setSurveyId] = useState(0);
  const [nextSurvey, setNextSurvey] = useState(0);
  const [currentStyle, setCurrentStyle] = useState(null);
  const [goProfile, setGoProfile] = useState(false);

  useEffect(() => {
    const cs_json = JSON.parse(localStorage.getItem("custom_client"));
    if (cs_json) {
      setCurrentStyle(cs_json);
    }
    if (location?.state?.newUser === true) {
      setSurveyId(45);
      setNextSurvey(46);
      setMode(true);
      setGoProfile(false);
    }
  }, []);

  const {
    surveys: { surveys, loading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [projectId, setProjectId] = useState(0);

  useEffect(() => {
    if (currentStyle != null) {
      dispatch(getClientSurveys(currentStyle.projectId));
    }
  }, [currentStyle]);

  return (
    <div
      className={` client-surveys client-mobile-container ${
        !modal ? "modal-custom" : ""
      }`}
    >
      <Modal
        getContainer="#modalMount"
        visible={modal}
        onCancel={() => {
          dispatch(resetGetData());
          setSurveyId(0);
          history.push({
            pathname: goProfile ? "/launchsa/profile/" : "/launchsa/resources/",
            state: { option: "1" },
          });
        }}
        footer={null}
        centered
      >
        <div className="survey-end">
          <SuccessIcon />

          <div className="survey-end-text">
            <Text
              noInherit={true}
              className={"survey-end-header"}
              text={
                goProfile
                  ? translate("pages").mobile.survey.profilePathway
                  : translate("pages").mobile.survey.pathway
              }
            />

            <Text
              noInherit={true}
              className={"survey-end-mid-text"}
              text={
                goProfile
                  ? translate("pages").mobile.survey.profileDetail
                  : translate("pages").mobile.survey.pathwayDetail
              }
            />

            <PrimaryButton
              title={goProfile ? "go to my profile" : "go to my resources"}
              className="client-login-button"
              onClick={() => {
                dispatch(resetGetData());
                setSurveyId(0);
                history.push({
                  pathname: goProfile
                    ? "/launchsa/profile/"
                    : "/launchsa/resources/",
                  state: { option: "1" },
                });
              }}
              style={{
                height: 50,
                width: "80%",
                marginTop: 50,
                backgroundColor: "#003F4F",
                borderRadius: 4,
                color: "white",
              }}
            />
          </div>
        </div>
      </Modal>
      <Layout id="modalMount" className="mobile-wrapper ">
        <div className="header">
          {mode ? (
            <Cancel
              onClick={(e) => {
                setMode(false);
              }}
            />
          ) : (
            <BackArrowMobile onClick={() => history.goBack()} />
          )}

          <Text
            className={"survey-title"}
            text={
              currentStyle
                ? "Questionnaire"
                : translate("pages").mobile.survey.header
            }
          />
        </div>
        <div className="survey-content">
          {surveys.length > 0 &&
            (mode ? (
              <Questions
                setMode={setMode}
                setModal={setModal}
                surveyId={surveyId}
                currentStyle={currentStyle}
                nextSurvey={nextSurvey}
                setSurveyId={setSurveyId}
                setNextSurvey={setNextSurvey}
              ></Questions>
            ) : (
              <SurveyAvailable
                setMode={setMode}
                surveys={surveys.filter((surveys) => surveys.published)}
                setSurveyId={setSurveyId}
                customClient={currentStyle}
                setNextSurvey={setNextSurvey}
                setGoProfile={setGoProfile}
              />
            ))}
          {surveys.length === 0 && (
            <div className="no-survey">
              <div>
                <NoSurvey />
              </div>
              <Text
                className={"no-survey-title"}
                noInherit={true}
                text={translate("pages").mobile.survey.noSurvey}
              />
            </div>
          )}
        </div>
      </Layout>
    </div>
  );
};

const SurveyAvailable = ({
  surveys,
  setMode,
  setSurveyId,
  customClient,
  setNextSurvey,
  setGoProfile
}) => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="client-surveys">
      <Text
        noInherit={true}
        className={"survey-content-title"}
        text={
          customClient
            ? "Complete the following questionnaires"
            : translate("pages").mobile.survey.subText
        }
      />

      <div className="client-survey-cards-holder">
        {surveys.map((survey) => {
          return (
            <SurveyCard
              survey={survey}
              onClick={(e) => {
                setSurveyId(survey.id);
                setMode(true);
                if (survey.id != 47) {
                  setGoProfile(false);
                } else {
                  setGoProfile(true);
                }
                setNextSurvey(survey.next_survey_id);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const Questions = ({
  setModal,
  setMode,
  surveyId,
  currentStyle,
  nextSurvey,
  setSurveyId,
  setNextSurvey,
}) => {
  const { translate } = useContext(I18nContext);
  const [index, setIndex] = useState(0);
  const [user, setUser] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const dispatch = useDispatch();
  const [response, setResponse] = useState([]);
  const [nextQuestion, setNextQuestion] = useState(0);
  const [saveResource, setSaveResource] = useState(null);
  const [nextSurveyLocal, setNextSurveyLocal] = useState(null);
  const [finished, setFinished] = useState(false);
  const responseCreated = useSelector((state) => state.responses.response);
  const citizenFetched = useSelector((state) => state.citizen.citizenProfile);

  const {
    surveys: { surveyQuestions },
  } = useSelector((state) => state);
  const answerCreated = useSelector((state) => state.responses.answers);
  useEffect(() => {
    console.log("responseCreated", responseCreated);
    setResponse(responseCreated);
  }, [responseCreated]);
  useEffect(() => {
    async function getUser() {
      console.log("citizenFetched", citizenFetched);
      await AsyncStorage.setItem("citizenData", JSON.stringify(citizenFetched));
      setUser(citizenFetched);
    }
    getUser();
  }, [citizenFetched]);

  useEffect(() => {
    async function getUser() {
      const rawUser = await AsyncStorage.getItem("citizenData");

      if (rawUser != null && JSON.parse(rawUser) != null) {
        console.log("not null");
        console.log("rawUser", JSON.parse(rawUser));
        let parsedUser = JSON.parse(rawUser);
        setUser(parsedUser);
      } else {
        console.log("null");
        dispatch(readCitizenProfile());
      }
    }
    getUser();
    setIndex(0);
    //  setCompleted(false);
    setUserAnswers({});
  }, []);
  const onChangeRadio = (selected, question) => {
    for (const option of question.options) {
      if (
        selected?.target?.value === option?.title ||
        selected === option?.title
      ) {
        setNextQuestion(option.next_question_id);

        setSaveResource(option.resources);
      }
    }

    setUserAnswers((prevAns) => ({
      ...prevAns,
      [question.id]: selected?.target?.value ?? selected,
    }));
  };
  const onChange = (selected, question, index) => {
    const tempArr = [];

    for (const sel of selected) {
      tempArr.push(sel);
    }

    setUserAnswers((prevAns) => ({
      ...prevAns,
      [question.id]: tempArr,
    }));

    if (question.options[0].next_question_id) {
      setNextQuestion(question.options[0].next_question_id);
    } else {
      setNextQuestion(-1);
    }

    const resourcesArr = [];
    for (const option of question.options) {
      for (const sel of selected) {
        if (option.title === sel) {
          for (const resource of option.resources) {
            resourcesArr.push(resource);
          }
        }
      }
    }

    if (resourcesArr.length > 0) {
      setSaveResource(resourcesArr);
    } else {
      setSaveResource(null);
    }
  };

  useEffect(() => {
    console.log("setUserAnswers changed", userAnswers);
  }, [userAnswers]);

  const onChangeField = (e, current) => {
    console.log(surveyQuestions);
    if (
      surveyQuestions[current].options &&
      surveyQuestions[current].options[0] &&
      surveyQuestions[current].options[0].next_question_id
    ) {
      setNextQuestion(surveyQuestions[current].options[0].next_question_id);
    } else {
      setNextQuestion(-1);
    }

    if (
      surveyQuestions[current].options &&
      surveyQuestions[current].options[0] &&
      surveyQuestions[current].options[0].resources
    ) {
      setSaveResource(surveyQuestions[current].options[0].resources);
    } else {
      setSaveResource(null);
    }

    if (
      surveyQuestions[current].options &&
      surveyQuestions[current].options[0] &&
      surveyQuestions[current].options[0].conditional_resources
    ) {
      const conditional_resources =
        surveyQuestions[current].options[0].conditional_resources;

      const resourceList = [];
      for (const item of conditional_resources) {
        console.log("item", item);

        var n = item.conditions.includes(e.target.value);
        console.log("n", n);
        if (n) {
          resourceList.push(item.resources[0]);
        }
      }
      setSaveResource(resourceList);

      // setSaveResource(surveyQuestions[current].options[0].resources);
    } else {
    }

    setUserAnswers((prevAns) => ({
      ...prevAns,
      [surveyQuestions[current].id]: e.target.value,
    }));
  };

  useEffect(() => {
    if (nextSurveyLocal != 0 && finished) {
      const itemToSend = {
        survey_id: nextSurvey,
        project_id: currentStyle.projectId,
        organization_id: currentStyle.organizationId,
        user_id: user.id,
      };
      console.log("itemToSend", itemToSend);
      setUserAnswers({});
      dispatch(createResponses(itemToSend));
      dispatch(getSurveyQuestions(nextSurvey));
      setNextSurveyLocal(0);
      setNextSurvey(0);
    }
  }, [nextSurveyLocal]);

  useEffect(() => {
    console.log("user", user);
    if (user != null) {
      const itemToSend = {
        survey_id: surveyId,
        project_id: currentStyle.projectId,
        organization_id: currentStyle.organizationId,
        user_id: user.id,
      };
      console.log("itemToSend", itemToSend);
      dispatch(createResponses(itemToSend));
      dispatch(getSurveyQuestions(surveyId));
    }
  }, [user]);

  const changeIndex = (directions) => {
    if (directions === 0) {
      if (index > 0) {
        setIndex(index - 1);
      } else {
        setMode(false);
      }
    } else {
      //if (index < surveyQuestions.length - 1) {
      var value = userAnswers[surveyQuestions[index].id];

      const itemToSend = {
        id: response?.id,
        response: {
          [surveyQuestions[index].id]: [value],
        },
        project_id: currentStyle.projectId,
        organization_id: currentStyle.organizationId,
        completion_time: 0,
        resources: saveResource,
        question_id: surveyQuestions[index].id,
      };
      dispatch(postAnswers(itemToSend));

      var indexToUse = surveyQuestions
        .map(function (question) {
          return question.id;
        })
        .indexOf(nextQuestion);
      console.log("indexToUse", indexToUse);
      console.log("nextSurvey", nextSurvey);
      if (indexToUse == -1) {
        if (nextSurvey != null && nextSurvey != 0) {
          setSurveyId(nextSurvey);
          setNextSurveyLocal(nextSurvey);
          setFinished(true);
          setIndex(0);
        } else {
          dispatch(resetGetData());
          setModal(true);
        }
      } else {
        setIndex(indexToUse);
      }
    }
  };

  return (
    <div className="client-surveys">
      <Question
        setIndex={changeIndex}
        index={index}
        question={surveyQuestions[index]}
        userAnswers={userAnswers}
        onChangeField={onChangeField}
        onChange={onChange}
        onChangeRadio={onChangeRadio}
        currentStyle={currentStyle}
        setNextQuestion={setNextQuestion}
      />
    </div>
  );
};

const Question = ({
  setIndex,
  index,
  question,
  onChangeField,
  onChange,
  userAnswers,
  onChangeRadio,
  currentStyle,
  setNextQuestion,
}) => {
  const { translate } = useContext(I18nContext);
  const [state, setState] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  return (
    <div className="client-surveys">
      <div className="survey-question">
        <Text
          noInherit={true}
          className={"survey-question"}
          text={question && question.title ? question.title : ""}
        />
        {question &&
        question.placeholder != null &&
        question.placeholder.type &&
        question.placeholder.type == "link" ? (
          <div style={{ paddingTop: 30 }}>
            <a href={question.placeholder.text} target="_blank">
              <Text
                noInherit={true}
                className={"survey-placeholder"}
                text={question.placeholder.text}
              />
            </a>
          </div>
        ) : null}

        {question &&
        question.placeholder != null &&
        question.placeholder.type &&
        question.placeholder.type == "text" ? (
          <div style={{ paddingTop: 30 }}>
            <Text
              noInherit={true}
              className={"survey-placeholder"}
              text={question.placeholder.text}
            />
          </div>
        ) : null}
        {question &&
        question.question_type_id &&
        question.question_type_id == "1" ? (
          <RadioGroup
            className={"radio-input"}
            onChange={(e) => {
              setIsDisabled(false);
              onChangeRadio(e, question);
            }}
            value={userAnswers[question.id] ? userAnswers[question.id] : ""}
            options={question.options}
          />
        ) : question &&
          question.question_type_id &&
          question.question_type_id == "2" ? (
          <CheckboxSurvey
            className={"radio-input"}
            onChange={(e) => {
              setIsDisabled(false);
              onChange(e, question, index);
            }}
            options={question.options.map((ele) => ele.title)}
            value={userAnswers[question.id] ? userAnswers[question.id] : []}
          />
        ) : question &&
          question.question_type_id &&
          question.question_type_id == "3" ? (
          <RadioGroup
            className={"radio-input"}
            onChange={(e) => {
              setIsDisabled(false);
              onChangeRadio(e, question);
            }}
            value={userAnswers[question.id] ? userAnswers[question.id] : ""}
            options={["True,False"]}
          />
        ) : question &&
          question.question_type_id &&
          question.question_type_id == "34" ? (
          <Select
            showSearch
            style={{ width: 350 }}
            placeholder="Choose category"
            optionFilterProp="children"
            onChange={(e) => {
              setIsDisabled(false);
              onChangeRadio(e, question);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {question?.options?.map((option) => (
              <Option value={option?.title}>{option?.title}</Option>
            ))}
          </Select>
        ) : (
          <DataInput
            type="textarea"
            rows={3}
            id={index}
            onChange={(value) => {
              onChangeField(value, index);
              setIsDisabled(false);
            }}
            value={
              question && userAnswers[question.id]
                ? userAnswers[question.id]
                : ""
            }
          />
        )}

        <div className="survey-button">
          <PrimaryButton
            style={currentStyle ? currentStyle.secondaryButtons : {}}
            title={translate("pages").mobile.survey.buttonBack}
            onClick={() => {
              setIndex(0);
            }}
          />
          <PrimaryButton
            title={translate("pages").mobile.survey.buttonNext}
            onClick={() => {
              if (
                question.question_type_id === 4 &&
                question.options &&
                question.options[0] &&
                question.options[0].next_question_id
              ) {
                setNextQuestion(question.options[0].next_question_id);
                setIsDisabled(true);
                setIndex(1);
              } else {
                setNextQuestion(-1);
                setIsDisabled(true);
                setIndex(1);
              }
            }}
            disabled={
              question && question.question_type_id === 4 ? false : isDisabled
            }
            style={
              question && question.question_type_id === 4
                ? currentStyle.buttons
                : currentStyle && isDisabled
                ? currentStyle.disabledButtons
                : currentStyle && !isDisabled
                ? currentStyle.buttons
                : {}
            }
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ClientSurvey);
