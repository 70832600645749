import React, { useContext } from "react";
import { I18nContext } from "utils/i18n/locale";
import Table, { HEADER_TRANSLUCENT } from "components/molecules/Table";
import UserListName from "components/molecules/UserListName";
import UserListSelect from "components/molecules/UserListSelect";
import "./UserListTable.scss";
import UserListDropdown from "components/molecules/UserListDropdown";
import { EditIcon, TrashIcon } from "components/atoms/icons";
import { FilterIcon } from "components/atoms/icons";
import { AlertIcon } from "components/atoms/icons";

const UserListTable = ({
  data,
  color,
  onAdd,
  onClick,
  openEditModal,
  openDeactivateModal,
  changeStatus,
  userList,
  loading,
}) => {
  const { translate } = useContext(I18nContext);
  const editOrDeactivateModal = (option, value) => {
    // console.log("option: ", option, "value: ", value);
    if (option.key === "edit") {
      const editUser = {
        id: value?.user?.id,
        firstName: value?.user?.name,
        lastName: value?.user?.last_name,
        email: value?.user?.email,
        phoneNumber: value?.user?.phone,
      };

      openEditModal(true, editUser);
    }
    if (option.key === "deactivate") {
      openDeactivateModal(value.id);
    }
  };

  const options = [
    {
      icon: <EditIcon className="users-edit-icon" />,
      key: "edit",
      title: translate("pages").settings.options.edit,
    },
    {
      icon: <TrashIcon color="#F44336" margintop={1} />,
      key: "deactivate",
      title: translate("pages").settings.options.deactivate,
      color: "#F44336",
    },
  ];
  const userListOptions = [
    {
      icon: <TrashIcon margintop={1} />,
      key: "delete",
      title: translate("pages").users.title.delete,
    },
    // {
    //   icon: <AlertIcon margintop={1} />,
    //   key: "report",
    //   title: translate("pages").users.title.report,
    // },
  ];

  return (
    <Table
      classes="table-normal-header user-list-table"
      scroll={{ x: true }}
      pagination={true}
      options={{
        collapsable: true,
        badgeColor: color,
        addOption: "some option",
        onAddPressed: onAdd,
      }}
      loading={loading}
      showPagination={true}
      headerType={HEADER_TRANSLUCENT}
      items={data}
      onClick={(e) => onClick(e)}
      columns={[
        {
          title: translate("pages").settings.columns.name,
          dataIndex: "user",
          render: (item, items, index) => {
            return (
              <UserListName
                // online={value.online}
                online={item?.online}
                image={item?.image}
                name={item?.name}
                level={item?.level ?? 0}
                userList
              />
            );
          },
        },

        {
          title: translate("pages").settings.columns.email,
          dataIndex: "user",
          render: (item, value) => item?.email,
        },
        // {
        //   title: translate("pages").settings.columns.department,
        //   dataIndex: "department",
        //   render: (item, value) => item?.name,
        // },
        // {
        //   title: translate("pages").settings.columns.role,
        //   dataIndex: "role",
        //   render: (item, value) => item?.name,
        // },
        //TODO: active / inactive users
        // {
        //   title: translate("pages").settings.columns.status,
        //   // dataIndex: "user",
        //   sorter: true,
        //   sortIcon: () => <FilterIcon />,
        //   render: (item) => (
        //     <UserListSelect
        //       initialValue={item?.enabled}
        //       changeStatus={changeStatus}
        //       userId={item?.user?.id}
        //       item={item}
        //     />
        //   ),
        // },
        ...(!userList
          ? [
              {
                title: translate("pages").settings.columns.department,
                dataIndex: "department",
                render: (item, value) => item?.name,
              },
              {
                title: translate("pages").settings.columns.role,
                dataIndex: "role",
                render: (item, value) => item?.name,
              },
              // {
              //   title: translate("pages").settings.columns.status,
              //   // dataIndex: "user",
              //   sorter: true,
              //   sortIcon: () => <FilterIcon />,
              //   render: (item) => (
              //     <UserListSelect
              //       initialValue={item?.enabled}
              //       changeStatus={changeStatus}
              //       userId={item?.user?.id}
              //       item={item}
              //     />
              //   ),
              // },
              //olds
              // {
              //   title: translate("pages").settings.columns.department,
              //   dataIndex: "department",
              //   render: (item) => item.name,
              // },
              // {
              //   title: translate("pages").settings.columns.role,
              //   dataIndex: "role",
              //   render: (item) => item.name,
              // },
              // {
              //   title: translate("pages").settings.columns.status,
              //   // dataIndex: "user",
              //   sorter: true,
              //   sortIcon: () => <FilterIcon />,
              //   render: (item) => (
              //     <UserListSelect
              //       initialValue={item.enabled}
              //       changeStatus={changeStatus}
              //       userId={item.user.id}
              //       item={item}
              //     />
              //   ),
              // },
            ]
          : [
              // {
              //   title: translate("pages").settings.columns.score,
              //   dataIndex: "score",
              //   render: (_, record) => <div>{record.score} pts.</div>,
              // },
            ]),
        // !userList
        //   ? {
        //       title: "",
        //       // dataIndex: "id",
        //       render: (value) => (
        //         <UserListDropdown
        //           options={userList ? userListOptions : options}
        //           title="..."
        //           className="ellipse-button"
        //           onClick={(option) => editOrDeactivateModal(option, value)}
        //         />
        //       ),
        //     }
        //   : {},
      ]}
    />
  );
};

export default React.memo(UserListTable);
