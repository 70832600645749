import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Row, Form } from "antd";
import React, { useContext, useState } from "react";
import { I18nContext } from "utils/i18n/locale";
import { Text } from "components/atoms/texts";
import "./EventSiderHeader.scss";
import { StandardInput } from "components/atoms/inputs";
import { CreateElementInput } from "components/atoms/inputs";
import { PrimaryButton } from "components/atoms/controls";
import CreateEventGroupModal from "components/molecules/CreateEventGroupModal";

const EventSiderHeader = (props) => {
  const { translate } = useContext(I18nContext);
  const { handleSearch, handleSaveTopic } = props;
  const [search, setSearch] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const onSearch = (e) => {
    let isEmpty;
    if (e.target.value) {
      if (e.target.value === "") {
        isEmpty = true;
      } else {
        isEmpty = false;
      }
    } else {
      if (e.currentTarget) {
        if (e.currentTarget.value === "") {
          isEmpty = true;
        } else {
          isEmpty = false;
        }
      }
    }

    handleSearch(e.target.value, isEmpty);
  };

  const onFinish = (e) => {
    let created = handleSaveTopic(e);
    created
      .then((data) => {
        setIsVisible(false);
      })
      .catch((err) => {});
  };

  return (
    <div className="event-sider-header">
      {!search ? (
        <Row>
          <Col span={16}>
            <Text
              text={translate("pages").events.sidebar.header}
              className="event-sider-header-text"
            />
          </Col>
          <Col span={4}>
            <PlusOutlined
              className="event-sider-icon"
              onClick={() => setIsVisible(true)}
            />
          </Col>
          <Col span={4}>
            <SearchOutlined
              className="event-sider-icon"
              onClick={() => setSearch(true)}
            />
          </Col>
        </Row>
      ) : (
        <div className="sider-search-box">
          <StandardInput
            onChange={onSearch}
            prefixIcon={<SearchOutlined />}
            placeholder={translate("pages").events.sidebar.searchPlaceholder}
          />
          <CloseOutlined
            className="event-close-icon"
            onClick={() => setSearch(false)}
          />
        </div>
      )}
      <CreateEventGroupModal
        isVisible={isVisible}
        title={translate("pages").events.createGroupEventModal.title}
        className="modal"
        handleCancel={() => setIsVisible(false)}
      >
        <Form name="event-topic-name" onFinish={onFinish}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: translate("pages").events.createEventModal
                  .validateTitle,
              },
            ]}
          >
            <CreateElementInput
              className="event-popup-input"
              placeholder={
                translate("pages").events.createGroupEventModal.placeholder
              }
            />
          </Form.Item>
          <div className="modal-button">
            <Form.Item>
              <PrimaryButton
                title={translate("pages").events.createGroupEventModal.button}
                htmlType="submit"
              />
            </Form.Item>
          </div>
        </Form>
      </CreateEventGroupModal>
    </div>
  );
};

export default EventSiderHeader;
