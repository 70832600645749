import React, { lazy, Suspense } from "react";
import { SpiningPage } from "components/atoms/icons";
const EventsTemplate = lazy(() => import("components/templates/Events"));

const renderLoader = () => <SpiningPage opacity={1} />;

const Events = () => {
  return (
    <Suspense fallback={renderLoader()}>
      <EventsTemplate />
    </Suspense>
  );
};

export default Events;
