import React, { useEffect, useRef, useState } from "react";
// import { Storage } from "aws-amplify";
import { Col, Image, Input, Row } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import "./media.scss";
import { Upload, message } from "antd";
import { Text } from "components/atoms/texts";
import { TrashIcon } from "../icons";
import { AttachFileIcon } from "../icons";
import {
    cloudFrontUrl,
    getCurrentProjectId,
    makeId,
    resizeImage,
} from "utils/common";
// import { makeId } from "utils/common";
// import ImgCrop from "antd-img-crop";
import { SaveMedia, SaveMediaAsync } from "api/amplifyAuthAPI";
import { Modal } from "antd";
import { PrimaryButton } from "components/atoms/controls";
const currentProject = getCurrentProjectId();
const { Dragger } = Upload;

export const uploadDrag = async (
    rootPath,
    media,
    customName,
    extension = "jpg",
    imageType = "image/jpg"
) => {
    try {
        const uploadName = `${rootPath}/${
            customName ?? "P-" + currentProject
        }/${makeId(15)}.${extension}`;
        const resizedMedia = await resizeImage(media, 0.4, imageType);
        return SaveMedia(uploadName, resizedMedia);
    } catch (e) {
        console.error(e, "error al cargar la imagen");
    }
};
export const uploadCarrouselFiles = async (
    rootPath,
    media,
    customName,
    extension = "jpg",
    imageType = "image/jpg"
) => {
    try {
        const uploadName = `${rootPath}/${
            customName ?? "P-" + currentProject
        }/${makeId(15)}.${extension}`;
        // const resizedMedia = await resizeImage(media, 0.4, imageType);
        return SaveMediaAsync(uploadName, media);
    } catch (e) {
        console.error(e, "error al cargar la imagen");
    }
};

export const SourceImage = ({
    src,
    style,
    onRemove = null,
    imageClassName = "",
    preview = true,
    ...props
}) => {
    return (
        <div className="media-container">
            {onRemove && (
                <CloseCircleFilled
                    className="media-cancel-btn"
                    onClick={onRemove}
                />
            )}
            <Image
                className={`media-image ${imageClassName}`}
                {...props}
                src={src}
                style={style}
                preview={preview}
            />
        </div>
    );
};

const dummyRequest = ({ file, onSuccess, onProgress, onerror }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 1200);
};

// const getImage = async (key) => {
//   if (key !== null) {
//     const url = await Storage.get(key);
//     return url.toString();
//   } else {
//     return "https://www.brdtex.com/wp-content/uploads/2019/09/no-image-480x480.png";
//   }
// };

export const FileUploader = ({
    showUploadList,
    fileList,
    setFileList,
    showIcon,
    isInput,
}) => {
    const handleRemove = (info) => {
        let fileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-5);

        // 2. Read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file;
        });

        setFileList(fileList);
    };
    const handleChange = (e) => {
        let cumulativeSize = 0;
        fileList.map((item) => {
            cumulativeSize = cumulativeSize + item.size;
        });

        let auxFiles = [];
        let error = false;
        for (const file of e.target.files) {
            cumulativeSize += file.size;
            const isLt5M = file.size / 1024 / 1024 < 5;
            const isLt20M = cumulativeSize / 1024 / 1024 < 20;
            if (isLt5M && isLt20M) {
                auxFiles.push(file);
            } else {
                error = true;
                message.error(
                    "Each file must be smaller than 5MB and cumulative size smaller than 20MB"
                );
            }
        }
        if (!error) {
            setFileList([...auxFiles, ...fileList]);
        }
    };
    const props = {
        multiple: true,
        defaultFileList: fileList,
        // action: "",
        showUploadList: showUploadList,
        beforeUpload: async (file) => {
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                message.error("File must smaller than 5MB!");
                return Upload.LIST_IGNORE;
            }
            if (isLt5M) {
                return true;
            }
        },
        onChange: handleRemove,
        customRequest: (file) => dummyRequest(file),
    };

    const inputRef = useRef(null);
    return (
        <>
            {isInput ? (
                <>
                    <input
                        type="file"
                        onChange={(e) => handleChange(e)}
                        multiple
                        hidden
                        ref={inputRef}
                    />
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            inputRef.current.click();
                        }}
                    >
                        <AttachFileIcon />
                    </span>
                </>
            ) : (
                <Upload {...props} fileList={fileList}>
                    {showIcon && (
                        <span style={{ cursor: "pointer" }}>
                            {" "}
                            <AttachFileIcon />
                        </span>
                    )}
                </Upload>
            )}
        </>
    );
};

export const FileDragger = ({
    label,
    getFileReference,
    getMedia,
    carrousel,
    currentFile,
    isSmall,
    isCustom,
    copyright,
}) => {
    const [image, setImage] = useState();
    const [fileList, setFileList] = useState([]);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageSelected, setImageSelected] = useState();
    const [images, setImages] = useState([]);
    const [visible, setVisible] = useState(false);
    const [displayingImage, setDisplayingImage] = useState("");
    const [copy, setCopy] = useState("");

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    useEffect(() => {
        if (getMedia) getMedia("carrousel_images", images);
    }, [images]);

    useEffect(() => {
        if (carrousel?.length > 0) {
            carrousel.map(async ({ key, copyright, isUploaded }) => {
                if (!isUploaded && key) {
                    getBase64FromUrl(cloudFrontUrl(key)).then((data) => {
                        setImages((currentImages) => [
                            ...currentImages,
                            {
                                base64: data,
                                copyright,
                                key,
                                isUploaded: true,
                            },
                        ]);
                    });
                }
            });
        }
    }, [carrousel]);

    useEffect(() => {
        function fetchImage() {
            // console.log({ currentFile });
            if (currentFile?.image) {
                setImage(cloudFrontUrl(currentFile.image));
            } else if (currentFile?.logo) {
                setImage(cloudFrontUrl(currentFile.logo));
                if (typeof currentFile.logo === "string") {
                    setImageSelected(cloudFrontUrl(currentFile.logo));
                }
            } else {
                setImage(
                    "https://www.brdtex.com/wp-content/uploads/2019/09/no-image-480x480.png"
                );
            }
        }
        fetchImage();
    }, [currentFile]);

    useEffect(() => {
        if (fileList.length <= 0) {
            setImageSelected("");
        }
    }, [fileList]);

    useEffect(() => {
        if (image !== undefined) {
            setFileList(
                currentFile?.image === undefined && currentFile?.logo === null
                    ? []
                    : [
                          {
                              uid: "-1",
                              name: currentFile?.image
                                  ? currentFile?.image
                                  : currentFile?.logo
                                  ? currentFile?.logo
                                  : "no media",
                              status: "done",
                              thumbUrl: image,
                              url: image,
                          },
                      ]
            );
        }
    }, [image, currentFile?.image || currentFile?.logo]);

    const onChange = ({ fileList: newFileList }) => {
        if (newFileList.length > 0) {
            setFileList([newFileList[newFileList.length - 1]]);
        } else {
            setFileList(newFileList);
        }
    };
    const onChangeMultiple = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const props = {
        customRequest: (file) => {
            dummyRequest(file);
        },
        // progress: {
        //   strokeColor: {
        //     "0%": "#108ee9",
        //     "100%": "#87d068",
        //   },
        //   strokeWidth: 3,
        //   format: () => `${parseFloat(95)}%`,
        // },
        name: "file",
        listType: "picture",
        // onChange(info) {
        //   const { status } = info.file;
        //   if (status !== "uploading") {
        //     console.log(info, "info");
        //   }
        //   if (status === "done") {
        //     message.success(`${info.file.name} file uploaded successfully.`);
        //   } else if (status === "error") {
        //     message.error(`${info.file.name} file upload failed.`);
        //   }
        // },
        beforeUpload: async (file) => {
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                message.error("You can only upload JPG/PNG file!");
            }
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                message.error("Image must smaller than 5MB!");
            }
            if (isJpgOrPng && isLt2M) {
                const tempImg = await getBase64(file);

                setImageSelected(tempImg);
                setImageLoaded(true);

                if (copyright && tempImg) {
                    // setUploadImages((images) => [...images, {base64:tempImg,file}]);
                    setImages((images) => [
                        ...images,
                        { base64: tempImg, file },
                    ]);
                }
                if (getFileReference) getFileReference(file);
            }
            return isJpgOrPng && isLt2M;
        },
    };

    const handleAddCopyright = (item) => {
        // setDisplayingImage(item);
        // setVisible((visible) => !visible);
        const imagesArr = [...images];
        const idx = imagesArr.findIndex((elem) => elem.base64 === item.base64);
        item.copyright = copy;
        setCopy("");
        imagesArr[idx] = item;
        setImages(imagesArr);
        // getMedia("carrousel_images", imagesArr);
        setVisible(false);
    };
    const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
                setImageLoaded(true);
            };
        });
    };

    return (
        <div>
            <Modal
                footer={false}
                closeIcon={
                    <CloseCircleFilled
                        className="modal-cancel-btn"
                        style={{
                            strokeWidth: "20", // --> higher value === more thickness the filled area
                            stroke: "white",
                            fill: "white",
                        }}
                    />
                }
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={"50vw"}
            >
                <Row justify="center" align="top" gutter={[12, 12]}>
                    <img
                        src={displayingImage.base64}
                        alt="alt"
                        style={{
                            display: "block",
                            maxWidth: "100%",
                            maxHeight: "50vh",
                            width: "auto",
                            height: "auto",
                            borderRadius: "10px",
                            marginBottom: "5vh",
                        }}
                    />
                </Row>

                <h3>Copyright notice</h3>
                <Row justify="center" align="top" gutter={[12, 12]}>
                    <Col flex="auto" xl={20} sm={24} md={20} xxl={20}>
                        <Input
                            value={copy}
                            placeholder="Add creator or copyright details..."
                            style={{ height: "50px" }}
                            onChange={(e) => setCopy(e.target.value)}
                            maxLength={22}
                        />
                    </Col>
                    <Col flex="auto" xl={4} sm={24} md={4} xxl={4}>
                        <PrimaryButton
                            htmlType="submit"
                            cssClassName="primary-button-small-altern"
                            title="SAVE"
                            onClick={() => handleAddCopyright(displayingImage)}
                        />
                    </Col>
                </Row>
            </Modal>
            {copyright && (
                <>
                    <Text text={label} />
                    <Dragger
                        {...props}
                        style={{ backgroundColor: "white", marginTop: 10 }}
                        listType="picture"
                        fileList={fileList}
                        onChange={onChangeMultiple}
                        showUploadList={false}
                        transformFile={undefined}
                        multiple={true}

                        // onPreview={onPreview}
                        // beforeUpload={beforeUpload}
                    >
                        <p className="ant-upload-hint">
                            Drop Image here to upload or <u>Choose file</u>
                        </p>
                    </Dragger>
                </>
            )}
            {imageLoaded && copyright && (
                <div className="media">
                    {images.map(({ base64: _img, file, copyright, key }) => (
                        <>
                            <div
                                className="attached-image-container"
                                key={`${_img}-image`}
                            >
                                <SourceImage
                                    onRemove={() =>
                                        setImages((images) =>
                                            images.filter(
                                                (i) => i.base64 !== _img
                                            )
                                        )
                                    }
                                    preview={false}
                                    width={140}
                                    alt={`${_img}-image`}
                                    height={90}
                                    src={_img}
                                />
                                <p
                                    className="copyright"
                                    onClick={() => {
                                        if (copyright) {
                                            setCopy(copyright);
                                        }
                                        let element = {
                                            base64: _img,
                                            file,
                                            copyright,
                                            key,
                                        };
                                        if (!file) {
                                            delete element.file;
                                        }
                                        if (key) {
                                            element.isUploaded = true;
                                        }
                                        setDisplayingImage(element);
                                        setVisible((visible) => !visible);
                                    }}
                                >
                                    {copyright
                                        ? `Ⓒ ${copyright}`
                                        : " + add copyright notice"}
                                </p>
                            </div>
                        </>
                    ))}
                </div>
            )}

            {!isSmall ? (
                !copyright && (
                    <div>
                        <Text text={label} />
                        {!isCustom && !copyright && (
                            <Dragger
                                {...props}
                                style={{
                                    backgroundColor: "white",
                                    marginTop: 10,
                                }}
                                listType="picture"
                                fileList={fileList}
                                onChange={onChange}
                                transformFile={undefined}

                                // onPreview={onPreview}
                                // beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-hint">
                                    Drop Image here to upload or{" "}
                                    <u>Choose file</u>
                                </p>
                            </Dragger>
                        )}

                        {fileList.length <= 0 && isCustom && !imageSelected ? (
                            <Dragger
                                {...props}
                                style={{
                                    backgroundColor: "white",
                                    marginTop: 10,
                                }}
                                listType="picture"
                                fileList={fileList}
                                onChange={onChange}
                                transformFile={undefined}

                                // onPreview={onPreview}
                                // beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-hint">
                                    Drop Image here to upload or{" "}
                                    <u>Choose file</u>
                                </p>
                            </Dragger>
                        ) : (
                            imageSelected &&
                            isCustom && (
                                <div>
                                    <img
                                        src={imageSelected}
                                        alt="avatar"
                                        style={{
                                            marginTop: "10px",
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "5px",
                                        }}
                                    />
                                    <span
                                        onClick={() => {
                                            getFileReference(null);
                                            setFileList([]);
                                        }}
                                        style={{
                                            cursor: "pointer",
                                            marginLeft: 10,
                                        }}
                                    >
                                        <TrashIcon />
                                    </span>
                                </div>
                            )
                        )}
                    </div>
                )
            ) : (
                <Row justify="left" align="middle">
                    <Col xs={24} md={24} lg={6} xl={6} xxl={6}>
                        <Text text={label} />
                    </Col>
                    <Col xs={24} md={24} lg={18} xl={18} xxl={18}>
                        <Dragger
                            {...props}
                            style={{ backgroundColor: "white", marginTop: 10 }}
                            listType="picture"
                            fileList={fileList}
                            onChange={onChange}
                            transformFile={undefined}
                            // onPreview={onPreview}
                            // beforeUpload={beforeUpload}
                        >
                            <p className="ant-upload-hint">
                                Drop Image here to upload or <u>Choose file</u>
                            </p>
                        </Dragger>
                    </Col>
                </Row>
            )}
        </div>
        // <Text text={label} />
        // {/* <ImgCrop
        //   rotate
        //   scale={100}
        //   zoom
        //   quality={1}
        //   cropperProps={{ aspect: 1 / 9 }}
        // > */}
        // <Dragger
        //   {...props}
        //   style={{ backgroundColor: "white", marginTop: 10 }}
        //   listType="picture"
        //   fileList={fileList}
        //   onChange={onChange}
        //   transformFile={undefined}
        //   // onPreview={onPreview}
        //   // beforeUpload={beforeUpload}
        // >
        //   <p className="ant-upload-hint">
        //     Drop Image here to upload or <u>Choose file</u>
        //   </p>
        // </Dragger>
        // {/* </ImgCrop> */}
        // </div>
    );
};
