import React, { useState, useContext } from "react";
import { Col, Layout, Row } from "antd";
import SurveyTemplateCard from "components/molecules/SurveyTemplateCard";
import {
  SurveyTemplateTwoIcon,
  SurveyTemplateOneIcon,
  SurveyTemplateThreeIcon,
  SurveyTemplateFourIcon,
} from "components/atoms/icons";
import SurveyCardDropdown from "components/molecules/SurveyCardDropdown";
import "./SurveyCardTemplate.scss";
import { options } from "pages/Surveys";
import { useHistory } from "react-router-dom";
import { QuestionContext } from "context/SurveyEditorContext";

const SurveyCardTemplate = ({ surveyItems }) => {
  const history = useHistory();
  const [show, showMore] = useState({ status: false, index: 0 });
  const { setSurveyTitle, setSurveyId } = useContext(QuestionContext);

  const setShowMore = (index) => {
    showMore((prev) => ({
      ...prev,
      status: show.status && index !== show.index ? prev.status : !prev.status,
      index: index,
    }));
  };
  const templateIcons = [
    { icon: SurveyTemplateTwoIcon, color: "#DAFFF7" },
    { icon: SurveyTemplateOneIcon, color: "#FAC6D4" },
    { icon: SurveyTemplateThreeIcon, color: "#EADDFF" },
    { icon: SurveyTemplateFourIcon, color: "#FCFFEE" },
  ];
  const handleClick = (title, id) => {
    setSurveyTitle(title);
    setSurveyId(id);
    history.push(`/home/surveys/editor/builder/${id}`);
  };
  return (
    <Layout>
      <Row gutter={[16, 16]}>
        {surveyItems.map((item, index) => (
          <Col xs={24} lg={12} xl={6} className="survey-item">
            <SurveyTemplateCard
              backgroundColor={
                templateIcons[index % templateIcons.length].color
              }
              Icon={templateIcons[index % templateIcons.length].icon}
              text={item.title}
              menu
              onMoreClick={setShowMore}
              show
              index={index}
              onClick={() => handleClick(item.title, item.id)}
            />
            {show.status && show.index === index && (
              <div className="survey-card-dropdown">
                <SurveyCardDropdown options={options} id={item} />
              </div>
            )}
          </Col>
        ))}
      </Row>
    </Layout>
  );
};

export default SurveyCardTemplate;
