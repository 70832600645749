import React, { Fragment, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { CreateElementInput } from "components/atoms/inputs";
import "./Map.scss";
import { Row, Col } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";
import { Text } from "components/atoms/texts";
import { textTruncate } from "utils/common";

export const MapComponent = ({ latitude, longitude, address, isFull }) => {
  const props = {
    center: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 15,
  };

  const renderMarkers = (map, maps) => {
    const mark = new maps.Marker({
      position: {
        lat: latitude,
        lng: longitude,
      },
      map,
      title: address,
    });
  };

  return (
    <div
      style={{
        height: isFull ? "100vh" : "40vh",
        width: "100%",
        zIndex: -3000,
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyARZ3O3LJt86JCLPBdPhbmULPk6wudF4VU",
        }}
        // defaultCenter={{ lat: latitude, lng: longitude }}
        center={{ lat: latitude, lng: longitude }}
        defaultZoom={props.zoom}
        draggable
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      ></GoogleMapReact>
    </div>
  );
};

export const LocationSearchInput = ({ getAddress, address }) => {
  const [state, setState] = useState({ address: address ? address : "" });
  useEffect(() => {
    setState({ address });
  }, [address]);

  const handleChange = (address) => {
    setState({ address });
  };

  const handleSelect = (address) => {
    setState({ address });
    geocodeByAddress(address).then((results) => {
      getLatLng(results[0])
        .then((latLng) => {
          getAddress({ address: address, ...latLng });
        })
        .catch((error) => console.error("Error", error));
    });
    // .then((latLng) => {
    //   console.log(address, " from google maps");
    //   getAddress({ address: address, ...latLng });
    // })
    // .catch((error) => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      value={state.address}
      onChange={handleChange}
      onSelect={handleSelect}
      debounce={500}
      highlightFirstSuggestion
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Fragment>
          <Row gutter={[0, 0]}>
            <Col flex="auto" span={24}>
              <CreateElementInput
                {...getInputProps({
                  placeholder: "Add a location for your item ...",
                })}
                style={{height:"50px"}}
                placeholder={"Add a location for your item ..."}
                label={"Location"}
                customValue={state.address}
              />
            </Col>
          </Row>
          <Row gutter={[0, 0]}>
            <Col flex="auto" span={24}>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      marginTop: 10,
                      // minHeight: "500px",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      marginTop: 10,

                      // minHeight: "500px",
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={Math.floor(Math.random() * 10000)}
                  >
                    {/* <PushpinOutlined size="large" /> */}
                    <div className="autocomplete-dropdown-container-box">
                      <Row style={{ borderBottom: 10 }} gutter={[12, 0]}>
                        <Col>
                          <EnvironmentFilled
                            size="large"
                            style={{ color: "grey", fontSize: 20 }}
                          />
                        </Col>
                        <Col>
                          <span>
                            {textTruncate(suggestion.description, 62, "...")}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Fragment>
        // <div>
        //   {/* <input
        //     {...getInputProps({
        //       placeholder: "Search Places ...",
        //       className: "form-item",
        //     })}
        //   /> */}

        //   <div className="autocomplete-dropdown-container">

        //   </div>
        // </div>
      )}
    </PlacesAutocomplete>
  );
};

export const WidgetLocationSearchInput = ({ getAddress, address }) => {
  const [state, setState] = useState({ address: address ? address : "" });

  useEffect(() => {
    setState({ address });
  }, [address]);
  const handleChange = (address) => {
    setState({ address });
  };

  const handleSelect = (address) => {
    setState({ address });
    geocodeByAddress(address).then((results) => {
      getLatLng(results[0])
        .then((latLng) => {
          getAddress({ address: address, ...latLng });
        })
        .catch((error) => console.error("Error", error));
    });
    // .then((latLng) => {
    //   console.log(address, " from google maps");
    //   getAddress({ address: address, ...latLng });
    // })
    // .catch((error) => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      value={state.address}
      onChange={handleChange}
      onSelect={handleSelect}
      debounce={500}
      highlightFirstSuggestion
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Fragment>
          <Row gutter={[24, 12]}>
            <Col flex="auto" span={24}>
              <CreateElementInput
                {...getInputProps({
                  placeholder: "Search a location...",
                  className: "form-item-search",
                })}
                placeholder={"Search a location ..."}
                // label={"Location"}
              />
            </Col>
          </Row>
          <Row>
            <Col flex="auto" span={24}>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      // marginTop: 10,
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      // marginTop: 10,
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={suggestion}
                  >
                    {/* <PushpinOutlined size="large" /> */}
                    <div className="autocomplete-dropdown-container-box">
                      <Row style={{ borderBottom: 10 }}>
                        <Col style={{ marginLeft: 15, marginTop: 5 }}>
                          <h5 style={{ fontWeight: "bolder" }}>
                            {suggestion.description}
                          </h5>
                          {/* <Text text={suggestion.description} /> */}
                        </Col>
                      </Row>
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Fragment>
        // <div>
        //   {/* <input
        //     {...getInputProps({
        //       placeholder: "Search Places ...",
        //       className: "form-item",
        //     })}
        //   /> */}

        //   <div className="autocomplete-dropdown-container">

        //   </div>
        // </div>
      )}
    </PlacesAutocomplete>
  );
};
