import React, { useContext } from "react";
import { Col, Image, Row } from "antd";
import { Text } from "components/atoms/texts";
import { useHistory } from "react-router";
import { I18nContext } from "utils/i18n/locale";
import "./KnowledgeCategoryCard.scss";
import MemoizedAvatar from "../ProjectSelect/Avatar";
import { cloudFrontUrl } from "utils/common";

const KnowledgeCategoryCard = ({
  image,
  title,
  description,
  noOfPosts,
  isLocal,
}) => {
  const { translate } = useContext(I18nContext);
  const history = useHistory();
  return (
    <div
      className="knowledge-category-card"
      onClick={() =>
        history.push(`/home/knowledge/${title.replaceAll(" ", "-")}`)
      }
    >
      <Row>
        <Col xs={6} lg={6} xl={5} xxl={4}>
          <MemoizedAvatar
            className="knowledge-category-card-image"
            // size={150}
            radius={0}
            src={cloudFrontUrl(image)}
            // logo={image}
            localImage={isLocal ? image : ""}
          />
          {/* <Image
            preview={false}
            src={image?.base64 || image}
            className="knowledge-category-card-image"
          /> */}
        </Col>
        <Col
          xs={18}
          lg={18}
          xl={19}
          xxl={20}
          className="knowledge-category-card-text"
        >
          <Text text={title} className="knowledge-category-title" />
          <Text
            text={description ?? "No description provided for this category"}
            className="knowledge-category-description"
          />
          <Text
            text={`${noOfPosts ? noOfPosts : 0} ${
              translate("pages").knowledge.texts.noOfPosts
            }`}
            className="knowledge-category-number-posts"
          />
        </Col>
      </Row>
    </div>
  );
};

export default KnowledgeCategoryCard;
