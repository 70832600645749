import React from "react";
import { Layout } from "antd";
import "./ProfileTemplate.scss";
import TabsMenu from "components/atoms/tabs";
import ProfileInfoOrganism from "components/organisms/ProfileInfoOrganism";
import LatestActivity from "components/organisms/LatestActivityOrganism";

const ProfileTemplate = ({ hideFollower, hideActivity, title, citizen }) => {
  return (
    <Layout className="profile-template-container">
      <div className="title">{title || "My Profile"}</div>
      <TabsMenu
        // onChange={(item) => console.log(item)}
        options={[
          {
            title: "Information",
            key: "information",
            children: (
              <ProfileInfoOrganism
                hideFollower={hideFollower}
                citizen={citizen}
              />
            ),
          },
          {
            title: "Latest Activity",
            key: "latest",
            children: <LatestActivity />,
            hide: hideActivity !== undefined ? hideActivity : true,
          },
        ].filter((option) => !option.hide)}
      />
    </Layout>
  );
};

export default ProfileTemplate;
