import { useState } from "react";
import { useSpring, animated } from "react-spring";
import { ReactComponent as DownArrow } from "assets/svgs/downArrow.svg";
import { ReactComponent as UpArrow } from "assets/svgs/upArrow.svg";
import "client/components/atoms/collapse/collapse.scss";

const Collapse = ({ closedColor, openColor, titleText, bodyText }) => {
  const [isOpen, toggleOpen] = useState(false);
  const styles = useSpring({
    opacity: isOpen ? 1 : 0,
    marginTop: isOpen ? 0 : -10,
    config: { duration: 200 },
  });
  return (
    <div className="collapse-container">
      <div
        className="collapse-header"
        style={{ backgroundColor: isOpen ? openColor : closedColor }}
        onClick={() => toggleOpen(!isOpen)}
      >
        <p clasName="collapse-title">{titleText}</p>
        {isOpen ? <DownArrow /> : <UpArrow />}
      </div>
      {isOpen ? (
        <animated.div
          key={Math.floor(Math.random) * 100}
          className="collapse-body-container"
          style={styles}
        >
          <p className="collapse-body-text">{bodyText}</p>
        </animated.div>
      ) : null}
    </div>
  );
};

export default Collapse;
