import React, { useContext, useState } from "react";
import { Row, Col, Image, Divider } from "antd";
import "./FlagDetailsOrganism.scss";
import { I18nContext } from "utils/i18n/locale";
import { MapPinSvg, PersonIcon } from "components/atoms/icons";
import colors from "config/styles/colors.json";
import Map from "components/atoms/map";
import { Storage } from "aws-amplify";
import { cloudFrontUrl } from "utils/common";

const InfoItem = ({ head, value, direction = "row" }) => (
  <div className={`flag-details-info-item details-info-item-${direction}`}>
    <b>{head}:</b> {value}
  </div>
);

const FlagDetailsOrganism = (props) => {
  const { translate } = useContext(I18nContext);
  const [image, setImage] = useState();

  // const getImage = async (key) => {
  //   if (key !== null && key !== undefined) {
  //     const url = await Storage.get(key);
  //     return url.toString(); // get key from Storage.list
  //   } else {
  //     return "https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder-1024x512.png";
  //   }
  // };
  const getImage = async (key) => {
    if (key !== null && key !== undefined) {
      return key;
    } else {
      return "https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder-1024x512.png";
    }
  };

  const printImage = async () => {
    setImage(await getImage(props?.images[0]));
    // console.log(await getImage(item?.image));
  };

  // printImage();

  console.log(props?.images);

  return (
    <Col className="flag-details-organism-container">
      <Row gutter={[18, 0]} className="info-container">
        <Col className="flag-details-image-container" lg={11} sm={24}>
          <Image
            className="flag-details-main-image"
            src={
              props?.images[0] !== null && props?.images[0] !== undefined
                ? cloudFrontUrl(props?.images[0])
                : "https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder-1024x512.png"
            }
          />
          {/* <Image className="flag-details-main-image" src={image} /> */}
        </Col>
        <Col lg={13} sm={24}>
          {[
            {
              key: "residentName",
              value: props?.resident
                ? props?.resident
                : props.user_report_reported_byTouser
                ? `${props.user_report_reported_byTouser?.name} ${props.user_report_reported_byTouser?.last_name}`
                : "Anonymous",

              // value: props.user_report_reported_byTouser
              //   ? `${props.user_report_reported_byTouser?.name} ${props.user_report_reported_byTouser?.last_name}`
              //   : "",
            },
            { key: "category", value: props.typename?.typename_category?.name },
            {
              key: "created",
              value: new Date(props.created_at).toDateString(),
            },
            { key: "department", value: props.department?.name },
            { key: "typename", value: props.typename?.name },
          ].map((item) => (
            <InfoItem
              head={translate("pages").flags.flagDetails[item.key]}
              value={item.value}
              key={item.key}
            />
          ))}
          <Divider />
          {[
            { key: "status", value: props.status ? props.status : "pending" },
            { key: "description", value: props.description },
          ].map((item) => (
            <InfoItem
              head={translate("pages").flags.flagDetails[item.key]}
              value={item.value}
              key={item.key}
            />
          ))}
          <Divider />
          <InfoItem
            direction="column"
            head={translate("pages").flags.flagDetails.location}
            value={
              <div className="flag-details-icon-text">
                <MapPinSvg />
                {props.formatted_address}
              </div>
            }
          />
          <InfoItem
            direction="column"
            head={translate("pages").flags.flagDetails.reporter}
            value={
              <div className="flag-details-icon-text classified">
                <PersonIcon color={colors.classified_color} />
                {props.user_report_reported_byTouser
                  ? `${props.user_report_reported_byTouser.name} ${props.user_report_reported_byTouser.last_name}`
                  : "Classified"}
              </div>
            }
          />
        </Col>
      </Row>
      <Map location={{ lat: props.latitude, lng: props.longitude }} />
    </Col>
  );
};

export default FlagDetailsOrganism;
