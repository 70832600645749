import React, { useContext } from "react";
import { Col } from "antd";
import "./FlagCommentsOrganism.scss";
import { I18nContext } from "utils/i18n/locale";
import CommentItem from "components/molecules/CommentItem";
import emptyCommentsSvg from "assets/img/no-comments.svg";

// missing endpoint docs for comments

const comments = [
  {
    username: "Cityflag",
    text: "You created this task by copying #Bpkh06(You Don't Have Access)",
    image: `https://i.pravatar.cc/100?u=${Math.random() * 100}`,
    time: '2:11 PM'
  },
  {
    username: "Pooja",
    text: "You set the task priority high? am i right ? Please let me know.. Thanks",
    repliedTo: "Brandon",
    time: '2:11 PM'
  }
]

const CommentsPlaceholder = () => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="flag-comments-placeholder-root">
      <img src={emptyCommentsSvg} alt={translate("pages").flags.flagDetails.commentListPlaceholder}/>
      <div>
        {translate("pages").flags.flagDetails.commentListPlaceholder}
      </div>
    </div>
  )
}

const FlagCommentsOrganism = (props) => {
  const { translate } = useContext(I18nContext);

  return (
    <Col className="flag-comments-organism-container">
      <div className="heading">{translate("pages").flags.flagDetails.comments}</div>
      {
        comments && comments.length > 0 && comments.map(comment => <CommentItem className="comment-item" {...comment}/>)
      }
      {
        (!comments || comments.length === 0) && <CommentsPlaceholder />
      }
    </Col>
  );
};

export default FlagCommentsOrganism;
