import React, {useContext} from 'react';
import "./Eventsmeetingcards.scss";
import { Text } from "components/atoms/texts";
import {NotificatioMainIcon, NotificationBuzzIcon, SnoozeIcon} from "components/atoms/icons";
import CardBackgroundGreen from "assets/img/illustrations/card-background-green.svg";
import CardBackgroundRose from "assets/img/illustrations/card-background-rose.svg";
import CardBackgroundCommon from "assets/img/illustrations/card-background-common.svg";
import { Image } from "antd";
import { I18nContext } from "utils/i18n/locale";
import { useHistory } from 'react-router-dom';


const IconRenderer = ({ snooze, notify }) => {
  let value = []
  if(snooze){
    value.push(
      <SnoozeIcon color={"rgba(20, 20, 20, 0.5)"} />
    )
  }
  else if(notify && !snooze){
    value.push(
      <NotificationBuzzIcon color={"rgba(20, 20, 20, 0.5)"} />
    )
  }
  else{
    value.push(
      <NotificatioMainIcon color={"rgba(20, 20, 20, 0.5)"} />
    )
  }
  return value
}

const EventsMeetingCards = ({events}) => {
  const { translate } = useContext(I18nContext)

  const vectors = [
    {color: "#FFEFFF", vector: CardBackgroundRose},
    {color: "#E5FFFA", vector: CardBackgroundGreen},
    {color: "#F8FFD3", vector: CardBackgroundCommon},
    {color: "#FDEEF2", vector: CardBackgroundCommon}
  ]

  const history = useHistory()

  const randomElement = vectors[Math.floor(Math.random() * vectors.length)]; 
  return (
    
    <div className="event-card" onClick={() => history.push(`/client/events/viewevent/?id=${events.id}`)} >
      <div className="events-card-details-conatiner" style={{backgroundColor: randomElement.color }}>
        <div className="detailer" >
          <div className="details-container">
            <Text className="meeting-type" text={events.type} />
            <Text className="topic" text={`${translate("client").pages.events.meetingCard.topic}: ${events.topic}`} />
          </div>
          <div className="vectors">
            <Image className="background-vector" src={randomElement.vector} preview={false} />
          </div>
        </div>
        <div className="timings-container">
          <Text className="timing-text" text={events.timing} />
          <IconRenderer snooze={events.snooze} notify={events.notify} />
        </div>
      </div>
      
    </div>
  )
}


export default EventsMeetingCards;