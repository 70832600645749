import React from "react";
import { Row, Col } from "antd";
import { PrimaryButton } from "components/atoms/controls";
import { SourceImage } from "components/atoms/media";
import { Title } from "components/atoms/texts";
import noNews from "assets/img/illustrations/no-news.svg";

import "./EmptyComponent.scss";

const EmptyComponent = ({
  image,
  title,
  subtitle,
  buttonText,
  onButtonClick,
  buttonIcon,
  long,
}) => {
  return (
    <div className="empty-placeholder-root">
      <Row justify="center">
        <Col flex="auto">
          <div style={{ textAlign: "center" }}>
            <SourceImage
              preview={false}
              src={image || noNews}
              alt="logo"
              className={!long ? "ilustration" : "ilustration-long"}
            />
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Title level={3} text={title} />
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Title level={2} text={subtitle} />
        </Col>
      </Row>
      <Row gutter={[24, 40]} justify="center">
        <Col>
          <PrimaryButton
            icon={buttonIcon}
            cssClassName="primary-button"
            title={buttonText}
            onClick={() => {
              onButtonClick();
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EmptyComponent;
