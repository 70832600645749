import React, { lazy, Suspense } from "react";
import { SpiningPage } from "components/atoms/icons";
const ProfileTemplate = lazy(() => import("components/templates/Profile"));

const renderLoader = () => <SpiningPage opacity={1} />;

const Profile = () => {
  return (
    <Suspense fallback={renderLoader()}>
      <ProfileTemplate />
    </Suspense>
  );
};

export default Profile;
