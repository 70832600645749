import React, {
  useContext,
  Suspense,
  useState,
  useEffect,
  createRef,
  useRef,
  useCallback,
} from "react";
import moment from "moment";
import Picker from "emoji-picker-react";
import { SpiningPage, StarIcon } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import { Col, Divider, Popover, Row } from "antd";
import Nav from "components/templates/Communication/Nav";
import UserProfileBadge from "components/molecules/UserProfileBadge";
import { EmojiIcon } from "components/atoms/icons";
import ComposeEmailFooter from "components/molecules/ComposeEmailFooter";
import { PrimaryButton } from "components/atoms/controls";
import AttachmentCard from "components/molecules/AttachementCard";
import "./singlemail.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  archiveEmail,
  createReply,
  getEmail,
  starrEmail,
} from "redux/slices/communication/emails";
import {
  ArrowLeftOutlined,
  FolderOpenOutlined,
  SwapOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Storage } from "aws-amplify";
import { FileUploader } from "components/atoms/media";
import { makeId, getCurrentUser } from "utils/common";

const FILE_TYPES = {
  application: "document",
  image: "image",
  video: "video",
  audio: "audio",
  "": "document",
};

const POPOVER_CONTENT = {
  ARCHIVE: <span>Click here to archive this conversation</span>,
  RESTORE: <span>Click here to restore this conversation</span>,
};

const SingleMail = () => {
  const { translate } = useContext(I18nContext);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const displayingEmailFetched = useSelector(
    (state) => state.emails.displayingEmail
  );
  const loading = useSelector((state) => state.emails.loading);
  const [loadingAction, setLoadingAction] = useState(false);
  const [displayingEmail, setDisplayingEmail] = useState(
    displayingEmailFetched
  );
  const [fileList, setFileList] = useState([]);
  const [replies, setReplies] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [inputs, setInputs] = useState({
    messageBox: "",
  });
  const [referenceNode, setReferenceNode] = useState();
  const [hidden, setHidden] = useState(true);
  const endRef = createRef();
  const topRef = createRef();
  const isMounted = useRef(false);
  const current_user = getCurrentUser();
  const rightIcons = [];

  useEffect(() => {
    return () => referenceNode?.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (isMounted.current) changeHidden(true);
  }, []);

  useEffect(() => {
    setDisplayingEmail(displayingEmailFetched);
    setReplies(displayingEmailFetched?.mail?.mail_reply);
  }, [displayingEmailFetched]);

  useEffect(() => {
    scrollTo(endRef);
  }, [replies]);

  useEffect(() => {
    dispatch(getEmail(params?.id));
  }, [params]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  const onClickNavIcon = () => {
    if (isMounted.current) changeHidden(true);
    history.goBack();
  };
  const onArchive = () => {
    dispatch(archiveEmail(displayingEmail.id ?? params?.id))
      .then(unwrapResult)
      .then((data) => {
        history.goBack();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onStarr = () => {
    dispatch(starrEmail(displayingEmail.id ?? params?.id))
      .then(unwrapResult)
      .then((data) => {
        let auxDisplayingEmail = {
          ...displayingEmail,
          starred: !displayingEmail.starred,
        };
        setDisplayingEmail(auxDisplayingEmail);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const leftIcons = [
    {
      component: <ArrowLeftOutlined />,
      clickAction: onClickNavIcon,
      cssClass: "outline",
    },
    !displayingEmail.archive
      ? {
          component: (
            <StarIcon filled={displayingEmail.starred ? "true" : "false"} />
          ),
          clickAction: onStarr,
          cssClass: "outline",
        }
      : "",
    !displayingEmail.archive
      ? {
          component: (
            <Popover content={POPOVER_CONTENT.ARCHIVE}>
              <FolderOpenOutlined />
            </Popover>
          ),
          clickAction: onArchive,
          cssClass: "outline",
        }
      : "",
    displayingEmail.archive
      ? {
          component: (
            <Popover content={POPOVER_CONTENT.RESTORE}>
              <SwapOutlined />
            </Popover>
          ),
          clickAction: onArchive,
          cssClass: "outline",
        }
      : "",
  ];

  const handleUpload = async (file) => {
    const uploadName = makeId(10) + "." + file.name.split(".").pop();
    return new Promise((resolve, reject) => {
      Storage.put("attachments/" + uploadName, file, {
        contentType: file.type,
        contentDisposition: "attachment",
      }).then((result) => {
        resolve({
          type: getFileType(file.type),
          url: result.key,
          name: file.name,
          size: (file.size / 1024).toFixed(1) + " kb",
        });
      });
    });
  };

  const onSave = (item, attachments = []) => {
    dispatch(createReply(item))
      .then(unwrapResult)
      .then((data) => {
        let auxReply = {
          id: item.id,
          body: item.body,
          reply_attachment: attachments,
          user: current_user,
        };
        setLoadingAction(false);
        setReplies([...replies, auxReply]);
        setInputs({ messageBox: "" });
        setFileList([]);
      })
      .catch((error) => {
        setLoadingAction(false);
        console.log(error);
      });
  };

  const onFinish = () => {
    setLoadingAction(true);
    let createNewReply = {
      id: displayingEmail?.mail?.id,
      body: inputs.messageBox,
    };
    if (fileList.length > 0) {
      const fileInfo = Promise.all(fileList.map(handleUpload)).then(
        (results) => {
          createNewReply.attachments = results;
          onSave(createNewReply, results);
        }
      );
    } else {
      setLoadingAction(true);
      onSave(createNewReply);
    }
  };

  const onShowEmojiClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleClickOutside = (e) => {
    if (e.target.className !== "emoji-img") {
      setShowEmojiPicker(false);
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    const myElement = document.getElementById("reply-mail-box");
    const startPosition = myElement.selectionStart;

    const boxTextValue =
      inputs.messageBox !== ""
        ? inputs?.messageBox?.slice(0, startPosition) +
          " " +
          emojiObject.emoji +
          " " +
          inputs?.messageBox?.slice(startPosition + 1)
        : emojiObject.emoji + " ";

    setInputs((inputs) => ({
      ...inputs,
      messageBox: boxTextValue,
    }));
  };

  const onChangeText = (e) => {
    e.persist();
    setInputs((inputs) => ({
      ...inputs,
      messageBox: e.target.value,
    }));
  };

  const getFileType = (type) => {
    let newType = type.split("/");
    return FILE_TYPES[newType[0]];
  };

  function handleScroll(event) {
    let node = event.target;
    if (
      node.scrollTop > 500 &&
      node.scrollHeight - node.scrollTop >= 500 &&
      node.scrollTop !== 0
    ) {
      if (isMounted.current && hidden) changeHidden(false);
    } else {
      if (isMounted.current && !hidden) changeHidden(true);
    }
  }

  const paneDidMount = (node) => {
    if (node) {
      node.addEventListener("scroll", handleScroll);
      setReferenceNode(node);
    }
  };

  const changeHidden = useCallback((state) => {
    setHidden(state);
  }, []);

  const scrollTo = (ref) => {
    if (ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const LoadMore = React.memo((props) => {
    return (
      <div
        style={{ position: "relative", width: "100%" }}
        onClick={() => {
          props.action();
        }}
      >
        <div className="show-more-container">
          <span>
            <UpOutlined /> <br />
          </span>
          <span>Show More</span>
        </div>
      </div>
    );
  });

  return (
    <Suspense fallback={<SpiningPage opacity={1} />}>
      <br />
      {loading ? (
        <SpiningPage opacity={1} />
      ) : (
        <div>
          <Nav
            rightIcons={rightIcons}
            leftIcons={leftIcons}
            totalMail="240"
            pagination={false}
            title={displayingEmail?.mail?.subject}
          />
          {!hidden && <LoadMore action={() => scrollTo(topRef)} />}
          <div
            id="conversation"
            className="conversation-container"
            ref={paneDidMount}
          >
            <div ref={topRef}></div>
            {replies?.map((mail) => (
              <div
                className="email-message-box"
                style={
                  current_user?.email !== mail?.user?.email
                    ? { borderTopLeftRadius: 0 }
                    : { borderTopRightRadius: 0 }
                }
              >
                <div className="recipient-stamp-box">
                  {current_user?.email !== mail?.user?.email ? (
                    <>
                      <div
                        style={
                          current_user?.email !== mail?.user?.email
                            ? { textAlign: "left" }
                            : { textAlign: "right" }
                        }
                      >
                        <UserProfileBadge
                          image={mail?.user.image}
                          name={`${mail?.user?.name}`}
                          email={mail?.user?.email}
                          isMail
                        />
                      </div>
                      <div
                        className="time-stamp"
                        style={
                          current_user?.email !== mail?.user?.email
                            ? { textAlign: "right" }
                            : { textAlign: "left" }
                        }
                      >
                        {moment(mail?.created_at).format("DD/MM/YYYY HH:mm a")}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="time-stamp"
                        style={{ marginRight: "50%", textAlign: "left" }}
                      >
                        {moment(mail?.created_at).format("DD/MM/YYYY HH:mm a")}
                      </div>
                      <div>
                        <UserProfileBadge
                          image={mail?.user.image}
                          name={`${current_user?.name} ${current_user?.last_name}`}
                          email={mail?.user?.email}
                          isMail
                        />
                      </div>
                    </>
                  )}
                </div>
                <Divider type="horizontal" />
                <div className="mail-content">{mail?.body}</div>
                <Row justify="left" align="middle" gutter={[36, 0]}>
                  {mail?.reply_attachment?.length > 0 &&
                    mail?.reply_attachment.map((attachment) => (
                      <Col>
                        <AttachmentCard
                          text={attachment.name}
                          fileSize={attachment.size}
                          type={attachment.type}
                          url={attachment.url}
                        />
                      </Col>
                    ))}
                </Row>
              </div>
            ))}
            <div ref={endRef}></div>
          </div>
          <ComposeEmailFooter
            cssClass="compose-new-mail-footer size-100 bg-white"
            hasReplyBox="true"
            placeholder={
              translate("pages").communication.email.compose.placeholder.reply
            }
            id="reply-mail-box"
            inputValue={inputs.messageBox}
            onChange={onChangeText}
          >
            <>
              {/* <AtMentionIcon /> */}
              <FileUploader
                showUploadList={false}
                fileList={fileList}
                setFileList={setFileList}
                showIcon={true}
                isInput
              />
              {showEmojiPicker ? <Picker onEmojiClick={onEmojiClick} /> : ""}
              <EmojiIcon onClick={onShowEmojiClick} />
              <PrimaryButton
                htmlType="submit"
                loading={loadingAction}
                onClick={onFinish}
                cssClassName="save-btn"
                title={
                  translate("pages").communication.email.compose.buttons.send
                }
              />
            </>
          </ComposeEmailFooter>
          <FileUploader
            showUploadList={true}
            fileList={fileList}
            setFileList={setFileList}
            showIcon={false}
          />
        </div>
      )}
    </Suspense>
  );
};
const MemoizedSingleMail = React.memo(SingleMail);
export default MemoizedSingleMail;
