import React from "react";
// import { useEffect, useState } from "react";
// import { Storage } from "aws-amplify";
import Avatar from "react-avatar";
import "./Avatar.scss";

const Image = React.memo(function Image({
  src,
  size,
  isSquare,
  radius,
  className = "image",
  onClick,
}) {
  return (
    <img
      alt={className}
      width={isSquare ? "100%" : size}
      height={isSquare ? "100%" : size}
      style={{
        borderRadius: radius,
        objectFit: "cover",
        overflow: "hidden",
      }}
      src={src}
      className={`${className} ${
        isSquare ? "square-image" : ""
      } "memoized-avatar`}
      onClick={onClick}
    />
  );
});

const ExportedAvatar = ({
  logo,
  size,
  radius,
  className,
  customPlaceHolder,
  localImage,
  isSquare,
  onClick,
  noPlaceHolder,
  src,
  avatarName,
  avatarSize = 50,
  fontSize=20,
  userName,
}) => {
  // const [image, setImage] = useState();
  // const getImage = async (key) => {
  //   const image = await Storage.get(key);
  //   setImage(image);
  //   return image;
  // };

  // useEffect(() => {
  //   getImage(logo);
  // }, [logo]);

  return (
    <>
      {avatarName ? (
        <Avatar
          name={userName}
          size={avatarSize}
          round="100px"
          style={{ letterSpacing: 1, fontSize, fontFamily:"IBMPlex-SemiBold" }}
        />
      ) : (
        <Image
          src={
            localImage !== undefined &&
            localImage !== null &&
            localImage !== [] &&
            localImage !== {} &&
            localImage !== ""
              ? localImage
              : avatarName
              ? avatarName
              : src
              ? src
              : customPlaceHolder
              ? customPlaceHolder
              : noPlaceHolder
              ? null
              : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAHlBMVEX09PTh4eH19fXg4ODk5OTw8PDs7Ozq6uru7u7n5+dZKxXMAAAELUlEQVR4nO2dWXKtMAwFwQMX9r/hB9RNMOARhCTyTv/kIxVQl4kHYZmuAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADgAsZ03o9eA6OfwyH360bXWz30kyd2HHvbq8LaibQdJ2V+C7Yf6RSdQsEZOxApGqWCsyJNK5qPVsEZCsHOS1tksBNFI2rsZTY8gaG0QxaCRjRj0ITS4/wvW0judguaKbjaID1f+xLEZG8/psZtV6OeKF3GbH2DHW9fbDN0WgTn/j0wvBtVYEjSM9MAwxZgKAMMW4ChDLyGxoQ/eGA0NMYPk3O9c9PH80nyGZo1A/e9l3WEiZM8DxmeZm3GH/Ib1jFNXZna0AzntTFZcigPj2E8f2M/HIoshqkEFYsih+Fu7b9XZOhvWNow4bdw85YVMBjmkqg0Kb4sHG2YaUKaFF+W5w3zefDnO5vnR/wwPxXh8YwOw1Oaz4Pbmzct8ryhLxhe+EdsivRxw/RgePW2821a/uZ5w8ILt/auZp7Ct8Qqb9g6/17XKA3BKnhK227yswirjvZtPc22yqwN912jxW6bQGW87xrx93mCuoBfNWs7JkKqIuaYeWcf04brn3ey1ITMsXrK7F9oWD1Ft+pUxPyeFXB8L1I5aJYsRnKbTcM9E5utyleQzUTVz2eSu8mKYb8km5jbLleI+xUZ4cJ+wHzgr8jqFzY85iNnfPe0vJn5uVff8mamuKMzGzrn27Xu9+3a4Bu2JddsWc3Erv4NaeWe3HTw6t9yV246Tkev3bB6V3UyfOWGDdvGU/HrNmzbFx8X0GzYvPE/asA14l+hubIhqqC4DS+UbsQc9Bpeq005S2g1vFx8c7LQani5uuikIWhohnR5wI3yqaOHnOG8ZLQpxVv1YYd1p5jhuiZOKN4sgNsrShl+F/0xxfsVfjtFoRE/yGqcfkdQwhgqyrRhmLY5tiJJjWbwvkfEcJeXOjyoNEWoVrYND4m3UJGqyla2DU+ZxUCRqoxY1DCWOv3pbsjqpCUNo4LfVqQrBBc0jAquioaylF/OMCG4KlKW8j9vmBjxk4LUSLUhm6CUIZ+gkCGjoIwhp6CIIaughCGvoIAhsyC/Ibcg+4jPLsjdhvyCzIYCgryGEoK8hoV93u83lDn+i9VQ4iGFIQwrDN2fN/z7bQhDGMIQhjCEYdnwvxrxPyKnljIadn6QgNOwMxKwGgoDwxZgKAMMW4ChDE/N2kiCoyA4eYTgPO/t/PPluyc6COrICc5kDybZ1jodhPP+u4LFg1qkofjXyR9jIgzFaaKl84Rkoen9FDci1Tmb0h5JqM6gNFo7m2R5zgVFbd8kW5nHZzJOx0MowNqhHHiL4+h2H7ARZvl4HvUHAtfD1yfpycyX6TPSfwCxE8o+JXhADwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAs/ANyGFT0fw3sTAAAAABJRU5ErkJggg=="
          }
          // src={logo ? image : "https://picsum.photos/800"}
          size={size}
          isSquare={isSquare}
          radius={radius}
          className={className}
          onClick={onClick}
        />
      )}
    </>
  );
};
const MemoizedAvatar = React.memo(ExportedAvatar);
export default MemoizedAvatar;
