import { Divider, Drawer, Image } from "antd";
import { NotificationContextProvider } from "client/context/NotificationContext";
import { SideBarCloseIcon } from "components/atoms/icons";
import { Text } from "components/atoms/texts";
import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import arcadisSvg from "assets/img/arcadis.svg";
import noProject from "assets/img/no-project.svg";
import "./ClientSidebar.scss";
import { useHistory } from "react-router";
import { ReactComponent as DownArrowSvg } from "components/client/components/atoms/DownArrow.svg";
import { ReactComponent as HomeIcon } from "assets/svgs/HomeSvg.svg";
import { ReactComponent as NewsFeedIcon } from "assets/svgs/NewsSvg.svg";
import { ReactComponent as RewardIcon } from "assets/svgs/RewardSvg.svg";
import { ReactComponent as InfoIcon } from "assets/svgs/InfoSvg.svg";
import { ReactComponent as FAQIcon } from "assets/svgs/faqs.svg";
import { ReactComponent as SurveyCommentIcon } from "assets/svgs/SurveySvg.svg";
import { ReactComponent as AvatarIcon } from "assets/svgs/profile.svg";
import { ReactComponent as ResourceIcon } from "assets/svgs/resources.svg";
import { ReactComponent as LogoutIcon } from "assets/svgs/logout.svg";
import { ReactComponent as CommunicationIcon } from "assets/svgs/communicationSvg.svg";

import { I18nContext } from "utils/i18n/locale";
import { GotoIcon } from "components/atoms/icons";
import { ReactComponent as EventsIcon } from "assets/svgs/CalendarSvg.svg";
import { Link } from "react-router-dom";
const ClientSidebar = ({ project }) => {
  const { sidebarVisible, setSidebarVisible } = useContext(
    NotificationContextProvider
  );
  const history = useHistory();

  const { translate } = useContext(I18nContext);

  const [currentStyle, setCurrentStyle] = useState(false);
  const customClient = useSelector((state) => state.citizen.custom);

  useEffect(() => {
    console.log("customClient", customClient);
    if (customClient != null) {
      setCurrentStyle(true);
    } else {
      setCurrentStyle(false);
    }
  }, [customClient]);

  const profileClick = () => {
    setSidebarVisible(false);

    console.log("click");

    const route = currentStyle ? `/launchsa/profile/` : `/client/profile/`;
    history.push({
      pathname: route,
    });
  };
  return (
    <Drawer
      visible={sidebarVisible}
      getContainer={false}
      onClose={() => setSidebarVisible(false)}
      className="client-sidebar"
      width="80%"
      closable={true}
      closeIcon={<SideBarCloseIcon />}
    >
      <div className="client-sidebar-wrapper"></div>
      <div className="client-sidebar-content">
        <Link
          to={currentStyle ? "/launchsa/home" : "/client/home"}
          className="segment"
          onClick={() => setSidebarVisible(false)}
        >
          <HomeIcon color="rgba(20, 20, 20, 0.24)" />
          <Text
            text={translate("client").sidebar.home}
            className="segment-text"
          />
        </Link>
        <Link
          to={currentStyle ? "/launchsa/profile" : "/client/profile"}
          className="segment"
          onClick={() => setSidebarVisible(false)}
        >
          <AvatarIcon
            color="rgba(20, 20, 20, 0.24)"
            height="20px"
            width="22px"
          />
          <Text
            text={translate("client").sidebar.profile}
            className="segment-text"
          />
        </Link>
        <Divider className="sidebar-divider" />
      </div>
      <div className="client-sidebar-content">
        <Link
          to={currentStyle ? "/launchsa/survey" : "/client/survey"}
          className="segment"
          onClick={() => setSidebarVisible(false)}
        >
          <SurveyCommentIcon color="rgba(20, 20, 20, 0.24)" />
          <Text
            text={
              currentStyle
                ? translate("client").sidebar.questionaries
                : translate("client").sidebar.surveys
            }
            className="segment-text"
          />
        </Link>
        <Link
          to={
            currentStyle ? "/launchsa/communication" : "/client/communication"
          }
          className="segment"
          onClick={() => setSidebarVisible(false)}
        >
          <CommunicationIcon color="rgba(20, 20, 20, 0.24)" />
          <Text
            text={translate("client").sidebar.communication}
            className="segment-text"
          />
        </Link>
        <Link
          to={"/launchsa/resources"}
          className="segment"
          onClick={() => setSidebarVisible(false)}
        >
          <ResourceIcon color="rgba(20, 20, 20, 0.24)" />
          <Text
            text={translate("client").sidebar.resources}
            className="segment-text"
          />
        </Link>
        <Link
          to={currentStyle ? "/launchsa/events" : "/client/events"}
          className="segment"
          onClick={() => setSidebarVisible(false)}
        >
          <EventsIcon color="rgba(20, 20, 20, 0.24)" />
          <Text
            text={translate("client").sidebar.cityEvents}
            className="segment-text"
          />
        </Link>
        <Link
          to="/launchsa/FAQ"
          className="segment"
          onClick={() => setSidebarVisible(false)}
        >
          <FAQIcon color="rgba(20, 20, 20, 0.24)" />
          <Text text={"FAQs"} className="segment-text" />
        </Link>
        <Divider className="sidebar-divider" />
      </div>
      <div className="client-sidebar-content">
        <Link
          to={`/client/verify?logout=true&launchsa=${currentStyle}`}
          className="segment"
          onClick={() => setSidebarVisible(false)}
        >
          <LogoutIcon color="rgba(20, 20, 20, 0.24)" />
          <Text
            text={translate("client").sidebar.logout}
            className="segment-text"
          />
        </Link>
      </div>
    </Drawer>
  );
};

export default ClientSidebar;
