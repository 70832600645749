import React, { useContext, Suspense, useState, lazy, useEffect } from "react";
import { SpiningPage } from "components/atoms/icons";
import { I18nContext } from "utils/i18n/locale";
import AnalyticsTemplate from "components/templates/Analytics";
import { Responsive, WidthProvider } from "react-grid-layout";
import { PlusOutlined } from "@ant-design/icons";
import { ACTION_COLOR, PrimaryButton } from "components/atoms/controls";
import AddChartsPane from "components/organisms/AddChartsPane";
import { CHARTS } from "components/organisms/Analytics";
import "react-grid-layout/css/styles.css";
import "./Editor.scss";
import placeholder from "assets/img/illustrations/no-charts.svg";
import { useParams } from "react-router-dom";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Row } from "antd";

const EmptyComponentTemplate = lazy(() =>
  import("components/templates/EmptyComponentTemplate")
);

const ResponsiveGridLayout = WidthProvider(Responsive);

const Actions = ({ onAddDashboard, onAddChart }) => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="analytics-editor-actions-container">
      <PrimaryButton
        icon={<PlusOutlined />}
        onClick={onAddDashboard}
        cssClassName="action-buttons"
        title={translate("pages").analytic.editor.addDashboard}
        color={ACTION_COLOR}
      />
      <PrimaryButton
        icon={<PlusOutlined />}
        onClick={onAddChart}
        cssClassName="action-buttons"
        title={translate("pages").analytic.editor.addGraph}
      />
    </div>
  );
};

const AnalyticsDashboardEditor = (props) => {
  const { id } = useParams();
  const [, setCurrentBreakpoint] = useState();
  const [, setCols] = useState();
  const [openAddChart, setOpenAddChart] = useState(false);
  const [items, setItems] = useState([]);
  const [layout, setLayout] = useState({});
  const { translate } = useContext(I18nContext);
  const [title] = useState(props?.history?.location?.state?.data?.title);
  const [isDetails] = useState(!!id);
  const [isEdit, setIsEdit] = useState(false);

  const onBreakpointChange = (breakpoint, cols) => {
    setCurrentBreakpoint(breakpoint);
    setCols(cols);

    console.log(cols, breakpoint);
  };

  const onDrop = (newLayout, layoutItem, event) => {
    const key = event.dataTransfer.getData("component");
    const { width, height, ...rest } = CHARTS[key];
    setLayout({
      ...layout,
      [`${key}-${items.length}`]: {
        ...layoutItem,
        ...rest,
        w: width,
        h: height || 1,
        i: `${key}-${items.length}`,
        isResizable: true,
        isBounded: true,
        resizeHandles: ["se"],
      },
    });
    setItems([
      ...items,
      {
        i: `${key}-${items.length}`,
        component: key,
      },
    ]);
  };

  const onAddNewDashboard = () => {
    props.history.replace("/analytics", {
      items: [
        {
          id: Date.now(),
          img:
            "https://res.cloudinary.com/kugoo/image/upload/v1609715481/upload/card.jpg",
          title,
          layout: items.map((item) => ({ ...layout[item.i], ...item })),
        },
      ],
    });
  };

  useEffect(() => {
    if (!props?.history?.location?.state?.data?.layout) return;

    let layoutItems = [];
    setLayout(
      props.history.location.state.data.layout.reduce(
        (acc, { component, ...item }) => {
          layoutItems.push({ i: item.i, component });
          return { ...acc, [item.i]: item };
        },
        {}
      )
    );
    setItems(layoutItems);
  }, []);

  return (
    <Suspense fallback={<SpiningPage opacity={1} />}>
      <AnalyticsTemplate
        onEdit={() => setIsEdit(true)}
        isDetails={isDetails}
        title={title}
        headerActivity={
          <Row align="middle" className="analytics-editor-actions-container">
            <Actions
              onAddChart={() => setOpenAddChart(true)}
              onAddDashboard={onAddNewDashboard}
            />
            {isDetails && !isEdit && (
              <Row className="action-navigation-container">
                <LeftOutlined />
                <RightOutlined />
              </Row>
            )}
          </Row>
        }
      >
        {(!items || items.length === 0) && !openAddChart ? (
          <EmptyComponentTemplate
            image={placeholder}
            buttonIcon={<PlusOutlined />}
            title={translate("pages").analytic.editor.charts.placeholder.title}
            subtitle={
              translate("pages").analytic.editor.charts.placeholder.subtitle
            }
            buttonText={
              translate("pages").analytic.editor.charts.placeholder.button
            }
            onButtonClick={() => setOpenAddChart(true)}
          />
        ) : (
          <>
            <AddChartsPane
              show={openAddChart}
              onClose={() => setOpenAddChart(false)}
            />
            <div className="analytics-grid-container">
              <ResponsiveGridLayout
                {...props}
                onBreakpointChange={onBreakpointChange}
                measureBeforeMount={false}
                useCSSTransforms={true}
                onDrop={onDrop}
                rowHeight={60}
                isDroppable={true}
                droppingItem={{ w: 12, h: 2, i: "__dropping-elem__" }}
                onLayoutChange={(newLayout) => {
                  setLayout(
                    newLayout.reduce(
                      (acc, item) => ({
                        ...acc,
                        [item.i]: item,
                      }),
                      {}
                    )
                  );
                }}
                autoSize
                containerPadding={[0, 0]}
                className="layout"
                cols={{ lg: 12, md: 12, sm: 12, xs: 4, xxs: 4 }}
              >
                {items.map(({ component, i }) => {
                  const Component = CHARTS[component].component;
                  return (
                    <div key={i} data-grid={layout[i]}>
                      <Component />
                    </div>
                  );
                })}
              </ResponsiveGridLayout>
            </div>
          </>
        )}
      </AnalyticsTemplate>
    </Suspense>
  );
};

export default AnalyticsDashboardEditor;
