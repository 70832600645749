import { Divider, Drawer, Image } from "antd";
import NotificationItem from "client/components/molecules/NotificationItem";
import { NotificationContextProvider } from "client/context/NotificationContext";
import { Text } from "components/atoms/texts";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import "./NotificationDrawer.scss";
import noNotificationSvg from "assets/img/no-notifications.svg";
import { I18nContext } from "utils/i18n/locale";
const NotificationDrawer = () => {
  const { visible, setVisible, notifications, setNotifications } = useContext(
    NotificationContextProvider
  );
  const history = useHistory();
  const handleClick = (index) => {
    setVisible(false);
    history.push({
      pathname: `/client/notification/${index}`,
      notification: notifications[index],
    });
  };
  const { translate } = useContext(I18nContext);
  return (
    <div>
      <Drawer
        visible={visible}
        getContainer={false}
        className="notification-drawer"
        onClose={() => setVisible(false)}
        title={translate("client").pages.notifications.title.notifications}
        width="80%"
      >
        <div className="notification-items">
          {notifications.length > 0 ? (
            <>
              {notifications.map(
                ({ type, subject, body, sent_at, read }, index) => (
                  <NotificationItem
                    isReport
                    read={read}
                    item={{
                      type,
                      subject,
                      body,
                      sent_at,
                    }}
                    index={index}
                    key={index}
                    onClick={handleClick}
                  />
                )
              )}
              <div className="clear-notifications">
                <Text
                  text={
                    translate("client").pages.notifications.title
                      .clearNotifications
                  }
                  onClick={() => setNotifications([])}
                />
              </div>
            </>
          ) : (
            <div className="no-notifications">
              <Image src={noNotificationSvg} preview={false} />
              <Text
                text={
                  translate("client").pages.notifications.title.noNotification
                }
              />
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default NotificationDrawer;
