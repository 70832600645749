import LoginRt from "client/pages/Login";
import SignUpRt from "client/pages/Signup";
import ForgotRt from "client/pages/Forgot";
import ValidateRt from "client/pages/Validate";
import Verify from "client/pages/Verify";
import EditProfile from "client/pages/EditProfile";
import Communication from "client/pages/Communication";
import CommunicationDetails from "client/pages/CommunicationDetails";
import CreateMessage from "client/pages/CreateMessage";
import CommunicationReply from "client/pages/CommunicationReply";

const prefix = "/launchsa";

const routes = [
  {
    path: `${prefix}`,
    component: LoginRt,
    exact: true,
  },
  {
    path: `${prefix}/signup`,
    component: SignUpRt,
    exact: true,
  },
  {
    path: `${prefix}/forgot`,
    component: ForgotRt,
    exact: true,
  },
  {
    path: `${prefix}/validate`,
    component: ValidateRt,
    exact: true,
  },
  {
    path: `${prefix}/verify`,
    component: Verify,
    exact: true,
  },
  {
    path: `${prefix}/profile`,
    component: EditProfile,
    exact: true,
  },
  {
    path: `${prefix}/communication`,
    component: Communication,
    exact: true,
  },
  {
    path: `${prefix}/message`,
    component: CommunicationDetails,
    exact: true,
  },
  {
    path: `${prefix}/newMessage`,
    component: CreateMessage,
    exact: true,
  },
  {
    path: `${prefix}/reply`,
    component: CommunicationReply,
    exact: true,
  },
];

export default routes;
