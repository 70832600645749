import React, {useContext} from 'react';
import "./EventsTab.scss";
import TabsMenu from "components/atoms/tabs";
import EventsContainer from "client/components/organisms/EventsContainer";
import { I18nContext } from "utils/i18n/locale";
const EventsTab = ({ eventFilter, setEventFilter, eventsList }) => {
  const { translate } = useContext(I18nContext)

  const eventsFilterTypes = [
    {key: 'all', title: translate("client").pages.events.tab.all, children: <EventsContainer eventsList={eventsList} type={"all"} /> }, 
    {key: 'my', title:  translate("client").pages.events.tab.my, children: <EventsContainer eventsList={eventsList} type={"my"} /> }
  ]

  return(
    <div className="events-tab-wrapper">
      <TabsMenu
        defaultActiveKey={eventFilter}
        activeKey={eventFilter}
        onChange={(item) => setEventFilter(item)}
        options={eventsFilterTypes}
      />
    </div>
    
  )
}

export default EventsTab;