import Recover from "pages/Recover";

const prefix = "/recover";

const routes = [
  {
    path: `${prefix}`,
    component: Recover,
    exact: true,
  },
];

export default routes;
