import React from "react";
import { Row, Col, Skeleton } from "antd";
import { Dropdown, CrudOptionsMenu } from "components/atoms/controls";
import { Fragment, useEffect, useRef } from "react";
import "./Card.scss";
// import notifyIcon1 from "assets/img/illustrations/notify1.svg";
// import notifyIcon2 from "assets/img/illustrations/notify2.svg";
import notifyIcon3 from "assets/img/illustrations/notify1.svg";
// import notifyIcon4 from "assets/img/illustrations/notify4.svg";
import { cloudFrontUrl } from "utils/common";

const Image = React.memo(function Image({ src, classname }) {
  return <img src={src} className={classname} alt="loading..." />;
});

const Card = (props) => {
  const isMounted = useRef(null);
  const { loading, item } = props;
  const propFunctions = props.props;

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getImage = (key) => {
    if (key !== null && key !== undefined) {
      return `${cloudFrontUrl(key)}`;
    } else {
      return notifyIcon3;
      // switch (Math.floor(Math.random() * 4) + 1) {
      //   case 1:
      //     return notifyIcon1;
      //   case 2:
      //     return notifyIcon2;
      //   case 3:
      //     return notifyIcon3;
      //   case 4:
      //     return notifyIcon4;
      //   default:
      //     return notifyIcon1;
      // }
    }
  };


  return (
    <div className="object-card">
      <div className="card-container">
        <Row
          onClick={() => {
            propFunctions.handleEdit(item);
          }}
        >
          <Col span={24}>
            {loading ? (
              <Skeleton.Image active={loading} className="card-image-loading" />
            ) : (
              <div
                className={
                  item?.image !== null && item?.image !== undefined
                    ? "card-image-container"
                    : "card-image-placeholder"
                }
              >
                <Image
                  src={`${getImage(item.image)}`}
                  classname={
                    item?.image !== null && item?.image !== undefined
                      ? "card-image"
                      : "card-image-placeholder"
                  }
                />
                {/* <img
                  className="card-image"
                  alt="loading..."
                  src={image}
                  // src="https://picsum.photos/800"
                /> */}
              </div>
            )}
          </Col>
        </Row>
        <div className="card-title-container">
          <Row gutter={[0, 6]}>
            {loading ? (
              <Skeleton.Input
                style={{ width: 175 }}
                active={loading}
                size={"small"}
              />
            ) : (
              <Fragment>
                <Col
                  className="card-title"
                  span={22}
                  onClick={() => {
                    propFunctions.handleEdit(item);
                  }}
                >
                  {item.title ?? item.subject ?? "NO DATA"}
                </Col>
                <Col span={2}>
                  <Dropdown
                    menu={<CrudOptionsMenu item={item} props={propFunctions} />}
                  />
                </Col>
              </Fragment>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};
export default Card;
