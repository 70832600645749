import React, { useContext } from "react";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Divider, Layout, Row } from "antd";
import CheckboxGroup, { TYPE_ICONS } from "components/atoms/checkbox-group";
import { PrimaryButton } from "components/atoms/controls";
import { ListViewIcon, GridIcon } from "components/atoms/icons";
import { StandardInput } from "components/atoms/inputs";
import { Title } from "components/atoms/texts";
import { LAYOUT_GRID, LAYOUT_LIST } from "pages/Surveys";
import { I18nContext } from "utils/i18n/locale";
import "./DirectoryHeader.scss";
import { useHistory } from "react-router";

const DirectoryHeader = ({
  title,
  style,
  withActions,
  onChangeLayout,
  layout = "list",
}) => {
  const { translate } = useContext(I18nContext);
  const history = useHistory();
  return (
    <Layout style={{ ...style }} className="directory-header-container">
      <Row gutter={[0, { xs: 8, sm: 16 }]}>
        <Col xs={24} lg={24} xl={10}>
          <Title text={title} className="directory-main-title" />
        </Col>
        {withActions && (
          <Col xs={24} lg={24} xl={14} className="directory-action-container">
            {
              <div className="actions-container">
                <StandardInput
                  placeholder={translate("pages").directory.placeholder.search}
                  className="directory-action-search"
                  prefixIcon={<SearchOutlined />}
                />
                <PrimaryButton
                  icon={<PlusOutlined />}
                  onClick={() =>
                    history.push("/home/business-directory/new-business/")
                  }
                  title={translate("pages").directory.placeholder.create}
                  cssClassName="directory-header-button"
                />
                <CheckboxGroup
                  className="directory-action-layout"
                  onChange={(value) => onChangeLayout(Object.keys(value)[0])}
                  selected={{ [layout]: true }}
                  options={[
                    {
                      key: LAYOUT_GRID,
                      icon: GridIcon,
                    },
                    {
                      key: LAYOUT_LIST,
                      icon: ListViewIcon,
                    },
                  ]}
                  multiSelect={false}
                  defaultColor="#FFFFFF"
                  // selectedColor="#141414"
                  iconSelected="rgba(20, 20, 20, 0.38)"
                  type={TYPE_ICONS}
                />
              </div>
            }
          </Col>
        )}
      </Row>
      <Divider className="divider" />
    </Layout>
  );
};

export default DirectoryHeader;
