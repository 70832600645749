import React, {useContext} from 'react';
import {Text} from 'components/atoms/texts';
import "./ReportFeed.scss";
import {I18nContext} from "utils/i18n/locale";
import NoFlags from "assets/img/NoFlags.png";
import ReportRow from "components/client/components/molecules/ReportRow";

const EventsFeed = (props) => {
    const {translate} = useContext(I18nContext);

    const {onClick, data, reloadTable, isFetching, scroll} = props;
    return (
      <div className="reportfeed-container">
        {data && data.length > 0 ? (
          data.map((item, index) => (
              <div key={index}>
                <ReportRow onClick={onClick} item={item} index={index} />
              </div>
          ))
        ) : (
          <div className="no-flags">
            <img className="image" src={NoFlags} alt="no-flags"/>
            <Text className="title-text" text={translate('client').pages.home.NO_PROGRAMS} />
          </div>
        )}
      </div>
    );

}

export default EventsFeed;
