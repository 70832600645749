import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import "./breadcrums.scss";
const Breadcrum = () => {
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  //   const history = useHistory();
  const currentPath = window.location.pathname;
  const paths = currentPath.split("/");
  return (
    <Breadcrumb style={{ marginBottom: 15 }}>
      {paths.map((route) =>
        route !== "" ? (
          <Breadcrumb.Item key={route} className="breadcrum-item">
            <span>{capitalize(route.replaceAll("-", " "))}</span>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key="empty"></Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  );
};

export default Breadcrum;
