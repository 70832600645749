import { Col, Row, Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { I18nContext } from "utils/i18n/locale";
import "./EventSidebar.scss";
import EventSiderHeader from "../EventSidebarHeader";

const EventSidebar = ({
  workspaces,
  deletedWorkspaces,
  handleFilter,
  handleSaveTopic,
}) => {
  const { translate } = useContext(I18nContext);
  const { SubMenu } = Menu;
  const [menus, setMenus] = useState(workspaces);
  const [menusAux, setMenusAux] = useState(workspaces);
  const [deleted, setDeleted] = useState(deletedWorkspaces);
  const [eventsLength, setEventsLength] = useState(0);

  const onClick = (item) => {
    handleFilter(item.id);
  };

  const handleSearch = (query, isEmpty) => {
    let result = menusAux.filter((n) =>
      n.name.toLowerCase().includes(query.toLowerCase())
    );
    if (result.length <= 0) {
      setMenus([{}]);
    } else {
      if (isEmpty) {
        setMenus(menusAux);
      } else {
        setMenus(result);
      }
    }
  };

  useEffect(() => {
    setMenus(workspaces);
    setMenusAux(workspaces);
  }, [workspaces]);

  useEffect(() => {
    getEventsFromCategory();
  }, [menus]);

  const getEventsFromCategory = () => {
    let result = 0;
    menus.map((menu) => {
      result = parseInt(menu.count) + result;
    });
    setEventsLength(result);
  };

  return (
    <div className="event-sidebar">
      <div className="header">
        <EventSiderHeader
          handleSearch={handleSearch}
          handleSaveTopic={handleSaveTopic}
        />
      </div>
      <Menu defaultSelectedKeys={["1"]} mode="inline" className="menus">
        {" "}
        <Menu.Item key={"AllEvents"} className="menus">
          <Row
            justify="start"
            align="middle"
            onClick={() => onClick({ id: "all" })}
          >
            <Col span={21}>{translate("pages").events.sidebar.allEvents}</Col>
            <Col span={3} className="count">
              {eventsLength}
            </Col>
          </Row>
        </Menu.Item>
        {menus.map((workspace) => (
          <Menu.Item key={workspace.name} className="menus">
            <Row
              justify="start"
              align="middle"
              onClick={() => onClick(workspace)}
            >
              <Col span={21}>{workspace.name}</Col>
              <Col span={3} className="count">
                {workspace.count}
              </Col>
            </Row>
          </Menu.Item>
        ))}
        {deleted.length > 0 && (
          <SubMenu
            key="deleted"
            title={`${translate("pages").events.sidebar.allEvents} (${
              deleted.count
            })`}
            className="deleted"
          >
            {deleted.workspaces.map((workspace) => (
              <Menu.Item key={workspace.name} className="menus">
                <Row
                  justify="start"
                  align="middle"
                  onClick={() => onClick(workspace)}
                >
                  <Col span={21}>{workspace.name}</Col>
                  <Col span={3} className="count">
                    {workspace.count}
                  </Col>
                </Row>
              </Menu.Item>
            ))}
          </SubMenu>
        )}
      </Menu>
    </div>
  );
};

export default EventSidebar;
