import Notifications from "pages/Notifications";
import NotificationsManagement from "pages/Notifications/NotificationsManagement";

const prefix = "/notifications";

const routes = [
  {
    path: `${prefix}`,
    component: Notifications,
    exact: true,
  },
  {
    path: `${prefix}/manage`,
    component: NotificationsManagement,
    exact: true,
  },
];

export default routes;
