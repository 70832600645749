import React, { useContext } from "react";
import { Text } from "components/atoms/texts";
import Moment from "moment";
import { I18nContext } from "utils/i18n/locale";
import "./NewsRow.scss";
import { cloudFrontUrl } from "utils/common";

const Image = React.memo(function Image({ src, classname }) {
  const { translate } = useContext(I18nContext);
  return (
    <img
      src={src}
      className={classname}
      alt={translate("client").pages.home.LOADING}
    />
  );
});

const EventsRow = (props) => {
  const { onClick, item, index } = props;

  return (
    <div onClick={() => onClick(index)}>
      <div className="news-container">
        <div className="news-info">
          <Text className="news-title" text={item.title} />
          <div className="news-row">
            <div className="news-source-info">
              <Text
                className="news-date-text"
                text={Moment(item.created_at).format("DD MMM YYYY")}
              />
            </div>
            <div className="news-actions"></div>
          </div>
        </div>
        <div className="news-image">
          {item.image != null ? (
            <Image
              classname="news-image-placeholder"
              src={
                item?.image
                  ? cloudFrontUrl(item.image)
                  : "https://jpassport.asia/static/image-not-available.png"
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EventsRow;
