import { Button, Image, message } from "antd";
import { I18nContext } from "utils/i18n/locale";
import Swal from "sweetalert2";
import NotFound from "assets/svgs/new404.svg";
import "./outputs.scss";
import { useHistory } from "react-router";
import { useContext } from "react";
import { PrimaryButton } from "../controls";
import { getLocalSettings } from "utils/common";

export const Message = (type, msg, key) => {
  const messageData = { content: msg, className: "messages", key };
  switch (type) {
    case "error":
      return message.error(messageData);
    case "success":
      return message.success(messageData);
    case "warning":
      return message.warning(messageData);
    case "info":
      return message.info(messageData);
    case "warn":
      return message.warn(messageData);
    case "loading":
      return message.loading(messageData);
    default:
      return message.info(messageData);
  }
};

export const Alert = (type, title) => {
  const Toast = Swal.mixin({
    toast: true,
    customClass: "alert-min",
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  switch (type) {
    case "error":
      Toast.fire({ icon: type, title: title });
      break;
    case "success":
      Toast.fire({ icon: type, title: title });
      break;
    case "warning":
      Toast.fire({ icon: type, title: title });
      break;
    case "info":
      Toast.fire({ icon: type, title: title });
      break;
    default:
      Toast.fire({ icon: "info", title: title });
  }
};

export const ConfirmAlert = (title) => {
  Swal.fire({
    title: "Do you want to save the changes?",
    showDenyButton: true,
    showCancelButton: true,
    // confirmButtonText: `Save`,
    denyButtonText: `Don't save`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isDenied) {
      Swal.fire("Se va a borrar", "", "info");
    }
  });
};

export const AlertWithfunctions = (
  {
    title,
    text,
    icon,
    confirm,
    successTitle,
    successSubtitle,
    errorTitle,
    errorSubtitle,
    dispatcher,
    dispatchFunction,
    unwrapResult,
    values,
    redirect,
    destroyFunction,
    destroyFuncArgs,
    history,
    path,
    dispatchOnSuccess,
    goBack,
  },
  props
) => {
  const settings = getLocalSettings();
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonColor: settings?.sibebar_color ?? "#000000",
    cancelButtonColor: "#d33",
    confirmButtonText: confirm,
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return dispatcher(dispatchFunction(values))
        .then(unwrapResult)
        .then(() => {
          Swal.fire({
            title: successTitle,
            text: successSubtitle,
            icon: "success",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            didClose: () => {
              if (history !== undefined && path !== undefined) {
                history.push(path);
              }
              if (history !== undefined && goBack) {
                history.goBack();
              }
              if (dispatchOnSuccess) {
                dispatcher(dispatchOnSuccess(values));
              }
            },

            // didOpen: () => {
            //   Swal.showLoading();
            // },
          });
          if (destroyFunction) {
            destroyFunction(...destroyFuncArgs);
          }
        })
        .catch(() => {
          Swal.fire({
            title: errorTitle,
            text: errorSubtitle,
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            // didOpen: () => {
            //   if (history !== undefined && goBack) {
            //     history.goBack();
            //   }
            //   if (dispatchOnSuccess) {
            //     console.log("se va a cerrar");
            //     dispatcher(dispatchOnSuccess(values));
            //   }
            // },
            // didOpen: () => {
            //   Swal.showLoading();
            // },
            icon: "error",
          });
        });
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });
};

export const SwalAlerts = ({ type, title, text }) => {
  Swal.fire({
    title: title,
    text: text,
    icon: type,
    timer: 2000,
    customClass: "top-index",
    timerProgressBar: true,
    showConfirmButton: false,
    didOpen: () => {
      // Swal.showLoading();
    },
  });
};

export const NotFoundResult = () => {
  const history = useHistory();
  const { translate } = useContext(I18nContext);
  return (
    <>
      <Image src={NotFound} preview={false} className="not-found-image" />
      <div className="not-found-button-container">
        <h1 className="not-found-title">
          {translate("pages").notfound.subtitle}
        </h1>
        <div>
          <PrimaryButton
            title={translate("pages").notfound.button.back}
            cssClassName="not-found-button "
            onClick={() => history.push("/home/analytics/demo")}
          />
        </div>
      </div>
    </>
  );
};
