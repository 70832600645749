import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./ViewEvent.scss";
import ViewEventTopbar from "client/components/molecules/ViewEventTopBar";
import ViewEventContainer from "client/components/organisms/ViewEventContainer";
import { I18nContext } from "utils/i18n/locale";
import { useHistory, useLocation } from "react-router-dom";

const ViewEvent = ({ location }) => {
  const { translate } = useContext(I18nContext);
  const historyLocation = useLocation();

  const [evento, setEvento] = useState(historyLocation?.state?.evento ?? null);
  console.log(evento);
  return (
    <div className="view-event-wrapper">
      <ViewEventTopbar
        title={translate("client").pages.events.viewEvents.title}
      />
      <ViewEventContainer evento={evento} />
    </div>
  );
};

export default ViewEvent;
