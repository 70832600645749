import moment from "moment";
import Cookies from "universal-cookie";
import { readAndCompressImage } from "browser-image-resizer";
export const dateFormat = "DD MMMM, YYYY";
export const dateFormatNoYear = "DD MMMM";
export const hourFormat = "HH:mm";

export const emailRegex = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/g);

export const passwordRegex = RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

export const phoneRegex = RegExp(
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
);

export const numberRegex = RegExp(/^\d+$/);

export const makeId = (length) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const capitalize = (text) => {
  if (typeof text !== "string") return "";
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const makeDate = (date, hour) => {
  let stringDate = `${moment(date).format("YYYY")}-${moment(date).format(
    "MM"
  )}-${moment(date).format("DD")}T${moment(hour)
    // .add(6, "hours")
    .format("HH")}:${moment(hour).format("mm")}:00`;
  // .format("HH")}:${moment(hour).format("mm")}:00.737Z`;
  return stringDate;
};

export const getFormatedDate = (fullDate, withYear) => {
  if (withYear) return moment(fullDate).format(dateFormat);
  else {
    return moment(fullDate).format(dateFormatNoYear);
  }
};
export const getFormatedTime = (fullDate) => {
  return moment(fullDate).utc().format(hourFormat);
};

export const formatDateInput = (fullDate, isTime) => {
  if (isTime) {
    if (!fullDate) {
      return moment(moment().format(hourFormat), hourFormat);
    } else {
      return moment(moment(fullDate).utc().format(hourFormat), hourFormat);
    }
  } else {
    return moment(
      moment(fullDate).format(dateFormat),
      // moment(fullDate).add(6, "hours").format(dateFormat),
      dateFormatNoYear
    );
  }
};

export const dateTimeFormat = (date) => {
  return moment(date).utc().format("DD/MM/YYYY hh:mm A");
};

export const resizeImage = async (file, quality = 0.4, type = "image/png") => {
  const imgConfig = {
    quality: quality,
    autoRotate: true,
    debug: true,
    mimeType: type,
  };
  try {
    let resizedImage = await readAndCompressImage(file, imgConfig);
    return resizedImage;
  } catch (error) {
    return error;
  }
};

export const textTruncate = (text = "", limit = 100, replacement = "") => {
  return text.length <= limit
    ? text
    : `${text.substring(0, limit)}${replacement}`;
};

export const getCurrentProjectId = () => {
  const project_id = localStorage.getItem("current_project");
  return Number(project_id);
};
export const getCurrentUser = () => {
  const current_user = localStorage.getItem("current_user");
  return JSON.parse(current_user);
};
export const getCurrentOrganizationId = () => {
  const organization_id = localStorage.getItem("current_organization");
  return Number(organization_id);
};

export const getLocalSettings = () => {
  const settings = localStorage.getItem("settings");
  if (settings) {
    return JSON.parse(settings);
  } else {
    return {};
  }
};

export const getLocalRoles = () => {
  const roles = localStorage.getItem("userRoles");
  if (roles) {
    return JSON.parse(roles);
  } else {
    return {};
  }
};

export const validateLaunchSA = () => {
  const cookies = new Cookies();
  const organization = cookies.get("organization");
  console.log({ organization });
  if (organization) {
    return true;
  }
};

export const cloudFrontUrl = (key) => {
  return `${process.env.REACT_APP_CLOUDFRONT_URL}${key}` ;
};
