import { Col, Layout, Row } from "antd";
import ListPagination from "components/atoms/pagination";
import { Text } from "components/atoms/texts";
import DirectoryListItem from "components/molecules/DirectoryListItem";
import React, { useContext } from "react";
import { I18nContext } from "utils/i18n/locale";
import "./DirectoryListTemplate.scss";

const DirectoryListTemplate = ({ data }) => {
  const { translate } = useContext(I18nContext);
  return (
    <Layout>
      <Text
        text={translate("pages").survey.title.allDirectories}
        className="directory-list-header-text"
      />
      <Row className="directory-list-header">
        <Col xs={11} lg={8}>
          <Text
            text={translate("pages").survey.title.name}
            className="directory-list-header-text directory-list-header-title"
          />
        </Col>
        <Col xs={11} lg={7} className="directory-list-header-text-wrapper">
          <Text
            text={translate("pages").survey.title.created}
            className="directory-list-header-text"
          />
        </Col>
        <Col xs={0} lg={7} className="directory-list-header-text-wrapper">
          <Text
            text={translate("pages").survey.title.lastEdit}
            className="directory-list-header-text"
          />
        </Col>
      </Row>
      <div className="directory-list-container">
        {data.map((directory) => (
          <DirectoryListItem directory={directory} />
        ))}
        <ListPagination data={data} currentPage={1} />
      </div>
    </Layout>
  );
};

export default DirectoryListTemplate;
