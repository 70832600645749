import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./resources.scss";

import TabMenu from "components/client/components/molecules/TabMenu";
import { I18nContext } from "utils/i18n/locale";
import ResourcesFeed from "components/client/components/organisms/ResourcesFeed";
import { BackArrowMobile } from "assets/svg/mobile";
import { Text } from "components/atoms/texts";

import {
  getClientResources,
  getClientMyResources,
} from "redux/slices/resources";
import { resetGetData } from "redux/slices/projects";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

const Resources = (props) => {
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();
  const historyLocation = useLocation();
  const resourcesFetched = useSelector((state) => state.resources.resources);
  const myResourcesFetched = useSelector(
    (state) => state.resources.clientResources
  );

  const [projectId, setProjectId] = useState();
  const [resources, setResources] = useState(resourcesFetched);
  const [myResources, setMyResources] = useState(myResourcesFetched);

  const history = useHistory();
  const [currentStyle, setCurrentStyle] = useState(null);

  useEffect(() => {
    const cs_json = JSON.parse(localStorage.getItem("custom_client"));
    if (cs_json) {
      setCurrentStyle(cs_json);
    }
  }, []);

  const tabMenuData = [
    translate("pages").mobile.resources.general,
    translate("pages").mobile.resources.myResources,
  ];

  const [option, setOption] = useState(historyLocation?.state?.option ?? 0);

  useEffect(() => {
    console.log("option", option);
    if (option == "1") {
      setState({
        ...state,
        selectedMenu: 1,
      });
    }
  }, [option]);

  useEffect(() => {
    if (currentStyle != null) {
      dispatch(getClientResources(currentStyle.projectId));
      dispatch(getClientMyResources(currentStyle.projectId));
    }
  }, [currentStyle]);

  useEffect(() => {
    console.log("resourcesFetched", resourcesFetched);
    setResources(resourcesFetched);
  }, [resourcesFetched]);

  useEffect(() => {
    console.log("myResourcesFetched", myResourcesFetched);
    setMyResources(myResourcesFetched);
  }, [myResourcesFetched]);

  const handleResourcesClick = (index) => {
    history.push({
      pathname: "/launchsa/resourceDetail/",
      state: {
        resourceId:
          state.selectedMenu === 0
            ? resources[index].id
            : myResources[index].resource_id,
      },
    });
  };

  const [state, setState] = useState({
    selectedMenu: 0,
    modalVisible: false,
    projectModal: false,
    isFetching: false,
    isFetchingReports: false,
    projectName: "",
    projectOrganization: "",
  });

  const projectClose = () => {
    console.log("click");
    setState({ ...state, projectModal: !state.projectModal });
  };

  const tabPress = ({ nativeEvent }) => {
    const selected = nativeEvent.target.innerText;

    console.log("selected", selected);

    setState({
      ...state,
      selectedMenu:
        selected.replace(/\s/g, "") ===
        translate("pages").mobile.resources.general.replace(/\s/g, "")
          ? 0
          : 1,
    });
  };

  return (
    <div id="client-home" className="client-home-container">
      <div className="mobile-header">
        <div className={"back-arrow"}>
          <BackArrowMobile onClick={() => history.push("/launchsa/home")} />
        </div>
        <Text
          className={"resources-title"}
          text={translate("pages").mobile.resources.header}
          style={{ lineHeight: "70px" }}
        />
      </div>
      <div className="home-wrapper">
        <TabMenu
          selected={state.selectedMenu}
          data={tabMenuData}
          onClick={tabPress}
          main={true}
        />

        {state.selectedMenu === 0 ? (
          <div>
            {resources && resources.length > 0 ? (
              <Text
                className="news-title-text"
                text={translate("pages").mobile.resources.listResources}
              />
            ) : null}
            <ResourcesFeed
              data={resources}
              onClick={handleResourcesClick}
              isFetching={state.isFetching}
              type={0}
            />
          </div>
        ) : (
          <div>
            {myResources && myResources.length > 0 ? (
              <div className="resourcelist-title-container">
                <Text
                  className="news-title-text"
                  text={translate("pages").mobile.resources.listResources}
                />
                <br />
                <Text
                  className="news-subtitle-text"
                  text={
                    translate("pages").mobile.resources.listResourcesSubtitle
                  }
                />
              </div>
            ) : null}
            <ResourcesFeed
              data={myResources}
              onClick={handleResourcesClick}
              isFetching={state.isFetching}
              type={1}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Resources);
