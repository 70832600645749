import React, {useContext} from "react";
import {Col, Divider, Layout} from "antd";
import "./FollowersPane.scss";
import TabsMenu from "components/atoms/tabs";
import FollowerItem from "components/molecules/FollowerItem";
import {I18nContext} from "utils/i18n/locale";

const ContentPane = ({ items = [] }) => {
  return <Col>
    {
      items.map((item, index) => <React.Fragment key={index.toString()}>
        <FollowerItem {...item}/>
        <Divider/>
      </React.Fragment>)
    }
  </Col>
}

const FollowersPane = () => {
  const { translate } = useContext(I18nContext);

  const following = Array(10).fill(0).map(() => ({
    name: 'Gary Vee',
    points: 7,
    level: 2,
    image: `https://i.pravatar.cc/100?u=${Math.random() * 100}`
  }))
  const followers = Array(10).fill(0).map(() => ({
    name: 'Gary Vee',
    points: 7,
    level: 2,
    image: `https://i.pravatar.cc/100?u=${Math.random() * 100}`
  }))

  return (
    <Layout className='followers-pane'>
      <TabsMenu
        variant='expanded'
        onChange={item => console.log(item)}
        options={
          [
            {
              title: translate("organisms").followerPane.following,
              key: "following",
              children: <ContentPane items={following}/>
            },
            {
              title: translate("organisms").followerPane.followers,
              key: "followers",
              children: <ContentPane items={followers}/>
            },
          ]
        }
      />
    </Layout>
  );
};

export default FollowersPane;
