import { SearchOutlined } from "@ant-design/icons";
import { StandardInput } from "components/atoms/inputs";
import { Text, SearchText } from "components/atoms/texts";
import { KnowledgeContextProvider } from "context/KnowledgeContext";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { I18nContext } from "utils/i18n/locale";
import "./KnowledgeSearch.scss";

const KnowledgeSearch = () => {
  const { translate } = useContext(I18nContext);
  const { categories } = useContext(KnowledgeContextProvider);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  let counter = 0;
  const onChange = (e) => {
    if (e.target.value !== "") {
      setSearchWord(e.target.value);
      const filteredData = categories.map((category) => {
        const searchedPosts = category.posts?.filter((post) =>
          post.title.includes(e.target.value)
        );
        return { searchedPosts, category: category.title };
      });
      setFilteredPosts(filteredData);
    } else {
      setFilteredPosts([]);
    }
  };
  return (
    <div className="knowledge-search-box">
      <StandardInput
        prefixIcon={<SearchOutlined />}
        placeholder={translate("pages").knowledge.placeholder.searchQuestion}
        onChange={onChange}
      />

      <div className="search-results">
        {filteredPosts?.map(({ searchedPosts, category }) =>
          searchedPosts?.map((post) => (
            <SearchResultCard
              title={post.title}
              category={category}
              searchWord={searchWord}
              fullPost={post}
            />
          ))
        )}
        {filteredPosts?.map(({ searchedPosts, category }) => {
          if (searchedPosts.length <= 0) {
            counter++;
          }
        })}
        {counter !== 0 && counter === filteredPosts.length && (
          <div className="search-no-result-card">
            <span>
              {translate("pages").knowledge.placeholder.noResultsFound}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const SearchResultCard = ({ title, category, searchWord, fullPost }) => {
  const history = useHistory();
  const formattedTitle =
    title.slice(0, title.indexOf(searchWord)) +
    "<b class='text-bold'>" +
    searchWord +
    "</b>" +
    title.slice(title.indexOf(searchWord) + searchWord.length, title.length);
  return (
    <div
      className="search-result-card"
      onClick={() => history.push(`/home/knowledge/${category}/${fullPost.id}`)}
    >
      <SearchText
        innerHTML={{ __html: formattedTitle }}
        className="search-title"
      />
      <Text text={category} className="search-category" />
    </div>
  );
};
export default KnowledgeSearch;
