import React, { useContext } from "react";
import { Text } from "components/atoms/texts";
import moment from "moment";
import { I18nContext } from "utils/i18n/locale";
import "./CommunicationRow.scss";
import LaunchSaLogo from "assets/img/launchSaLogo.png";
import { RedCircle } from "assets/svg/mobile";
import { cloudFrontUrl } from "utils/common";

const Image = React.memo(function Image({ src, classname }) {
  const { translate } = useContext(I18nContext);
  return (
    <img
      src={src}
      className={classname}
      alt={translate("client").pages.home.LOADING}
    />
  );
});

const CommunicationRow = (props) => {
  const { onClick, item } = props;
  return (
    <div onClick={() => onClick(item.id)}>
      <div className="communication-container">
        <div className="communication-horizontal">
          <div className="communication-image">
            <Image
              classname="communication-image-placeholder"
              src={
                item?.mail?.user?.image
                  ? cloudFrontUrl(item?.mail?.user?.image)
                  : LaunchSaLogo
              }
            />
          </div>
          <div className="communication-info">
            <Text
              className="communication-date-text"
              text={moment(item.last_edit).format("DD MMM YYYY")}
            />
            <div className="communication-row">
              <Text className="communication-title" text={"Launch SA"} />
            </div>

            <div className="communication-row">
              <div className="communication-source-info">
                <Text className="communication-text" text={item.mail.subject} />
              </div>
            </div>
            <div className="communication-row">
              <div style={{ position: "absolute", right: 16 }}>
                {item.read ? null : <RedCircle />}
              </div>
            </div>
          </div>
        </div>
        <div className="communication-actions"></div>
      </div>
    </div>
  );
};

export default CommunicationRow;
