import React, { useContext, useState } from "react";
import ActivityItem from "components/molecules/ActivityItem";
import { CommentIcon, FlagsIcon } from "components/atoms/icons";
import { Divider } from "antd";
import "./LatestActivityOrganism.scss";
import { Title } from "components/atoms/texts";
import { I18nContext } from "utils/i18n/locale";

const LatestActivity = () => {
  const { translate } = useContext(I18nContext);
  const [activities] = useState([
    {
      key: 1,
      icon: <FlagsIcon />,
      title: "Latest Flag",
      subtitle: "No Flags Have Been Submitted By This User",
      color: "#DA8BE3",
    },
    {
      key: 2,
      icon: <CommentIcon />,
      title: "Latest Flag Comments",
      color: "#00B999",
    },
  ]);

  return (
    <div className="organism-container">
      <Title
        className="activity-title"
        level={3}
        text={translate("pages").profile.activity}
      />
      {activities &&
        activities.length > 0 &&
        activities.map((activity, key) => (
          <div key={key}>
            <ActivityItem {...activity} />
            <Divider />
          </div>
        ))}
    </div>
  );
};

export default LatestActivity;
