import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "./resources.scss";
import { I18nContext } from "utils/i18n/locale";
import EventsFeed from "components/client/components/organisms/EventsFeed";
import {
  BackArrowMobile
} from "assets/svg/mobile";
import { Text } from "components/atoms/texts";
import { getClientEvents } from "redux/slices/events";
import { useHistory } from "react-router";

import { Storage } from "aws-amplify";

const SaEvents = (props) => {
  const { translate } = useContext(I18nContext);
  const dispatch = useDispatch();

  const eventsFetched = useSelector((state) => state.events.events);
  const [projectId, setProjectId] = useState();
  const [events, setEvents] = useState(eventsFetched);

  const history = useHistory();

  const tabMenuData = [
    translate("pages").mobile.resources.general,
    translate("pages").mobile.resources.myResources
  ];
  const [currentStyle, setCurrentStyle] = useState(null);

  useEffect(() => {
    const cs_json = JSON.parse(localStorage.getItem("custom_client"));
    if (cs_json) {
      setCurrentStyle(cs_json);
    }
  }, []);



  useEffect(() => {
    if (currentStyle != null) {
      dispatch(getClientEvents(currentStyle.projectId));
    }

  }, [currentStyle]);

  useEffect(() => {
    console.log("eventsFetched", eventsFetched);
    setEvents(eventsFetched);
  }, [eventsFetched]);


const handleResourcesClick = (index)=>{
  history.push({
    pathname: "/launchsa/events/viewevent",
    state: { evento: events[index]  },
  });
}


  const [state, setState] = useState({
    selectedMenu: 0,
    modalVisible: false,
    projectModal: false,
    isFetching: false,
    isFetchingReports: false,
    projectName: "",
    projectOrganization: "",
  });


  const projectClose = () => {
    console.log("click");
    setState({ ...state, projectModal: !state.projectModal });
  };

  const tabPress = ({ nativeEvent }) => {
    const selected = nativeEvent.target.innerText;
    setState({ ...state, selectedMenu: selected === translate("pages").mobile.resources.general ? 0 : 1 });
  };



  return (
    <div id="client-home" className="client-home-container">
    <div className="mobile-header">
      <div className={"back-arrow"}>
      <BackArrowMobile onClick={() => history.goBack()} />
      </div>
      <Text
        className={"resources-title"}
        text={"Events"}
        style={{lineHeight:'70px'}}
      />
    </div>
      <div className="home-wrapper">


          <div >

          <EventsFeed
            data={events}
            onClick={handleResourcesClick}
            isFetching={state.isFetching}
          />
          </div>

      </div>


    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(SaEvents);
