import React from "react";
import "./MainOption.scss";
import {Text} from "components/atoms/texts";

const MainOptionSA = (props) => {
    const {item,onClick} = props;

    return(
        <div className={`option-container-launch ${item.title}`} onClick={()=> onClick(item.route)}>
          <div className="icon" >{item.icon}</div>
          <Text className="title" text={item.title} />
        </div>
    )
}

export default MainOptionSA;
