import { lazy, Suspense } from "react";
import { SpiningPage } from "components/atoms/icons";

const WidgetTemplate = lazy(() =>
  import("components/templates/WidgetTemplate")
);

const renderLoader = () => <SpiningPage opacity={1} />;

const ReportWidget = () => {
  return (
    <Suspense fallback={renderLoader()}>
      <WidgetTemplate />
    </Suspense>
  );
};

export default ReportWidget;
