import React, { useContext } from "react";
import { I18nContext } from "utils/i18n/locale";

import "./SurveyCard.scss";
import { Text } from "components/atoms/texts";

const SurveyCard = ({onClick,survey}) => {

  return (
    <div className="client-survey-card" onClick={onClick} >
      <div>
        <Text
          noInherit={true}
          className={"client-survey-card-title"}
          text={survey.title}
        />
      </div>

      <div className="client-lower-card">
        <Text
          noInherit={true}
          className={"client-survey-card-left-text"}
          text={`${survey.description}`}
        />

        <Text
          noInherit={true}
          className={"client-survey-card-right-text"}
          text={`${survey.participants} participants `}
        />
      </div>
    </div>
  );
};

export default SurveyCard;
